import React, {Component} from "react";
import {Button, Form, ListGroup, ListGroupItem} from "react-bootstrap";
import {Checkbox, Select, Textarea, Textbox} from "react-inputs-validation";
import {Link} from "react-router-dom";
import RuleGroup from "./ruleGroup";
import {toast} from "react-toastify";
import CustomersAPI from "../../../../../services/customers";
import Loader from "../../../../../components/Loader/loader";
import View from "../../common/DataView/view";
import STANDARD_SYNC from "../../../Destinations/assets/img/audience.svg";
import HYPER_PERSONALISED from "../../../Destinations/assets/img/personalised.svg";
import {segmentGrowthTypes} from "../../../../../variables/globalValues.json"

import moment from "moment";
import DestinationAPI from "../../../../../services/destination";
import {
  areDisabledAttributesUsed,
  getPluralEnding,
  isSegmentProcessing,
  processAttributeListToShow
} from "../segmentUtils";
import SegmentCustomersDataGrid from "../DataGrid/SegmentCustomersDataGrid";

class NewSegment extends Component {
  poolingForUpdatingSegmentInfo;

  state = {
    updatingSegment: false,

    // copied from DestinationHome
    destinationData: [
      {
        enum: "STANDARD_SYNC",
        name: "Create Multi-Channel Audience",
        logo: STANDARD_SYNC,
        steps: ["SELECT_SEGMENT", "DESTINATIONS", "REVIEW_ACTIVATE"],
        description:
          "Choose a Segment to sync across multiple marketing platforms to create one unified Audience across multiple channels.",
        cardContent: []
      },
      {
        enum: "HYPER_PERSONALISED",
        name: "Create Personalised Recommendations",
        logo: HYPER_PERSONALISED,
        steps: [
          "SELECT_SEGMENT",
          "RECOMMENDATIONS",
          "UTM_INFO",
          "DESTINATIONS",
          "REVIEW_ACTIVATE"
        ],
        description:
          "Use Product Recommendations to create personalised experiences over email and on-platform.",
        cardContent: []
      }
    ],
    availableIntegration: [],

    ruleCount: 0,
    detailSegment: {
      name: "",
      description: "",
      customerSegmentGrowthType: 'GROWING_IS_GOOD',
      percentageChangeLast30Days: 0,
      customerSegmentType: 'USER_CREATED',
      privateSegment: true,
      ruleGroupAndOr: "AND",
      cachedCustomerCount: 0,
      customerSegmentBuildStatus: 'READY',
      ruleGroups: [
        {
          clausesAndOr: "AND",
          rules: [
            {
              dataSourceAttributeId: null,
              matchCondition: null,
              value: "",
              new: true
            }
          ]
        }
      ],
      destinations: null,
      editable: true,
      dateCreated: null,
      dateModified: null,
      createdByUserName: null,
      modifiedByUserName: null
    },
    blankRule: {
      dataSourceAttributeId: null,
      matchCondition: null,
      value: "",
      new: true
    },
    blankGroup: {
      clausesAndOr: "AND",
      rules: [
        {
          dataSourceAttributeId: null,
          matchCondition: null,
          value: "",
          new: true
        }
      ]
    },
    currentStats: {
      totalSpendInSegment: null,
      spendInLastYearInSegment: null,
      spendInLast180DaysInSegment: null,
      spendInLast60DaysInSegment: null,
      spendInLast30DaysInSegment: null,
      percentageOfTotal: null,
      totalMembership: null,
      numberOfLeavers: null,
      numberOfJoiners: null,
      segmentId: null,
      processingStats: []
    },
    validate: false,
    newSegment: false,
    isloading: false,
    newRule: true,
    newGroup: false,
    enablePreview: false,
    isPreviewloading: false,
    attributesWarningDisplayed: false
  };

  componentWillMount() {
    this.getIntegration().then(() => this.singleSegment());
    this.setState({dataViewId: this.props.dataViewId});
  }

  showWarningIfNeeded() {
    if (areDisabledAttributesUsed(this.state.detailSegment.ruleGroups, this.props.dataSchema)) {
      toast.warn('Attributes highlighted in red are not currently being updated');
    }
    processAttributeListToShow(this.state.detailSegment.ruleGroups, this.props.dataSchema)
  }

  singleSegment = async event => {
    if (this.props.currentSegmentId !== null) {
      try {
        await CustomersAPI.getSingleSegment(this.props.currentSegmentId).then(
          response => {
            this.mapDestination(response);
            this.setState({
              detailSegment: response,
              newRule: false,
              isloading: true
            }, () => this.showWarningIfNeeded());
          }
        );

        return this.rulesCount();
      } catch (err) {
        toast.error("Failed to Load Segment");

        this.setState({
          isloading: true
        });
      }
    } else {
      this.setState({newSegment: true, isloading: true});
    }
  };

  checkSegmentDependency = async event => {
    try {
      await CustomersAPI.segmentDependency(this.props.currentSegmentId).then(
        response => {
          if (response === true) {
            toast.error(
              "This Segment has dependent Destinations. Delete these first in order to continue"
            );
          } else {
            this.props.onDeleteSegment(this.props.currentSegmentId);
          }
        }
      );
    } catch (err) {
      toast.error("Failed to Check Segment Dependcy");

      this.setState({
        isloading: true
      });
    }
  };

  rulesCount() {
    const segment = this.state.detailSegment;
    let ruleCount = 0;
    segment.ruleGroups.map(ruleGroup => {
      ruleCount = ruleCount + ruleGroup.rules.length;
      this.setState({ruleCount});
    });
  }

  handleNeweSegment = async event => {
    this.setState({isloading: false});
    try {
      await CustomersAPI.createNewSegment(this.state.detailSegment).then(
        response => {
          this.setState(
            {
              detailSegment: response,
              isloading: true
            },
            () => this.props.onsaveNewSegment(this.state.detailSegment, true)
          );
          toast.success("Created New Segment");
        }
      );
    } catch (err) {
    }
  };

  handlePrevieweSegment = async event => {
    this.setState({isPreviewloading: false, enablePreview: true});

    try {
      await CustomersAPI.segmentPreview(
        this.state.detailSegment,
        this.state.dataViewId
      ).then(response => {
        this.setState({
          CustomerList: response.firstPageCustomers,
          totalSize: response.totalRecordsCount,
          isPreviewloading: true
        });

      });
    } catch (err) {
    }
  };

  componentWillReceiveProps(prevProps) {

    if (prevProps.dataViewId !== this.state.dataViewId) {
      this.setState({dataViewId: prevProps.dataViewId}, () =>
        this.handlePrevieweSegment()
      );
    }
  }

  componentWillUnmount() {
    clearTimeout(this.poolingForUpdatingSegmentInfo);
  }

  handleUpdateSegment = async event => {

    if (this.props.currentSegmentId !== null) {
      try {
        this.setState({updatingSegment: true}, (async () => {
          const segment = await CustomersAPI.updateSegment(
            this.props.currentSegmentId,
            this.state.detailSegment
          );

          this.props.onUpdateSegment(this.props.currentSegmentId, true, segment);
          this.setState({updatingSegment: false});

          if (isSegmentProcessing(segment)) {
            this.restartSegmentInfoTimeout(this.props.currentSegmentId)
          }
        }))

      } catch (err) {
      }
    } else {
      this.setState({newSegment: true, isloading: true});
    }
  };

  restartSegmentInfoTimeout(segmentId) {
    clearTimeout(this.poolingForUpdatingSegmentInfo)
    this.poolingForUpdatingSegmentInfo = setTimeout(() => {
      CustomersAPI.getSingleSegment(segmentId).then(response => {
        if (response.customerSegmentBuildStatus === 'READY') {
          this.props.onUpdateSegment(this.props.currentSegmentId, true, response, false);
          clearTimeout(this.poolingForUpdatingSegmentInfo);
        } else {
          this.restartSegmentInfoTimeout(segmentId);
        }
      })
    }, 10_000)
  }

  getIntegration = async event => {
    try {
      await DestinationAPI.getIntegration().then(response => {
        let availableIntegration = response;
        this.setState({availableIntegration});
      });
    } catch (err) {
    }
  };

  mapDestination(segmentResponse) {
    let destData = this.state.destinationData;
    let segmentDestinations = segmentResponse.destinations;
    destData.map(destinations => {
      let destinationTypes = segmentDestinations.filter(
        item => item["type"] === destinations.enum
      );
      let formattedData = [];
      destinationTypes.map(destinationData => {

        let segment = {
          title: destinationData.title,
          description: destinationData.description,
          hasError: false,
          segmentID: segmentResponse.id,
          segmentName: segmentResponse.name,
          // createdDate: destinationData.createdDate,
          // records: segmentResponse.cachedCustomerCount,
          isAdded: true
        };

        let attributeList = [];

        destinationData.filters !== undefined &&
        destinationData.filters.map(attribute => {
          attributeList.push({
            attributeID: attribute.id,
            field: attribute.productAttributeDataTag,
            segmentCondition: attribute.matchCondition,
            value: attribute.value,
            type: attribute.dataSourceAttributeType,
            isSaved: true
          });
        });
        let recommendation = {
          fieldValue:
            destinationData.enrichmentType !== undefined
              ? destinationData.enrichmentType
              : null,
          attributeList: attributeList,
          customAttribute: destinationData.filters?.length > 0,
          recommendationCount:
            destinationData.numberRecommendations !== undefined
              ? destinationData.numberRecommendations
              : null,
          hasError: false
        };

        let utmInfo = {
          utmOptions: [
            {
              id: 1,
              title: "UTM Source",
              value:
                destinationData.utmSourceText !== undefined
                  ? destinationData.utmSourceText
                  : null,
              selected:
                destinationData.utmSourceEnabled !== undefined
                  ? destinationData.utmSourceEnabled
                  : false,
              validateSource: false,
              validateHasError: false,
              hasError: false
            },
            {
              id: 2,
              title: "UTM Medium",
              value:
                destinationData.utmMediumText !== undefined
                  ? destinationData.utmMediumText
                  : "",
              selected:
                destinationData.utmMediumEnabled !== undefined
                  ? destinationData.utmMediumEnabled
                  : false,
              validateMedium: false,
              validateHasError: false,
              hasError: false
            },
            {
              id: 3,
              title: "UTM Campaign",
              value:
                destinationData.utmCampaignText !== undefined
                  ? destinationData.utmCampaignText
                  : "",
              selected:
                destinationData.utmCampaignEnabled !== undefined
                  ? destinationData.utmCampaignEnabled
                  : false,
              validateCampaign: false,
              validateHasError: false,
              hasError: false
            }
          ],
          utmAllowDistil:
            destinationData.allowTrackingParameter !== undefined
              ? destinationData.allowTrackingParameter
              : false,
          hasError: false
        };

        let destination = [];

        if (destinationData.destinationIntegrations !== null) {
          let destinationIntegrations = [];
          destinationData.destinationIntegrations.map(integration => {
            let filtereIntegrationData = this.state.availableIntegration.filter(
              item => item.id === integration.connectionId
            );

            if (filtereIntegrationData !== null && filtereIntegrationData.length !== 0) {
              // console.log(filtereIntegrationData[0].name);
              let items = {
                id: integration.id,
                destinationId: integration.connectionId,
                numExternalFields:
                  filtereIntegrationData[0].integrationSettings != null
                    ? filtereIntegrationData[0].integrationSettings
                      .customFieldsLimit
                    : -1,
                destinationName: filtereIntegrationData[0].name,
                attributes: integration.attributes
              };
              destinationIntegrations.push(items);
            }
          });
          destination = destinationIntegrations;
        }

        formattedData = {
          id: destinationData.id,
          apiAccessUrl: destinationData.apiAccessUrl,
          enabled: destinationData.enabled,
          autoSync: destinationData.autoSync,
          createdDate: destinationData.createdDate,
          segment: segment,
          recomendation: recommendation,
          utmInfo,
          destination
        };

        destinations.cardContent.push(formattedData);
      });
    });

    this.setState({destinationData: destData});
  }


  handleChange = e => {
    let detailSegment = this.state.detailSegment;
    detailSegment[e.target.name] = e.target.value;
    this.setState({detailSegment}, () => this.props.onCheckChange());
  };

  handleSegmentType = (privateSegment, e) => {
    let detailSegment = this.state.detailSegment;
    detailSegment.privateSegment = privateSegment;
    this.setState({detailSegment}, () => this.props.onCheckChange());
  };

  handleSegmentGrowthType = (growthType, e) => {
    let detailSegment = this.state.detailSegment;
    detailSegment.customerSegmentGrowthType = growthType;
    this.setState({detailSegment}, () => this.props.onCheckChange());
  };

  handleGroupRule = (e, groupid) => {
    // console.log(e, groupid);
    let detailSegment = this.state.detailSegment;
    // let groupIndex = detailSegment.ruleGroups.findIndex(
    //   item => item.id === groupid
    // );
    detailSegment.ruleGroups[groupid].clausesAndOr = e;

    this.setState({detailSegment}, () => this.props.onCheckChange());
  };

  handleRule = (e, groupid) => {
    let detailSegment = this.state.detailSegment;
    detailSegment.ruleGroupAndOr = e;
    this.setState({detailSegment}, () => this.props.onCheckChange());
  };
  handleAttributeSelection = (displayName, attributeid, groupid, ruleid) => {
    let detailSegment = this.state.detailSegment;
    detailSegment.ruleGroups[groupid].rules[
      ruleid
      ].dataSourceAttributeDisplayName = displayName;
    detailSegment.ruleGroups[groupid].rules[
      ruleid
      ].dataSourceAttributeId = attributeid;
    this.setState({detailSegment}, () => this.props.onCheckChange());
  };
  handleAddRule = groupindex => {
    let detailSegment = this.state.detailSegment;
    // let blankRule = this.state.blankRule;
    let blankRule = {
      dataSourceAttributeId: null,
      matchCondition: null,
      value: "",
      new: true
    };
    detailSegment.ruleGroups[groupindex].rules.push(blankRule);

    this.rulesCount();
    this.setState({detailSegment, newRule: true}, () =>
      this.props.onCheckChange()
    );
  };
  handleRuleSave = (rule, groupIndex, ruleIndex) => {
    let detailSegment = {...this.state.detailSegment};

    detailSegment.ruleGroups[groupIndex].rules[ruleIndex] = rule;
    this.rulesCount();
    this.setState({detailSegment, newRule: false}, () =>
      this.props.onCheckChange()
    );
  };
  handleAddGroup = () => {
    let detailSegment = this.state.detailSegment;
    let blankGroup = {
      clausesAndOr: "AND",
      rules: [
        {
          dataSourceId: null,
          dataSourceAttributeId: null,
          matchCondition: null,
          value: "",
          new: true
        }
      ]
    };
    detailSegment.ruleGroups.push(blankGroup);
    this.setState({detailSegment, newRule: true}, () =>
      this.props.onCheckChange()
    );
  };
  handleDeleteRule = (ruleIndex, groupIndex) => {

    let detailSegment = this.state.detailSegment;
    if (detailSegment.ruleGroups[groupIndex].rules.length === 1) {
      detailSegment.ruleGroups.splice(groupIndex, 1);
    } else {
      detailSegment.ruleGroups[groupIndex].rules.splice(ruleIndex, 1);
    }

    this.setState({detailSegment, newRule: false, newGroup: false}, () => this.props.onCheckChange());

  };
  handleRuleMatchCondidtion = (e, ruleIndex, groupIndex) => {
    let detailSegment = this.state.detailSegment;
    detailSegment.ruleGroups[groupIndex].rules[ruleIndex].matchCondition =
      e.target.value;

    this.setState({detailSegment}, () => this.props.onCheckChange());
  };

  getBtnStatus() {
    return this.state.ruleCount === 0 ||
      this.state.ruleCount === "undefined" ||
      this.state.detailSegment.name === "" ||
      this.state.newRule === true ||
      this.state.detailSegment.description === "" ||
      this.state.updatingSegment
  }

  renderButtonType(isHidden) {
    if (this.state.newSegment) {
      return (
        <Button
          disabled={this.getBtnStatus()}
          onClick={() => this.handleNeweSegment()}
        >
          Save{" "}
        </Button>
      );
    } else {
      return (
        <Button
          disabled={this.getBtnStatus()}
          hidden={isHidden}
          onClick={() => this.handleUpdateSegment()}
        >
          {" "}
          {this.state.updatingSegment ? 'Updating' : 'Update'}{" "}
        </Button>
      );
    }
  }

  checkGroupStatus = () => {
    const detailSegment = {...this.state.detailSegment};

    var res = detailSegment.ruleGroups.filter(function f(o) {
      if (o.rules) {
        return o.rules.filter(f => f.new === true).length;
      }
    });

    return res;
  };

  render() {
    const {
      destinationData,
      totalSize,
      detailSegment,
      isloading,
      isPreviewloading,
      validate,
      newSegment,
      enablePreview
    } = this.state;

    const destinationsLinks = destinationData.flatMap(dd => {
      return dd.cardContent.map(cc => {
        const ddCopy = {
          ...dd
        };
        ddCopy.cardContent = cc;
        return ddCopy;
      })
    });

    const {onBack, dataSchema, dataViewId} = this.props;
    if (isloading) {
      return (
        <main
          className={`position-relative area-main secondary-font add-segment pt-0 ${enablePreview &&
          `has-grid`}`}
        >
          <section className="addsection-top pt-3 pb-3">
            <div className="container-fluid">
              {" "}
              <div className="row">
                <div className="col-sm-4 col-md-3 d-flex align-items-center custom-edit-segment">
                  <div className="heading d-flex">
                    <i
                      className="icon-chevron-thin-left mr-2"
                      onClick={() => onBack(this.props.currentSegmentId)}
                    />
                    <h6 className="m-0">
                      {newSegment ? "Create a Segment" : "Edit A Segment"}
                    </h6>
                  </div>
                </div>
                <div className="col-sm-8 col-md-9 d-flex">
                  {enablePreview && (
                    <>
                      <View
                        dataSchema={dataSchema}
                        dataViewId={dataViewId}
                        onChangeView={this.props.onChangeView}
                      />
                      {isPreviewloading && <div className="segment-info ml-4">
                        <h6>Preview</h6>
                        <span>
                          {totalSize} record{getPluralEnding(totalSize)}
                        </span>
                      </div>}
                    </>
                  )}
                  <div className="right-btn ">
                    {!enablePreview ? (
                      <React.Fragment>
                        {!newSegment && (
                          <Button
                            variant="secondary"
                            hidden={!detailSegment.editable}
                            onClick={() => this.checkSegmentDependency()}
                          >
                            <span className="icon-delete-light"/> Delete
                          </Button>
                        )}

                        <Button
                          variant="secondary"
                          onClick={() => onBack(this.props.currentSegmentId)}
                        >
                          Cancel
                        </Button>

                        <Button
                          variant="secondary"
                          disabled={this.state.newRule}
                          hidden={!detailSegment.editable}
                          onClick={() => this.handlePrevieweSegment()}
                        >
                          Preview
                        </Button>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Button
                          variant="secondary"
                          onClick={() =>
                            this.setState({enablePreview: false})
                          }
                        >
                          Cancel
                        </Button>
                      </React.Fragment>
                    )}

                    {this.renderButtonType(!detailSegment.editable)}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="sideNav">
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Segment Name</Form.Label>

              <Textbox
                disabled={!detailSegment.editable}
                classNameInput="form-control"
                classNameContainer="custome-input"
                tabIndex="1"
                id={"name"}
                name="name"
                type="text"
                validate={validate}
                validationCallback={res =>
                  this.setState({
                    hassegmentNameError: res,
                    validate: false
                  })
                }
                value={detailSegment.name}
                onChange={(val, e) => this.handleChange(e)}
                onBlur={e => {
                }}
                validationOption={{
                  name: "Segment name",
                  check: true,
                  max: 30,
                  required: true
                }}
              />
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
              <Form.Label>Segment Description</Form.Label>

              <Textarea
                disabled={!detailSegment.editable}
                classNameInput="form-control"
                classNameContainer="custome-input"
                tabIndex="1"
                id={"description"}
                name="description"
                type="text"
                validate={validate}
                validationCallback={res =>
                  this.setState({
                    hassegmentDescError: res,
                    validate: false
                  })
                }
                value={detailSegment.description}
                onChange={(val, e) => this.handleChange(e)}
                onBlur={e => {
                }}
                validationOption={{
                  name: "Segment description",
                  check: true,
                  required: true
                }}
              />
            </Form.Group>

            <Form.Group controlId="formBasicCheckbox">
              <Form.Label>Private Segment</Form.Label>
              <Checkbox
                disabled={!detailSegment.editable}
                id={"privateSegment"}
                name={"privateSegment"}
                value={detailSegment.privateSegment}
                onBlur={() => {}}
                checked={detailSegment.privateSegment}
                onChange={(isPrivateSegmentChecked, e) =>
                  this.handleSegmentType(isPrivateSegmentChecked)
                }
                labelHtml={
                  <div style={{color: "#4a4a4a", marginTop: "2px"}}>
                    Segment is only visible to me
                  </div>
                }
                validationOption={{
                  name: "privateSegment",
                  check: true,
                  required: true
                }}
              />
            </Form.Group>

            <Form.Group controlId="formBasicDropDown">
              <Form.Label className="mb-0">Segment Growth</Form.Label>
              <Form.Text className="custom-form-text my-2">Does this Segment contain good or bad customers?</Form.Text>
              <Select
                name={"segmentGrowthType"}
                value={detailSegment.customerSegmentGrowthType}
                optionList={segmentGrowthTypes}
                classNameSelect="select-control"
                classNameWrapper="form-control-custom base-select-wrapper"
                classNameContainer=""
                classNameOptionListContainer="select-control-option"
                classNameOptionListItem=""
                customStyleSelect={{}}
                customStyleWrapper={{}}
                customStyleContainer={{}}
                customStyleOptionListContainer={{}}
                customStyleOptionListItem={{}}
                onChange={(customerSegmentGrowthType, e) =>
                  this.handleSegmentGrowthType(customerSegmentGrowthType)
                }
                validationOption={{
                  name: "SegmentGrowthType",
                  check: true,
                  required: true
                }}
              />
            </Form.Group>
          </section>

          {enablePreview ? (
            <section className="right-list-area position-relative ">
              {isPreviewloading ? (
                <SegmentCustomersDataGrid
                  segmentId={this.props.currentSegmentId}
                  dataViewId={this.props.dataViewId}
                  customerList={this.state.CustomerList}
                  onCustomerDetail={this.props.onCustomerDetail}
                  isUpdateState={this.props.isUpdateState}
                />
              ) : (
                <Loader/>
              )}
            </section>
          ) : (
            <section className="right-list-area">
              <div className="content-body-top mt-3">

                {!detailSegment.editable && (
                  <h6>
                    <strong>Segment cannot be altered as it is currently used in live destinations:</strong>
                  </h6>)}

                {!detailSegment.editable && (
                  <ListGroup style={{marginBottom: "30px"}} className="list-group-flush"> {
                    destinationsLinks.map((card, key) => (
                      <ListGroupItem key={key}>
                        <Link
                          className="linkList"
                          to={{
                            pathname: "/destinations/view",
                            state: {
                              currentPage: 'Destinations',
                              selectedCard: card,
                              cardContent: card.cardContent,
                              segmentsIsLoaded: true,
                              prevPath: "/destinations/view"
                            }
                          }}
                        >
                          <div className="card-list">
                            <h6>{card.cardContent.segment.title}</h6>
                            <div className="sub-content">
                              <span>{card.cardContent.segment.segmentName}</span>
                              <br/>
                              Created{" "}
                              <strong>
                                {moment(card.cardContent.createdDate).format(
                                  "MMM DD, YYYY"
                                )}
                              </strong>
                            </div>
                          </div>

                          <div className="icon-wrap">
                            {!card.cardContent.enabled && (
                              <span className="icon-disable float-right"></span>
                            )}

                            <span className="icon-chevron-thin-right right-arrow"></span>
                          </div>
                        </Link>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                )}

                <h6>
                  <strong>Segment Conditions</strong>
                </h6>
                <ListGroup as="ul" className="c-match">
                  <ListGroup.Item as="li">
                    <label>
                      Find Customers that match
                      <Select
                        tabIndex="4"
                        id={"fieldType"}
                        name={"fieldType"}
                        value={detailSegment.ruleGroupAndOr}
                        disabled={!detailSegment.editable}
                        optionList={[
                          {id: "OR", name: "ANY"},
                          {id: "AND", name: "ALL"}
                        ]}
                        classNameSelect="select-control"
                        classNameWrapper="form-control select-control-wrapper"
                        classNameContainer="dropdown-without-title"
                        classNameOptionListContainer="select-control-option"
                        classNameOptionListItem=""
                        customStyleSelect={{}}
                        customStyleWrapper={{}}
                        customStyleContainer={{}}
                        customStyleOptionListContainer={{}}
                        customStyleOptionListItem={{}}
                        onChange={e => this.handleRule(e)}
                      />
                      <span> of the specified conditions </span>{" "}
                    </label>
                  </ListGroup.Item>
                </ListGroup>
              </div>

              {detailSegment.ruleGroups.map((ruleGroup, key) => (
                <RuleGroup
                  key={key}
                  groupIndex={key}
                  ruleGroup={ruleGroup}
                  dataSchema={dataSchema}
                  onGroupRuleChange={this.handleGroupRule}
                  onAttributeSelection={this.handleAttributeSelection}
                  onAddRule={this.handleAddRule}
                  onDeleteRule={this.handleDeleteRule}
                  onNewCancel={this.handleNewCancel}
                  onRuleMAtchCondition={this.handleRuleMatchCondidtion}
                  onRuleSave={this.handleRuleSave}
                  editable={detailSegment.editable}
                />
              ))}

              {this.checkGroupStatus().length <= 0 && (
                <Button
                  variant="secondary  mb-4 align-self-start add-link"
                  hidden={!detailSegment.editable}
                  onClick={this.handleAddGroup}
                >
                  {detailSegment.ruleGroups.length > 0
                    ? "+ Add Another Group"
                    : " + Add Rule Group"}
                </Button>
              )}

            </section>
          )}

          <section className="panel panel-default mb-0 device-info mt-5">
            <p className="panel-body text-center font-weight-bold">
              Not Supported in Small Devices
            </p>
          </section>
        </main>
      );
    } else {
      return <Loader/>;
    }
  }
}

export default NewSegment;
