import React, {Component, Fragment} from "react";
import {Textbox} from "react-inputs-validation";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import {ListGroup, ListGroupItem, OverlayTrigger, Tooltip} from "react-bootstrap";
import {debounce} from "lodash";
import CustomersAPI from "../../../../../services/customers.js";
import DestinationAPI from "../../../../../services/destination.js";
import moment from "moment";
import {
  filterAiAndDefineAiStatus,
  getAllCustomersSegment,
  getPluralEnding,
  getSegmentIconClass,
  getSegmentsSort
} from "../../../Customers/customerdata/segmentUtils";
import EnrichmentAPI from "../../../../../services/enrichments";
import Loader from "../../../../../components/Loader/loader";

const privateSegment = (
  <Tooltip>
    This cannot be used in a destination - as all users can access this
    destination, and therefore need access to the segment
  </Tooltip>

  // <Popover id="popover-private">
  //   <Popover.Title as="h3">Private Segment</Popover.Title>
  //   <Popover.Content>
  //     This cannot be used in a destination - as all users can access this
  //     destination, and therefore need access to the segment
  //   </Popover.Content>
  // </Popover>
);

class SelectSegment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      criteria: "",
      validate: false,
      hasTitleError: true,
      hasDescriptionError: true,
      reverseOrder: false,
      enrichment: {},
      segment: {
        title: "",
        description: "",
        segmentID: "",
        segmentName: "",
        createdDate: "",
        records: "",
        isAdded: false,
        editable: true,
        destinationsCount: 0,
        hasError: true
      },
      allAvailableSegments: [],
      availableSegments: [],
      destinationData: [],
      destinationTitles: [],
      showPrivatePopup: false,
      segmentsIsLoaded: false,
    };
  }
  componentDidMount() {
    let newDestination = this.props.newDestination;
    this.getSegments(newDestination?.segment?.segmentID);
    this.getDestination();
    this.getCustomerCount();

    if (newDestination.segment !== undefined) {
      this.setState({
        segment: newDestination.segment,
        hasTitleError: newDestination.segment.segmentName !== "" && false,
        hasDescriptionError: newDestination.segment.description !== "" && false
      });
      this.updatedSegment(newDestination.segment);
    }
  }
  componentWillReceiveProps(nextProp) {
    let newDestination = nextProp.newDestination;
    if (newDestination.segment !== undefined) {
      this.setState({
        segment: newDestination.segment,
        hasTitleError: newDestination.segment.segmentName !== "" && false,
        hasDescriptionError: newDestination.segment.description !== "" && false
      });
      this.updatedSegment(newDestination.segment);
    }
  }

  componentWillUnmount() {
    this.props.onPageChange("segment", this.state.segment);
  }

  getDestination = async () => {
    try {
      await DestinationAPI.getDestination().then(response => {
        let destinationData = response;
        let destinationTitles = this.state.destinationTitles;
        destinationData.map(item => {
          if (item.type === this.props.cardType) {
            destinationTitles.push({ title: item.title, id: item.id });
          }
          this.setState({ destinationData, destinationTitles });
        });
      });
    } catch (err) {}
  };

  getCustomerCount = () => {
    try {
      CustomersAPI.getCustomersCount().then(customersCount => {
        this.setState({
          customersCount: Number(customersCount).toLocaleString(
              navigator.language
          ) + " Record" + getPluralEnding(customersCount),
          allCountOfCustomerIsLoaded: true,
        })});
    } catch (err) {
    }
  };

  getSegments = async (currentSegmentId) => {
    try {
      await EnrichmentAPI.getEnrichmentMinimalData().then(enrichment => {
          CustomersAPI.getSegmentsWithNoStats().then(segmentsWithRules => {
            let availableSegments = [];
            segmentsWithRules.map(item => {
              availableSegments.push({
                segmentID: item.id,
                segmentName: item.name,
                createdDate: item.dateCreated,
                privateSegment: item.privateSegment,
                customerSegmentType: item.customerSegmentType,
                maxCustomersCount: item.maxCustomersCount,
                records:
                  Number(item.cachedCustomerCount).toLocaleString(
                    navigator.language
                  ) + " Record" + getPluralEnding(item.cachedCustomerCount),
                editable: item.editable,
                destinations: item.destinations,
                destinationsCount: 0, // _.get(item, "destinations", []).length, // not working for some reason
                isAdded: item.id === currentSegmentId && currentSegmentId,
              });
            });

            this.setState({
              enrichment,
              availableSegments,
              allAvailableSegments: availableSegments,
              segmentsIsLoaded: true,
            })
          })
        }
      );
    } catch
      (err) {
    }
  };

  updateTitle = value => {
    let segment = this.state.segment;
    segment.title = value;
    this.setState({ segment, validate: true });
  };

  updateDescription = value => {
    let segment = this.state.segment;
    segment.description = value;
    this.setState({ segment, validate: true });
  };

  // Check error in segment
  validatingSegment = () => {
    localStorage.setItem("checkChange", true);
    let segment = this.state.segment;
    if (
      this.state.hasTitleError ||
      this.state.hasDescriptionError ||
      this.state.segment.segmentID === ""
    ) {
      segment.hasError = true;
    } else {
      segment.hasError = false;
    }

    this.setState({ segment });
  };

  updatedSegment = segment => {
    setTimeout(() => {
      let availableSegments = this.state.availableSegments;
      availableSegments.filter(item => item.segmentID === segment.segmentID).forEach(s => s.isAdded);
      this.setState({ availableSegments });
    }, 1000);
  };

  selectSegment = (selectedSegment, index, isPrivate) => {
    if (!isPrivate) {
      localStorage.setItem("checkChange", true);
      //Updated Current Segment Start
      let segment = this.state.segment;
      let title = segment.title;
      let description = segment.description;
      let hasError = segment.hasError;
      let isAdded = true;
      let editable = segment.editable;
      let destinationsCount = segment.destinationsCount + 1;
      segment = { title, description, hasError, editable, destinationsCount, ...selectedSegment, isAdded };
      this.setState({ segment });
      //Updated Current Segment End

      //Updated Available Segment Selected Start
      let allAvailableSegments = this.state.allAvailableSegments;
      allAvailableSegments.map(s => {
        if (s.segmentID === selectedSegment.segmentID) {
          s.isAdded = true;
        } else if (s.isAdded) {
          s.isAdded = false;
          s.destinationsCount -= 1;
        }
      });

      this.setState({ allAvailableSegments }, this.validatingSegment);
      //Updated Available Segment Selected End
    }
  };

  // Filtering Segment List Start
  doSearch = async e => {
    let searchWhat = e.toLocaleLowerCase();
    var filteredSegment = this.state.allAvailableSegments.filter(s => s.segmentName.toLocaleLowerCase().includes(searchWhat));
    this.setState({ availableSegments: filteredSegment });
  };

  raiseDoSearchWhenUserStoppedTyping = debounce(() => {
    if (this.state.criteria.length > 0) {
      this.doSearch(this.state.criteria);
    } else {
      this.setState({ availableSegments: this.state.allAvailableSegments });
    }
  }, 500);

  onSearchStart = e => {
    this.setState({ criteria: e.currentTarget.value }, this.raiseDoSearchWhenUserStoppedTyping);
  };

  changeSegmentSort = () => {
    this.setState({ reverseOrder: !this.state.reverseOrder });
  };

  // Filtering Segment List End

  render() {
    const { validate, enrichment, availableSegments: segments, reverseOrder, segmentsIsLoaded,
        allCountOfCustomerIsLoaded, customersCount} = this.state;

    let availableSegments = [].concat(segments, enrichment.ENGAGEMENT, enrichment.SPEND_BRACKET);

    return (
      <div className="content-wrap">
        <div className="form-wrap">
          <form>
            <div className="form-group">
              <label htmlFor="title">Title</label>
              <Textbox
                ref={this.title}
                classNameInput="form-control"
                classNameContainer="custome-input"
                tabIndex="1"
                id={"title"}
                name="title"
                type="text"
                validate={validate}
                validationCallback={res =>
                  this.setState(
                    {
                      hasTitleError: res,
                      validate: false
                    },
                    () => this.validatingSegment()
                  )
                }
                value={this.state.segment.title}
                placeholder="Your title here"
                autoComplete="off"
                onChange={title => {
                  this.updateTitle(title);
                }}
                onBlur={e => {}}
                validationOption={{
                  name: "Title",
                  check: true,
                  required: true,
                  customFunc: res => {
                    //[Func].test if title is available in previous destination. Returns true or err message
                    if (
                      this.state.destinationTitles.filter(
                        titles =>
                          titles.title === res &&
                          (titles.id === this.state.segment.segmentID ||
                            this.state.segment.segmentID === "")
                      ).length > 0
                    ) {
                      return "Your title can't be duplicate";
                    }
                    return true;
                  }
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="title">Description</label>

              <Textbox
                ref={this.description}
                classNameInput="form-control"
                classNameContainer="custome-input"
                tabIndex="2"
                id={"description"}
                name="description"
                type="text"
                validate={validate}
                validationCallback={res =>
                  this.setState(
                    {
                      hasDescriptionError: res,
                      validate: false
                    },
                    () => this.validatingSegment()
                  )
                }
                value={this.state.segment.description}
                placeholder="Your description here"
                autoComplete="off"
                onChange={description => {
                  this.updateDescription(description);
                }}
                onBlur={e => {}}
                validationOption={{
                  name: "Description",
                  check: true,
                  required: true
                }}
              />
            </div>
          </form>
        </div>
        <hr />
        <div className="select-segment">
          <div className="segment-header">
            <div className="left-section">
              <label>Select a Segment</label>
              <p>
                {this.props.cardType === "STANDARD_SYNC"
                  ? "Choose the Customer segment to sync across platforms:"
                  : "Choose the Customer Segment you wish to configure recommendations for:"}
              </p>
            </div>
            <div className="right-section">
              <div className="search-div">
                <input
                  type="text"
                  className="search-text form-control"
                  placeholder="Search"
                  value={this.state.criteria}
                  onChange={this.onSearchStart}
                />
                <span onClick={this.changeSegmentSort}>
                  <i className="fa fa-sort" />
                </span>
              </div>
            </div>
          </div>

          <div className="segment-body segment-body-with-loading">
            {!segmentsIsLoaded ? <Loader message="Fetching segment data"/> :
                <ListGroup className="list-group-flush">
                  {availableSegments.sort(getSegmentsSort(reverseOrder)).map((segment, key) => (
                      <ListGroupItem
                          key={key}
                          onClick={() => this.selectSegment(segment, key, segment.privateSegment)}
                          className={segment.privateSegment ? "no-hover" : ""}
                      >
                        {segment.privateSegment ? (
                            <OverlayTrigger
                                placement="right"
                                rootClose="true"
                                overlay={privateSegment}
                            >
                              <span className={getSegmentIconClass(segment)}/>
                            </OverlayTrigger>
                        ) : (
                            <span className={getSegmentIconClass(segment, segment.isAdded === true)}/>
                        )}

                        <div className="segmentData">
                          <label> {segment.segmentName}</label>

                          {segment.createdDate && (
                              <span className="date">
                                Created {moment(segment.createdDate).format("MMM DD, YYYY")}
                              </span>
                          )}

                          <span className="records">
                            {(!allCountOfCustomerIsLoaded && segment.customerSegmentType === 'ALL_CUSTOMERS') ?
                                (<React.Fragment><span className="icon-distil-logo icon spin"/> Records</React.Fragment>) :
                             (allCountOfCustomerIsLoaded && segment.customerSegmentType === 'ALL_CUSTOMERS') ?
                                 <React.Fragment>{customersCount}</React.Fragment>: (segment.records)}
                          </span>
                          {segment.isAdded && (
                              <span className="check-ico">
                                <i className="fa fa-check"/>
                              </span>
                          )}
                        </div>
                      </ListGroupItem>
                  ))}
                </ListGroup>}
          </div>
        </div>
      </div>
    );
  }
}

export default SelectSegment;
