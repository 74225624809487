import React, { Component } from "react";
import { ListGroup, Modal, Button, Row, Col, Form } from "react-bootstrap";
import DataConnectionAPI from "../../../../../services/dataConnections";
import { toast } from 'react-toastify';

class AttributeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      isLoading: false,
      searchItem: ""
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {
    this.getDataSourceAttr();
  }
  getDataSourceAttr = async event => {
    try {
      await DataConnectionAPI.getDataSourceAttr(
        this.props.content.connectionId,
        this.props.content.id
      ).then(response => {
        this.setState({
          dataSource: response,
          filtered: response.attributes,
          isLoading: true
        });
      });
    } catch (err) {}
  };

  updateDataSourceAttr = async (event) => {
  
    try {
      await DataConnectionAPI.updateDataSourceAttr(this.state.dataSource).then(
        response => {
          this.setState({
            dataSource: response,
            isLoading: true
          });
          this.props.onAttrUpdate(response);
          toast.success("Attributes Updated Successfully")
          return this.props.onCloseModal();
        }
      );
    } catch (err) {}
  };

  toggleAttr = attributeId => {
    if (this.state.isLoading) {
      const dataSource = this.state.dataSource;

      const attrIndex = dataSource.attributes.findIndex(
        item => item.id === attributeId
      );
      dataSource.attributes[attrIndex].syncAttribute
        ? (dataSource.attributes[attrIndex].syncAttribute = false)
        : (dataSource.attributes[attrIndex].syncAttribute = true);

      this.setState({ dataSource });
    }
  };

  sortUnsyncAtt() {
    if (this.state.isLoading) {
      const unsyncAtt = this.state.filtered.filter(
        item => item.syncAttribute === false
      );

      if (unsyncAtt.length > 0) {
        return (
          <ListGroup as="ul">
            {unsyncAtt.map(list => (
              <ListGroup.Item
                as="li"
                className="position-relative border-0"
                key={list.id}
                onClick={() => this.toggleAttr(list.id)}
              >
                <i className="icon-check_circle position-absolute" />{" "}
                {list.attributeDisplayName}
              </ListGroup.Item>
            ))}
          </ListGroup>
        );
      } else {
        return "All Selected";
      }
    }
  }
  sortSyncAtt() {
    if (this.state.isLoading) {
      const syncAtt = this.state.filtered.filter(
        item => item.syncAttribute === true
      );

      if (syncAtt.length > 0) {
        return (
          <ListGroup as="ul">
            {syncAtt.map(list => (
              <ListGroup.Item
                as="li"
                className="position-relative border-0"
                key={list.id}
                onClick={() => this.toggleAttr(list.id)}
              >
                <i className="icon-check_circle position-absolute active" />{" "}
                {list.attributeDisplayName}
              </ListGroup.Item>
            ))}
          </ListGroup>
        );
      } else {
        return "Nothing Selected";
      }
    }
  }
  handleChange(e) {
  
    let newList = [];

    // If the search bar isn't empty
    if (e.target.value !== "") {
        newList = this.state.dataSource.attributes.filter(item => {
        const lc = item.attributeDisplayName.toLowerCase();
        const filter = e.target.value.toLowerCase();
        if (lc !== undefined) {
         return lc.includes(filter);
        }
      });
    } else {
     
      newList = this.state.dataSource.attributes;
    }
    this.setState({
      filtered: newList
    });
  }

  render() {
    return (
      <Modal
        className="model-attribute"
        show={this.props.modalstate}
        onHide={this.props.onCloseModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="border-bottom-0 pb-0">
          <Modal.Title className="" id="contained-modal-title-vcenter ">
            Choose Attributes
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <p className="mb-3 pl-3">{this.props.content.name}</p>
          <Form className="mb-2">
            <Row>
              <Col xs={12} sm={6}>
                <Form.Control
                  onChange={this.handleChange}
                  placeholder="Search..."
                />
              </Col>
            </Row>
          </Form>
          <Row>
            <Col xs={12} sm={6}>
              <section className="simple-attribute">
                {this.sortUnsyncAtt()}
              </section>
            </Col>
            <Col xs={12} sm={6}>
              <section className=" active-attribute">
                <h5>Selected Attributes</h5>
                {this.sortSyncAtt()}
              </section>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="pl-0">
          <Button
            onClick={() => this.updateDataSourceAttr()}
            className="border-0 btn-save"
          >
            Save Changes
          </Button>
          <Button
            onClick={this.props.onCloseModal}
            className="border-0 btn-cancel"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AttributeModal;
