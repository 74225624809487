import React, {Component} from "react";
import {Button, Col, Form, ListGroup, Modal} from "react-bootstrap";
import {Select} from "react-inputs-validation";
import DatePicker from "react-datepicker";
import moment from "moment";

import {debounce} from "lodash";
import "react-datepicker/dist/react-datepicker.css";
import CustomersAPI from "../../../../../services/customers";
import {attributesTypes, calculatedValues} from "../../../../../variables/globalValues.json";
import {AsyncTypeahead} from "react-bootstrap-typeahead";


class Rule extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onDataChange = this.handleDateChange.bind(this);
  }
  state = {
    editRule: false,
    schemaOptions: "",
    attributeType: "",
    conditionType: "",
    matchCondition: "",
    isLoadingAutoSuggest: false,
    autoSuggestOptions: [],
    fieldValue: "",
    currentAttribute: "",
    dataSourceId: "",
    startDate: new Date(),
    show: false,
    errors: {
      fieldValue: "",
      conditionType: ""
    },
    validated: false
  };
  componentWillMount() {
     this.setState({
      attributeType: this.props.rule.dataSourceAttributeType,
      currentAttribute: this.props.rule.dataSourceAttributeDisplayName,
      currentAttributeId: this.props.rule.dataSourceAttributeId,
      conditionType: this.props.rule.matchCondition,
      attributeDataTag: this.props.rule.attributeDataTag,
      currentDistilAttribute: this.props.rule.dataSourceAttributeDistilName,
      dataSourceId: this.props.rule.dataSourceId,
      fieldValue: this.props.rule.value || "",
      dataSchema: this.props.dataSchema,
      new:this.props.rule.new
    });
  }
  componentWillReceiveProps( prevProps){

  }

  onEdit = () => {
    this.setState({ editRule: true });
  };
  onCancel = (ruleIndex, groupIndex) => {


    if ( this.state.new ) {
      this.props.onDeleteRule(ruleIndex, groupIndex);
    } else {
      this.setState({
            attributeType: this.props.rule.dataSourceAttributeType,
            currentAttribute: this.props.rule.dataSourceAttributeDisplayName,
            conditionType: this.props.rule.matchCondition,
            fieldValue: this.props.rule.value || "",
            currentAttributeId: this.props.rule.dataSourceAttributeId,
            attributeDataTag: this.props.rule.attributeDataTag,
            editRule: false
          });

    }

  };
  onSave = () => {
    this.setState({ editRule: false });

    let rule = {
      dataSourceAttributeDisplayName: this.state.currentAttribute,
      dataSourceAttributeDistilName: this.state.currentDistilAttribute,
      dataSourceAttributeId: this.state.currentAttributeId,
      dataSourceAttributeType: this.state.attributeType,
      matchCondition: this.state.conditionType,
      dataSourceId: this.state.dataSourceId,
      value: this.state.fieldValue,
      id: this.state.currentAttributeId,
      attributeDataTag: this.state.attributeDataTag,
      new:false
    };
    let ruleIndex = this.props.ruleIndex;
    let groupIndex = this.props.groupIndex;
    this.props.onRuleSave(rule, groupIndex, ruleIndex);
  };

  handleAttributeSelect = attribute => {
    // const { groupIndex, ruleIndex, onAttributeSelection } = this.props;
    this.handleClose();
    const errors = this.state.errors;
    errors.fieldValue = "";
    this.setState({
      attributeType: attribute.attributeType,
      attributeDataTag: attribute.attributeDataTag,
      conditionType: "",
      dataSourceId: attribute.dataSourceId,
      currentAttribute: attribute.displayName,
      currentDistilAttribute: attribute.distilName,
      currentAttributeId: attribute.id,
      fieldValue: "",
      errors: errors
    });
  };

  handleSearchAll = e => {
    let filteredResult = [];
    let dataSchema = { ...this.props.dataSchema };
    let schemaSections = dataSchema.schemaSections;

    if (e.target.value !== "") {
      for (let i = 0; i < schemaSections.length; i++) {
        if (
          schemaSections[i].sectionName
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          filteredResult.push(schemaSections[i]);
        } else {
          let schemaSectionsItem = { ...schemaSections[i] };
          schemaSectionsItem.attributes = [];

          let attributes = [];
          for (let j = 0; j < schemaSections[i].attributes.length; j++) {
            if (
              schemaSections[i].attributes[j].displayName
                .toLowerCase()
                .includes(e.target.value.toLowerCase())
            ) {
              attributes.push(schemaSections[i].attributes[j]);
            }
          }
          schemaSectionsItem.attributes = attributes;
          if (attributes.length > 0) {
            filteredResult.push(schemaSectionsItem);
          }
        }
      }
    } else {
      filteredResult = this.props.dataSchema.schemaSections;
    }
    dataSchema.schemaSections = filteredResult;
    this.setState({
      dataSchema
    });
  };

  renderDataSchema() {
    if (
      this.state.dataSchema !== null &&
      this.state.dataSchema.schemaSections !== undefined
    ) {
      return (
        <ListGroup variant="flush" as="ul" className="d-block">
          <ListGroup as="ul" className="d-block">
            {this.state.dataSchema.schemaSections.filter(s => !s.disabled || s.usedInRule).map((item, key) => (
              <React.Fragment key={key}>
                <ListGroup.Item
                  as="li"
                  className={
                    item.isActive
                      ? "list_active position-relative"
                      : "position-relative notactive"
                  }
                >
                  <span>
                    {item.isActive && <i className="icon-check active" />}
                  </span>
                  <strong className={item?.disabled ? 'draw-negative' : ''}>{item.sectionName}</strong>
                </ListGroup.Item>
                {this.renderSchemaAttr(item, item.sectionName)}
              </React.Fragment>
            ))}
          </ListGroup>
        </ListGroup>
      );
    }
  }

  renderSchemaAttr(item, sectionName) {
    const attr = item.attributes;
    const isCoreData = sectionName === 'Core Data';

    if (attr.length > 0) {
      return (
        <ListGroup as="ul">
          {attr.filter(a => (isCoreData ? a.attributeDataTag !== 'GDPR_STATUSES_COUNT' : true))
            .filter(a => ((!item.disabled || a.usedInRule) && a.active) || (a.usedInRule && !a.active))
            .map((attribute, key) => (
            <ListGroup.Item
              as="li"
              onClick={() => this.handleAttributeSelect({...attribute, ...{dataSourceId: item.datasourceId}})}
              key={key}
              className={
                attribute.isActive
                  ? "list_active position-relative"
                  : "position-relative notactive"
                  + (item?.disabled || attribute?.active === false ? ' draw-negative' : '')
              }
            >
              {this.state.currentAttributeId === attribute.id && (
                <i className="icon-check position-absolute active" />
              )}
              {attribute.displayName}
            </ListGroup.Item>
          ))}
        </ListGroup>
      );
    }
  }

  handleDateChange(date) {
    const fieldValue = moment(date).format('DD/MM/YYYY');
    this.setState({ startDate: date, fieldValue });
  }

  handleDataChange = e => {
    this.setState({ fieldValue: e.target.value });
  };

  validate = () => {
    const errors = {};

    if (this.checkNotBlankType() && this.state.fieldValue === "") {
      errors.fieldValue = "Must not be empty";
    }

    if (this.state.conditionType === "") {
      errors.conditionType = " Condition is required";
    }
    if (this.state.errors !== null &&  this.state.errors.fieldValue === "Must be a Valid Email Address") {
      errors.fieldValue = "Must be a Valid Email Address";
    }

    return Object.keys(errors).length === 0 ? null : errors;
  };
  handleTextChange(e, type) {
    const regexp = new RegExp(`^-?[0-9]*$`);
    const deciType = new RegExp(`^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$`);
    const errors = this.state.errors;

    const newValue = e.target.value;
    if (type === "INTEGER") {
      if (!regexp.test(newValue)) {
        errors.fieldValue = "Must be a whole number";
      } else {
        this.setState({ fieldValue: newValue });
        errors.fieldValue = null;
      }
      this.setState({ errors });
    }
    else if (type === "DECIMAL" ) {
      if (!deciType.test(newValue)) {
        errors.fieldValue = "Must be a number";
      } else {
        this.setState({ fieldValue: newValue });
        errors.fieldValue = null;
      }
      this.setState({ errors });
    }
    else {
      this.setState({ fieldValue: newValue });
    }

  }

  renderTypeOptions() {
    return attributesTypes[this.state.attributeType || "ERROR"];
  }

  renderFieldType() {
    const { attributeType, fieldValue, validated, attributeDataTag } = this.state;

    switch (attributeType) {
      case "BOOLEAN":
        return (
          <Select
            tabIndex="4"
            id={"fieldType"}
            name={"fieldType"}
            value={fieldValue || ""}
            disabled={false}
            optionList={[
              { id: "", name: "Please Select Value" },
              { id: "TRUE", name: "TRUE" },
              { id: "FALSE", name: "FALSE" }
            ]}
            classNameSelect="select-control"
            classNameWrapper="form-control select-control-wrapper"
            classNameContainer=""
            classNameOptionListContainer="select-control-option"
            classNameOptionListItem=""
            customStyleSelect={{}}
            customStyleWrapper={{}}
            customStyleContainer={{}}
            customStyleOptionListContainer={{}}
            customStyleOptionListItem={{}}
            onChange={(condition, e) =>
              this.setState({ fieldValue: condition })
            }
            validationOption={{
              name: "Attribute Value",
              check: true,
              required: true
            }}
          />
        );

      case "ENUM":
        return (
          <Select
            tabIndex="5"
            id={"enumField"}
            name={"enumField"}
            value={fieldValue || ""}
            disabled={false}
            optionList={this.getTagOptionList(attributeDataTag)}
            classNameSelect="select-control"
            classNameWrapper="form-control select-control-wrapper"
            classNameContainer=""
            classNameOptionListContainer="select-control-option"
            classNameOptionListItem=""
            customStyleSelect={{}}
            customStyleWrapper={{}}
            customStyleContainer={{}}
            customStyleOptionListContainer={{}}
            customStyleOptionListItem={{}}
            onChange={(condition, e) =>
              this.setState({fieldValue: condition})
            }
            validationOption={{
              name: "Attribute Value",
              check: true,
              required: true
            }}
          />
        );

      case "DATE":
        let date = this.state.fieldValue === undefined || this.state.fieldValue === null || this.state.fieldValue === ''
            ? this.state.startDate
            : this.parseDate(this.state.fieldValue);

        return (
          <DatePicker
            selected={date}
            onChange={this.onDataChange}
            dateFormat="dd/MM/yyyy"

          />
        );

      case "DOUBLE":
      case "DECIMAL":
        return (
          <Form.Control
            className={this.state.errors !== null && this.state.errors.fieldValue && "error"}
            type="text"
            value={fieldValue}
            placeholder={"Please enter valid " + this.state.currentAttribute}
            onChange={e => this.handleTextChange(e, "DECIMAL")}
          />
        );

      case "INTEGER":
        return (
          <Form.Control
            type="text"
            value={fieldValue}
            validated={validated}
            placeholder={"Please enter valid " + this.state.currentAttribute}
            onChange={e => this.handleTextChange(e, "INTEGER")}
          />
        );

      case "LONG":
        return (
          <Form.Control
            type="text"
            value={fieldValue}
            placeholder={"Please enter valid " + this.state.currentAttribute}
            onChange={e => this.handleTextChange(e, "INTEGER")}
          />
        );

      case "STRING":
      case "TEXT":
        return (
            <AsyncTypeahead
                minLength={2}
                onInputChange={v => {
                  if(v === '' || v.length < 2) {
                    this.setState({fieldValue: v});
                  }
                }}
                renderMenuItemChildren={(option, props, index) => {
                  return (<div className={"autosuggest-dropdown-item text-ellipsis"}>
                    {
                      this.renderHighlightItem(option, props)
                    }
                  </div>)
                }}
                onChange={([value]) => {
                  this.handleTextChange({target: {value}}, "STRING");
                }}
                filterBy={(option, props) => {
                  const str = option.toLowerCase().replace(/\s+/g, ' ').trim();
                  const search = props.text.toLowerCase().replace(/\s+/g, ' ').trim();

                  return str.indexOf(search) > -1;
                }}
                onBlur={(event) => {
                  this.handleTextChange(event, "STRING");
                }}
                defaultInputValue={this.state.fieldValue}
                autoFocus={true}
                onSearch={debounce(this.segmentationFieldAutoSuggest.bind(this), 200)}
                options={this.state.autoSuggestOptions}
                isLoading={this.state.isLoadingAutoSuggest}
                id="basic-behaviors-example"
                labelKey="name"
                promptText={"Searching the best option for you"}
                placeholder="Start typing..."
            />);
    }
  }

  getTagOptionList(attributeDataTag) {
    return calculatedValues[attributeDataTag || "ERROR"];
  }

  renderHighlightItem(option, props) {
    let indexOf = option.indexOf(props.text);

    if(indexOf === -1) {
      //that weird but why not
      return option;
    } else {
      option.substring(0, indexOf);

      return (
        <div className={'text-ellipsis'}>
          {option.substring(0, indexOf)}
          <span className={'highlight'}>{option.substring(indexOf, indexOf + props.text.length)}</span>
          {option.substring(indexOf + props.text.length, option.length)}
        </div>)
    }
  }

  checkNotBlankType() {
    const { conditionType, attributeType } = this.state;
    return conditionType !== "IS_BLANK"
        && conditionType !== "IS_NOT_BLANK"
        && attributeType !== "CUSTOMER_TRAIT";
  }

  renderFieldValue(fieldValue, attributeType, attributeDataTag) {
    if (attributeType === 'ENUM') {
      return this.getTagOptionList(attributeDataTag).find(e => e.id === fieldValue)?.name || "ERROR";
    } else {
      return fieldValue;
    }
  }

  renderCondition(conditionType) {
    return Object.values(attributesTypes).flatMap(t => t).find(type => type.id === conditionType)?.name || "ERROR";
  }

  handleClose() {
    this.setState({ show: false, dataSchema: this.props.dataSchema });
  }

  handleShow() {
    this.setState({ show: true });
    setTimeout(() => {
      this.mySearch.focus();
    }, 100);
  }

  handleChange = (conditionType, e) => {
    const { attributeType } = this.state;

    if (attributeType !== "TEXT" && attributeType !== "STRING") {
      this.setState({ fieldValue: "" });
    }
    this.setState({ conditionType });
  };

  parseDate = (date) => {
    let dateArray = date.split("/");

    if (dateArray.length < 3) {
      return new Date();
    }
    // dateArray[1] - 1: because the month as a number between 0 and 11 (January to December)
    return new Date(dateArray[2], dateArray[1] - 1, dateArray[0]);
  }

  render() {
    const {
      rule,
      onDeleteRule,
      groupIndex,
      ruleIndex,
      editable,
    } = this.props;
    const {
      editRule,
      currentAttribute,
      fieldValue,
      attributeType,
      attributeDataTag,
      conditionType,
      dataSourceId,
      dataSchema,
      currentAttributeId
    } = this.state;

    const ds = dataSchema?.schemaSections.find(d => d.datasourceId === dataSourceId);
    const attr = ds?.attributes.find(a => a.id === currentAttributeId);

    if (!editRule && !rule.new) {
      return (
        <React.Fragment>
          <ListGroup.Item as="li">
            <p className="m-0">
              <strong className={ds?.disabled || attr?.active === false ? 'draw-negative' : ''}>{ds?.sectionName}.{currentAttribute}</strong>
              <span> {this.renderCondition(conditionType)} </span>{" "}
              <strong>
                { !!conditionType && this.checkNotBlankType() && this.renderFieldValue(fieldValue, attributeType, attributeDataTag)}
              </strong>{" "}
            </p>

            <dl className="d-flex ml-auto mb-0 section-icon">
              <dt>
                <Button variant="secondary" hidden={!editable} onClick={() => this.onEdit()}>
                  <i className="icon-edit-light l-edit" />
                </Button>
              </dt>
              <dd>
                <Button
                  variant="secondary"
                  hidden={!editable}
                  onClick={() => onDeleteRule(ruleIndex, groupIndex)}
                >
                  <i className="icon-delete-light l-delete" />
                </Button>
              </dd>
            </dl>
          </ListGroup.Item>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Modal
            show={this.state.show}
            className="select-attribute"
            onHide={this.handleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Select Attribute</Modal.Title>
              <div className="search position-relative">
                <span className="icon-search" />
                <Form.Control
                  ref={input => {
                    this.mySearch = input;
                  }}
                  type="text"
                  onChange={e => this.handleSearchAll(e)}
                  placeholder="Search here..."
                />
              </div>
            </Modal.Header>
            <Modal.Body> {this.renderDataSchema()}</Modal.Body>
          </Modal>

          <ListGroup.Item as="li" className="bg-light">
            <Form.Row className="col-md-10">
              <Form.Group
                as={Col}
                className="mb-0 position-relative purchase-field d-flex"
              >
                <Form.Control
                  type="text"
                  readOnly
                  placeholder="Please Select a Attribute"
                  value={ this.state.currentAttribute || ""}

                  onClick={() => this.handleShow()}
                />
                <Button
                  onClick={() => this.handleShow()}
                  className="btn btn-default"
                >
                  <i className="icon-dots-three-horizontal" />
                </Button>
              </Form.Group>
              <Form.Group as={Col} className="mb-0">
                {this.state.currentAttributeId !== null && (
                  <Select
                    tabIndex="4"
                    id={"attributeType"}
                    name={"attributeType"}
                    value={this.state.conditionType || ""}
                    disabled={false}
                    optionList={this.renderTypeOptions()}
                    classNameSelect="select-control"
                    classNameWrapper="form-control select-control-wrapper"
                    classNameContainer=""
                    classNameOptionListContainer="select-control-option"
                    classNameOptionListItem=""
                    customStyleSelect={{}}
                    customStyleWrapper={{}}
                    customStyleContainer={{}}
                    customStyleOptionListContainer={{}}
                    customStyleOptionListItem={{}}
                    onChange={(attributeType, e) =>
                      this.handleChange(attributeType, e)
                    }
                    onBlur={() => {}}
                    validationOption={{
                      name: "Condition Type",
                      check: true,
                      required: true
                    }}
                  />
                )}
                {this.state.errors !== null &&
                  this.state.errors.conditionType !== "" && (
                    <span className="text-danger">
                      {this.state.errors.conditionType}
                    </span>
                  )}
              </Form.Group>
              {
                <Form.Group
                  as={Col}
                  className={
                    this.state.errors !== null &&
                    this.state.errors.fieldValue !== ""
                      ? "mb-0"
                      : "mb-0 error"
                  }
                >
                  { !!conditionType && this.checkNotBlankType() && this.renderFieldType()}
                  {this.state.errors !== null &&
                    this.state.errors.fieldValue !== "" && this.state.errors.fieldValue !== null && (
                      <span className="text-danger">
                        {this.state.errors.fieldValue}
                      </span>
                    )}
                </Form.Group>
              }
            </Form.Row>
            <dl className="d-flex ml-auto mb-0 section-icon">
              <dt>
                <Button
                  variant="secondary"
                  disabled={this.validate()}
                  onClick={() => this.onSave()}
                >
                  {this.validate() && <i className="icon-disable" />}
                  <strong className="save">Save</strong>
                </Button>
              </dt>
              <dd>
                <Button
                  variant="secondary"
                  onClick={() => this.onCancel(ruleIndex, groupIndex)}
                >
                  Cancel
                </Button>
              </dd>
            </dl>
          </ListGroup.Item>
        </React.Fragment>
      );
    }
  }

  segmentationFieldAutoSuggest = async value => {

    this.handleTextChange({target: {value}}, "STRING");
    this.setState({isLoadingAutoSuggest: true});

    return CustomersAPI.segmentationFieldAutoSuggest(
        {
          dataSourceId: this.state.dataSourceId,
          dataSourceAttrId: this.state.currentAttributeId,
          searchPhrase: this.state.fieldValue
        }
    ).then(value => this.setState({isLoadingAutoSuggest: false, autoSuggestOptions: value}))
     .catch(reason => {
      this.setState({autoSuggestOptions: [], isLoadingAutoSuggest: false});
    });
  };
}

export default Rule;
