import ApiService from './tenantApiService';

function updateAttr(connectionId, newAttr) {
    const requestUrl = "/connection/" + connectionId + "/zapier/attributes";
    return ApiService.put(requestUrl, newAttr).then(response => {
        return response.data;
    });
}

export default {
    updateAttr
};