import ApiService from "./tenantApiService";
 
 
function searchData(dataToSearch) {
  ApiService.defaults.headers.post["Content-Type"] = "text/plain";
   
  const requestUrl =  "/search";

  return ApiService.post(requestUrl, dataToSearch).then(response => {
    return response.data;
  });
}

export default {
  searchData
};
