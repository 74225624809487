import React, { Component } from "react";
import moment from "moment";
import { globalConstants } from "../../variables/globalVariables";

class BottomWrap extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="bottom-wrap">
        <div className="right-text light-black-text-40">
          <p>
              
            ©2018–{moment().format("YYYY")} All Rights Reserved. Distil.ai® is a registered trademark
            of Big Business Intelligence Limited.{" "}
            <a href={globalConstants.HELP_BASE_URL + "/cookie-policy/"} target="_blank">
              Cookie Preferences
            </a>
            ,{" "}
            <a href={globalConstants.HELP_BASE_URL + "/privacy-policy/"} target="_blank">
              Privacy
            </a>
            , and{" "}
            <a href={globalConstants.HELP_BASE_URL + "/terms-of-use/"} target="_blank">
              Terms
            </a>
            .
          </p>
        </div>
      </div>
    );
  }
}

export default BottomWrap;
