import React, {Component} from "react";
import RuleGroup from "../segment/ruleGroup";
import * as moment from "moment";
import {ListGroup} from "react-bootstrap";
import {Select} from "react-inputs-validation";
import {areDisabledAttributesUsed, getSegmentGrowthType} from "../segmentUtils";
import {toast} from "react-toastify";

class AnalyticsRules extends Component {

  getDaysFromNow(date) {
    return moment().diff(moment(date).startOf('day'), 'days');
  }

  printMembership(date, name, action) {
    return date ?
      <p>{action} {name && 'by '} {name && <strong>{name}</strong>} on <strong>{moment.unix(date / 1000)
        .format('Do MMMM YYYY')}</strong></p> : '';
  }

  constructor(props) {
    super(props)
    if (areDisabledAttributesUsed(props.segment.ruleGroups, props.dataSchema)) {
      toast.warn('Attributes highlighted in red are not currently being updated');
    }
  }

  render() {
    let {segment, dataSchema} = this.props;

    return (
      <React.Fragment>
        <div className="segment-rules-info secondary-font">
          {segment.customerSegmentGrowthType !== 'SIZE_DOES_NOT_MATTER' ?
            <strong>You are trying to {getSegmentGrowthType(segment.customerSegmentGrowthType)} this Segment</strong> :
            <strong>Segment growth does not matter</strong>
          }
          {segment.customerSegmentType === 'AI' ? 
            (<React.Fragment><p><strong>A.I. Segment</strong></p></React.Fragment>)
              :            
            (<React.Fragment>
              <p className="pt-3">{this.getDaysFromNow(segment.dateCreated)} day(s)
                old <strong>{segment.privateSegment ? 'Private' : 'Shared'} Segment</strong></p>
              {this.printMembership(segment.dateCreated, segment.createdByUserName, 'Created')}
              {segment.dateCreated !== segment.dateModified &&
                this.printMembership(segment.dateModified, segment.modifiedByUserName, 'Last updated')}
            </React.Fragment>)
          }
        </div>

        <main className={`position-relative area-main secondary-font add-segment pt-0 h-auto`}>

          <section className={`right-list-area m-0 h-auto flex-grow-0 ${segment.customerSegmentType === 'ALL_CUSTOMERS' && 'd-none'}`}>
            <div className="content-body-top mt-3 pl-3 d-block">
              <h6>
                <strong>Segment Rules</strong>
              </h6>
              <ListGroup as="ul" className="c-match">
                <ListGroup.Item as="li">
                  <label>
                    Find Customers that match
                    <Select
                      tabIndex="4"
                      id={"fieldType"}
                      name={"fieldType"}
                      value={segment.ruleGroupAndOr || "AND"}
                      disabled={true}
                      optionList={[
                        {id: "OR", name: "ANY"},
                        {id: "AND", name: "ALL"}
                      ]}
                      classNameSelect="select-control"
                      classNameWrapper="form-control select-control-wrapper"
                      classNameContainer="dropdown-without-title"
                      classNameOptionListContainer="select-control-option"
                    />
                    <span> of the specified conditions </span>{" "}
                  </label>
                </ListGroup.Item>
              </ListGroup>
            </div>
            <div className="pl-3">

            {segment.ruleGroups.map((ruleGroup, key) => (
              <RuleGroup
                key={key}
                groupIndex={key}
                ruleGroup={ruleGroup}
                dataSchema={dataSchema}
                editable={false}
              />
            ))}
            </div>
          </section>
        </main>
      </React.Fragment>
    );
  }
}

export default AnalyticsRules;
