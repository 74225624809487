import React, {Component} from "react";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import BottomWrap from "../../../components/BottomWrap/BottomWrap";
import {Route} from "react-router-dom";
import {getUrlVars} from "../../Main/Customers/customerdata/segmentUtils";
import AccountApi from "../../../services/account";
import AuthenticationService from "../../../services/authentication";
import checkIcon from "../../../assets/img/check-mark-icon.svg";
import destinations from "./assets/img/destinations.svg";
import "./assets/css/styles.scss";
import "../../../assets/css/_empty-pages.scss";

class Activate extends Component {
  constructor(props) {
    super(props);
    this.myEmail = React.createRef();
    this.myPassword = React.createRef();
    this.state = {
      lockBtn: true
    }
  }

  doActivate = async (history) => {
    JSON.parse(sessionStorage.getItem("registeredShopUser"));
    AccountApi.activateOrg(sessionStorage.getItem("shopifyShop")).then(_ => {
      AuthenticationService.loginFromShopify(sessionStorage.getItem("shopToken")).then(res => {
        let registeredShopUser = JSON.parse(sessionStorage.getItem("registeredShopUser"));
        if (registeredShopUser === undefined || registeredShopUser === null || registeredShopUser.firstName === null
          || registeredShopUser.lastName === null|| registeredShopUser.marketingEmailAddress === null) {
          window.location = "/update"
        } else {
          window.location = "/"
        }
      })
    })
  }

  componentDidMount() {
    document.title = "Distil - Activate your Account";
    AuthenticationService.resetOldSession();
    this.getUserData();
  }

  getUserData = async () => {
    try {
      let vars = getUrlVars(window.location.href);
      let data = JSON.parse(atob(decodeURIComponent(vars?.shopifyUser)));
      console.log("TEST user info: ", data);
      await AuthenticationService.checkShopifyUser(data.token, data.userId).then(response => {
        console.log("TEST user check response: ", response);

        sessionStorage.setItem("firstName", response?.firstName === null ? "" : response?.firstName);
        sessionStorage.setItem("lastName", response?.lastName === null ? "" : response?.lastName);
        sessionStorage.setItem("email", response?.emailAddress === null ? "" : response?.emailAddress);
        sessionStorage.setItem("isShopify", "true");
        sessionStorage.setItem("shopifyShop", response.shop);
        sessionStorage.setItem("shopToken", data.token.toString())
        this.setState({lockBtn: false})
      });
    } catch (err) {
      // console.log(err);
    }
  };

  render() {
    return (
      <div className="content-wrap bg-white lock-screen-component activate-screen-component">
        <div className="content-inner-wrap content-inner-wrap__activate
         col-xl-9 col-md-11 content-inner-wrap-activate">
          <div className="content">
            <div className="inner-content">
              <div className="top-wrap">
                <h3 className="font-weight-Avenirmedium mb-4">Activate your Customer Data</h3>
                <div className="font-on-top primary-font">
                  <p>
                    Unlock the data that is within your business. Find out what your Customers are really like,
                    understand how each channel is driving each stage in your funnel and get tailored analytics,
                    made-to-measure, for your business.
                  </p>
                  <p>
                    <b>It’s your data, let’s make it work for you.</b>
                  </p>
                </div>
              </div>

              <div className="form-wrap">
                  <Route render={({history}) => (
                    <button disabled={this.state.lockBtn}
                        type="submit"
                              onClick={() => {
                                this.doActivate(history)
                              }}
                              className="btn btn-primary black-btn"
                      >
                        Explore Distil Data Platform
                      </button>
                  )}/>
              </div>
              <div className="content-items">
                <div className="content-item">
                  <div className="content-item-icon">
                    <img src={checkIcon} alt="checkIcon"/>
                  </div>
                  <div>
                    <p className="content-item-text">
                      <b>Full Funnel Visualisation</b> - showing how each channel acquires, converts and retains.
                    </p>
                  </div>
                </div>
                <div className="content-item">
                  <div className="content-item-icon">
                    <img src={checkIcon} alt="checkIcon"/>
                  </div>
                  <div>
                    <p className="content-item-text">
                      <b>Customer Data Studio</b> - giving you that elusive Single Customer
                      View across all your platforms so you can Segment, Tag and Organise all your Customer Data.
                    </p>
                  </div>
                </div>
                <div className="content-item">
                  <div className="content-item-icon">
                    <img src={checkIcon} alt="checkIcon"/>
                  </div>
                  <div>
                    <p className="content-item-text">
                      <b>Data Destinations</b> that let you activate multi-channel journeys,
                      push your data into analytical tools and deploy personalisation
                      strategies that drive revenue and growth.
                    </p>
                  </div>
                </div>
                <div className="content-item">
                  <div className="content-item-icon">
                    <img src={checkIcon} alt="checkIcon"/>
                  </div>
                  <div>
                    <p className="content-item-text">
                      <b>Tailored Analytics</b> your data, your way.
                      Staying on top of your key metrics, custom dashboard development,
                      answer specific questions or help with your data strategy that help
                      you compete with much larger rivals, we’re here to help.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="content-image">
              <img src={destinations} alt="destinations"/>
            </div>
          </div>
          <BottomWrap/>
        </div>
      </div>
    );
  }
}

export default Activate;
