import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from "moment";
import {faPlay} from "@fortawesome/free-solid-svg-icons";
import "./assets/css/analytic-card.scss";
import {Col, Container, Row} from "react-bootstrap";
import Monday from "./components/Monday";
import Tuesday from "./components/Tuesday";
import Wednesday from "./components/Wednesday";
import Thursday from "./components/ThursdayMinimal";
import Friday from "./components/Friday";
import CardLoader from "./components/CardLoader";
import equal from 'fast-deep-equal';
import ShopifyAPI from "../../../services/shopify";
import * as Sentry from "@sentry/react";
import { shopProperties } from "./utils";

class AnalyticCard extends Component {

    constructor(props) {
        super(props);
        let selectedMembers = JSON.parse(sessionStorage.getItem("selectedMembers"));

        this.state = {
            selectedMembers: selectedMembers,
            currentDate: '',
            date: "",
            nextButtonActive: false,
            previousButtonActive: false,
            previousButtonPressed: false,
            nextButtonPressed: false,
            showLoader: true,
            isFirstLoading: true,
            briefDate: "",
            cardData: {},
            error: '',
        };

    }

    // GLOBAL INIT FOR CARDS
    componentDidMount() {
        this.addDate();
        this.getDate();
    }

    addDate() {
        const { cardHeader } = this.props;
        const { isFirstLoading } = this.state;

        if (cardHeader.date) {

            if (isFirstLoading) {
                this.setState( {
                    isFirstLoading: false,
                    currentDate: cardHeader.date
                });
            }
            shopProperties.currency = cardHeader.properties?.currency ?? '£';
        }
    }
    getDate() {
        const { currentDate, selectedMembers, previousButtonPressed, nextButtonPressed } = this.state;

        if(currentDate && currentDate !== '') {
            this.setState({
                date: moment(currentDate).format("dddd Do MMMM YYYY"),
                briefDate: moment(currentDate).format("Do MMM YY"),
            })
            const fetchGetCardData = async () => {
                ShopifyAPI.getDataDailyInsight(currentDate).then((response) => {
                    if(response.card?.data)
                    {
                        this.setState({
                            cardData: { ...response.card },
                            showLoader: false
                        });
                    }
                    else if (response.card?.meta){
                        let selectedMember = JSON.parse(sessionStorage.getItem("selectedMembers"));
                        Sentry.setContext("CardDetails", {
                            missingDate: currentDate,
                            tenant: selectedMember.tenantCode,
                            orgName: selectedMember.orgName,
                            orgId: selectedMember.id
                        });
                        Sentry.setTag("missingDate", currentDate);
                        Sentry.setTag("tenant", selectedMember.tenantCode);
                        Sentry.setTag("orgName", selectedMember.orgName);
                        Sentry.setTag("orgId", selectedMember.id);
                        console.error("No Card data available for date: ", currentDate);

                        if(previousButtonPressed) {
                            this.getDataCardDatePrevious(moment(currentDate).subtract(1, "days").format("YYYY-MM-DD"))
                        }
                        else{
                            this.getDataCardDateNext(moment(currentDate).add(1, "days").format("YYYY-MM-DD"))
                        }
                    }
                });
            };

            const weekday = moment(currentDate).isoWeekday();
            if(weekday === 6 || weekday === 7) {
                this.setState({
                    showLoader: true,
                })
                this.skipWeekend(weekday, currentDate);
                return;
            }
            fetchGetCardData();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!equal(this.props.cardHeader, prevProps.cardHeader) || !equal(this.state.isFirstLoading, prevState.isFirstLoading)) {
            this.addDate();
        }
        if (!equal(this.state.currentDate, prevState.currentDate)) {
            this.getDate();
        }
        if (!equal(this.state.cardData, prevState.cardData) || !equal(this.props.error, prevProps.error)) {
            const { error } = this.props;
            const { cardData } = this.state;
            if (!!cardData.meta && error === "") {
                this.setState({
                    nextButtonActive: cardData.meta.hasNext,
                    previousButtonActive: cardData.meta.hasPrevious,
                })
            } else if (error !== "") {
                // Just to be able moving next or prev page
                this.setState({
                    nextButtonActive: true,
                    previousButtonActive: true,
                })
            }
        }
    }


    getDataCardDatePrevious = (date) => {
        this.setState({
            nextButtonPressed: false,
            previousButtonPressed: true,
            currentDate: date,
            showLoader: true,
        })
    };

    getDataCardDateNext = (date) => {
        this.setState({
            previousButtonPressed: false,
            nextButtonPressed: true,
            currentDate: date,
            showLoader: true,
        })
    };

    skipWeekend = (dayWeek, currentDate) => {
        let date = moment(currentDate);
        const { previousButtonPressed, nextButtonPressed } = this.state;

        if (previousButtonPressed) {
            if (dayWeek === 7) {
                date = date.subtract(2, "days");
            }
        } else if (nextButtonPressed) {
            if (dayWeek === 6) {
                date = date.add(2, "days");
            }
        } else {
            if (dayWeek === 7) {
                date = date.subtract(2, "days");
            } else if (dayWeek === 6) {
                date = date.subtract(1, "days");
            }
        }
        this.setState({
            currentDate: date.format("YYYY-MM-DD"),
            previousButtonActive: false,
            nextButtonPressed: false,
        })
    }

    renderContent = () => {
        const { showLoader, currentDate, date, cardData } = this.state;
        const { trackingIdCorrect } = this.props;
        const weekday = moment(currentDate).isoWeekday();

        let content = null;

        if (showLoader && weekday === 3) {
            return <CardLoader label={"Loading Shopify tracking configuration "}/>
        } else if (showLoader) {
            return <CardLoader/>
        }

        switch (weekday) {
            case 1:
                content = <Monday date={date} cardData={cardData}/>;
                break;
            case 2:
                content = <Tuesday date={date} cardData={cardData}/>;
                break;
            case 3:
                if (trackingIdCorrect === null) {
                    return <CardLoader label={"Loading Shopify tracking configuration "}/>
                } else {
                    content = <Wednesday date={date}
                                         cardData={cardData} history={this.props.history}
                                         trackingIdCorrect={trackingIdCorrect}/>;
                }
                break;
            case 4:
                content = <Thursday date={date} cardData={cardData}/>;
                break;
            case 5:
                content = <Friday date={date} cardData={cardData}/>;
                break;
            case 6:
            case 7:
                this.setState({showLoader:  true });
                this.skipWeekend(weekday, currentDate);
                break;
            default:
                break;
        }
        return content;
    };

    render() {
        const { currentDate, briefDate, previousButtonActive, nextButtonActive } = this.state;

        return (
            <>
                <Container className="padding-container analytic-card" fluid>
                    <Row>
                        <Col>
                            <div className="d-inline-flex">
                                  <span
                                      onClick={() => {
                                          this.setState({
                                              previousButtonActive: false,
                                              nextButtonActive: false,
                                          })
                                          this.getDataCardDatePrevious(moment(currentDate).subtract(1, "days").format("YYYY-MM-DD"))
                                      }} className={`icon-rotate card-sm-icon icon ${previousButtonActive ? "" : "icon-not-active"}
                                       ${previousButtonActive && !!briefDate ? "" : "disable"}`}>
                                    <FontAwesomeIcon icon={faPlay}/>
                                  </span>
                                <p className="prev-next-button">{briefDate}</p>
                                <span
                                    onClick={() => {
                                        this.setState({
                                            previousButtonActive: false,
                                            nextButtonActive: false,
                                        })
                                        this.getDataCardDateNext(moment(currentDate).add(1, "days").format("YYYY-MM-DD"))
                                    }}
                                    className={`card-sm-icon icon ${nextButtonActive ? "" : "icon-not-active"}
                                    ${nextButtonActive && !!briefDate ? "" : "disable"}`}>
                                <FontAwesomeIcon icon={faPlay}/>
                                </span>
                            </div>
                            {this.renderContent()}
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
};



export default AnalyticCard;