import React, {Component} from "react";
import {ListGroup} from "react-bootstrap";

class SourceView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let {
      isSelectAllDataSource, data, unSelectedDataSourcesId,
      onSelectAllDataSource, onUpdateConnectionsSelection, onUpdateDataSourcesSelection,
    } = this.props;

    return (
      <React.Fragment>
        <section className="m-auto list-view-main d-flex flex-row justify-content-center position-relative">
          <section className="view-select p-select view-select list-view-main">
            <dl className="d-flex flex-row justify-content-between align-items-center mb-0">
              <dt>
                <h4>Select Data Sources to view</h4>
              </dt>
            </dl>
            <ListGroup as="ul">
              <ListGroup variant="flush" as="ul" className="d-block">
                <ListGroup as="ul" className="d-block mySize">
                  <ListGroup.Item
                    onClick={onSelectAllDataSource}
                    as="li"
                    className="position-relative no-pointer"
                  >
                    <span>
                      {isSelectAllDataSource && (
                        <i className="icon-check"/>
                      )}
                    </span>
                    <strong>Select All</strong>
                  </ListGroup.Item>
                  {data.map((connection, key) =>
                      connection.dataSources.length > 0 && (
                        <React.Fragment>
                          <ListGroup.Item
                            as="li"
                            key={key}
                            className="position-relative no-pointer"
                            onClick={() => onUpdateConnectionsSelection(connection.id)
                            }
                          >
                            <span>
                              {connection.isSelected && (
                                <i className="icon-check"/>
                              )}
                            </span>
                            <strong
                              className={`${!connection.enabled ? 'title-disable' : ''}`}>{connection.name} </strong>
                            {!connection.enabled && <span className="icon-disable"/>}
                          </ListGroup.Item>
                          <ListGroup as="ul" className="d-block mySize">
                            {connection.dataSources.map((dataSources, key) => (
                              <ListGroup.Item
                                as="li"
                                key={key}
                                className={`position-relative no-pointer
                                ${!connection.enabled || !dataSources.enabled ? 'title-disable' : ''}`}
                                onClick={() => onUpdateDataSourcesSelection(dataSources.id)
                                }
                              >
                                <span>
                                  {!unSelectedDataSourcesId.includes(dataSources.id) && <i className="icon-check"/>}
                                </span>
                                {dataSources.name}
                                {!dataSources.enabled && <span className="icon-disable"/>}
                              </ListGroup.Item>
                            ))}
                          </ListGroup>
                        </React.Fragment>
                      )
                  )}
                </ListGroup>
              </ListGroup>
            </ListGroup>
          </section>
        </section>
      </React.Fragment>
    );
  }
}

export default SourceView;
