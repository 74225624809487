import React, {Component} from "react";
import AnalyticCard from "./AnalyticCard";
import AnalyticHeader from "./AnalyticHeader";
import ShopifyAPI from "../../../services/shopify";
import Newsfeed from "../Newsfeed";
import AccountAPI from "../../../services/account";
import NoData from "./NoData";
import CardLoader from "./components/CardLoader";

class DailyInsight extends Component {
    constructor(props) {
        super(props);
        const selectedMembers = JSON.parse(sessionStorage.getItem("selectedMembers"));

        this.state = {
            selectedMembers: selectedMembers,
            cardHeader: {},
            error: '',
            trackingIdCorrect: null,
        };
    }

    componentDidMount() {
        ShopifyAPI.getHeaderDailyInsight().then((response) => {
            this.setState({
                cardHeader: {
                    ...response
                }
            });
        }).catch((e) => {
            this.setState({ error: e })
        });
        AccountAPI.getShopifyTracking().then((response) => {
            this.setState({
                trackingIdCorrect: response.trackingIdCorrect && response.extTrackingBlockEnabled,
            });
        }).catch(() => {
            this.setState({ trackingIdCorrect: false })
        });
    }


    render() {
        const { cardHeader, error, selectedMembers, trackingIdCorrect } = this.state;

        if (selectedMembers.origin === "SHOPIFY") {

            if(cardHeader && !cardHeader.cardAvailabilityStatus) {
                return <CardLoader label={"Fetching Analytical Data"} />
            }
            else if(cardHeader && cardHeader.cardAvailabilityStatus === "AVAILABLE") {
                return (
                    <>
                        <AnalyticHeader cardHeader={cardHeader} history={this.props.history}/>
                        <AnalyticCard cardHeader={cardHeader} error={error} history={this.props.history}
                                      trackingIdCorrect={trackingIdCorrect}/>
                    </>
                );
            } else {
                return <NoData/>
            }
        } else {
            return (
                <>
                    <Newsfeed contextUser={this.props.contextUser}/>
                </>
            );
        }

    }
}

export default DailyInsight;
