import React, { Component } from "react";
import CommonMessage from "../../../components/CommonMessage/commonMessage";
import { Link } from "react-router-dom";
import { Textbox } from "react-inputs-validation";
import { ListGroup } from "react-bootstrap";
import AuthenticationService from "../../../services/authentication";
import { toast } from "react-toastify";
import ReCaptcha from "react-google-recaptcha";
import _ from "lodash";
import BottomWrap from "../../../components/BottomWrap/BottomWrap";

const getUrlVars = (url) => {
  const vars = {};
  url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    vars[key] = value;
  });
  return vars;
};



class Signup extends Component {

  constructor(props) {
    super(props);
    this.myFirstName = React.createRef();
    this.myLastName = React.createRef();
    this.myEmail = React.createRef();
    this.myPassword = React.createRef();
    this.state = {
      firstName: sessionStorage.getItem("firstName"),
      lastName: sessionStorage.getItem("lastName"),
      email: sessionStorage.getItem("email"),
      password: "",
      errors: {},
      isPasswordField: "password",
      isCaptchaVerified: false,
      allValid: false,
      passwordValidation: [
        {
          type: "8 characters minimum",
          isValid: false,
          isValidType: /[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~0-9a-zA-Z]{8,}/
        },
        { type: "One number", isValid: false, isValidType: ".*[0-9].*" },
        {
          type: "One uppercase character",
          isValid: false,
          isValidType: "(?=.*[A-Z])"
        },
        {
          type: "One lowercase character",
          isValid: false,
          isValidType: "(?=.*[a-z])"
        }
      ],
      validateEmail: true,
      isInvited: false,
      token: "",
      isShopify: sessionStorage.getItem("isShopify")};
  }

  onChangeCaptcha = value => {
    // console.log(value);
    if (value != undefined) {
      this.setState({ isCaptchaVerified: true }, () => this.validationForm());
    } else {
      this.validationForm();
    }
  };
  componentDidMount() {
    document.title = "Distil - Create your Distil account";
    if (this.props.location.state != undefined) {
      console.log("invited");

      this.setState({
        isInvited: true,
        firstName:
          this.props.location.state.invitedUser.firstName !== null
            ? this.props.location.state.invitedUser.firstName
            : "",
        lastName:
          this.props.location.state.invitedUser.lastName !== null
            ? this.props.location.state.invitedUser.lastName
            : "",
        email:
          this.props.location.state.invitedUser.emailAddress !== null
            ? this.props.location.state.invitedUser.emailAddress
            : "",
        token: this.props.location.state.token
      });
    }
  }

  validationForm = () => {
    const firstName = this.state.firstName;
    const lastName = this.state.lastName;
    const email = this.state.email;
    const password = this.state.password;

    const errInFirstName = this.myFirstName.current.state.err;
    const errInMyLastName = this.myLastName.current.state.err;
    const errInMyEmail = this.myEmail.current.state.err;
    this.setState({ validateEmail: !errInMyEmail });
    const errInMymyPassword =
      _.filter(this.state.passwordValidation, { isValid: true }).length > 0
        ? true
        : false;
    // console.log(errInMymyPassword);

    // const errInMymyPassword = this.myPassword.current.state.err;
    if (
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      password !== "" &&
      !errInMyEmail &&
      !errInMymyPassword &&
      !errInFirstName &&
      !errInMyLastName &&
      this.state.isCaptchaVerified
    ) {
      this.setState({ allValid: true });
    } else {
      this.setState({ allValid: false });
    }
  };

  createUer = async event => {
    let url = getUrlVars(window.location.href);
    event.preventDefault();
    const firstName = this.state.firstName;
    const lastName = this.state.lastName;
    const email = this.state.email;
    const password = this.state.password;
    const token = this.state.token;
    let CFID;
    const shopifyHost = sessionStorage.getItem("shopifyShop");
    const shopToken = sessionStorage.getItem("shopToken");
    console.log("shopToken", shopToken)

    if(url["submissionId"]) {
      CFID = String(url['submissionId']);
    }

    window.distil.identifyCustomer({CFID: CFID, EmailAddress: email, GDPRStatus: {MarketingSubscribed: false}});
    window.distil.trackEvent('try_signup', ' ', {EmailAddress: email, CFID: CFID});

    if (this.state.allValid) {
      try {
        await AuthenticationService.createUser(
          firstName,
          lastName,
          email,
          password,
          token,
          CFID,
          shopifyHost,
          shopToken,
        ).then(async response => {

          if (token == "") {
            this.props.history.push("/createaccount");
          }

          try {
            AuthenticationService.login(email, password).then(response => {
              if (this.state.isShopify === "true"){
                this.props.history.push("/signup/activated");
              } else {
                console.log("2222222222222", this.state)
                this.props.history.push("/chooseaccount");
              }
            });
          } catch (ex) {
            toast.error("Account created, but not able to move further");
          }
        });
      } catch (ex) {

        window.distil.trackEvent('unsuccessful_signup', ' ', {EmailAddress: email, HttpResponseStatus: ex.response.status});

        if (ex.response.status === 409) {
          this.setState({ validateEmail: false });
          toast.error("Email already exists");
        } else {
          toast.error(
            <div>
              {ex.response.data.message} <br />
              {ex.response.data.errors.map(type => type.message + " ")}
            </div>
          );
        }
      }
    }
  };

  togglePasswordField = () => {
    this.setState({
      isPasswordField:
        this.state.isPasswordField === "password" ? "text" : "password"
    });
  };

  passwordFieldValidation = res => {
    var passwordValidationState = [...this.state.passwordValidation];
    for (let i = 0; i < this.state.passwordValidation.length; i++) {
      var pattern = new RegExp(this.state.passwordValidation[i].isValidType);
      // console.log(i, " - ", pattern.test(res), pattern, res);
      if (pattern.test(res)) {
        this.setState();
        passwordValidationState[i].isValid = false;
        this.setState({ passwordValidation: passwordValidationState });
      } else {
        passwordValidationState[i].isValid = true;
        this.setState({ passwordValidation: passwordValidationState });
      }
    }
    this.validationForm();
  };

  render() {
    return (
      <div className="content-wrap bg-white">
        <div className="content-inner-wrap col-xl-9 col-md-11">
          <div className="top-wrap">
            <h1 className="font-weight-heavy">Create your Distil account</h1>
            <p>
              Unlock the facts that will help you speak to your whole audience
              in a uniquely personal way.
            </p>
            <p>
              Already have an account?
              <Link
                className="px-1"
                to={{
                  pathname: "/login"
                }}
              >
                {"Log in here"}
              </Link>
            </p>
          </div>

          <div className="form-wrap">
            <form onSubmit={this.createUer}>
              <div className="row-wrap d-flex">
                <div className="form-group new-form-group">
                  <label>First Name</label>
                  <Textbox
                    ref={this.myFirstName}
                    classNameInput="form-control"
                    classNameContainer="custome-input"
                    tabIndex="1" // Optional.[String or Number].Default: none.
                    name="firstName" // Optional.[String].Default: "". Input name.
                    type="text" // Optional.[String].Default: "text". Input type [text, password, number].
                    value={this.state.firstName}
                    placeholder="Your First Name here" // Optional.[String].Default: "".
                    autoComplete="off"
                    onChange={(firstName, e) => {
                      this.setState({ firstName }, () => this.validationForm());
                      // console.log(e);
                    }}
                    onBlur={e => {
                      console.log(e);
                    }} // Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                    validationOption={{
                      name: "First Name", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
                      check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                      required: true // Optional.[Bool].Default: true. To determin if it is a required field.
                    }}
                  />
                </div>

                <div className="form-group new-form-group">
                  <label>Last name</label>
                  <Textbox
                    ref={this.myLastName}
                    classNameInput="form-control"
                    classNameContainer="custome-input"
                    tabIndex="2" // Optional.[String or Number].Default: none.
                    name="lastName" // Optional.[String].Default: "". Input name.
                    type="text" // Optional.[String].Default: "text". Input type [text, password, number].
                    value={this.state.lastName}
                    placeholder="Your Last Name here" // Optional.[String].Default: "".
                    autoComplete="off"
                    onChange={(lastName, e) => {
                      this.setState({ lastName }, () => this.validationForm());
                      console.log(e);
                    }}
                    onBlur={e => {
                      console.log(e);
                    }} // Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                    validationOption={{
                      name: "Last Name", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
                      check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                      required: true // Optional.[Bool].Default: true. To determin if it is a required field.
                    }}
                  />
                </div>
              </div>

              <div className="form-group new-form-group">
                {/* For dummmy form  Start*/}
                <input
                  id="email"
                  type="text"
                  autoComplete="off"
                  value=""
                  className="d-none"
                ></input>
                <input
                  id="password"
                  type="password"
                  autoComplete="off"
                  value=""
                  className="d-none"
                ></input>
                {/* For dummmy form  End */}
                <label htmlFor="emails">Email</label>
                <Textbox
                  ref={this.myEmail}
                  classNameInput={
                    this.state.validateEmail
                      ? "form-control"
                      : "react-inputs-validation__error___2aXSp  form-control"
                  }
                  classNameContainer="custome-input"
                  tabIndex="3" // Optional.[String or Number].Default: none.
                  name="emails" // Optional.[String].Default: "". Input name.
                  type="text" // Optional.[String].Default: "text". Input type [text, password, number].
                  value={this.state.email} // Optional.[String].Default: "".
                  placeholder="Your Email here" // Optional.[String].Default: "".
                  autoComplete="off"
                  onChange={(email, e) => {
                    this.setState({ email, validateEmail: true }, () =>
                      this.validationForm()
                    );
                    console.log(e);
                  }} // Required.[Func].Default: () => {}. Will return the value.
                  onBlur={e => {
                    console.log(e);
                  }} // Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                  validationOption={{
                    name: "Email Address", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
                    check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                    required: true, // Optional.[Bool].Default: true. To determin if it is a required field.

                    reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // Optional.[Bool].Default: "" Custom regex.
                    regMsg: "Enter valid Email Address" // Optional.[String].Default: "" Custom regex error message.
                  }}
                />
                {!this.state.validateEmail && (
                  <span className="hasError">
                    An account with this email address already exists.
                    <Link
                      className="px-1"
                      to={{
                        pathname: "/login"
                      }}
                    >
                      {"Please login to continue."}
                    </Link>
                  </span>
                )}
              </div>

              <div className="form-group new-form-group">
                <label htmlFor="password">
                  Password{" "}
                  <span onClick={this.togglePasswordField}>
                    {" "}
                    <i
                      className={`${
                        this.state.isPasswordField === "password"
                          ? "icon-visibility"
                          : "icon-visibility_off"
                      }`}
                    ></i>{" "}
                    Show{" "}
                  </span>
                </label>

                <Textbox
                  ref={this.myPassword}
                  classNameInput="form-control"
                  classNameContainer="custome-input"
                  tabIndex="3" // Optional.[String or Number].Default: none.
                  id={"password"} // Optional.[String].Default: "".  Input ID.
                  name="password" // Optional.[String].Default: "". Input name.
                  type={this.state.isPasswordField} // Optional.[String].Default: "text". Input type [text, password, number].
                  value={this.state.password} // Optional.[String].Default: "".
                  placeholder="Your password here" // Optional.[String].Default: "".
                  onChange={(password, e) => {
                    this.setState({ password }, () =>
                      this.passwordFieldValidation(password)
                    );
                    console.log(e);
                  }} // Required.[Func].Default: () => {}. Will return the value.
                  onBlur={e => {
                    console.log(e);
                  }}
                  autoComplete="off"
                  // Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                  validationOption={{
                    name: "Password", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
                    required: false // Optional.[Bool].Default: true. To determin if it is a required field.
                  }}
                />
                <ListGroup as="ul" className="p-strength ">
                  {this.state.passwordValidation.map(field => (
                    <ListGroup.Item
                      as="li"
                      className={!field.isValid ? " " : "isError"}
                    >
                      {field.type}
                    </ListGroup.Item>
                  ))}

                  {/* <ListGroup.Item as="li">One number</ListGroup.Item>
                  <ListGroup.Item as="li">
                    One uppercase character
                  </ListGroup.Item> */}
                </ListGroup>

                <ReCaptcha
                  className="reCaptcha"
                  size="normal"
                  data-theme="dark"
                  render="explicit"
                  sitekey="6LeJ5MQUAAAAADmqj2qFTYv7h1NE-W_ZhNk6Krld"
                  onChange={this.onChangeCaptcha}
                  onExpired={this.onChangeCaptcha}
                  onErrored={this.onChangeCaptcha}
                />

                <p className="service-agree">
                  {" "}
                  By continuing, you’re agreeing to our customer{" "}
                  <a href="#">Terms of Use</a> and{" "}
                  <a href="#">Privacy Policy</a>.{" "}
                </p>
              </div>

              <div className="btn-wrap">
                <button
                  type="submit"
                  className="btn btn-primary green-btn"
                  disabled={!this.state.allValid}
                >
                  Create Account
                </button>
              </div>
            </form>
          </div>
          <BottomWrap />
        </div>
      </div>
    );
  }
}

export default Signup;
