import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class Items extends Component {
  render() {
    return (
      <li className={this.props.routeItem.className}>
        <NavLink
          nav-label={this.props.routeItem.name}
          className="nav-link"
          to={{
            pathname: this.props.routeItem.path
          }}
          onClick={(e)=>this.props.onVerifyChange(e,this.props.routeItem.path) }
        >
          {this.props.routeItem.name}
        </NavLink>
      </li>
    );
  }
}

export default Items;
