import React, { Component } from "react";

import { Link } from "react-router-dom";

class Error500 extends Component {
  render() {
    return (
      <div className="content-wrap bg-white">
        <div className="content-inner-wrap newtrial mw-100">
          <div className=" my-auto d-flex flex-column align-items-center">
            <h3>
              There has been an issue creating your new trial <br />
              organisation, please contact{" "}
              <a href="mailto:support@distil.ai"> support@distil.ai</a>
            </h3>
          </div>
        </div>
      </div>
    );
  }
}

export default Error500;
