import React, { Component } from "react";
import { ListGroup, ListGroupItem, Row, Col } from "react-bootstrap";

class EnrichmentSummary extends Component {
  state = {};
  render() {
    const { lifetimeValue, spendBracket, chronotype } = this.props;
    return (
      <div className="customer-detail-wrapper">
        <Row noGutters={true}>
          <Col xs={12}>
            <ListGroup className="customer-detail">
              <ListGroupItem>
                <h6>Customer Lifetime Value</h6>
                <span className="detail-value value">                          
                  £{lifetimeValue !== null ? Number(lifetimeValue).toLocaleString(navigator.language, {
                            minimumFractionDigits: 2
                          }) : 0}
                </span>
              </ListGroupItem>

              {spendBracket !== null && (
                <ListGroupItem>
                  <h6>Spend Bracket</h6>
                  <span className="detail-value spend">{spendBracket}</span>
                </ListGroupItem>
              )}

              {chronotype !== null && (
                <ListGroupItem>
                  <h6>Chronotype</h6>
                  <span className="detail-value chronotype">{chronotype}</span>
                </ListGroupItem>
              )}
            </ListGroup>
          </Col>
        </Row>
      </div>
    );
  }
}

export default EnrichmentSummary;
