import React, { Component } from "react";
import { Row, Col, Form, Table } from "react-bootstrap";

import Mixer from "../../../component/Mixer/Mixer";
import CustomeSearch from "../../../component/CustomeSearch";
import Loader from "../../../../../../../components/Loader/loader";
import EnrichmentsAPI from "../../../../../../../services/enrichments";
import moment from "moment";

class MixingDeck extends Component {
  state = {
    currentEnrichment: {},
    customerList: [],
    selectedCustomerId: "",
    selectedCustomerName: "",
    getRecommendations: {
      customerId: "",
      recommendationResults: [],
      contentViews: []
    },
    itemName: {
      mixerValue: "contentRecFactor",
      isEnable: "contentRecEnabled",
      overDays: "contentRec"
    },
    isLoadingRecomendation: false
  };

  onSliderUpdate = (name, value) => {
    this.props.onAttributeChange(name, value);

    const currentEnrichment = this.state.currentEnrichment;
    currentEnrichment[name] = value;
    this.setState({ currentEnrichment }, this.updateSettings);
  };

  updateSettings = () => {
    this.props.onUpdateValue(this.state.currentEnrichment);
    if (this.state.selectedCustomerId !== "") {
      this.getRecommendations(this.state.selectedCustomerId);
    }
  };

  componentDidMount() {
    this.setState({
      currentEnrichment: this.props.currentEnrichment,
      isLoading: true
    });
  }

  componentWillReceiveProps(prevProps) {
    if (this.state.currentEnrichment !== prevProps.currentEnrichment) {
      this.setState({
        currentEnrichment: prevProps.currentEnrichment
      });
    }
  }

  customerSelected = async searchWhat => {
    this.setState({ searchWhat });
    try {
      await EnrichmentsAPI.customerSearch(searchWhat).then(response => {
        this.setState({ customerList: response });
        // console.log(response);
      });
    } catch (err) {}
  };

  onCustomerSelection = (id, name) => {
    this.setState({ selectedCustomerId: id, selectedCustomerName: name });
    this.getRecommendations(id);
  };

  getRecommendations = async id => {
    this.setState({ isLoadingRecomendation: true });
    try {
      await EnrichmentsAPI.getRecommendations(
        id,
        this.state.currentEnrichment
      ).then(response => {
        this.setState({ getRecommendations: response });
      });
    } catch (err) {}
    this.setState({ isLoadingRecomendation: false });
  };

  searchDone = () => {
    this.setState({ customerList: [] });
  };

  onAlreadyPurchased = () => {
    if (this.state.selectedCustomerId !== "") {
      this.getRecommendations(this.state.selectedCustomerId);
    }
    this.props.onAttributeChange(
      "contentRecExcludeAlreadyViewed",
      !this.state.currentEnrichment.contentRecExcludeAlreadyViewed
    );
  };

  render() {
    const {
      currentEnrichment,
      getRecommendations,
      selectedCustomerName,
      itemName,
      isLoadingRecomendation
    } = this.state;

    return (
      <Row className="no-gutters">
        <Col xs={5}>
          <section className="slider-feature">
            <h6>Recommendation Mixing Deck</h6>
            <p>
              Tune the algorithm using the mixing deck then test the results for
              each customer.
            </p>
            <div className="bg-slider d-flex mb-4">
              <Mixer
                type="Content Affinity"
                ids="1"
                mixerValue={currentEnrichment.contentRecFactorContentAffinity}
                isEnable={currentEnrichment.contentRecEnabledContentAffinity}
                isSliderEnable={
                  currentEnrichment.contentRecOkToUseContentAffinity
                }
                itemName={itemName}
                mixerName={"ContentAffinity"}
                onSliderUpdate={this.onSliderUpdate}
                numberOfItems={6}
              />
              <Mixer
                type="Content viewed together"
                ids="2"
                mixerValue={
                  currentEnrichment.contentRecFactorContentViewedTogether
                }
                isEnable={
                  currentEnrichment.contentRecEnabledContentViewedTogether
                }
                isSliderEnable={
                  currentEnrichment.contentRecOkToUseContentViewedTogether
                }
                itemName={itemName}
                mixerName={"ContentViewedTogether"}
                onSliderUpdate={this.onSliderUpdate}
                numberOfItems={6}
              />
              <Mixer
                type="Content Product Affinity"
                ids="3"
                mixerValue={
                  currentEnrichment.contentRecFactorContentProductAffinity
                }
                isEnable={
                  currentEnrichment.contentRecEnabledContentProductAffinity
                }
                isSliderEnable={
                  currentEnrichment.contentRecOkToUseContentProductAffinity
                }
                itemName={itemName}
                mixerName={"ContentProductAffinity"}
                onSliderUpdate={this.onSliderUpdate}
                numberOfItems={6}
              />
              <Mixer
                type="Viewed before Purchase"
                ids="4"
                mixerValue={
                  currentEnrichment.contentRecFactorViewedBeforePurchase
                }
                isEnable={
                  currentEnrichment.contentRecEnabledViewedBeforePurchase
                }
                isSliderEnable={
                  currentEnrichment.contentRecOkToUseViewedBeforePurchase
                }
                itemName={itemName}
                mixerName={"ViewedBeforePurchase"}
                onSliderUpdate={this.onSliderUpdate}
                numberOfItems={6}
              />
              <Mixer
                type="Trending over past"
                ids="5"
                mixerValue={currentEnrichment.contentRecFactorTrending}
                isEnable={currentEnrichment.contentRecEnabledTrending}
                isSliderEnable={currentEnrichment.contentRecOkToUseTrending}
                overDays={currentEnrichment.contentRecTrendingOverDays}
                reqOverDays={true}
                itemName={itemName}
                mixerName={"Trending"}
                onSliderUpdate={this.onSliderUpdate}
                numberOfItems={6}
              />
              <Mixer
                type="Default Content"
                ids="6"
                mixerValue={currentEnrichment.contentRecFactorDefaultContent}
                isEnable={currentEnrichment.contentRecEnabledDefaultContent}
                isSliderEnable={
                  currentEnrichment.contentRecOkToUseDefaultContent
                }
                itemName={itemName}
                mixerName={"DefaultContent"}
                onSliderUpdate={this.onSliderUpdate}
                numberOfItems={6}
              />
            </div>
            <div className="d-flex align-self-left exclude-purchase">
              <Form>
                {["checkbox"].map(type => (
                  <div
                    key={`custom-inline-${type}`}
                    className="mb-3 justify-content-center d-flex"
                  >
                    <Form.Check
                      custom
                      checked={currentEnrichment.contentRecExcludeAlreadyViewed}
                      onChange={this.onAlreadyPurchased}
                      inline
                      label="Exclude already purchased products"
                      type={type}
                      id={`custom-inline-${type}-6`}
                    />
                  </div>
                ))}
              </Form>
            </div>
          </section>
        </Col>
        <Col xs={7}>
          <section className="area-filter pt-5">
            <CustomeSearch
              onCustomerSelect={this.customerSelected}
              customerList={this.state.customerList}
              onCustomerSelection={this.onCustomerSelection}
              onSearchDone={this.searchDone}
            />
            {isLoadingRecomendation && (
              <div className="over-lay">
                <Loader />
              </div>
            )}
            <section className="pl-5 pb-2 table-search-bg">
              <h5>{selectedCustomerName}</h5>
              {this.state.selectedCustomerName.length > 0 ? (
                <div>
                  <h6>Recommendations</h6>

                  <div className="table-search-container">
                    <Table>
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>Total</th>
                          <th>Affinity</th>
                          <th>Viewed together</th>
                          <th>Purchased Affinity</th>
                          <th>Viewed Before Purchase</th>
                          <th>Trending</th>
                          <th>Default</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getRecommendations.recommendationResults.length > 0 ? (
                          getRecommendations.recommendationResults.map(item => (
                            <tr>
                              <td>{item.contentTitle}</td>
                              <td>{item.total}</td>
                              <td>{item.contentAffinity}</td>
                              <td>{item.contentViewedTogether}</td>
                              <td>{item.contentProductAffinity}</td>
                              <td>{item.viewedBeforePurchase}</td>
                              <td>{item.trendingResult}</td>
                              <td>{item.defaultContentResult}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8}>
                              {this.state.selectedCustomerName.length > 0
                                ? "No customer recommendations available"
                                : "Please search for a customer to show recommendations for"}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>

                  <div className="pt-4 purchased-table">
                    <h6>Content Viewed</h6>
                    <div className="row no-gutters">
                      <div className="col-lg-12 col-xl-5">
                        <div className="table-search-container">
                          <Table responsive="lg">
                            <thead>
                              <tr>
                                <th>Product</th>
                                <th>Purchased on</th>
                                <th>Times Purchased</th>
                              </tr>
                            </thead>
                            <tbody>
                              {getRecommendations.contentViews.length > 0 ? (
                                getRecommendations.contentViews.map(item => (
                                  <tr>
                                    <td>{item.contentTitle}</td>
                                    <td>{moment.utc(item.viewedOn).format("MMM DD, YYYY") }</td>
                                    <td>{item.numTimesViewed}</td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={3}>
                                    {this.state.selectedCustomerName.length > 0
                                      ? "Customer has no content viewed"
                                      : "Please search for a customer to show recommendations for"}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                "Search for a Customer to see the Recommendations these settings generate"
              )}
            </section>
          </section>
        </Col>
      </Row>
    );
  }
}

export default MixingDeck;
