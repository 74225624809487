import React, { Component } from "react";
import "react-inputs-validation/lib/react-inputs-validation.min.css";

import queryString from "query-string";
import AuthenticationService from "../../../services/authentication";
import Loader from "../../../components/Loader/loader";
import { toast } from "react-toastify";
import BannerPanel from "../../../components/Branding/BannerPanel/BannerPanel";
import VerificationResult from "./VerificationResult";

class VerifyUser extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    isLoading: true,
    result: null,
    token: null
  };

  componentDidMount() {
    const token = queryString.parse(this.props.location.search);
    this.onVerify(token.token);

    if (token.token !== undefined && token.token !== "") {
      this.setState({ token: token.token });
      this.onVerify(token.token);
    }
  }
  clearSession = () => {

  };


  onVerify = async token => {
    let userAccountData = JSON.parse(localStorage.getItem("userAccountData"));
    try {
      await AuthenticationService.verifyUser(token).then(response => {
        console.log(userAccountData);
        if (userAccountData !== null) {
          if (
            userAccountData.contextUser.emailAddress !== response.emailAddress
          ) {
            this.clearSession();
            this.setState({ isLoading: false, result: "verifiedOtherUser" });
          } else {
            // this.props.history.replace("/");
            if (response.emailVerified) {
              this.setState({ isLoading: false, result: "alreadyVerified" });
            } else {
              this.props.history.replace("/login");
              this.setState({ isLoading: false, result: "success" });
            }
          }
        } else {
          this.props.history.replace("/login");
          // if (response.emailVerified) {
          //   this.setState({ isLoading: false, result: "alreadyVerified" });
          // } else {
          //   this.setState({ isLoading: false, result: "success" });
          // }
        }
      });
    } catch (ex) {
      switch (ex.response.status) {
        case 404:
          this.setState({ isLoading: false, result: "error404" });

          break;

        default:
          this.setState({ isLoading: false, result: "error" });
      }

      // this.setState({ isLoading: false, result: "error" });
    }
  };

  render() {
    const result = {
      error: {
        title: "There is an issue",
        description:
          "Your verification has an issue. Please contact the Distil team.",
        actionLabel: "Create Account",
        actionPath: "/signup"
      },
      error404: {
        title: "Invalid verification token",
        description:
          "Your user verification token is not valid. Please contact the Distil team.",
        actionLabel: "Let's go",
        actionPath: "/"
      },
      error404OtherUser: {
        title: "Invalid verification token",
        description:
          "The token to verify your user account is not valid. Please contact the Distil team",
        actionLabel: "Let's go",
        actionPath: "/"
      },

      verifiedOtherUser: {
        title: "Token verified for different user account",
        description: "Verification using this token has already been done for another user account.",
        actionLabel: "Login",
        actionPath: "/",
        refreshPage:true
      },
      alreadyVerified: {
        title: "User is already verified",
        description: "User is already verified.",
        actionLabel: "Let's go",
        actionPath: "/"
      },
      success: {
        title: "User is verified",
        description: "User is verified.",
        actionLabel: "Let's go",
        actionPath: "/"
      }
    };
    return (
      <div className="app">
        <div className="login">
          <div className="container-fluid">
            <div className="row no-gutters h-100">
              <div className="col-lg-5">
                <BannerPanel />
              </div>
              <div className="col-lg-7">
                {this.state.isLoading ? (
                  <Loader />
                ) : (
                  <VerificationResult data={result[this.state.result]} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VerifyUser;
