import React, { Component } from "react";
import { ListGroup, Modal, Button, Row, Col, Form } from "react-bootstrap";
import TraitsAPI from '../../../../../services/traits';
import { toast } from 'react-toastify';
import {Link} from "react-router-dom";
import TraitCategoryItem from "./TraitCategoryItem";
import "../../assets/traitCategoriesModal.scss";
import PropTypes from "prop-types";


const propTypes = {
  modalstate: PropTypes.bool.isRequired,
  traitCategories: PropTypes.array.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onTraitCategoryCreated: PropTypes.func.isRequired,
  onTraitCategoryUpdated: PropTypes.func.isRequired,
  onTraitCategoryDeleted: PropTypes.func.isRequired,
};

class TraitCategoriesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      searchItem: "",
      currentEditingCategory:null,
      traitCategories: [...this.props.traitCategories.filter(c => !!c.id && !c.isDefault)],
      newCategoryAdded: false,
      locked: false,
    };
  }

  componentWillMount() {
    // this.getDataSourceAttr();
  }

  onCategoryEditing = (category) => {
    const { currentEditingCategory, newCategoryAdded } = this.state;
    if(currentEditingCategory) {
      currentEditingCategory.onCancel();
    }
    if(newCategoryAdded) {
      this.onCategoryEditingCancelled();
    }
    this.setState({
      currentEditingCategory: category,
    });
  }

  onCategoryEditingCancelled = () => {
    const {traitCategories} = this.state;
    const filteredCategories = traitCategories.filter(c => !c.isNew);
    if(filteredCategories.length !== traitCategories.length) {
      this.setState({
        traitCategories: filteredCategories,
        currentEditingCategory: null,
        newCategoryAdded: false,
      });
    } else {
      this.setState({
        currentEditingCategory: null,
        newCategoryAdded: false,
      });
    }
  }

  addNewCategory = () => {
    const { newCategoryAdded, locked } = this.state;
    if(newCategoryAdded || locked) {
      return;
    }
    const { traitCategories, currentEditingCategory } = this.state;
    if(currentEditingCategory) {
      currentEditingCategory.onCancel();
    }
    const newCategory = {
      id: null,
      name: "",
      isNew: true,
    };
    traitCategories.push(newCategory);
    this.setState({
      traitCategories: traitCategories,
      newCategoryAdded: true,
    });
  }

  updateCategory = (category, callback) => {
    const { onTraitCategoryUpdated, onTraitCategoryCreated } = this.props;
    this.setState({locked: true});
    if(category.id) {
      //update
      TraitsAPI.updateTraitCategory(category.id, { id: category.id, name: category.name})
      .then(updatedCategory => {
        toast.success("Customer Trait Category updated successfully");
        this.onCategoryEditingCancelled();
        onTraitCategoryUpdated(updatedCategory);
        callback();
        this.setState({locked: false});
      }).catch(error => {
        toast.error("Error creating Customer Trait Category");
        this.setState({locked: false});
      });
    } else {
      //create
      TraitsAPI.createNewTraitCategory({ name: category.name })
      .then(createdCategory => {
        toast.success("Customer Trait Category created successfully");
        this.onCategoryEditingCancelled();
        onTraitCategoryCreated(createdCategory);
        callback();
        const {traitCategories} = this.state;
        this.setState({locked: false, traitCategories: [...traitCategories, createdCategory]});
      }).catch(error => {
        toast.error("Error creating Customer Trait Category");
        this.setState({locked: false});
      });
    }

  }

  deleteCategory = (categoryId, callback) => {
    const { onTraitCategoryDeleted } = this.props;
    this.setState({locked: true});
    TraitsAPI.deleteTraitCategory(categoryId)
    .then(() => {
      toast.success("Customer Trait Category deleted successfully");
      onTraitCategoryDeleted(categoryId);
      callback();
      const {traitCategories} = this.state;
      this.setState({locked: false, traitCategories: [...traitCategories.filter(c => c.id !== categoryId)]});
    }).catch(error => {
      toast.error("Error deleting Customer Trait Category");
      this.setState({locked: false});
    });
  }

  validateCategory = (category) => {
    const hasDuplicates= this.props.traitCategories.filter(e => e.id !== category.id && e.name === category.name).length > 0;
    return hasDuplicates ? "Category name must be unique" : "";
  }

  render() {
    const { traitCategories, newCategoryAdded, locked } = this.state;
    return (
      <Modal
        className="modal-trait-categories"
        show={this.props.modalstate}
        onHide={this.props.onCloseModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="border-bottom-0">
          <Modal.Title className="h6 bold" id="contained-modal-title-vcenter ">
            Customer Trait Categories
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <ListGroup as="ul" className="trait-categories-list">
            {traitCategories.map((category, index) => (
                <TraitCategoryItem key={index}
                                   locked={locked}
                                   traitCategory={category}
                                   onCategoryUpdated={this.updateCategory}
                                   onCategoryDeleted={this.deleteCategory}
                                   onCategoryEditing={this.onCategoryEditing}
                                   onCategoryEditingCancelled={this.onCategoryEditingCancelled}
                                   validateCategory={this.validateCategory}/>
            ))}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer className="border-top-0 pb-0">
          <Link className={"add-category common-link " + (newCategoryAdded || locked ? "disabled" : "")} to="#" onClick={this.addNewCategory}  >
            + Add Another Category
          </Link>
        </Modal.Footer>
      </Modal>
    );
  }
}

TraitCategoriesModal.propTypes = propTypes;

export default TraitCategoriesModal;
