import React, {Component} from "react";
import {Button, Form, ListGroup} from "react-bootstrap";
import PropTypes from "prop-types";

const propTypes = {
  locked: PropTypes.bool,
  traitCategory: PropTypes.object.isRequired,
  onCategoryUpdated: PropTypes.func.isRequired,
  onCategoryDeleted: PropTypes.func.isRequired,
  onCategoryEditing: PropTypes.func.isRequired,
  onCategoryEditingCancelled: PropTypes.func.isRequired,
  validateCategory: PropTypes.func.isRequired,
};

const defaultProps = {
  locked: false,
}

class TraitCategoryItem extends Component {

  constructor(props) {
    super(props);
    const { traitCategory } = this.props;
    this.state = {
      editCategory: !!traitCategory.isNew,
      fieldValue: "",
      show: false,
      errors: {
        fieldValue: "",
        conditionType: ""
      },
      validated: false,
    };
  }


  isReadOnly = () => {
    return this.props.traitCategory.id === null && !this.props.traitCategory.isNew;
  }

  onEdit = () => {
    const { onCategoryEditing } = this.props;
    onCategoryEditing(this);
    this.setState({ editCategory: true, fieldValue: this.props.traitCategory.name || "" });
  };

  onDelete = () => {
    const { onCategoryDeleted, traitCategory } = this.props;
    onCategoryDeleted(traitCategory.id, () => {})
  };

  onSave = () => {
    const { onCategoryUpdated } = this.props;
    const { fieldValue } = this.state;
    onCategoryUpdated({id: this.props.traitCategory.id, name: fieldValue}, () => this.setState({ editCategory: false }) );
  }

  onCancel = (propagate) => {
    const { onCategoryEditingCancelled } = this.props;
    this.setState({ editCategory: false });
    if(propagate) {
      onCategoryEditingCancelled();
    }
  };

  handleChange = e => {
    const { value } = e.target;
    this.setState({ fieldValue: value });
  }

  validate = () => {
    const { validateCategory } = this.props;
    const { fieldValue } = this.state;

    if (fieldValue === "") {
      return  "Must not be empty";
    }

    return validateCategory({id: this.props.traitCategory.id, name: fieldValue});
  };

  render() {
    const {
      traitCategory,
      locked,
    } = this.props;
    const { editCategory } = this.state;
    if (editCategory) {
      return (
          <ListGroup.Item as="li" className="trait-category-item lci-edit">
            <Form.Control
                autoFocus
                disabled={locked}
                className={this.state.errors?.fieldValue && "error"}
                type="text"
                value={this.state.fieldValue}
                placeholder={"Please enter Customer Trait name"}
                onChange={e => this.handleChange(e)}
            />

            <dl className="d-flex ml-auto mb-0 section-icon">
              <dt>
                <Button
                    variant="secondary"
                    disabled={this.validate() || locked }
                    onClick={() => this.onSave()}
                >
                  {this.validate() && <i className="icon-disable" />}
                  <strong className="save">Save</strong>
                </Button>
              </dt>
              <dd>
                <Button
                    variant="secondary"
                    disabled={locked}
                    onClick={() => this.onCancel(true)}
                >
                  Cancel
                </Button>
              </dd>
            </dl>
          </ListGroup.Item>
      );
    } else {
      return (
          <ListGroup.Item as="li" className="trait-category-item lci-view" >
            <p className="m-0">
              <span> {traitCategory.name} </span>
            </p>

            <dl className="d-flex ml-auto mb-0 section-icon">
              <dt>
                <Button
                    variant="secondary"
                    onClick={ () => this.onEdit() }
                    disabled={this.isReadOnly() || locked}>
                  <i className="icon-edit-light l-edit"/>
                </Button>
              </dt>
              <dd>
                <Button
                    variant="secondary"
                    onClick={ () => this.onDelete() }
                    disabled={this.isReadOnly() || locked}
                >
                  <i className="icon-delete-light l-delete"/>
                </Button>
              </dd>
            </dl>
          </ListGroup.Item>
    );
    }
  }
}

TraitCategoryItem.propTypes = propTypes;
TraitCategoryItem.defaultProps = defaultProps;

export default TraitCategoryItem;
