import React, { Component } from "react";
import "../assets/styles.css";
import { Col, Row, Badge } from "react-bootstrap";

import Bantimage from "../assets/img/disable.svg";
import Resendimage from "../assets/img/resend.svg";
import inviteAPI from "../../../../../../services/users.js";
import { toast } from "react-toastify";
import moment from "moment";
import { dataFormat } from "../../../../../../variables/globalVariables";

class InviteList extends Component {
  constructor() {
    super();
    this.state = {
      usersInvite: "",
      isLoading: false,
      filteredInvite: "",
      inviteResponse: {
        NOT_SENT: ["Not Sent", "bg-info"],
        SENT_NO_RESPONSE: ["Pending", "bg-warning"],
        CLICKED: ["Accepted", "bg-dark"],
        ACCOUNT_CREATED: ["Accepted", "bg-primary"],
        ACCOUNT_LINKED: ["Accepted", "bg-primary"],
        VERIFICATION_APPLIED: ["Accepted", "bg-primary"],
        EXPIRED: ["Expired", "bg-primary"]
      },
      membershipType: {
        ADMIN: ["Admin", "info"],
        CONTRIBUTOR: ["Contributor", "primary"],
        TECHNICAL: ["Technical", "warning"],
        READER: ["Reader", "danger"],
        NEWSFEED: ["NewsFeed", "primary"],
        DISABLED: ["Disabled", "primary"]
      }
    };
  }
  componentDidMount() {
    this.setState({
      usersInvite: this.props.usersInvite,
      isLoading: true,
      filteredInvite: this.props.filteredInvite
    });
  }

  renderBadge = membershipType => {
    // console.log(membershipType);
    switch (membershipType) {
      case "ADMIN":
        return (
          <Badge variant={"none"} className="badge badge-admin">
            {" "}
            {membershipType}
          </Badge>
        );
      case "CONTRIBUTOR":
        return (
          <Badge className="badge badge-contributor" variant="primary">
            {membershipType}
          </Badge>
        );
      case "TECHNICAL":
        return (
          <Badge className="badge badge-technical" variant="warning">
            {membershipType}
          </Badge>
        );
      case "READER":
        return (
          <Badge className="badge badge-reader" variant="danger">
            {membershipType}
          </Badge>
        );
      case "DISABLED":
        return (
          <Badge className="badge badge-disabled" variant="danger">
            {membershipType}
          </Badge>
        );
      case "NEWSFEED":
        return (
          <Badge className="badge badge-newsfeed" variant="danger">
            {membershipType}
          </Badge>
        );
      default:
        return <Badge variant="primary">{membershipType}</Badge>;
    }
  };

  inviteResponse = inviteResponse => {
    console.log(this.state.inviteResponse, inviteResponse);
    return (
      <span
        className={`d-inline-block text-white pl-2 pr-2 ${this.state.inviteResponse[inviteResponse][1]}`}
      >
        {this.state.inviteResponse[inviteResponse][0]}
      </span>
    );
  };

  revokeInvite = async invideId => {
    try {
      await inviteAPI.revokeUsersInvite(invideId).then(response => {
        this.props.onRevokeInvite(invideId);
        toast.success("Invite Revoked");
      });
    } catch (err) {
      toast.error("An issue occurred whilst revoking the invite");
    }
  };
  resendInvite = async invideId => {
    try {
      await inviteAPI.resendUsersInvite(invideId).then(response => {
        toast.success("Invite Resent");
        this.setState({
          usersInvite: response,
          isLoading: true
        });
      });
    } catch (err) {
      toast.error("An issue occurred whilst resending the invite");
    }
  };

  render() {
    const { filteredInvite } = this.props;

    return (
      <div className="invites-list">
        {filteredInvite.map((usersInvite, key) => (
          <Row
            key={key}
            className="justify-content-md-center list-seprator no-gutters position-relative hasanimation"
          >
            <Col xs={6} lg={2} className="pl-0 user-name">
              {usersInvite.firstName} {usersInvite.lastName}
            </Col>
            <Col xs={6} lg={2}>
              {this.renderBadge(usersInvite.membershipType)}
            </Col>
            <Col lg="2" className="user-email">
              {usersInvite.emailAddress}
            </Col>
            <Col xs={5} lg={2} className="user-invite">
              <span className="text-light">Invited </span>
              {moment(usersInvite.dateInviteSent).format(dataFormat.MEDIUM)}
            </Col>
            <Col xs={5} lg={2} className="user-accept">
              <span className="text-light ">Accepted </span>{" "}
              {usersInvite.dateInviteRespondedTo != null
                ? moment(usersInvite.dateInviteRespondedTo).format(
                    dataFormat.MEDIUM
                  )
                : "Waiting"}
            </Col>
            <Col xs={2} lg={2} className="pr-0 text-right user-status">
              {this.inviteResponse(usersInvite.inviteResponse)}
            </Col>
            {usersInvite.rollUpStatus !== "CREATED" && (    //disable button to particular status
              <div className="invite-property">
                <ul className="d-flex list-unstyled">
                  <li
                    className="revoke mr-auto"
                    onClick={() => this.revokeInvite(usersInvite.id)}
                  >
                    <a>
                      <img src={Bantimage} alt="ban" />{" "}
                      <span>Revoke Invite</span>
                    </a>
                  </li>
                  <li
                    className="resend"
                    onClick={() => this.resendInvite(usersInvite.id)}
                  >
                    <a>
                      <img src={Resendimage} alt="resend" />{" "}
                      <span>Resend Invite</span>
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </Row>
        ))}
        {filteredInvite.length === 0 && (
          <p className="text-center">No invited user found</p>
        )}
      </div>
    );
  }
}

export default InviteList;
