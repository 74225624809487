import React, { Component } from "react";
import { Button, ListGroup } from "react-bootstrap";
import { Select } from "react-inputs-validation";
import Rule from "./rule";
import PropTypes from "prop-types";

const propTypes = {
  ruleGroup: PropTypes.object.isRequired,
  orderPaymentStatuses: PropTypes.array.isRequired,
  editable: PropTypes.bool.isRequired,
  groupIndex: PropTypes.number.isRequired,
  onGroupRuleChange: PropTypes.func.isRequired,
  onAddRule: PropTypes.func.isRequired,
  onDeleteRule: PropTypes.func.isRequired,
  onNewCancel: PropTypes.func.isRequired,
  onRuleMatchCondition: PropTypes.func.isRequired,
  onRuleSave: PropTypes.func.isRequired,
};

class RuleGroup extends Component {
  render() {
    const {
      ruleGroup,
      onGroupRuleChange,
      groupIndex,
      orderPaymentStatuses,
      onAddRule,
      onDeleteRule,
      onNewCancel,
      onRuleMatchCondition,
      onRuleSave,
      editable
    } = this.props;
    
    return (
      <div className="trait-body-content p-4 mb-4">
        <ListGroup as="ul">
          <ListGroup.Item as="li">
            <span> Match </span>
            <div className="trait-wrap">
              <Select
                tabIndex="4"
                id={"fieldType"}
                name={"fieldType"}
                value={ruleGroup.clausesAndOr}
                disabled={!editable}
                optionList={[
                  { id: "OR", name: "ANY" },
                  { id: "AND", name: "ALL" }
                ]}
                classNameSelect="select-control"
                classNameWrapper="form-control select-control-wrapper"
                classNameContainer="dropdown-without-title"
                classNameOptionListContainer="select-control-option"
                classNameOptionListItem=""
                customStyleSelect={{}}
                customStyleWrapper={{}}
                customStyleContainer={{}}
                customStyleOptionListContainer={{}}
                customStyleOptionListItem={{}}
                onChange={e => onGroupRuleChange(e, groupIndex)}
              />
 
            </div>
            <span> of the specified conditions </span>
          </ListGroup.Item>
          {ruleGroup.traitRules.map((rule, key) => (
            <Rule
              key={key}
              rule={rule}
              groupIndex={groupIndex}
              ruleIndex={key}
              orderPaymentStatuses={orderPaymentStatuses}
              onDeleteRule={onDeleteRule}
              onNewCancel={onNewCancel}
              onRuleMatchCondition={onRuleMatchCondition}
              onRuleSave={onRuleSave}
              editable={editable}
            />
          ))}
        </ListGroup> 
 {ruleGroup.traitRules.filter(item => item.new === true).length >= 1 ? " " : <Button
          variant="secondary mt-3 align-self-start add-link"
          hidden={!editable}
          onClick={() => onAddRule(groupIndex)}
        >
          + Add Another Condition
        </Button>  }
        
      </div>
    );
  }
}

RuleGroup.propTypes = propTypes;

export default RuleGroup;
