import React, { Component } from "react";
import welcomescreen from "../../../assets/img/welcome-screen.jpg";
import lightLogo from "../../../assets/img/brand/Logo-light.png"

class BannerPanel extends Component {
  render() {
    return (
      <div
        className="image-wrap"
        style={{
          backgroundImage: `url(${welcomescreen})`
        }}
        >
          <img src={lightLogo} alt="" />
        </div>
      
    );
  }
}

export default BannerPanel;
