import ApiService from "./tenantApiService";

 

function handleOrganisationNotification(response) {
  if (response && response.newsfeedNotificationCount) {
    sessionStorage.setItem(
      "newsfeedNotificationCount",
      JSON.stringify(response.newsfeedNotificationCount)
    );
    return response.newsfeedNotificationCount;
  }
  return null;
}



function checkOrganisationNotification() {
 
  const requestUrl =   "/notifications";
  return ApiService.get(requestUrl)
    .then(response => {
      return response.data;
    })
    .then(handleOrganisationNotification);
}

export default {
  checkOrganisationNotification
};
