import jwtDecode from "jwt-decode";
import ApiService from "./apiService";
import axios from "axios";
import { getUrlVars } from "../views/Main/Customers/customerdata/segmentUtils";

let sessionUser = JSON.parse(sessionStorage.getItem("user"));
let refreshToken = localStorage.getItem("refresh_token");

let refreshPromise = null;


function handleAuthShopifyUser(response) {
  // successful response if there's a jwt token in the response
  let userTokenState = response.user_token_state;
  let shopifyUser = response.shopify_user
  sessionStorage.setItem("registeredShopUser",  JSON.stringify(response.shopify_user));
  console.log("userTokenState", userTokenState)
  if(userTokenState && userTokenState.access_token) {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    let decodedToken = jwtDecode(userTokenState.access_token) || {};

    let userData = {
      ...userTokenState,
      decoded_token: decodedToken,
      admin: decodedToken.isAdmin
    };

    // store session user in storage
    sessionUser = userData;
    sessionStorage.setItem("user", JSON.stringify(sessionUser));

    // if refresh token is null, keep the current token
    if(userTokenState.refresh_token) {
      refreshToken = userTokenState.refresh_token;
      localStorage.setItem("refresh_token", refreshToken);
    }

    ApiService.defaults.headers.common["Authorization"] =
            "Bearer " + userTokenState.access_token;

    return userData;
  }

  return null;
}

function handleAuth(response) {
  // successful response if there's a jwt token in the response
  if (response && response.access_token) {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    let decodedToken = jwtDecode(response.access_token) || {};

    let userData = {
      ...response,
      decoded_token: decodedToken,
      admin: decodedToken.isAdmin
    };

    // store session user in storage
    sessionUser = userData;
    sessionStorage.setItem("user", JSON.stringify(sessionUser));

    // if refresh token is null, keep the current token
    if (response.refresh_token) {
      refreshToken = response.refresh_token;
      localStorage.setItem("refresh_token", refreshToken);
    }

    ApiService.defaults.headers.common["Authorization"] =
      "Bearer " + response.access_token;

    return userData;
  }

  return null;
}

function getLoginData(email, password) {
  const requestUrl = "auth/login";
  const data = { email, password };

  // use axios rather than API service for login, so 401 doesn't redirect
  return axios
    .post(requestUrl, data)
    .then(response => {
      return response.data;
    });
}

function login(email, password) {
  return getLoginData(email, password).then(handleAuth);
}

function loginFromShopify(shopifyToken) {
  return getShopifyUserLoginData(shopifyToken).then(handleAuthShopifyUser)
}

function getShopifyUserLoginData(shopifyToken) {
  const requestUrl = `auth/public/shopify/login?shopifyToken=${shopifyToken}`
  return axios
          .post(requestUrl)
          .then(response => {
            return response.data;
          })
}


function createUser(firstName, lastName, email, password, token, submissionId, shopifyHost, idInExternalSystem) {
  let requestUrl = "";
  if (token !== undefined && token !== "") {
    requestUrl = "context/public/user?inviteToken=" + token;
  } else if (!!shopifyHost) {
    requestUrl = `context/public/user?shopifyHost=${shopifyHost}`;
  } else {
    requestUrl = "context/public/user";
  }

  const data = { firstName, lastName, emailAddress: email, password, submissionId, idInExternalSystem };

  // use axios rather than API service for login, so 401 doesn't redirect
  return axios
    .post(requestUrl, data)
    .then(response => {
      console.log(response);
      return response.data;
    })
    .then(handleAuth);
}

function checkShopifyUser(shopifyToken, userId) {
  const requestUrl = `/context/public/user/shopify/check?shopifyToken=${shopifyToken}&userId=${userId}`;
  return axios
          .get(requestUrl)
          .then(response => {
            console.log('shopify user: ', response);
            return response.data;
          });
}

function emailAuthentiction(token) {
  const requestUrl = "context/public/user/verify?token=" + token;

  // use axios rather than API service for login, so 401 doesn't redirect
  return axios
    .post(requestUrl)
    .then(response => {
      return response.data;
    })
    .then(handleAuth);
}

function verifyUser(token) {
  const requestUrl = "context/public/user/verify?token=" + token;

  // use axios rather than API service for login, so 401 doesn't redirect
  return axios.post(requestUrl).then(response => {
    return response.data;
  });
}

function userAuthentiction(token) {
  const requestUrl = "context/public/verify?token=" + token;

  // use axios rather than API service for login, so 401 doesn't redirect
  return axios.post(requestUrl).then(response => {
    // console.log(response);
    return response.data;
  });
}

function passwordResetRequest(email) {
  const requestUrl = "context/public/user/password/reset?email=" + email;

  // use axios rather than API service for login, so 401 doesn't redirect
  return axios.post(requestUrl).then(response => {
    return response.data;
  });
}

function setPassword(data, token) {
  const requestUrl = "context/public/user/password/change?token=" + token;

  // use axios rather than API service for login, so 401 doesn't redirect
  return axios
    .post(requestUrl, data)
    .then(response => {
      return response.data;
    })
    .then(handleAuth);
}

function logout() {
  sessionStorage.removeItem("user");
  localStorage.removeItem("userAccountData");
  localStorage.removeItem("refresh_token");

  window.location = "/login";
}

function getAccessToken(refreshToken) {
  const params = new URLSearchParams();
  params.append("refreshToken", refreshToken);

  return ApiService.post("auth/refresh", params)
    .then(response => {
      return response.data;
    })
    .then(resp => {
      refreshPromise = null;
      if (resp && resp.access_token) {
        return handleAuth(resp);
      } else {
        throw new Error("No access token returned");
      }
    })
    .catch(logout);
}

function refreshAccessToken() {
  if (!refreshToken) {
    logout();
  }

  let promise = refreshPromise;

  if (!promise) {
    promise = getAccessToken(refreshToken).then(resp => {
      refreshPromise = null;
      return handleAuth(resp);
    });

    refreshPromise = promise;
  }

  return promise;
}

function getCurrentAccessToken() {
  if (!sessionUser) {
    return null;
  }

  return sessionUser.access_token;
}

function isAuthenticated() {
  return Boolean(refreshToken);
}

function isAdmin() {
  return Boolean(sessionUser && sessionUser.admin);
}


function needToResetSession() {
  let vars = getUrlVars(window.location.href);
  return vars?.resetSession === true || vars?.resetSession === 'true';
}

function resetOldSession() {
  if(needToResetSession()) {
    console.log("Cleaning sessions storage...")
    sessionUser = undefined;
    refreshToken = undefined;
    sessionStorage.clear();
    localStorage.clear();
  }
}

export default {
  getLoginData,
  login,
  logout,
  getCurrentAccessToken,
  refreshAccessToken,
  isAuthenticated,
  isAdmin,
  createUser,
  emailAuthentiction,
  passwordResetRequest,
  setPassword,
  userAuthentiction,
  verifyUser,
  checkShopifyUser,
  loginFromShopify,
  resetOldSession,
  needToResetSession,
};
