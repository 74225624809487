import React, { Component } from "react";
import { ListGroup } from "react-bootstrap";
class DataList extends Component {
  render() {
    const { customerList } = this.props;

    return (

        <ListGroup as="ul">
          {customerList.map(customer => (
            <ListGroup.Item
              as="li"
              className="position-relative"
              key={customer.id}
              onClick={()=> {
                this.props.onCustomerDetail(customer.id)
              }}
            >
              {customer.firstName} {""}
              {customer.lastName}
              <p className="d-flex text-profile m-0">
                <span className="mr-3">
                  Last Active <strong>{customer.attribute1}</strong>
                </span>
                <span>lifetime value £{customer.attribute2}</span>
              </p>
              <button className="position-absolute border-0  p-0">
                <i className="icon-chevron-thin-right" />
              </button>
            </ListGroup.Item>
          ))}
        </ListGroup>

    );
  }
}

export default DataList;
