import ApiService from "./tenantApiService";

function getNewsfeed() {
  const requestUrl = "/newsfeed/headlines?page_no=1";
  return ApiService.get(requestUrl).then(response => {
    return response.data;
  });
}

function getCardHistory(id) {
  const requestUrl = "/newsfeed/history/" + id;
  return ApiService.get(requestUrl).then(response => {
    return response.data;
  });
}

function updatePinStatus(pinRequest) {
  const requestUrl = "/newsfeed/pinned";
  return ApiService.post(requestUrl, pinRequest).then(response => {
    return response.data;
  });
}

function allPinnedCards(pinRequest) {
  const requestUrl = "/newsfeed/pinned/all";
  return ApiService.get(requestUrl).then(response => {
    return response.data;
  });
}

function latestPinnedCards(pinRequest) {
  const requestUrl = "/newsfeed/pinned/latest";
  return ApiService.get(requestUrl).then(response => {
    return response.data;
  });
}
function contextPinnedCards(stackType, paramData) {
  let requestUrl = "/newsfeed/all?stackType=" + stackType;
  requestUrl =
    paramData !== null ? requestUrl + "&id=" + paramData : requestUrl;
  return ApiService.get(requestUrl).then(response => {
    return response.data;
  });
}

function getDataSources() {
  const requestUrl = "/newsfeed/full/DATA_SOURCE";
  return ApiService.get(requestUrl).then(response => {
    return response.data;
  });
}

function getDataSourcesStatus() {
  const requestUrl = "/newsfeed/history/DATA_SOURCE/filters";
  return ApiService.get(requestUrl).then(response => {
    return response.data;
  });
}


function setDataSourcesStatus(ids) {
  const requestUrl = "/newsfeed/history/DATA_SOURCE/filters";
  return ApiService.post(requestUrl, ids).then(response => {
    return response.data;
  });
}


export default {
  getNewsfeed,
  getCardHistory,
  allPinnedCards,
  updatePinStatus,
  latestPinnedCards,
  contextPinnedCards,
  getDataSources,
  getDataSourcesStatus,
  setDataSourcesStatus
};
