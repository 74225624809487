import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import "./assets/style.css";
import MixingDeck from "./MixingDeck";
import Options from "./Options";
import DefaultContent from "./DefaultContent";

class ContentRecommendations extends Component {
  state = {
    currentEnrichment: {},
    isLoading: false
  };
  componentDidMount() {
    this.setState({
      currentEnrichment: this.props.currentEnrichment,
      isLoading: true
    });
  }

  componentWillReceiveProps(prevProps) {
    if (prevProps.currentEnrichment !== this.state.currentEnrichment) {
      this.setState({ currentEnrichment: prevProps.currentEnrichment });
    }
  }

  updateCurrentEnrichment = currentEnrichment => {
    this.setState({ currentEnrichment });
  };

  render() {
    const { currentEnrichment, isLoading } = this.state;
    return (
      isLoading && (
        <div className=" tab-inner-main bg-white">
          <Tabs defaultActiveKey="mixing">
            <Tab eventKey="mixing" title="Mixing Deck">
              <MixingDeck
                currentEnrichment={currentEnrichment}
                onUpdateValue={this.updateCurrentEnrichment}
                onAttributeChange={this.props.onAttributeChange}
              />
            </Tab>
            <Tab eventKey="default" title="Default Content">
              <DefaultContent
                currentEnrichment={currentEnrichment}
                onAttributeChange={this.props.onAttributeChange}
              />
            </Tab>
            <Tab eventKey="options" title="Options">
              <Options
                currentEnrichment={currentEnrichment}
                onAttributeChange={this.props.onAttributeChange}
              />
            </Tab>
          </Tabs>
        </div>
      )
    );
  }
}

export default ContentRecommendations;
