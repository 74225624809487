import React, { Component } from "react";
import { Col, Row, Table } from "react-bootstrap";

import AnalyticChart from "../chart/AnalyticChart";
import "../../assets/css/analytic-card-detail.scss";
import moment from "moment";
import BarChartVertical from "../chart/BarChartVertical";
import LineChart from "../chart/LineChart";
import equal from "fast-deep-equal";

class AnalyticCardDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startLastWeekDate: "",
      endLastWeekDate: "",
      salesLastWeek: "",
      lastWeeksWeekForecast: "",
      lastWeekOrders: "",
      lastWeekCustomers: "",
      lastWeekNewCustomers: "",
      lastWeekNewCustomersPercent: "",
      lastWeekReturningCustomersPercent: "",
      lastWeekAverageOrderValue: "",
      lastYearAverageOrderValue: "",
      last20weeksRanking: "",
      weekData: "",
      quarter0: "",
      quarter1: "",
      quarter2: "",
      quarter3: "",
    };

  }

  componentDidMount() {
    this.setDataCard();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!equal(this.props.cardData, prevProps.cardData)) {
      this.setDataCard();
    }
  }

  setDataCard() {
    const { cardData } = this.props;

    this.setState({
      startLastWeekDate: moment(cardData?.data?.lastWeekDate).format("dddd Do MMMM"),
      endLastWeekDate: moment(cardData?.data?.lastWeekDate).add(6, "days").format("dddd Do MMMM"),
      salesLastWeek: cardData?.data?.salesLastWeek_formatted,
      lastWeeksWeekForecast: cardData?.data?.lastWeeksWeekForecast_formatted,
      lastWeekOrders: cardData?.data?.lastWeekOrders_formatted,
      lastWeekCustomers: cardData?.data?.lastWeekCustomers_formatted,
      lastWeekNewCustomers: cardData?.data?.lastWeekNewCustomers_formatted,
      lastWeekNewCustomersPercent: cardData?.data?.lastWeekNewCustomersPercent_formatted,
      lastWeekReturningCustomersPercent: cardData?.data?.lastWeekReturningCustomersPercent_formatted,
      lastWeekAverageOrderValue: cardData?.data?.lastWeekAverageOrderValue_formatted,
      lastYearAverageOrderValue: cardData?.data?.lastYearAverageOrderValue_formatted,
      last20weeksRanking: cardData?.data?.last20weeksRanking_formatted,
      weekData: cardData?.data?.weekData_formatted,
      quarter0: cardData?.data?.quarter0,
      quarter1: cardData?.data?.quarter1,
      quarter2: cardData?.data?.quarter2,
      quarter3: cardData?.data?.quarter3,
    })
  };

  widthByName(lastWeekOrders) {
    const val = `${lastWeekOrders ?? ''}`;
    return `${90 + (val.length * 10)}px`;
  }

  isActual(quarter) {
    return !!quarter?.status && (quarter.status === 'Actual' || quarter.status === 'ACTUAL');
  }
  render() {
    const { startLastWeekDate, endLastWeekDate, salesLastWeek, lastWeeksWeekForecast, lastWeekOrders, lastWeekCustomers,
      lastWeekNewCustomers, lastWeekNewCustomersPercent, lastWeekReturningCustomersPercent,
      lastWeekAverageOrderValue, lastYearAverageOrderValue, last20weeksRanking,
      weekData, quarter0, quarter1, quarter2, quarter3 } = this.state;

    const { cardData } = this.props;

    return (
        <>
          <div className="analytic-card-detail">
            <Row className="analytic-card-detail_top">
              <Col lg={6} xs={0} className="chart-wrap">
                <div className="">
                  <div className="card-title fs-l fw-bold text-black mb-0 pb-0">Last Week</div>
                  <div className="fs-s text-gray mb-1 mb-md-2">
                    {startLastWeekDate} to {endLastWeekDate}
                  </div>
                </div>
                <div className="card-title">
                  <div className="">
                    <div className="normal text-gray fs-s mb-0">Sales last week</div>
                    <div className="card-title fs-xxxl fw-bold text-black">{salesLastWeek}</div>
                  </div>
                  <div className="card-title mt-0">
                    <Row className="">
                      <Col lg={2} xs={0} style={{width: this.widthByName(lastWeekOrders), paddingLeft: 0}}>
                        <div className="fs-s text-gray">Orders</div>
                        <div className="fs-xl fw-bold text-gray text-nowrap">{lastWeekOrders}</div>
                      </Col>
                      <Col lg={5} xs={0} style={{paddingLeft: 0}}>
                        <div className="fs-s text-gray">Customers</div>
                        <div className="fs-xl fw-bold text-gray">{lastWeekCustomers}</div>
                      </Col>
                    </Row>
                  </div>
                  <div className="card-title mt-2">
                    <div className="fs-xm fw-bold text-gray">
                      {lastWeekNewCustomersPercent}
                      <span> new Customers, </span>
                      {lastWeekReturningCustomersPercent}
                      <span> repeat.</span>
                    </div>
                  </div>
                  <div className="card-title mt-2">
                    {lastWeeksWeekForecast && (
                        <div className="fs-xm fw-bold text-gray">
                          <span> Sales forecast for last week was </span>{lastWeeksWeekForecast}
                        </div>
                    )}
                  </div>
                </div>
                <div className="card-title">
                  <div className="">
                    <div className="fs-s text-gray">Average Order Value</div>
                    <div className="fs-xl fw-bold text-gray">{lastWeekAverageOrderValue}</div>
                  </div>
                  <div className="fs-xm fw-bold text-gray ">
                    <span className="normal text-gray">This is</span>
                    {cardData?.data?.lastWeekAverageOrderValue > cardData?.data?.lastYearAverageOrderValue ?
                        <span className="p-1 fw-bold text-blue">greater</span> :
                        <span className="p-1 fw-bold text-red">lower</span>
                    }

                    <span className="normal text-gray">
                    than your <span className="fw-bold"> 12 </span> month average, which is {" "}
                  </span>
                    <span className="text-wrap fw-bold card-text">{lastYearAverageOrderValue}</span>
                  </div>
                </div>
              </Col>
              <Col lg={6} xs={0} className="chart-wrap">
                <div className="chart_content normal text-gray fs-s mt-4 pt-1">
                  Last weeks Sales Ranking
                </div>
                <div className="chart">
                  <AnalyticChart cardData={cardData}/>
                </div>
                <div className="chart_content normal text-gray fs-xm">
                  This week ranks <span className="fw-bold"> {last20weeksRanking} </span>out of the last <span
                    className="fw-bold">20</span> weeks.
                </div>
              </Col>
            </Row>
            <Row className="d-flex justify-content-between">
              <div className="card-title fs-l fw-bold text-black"
                   style={{margin: "-25px 0 0px 12px", padding: "0 0 10px 0", width: '100%'}}>
                Sales Forecast
              </div>
              <Col lg={6} xs={0} className="chart-wrap mt-5">
                <div style={{height: "20%"}}>
                  <div className="fs-s text-gray">This weeks forecast</div>
                  <div className="fs-number fw-bold text-dark-gray">
                    {" "}{weekData?.weekForecast_formatted}
                  </div>
                  <div className="fs-s text-gray mt-0">
                        <span className="fs-xm fw-bold text-gray">
                          {cardData?.data?.lastYearWeekSalesGrowsPercents_formatted ?? 0}
                        </span>
                    {" "}
                    <span>Growth on last year</span>
                  </div>
                </div>
                <div style={{height: "40%"}} className="mt-4">
                  <div className="">
                  <span className="fs-xm text-gray">
                    Your sales this time last year were {" "}
                    <span className="fs-xm fw-bold text-gray">
                      {cardData?.data?.salesLastYearWeek_formatted ?? 0}
                    </span>
                    {/*{" "} You have been recently trending {" "}*/}
                    {/*<span className="fs-xm fw-bold text-gray">*/}
                    {/*<CountValue value={(cardData?.data?.lastYearWeekForecastGrowsPercents) ?? 0} className="fs-xm fw-bold text-gray"/>%*/}
                    {/*</span>*/}
                    {/*{" "}  higher that this time last year.*/}
                  </span>
                  </div>
                  <div className="fs-xm text-gray mt-2">
                    If you achieve this forecast it would rank <b>{cardData?.data?.forecastWeekPosition_formatted}</b> out
                    of the
                    last <span
                      className="fs-xm fw-bold text-gray">20 </span>
                    weeks.
                  </div>
                </div>
                <div style={{height: "40%"}} lg={6} xs={0} className="mb-1 mt-4 chart-1">
                  <BarChartVertical cardData={cardData}/>
                </div>
                <div style={{marginLeft: "74px"}}>
                  <Table bordered className="monday-bar-chart monday-bar-chart-legend">
                    <tbody>
                    <tr>
                      <td className="fill-forecast"><span className="fs-xm text-white">Forecast</span></td>
                    </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col lg={6} xs={0} className="chart-wrap mt-5 margin-left inherit">
                <div style={{height: "20%"}}>
                  <div className="fs-s text-gray">End of Year Forecast</div>
                  <div className="fs-number fw-bold text-dark-gray">
                    {cardData?.data?.yearForecast_formatted}
                  </div>
                  <div className="fs-m text-gray">
                    {cardData?.data?.fullYearLastWeekForecastChange
                    < 0 ? <span className="text-red fw-bold"> Down </span> :
                        <span className="text-blue fw-bold"> Up</span>
                    }
                    <span className="text-wrap fw-bold card-text">
                     {" "}{cardData?.data?.fullYearLastWeekForecastChange_formatted ?? 0} {" "}
                  </span>
                    <span className="fs-s text-gray">from this time last week.</span>
                  </div>
                </div>
                <div style={{height: "40%"}} className="mt-4">
                  <div className="fs-l fw-bold text-black quarter-forecast-text">Quarter by Quarter Forecast</div>
                  <div className="d-flex" style={{flexWrap: "wrap"}}>
                    <div className="me-lg-3 quarter-forecast-block">
                      <Table bordered className="quarter-forecast">
                        <tbody>
                        <tr className={this.isActual(quarter0) ? "fill-actual" : "fill-forecast"}>
                          <td><span className="fs-xm text-white">{quarter0?.name}</span></td>
                          <td className="w-25">
                                <span className="fs-xm text-white fw-bold">
                                  {cardData?.data?.quarter0_formatted?.forecastValue_formatted}
                                </span>
                          </td>
                        </tr>
                        <tr className={this.isActual(quarter1) ? "fill-actual" : "fill-forecast"}>
                          <td>
                            <span className="fs-xm text-white">{quarter1?.name}</span>
                          </td>
                          <td className="w-25">
                                <span className="fs-xm text-white fw-bold">
                                  {cardData?.data?.quarter1_formatted?.forecastValue_formatted}
                                </span>
                          </td>
                        </tr>
                        <tr className={this.isActual(quarter2) ? "fill-actual" : "fill-forecast"}>
                          <td><span className="fs-xm text-white">{quarter2?.name}</span></td>
                          <td className="w-25">
                                <span className="fs-xm text-white fw-bold">
                                  {cardData?.data?.quarter2_formatted?.forecastValue_formatted}
                                </span>
                          </td>
                        </tr>
                        <tr className={this.isActual(quarter3) ? "fill-actual" : "fill-forecast"}>
                          <td><span className="fs-xm text-white">{quarter3?.name}</span></td>
                          <td className="w-25">
                                <span className="fs-xm text-white fw-bold">
                                  {cardData?.data?.quarter3_formatted?.forecastValue_formatted}
                                </span>
                          </td>
                        </tr>
                        </tbody>
                      </Table>
                    </div>
                    <div>
                      <Table bordered className="quarter-forecast quarter-forecast-legend">
                        <tbody>
                        <tr>
                          <td className="fill-actual"><span className="fs-xm text-white">Actual</span></td>
                          <td className="fill-forecast"><span className="fs-xm text-white">Forecast</span></td>
                        </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
                <div style={{height: "40%"}} lg={6} xs={0} className="mb-1 mt-5 chart-1">
                  <LineChart cardData={cardData}/>
                </div>
              </Col>
            </Row>
          </div>
        </>
    );
  }
};

export default AnalyticCardDetail;
