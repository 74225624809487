import React, { Component } from "react";
import AccountAPI from "../../../../services/account";
import {Select, Textbox} from "react-inputs-validation";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import { toast } from "react-toastify";

import "./assets/styles.css";
import AuthenticationService from "../../../../services/authentication";

import { Form, Col, Row, ListGroup, Button, Collapse } from "react-bootstrap";
import {alertingLevelList} from "../../../../variables/globalValues.json";

class Myprofile extends Component {
  constructor(props) {
    super(props);
    this.myfirstName = React.createRef();
    this.mylastName = React.createRef();
    this.myemailAddress = React.createRef();
    this.myalertingLevel = React.createRef();
    this.mycurrentPassword = React.createRef();
    this.mynewPassword = React.createRef();
    this.myconfirmPassword = React.createRef();

    this.validateForm = this.validateForm.bind(this);
  }

  state = {
    contextUser: "",
    userId: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    alertingLevel: "",
    emailVerified: "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    errors: {},
    hasfirstNameError: true,
    haslastNameError: true,
    hasemailAddressError: true,
    hasAlertingError: true,

    hascurrentPasswordError: true,
    hasnewPasswordError: true,
    hasconfirmPasswordError: true,

    validate: false,
    wantChangePassword: false,
    isPersonalInfoChange:false
  };

  componentDidMount() {
    this.setState({ contextUser: this.props.contextUser });
    this.setState({
      userId: this.props.contextUser.id,
      firstName: this.props.contextUser.firstName,
      lastName: this.props.contextUser.lastName,
      alertingLevel: this.props.contextUser.alertingLevel,
      emailAddress: this.props.contextUser.isShopify ? this.props.contextUser.marketingEmailAddress : this.props.contextUser.emailAddress,
      emailVerified: this.props.contextUser.emailVerified
    });

    this.setState({
      hasfirstNameError: false,
      haslastNameError: false,
      hasemailAddressError: false
    });
  }

  toggleValidating(validate) {
    this.setState({ validate });
  }

  validateForm(e) {
    e.preventDefault();
    this.toggleValidating(true);
    const {
      hasfirstNameError,
      haslastNameError,
      hasemailAddressError,
      hascurrentPasswordError,
      hasnewPasswordError,
      hasconfirmPasswordError,
      wantChangePassword
    } = this.state;

    if (!hasfirstNameError && !haslastNameError && !hasemailAddressError) {
      if (wantChangePassword) {
        if (
          !hascurrentPasswordError &&
          !hasnewPasswordError &&
          !hasconfirmPasswordError
        ) {
          this.saveUserPassword();
          if(this.state.isPersonalInfoChange){
            this.savePersonalInformation();
          }
        }
      }

      if (!wantChangePassword && this.state.isPersonalInfoChange) {
        this.savePersonalInformation();
      }
    }
  }

  savePersonalInformation = async () => {
    let formData = {
      id: this.state.userId,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      emailAddress: this.state.emailAddress,
      alertingLevel: this.state.alertingLevel,
      emailVerified: this.state.emailVerified
    };

    try {
      await AccountAPI.updateAccount(formData).then(response => {
        toast.success("Personal information updated");
        this.props.updateContextUser(formData);
        //this.props.updateContexUser(formData)
        localStorage.setItem("checkChange", false);
      });
    } catch (ex) {
      toast.error("Error while updating personal information");
    }
    this.setState({isPersonalInfoChange:false})
  };

  saveUserPassword = async () => {
    let formData = {
      oldPassword: this.state.currentPassword,
      newPassword: this.state.newPassword
    };

    try {
      await AccountAPI.updatePassword(formData)
        .then(response => {
          toast.success("Password updated");
          this.clearUserPassword();
          AuthenticationService.refreshAccessToken();
        })
        .catch(function(error) {
          if (error.response.status === 400) {
            toast.error(error.response.data.debugMessage);
          } else if (error.response.status === 401) {
            toast.error(error.response.data.message);
          }
          localStorage.setItem("checkChange", false);
        });
    } catch (error) {
      toast.error("Error while updating password");
    }
  };

  clearUserPassword = () => {
    this.setState({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      wantChangePassword: false
    });
  };

  wantChangePassword = () => {
    this.setState({ wantChangePassword: !this.state.wantChangePassword });
  };

  render() {
    const {
      firstName,
      lastName,
      emailAddress,
      alertingLevel,
      currentPassword,
      newPassword,
      confirmPassword,
      validate,
      wantChangePassword
    } = this.state;

    return (
      <main className="my-wrapper">
        <div className="my-container">
          <section className="form-bg">
            <section className="profile-info">
              <ListGroup as="ul" className="d-flex flex-row align-items-center">
                <ListGroup.Item as="li" className="profile-user dark">
                  <span className={`user-default-bg`}>
                    {this.props.contextUser.firstName !== undefined
                      ? this.props.contextUser.firstName.charAt(0)
                      : ""}
                    {this.props.contextUser.lastName !== undefined
                      ? this.props.contextUser.lastName.charAt(0)
                      : ""}
                  </span>
                </ListGroup.Item>

                <ListGroup.Item as="li" className="text-profile ">
                  <h5>
                    {this.props.contextUser.firstName}{" "}
                    {this.props.contextUser.lastName}
                  </h5>
                  <p>{this.props.contextUser.isShopify ? this.props.contextUser.marketingEmailAddress : this.props.contextUser.emailAddress}</p>
                </ListGroup.Item>
              </ListGroup>
            </section>
            <Row>
              <Col sm={12} md={7}>
                <h5 className="mt-3  mb-3">Personal Information</h5>
                <Form className="form-wrap" onSubmit={this.validateForm}>
                  <Form.Group>
                    <Row>
                      <Col md={6}>
                        <Form.Label>First Name</Form.Label>
                        <Textbox
                          ref={this.myfirstName}
                          classNameInput="form-control"
                          classNameContainer="custome-input"
                          tabIndex="1"
                          id={"firstName"}
                          name="firstName"
                          type="text"
                          validate={validate}
                          validationCallback={res =>
                            this.setState({
                              hasfirstNameError: res,
                              validate: false
                            })
                          }
                          value={firstName}
                          placeholder="Your First Name here"
                          onChange={(firstName, e) => {
                            this.setState({ firstName,isPersonalInfoChange:true }, () =>
                              this.props.onCheckChange()
                            );
                          }}
                          onBlur={e => {
                            console.log(e);
                          }}
                          validationOption={{
                            name: "First Name",
                            check: true,
                            required: true
                          }}
                        />
                      </Col>
                      <Col md={6}>
                        <Form.Label>Last Name</Form.Label>
                        <Textbox
                          ref={this.mylastName}
                          classNameInput="form-control"
                          classNameContainer="custome-input"
                          tabIndex="2"
                          id={"lastName"}
                          name="lastName"
                          type="text"
                          validate={validate}
                          validationCallback={res =>
                            this.setState({
                              haslastNameError: res,
                              validate: false
                            })
                          }
                          value={lastName}
                          placeholder="Your Last Name here"
                          onChange={(lastName, e) => {
                            this.setState({ lastName,isPersonalInfoChange:true }, () =>
                              this.props.onCheckChange()
                            );
                            console.log(e);
                          }}
                          onBlur={e => {
                            console.log(e);
                          }}
                          validationOption={{
                            name: "Last Name",
                            check: true,
                            required: true
                          }}
                        />
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Row className={'alert-name-section'}>
                    <Form.Group as={Col} controlId="formGridIP">
                      <Form.Label>Alert Level</Form.Label>
                      <Select
                          name={"alertingLevel"}
                          tabIndex="3"
                          value={alertingLevel || "null"}
                          optionList={alertingLevelList}
                          classNameSelect="select-control"
                          classNameWrapper="form-control select-control-wrapper selection-type"
                          classNameContainer=""
                          classNameOptionListContainer="select-control-option"
                          classNameOptionListItem=""
                          validate={validate}
                          validationCallback={res =>
                              this.setState({hasAlertingError: res, validate: false})
                          }
                          customStyleSelect={{}}
                          customStyleWrapper={{}}
                          customStyleContainer={{}}
                          customStyleOptionListContainer={{
                            maxHeight: "300px",
                            overflow: "auto"
                          }}
                          customStyleOptionListItem={{}}
                          onChange={(alertingLevel, e) => {
                            debugger;
                            this.setState({ alertingLevel,isPersonalInfoChange:true}, () => {
                              this.state.contextUser.alertingLevel = alertingLevel;
                              this.props.contextUser.alertingLevel = alertingLevel;
                              this.props.onCheckChange();
                                }
                            );
                            console.log(e);
                          }}
                          onBlur={e => {
                          }}
                          validationOption={{
                            name: "Alerting Level",
                            check: true,
                            required: true
                          }}
                      />
                    </Form.Group>
                  </Form.Row>

                  <Form.Group>
                    <Row>
                      <Col md={12}>
                        <Form.Label>E-mail</Form.Label>
                        <Textbox
                          ref={this.myemailAddress}
                          classNameInput="form-control disabled"
                          classNameContainer="custome-input"
                          autoComplete="off"
                          disabled={true}
                          tabIndex="3"
                          id={"emailAddress"}
                          name="emailAddress"
                          type="text"
                          validate={validate}
                          validationCallback={res =>
                            this.setState({
                              hasemailAddressError: res,
                              validate: false
                            })
                          }
                          value={emailAddress}
                          placeholder="Your Email Address here"
                          // onChange={(emailAddress, e) => {
                          //   this.setState({ emailAddress }, () =>
                          //     this.props.onCheckChange()
                          //   );
                          //   console.log(e);
                          // }}
                          onBlur={e => {
                            console.log(e);
                          }}
                          validationOption={{
                            name: "Email Address",
                            check: true,
                            required: true,

                            reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            regMsg: "Enter Valid Email Address"
                          }}
                        />
                      </Col>
                    </Row>
                  </Form.Group>

                  {!this.props.contextUser.isShopify ?
                  <Form.Group>
                    <Button
                      variant="info"
                      size="sm"
                      className={`has-icon ${wantChangePassword && "active"}`}
                      onClick={this.wantChangePassword}
                      aria-controls="change-password-section"
                      aria-expanded={wantChangePassword}
                    >
                      <i
                        className={`icon-check_circle ${wantChangePassword &&
                          "active"}`}
                      />{" "}
                      Change Password
                    </Button>
                  </Form.Group>
                      : ""
                  }

                  {!this.props.contextUser.isShopify ?
                  <Collapse in={wantChangePassword}>
                    <div className="change-password-section">
                      {wantChangePassword && (
                        <React.Fragment>
                          <Form.Group className="bordered">
                            {" "}
                            <Row>
                              <Col md={6}>
                                <Form.Label>Current Password</Form.Label>
                                <Textbox
                                  ref={this.mycurrentPassword}
                                  classNameInput="form-control"
                                  classNameContainer="custome-input"
                                  tabIndex="4"
                                  id={"currentPassword"}
                                  name="currentPassword"
                                  type="password"
                                  validate={validate}
                                  validationCallback={res =>
                                    this.setState({
                                      hascurrentPasswordError: res,
                                      validate: false
                                    })
                                  }
                                  value={currentPassword}
                                  placeholder=""
                                  onChange={(currentPassword, e) => {
                                    this.setState({ currentPassword }, () =>
                                      this.props.onCheckChange()
                                    );
                                    console.log(e);
                                  }}
                                  onBlur={e => {
                                    console.log(e);
                                  }}
                                  validationOption={{
                                    name: "Current Password",
                                    check: true,
                                    required: true
                                  }}
                                />
                              </Col>
                            </Row>
                          </Form.Group>
                          <Form.Group className="bordered">
                            <Row>
                              <Col md={6}>
                                <Form.Label>New Password</Form.Label>
                                <Textbox
                                  ref={this.mynewPassword}
                                  classNameInput="form-control"
                                  classNameContainer="custome-input"
                                  tabIndex="5"
                                  id={"newPassword"}
                                  name="newPassword"
                                  type="password"
                                  validate={validate}
                                  validationCallback={res =>
                                    this.setState({
                                      hasnewPasswordError: res,
                                      validate: false
                                    })
                                  }
                                  value={newPassword}
                                  placeholder=""
                                  onChange={(newPassword, e) => {
                                    this.setState({ newPassword }, () =>
                                      this.props.onCheckChange()
                                    );
                                    console.log(e);
                                  }}
                                  onBlur={e => {
                                    console.log(e);
                                  }}
                                  validationOption={{
                                    name: "New Password",
                                    check: true,
                                    required: true,
                                    //One upper, One Lower, One Number, No space, 8-16 character check
                                    reg: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,16}$/,
                                    regMsg:
                                      "Password does not meet requirements"
                                  }}
                                />
                              </Col>
                              <Col md={6}>
                                <Form.Label>Confirm Password</Form.Label>
                                <Textbox
                                  ref={this.myconfirmPassword}
                                  classNameInput="form-control"
                                  classNameContainer="custome-input"
                                  tabIndex="6"
                                  id={"confirmPassword"}
                                  name="confirmPassword"
                                  type="password"
                                  validate={validate}
                                  validationCallback={res =>
                                    this.setState({
                                      hasconfirmPasswordError: res,
                                      validate: false
                                    })
                                  }
                                  value={confirmPassword}
                                  placeholder=""
                                  onChange={(confirmPassword, e) => {
                                    this.setState({ confirmPassword }, () =>
                                      this.props.onCheckChange()
                                    );
                                    console.log(e);
                                  }}
                                  onBlur={e => {
                                    console.log(e);
                                  }}
                                  validationOption={{
                                    name: "Confirm Password",
                                    check: true,
                                    required: true,
                                    //One upper, One Lower, One Number, No space, 8-16 character check
                                    reg: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,16}$/,
                                    regMsg:
                                      "Password does not meet requirements",
                                    customFunc: res => {
                                      if (res !== this.state.newPassword) {
                                        return "Please ensure the new passwords match";
                                      }
                                      return true;
                                    }
                                  }}
                                />
                              </Col>
                            </Row>
                          </Form.Group>
                        </React.Fragment>
                      )}
                    </div>
                  </Collapse>
                      : ""
                  }

                  <Button variant="primary submit" type="submit">
                    Save Changes
                  </Button>

                </Form>
              </Col>
            </Row>
          </section>
        </div>
      </main>
    );
  }
}

export default Myprofile;
