// import React, { Component } from 'react';
import React from "react";
const CardControlers = props => {
  return (
    <div className="card-controlers" style={{...props.style}}>
            
      {props.onCardHistory && (
        <span
        className="icon-history"
        onClick={() => props.onCardHistory(props.cardContent)}
      />
      )}
      
      {props.onPinnedCard && (
        <span
          onClick={() => props.onPinnedCard(props.cardContent)}
          className={props.cardContent.pinned ? "active icon-pin" : "icon-pin"}
        />
      )}

    </div>
  );
};

export default CardControlers;
