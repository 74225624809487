import React from "react";
import "./styles.scss";

const PurchaseTable = ({name, funnelMetric_formatted}) => {

    return (
        <>
            <div className="row display-flex-max576 display-none pb-3 border-top1px-dotted pt-2">
                <div className="col-7 col-sm-4 col-lg-4 font-size-20px color-brown">
                    <strong>{name}</strong>
                </div>
            </div>
            <div className="row display-flex-max576 display-none pb-3">
                <div className="col-1">

                </div>
                <div className="col-11">
                    <div className="content-row"
                         style={{display: 'flex', justifyContent: 'space-evenly'}}>
                        <p className="unique color-706 width168px text-right"><strong>Customers</strong>
                        </p>
                        <p className="unique color-706 width60px">{funnelMetric_formatted?.metricCustomerNumber_formatted}</p>
                        <p/>
                    </div>
                    <div className="content-row pt-2"
                         style={{display: 'flex', justifyContent: 'space-evenly'}}>
                        <p className="unique color-706 width168px text-right">
                            <strong>Conversion</strong></p>
                        <p className="width60px unique color-706">
                            {funnelMetric_formatted ? "0%" : funnelMetric_formatted?.conversion_formatted}
                        </p>
                        <p/>
                    </div>
                    <div className="content-row pt-2"
                         style={{display: 'flex', justifyContent: 'space-evenly'}}>
                        <p className="unique color-706 width168px text-right"><strong>Avg. Conversion
                            Time</strong></p>
                        <p className="unique color-706 width60px">{funnelMetric_formatted?.averageConversionTimeInSeconds_formatted}</p>
                        <p/>
                    </div>
                    <div className="content-row pt-2"
                         style={{display: 'flex', justifyContent: 'space-evenly'}}>
                        <p className="unique color-706 width168px text-right"><strong>Revenue</strong>
                        </p>
                        <p className="unique color-706 width60px">{funnelMetric_formatted?.revenue_formatted}</p>
                        <p/>
                    </div>
                    <div className="content-row pt-2"
                         style={{display: 'flex', justifyContent: 'space-evenly'}}>
                        <p className="unique color-706 width168px text-right"><strong>% of
                            Registered</strong></p>
                        <p className="width60px unique color-706">
                            {funnelMetric_formatted?.registeredCustomersPercentage_formatted}
                        </p>
                        <p/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PurchaseTable;