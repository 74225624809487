import React, { Component } from "react";
import { ListGroup } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ContentSearch from "../../../component/ContentSearch";
import EnrichmentsAPI from "../../../../../../../services/enrichments";

import _ from "lodash";
// import debounce from "lodash";

import ListName from "./listItem";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 7.5;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,

  // change background colour if dragging
  background: isDragging && "#ffffff",
  border: isDragging && "2px dashed #9FCE1D",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  // background: isDraggingOver ? '#efefef' : 'white',
  padding: 0
});

class DefaultContent extends Component {
  state = {
    contentList: null,
    items: "",
    itemsCount: "",
    isLoading: false,
    filtered: [],
    searchValue: ""
  };
  componentWillMount() {
    this.handleDefaultContent();

    this.setState({
      items: this.props.currentEnrichment.productRecDefaultProductsIds,
      currentEnrichment: this.props.currentEnrichment
    });
  }

  componentWillReceiveProps(prevProps) {
    if (this.state.currentEnrichment !== prevProps.currentEnrichment) {
      this.setState(
        {
          items: prevProps.currentEnrichment.productRecDefaultProductsIds,
          currentEnrichment: prevProps.currentEnrichment
        },
        () => this.filteredResult(false)
      );
    }
  }

  handleDefaultContent = async event => {
    try {
      await EnrichmentsAPI.getProductList().then(response => {
        this.setState(
          {
            contentList: response,
            isLoading: true
          },
          () => this.filteredResult()
        );
      });
    } catch (err) {}
  };

  id2List = {
    droppable: "items"
  };

  getList = id => this.state[this.id2List[id]];

  onDragEnd = result => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        this.getList(source.droppableId),
        source.index,
        destination.index
      );

      let state = { items };

      if (source.droppableId === "droppable2") {
        state = { selected: items };
      }
      this.props.onAttributeChange("productRecDefaultProductsIds", items);
      this.setState(state);
    } else {
      const result = move(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination
      );

      this.setState({
        items: result.droppable
      });
    }
  };

  handleDelete = list => {
    let items = [...this.state.items];

    var newitems = _.remove(items, function(n) {
      return n != list.id;
    });

    this.props.onAttributeChange("productRecDefaultProductsIds", newitems);
    let filtered = [...this.state.filtered, list];

    this.setState({ items: newitems, filtered });
  };
  handleAdd = list => {
    let items = [...this.state.items, list.id.toString()];
    this.props.onAttributeChange("productRecDefaultProductsIds", items);
    // let filtered = this.state.filtered.filter(item => item.id !== list.id);
    this.setState({ items }, () => this.filteredResult());
  };

  filteredResult() {
    let items = this.state.items;
    let filtered = [];
    this.state.contentList !== null &&
      this.state.contentList.filter(item => {
        if (!_.includes(items, item.id.toString())) {
          filtered.push(item);
        }
      });

    this.setState({ filtered });
  }

  contentSelected = async value => {
    this.setState({searchDone:true})
    if (value !== "") {
     
      try {
        await EnrichmentsAPI.productSearch(value).then(response => {
          this.setState({ contentList: response }, () => this.filteredResult());
          this.setState({searchDone:false})
        });
      } catch (err) {
        this.setState({searchDone:false})
      }
    } else {
      this.handleDefaultContent();
      this.setState({searchDone:false})
    }
    
  };

  render() {
    return (
      <div className="default-product-wrap">
        <div className="selected-products bg-white">
          <p>Select the products to use.</p>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {this.state.items.length > 0 && this.state.items.length !== ""
                    ? this.state.items.map((item, index) => (
                        <Draggable key={item} draggableId={item} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <ListName
                                item={item}
                                onDelete={this.handleDelete}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))
                    : " Please add Product Item from right panel"}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>

        <div className="product-listing">
          <ContentSearch onContentSelect={this.contentSelected} searchDone={this.state.searchDone} />

          <h6>Product Listing</h6>
          <ListGroup as="ul" className="selected-product-list ">
            {this.state.contentList !== null &&
            this.state.filtered.length > 0 ? (
              this.state.filtered.map((item, index) => (
                <ListGroup.Item
                  key={index}
                  as="li"
                  onClick={() => this.handleAdd(item)}
                >
                  <span className="icon-add-outline" />
                  {item.name}
                </ListGroup.Item>
              ))
            ) : (
              <ListGroup.Item as="li" className="align-center">
                No matching listing found
              </ListGroup.Item>
            )}
          </ListGroup>
        </div>
      </div>
    );
  }
}

export default DefaultContent;
