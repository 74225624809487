import React, {Component} from "react";
import "./../../assets/css/styles.css";
import {Table} from "react-bootstrap";
import DestinationAPI from "../../../../../services/destination";
import AttributeSelectDropdown from "../../../../../components/AttributeModal/AttributeSelectDropdown";
import _ from "lodash";

// key - is destinationTyp
// value is a list of manadatory core attributes tags

const destinationsRules = {
  MAILCHIMP: [
    "CUSTOMER_EMAIL_ADDRESS"
  ]
}

class Destinations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataconnections: [],
      availableDestination: [],
      destination: [],
      modalstate: false,
      selectedIntegration: null
    };
  }

  componentDidMount() {
    this.getIntegration();

    let newDestination = this.props.newDestination;
    if (newDestination.destination) {
      this.setState({destination: newDestination.destination});
    }
  }

  componentWillReceiveProps(nextProps) {
    this.getPreviousDestination();

    let newDestination = nextProps.newDestination;
    if (newDestination.destination) {
      this.setState({destination: newDestination.destination});
    }
  }

  componentWillUnmount() {
    this.updatedDestinationResult();
  }

  attributesSelectionCheck = (attributes, recommendationCount) => {
    const productTags = [
      "PRODUCT_EXTERNAL_ID",
      "PRODUCT_NAME",
      "PRODUCT_SHOP_URL",
      "PRODUCT_IMAGE_URL",
      "PRODUCT_THUMBNAIL_URL",
      "PRODUCT_PRECIS",
      "PRODUCT_AVAILABLE",
      "PRODUCT_LIST_PRICE_EX_TAX",
      "PRODUCT_LIST_PRICE_INC_TAX",
      "PRODUCT_PRICE_BREAKS_DESCRIPTION"
    ];
    let selectedAttributes = [];
    let selectedAttributesCount = 0;

    (attributes || []).map(attribute => {
      selectedAttributes.push(attribute);
      if (
        _.includes(
          productTags,
          attribute.attributeTag || attribute.attributeDataTag
        )
      ) {
        selectedAttributesCount += parseInt(recommendationCount);
      } else {
        selectedAttributesCount++;
      }
    });

    return selectedAttributesCount;
  };

  getPreviousDestination = () => {
    let newDestination = this.props.newDestination;
    let availableDestination = this.state.availableDestination;
    let recommendationCount = parseInt(newDestination?.recomendation?.recommendationCount ?? '1');
    if (newDestination.destination) {
      this.setState({destination: newDestination.destination});

      newDestination.destination.map(item =>
        availableDestination.map((availableDes, key) => {
          if (availableDes.destinationId === item.destinationId) {
            availableDestination[key].isSelected = true;
            availableDestination[key].attributes = item.attributes;
            availableDestination[key].id = item.id;
            availableDestination[key].selectedAttributesCount = this.attributesSelectionCheck(
              item.attributes,
              recommendationCount
            );
            availableDestination[key].integrationSettings = item.integrationSettings;
            [availableDestination[key].attributes || []].map(
              att => {
                att.selected = true;
                att.isActive = true
              }
            );
          }
        })
      );

      let selectedDestination = availableDestination.filter(dest => dest.isSelected);
      this.setState({availableDestination, destination: selectedDestination});
    }
  };

  updatedDestinationResult = () => {
    let destination = this.state.destination;
    let newDestination = this.props.newDestination;

    let hasSelectedWithoutAttr = destination.filter(
      selectedDestination =>
        selectedDestination.isSelected === true &&
        selectedDestination.attributes?.length === 0
    );

    let recommendationCount = newDestination?.recomendation?.recommendationCount ?? 1;
    let hasMoreAttr = destination.filter(
      selectedDestination =>
        this.attributesSelectionCheck(selectedDestination.attributes, recommendationCount) > selectedDestination.numExternalFields
    );

    let isAttrReq = hasMoreAttr.filter(
      selectedDestination => selectedDestination.numExternalFields === -1
    );

    if (isAttrReq.length === 0 && hasSelectedWithoutAttr.length === 0 && hasMoreAttr.length === 0) {
      destination.hasError = false;
    } else if (isAttrReq.length === 0 && hasSelectedWithoutAttr.length === 1 && hasMoreAttr.length === 0) {
      destination.hasError = true;
    } else if (isAttrReq.length === 0 && hasSelectedWithoutAttr.length === 0 && hasMoreAttr.length === 1) {
      destination.hasError = true;
    } else {
      isAttrReq.length !== 0
        ? hasSelectedWithoutAttr.length === 0 || hasMoreAttr.length === 0
        ? (destination.hasError = false)
        : (destination.hasError = true)
        : (destination.hasError = false);
    }

    this.setState({destination});
    this.props.onPageChange("destination", destination);
  };

  getIntegration = async event => {
    let recommendationCount = parseInt(this.props?.newDestination?.recomendation?.recommendationCount ?? '1');
    try {
      await DestinationAPI.getIntegration(['API_CONNECTION', 'DWH']).then(response => {
        let availableDestination = [];

        response.map(integration => {
          let numExternalFields = integration?.integrationSettings?.customFieldsLimit ?? -1

          // for mailchimp we do reserve one field for hash 
          numExternalFields = integration.connectionType === 'MAILCHIMP' ? numExternalFields - 1 : numExternalFields;

          availableDestination.push({
            destinationId: integration.id,
            destinationName: integration.name,
            destinationType: integration.connectionType,
            recommendationCount: recommendationCount,
            numExternalFields,
            selectedAttributesCount: 0,
            isEnable: integration.enabled,
            isConnected: integration.connectionEstablished,
            action: "N/A",
            isSelected: false,
            attributes: integration.attributes,
            destinationAttributeSections: integration.connectionSettings.destinationAttributeSections
          });
        });

        this.setState(
          {
            dataconnections: response,
            availableDestination,
            isLoading: true
          },
          this.getPreviousDestination
        );
      });
    } catch (err) {
    }
  };

  selectDestination = (index, destination) => {
    let availableDestination = this.state.availableDestination;
    availableDestination[index].isSelected = destination;

    var selectedDestination = this.state.availableDestination.filter(
      e => e.isSelected === true
    );

    selectedDestination.map(e => {
      if (e.destinationAttributeSections !== null && e.destinationAttributeSections.length > 0) {
        e.attributes = e.destinationAttributeSections[0].attributes
      }
    })

    this.setState(
      {availableDestination, destination: selectedDestination},
      localStorage.setItem("checkChange", true)
    );
  };

  selectAttribute = (event, destination) => {
    event.stopPropagation();
    event.preventDefault();

    this.setState(
      {
        selectedIntegration: destination
      },
      () => this.showModal()
    );
  };

  selectDestinationAttribute = (index, destination) => {
    this.selectDestination(index, !destination.isSelected);
    setTimeout(() => {
      this.setState({selectedIntegration: destination},
        () => this.showModal()
      );
    }, 200);
  };

  onSelectAttribute = (attributeSelected, selectedAttributesCount) => {
    const destination = this.state.destination;
    
    const selectedDestinationId = this.state.selectedIntegration.destinationId;
    const selectedDestinationIndex = destination.findIndex(
      item => item.destinationId === selectedDestinationId
    );

    const selectedAttriubutes = destination[selectedDestinationIndex].attributes;
    if(selectedAttriubutes){
      attributeSelected.forEach(attr =>
        attr.id = selectedAttriubutes.filter(a => a.attributeDataTag != null).find(a =>
          ((a.attributeDataTag ?? "NONE") === (attr.attributeTag ?? "NONE")) ||
          ((a.attributeId ?? "null") === (attr.attributeId ?? "null"))
        )?.id
      )
    }

    destination[selectedDestinationIndex].attributes = attributeSelected;
    destination[selectedDestinationIndex].selectedAttributesCount = selectedAttributesCount;
    this.setState({destination: destination}, this.updatedDestinationResult);
  };

  showModal = () => {
    this.setState({
      modalstate: true
    });
  };

  closeModal = () => {
    this.setState({modalstate: false});
  };

  render() {
    const {availableDestination} = this.state;
    return (
      <React.Fragment>
        <div className="d-main">
          <Table>
            <thead>
            <tr>
              <th/>
              <th>Destination</th>

              <th>Attributes</th>
              <th>Attribute chosen</th>
            </tr>
            </thead>
            <tbody>
            {availableDestination.map((destination, key) => (
              <tr
                key={key}
                className={`${destination.isSelected ? "active-row" : ""} ${
                  destination.isConnected ? "" : "not-message"
                }`}
              >
                <td
                  onClick={() =>
                    this.selectDestination(key, !destination.isSelected)
                  }
                >
                  <i className="icon-check"/>
                </td>
                <td
                  className="des"
                  onClick={() =>
                    this.selectDestination(key, !destination.isSelected)
                  }
                >
                  <span>{destination.destinationName}</span>
                </td>
                {destination.destinationAttributeSections !== null
                    && destination.destinationAttributeSections.length > 0 ? (
                    <td>
                      <span>Pre-selected</span>
                    </td>
                ) : destination.isSelected ? (
                  <td
                    onClick={event =>
                      this.selectAttribute(event, destination)
                    }
                  >
                    <span className="link">Choose Attributes to Sync...</span>
                  </td>
                ) : (
                  <td
                    onClick={event =>
                      this.selectDestinationAttribute(key, destination)
                    }
                  >
                    <span>Choose Attributes to Sync...</span>
                  </td>
                )}
                {destination.isSelected ? destination.destinationAttributeSections !== null
                    && destination.destinationAttributeSections.length > 0? (
                    <td>Pre-selected</td>
                ) : (
                  <td
                    onClick={event =>
                      this.selectAttribute(event, destination)
                    }
                    className={`${
                      destination.selectedAttributesCount >
                      destination.numExternalFields &&
                      destination.numExternalFields !== -1
                        ? "hasMoreAttribute"
                        : ""
                    } ${
                      destination.selectedAttributesCount === 0
                        ? " noAttribute"
                        : ""
                    }`}
                  >
                    {destination.selectedAttributesCount > 0
                      ? (destination.numExternalFields !== -1
                      ? destination.selectedAttributesCount +
                      "/" +
                      destination.numExternalFields
                      : destination.selectedAttributesCount) +
                      " Attribute chosen"
                      : "No attribute saved"}
                  </td>
                ) : (
                  <td
                    onClick={event =>
                      this.selectDestinationAttribute(key, destination)
                    }
                  ></td>
                )}
              </tr>
            ))}
            </tbody>
          </Table>
        </div>

        {this.state.modalstate && (
          <AttributeSelectDropdown
            enrichmentType={this.props.enrichmentType}
            currentCardType={this.props.currentCardType}
            modalstate={this.state.modalstate}
            onCloseModal={this.closeModal}
            content={this.state.selectedIntegration}
            onSelectAttribute={this.onSelectAttribute}
            selectedIntegration={this.state.selectedIntegration}
            defaultAttributesTagsForCoreDataSource={destinationsRules[this.state.selectedIntegration.destinationType]}
          />
        )}
      </React.Fragment>
    );
  }
}

export default Destinations;
