import ApiService from "./tenantApiService";
// import AuthenticationService from "./authentication";

function getEnrichments() {
  const requestUrl = "/customer/enrichment";

  return ApiService.get(requestUrl).then(response => {
    return response.data;
  });
}

function getEnrichmentMinimalData() {
  const requestUrl = "/customer/enrichment/minimal-data";

  return ApiService.get(requestUrl).then(response => {
    return response.data;
  });
}

function getEnrichment(type) {
  const requestUrl = "/customer/enrichment/" + type;

  return ApiService.get(requestUrl).then(response => {
    return response.data;
  });
}

function updateEnrichments(data) {
  const requestUrl = "/customer/enrichment/" + data.enrichmentType;

  return ApiService.put(requestUrl, data).then(response => {
    return response.data;
  });
}

function getDefaultContent() {
  const requestUrl = "/content";

  return ApiService.get(requestUrl).then(response => {
    return response.data;
  });
}

function getSingleContent(id) {
  const requestUrl = "/content/" + id;

  // const sessionUserAccess_token = AuthenticationService.getCurrentAccessToken();

  return ApiService.get(requestUrl).then(response => {
    return response.data;
  });
}

function getSingleProduct(id) {
  const requestUrl = "/product/" + id;

  // const sessionUserAccess_token = AuthenticationService.getCurrentAccessToken();

  return ApiService.get(requestUrl).then(response => {
    return response.data;
  });
}

function getProductList() {
  const requestUrl = "/product";

  return ApiService.get(requestUrl).then(response => {
    return response.data;
  });
}
function customerSearch(dataToSearch) {
  const requestUrl = "/customersearch";

  ApiService.defaults.headers.post["Content-Type"] = "text/plain";
  return ApiService.post(requestUrl, dataToSearch).then(response => {
    return response.data;
  });
}
function getRecommendations(customerId, enrichment) {
  // console.log(customerId);

  const requestUrl =
    "/customer/enrichment/CONTENT_RECOMMENDATIONS_MIX/test/" + customerId;

  return ApiService.post(requestUrl, enrichment).then(response => {
    return response.data;
  });
}

function contentSearch(dataToSearch) {
  const requestUrl = "/contentsearch";

  ApiService.defaults.headers.post["Content-Type"] = "text/plain";
  return ApiService.post(requestUrl, dataToSearch).then(response => {
    return response.data;
  });
}

function productSearch(dataToSearch) {
  const requestUrl = "/productsearch";

  ApiService.defaults.headers.post["Content-Type"] = "text/plain";
  return ApiService.post(requestUrl, dataToSearch).then(response => {
    return response.data;
  });
}

function getRecommendationsProduct(customerId, enrichment) {

  const requestUrl =
    "/customer/enrichment/PRODUCT_RECOMMENDATIONS_MIX/test/" + customerId;

  return ApiService.post(requestUrl, enrichment).then(response => {
    return response.data;
  });
}

export default {
  getEnrichments,
  getEnrichmentMinimalData,
  getEnrichment,
  updateEnrichments,
  getDefaultContent,
  getProductList,
  getSingleContent,
  getSingleProduct,
  customerSearch,
  contentSearch,
  productSearch,
  getRecommendations,
  getRecommendationsProduct
};
