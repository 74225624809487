import React, { Component } from "react";
import Header from "../header";
import Choronotype from "./chronotype";
import _ from "lodash";
import { toast } from "react-toastify";

import Demographics from "./demographics";
import Engagement from "./engagement";
import RFIM from "./rfim";
import ProductRecommendations from "./ProductRecommendations/";
import ContentRecommendations from "./ContentRecommendations/";
import SpreadBrackets from "./spreadBrackets";
import Loader from "../../../../../../components/Loader/loader";

import EnrichmentsAPI from "../../../../../../services/enrichments";

class EnrichmentContent extends Component {
  state = {
    isLoading:false,
    currentEnrichment: null,
    currentStatus: false,
    applyButton: false
  };

  handleEnrichmentsUpdate = async event => {
    const currentEnrichment = this.state.currentEnrichment;

    try {
      await EnrichmentsAPI.updateEnrichments(currentEnrichment).then(
        response => {
          this.setState(
            {
              currentEnrichment: response,
              applyButton: false
            },
            () => this.props.onUpdateParent(response)
          );
          this.props.onSaveChanges(false);
          localStorage.setItem("checkChange", false);
         
          toast.success("Changes Applied Successfully ");
        }
      );
    } catch (err) {}
  };

  componentWillMount() {
    this.setState({ currentEnrichment: this.props.currentEnrichment,isLoading:true }, () =>
      this.checkStatus()
    );
  }
  componentWillReceiveProps(prevProps) {
    if (
      this.state.currentEnrichment.enrichmentType !==
      prevProps.currentEnrichment.enrichmentType
    ) {
      if (
        prevProps.currentEnrichment.enrichmentProcessedStatus ===
          "ENABLED_WAITING_FOR_FIRST_RUN" ||
        prevProps.currentEnrichment.enrichmentProcessedStatus === "ENABLED"
      ) {
        this.setState({ currentStatus: true });
      } else {
        this.setState({ currentStatus: false });
      }


      this.setState({
        currentEnrichment: prevProps.currentEnrichment,
        applyButton: false
      });
    }
  }

  handleEnrichmentStatus = async currentStatus => {

    
    const currentEnrichment = { ...this.state.currentEnrichment };
    if (this.state.currentStatus) {
      currentEnrichment.enrichmentProcessedStatus = "DISABLED";
      this.setState({ currentStatus: false });
    } else {
      currentEnrichment.enrichmentProcessedStatus = "ENABLED";
      this.setState({ currentStatus: true });
    }

    this.props.updateEnrichmentStatus(currentEnrichment);
  


  };

 
  checkStatus() {
    const { currentEnrichment } = this.props;
    if (currentEnrichment !== null) {
      if (
        currentEnrichment.enrichmentProcessedStatus ===
          "ENABLED_WAITING_FOR_FIRST_RUN" ||
        currentEnrichment.enrichmentProcessedStatus === "ENABLED"
      ) {
        this.setState({ currentStatus: true });
      } else {
        this.setState({ currentStatus: false });
      }
    }
  }

  handleAttributeChange = (type, value) => {
    const currentEnrichment = { ...this.state.currentEnrichment };
    currentEnrichment[type] = value;

     
    this.props.onSaveChanges(true);

    this.setState({ currentEnrichment,applyButton:true }, ()=>this.props.onCheckChange());
  };

  // handleApplyButton() {
  //   let applyButton = _.isEqual(
  //     this.state.currentEnrichment,
  //     this.props.currentEnrichment
  //   );
  //   this.setState({
  //     applyButton: applyButton
  //   });

  //   this.props.onSaveChanges(applyButton);
  // }

  handleCancel = () => {
    this.props.onSaveChanges(true);
    this.setState({
      currentEnrichment: this.props.currentEnrichment,
      applyButton: false
    });
    this.props.onSaveChanges(false);
    localStorage.setItem("checkChange", false);
  };

  enrichmentType(currentEnrichment) {
    switch (currentEnrichment.enrichmentType) {
      case "CHRONOTYPE":
        return (
          <Choronotype
            currentEnrichment={currentEnrichment}
            onAttributeChange={this.handleAttributeChange}
          />
        );
      case "DEMOGRAPHIC":
        return (
          <Demographics
            currentEnrichment={currentEnrichment}
            onAttributeChange={this.handleAttributeChange}
          />
        );
      case "ENGAGEMENT":
        return (
          <Engagement
            currentEnrichment={currentEnrichment}
            onAttributeChange={this.handleAttributeChange}
          />
        );
      case "RFIM":
        return (
          <RFIM
            currentEnrichment={currentEnrichment}
            onAttributeChange={this.handleAttributeChange}
          />
        );
      case "SPEND_BRACKET":
        return (
          <SpreadBrackets
            currentEnrichment={currentEnrichment}
            onAttributeChange={this.handleAttributeChange}
          />
        );
      case "PRODUCT_RECOMMENDATIONS_MIX":
        return (
          <ProductRecommendations
            onAttributeChange={this.handleAttributeChange}
            currentEnrichment={currentEnrichment}
          />
        );
      case "CONTENT_RECOMMENDATIONS_MIX":
        return (
          <ContentRecommendations
            onAttributeChange={this.handleAttributeChange}
            currentEnrichment={currentEnrichment}
          />
        );
      default:
        return <Choronotype currentEnrichment={currentEnrichment} />;
    }
  }

  render() {
    const { currentEnrichment, currentStatus, applyButton, isLoading } = this.state;
    return (
     
      <section className="right-list-area">
        <Header
          currentEnrichment={currentEnrichment}
          currentStatus={currentStatus}
          applyButtonStatus={applyButton}
          enrichmentActivateStatus={currentEnrichment.okToActivateStatus}
          onEnableEnrichment={this.handleEnrichmentStatus}
          onEnrichmentCancel={this.handleCancel}
          onEnrichmentUpdate={() => {
            this.setState({applyButton:false}, this.handleEnrichmentsUpdate);
          }}
          onTogglePin={this.props.onTogglePin}
        />
        {currentStatus && this.enrichmentType(currentEnrichment)}
      </section>
     
  );
  }
}

export default EnrichmentContent;
