import React, {Component} from "react";
import {Link} from "react-router-dom";
import AccountAPI from "../../services/account";
import Loader from "../Loader/loader";
import headphones from "../../views/Main/AnalyticsPortal/assets/img/headphones.svg";
import  "./assets/styles.scss";

class Notification extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedMembers: this.props.selectedMembers,
      currentPlanInfoLoaded: false,
      isFreeBillingPlan: false,
      isNewsfeedOrReader: false
    };
  }

  componentDidMount() {
    this.getCurrentPlanInfo(this.state.selectedMembers.fkStripeProductId);
  }

  redirectWithinTheWebSite = (route) => {
    if (window.location.pathname !== "/settings/billing") {
      window.location.pathname = route;
    }
  }

  trialExpired = () => {
    sessionStorage.setItem("trialMember", JSON.stringify(this.props.selectedMembers));

    window.location = "/settings/billing";
  };

  openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  getCurrentPlanInfo(fkStripeProductId) {
    if (fkStripeProductId !== null) {
      return AccountAPI.getCurrentPlanInfo(fkStripeProductId).then(response => {
        this.setState({ currentPlanInfoLoaded: true, isFreeBillingPlan: response.productName === 'Trial' })
      })
    } else {
      this.setState({
        currentPlanInfoLoaded: true,
        isFreeBillingPlan: true
      })
    }
  }

  isNewsFeedOrReaderMembershipType(selectedMembers) {
    switch (selectedMembers.membershipType) {
      case "READER":
      case "NEWSFEED":
        return true;
      default:
        return false;
    }
  }

  hasStripePaymentIssue(selectedMembers) {
    if (selectedMembers !== null) {
      return selectedMembers.stripePaymentIssue;
    }
  }

  managePaymentMethod() {
    AccountAPI.managePaymentMethod(this.state.selectedMembers.id).then(response => {
        window.open(response, '_blank', 'noopener,noreferrer');
      }
    );
  }

  render() {
    let { currentPlanInfoLoaded } = this.state;
    const daysLeftInContract = this.props.selectedMembers.daysLeftInContract;
    const hasPaymentIssue = this.hasStripePaymentIssue(this.props.selectedMembers);
    let selectedMembers = JSON.parse(sessionStorage.getItem("selectedMembers"));
    let accountType = selectedMembers.accountType;
    const hasBillingPermission = this.isNewsFeedOrReaderMembershipType(this.props.selectedMembers);
    let message = "";

    if (hasBillingPermission) {
      message = <li className="font-weight-light d-trial">
          <span>Choose a plan to unlock more features.
            <strong> Contact your company’s Distil Admin to select a plan</strong>
          </span>
      </li>
    } else if(accountType === 'NEW_SIGN_UP') {
      message = <li className="font-weight-light d-trial flex-alert-elements">
        <div className={"flex-alert-elements-inner"}>
          <span>You are currently on a Free Trial. Book a call for a full product tour.</span>
          <React.Fragment>
            <Link
                to="/#"
                className="font-weight-Avenirmedium link-book"
                onClick={() => this.openInNewTab( "https://distil.ai/get-distil/#demo")}
            >
              <img className="top-picture" src={headphones} alt=""/>
              <span>Book a demo</span>
            </Link>
          </React.Fragment>
        </div>
      </li>
    } else if(accountType === 'ACTIVATED_TRIAL') {
      message = <li className="font-weight-light d-trial">
        <span>You are currently on a Free Trial. </span>
        <React.Fragment>
          {" "}
          <Link
              to="/#"
              className="font-weight-Avenirmedium link-billing-plan"
              onClick={() => this.redirectWithinTheWebSite("/settings/billing")}
          >
            Get Distil Now
          </Link>
        </React.Fragment>
      </li>
    } else if(accountType === 'ACTIVATED_FREE' || selectedMembers.fkStripeProductId === 0) {
      message = <li className="font-weight-light d-trial flex-alert-elements">
        <div style={{justifyContent: 'flex-start'}} className={"flex-alert-elements-inner"}>
          <span>Choose a plan to unlock more features. </span>
          <React.Fragment>
            <Link
                to="/#"
                className="font-weight-Avenirmedium link-billing-plan"
                onClick={() => this.redirectWithinTheWebSite('/settings/billing')}
            >
              <span>Choose Billing Plan</span>
            </Link>
          </React.Fragment>
        </div>
      </li>
    }

    const freePlanStyles = accountType === 'ACTIVATED_FREE' || accountType === 'NEW_SIGN_UP'
        || selectedMembers.fkStripeProductId === 0;

    return currentPlanInfoLoaded ? (
      <div
        className={`alert ${
          hasPaymentIssue ? 'alert-danger' : freePlanStyles ? "alert-activated-free" : "alert-success"
        } m-0 border-radius-0 text-white pt-3 pb-3 d-flex align-items-center`}
      >
        <ul className="d-flex m-0 p-0 mr-auto align-items-center list-unstyled custom-alert">
          {message}
        </ul>
        {accountType === 'ACTIVATED_TRIAL' && (
          <strong className="font-weight-Avenirmedium">
            {daysLeftInContract} Days left
          </strong>
        )}
      </div>
    ) : (
      <Loader/>
    );
  }
}

export default Notification;
