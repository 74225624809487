import React, {Component} from "react";
import {Button, Form, ListGroup, Modal} from "react-bootstrap";
import {
  getPluralEnding
} from "../customerdata/segmentUtils";
import {Select} from "react-inputs-validation";
import {isTraitProcessing, getTraitIconClass} from "./traitUtils";

const additionalTraitCategories = [
  {id:'', name:'Select Trait Category'},
  {id: null, name: "All"}
];
class TraitsSidebar extends Component {

  state = {
    show: false,
    responsiveMode: false,
    categoryId: null
  };

  componentWillMount() {
    this.setState({categoryId: localStorage.getItem("traitCategoryIdFilter") || null});
    this.updateUserSectionState();
    window.addEventListener("resize", this.updateUserSectionState.bind(this));
  }

  updateUserSectionState() {
    if (window.outerWidth < 1024) {
      this.setState({responsiveMode: true});
    } else {
      this.setState({responsiveMode: false});
    }
  }

  handleClose = () => {
    this.setState({show: false});
  };

  handleShow = () => {
    this.props.resetData();
    this.setState({show: true});
  };

  handleClickTrait = (id) => {
    this.props.onTraitSelect(id);
    this.handleClose();
  }

  setFilterCategoryId = (categoryId) => {
    localStorage.setItem("traitCategoryIdFilter", categoryId);
    this.setState({categoryId: categoryId}, () => this.props.onSearch());
  }

  getFilter = () => {
    if(this.state.categoryId && this.state.categoryId !== String(null)) {
      return (t) => {
        return String(t.traitCategoryId) === this.state.categoryId;
      }
    }
    return (t) => t;
  }

  renderTraitList() {
    const {allTraits, currentTraitId, isSearching} = this.props;

    const filteredTraits = allTraits.filter(this.getFilter());

    return filteredTraits.length > 0 ? (
      <ListGroup as="ul">

        {filteredTraits.map((trait) => (

          <ListGroup.Item
            as="li"
            action
            key={trait.id}
            className={trait.id === currentTraitId && "active"}
            onClick={() => this.handleClickTrait(trait.id)}
          >
            <div>
              <span className={getTraitIconClass(trait)}/>
              <div className="list-data">
                <h6>{trait.name} </h6>
                <span className="sub-head">
                {isTraitProcessing(trait) ?
                    (<span className="icon-distil-logo icon spin" style={{marginRight: 0}}/>) :
                    (trait.currentMatchCount > 0 ? trait.currentMatchCount.toLocaleString(navigator.language,
                          {minimumFractionDigits: 0}) : 0)} Record{getPluralEnding(trait.currentMatchCount)}
              </span>
              </div>
            </div>
          </ListGroup.Item>

        ))}
      </ListGroup>
    ) : (
      <ListGroup>
        <ListGroup.Item>
          <h6>{this.getTraitCategoryMessage(isSearching)}</h6>
        </ListGroup.Item>
      </ListGroup>
    );
  }

  getTraitCategoryMessage(isSearching) {
    if (isSearching) {
      return 'No matching Traits Found';
    }

    return 'No traits are assigned to this category';
  }

  handleSearchTermChange = (e) => {
    this.props.onSearch(e.target.value);
  }

  render() {
    const {onSort, onNewTrait, onSearch, currentTrait, traitCategories} = this.props;
    const {categoryId} = this.state;
    let currentUser = JSON.parse(sessionStorage.getItem("selectedMembers"));
    const traitCategoriesSelectItems = [...additionalTraitCategories,  ...traitCategories];
    return (
      <React.Fragment>
        <section className="sideNav">
          <div className="top-head">
            <div className="trait d-flex ">
              <div className="search position-relative">
                <span className="icon-search"/>
                <Form.Control
                  type="text"
                  onChange={this.handleSearchTermChange}
                  placeholder="Search here..."
                />
              </div>
              <div className="top-icon-wrap ml-auto d-flex">
                <span className="icon-sort" onClick={onSort}/>
                {currentUser.membershipType !== 'READER' &&
                <span className="icon-add" onClick={() => onNewTrait()}/>}
              </div>
            </div>

          </div>

          <div className="filters">
            <Select
                name={"traitCategory"}
                value={categoryId}
                disabled={false}
                optionList={traitCategoriesSelectItems}
                classNameSelect="select-control"
                classNameWrapper="form-control select-control-wrapper manage-trait-category"
                classNameContainer=""
                classNameOptionListContainer="select-control-option"
                classNameOptionListItem=""
                customStyleSelect={{}}
                customStyleWrapper={{}}
                customStyleContainer={{}}
                customStyleOptionListContainer={{}}
                customStyleOptionListItem={{}}
                onChange={this.setFilterCategoryId}
            />
          </div>

          <div className="traits-list">{this.renderTraitList()}</div>
        </section>
        {this.state.responsiveMode && (
          <div className="mobile-head d-lg-none">
            <Button
              variant="secondary"
              className="grid-menu"
              onClick={this.handleShow}
            >
              <span className="icon-np_grid-menu_654601_000000 gridicon"/>
            </Button>
            <Modal
              show={this.state.show}
              onHide={this.handleClose}
              className="customer-data"
            >
              <Modal.Body>
                <section className="sideNav ">
                  <div className="top-head">
                    <div className="trait d-flex ">
                      <h6>Traits</h6>
                      <span
                        onClick={this.handleClose}
                        className="icon-cancel cancel-modal ml-auto"
                      />
                    </div>

                    <div className="search position-relative">
                      <span className="icon-search"/>
                      <Form.Control
                        type="text"
                        onChange={onSearch}
                        placeholder="Search  here..."
                      />
                      <span className="icon-sort" onClick={onSort}/>
                    </div>
                  </div>

                  <div className="traits-list">
                    {this.renderTraitList()}
                  </div>
                </section>
              </Modal.Body>
            </Modal>{" "}
            <div className="head-data ">
              <h6>{currentTrait.name}</h6>
              <span
                className="sub-head">{currentTrait.currentMatchCount > 0 ? currentTrait.currentMatchCount.toLocaleString(navigator.language, {minimumFractionDigits: 0}) : 0} Records</span>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default TraitsSidebar;
