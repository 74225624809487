import React, {Component} from "react";
import {Button, Col, Form} from "react-bootstrap";
import DataConnectionAPI from "../../../../../services/dataConnections";
import NewConnectionMeta from "./NewConnectionMeta";
import {Textbox} from "react-inputs-validation";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import {toast} from "react-toastify";
import {distilShopifyAccountConfig} from "../../../../../variables/authCreds";

const initialState = {
  updatingContent: false,

  baseExternalConnectionUrl: "",
  connectionDesc: "",
  connectionTitle: "",
  shop: "",

  connectionToken: "",
  iconType: "SHOPIFY",
  isConnectionTested: "TEST",
  connectionSettingEnabled: true,
  hasConnectionTitleError: true,
  hasShopError: true,
  hasConnectionDescError: true,
  enabled: true,
  validate: false,
  disableOnEdit: false
};

const editConnection = {
  disableOnEdit: false
};

class Shopify extends Component {
  constructor(props) {
    super(props);
    this.state = {initialState, editConnection};
  }

  resetData = () => {
    this.setState(initialState);
    localStorage.setItem("checkChange", false);
  };

  componentDidMount() {
    this.setState(initialState);
    this.setState({connectionType: this.props.formType});
    if (this.props.editConnection) {
      this.editConnectionDefaults();
    } else {
      this.parseDataToken();
    }
  }

  componentWillReceiveProps(nextProps) {
    let checkChange = JSON.parse(localStorage.getItem("checkChange"));

    if (!checkChange) {
      if (nextProps.editConnection) {
        this.editConnectionDefaults();
      } else {
        if (this.props.editConnection) {
          this.resetData();
        }
      }
    }
  }

  parseDataToken() {
    const {params} = this.props;
    let dataToken = params?.state;

    if (dataToken) {
      try {
        let data = JSON.parse(atob(decodeURIComponent(dataToken)));
        let validData = this.isNotEmpty(data?.title) && this.isNotEmpty(data?.shop) && this.isNotEmpty(data?.desc) && this.isNotEmpty(params?.code);
        let shopUrl = params?.shop;

        if (validData) {
          this.setState({
                connectionTitle: data.title,
                connectionDesc: data.desc,
                shop: data.shop,
                connectionToken: params?.code,
                baseExternalConnectionUrl: shopUrl,
                isConnectionTested: 'PASS'
              }
          );
        }
        window.history.pushState({}, document.title, "/settings/integrations")
        this.onCheckChange();
      } catch (e) {}
    }
  }

  isNotEmpty(data) {
    return data?.length > 0;
  }

  editConnectionDefaults = () => {
    this.setState({
      connectionTitle: this.props.selectedConnection.name,
      connectionDesc: this.props.selectedConnection.description,
      shop: this.props.selectedConnection.connectionSettings.baseExternalConnectionUrl,
      connectionType: this.props.selectedConnection.connectionType,
      iconType: this.props.selectedConnection.iconType,
      connectionSettingEnabled: this.props.selectedConnection.enabled,
      baseExternalConnectionUrl: this.props.selectedConnection.connectionSettings.baseExternalConnectionUrl,
      updatingContent: false,
      isConnectionTested: 'PASS'
    });
  };

  validateForm = e => {
    e.preventDefault();

    this.setState({updatingContent: false});

    if (this.isValidData()) {
      this.testConnection();
    } else {
      toast.warn("Please enter valid data in fields");
    }
  };

  isValidData() {
    const {connectionDesc, connectionTitle, shop} = this.state;
    return this.isNotEmpty(shop) && this.isNotEmpty(connectionDesc) && this.isNotEmpty(connectionTitle);
  }

  testConnection = () => {
    window.location.href = this.buildAuthUrl();
  }

  addConnection = async (e) => {
    e.preventDefault();
    this.setState({updatingContent: true});
    let formData = {
      name: this.state.connectionTitle,
      description: this.state.connectionDesc,
      connectionType: 'SHOPIFY',
      iconType: this.state.iconType,
      enabled: this.state.connectionSettingEnabled,
      connectionSettings: {
        baseExternalConnectionUrl: this.state.baseExternalConnectionUrl,
        token: this.state.connectionToken,
      }
    };

    if (this.props.editConnection) {
      let formUpdate = {id: this.props.selectedConnection.id, ...formData};

      try {
        await DataConnectionAPI.updateConnection(formUpdate, this.props.selectedConnection.id).then(response => {
          toast.success("Connection updated successfully");
          this.resetData();
          this.props.onAddingConnection(response.id);
        });
      } catch (err) {
        toast.error("Connection updating error");
      }
    } else {
      try {
        await DataConnectionAPI.newConnection(formData).then(response => {
          toast.success("Connection created successfully");
          this.resetData();
          this.props.onAddingConnection(response.id);
        });
      } catch (err) {
        toast.error("Connection creation error");
        this.setState({isConnectionTested: "TEST"});
      }
    }
    this.setState({updatingContent: false});
  };

  onCheckChange = () => {
    this.props.onCheckChange()
  };

  sanitizeShopUrl = (s) => {
    return s.replace(/^(?:https?:\/\/)(?:www\.)|[\/?].*/g, '');
  }

  buildAuthUrl() {
    let connectionData = {
      title: this.state.connectionTitle,
      desc: this.state.connectionDesc,
      shop: this.state.shop,
    };

    return `https://${this.sanitizeShopUrl(this.state.shop)}/admin/oauth/authorize` +
        "?client_id=" + distilShopifyAccountConfig.apiKey +
        "&scope=" + distilShopifyAccountConfig.scope +
        "&redirect_uri=" + distilShopifyAccountConfig.redirectUrl +
        "&state=" + btoa(JSON.stringify(connectionData)) +
        "&grant_options[]=" + distilShopifyAccountConfig.grant;
  }

  renderDoneButton(isConnectionTested) {
    return <Button variant="primary"
                   disabled={!this.isValidData()}
                   type="submit">{isConnectionTested === 'PASS' ? 'Done' : 'Login'}</Button>;
  }

  getSubmitAction(isConnectionTested) {
    if (isConnectionTested === 'PASS') {
      return this.addConnection;
    } else {
      return this.validateForm;
    }
  }

  render() {
    const {connectionTitle, connectionDesc, shop, validate, isConnectionTested, disableOnEdit} = this.state;

    return (
      <React.Fragment>

        <NewConnectionMeta
            formType={this.props.formType}
            onBackPress={this.props.onBackPress}
            editConnection={this.props.editConnection}
        />

        <Form onSubmit={this.getSubmitAction(isConnectionTested)}>
          <Form.Row>
            <Form.Group as={Col} controlId="formGridName">
              <Form.Label>Title</Form.Label>
              <Textbox
                  classNameInput="form-control"
                  classNameContainer="custome-input"
                  tabIndex="1"
                  id={"connectionTitle"}
                  name="connectionTitle"
                  type="text"
                  validate={validate}
                  validationCallback={res => {
                    this.setState({hasConnectionTitleError: res, validate: false})
                  }}
                  onBlur={e => {}}
                  value={connectionTitle}
                  onChange={(connectionTitle, e) => {
                    this.setState({connectionTitle, updatingContent: false}, () => this.onCheckChange());
                  }}
                  validationOption={{name: "Title", check: true, required: true}}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridDesc">
              <Form.Label>Description</Form.Label>
              <Textbox
                  classNameInput="form-control"
                  classNameContainer="custome-input"
                  tabIndex="2"
                  id={"connectionDesc"}
                  name="connectionDesc"
                  type="text"
                  validate={validate}
                  validationCallback={res => this.setState({hasConnectionDescError: res, validate: false})}
                  value={connectionDesc}
                  onBlur={e => {
                  }}
                  onChange={(connectionDesc, e) => {
                    this.setState({connectionDesc}, () => this.onCheckChange());
                  }}
                  validationOption={{name: "Description", check: true, required: true}}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridDesc">
              <Form.Label>Shop URL (shop.myshopify.com)</Form.Label>
              <Textbox
                  classNameInput="form-control"
                  classNameContainer="custome-input"
                  tabIndex="2"
                  id={"shopTitle"}
                  name="shopTitle"
                  type="text"
                  validate={validate}
                  validationCallback={res => this.setState({hasShopError: res, validate: false})}
                  value={shop}
                  onBlur={e => {
                  }}
                  disabled={isConnectionTested === 'PASS'}
                  onChange={(shopTitle, e) => {
                    this.setState({shop: shopTitle}, () => this.onCheckChange());
                  }}
                  validationOption={{name: "Shop URL", check: true, required: true}}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row className="btn-wrap">
            {this.renderDoneButton(isConnectionTested)}

            {!disableOnEdit && (
                <Button variant="secondary" onClick={this.props.onCancelConnection}>
                  <span className="icon-cancel icon"/>
                  Cancel
                </Button>
            )}
          </Form.Row>
        </Form>
      </React.Fragment>
    );
  }
}

export default Shopify;
