import React, { Component } from 'react';
import { ListGroup } from "react-bootstrap";
class RFIM extends Component {
    state = {  }
    render() { 
        const { currentEnrichment,onAttributeChange} = this.props
       
        return (
            <div className="body-content-wrap bg-white"> <div className="content-section">
        <p>
          Choose the RFI/M measurement frequency rate.
        </p>

        <ListGroup as="ul">
          <ListGroup.Item as="li"  onClick={()=>onAttributeChange( "rfimMeasurementFrequency","DEFAULT")}>
          <i className={currentEnrichment.rfimMeasurementFrequency === "DEFAULT" ?  "icon-check_circle active" :  "icon-check_circle "} />
            <p>
          <span>Default</span>
          Customers RFI/M values are calculated <strong>monthly.</strong> This is the default value and should be suitable for most platforms.
          </p>
          </ListGroup.Item>
          <ListGroup.Item as="li" onClick={()=>onAttributeChange( "rfimMeasurementFrequency","HIGH_FREQUENCY")}>
          <i className={currentEnrichment.rfimMeasurementFrequency === "HIGH_FREQUENCY" ?  "icon-check_circle active" :  "icon-check_circle "} />
          <p>
          <span>Hign Frequency</span>
          Scores are recalculated on a <strong>weekly</strong> basis. This is for very high engagement sites, for example if you expect your Customers to visit your platform multiple times per day. 
          </p>
          </ListGroup.Item>
          <ListGroup.Item as="li" onClick={()=>onAttributeChange( "rfimMeasurementFrequency","LOW_FREQUENCY")}>
          <i className={currentEnrichment.rfimMeasurementFrequency === "LOW_FREQUENCY" ?  "icon-check_circle active" :  "icon-check_circle "} />
         <p>
          <span>Low Frequency</span>
          Scores are measured every <strong>three months.</strong> If your platform has low but regular interactions choose this option.
          </p>
          </ListGroup.Item>
          
        </ListGroup>

        <p>
          This Enrichment is typically set in conjunction with the <span className="highlight"> Engagement Enrichment </span> which is currently set to <strong> Default.</strong>
        
        </p>

      </div></div> );
    }
}
 
export default RFIM;