import React, { Component } from "react";
import { Button, ListGroup } from "react-bootstrap";
import { Select } from "react-inputs-validation";
import Rule from "./rule";
class RuleGroup extends Component {
  render() {
    const {
      ruleGroup,
      dataSchema,
      onGroupRuleChange,
      onAttributeSelection,
      groupIndex,
      onAddRule,
      onDeleteRule,
      onNewCancel,
      onRuleMAtchCondition,
      onRuleSave,
      editable
    } = this.props;
    
    return (
      <div className="segment-body-content p-4 mb-4">
        <ListGroup as="ul">
          <ListGroup.Item as="li">
            <span> Match </span>
            <div className="segment-wrap">
              <Select
                tabIndex="4"
                id={"fieldType"}
                name={"fieldType"}
                value={ruleGroup.clausesAndOr}
                disabled={!editable}
                optionList={[
                  { id: "OR", name: "ANY" },
                  { id: "AND", name: "ALL" }
                ]}
                classNameSelect="select-control"
                classNameWrapper="form-control select-control-wrapper"
                classNameContainer="dropdown-without-title"
                classNameOptionListContainer="select-control-option"
                classNameOptionListItem=""
                customStyleSelect={{}}
                customStyleWrapper={{}}
                customStyleContainer={{}}
                customStyleOptionListContainer={{}}
                customStyleOptionListItem={{}}
                onChange={e => onGroupRuleChange(e, groupIndex)}
              />
 
            </div>
            <span> of the specified conditions </span>
          </ListGroup.Item>
          {ruleGroup.rules.map((rule, key) => (
            <Rule
              key={rule.dataSourceAttributeId + '_' + rule.id + '_' + key}
              rule={rule}
              groupIndex={groupIndex}
              ruleIndex={key}
              onAttributeSelection={onAttributeSelection}
              dataSchema={dataSchema}
              onDeleteRule={onDeleteRule}
              onNewCancel={onNewCancel}
              onRuleMAtchCondition={onRuleMAtchCondition}
              onRuleSave={onRuleSave}
              editable={editable}
            />
          ))}
        </ListGroup> 
 {ruleGroup.rules.filter(item => item.new === true).length >= 1 ? " " : <Button
          variant="secondary mt-3 align-self-start add-link"
          hidden={!editable}
          onClick={() => onAddRule(groupIndex)}
        >
          + Add Another Condition
        </Button>  }
        
      </div>
    );
  }
}

export default RuleGroup;
