import React, { Component } from "react";
import "./assets/styles.css";

import EnrichmentsAPI from "../../../../services/enrichments";

import SweetAlert from "react-bootstrap-sweetalert";

import Sidebar from "./enrichmentData/sidebar";
import EnrichmentContent from "./enrichmentData/enrichmentContent/enrichmentContent";
import Loader from "../../../../components/Loader/loader";
import DevcieNotification from "../../../../components/DeviceNotification";
class Enrichment extends Component {
  state = {
    isLoading: false,
    enrichments: [],
    currentEnrichment: null,
    currentEnrichmentKey: 0,
    isSaved: false,
    navigateto: null,
    showAlert: false,
    disableSidebar: false
  };

  componentWillMount() {
    this.props.onContextIdChange(null);
    this.handleEnrichments();
  }

  componentWillUnmount() {
    this.props.onContextChange(null);
  }

  handleUpdateEnrichmentStatus = async currentEnrichment => {
    {
      this.setState({ disableSidebar: true });
    }
    try {
      await EnrichmentsAPI.updateEnrichments(currentEnrichment).then(
        response => {
          this.setState(
            { disableSidebar: false, currentEnrichment: response },
            () => this.handleUpdateEnrichment(response)
          );
        }
      );
    } catch (err) {}
  };

  handleEnrichments = async event => {
    try {
      await EnrichmentsAPI.getEnrichments().then(response => {
        this.setState(
          {
            enrichments: response,
            currentEnrichment: response[this.state.currentEnrichmentKey],
            isLoading: true
          },
          () =>
            this.props.onContextChange(
              this.state.currentEnrichment.enrichmentType
            )
        );
      });
    } catch (err) {}
  };

  handleCurrentEnrichment = (current, key) => {
    if (this.state.isSaved) {
      this.setState({
        showAlert: true,
        navigateto: current,
        processKey: key
      });
    } else {
      this.setState(
        { currentEnrichment: current, currentEnrichmentKey: key },
        () =>
          this.props.onContextChange(
            this.state.currentEnrichment.enrichmentType
          )
      );
    }
  };

  handleDiscard = () => {
    if (this.state.navigateto !== null) {
      const currentElement = this.state.navigateto;
      this.setState({
        currentEnrichment: currentElement,
        currentEnrichmentKey: this.state.processKey,
        showAlert: false,
        isSaved: false
      });
      localStorage.setItem("checkChange", false);
    }
  };

  handleUpdateEnrichment = data => {
    const enrichments = this.state.enrichments;
    const indx = enrichments.findIndex(
      item => item.enrichmentType === data.enrichmentType
    );
    enrichments[indx] = data;

    this.setState({ currentEnrichment: data, enrichments });
  };

  isEnrcihmentSaved = isSaved => {
    this.setState({ isSaved });
  };

  render() {
    const { isLoading, enrichments, currentEnrichment } = this.state;

    if (isLoading) {
      return (
        <main className="position-relative area-main enrichment customer-wrap">
          {this.state.showAlert && (
            <SweetAlert
              custom
              showCancel
              confirmBtnText="Discard"
              cancelBtnText="Cancel"
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="default"
              allowEscape="false"
              closeOnClickOutside="false"
              title="Discard Changes "
              onConfirm={() => this.handleDiscard()}
              onCancel={() => this.setState({ showAlert: false })}
            >
              Changes will be discarded by clicking discard
            </SweetAlert>
          )}
          <Sidebar
            enrichments={enrichments}
            onContextChange={this.handleCurrentEnrichment}
            currentEnrichmentType={currentEnrichment.enrichmentType}
            sidebarStatus={this.state.disableSidebar}
          />

          <EnrichmentContent
            currentEnrichment={currentEnrichment}
            onSaveChanges={this.isEnrcihmentSaved}
            onUpdateParent={this.handleUpdateEnrichment}
            onTogglePin={this.props.onTogglePin}
            updateEnrichmentStatus={this.handleUpdateEnrichmentStatus}
            onCheckChange={this.props.onCheckChange}
          />
          <DevcieNotification />
        </main>
      );
    } else {
      return <Loader />;
    }
  }
}

export default Enrichment;
