import React, { Component } from "react";
import { ListGroup } from "react-bootstrap";
class SpreadBrackets extends Component {
  state = {};
  render() {
    const { currentEnrichment, onAttributeChange } = this.props;
    return (
      <div className="body-content-wrap bg-white">
        <div className="content-section">
          <p>
            Choose the number of Spend Categories you think is most appropriate
            to your business.
          </p>

          <ListGroup as="ul">
            <ListGroup.Item
              as="li"
              onClick={() =>
                onAttributeChange("spendBracketNumCategories", "THREE")
              }
            >
              <i
                className={
                  currentEnrichment.spendBracketNumCategories === "THREE"
                    ? "icon-check_circle active"
                    : "icon-check_circle "
                }
              />
              <p>
                <span>Three</span>
                Customers are placed into three spend categories,
                <strong>
                  {" "}
                  Highest Spenders, Average Spenders, Low Spenders
                </strong>
                , plus <strong>No Spend.</strong>
              </p>
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              onClick={() =>
                onAttributeChange("spendBracketNumCategories", "FOUR")
              }
            >
              <i
                className={
                  currentEnrichment.spendBracketNumCategories === "FOUR"
                    ? "icon-check_circle active"
                    : "icon-check_circle "
                }
              />
              <p>
                <span>Four</span>
                Customers are placed into four spend categories, <strong>  Highest Spenders, Top-Average Spenders, Bottom-Average
                  Spenders, Low Spenders,
                </strong>{" "}
                plus <strong> No Spend</strong>.
              </p>
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              onClick={() =>
                onAttributeChange("spendBracketNumCategories", "FIVE")
              }
            >
              <i
                className={
                  currentEnrichment.spendBracketNumCategories === "FIVE"
                    ? "icon-check_circle active"
                    : "icon-check_circle "
                }
              />
              <p>
                <span>Five</span>
                Customers are placed into five spend categories,  <strong> Big Spenders, High Spenders, Top-Average Spenders,
                  Bottom-Average Spenders, Low Spenders,
                </strong>{" "}
                plus <strong> No Spend</strong>.
              </p>
            </ListGroup.Item>
          </ListGroup>
        </div>
      </div>
    );
  }
}

export default SpreadBrackets;
