import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { Col, Container, Row } from "react-bootstrap";
import LineChartTuesday from "./chart/ChartCustomerSegment";
import iconWednesday from "../assets/images/wednesday-footer-icon.svg";
import iconHeaderTuesday from "../assets/images/tuesday-header-icon.svg";
import "../assets/css/segment.scss";
import "../assets/css/dailyCard/tuesday.scss";
import "../assets/css/general-styles.scss";

const Tuesday = ({ date, cardData }) =>  {

    return (
            <div className="page-customer-segment progress-friday">
                <Container fluid className="container-content">
                    <Row>
                        <Col>
                            <div className="card m-0 p-2 rounded shadow-sm analytic-card container-monday">
                                <div className="card-header border-bottom-1 pl-3 position-relative padding-top4em">
                                    <div className="row card-header-row">
                                        <div className="col-1 d-flex card-col-icon align-items-center">
                                            <div className="card-icon">
                                                <img src={iconHeaderTuesday} alt="header..."/>
                                            </div>
                                        </div>
                                        <div className="col-11 card-title-col">
                                            <div className=" fs-l fw-bold text-black">Segmentation Tuesday</div>
                                            <p className="fs-m text-muted mb-1 mb-md-2">{date}</p>
                                            <div className="text-wrap pr-3 w-75">
                                                <p className="card-text fs-m">
                                                    It’s time to get to know your customers. Every Tuesday you can get
                                                    to grips with who they really are, so you can talk - and sell - to
                                                    them as individuals. Are they already engaged or need persuading?
                                                    Passionate advocates or at risk of leaving altogether? Tuesday’s
                                                    card reveals all.

                                                </p>
                                            </div>
                                        </div>
                                        <div className="card-offset">
                                            <p className="card-info small">
                                                <span className="text-infor"> How to read this card?</span>
                                                    <span className="card-sm-icon m-2">
                                                    <a href="https://help.distil.ai/hc/en-gb/articles/4419131519121-Daily-Insights-Customer-Segmentation-Tuesday" className="" rel="noreferrer" target="_blank">
                                                       <FontAwesomeIcon icon={faInfo}/>
                                                    </a>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="resonse-header-mobile">
                                        <p className="card-text-mobile card-text fs-m">
                                            It’s time to get to know your customers. Every Tuesday you can get to grips
                                            with who they really are, so you can talk - and sell - to them as
                                            individuals. Are they already engaged or need persuading? Passionate
                                            advocates or at risk of leaving altogether? Tuesday’s card reveals all.
                                        </p>
                                    </div>
                                </div>
                                <div className="card-body" style={{ "marginBottom": "50px" }}>

                                    <div className="segment-body">
                                        <Row>
                                            <Col lg={3} xs={0}/>
                                            <Col lg={3} xs={0} className="mt-3 customers-data-block">
                                                <div className="segment-title mb-2">Active Customers</div>
                                                <div className="segment-biggest mb-3">
                                                    <span className="segment-biggest mb-2">
                                                        {cardData.data?.activeCustomers_formatted ?? 0}
                                                    </span>
                                                </div>
                                                <div className="segment-title mb-0">
                                                    Value in last 12 months
                                                </div>
                                                <div className="segment-price-big mb-4">
                                                    {cardData.data?.activeCustomersLastYearSpend_formatted ?? 0}
                                                </div>
                                            </Col>
                                            <Col lg={3} xs={0} className="mt-3 customers-data-block">
                                                <div className="segment-title mb-2">
                                                    At Risk Customers
                                                </div>
                                                <div className="segment-biggest segment-orange mb-3">
                                                    {cardData.data?.atRiskCustomers_formatted ?? 0}
                                                </div>
                                                <div className="segment-title mb-0">
                                                    Value in last 12 months
                                                </div>
                                                <div className="segment-price-big segment-orange mb-4">
                                                    {cardData.data?.atRiskCustomersLastYearSpend_formatted ?? 0}
                                                </div>
                                            </Col>
                                            <Col lg={3} xs={0}/>
                                        </Row>
                                        <Row>
                                            <Col lg={3} xs={0}/>
                                            <Col>
                                                <div className="chart-block">
                                                    <div className="segment-currently mb-4 mt-4">
                                                        Key Customer Segments
                                                    </div>
                                                    <div className="segment-chart mb-4 line-chart-tuesday">
                                                        <LineChartTuesday cardData={cardData}/>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="segment-body">
                                        <div className="segment-desktop">
                                            <Row className="mb-4">
                                                <Col lg={3} md={3} xs={0}/>
                                                <Col lg={3} md={3} xs={4} className={'tuesday-column'}>
                                                    <div className="segment-title">
                                                        Active High Value<br/> Customers
                                                    </div>
                                                    <div className="segment-big mb-4 mt-3">
                                                        #{" "}{cardData.data?.highValueSegmentData_formatted?.numberOfCustomers_formatted ?? 0}
                                                    </div>
                                                    <div className="segment-price-normal">
                                                        {cardData.data?.highValueSegmentData_formatted?.averageSpend_formatted ?? 0}
                                                    </div>
                                                    <div className="segment-title mb-4">Avg LTV</div>
                                                    <div className="segment-normal">
                                                        {cardData.data?.highValueSegmentData_formatted?.activeCustomersPercentage_formatted ?? 0}
                                                    </div>
                                                    <div className="segment-title mb-4">
                                                        of Active Customers
                                                    </div>
                                                    <div className="segment-normal">
                                                        {cardData.data?.highValueSegmentData_formatted?.last12MonthsActiveCustomersSalesPercentage_formatted ?? 0}
                                                    </div>
                                                    <div className="segment-title mb-4">
                                                        of last 12-months Sales
                                                    </div>
                                                    <div className="segment-title-bold">
                                                        {cardData.data?.highValueSegmentData_formatted?.oneOrderCustomersPercentage_formatted ?? 0} new, {" "}
                                                        {cardData.data?.highValueSegmentData_formatted?.multipleOrderCustomersPercentage_formatted ?? 0} repeat.
                                                    </div>
                                                </Col>
                                                <Col lg={3} md={3} xs={4} style={{minWidth: '180px'}}>
                                                    <div className="segment-title">
                                                        Active Avg Value <br/>Customers
                                                    </div>
                                                    <div className="segment-big mb-4 mt-3">
                                                        #{" "}{cardData.data?.averageValueSegmentData_formatted?.numberOfCustomers_formatted ?? 0}
                                                    </div>
                                                    <div className="segment-price-normal">
                                                        {cardData.data?.averageValueSegmentData_formatted?.averageSpend_formatted ?? 0}
                                                    </div>
                                                    <div className="segment-title mb-4">Avg LTV</div>
                                                    <div className="segment-normal">
                                                        {cardData.data?.averageValueSegmentData_formatted?.activeCustomersPercentage_formatted ?? 0}
                                                    </div>
                                                    <div className="segment-title mb-4">
                                                        of Active Customers
                                                    </div>
                                                    <div className="segment-normal">
                                                        {cardData.data?.averageValueSegmentData_formatted?.last12MonthsActiveCustomersSalesPercentage_formatted ?? 0}
                                                    </div>
                                                    <div className="segment-title mb-4">
                                                        of last 12-months Sales
                                                    </div>
                                                    <div className="segment-title-bold">
                                                        {cardData.data?.averageValueSegmentData_formatted?.oneOrderCustomersPercentage_formatted ?? 0} new, {" "}
                                                        {cardData.data?.averageValueSegmentData_formatted?.multipleOrderCustomersPercentage_formatted ?? 0} repeat.
                                                    </div>
                                                </Col>
                                                <Col lg={3} md={3} xs={4} style={{minWidth: '180px'}}>
                                                    <div className="segment-title">
                                                        Active Low Value <br/>Customers
                                                    </div>
                                                    <div className="segment-big mb-4 mt-3">
                                                        #{" "} {cardData.data?.lowValueSegmentData_formatted?.numberOfCustomers_formatted ?? 0}
                                                    </div>
                                                    <div className="segment-price-normal">
                                                        {cardData.data?.lowValueSegmentData_formatted?.averageSpend_formatted ?? 0}
                                                    </div>
                                                    <div className="segment-title mb-4">Avg LTV</div>
                                                    <div className="segment-normal">
                                                        {cardData.data?.lowValueSegmentData_formatted?.activeCustomersPercentage_formatted ?? 0}
                                                    </div>
                                                    <div className="segment-title mb-4">
                                                        of Active Customers
                                                    </div>
                                                    <div className="segment-normal">
                                                        {cardData.data?.lowValueSegmentData_formatted?.last12MonthsActiveCustomersSalesPercentage_formatted ?? 0}
                                                    </div>
                                                    <div className="segment-title mb-4">
                                                        of last 12-months Sales
                                                    </div>
                                                    <div className="segment-title-bold">
                                                        {cardData.data?.lowValueSegmentData_formatted?.oneOrderCustomersPercentage_formatted ?? 0} new, {" "}
                                                        {cardData.data?.lowValueSegmentData_formatted?.multipleOrderCustomersPercentage_formatted ?? 0} repeat.
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="pt-4" style={{minWidth: '180px'}}>
                                                <Col lg={3} md={3} xs={4} className={'tuesday-column'}>
                                                    <div className="segment-item mb-2">
                                                        Number Purchases
                                                    </div>
                                                    <div className="segment-item mb-2">
                                                        Average Order Value
                                                    </div>
                                                    {/* <div className="segment-item mb-2">
                                                        Visit Frequency
                                                    </div> */}
                                                    <div className="segment-item mb-2">
                                                        Purchase Frequency
                                                    </div>
                                                </Col>
                                                <Col lg={3} md={3} xs={4}>
                                                    <div className="segment-title-alt segment-title">
                                                        <span>Active High Value <br/>Customers</span>
                                                    </div>
                                                    <div className="segment-small mb-2">
                                                        {cardData.data?.highValueSegmentData_formatted?.totalOrderNumber_formatted ?? 0}
                                                    </div>
                                                    <div className="segment-small mb-2">
                                                        {cardData.data?.highValueSegmentData_formatted?.averageOrderValue_formatted ?? 0}
                                                    </div>
                                                    {/* <div className="segment-small mb-2">
                                                        {cardData.data?.highValueSegmentData?.averageVisitFrequencyFormatted ?? ""}
                                                    </div> */}
                                                    <div className="segment-small mb-2">
                                                        {cardData.data?.highValueSegmentData?.averagePurchaseFrequencyFormatted ?? ""}
                                                    </div>
                                                </Col>

                                                <Col lg={3} md={3} xs={4}>
                                                    <div className="segment-title-alt segment-title">
                                                        Active Avg Value <br/>Customers
                                                    </div>
                                                    <div className="segment-small mb-2">
                                                        {cardData.data?.averageValueSegmentData_formatted?.totalOrderNumber_formatted ?? 0}
                                                    </div>
                                                    <div className="segment-small mb-2">
                                                        {cardData.data?.averageValueSegmentData_formatted?.averageOrderValue_formatted ?? 0}
                                                    </div>
                                                    {/* <div className="segment-small mb-2">
                                                        {cardData.data?.averageValueSegmentData?.averageVisitFrequencyFormatted ?? ""}
                                                    </div> */}
                                                    <div className="segment-small mb-2">
                                                        {cardData.data?.averageValueSegmentData?.averagePurchaseFrequencyFormatted ?? ""}
                                                    </div>                                     
                                                </Col>
                                                <Col lg={3} md={3} xs={4}>
                                                    <div className="segment-title-alt segment-title">
                                                        Active Low Value <br/>Customers
                                                    </div>
                                                    <div className="segment-small mb-2">
                                                        {cardData.data?.lowValueSegmentData_formatted?.totalOrderNumber_formatted ?? 0}
                                                    </div>
                                                    <div className="segment-small mb-2">
                                                        {cardData.data?.lowValueSegmentData_formatted?.averageOrderValue_formatted ?? 0}
                                                    </div>
                                                    {/* <div className="segment-small mb-2">
                                                        {cardData.data?.lowValueSegmentData?.averageVisitFrequencyFormatted ?? ""}
                                                    </div> */}
                                                    <div className="segment-small mb-2">
                                                        {cardData.data?.lowValueSegmentData?.averagePurchaseFrequencyFormatted ?? ""} 
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="mb-4 mt-5 pt-3">
                                                <Col md={3} xs={4} style={{minWidth: '250px'}}>
                                                    <div className="segment-item mb-2">
                                                        Leading Purchase
                                                    </div>
                                                    <div className="segment-small segment-small-thin segment-start">
                                                        The most popular leading (first) product bought by
                                                        these two groups is:
                                                    </div>
                                                </Col>
                                                <Col md={3} xs={4} style={{minWidth: '250px'}}>
                                                    <div className="segment-title-alt segment-title">
                                                        <span>Active High Value <br/>Customers</span>
                                                    </div>
                                                    <div className="segment-item segment-small-thin mb-2">
                                                        <span>{cardData.data?.highValueSegmentData?.leadingPurchase?.name}</span>
                                                    </div>
                                                    <div className="segment-small segment-small-thin segment-start">
                                                        {cardData.data?.highValueSegmentData_formatted?.leadingPurchase_formatted?.customerPurchasedPercent_formatted ?? 0} bought this product first
                                                    </div>
                                                </Col>
                                                <Col md={3} xs={4} style={{minWidth: '250px'}}>
                                                    <div className="segment-title-alt segment-title">
                                                        Active Avg Value <br/>Customers
                                                    </div>
                                                    <div className="segment-item segment-small-thin mb-2">
                                                        {cardData.data?.averageValueSegmentData?.leadingPurchase?.name}
                                                    </div>
                                                    <div className="segment-small segment-small-thin segment-start">
                                                        {cardData.data?.averageValueSegmentData_formatted?.leadingPurchase_formatted?.customerPurchasedPercent_formatted ?? 0} bought this product first
                                                    </div>
                                                </Col>
                                                <Col md={3} xs={4} style={{minWidth: '250px'}}>
                                                    <div className="segment-title-alt segment-title">
                                                        Active Low Value <br/>Customers
                                                    </div>
                                                    <div className="segment-item segment-small-thin mb-2">
                                                        {cardData.data?.lowValueSegmentData?.leadingPurchase?.name}
                                                    </div>
                                                    <div className="segment-small segment-small-thin segment-start">
                                                        {cardData.data?.lowValueSegmentData_formatted?.leadingPurchase_formatted
                                                                ?.customerPurchasedPercent_formatted ?? 0} bought this product first
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="mt-5 pt-3">
                                                <Col md={3} xs={4}>
                                                    <div className="segment-item mb-2">Top 5 Products</div>
                                                    <div className="segment-small segment-small-thin">
                                                        Over the past 3-months the most popular product in the
                                                        two groups are:
                                                    </div>
                                                </Col>
                                                <Col md={3} xs={4}>
                                                    <div className="segment-title-alt segment-title">
                                                        Active High Value <br/>Customers
                                                    </div>
                                                    {cardData.data?.highValueSegmentData?.mostPopularProducts?.map(
                                                            (item, index) => {
                                                                const mostPopularProductsFormatted = cardData.data?.highValueSegmentData_formatted?.mostPopularProducts_formatted;
                                                                return (
                                                                        <div key={index}>
                                                                            <div className="segment-small mb-1">
                                                                                {item.name}
                                                                            </div>
                                                                            <div className="segment-small segment-small-thin mb-3">
                                                                                ({
                                                                                (mostPopularProductsFormatted && mostPopularProductsFormatted[index])
                                                                                    ?.customerPurchasedPercent_formatted ?? 0}&nbsp;purchased
                                                                                this)
                                                                            </div>
                                                                        </div>
                                                                );
                                                            }
                                                    )}
                                                </Col>
                                                <Col md={3} xs={4}>
                                                    <div className="segment-title-alt segment-title">
                                                        Active Avg Value <br/>Customers
                                                    </div>
                                                    {cardData.data?.averageValueSegmentData?.mostPopularProducts?.map(
                                                            (item, index) => {
                                                                const averageValueSegmentDataFormatted = cardData.data
                                                                        ?.averageValueSegmentData_formatted;
                                                                const mostPopularProductsFormatted = averageValueSegmentDataFormatted
                                                                        ?.mostPopularProducts_formatted;
                                                                return (
                                                                        <div key={index}>
                                                                            <div className="segment-small mb-1">
                                                                                {item.name}
                                                                            </div>
                                                                            <div className="segment-small segment-small-thin mb-3">
                                                                                ({(mostPopularProductsFormatted && mostPopularProductsFormatted[index])
                                                                                    ?.customerPurchasedPercent_formatted ?? 0}&nbsp;purchased
                                                                                this)
                                                                            </div>
                                                                        </div>
                                                                );
                                                            }
                                                    )}
                                                </Col>
                                                <Col md={3} xs={4}>
                                                    <div className="segment-title-alt segment-title">
                                                        Active Low Value <br/>Customers
                                                    </div>
                                                    {cardData.data?.lowValueSegmentData?.mostPopularProducts?.map(
                                                            (item, index) => {
                                                                const lowValueSegmentDataFormatted = cardData.data?.lowValueSegmentData_formatted;
                                                                const mostPopularProductsFormatted = lowValueSegmentDataFormatted
                                                                        ?.mostPopularProducts_formatted;
                                                                return (
                                                                        <div key={index}>
                                                                            <div className="segment-small mb-1">
                                                                                {item.name}
                                                                            </div>
                                                                            <div className="segment-small segment-small-thin mb-3">
                                                                                ({(mostPopularProductsFormatted && mostPopularProductsFormatted[index])
                                                                                    ?.customerPurchasedPercent_formatted ?? 0}&nbsp;purchased
                                                                                this)
                                                                            </div>
                                                                        </div>
                                                                );
                                                            }
                                                    )}
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer fs-m">
                                    <Row>
                                        <Col lg={2}/>
                                        <Col ls={8} className="section-text-footer">
                                            <div className="fs-l fw-bold">
                                                Tomorrow is Cohort Check-in Wednesday{" "}
                                            </div>
                                            <div className="fs-xm text-gray">
                                                Time to catch up with how your latest cohort of customers
                                                are getting on.
                                            </div>
                                            <div className="card-icon">
                                                <img src={iconWednesday} alt="footer..."/>
                                            </div>
                                        </Col>
                                        <Col lg={2}/>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
    );
}

export default Tuesday;
