import React, { Component } from "react";
import CommonMessage from '../../../../components/CommonMessage/commonMessage';
 
class Notifications extends Component {
  render() {
    return (
      <div className="p-4 align-center">
       <CommonMessage />
       </div>
    );
  }
}

export default Notifications;
