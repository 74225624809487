import React, {Component} from "react";
import ListGroup from 'react-bootstrap/ListGroup'
import NoData from "../NoData/nodata";
import {Select, Textbox} from "react-inputs-validation";
import {mapAttr, notNull} from "./attributeProcessor";
import {zapierTypes} from "../../variables/globalValues.json";
import {Col, Form, FormGroup} from "react-bootstrap";
import dataConnections from "../../services/dataConnections";
import destination from "../../services/destination";
import _ from "lodash";
import {toast} from "react-toastify";

/**
 * props:
 *   attributesData - list of all groups with attributes
 */

class MapBrazeAttributes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            coreField: [
                {
                    id: 1000001,
                    name: null,
                    nameCoreField: "External ID",
                    tag: "CUSTOMER_EXTERNAL_ID",
                    isText: true,
                    isNumerical: true,
                    isBool: false,
                    validate: true
                },
                {
                    id: 1000002,
                    name: null,
                    nameCoreField: "Email",
                    tag: "CUSTOMER_EMAIL_ADDRESS",
                    isText: true,
                    isNumerical: false,
                    isBool: false,
                    validate: true
                },
                {
                    id: 1000025,
                    name: null,
                    nameCoreField: "Subscribed Status",
                    tag: "GDPR_STATUS_SUBSCRIBED",
                    isText: false,
                    isNumerical: false,
                    isBool: true,
                    validate: false
                },
                {
                    id: 1000004,
                    name: null,
                    nameCoreField: "First name",
                    tag: "CUSTOMER_FIRST_NAME",
                    isText: true,
                    isNumerical: false,
                    isBool: false,
                    validate: false
                },
                {
                    id: 1000005,
                    name: null,
                    nameCoreField: "Last Name",
                    tag: "CUSTOMER_LAST_NAME",
                    isText: true,
                    isNumerical: false,
                    isBool: false,
                    validate: false
                },
                {
                    id: 1000007,
                    name: null,
                    nameCoreField: "Country",
                    tag: "CUSTOMER_COUNTRY_CODE",
                    isText: true,
                    isNumerical: false,
                    isBool: false,
                    validate: false
                },
                {
                    id: 1000006,
                    name: null,
                    nameCoreField: "Phone",
                    tag: "CUSTOMER_MOBILE_NUMBER",
                    isText: true,
                    isNumerical: true,
                    isBool: false,
                    validate: false
                },
                {
                    id: 1000097,
                    name: null,
                    nameCoreField: "Language",
                    tag: "LANGUAGE",
                    isText: true,
                    isNumerical: false,
                    isBool: false,
                    validate: false
                }

            ],
            validate: true,
            textAttrs: [],
            numericalAttrs: [],
            boolAttrs: []
        };
    }

    componentWillMount() {
        this.getAttrs();
    }

    getAttrs = async () => {
        const {coreField} = this.state;
        let enableIds = [];
        this.props.attributesData.forEach(data => {
            data.attributes.forEach(attr => {
                enableIds.push(attr.attributeId);
                if(attr.externalId) {
                    if(attr.externalId.includes(",")) {
                        let tags = attr.externalId.split(",");
                        tags.forEach(tag => {
                            coreField.forEach(coreAttr => {
                                if(coreAttr.tag === tag) {
                                    coreAttr.name = attr.attributeId;
                                }
                            })
                        })
                    }else {
                        coreField.forEach(coreAttr => {
                            if(coreAttr.tag === attr.externalId) {
                                coreAttr.name = attr.attributeId;
                            }
                        })
                    }
                    attr.externalId = null;
                }
            })
        })
        let sectionNameAndIds = [];
        let attrs = [];
        this.props.attributesData.flatMap(section => {
            let x = {
                sectionName: section.sectionName,
                ids: [],
                tags: []
            }
            section.attributes.forEach(attr => {
                attrs.push({
                    attributeId: attr.attributeId,
                    attributeDataTag: attr.attributeTag,
                    externalId: attr.externalId,
                    attributeDisplayName: attr.name
                });

                x.ids.push(attr.attributeId);
                if(attr.attributeTag) {
                    x.tags.push(attr.attributeTag);
                }
            })
            sectionNameAndIds.push(x);
        });

        await destination.getAttributesByDestAttrs(attrs).then(response => {

            let numericalAttrs = [];
            let textAttrs = [];
            let boolAttrs = [];
            response.map(attr => {
                let sectionName = "";
                if(this.props.attributesData[0].sectionId === -1) {
                    let tagAndId = {};
                    this.props.attributesData[0].attributes.forEach(coreAttr => {
                        tagAndId[coreAttr.attributeTag] = coreAttr.attributeId;
                    })

                    if(!enableIds.includes(attr.id)) {
                        attr.id = tagAndId[attr.attributeDataTag];
                    }
                }
                sectionNameAndIds.forEach(section => {
                    if (section.ids.includes(attr.id) || section.tags.includes(attr.attributeDataTag)) {
                        sectionName = section.sectionName;
                    }
                })

                let x = {
                    id: attr.id,
                    name: sectionName + "." + attr.attributeDisplayName
                };

                switch (attr.attributeType) {
                    case "STRING":
                        textAttrs.push(x);
                        break;
                    case "INTEGER":
                        numericalAttrs.push(x);
                        break;
                    case "BOOLEAN":
                        boolAttrs.push(x);
                        break;
                }
            })

            this.setState({numericalAttrs: numericalAttrs, textAttrs: textAttrs, boolAttrs: boolAttrs});
        })
    }

    getUpdatedData() {
        const {coreField} = this.state;
        this.validateField();

        let idAndExternalId = {};
        coreField.forEach(field => {
            if(idAndExternalId.hasOwnProperty(field.name)) {
                let tags = idAndExternalId[field.name];
                tags += "," + field.tag;
                idAndExternalId[field.name] = tags;
            } else {
                idAndExternalId[field.name] = field.tag;
            }
        });

        return idAndExternalId;
    }

    validateField() {
        const {coreField} = this.state;
        let isNotValid = false;

        if(!coreField[0].name && !coreField[1].name) {
            isNotValid = true
        }

        if (isNotValid) {
            toast.error("Braze requires an External Identifier,Email to be chosen - and does not recommend the email address");
            this.setState({validate: false})
            throw new Error();
        }
    }

    updateAttr(i, e) {
        let coreField = this.state.coreField;
        coreField[i].name = parseInt(e);
        this.setState({coreField: coreField});
    }

    render() {
        let coreField = this.state.coreField;
        const {numericalAttrs, textAttrs, boolAttrs} = this.state;
        let optionsList = [
            {
                id: null,
                name: "Select attribute"
            }
        ]
        return (
            <div className="d-block mySize attribute-accordion-braze">
                <div className="attribute-section d-flex flex-column-reverse">

                    <div className="attribute-section-body">
                        {<Form.Group as={Col}>
                            {coreField.map((field, attrIdx) => (
                                <Form.Row className="m-2">
                                    <Col>
                                        <strong>{field.nameCoreField}</strong>
                                    </Col>
                                    <Col>
                                        <Select
                                            classNameSelect="select-control select-control-thin "
                                            classNameWrapper="form-control select-control-wrapper-reset selection-type"
                                            classNameContainer=""
                                            classNameOptionListContainer="select-control-option select-option-text-ellipsis"
                                            classNameOptionListItem="select-option-text-ellipsis select-option-thin"
                                            value={field.name}
                                            customStyleSelect={{}}
                                            customStyleWrapper={{}}
                                            customStyleContainer={{width:"350px"}}
                                            optionList={optionsList.concat(
                                                field.isText ? textAttrs : [],
                                                field.isNumerical ? numericalAttrs : [],
                                                field.isBool ? boolAttrs : [])}
                                            customStyleOptionListContainer={{}}
                                            onChange={(e) => this.updateAttr(attrIdx, e)}
                                            onBlur={e => {}}
                                            validate={this.state.validate}
                                            validationOption={{
                                                name: "Attribute",
                                                check: field.validate,
                                                required: field.validate
                                            }}
                                        />
                                    </Col>
                                </Form.Row>
                            ))}
                        </Form.Group>}
                    </div>
                </div>
            </div>
        )
    }
}

export default MapBrazeAttributes;
