import React from "react";
import "./styles.scss";

import {Row} from "react-bootstrap";
import FirstVisitTable from "./FirstVisitTable";
import MarketingAttributeRow from "./MarketingAttributeRow";
import FunnelStageAttributionTable from "./FunnelStageAttributionTable";
import DirectUnknownRow from "./DirectUnknownRow";
import RegisteredTable from "./RegisteredTable";
import PurchaseTable from "./PurchaseTable";
import ChannelPerformanceAndProjectedLTV from "../funnelFull/ChannelPerformanceAndProjectedLTV";
import MainBlock from "../funnelFull/MainBlock";

const FunnelMetric = ({cardData}) => {

    return (
        <div className="funnel-metric">
            <div className="card-body">
                <div className="content ">

                    {/* ------------------------------ MAIN BLOCK ----------------------------- */}
                    <MainBlock data={cardData?.data}/>
                    {/* ----------------------------------------------------------------------- */}

                    {/* ---------------- Channel Performance and Projected LTV ---------------- */}
                    <ChannelPerformanceAndProjectedLTV roiAndLifetimeValuesData={cardData?.data?.roiAndLifetimeValues}
                                                       roiAndLifetimeValuesData_formatted={cardData?.data?.roiAndLifetimeValues_formatted}/>
                    {/* ----------------------------------------------------------------------- */}

                    {/* ---------------- Funnel ---------------- */}
                    <Row className="w-100">
                        <div className="main" style={{width: "100%"}}>
                            <div className="row">
                                <div className="col">
                                    <p className="month color-brown">
                                        {cardData?.data?.cohortName?.split(" ")[0]} Funnel</p>
                                </div>
                            </div>
                        </div>
                    </Row>
                    <Row className="w-100">
                        <div className="main"  style={{width: "100%"}}>
                            {/* ---------------- Mobile view START ---------------- */}
                            {/* ---------------- First Visit ---------------- */}
                            <FirstVisitTable funnelMetrics_formatted={cardData?.data?.funnelMetrics_formatted[0]}/>
                            <MarketingAttributeRow data={cardData?.data?.funnelMetrics_formatted[0].marketingAttributedCustomerCount_formatted}/>
                            <FunnelStageAttributionTable funnelStageAttributions={cardData?.data?.funnelMetrics[0]?.funnelStageAttributions}
                                                         funnelStageAttributions_formatted={cardData?.data?.funnelMetrics_formatted[0]?.funnelStageAttributions_formatted}/>
                            <DirectUnknownRow funnelMetric={cardData.data?.funnelMetrics[0]} funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[0]}/>

                            {/* ---------------- Registered ---------------- */}
                            <RegisteredTable funnelMetric={cardData?.data?.funnelMetrics_formatted[1]}/>
                            <MarketingAttributeRow data={cardData?.data?.funnelMetrics_formatted[1].marketingAttributedCustomerCount_formatted}/>
                            <FunnelStageAttributionTable funnelStageAttributions={cardData?.data?.funnelMetrics[1]?.funnelStageAttributions}
                                                         funnelStageAttributions_formatted={cardData?.data?.funnelMetrics_formatted[1]?.funnelStageAttributions_formatted}/>
                            <DirectUnknownRow funnelMetric={cardData.data?.funnelMetrics[1]} funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[1]}/>

                            {/* ---------------- 1st Purchase ---------------- */}
                            <PurchaseTable name="1st Purchase" funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[2]}/>
                            <MarketingAttributeRow data={cardData?.data?.funnelMetrics_formatted[2].marketingAttributedCustomerCount_formatted}/>
                            <FunnelStageAttributionTable funnelStageAttributions={cardData?.data?.funnelMetrics[2]?.funnelStageAttributions}
                                                         funnelStageAttributions_formatted={cardData?.data?.funnelMetrics_formatted[2]?.funnelStageAttributions_formatted}/>
                            <DirectUnknownRow funnelMetric={cardData.data?.funnelMetrics[2]} funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[2]}/>

                            {/* ---------------- 2nd Purchase ---------------- */}
                            <PurchaseTable name="2nd Purchase" funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[3]}/>
                            <MarketingAttributeRow data={cardData?.data?.funnelMetrics_formatted[3].marketingAttributedCustomerCount_formatted}/>
                            <FunnelStageAttributionTable funnelStageAttributions={cardData?.data?.funnelMetrics[3]?.funnelStageAttributions}
                                                         funnelStageAttributions_formatted={cardData?.data?.funnelMetrics_formatted[3]?.funnelStageAttributions_formatted}/>
                            <DirectUnknownRow funnelMetric={cardData.data?.funnelMetrics[3]} funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[3]}/>

                            {/* ---------------- 3rd Purchase ---------------- */}
                            <PurchaseTable name="3rd+ Purchase" funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[4]}/>
                            <MarketingAttributeRow data={cardData?.data?.funnelMetrics_formatted[4].marketingAttributedCustomerCount_formatted}/>
                            <FunnelStageAttributionTable funnelStageAttributions={cardData?.data?.funnelMetrics[4]?.funnelStageAttributions}
                                                         funnelStageAttributions_formatted={cardData?.data?.funnelMetrics_formatted[4]?.funnelStageAttributions_formatted}/>
                            <DirectUnknownRow funnelMetric={cardData.data?.funnelMetrics[4]} funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[4]}/>
                            {/* ---------------- Mobile view END ---------------- */}
                        </div>
                    </Row>
                </div>
            </div>
        </div>

    );
};

export default FunnelMetric;
