import React, { Component } from "react";

import moment from "moment";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import CardControlers from "./components/cardControlers";
import { get } from "lodash";

class DataSourceCard extends Component {
  state = {};
  filterTagName(tag, index) {
    if (tag !== "NONE") {
      const colorCodes = [
        "#A8D322",
        "#7BC412",
        "#2FAA22",
        "#85CEE0",
        "#00ABCB",
        "#32A4E3",
        "#0087C5",
        "#DDB1CD",
        "#C482B3",
        "#EF5B88",
        "#AE72ED",
        "#8555E0",
        "#5A4A75",
        "#F05A51",
        "#DF171B",
        "#EF571B",
        "#F6921E",
        "#FFD300",
        "#DBE50A"
      ];
      const tagStlye = {
        backgroundColor: colorCodes[index]
      };

      const removalbles = ["CUSTOMER_", "PRODUCT_", " CONTENT_", "ORDER_"];

      var tagValue = tag;
      removalbles.map(item => {
        tagValue = tagValue.replace(item, "");
      });
      tagValue = tagValue.replace("_", " ").toLowerCase();
      return (
        <span style={tagStlye} className="data-firstname-bg">
          {tagValue}
        </span>
      );
    } else {
      return null;
    }
  }

  pad = num => {
    return ("" + num).slice(-2);
  };
  formatSeconds = secs => {
    var minutes = Math.floor(secs / 60);
    secs = secs % 60;
    var hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    if (hours === 0 && minutes > 0) {
      return (
        <React.Fragment>
          <strong>{this.pad(minutes)}</strong> Minutes{" "}
          <strong> {this.pad(secs)} </strong> {this.pad(secs) >1 ?"Seconds":"Second"}
        </React.Fragment>
      );
    } else if (minutes === 0) {
      return (
        <React.Fragment>
            <strong> {this.pad(secs)} </strong> {this.pad(secs) >1 ?"Seconds":"Second"}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <strong>{this.pad(hours)}</strong> Hours{" "}
          <strong>{this.pad(minutes)}</strong> Minutes{" "}
          <strong> {this.pad(secs)} </strong> {this.pad(secs) >1 ?"Seconds":"Second"}
        </React.Fragment>
      );
    }
  };

  render() {
    const { cardContent, onPinnedCard, onCardHistory, keyIndex } = this.props;
    const cardDetails = get(cardContent, "cardDetails", {});

    const cardTitle = {
      DATA_SOURCE_SYNC_COMPLETED:      
        "The " + cardDetails.connectionName + " Data Source '" + cardDetails.dataSourceName + "' has synced successfully.",
      CREATED:
        "A new Data Source called " +
        cardContent.stackTitle +
        " has been created!"
    };

    const cardHeading = {
      DATA_SOURCE_SYNC_COMPLETED: "Sync Complete",
      CREATED: "Created"
    };

    const cardType = {
      CUSTOMER: "a Customer",
      PRODUCT: "a Product",
      ORDER: "an Order",
      CONTECT: "a Content"
    };

    return (
      <div className="card fadeIn data-source">
        <div className="card-thumbnail">
          <span className="icon-data-source"></span>
        </div>
        <div className="card-content">
          <CardControlers
            onPinnedCard={cardContent.newsfeedStackCardType.cardType === "DATA_SOURCE_SYNC_COMPLETED" ? onPinnedCard : null}
            cardContent={cardContent}
            onCardHistory={cardContent.newsfeedStackCardType.cardType === "DATA_SOURCE_SYNC_COMPLETED" ? onCardHistory : null}
          />

          <div className="card-detail">
            <h6 className="card-type">
              Data Source /{" "}
              {cardHeading[cardContent.newsfeedStackCardType.cardType]}
            </h6>
            <h5>{cardTitle[cardContent.newsfeedStackCardType.cardType]}</h5>
            <p className="date">
              {" "}
              {moment(cardContent.dateShowAfter)
                .local()
                .format("MMMM DD, YYYY")}
              <span className="ml-1">
                {moment(cardContent.dateShowAfter)
                  .local()
                  .format("  h:mm a")}
              </span>
            </p>
            {cardDetails !== null && (
              <p className="desc">                
                This is {cardType[cardDetails.dataSourceType]} Data Source.
              </p>
            )}

            {/* <div className={"card-graphic"} style={divStyle} /> */}
            {cardDetails !== null && (
              <div className="card-graphics-section">
                {cardContent.newsfeedStackCardType.cardType ===
                  "DATA_SOURCE_SYNC_COMPLETED" && (
                  <div className="graphics-detail">
                    <p>
                      {" "}
                      The Sync processed{" "}
                      <strong>{cardDetails.recordsProcessed} </strong>records in{" "}
                      {this.formatSeconds(cardDetails.processDurationSeconds)}
                      {/* <strong>{cardDetails.processDurationSeconds} </strong> 
                      seconds{" "}*/}
                    </p>

                    <ListGroup>
                      <ListGroup.Item>
                        <strong>{cardDetails.numUpdated}</strong> records were
                        updated
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <strong>{cardDetails.numNew}</strong> were inserted
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <strong>{cardDetails.numDeleted}</strong> records were
                        deleted
                      </ListGroup.Item>
                    </ListGroup>
                  </div>
                )}

                {cardContent.newsfeedStackCardType.cardType === "CREATED" && (
                  <React.Fragment>
                    <div className="graphics-detail">
                      <p>
                        There are <strong>{cardDetails.numAttributes}</strong>{" "}
                        Attributes have been in this Data Source
                      </p>
                    </div>

                    <div className="graphics-attribute">
                      <table>
                        <thead>
                          <tr>
                            <td>
                              <span>Attribute</span>
                            </td>
                            <td>
                              <span>Data Marker</span>
                            </td>
                          </tr>
                        </thead>

                        <tbody>
                          {cardDetails.attributes !== undefined &&
                            cardDetails.attributes.length > 0 &&
                            cardDetails.attributes.map(
                              (item, index) =>
                                index <= 4 && (
                                  <tr
                                    key={index}
                                    className={
                                      item.attributeTag === "NONE"
                                        ? "noTag"
                                        : ""
                                    }
                                  >
                                    <td>{item.attributeName}</td>
                                    <td>
                                      {" "}
                                      {this.filterTagName(
                                        item.attributeTag,
                                        index
                                      )}
                                    </td>
                                  </tr>
                                )
                            )}
                        </tbody>
                      </table>
                      {/* { cardDetails !== null  &&
                cardDetails.attributes.length - 5 > 0 && (
                    <span>
                      and another {cardContent.attributes.length - 5} attributes{" "}
                    </span>
                  )} * /* {  cardDetails !== null && cardDetails.attributes.length -5 > 0   && <span>
                      and another {cardContent.attributes.length - 5} attributes{" "}
                    </span>  */}
                    </div>

                    <p>
                      The Data will be avalable after the next full data refresh
                    </p>
                  </React.Fragment>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default DataSourceCard;
