import React, { Component } from "react";
import { ListGroup } from "react-bootstrap";
class Choronotype extends Component {
  state = {};
  render() {
    const { currentEnrichment,onAttributeChange} = this.props
    return (
      <div className="body-content-wrap bg-white">
        <p>
        Choose how many Chronotype Categories you would like. The higher the number the better the personalisation, however keep in mind the practicallity of dealing with the higher numbers. 
        </p>

        <div className="chronotype-category">
          <ListGroup as="ul">
            <ListGroup.Item as="li"  onClick={()=>onAttributeChange( "chronotypeSettingNumCategories","TWO")} >
              <i className={currentEnrichment.chronotypeSettingNumCategories === "TWO" ?  "icon-check_circle active" :  "icon-check_circle "} />
              <p>
                <span>2 Chronotype categories</span>
                Your Customers will be assigned into one of <u>two </u> categories. Those whose enagement is typically in the <strong>Morning </strong>and those who prefer the <strong>Afternoon</strong>.
              </p>
            </ListGroup.Item>
            <ListGroup.Item as="li" onClick={()=>onAttributeChange("chronotypeSettingNumCategories","THREE")}>
              <i className={currentEnrichment.chronotypeSettingNumCategories === "THREE" ?  "icon-check_circle active" :  "icon-check_circle "}  />
              <p>
                <span>3 Chronotype categories</span>
                Your Customers will be assigned into one of <u>three</u>  categories. Those whose enagement is typically in the <strong>Morning</strong> and those it over <strong>Lunchtime</strong> and those in the <strong>Evening</strong>.
              </p>
            </ListGroup.Item>
            <ListGroup.Item as="li" onClick={()=>onAttributeChange("chronotypeSettingNumCategories","SIX")}>
              <i className={currentEnrichment.chronotypeSettingNumCategories === "SIX" ?  "icon-check_circle active" :  "icon-check_circle "}  />
              <p>
                <span>6 Chronotype categories</span>
                Your Customers will be assigned into one of <u>six </u> categories.<strong> Early Birds, Morning, Lunchtime, Afternoon, Evening</strong> and <strong>Nightowl</strong>.
              </p>
            </ListGroup.Item>
          </ListGroup>
        </div>

        <div className="chronotype-category">
          <h6>Assigned day type</h6>
          <ListGroup as="ul">
            <ListGroup.Item as="li" 
            onClick={()=>onAttributeChange("chronotypeSettingDayType","WEEK_OR_WEEKEND")}> 
              <i className={currentEnrichment.chronotypeSettingDayType === "WEEK_OR_WEEKEND" ?  "icon-check_circle active" :  "icon-check_circle "}  />
              <p>
              Attempt to assign each Customer to <strong> Week</strong> or<strong> Weekend</strong>
              </p>
            </ListGroup.Item>
            <ListGroup.Item as="li" onClick={()=>onAttributeChange("chronotypeSettingDayType","DAY_OF_WEEK")}>
              <i className={currentEnrichment.chronotypeSettingDayType === "DAY_OF_WEEK" ?  "icon-check_circle active" :  "icon-check_circle "}  />
              <p>
              Attempt to assign each Customer to a single <strong>Day of the week </strong> 
              </p>
            </ListGroup.Item>
          </ListGroup>
        </div>

        <div className="chronotype-category">
          <h6>Where there is not enough data to assign a Chronotype</h6>
          <ListGroup as="ul">
            <ListGroup.Item as="li" onClick={()=>onAttributeChange("chronotypeSettingUnknownAssignation","ASSIGN_RANDOMLY")}>
            <i className={currentEnrichment.chronotypeSettingUnknownAssignation === "ASSIGN_RANDOMLY" ?  "icon-check_circle active" :  "icon-check_circle "}  />
              <p>
              <strong>Randomly</strong> distribute between other Chronotypes 
              </p>
            </ListGroup.Item>
            <ListGroup.Item as="li" onClick={()=>onAttributeChange("chronotypeSettingUnknownAssignation","ASSIGN_TO_UNKNOWN")}>
            <i className={currentEnrichment.chronotypeSettingUnknownAssignation === "ASSIGN_TO_UNKNOWN" ?  "icon-check_circle active" :  "icon-check_circle "}  />
              <p>
                {" "}
                Mark as <strong>Unknown</strong>{" "}
              </p>
            </ListGroup.Item>
          </ListGroup>
        </div>
      </div>
    );
  }
}

export default Choronotype;
