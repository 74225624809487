import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "../../../routes/customers";

import "./assets/styles.css";

class Customers extends Component {
  componentWillUnmount() {
    this.props.onContextChange(null);
    this.props.onContextIdChange(null);
  }
  render() {
    return (
      <Switch>
        {routes.map((route, idx) => {
          return route.component ? (
            <Route
              key={idx}
              path={route.path}
              exact={route.exact}
              name={route.name}
              render={props => (
                <route.component
                  contextUser={this.props.contextUser}
                  updateContextUser={this.props.updateContextUser}
                  onTogglePin={this.props.onTogglePin}
                  onCustomerDetail={this.props.onCustomerDetail}
                  onContextChange={this.props.onContextChange}
                  onContextIdChange={this.props.onContextIdChange}
                  onCheckChange={this.props.onCheckChange}
                  onVerifyChange={this.props.onVerifyChange}
                  {...props}
                />
              )}
            />
          ) : null;
        })}
        <Redirect from="/customers" to="/customers/segments" />
      </Switch>
    );
  }
}

export default Customers;
