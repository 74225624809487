import Users from "../views/Main/Settings/Users";
import Dataconnections from "../views/Main/Settings/Dataconnections";
import Integrations from "../views/Main/Settings/Integrations";

import PaymentAndBilling from "../views/Main/Settings/PaymentAndBilling";
import Myprofile from "../views/Main/Settings/Myprofile";
import Tracking from "../views/Main/Settings/Tracking";
import Notifications from "../views/Main/Settings/Notifications";
import BillingPlan from "../views/Main/Settings/BillingPlan";

const ADMIN = [
  { path: "/settings", exact: true, name: "Settings" },
  {
    path: "/settings/users",
    name: "Users",
    component: Users,
    className: "users-link",
    roles: ["ADMIN"]
  },
  {
    path: "/settings/tracking",
    name: "Tracking",
    component: Tracking,
    className: "tracking-link",
    roles: ["ADMIN", "CONTRIBUTOR", "TECHNICAL"]
  },
  {
    path: "/settings/dataconnections",
    name: "Data Connections",
    component: Dataconnections,
    className: "dataconnections-link",
    roles: ["ADMIN", "CONTRIBUTOR", "TECHNICAL"]
  },
  {
    path: "/settings/integrations",
    name: "Integrations",
    component: Integrations,
    className: "integrations-link",
    roles: ["ADMIN", "CONTRIBUTOR", "TECHNICAL"]
  },
  {
    path: "/settings/myprofile",
    name: "My Profile",
    component: Myprofile,
    className: "myprofile-link",
    roles: ["ADMIN", "CONTRIBUTOR", "TECHNICAL", "READER", "NEWSFEED"]
  },
  {
    path: "/settings/billing",
    name: "Billing Plan",
    component: BillingPlan,
    className: "billingplan-link",
    roles: ["ADMIN", "CONTRIBUTOR", "TECHNICAL"]
  }
  // {
  //   path: "/settings/notifications",
  //   name: "My Notifications",
  //   component: Notifications,
  //   className: "notifications-link",
  //   roles: ["ADMIN", "CONTRIBUTOR", "TECHNICAL", "READER","NEWSFEED"]
  // }
];
export const CONTRIBUTOR = [
  { path: "/settings", exact: true, name: "Settings" },

  {
    path: "/settings/tracking",
    name: "Tracking",
    component: Tracking,
    className: "tracking-link",
    roles: ["ADMIN", "CONTRIBUTOR", "TECHNICAL"]
  },
  {
    path: "/settings/dataconnections",
    name: "Data Connections",
    component: Dataconnections,
    className: "dataconnections-link",
    roles: ["ADMIN", "CONTRIBUTOR", "TECHNICAL"]
  },
  {
    path: "/settings/integrations",
    name: "Integrations",
    component: Integrations,
    className: "integrations-link",
    roles: ["ADMIN", "CONTRIBUTOR", "TECHNICAL"]
  },
  {
    path: "/settings/myprofile",
    name: "My Profile",
    component: Myprofile,
    className: "myprofile-link",
    roles: ["ADMIN", "CONTRIBUTOR", "TECHNICAL", "READER", "NEWSFEED"]
  },
  {
    path: "/settings/billing",
    name: "Billing Plan",
    component: BillingPlan,
    className: "billingplan-link",
    roles: ["ADMIN", "CONTRIBUTOR", "TECHNICAL"]
  }
];

export const TECHNICAL = [
  { path: "/settings", exact: true, name: "Settings" },

  {
    path: "/settings/tracking",
    name: "Tracking",
    component: Tracking,
    className: "tracking-link",
    roles: ["ADMIN", "CONTRIBUTOR", "TECHNICAL"]
  },
  {
    path: "/settings/dataconnections",
    name: "Data Connections",
    component: Dataconnections,
    className: "dataconnections-link",
    roles: ["ADMIN", "CONTRIBUTOR", "TECHNICAL"]
  },
  {
    path: "/settings/integrations",
    name: "Integrations",
    component: Integrations,
    className: "integrations-link",
    roles: ["ADMIN", "CONTRIBUTOR", "TECHNICAL"]
  },
  {
    path: "/settings/myprofile",
    name: "My Profile",
    component: Myprofile,
    className: "myprofile-link",
    roles: ["ADMIN", "CONTRIBUTOR", "TECHNICAL", "READER", "NEWSFEED"]
  },
  {
    path: "/settings/billing",
    name: "Billing Plan",
    component: BillingPlan,
    className: "billingplan-link",
    roles: ["ADMIN", "CONTRIBUTOR", "TECHNICAL"]
  }
];

export const NEWSFEED = [
  { path: "/settings", exact: true, name: "Settings" },
  {
    path: "/settings/myprofile",
    name: "My Profile",
    component: Myprofile,
    className: "myprofile-link",
    roles: ["ADMIN", "CONTRIBUTOR", "TECHNICAL", "READER", "NEWSFEED"]
  }
];
export const READER = [
  { path: "/settings", exact: true, name: "Settings" },
  {
    path: "/settings/myprofile",
    name: "My Profile",
    component: Myprofile,
    className: "myprofile-link",
    roles: ["ADMIN", "CONTRIBUTOR", "TECHNICAL", "READER", "NEWSFEED"]
  }
];

export default ADMIN;
