import React, {Component} from "react";
import {Card, ListGroup, ListGroupItem} from "react-bootstrap";
import "../assets/css/styles.css";
import {Link} from "react-router-dom";
import TopTitle from "../components/topTitle";
import _ from "lodash";
import moment from "moment";

class DestinationHome extends Component {
    render() {
        let currentUser = JSON.parse(sessionStorage.getItem("selectedMembers"));
        const {destinationData, loadingDestination} = this.props;
        return (
            <div className="destination-wrap destination-home">
                <TopTitle title="How would you like to use your Customer Data?"/>
                <div className="card-wrap">
                    {destinationData.map((card, key) => (
                        <Card key={key}>
                            <Card.Body>
                                <Card.Img variant="top" src={card.logo}/>
                                <Card.Title>{card.name}</Card.Title>
                                <Card.Text>{card.description}</Card.Text>
                                {currentUser.membershipType !== 'READER' &&
                                <Link
                                    className="primary-card-link btn btn-primary"
                                    to={{
                                        pathname: "/destinations/overview",
                                        state: {
                                            selectedCard: card
                                        }
                                    }}
                                >
                                    Create
                                </Link>
                                }
                            </Card.Body>
                            <ListGroup className="list-group-flush">
                                {_.orderBy(card.cardContent, "createdDate", "desc").map(
                                    (cardContent, key) => (
                                        <ListGroupItem key={key}>
                                            <Link
                                                className="linkList"
                                                to={{
                                                    pathname: "/destinations/view",
                                                    state: {
                                                        cardEnum: card.enum,
                                                        cardContentID: cardContent.id,
                                                    }
                                                }}
                                            >
                                                <div className="card-list">
                                                    <h6>{cardContent.segment.title}</h6>
                                                    <div className="sub-content">
                                                        {cardContent.segment.segmentName ? (
                                                            <React.Fragment>
                                                                <span>{cardContent.segment.segmentName}</span>
                                                                <br/>
                                                            </React.Fragment>
                                                        ) : (
                                                            <div className="no-record getting-segment">
                                                                <span className="icon-distil-logo icon spin"/>{" "}
                                                                Getting Segments
                                                            </div>
                                                        )}
                                                        Created{" "}
                                                        <strong>
                                                            {moment(cardContent.createdDate).format(
                                                                "MMM DD, YYYY"
                                                            )}
                                                        </strong>
                                                    </div>
                                                </div>

                                                <div className="icon-wrap">
                                                    {!cardContent.enabled && (
                                                        <span className="icon-disable float-right"/>
                                                    )}

                                                    <span className="icon-chevron-thin-right right-arrow"/>
                                                </div>
                                            </Link>
                                        </ListGroupItem>
                                    )
                                )}
                                {card.cardContent.length === 0 && (
                                    <ListGroupItem className="no-record">
                                        {loadingDestination ? (
                                            "No Destinations"
                                        ) : (
                                            <React.Fragment>
                                                <span className="icon-distil-logo icon spin"/>{" "}
                                                Getting Destinations
                                            </React.Fragment>
                                        )}
                                    </ListGroupItem>
                                )}
                            </ListGroup>
                        </Card>
                    ))}
                </div>
            </div>
        );
    }
}

export default DestinationHome;
