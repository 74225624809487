import React, { Component } from "react";
import "react-inputs-validation/lib/react-inputs-validation.min.css";

import ResetPasswordError from "./Pages/ResetPasswordError";
import ResetPassword from "./Pages/ResetPassword";
import queryString from "query-string";
import AuthenticationService from "../../../services/authentication";
import Loader from "../../../components/Loader/loader";
import { toast } from "react-toastify";
import BannerPanel from "../../../components/Branding/BannerPanel/BannerPanel";
class Verify extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    isloading: true,
    token: null,
    currentSection: "SETPASSWORD",
    email: null
  };

  componentDidMount() {

    const token = queryString.parse(this.props.location.search);
    // this.onVerify(token.token)
    if (token.email !== undefined && token.email !== "") {
      this.setState({ email: token.email });
    }
    if (token.token !== undefined && token.token !== "") {
      this.setState({ token: token.token });
    }
  }

  onVerify = async token => {
    try {
      await AuthenticationService.emailAuthentiction(token).then(response => {
        this.setState({ currentSection: "SETPASSWORD" });
      });
    } catch (ex) {
      this.setState({ currentSection: "EMAILSENT" });
    }
  };

  setPassword = async password => {
    let { token, email } = this.state;
    let data = { password, emailAddress: email };
    try {
      await AuthenticationService.setPassword(data, token).then(response => {
        toast.success("You have successfully reset your Distil password", {
          onClose: () => (window.location = "/")
        });
      });
    } catch (ex) {
      if (ex.response.status == 400) {
        toast.error(ex.response.data.message);
        this.setState({ currentSection: "PASSWODSETERROR" });
      }
      if (ex.response.status == 404) {
        toast.error(ex.response.data.message, {
          onClose: () => (window.location = "/forgot")
        });
      }
    }
  };

  renderComponent() {
    if (this.state.currentSection !== null) {
      return this.checkType();
    } else {
      return (
        <div className="content-wrap bg-white">
          <Loader message="Verification is in Progress" />
        </div>
      );
    }
  }

  checkType() {
    if (this.state.currentSection === "SETPASSWORD") {
      return <ResetPassword onSetPassword={this.setPassword} />;
    } else {
      return <ResetPasswordError email={this.state.email} />;
    }
  }

  render() {
    return (
      <div className="app">
        <div className="login">
          <div className="container-fluid">
            <div className="row no-gutters h-100">
              <div className="col-lg-5">
                <BannerPanel />
              </div>
              <div className="col-lg-7">{this.renderComponent()}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Verify;
