import axios from 'axios';
import { globalConstants } from "../variables/globalVariables";

let instance = axios.create({
    baseURL: 'https://api.stripe.com/'
});

instance.interceptors.request.use(function (config) {
    let accessToken = globalConstants.STRIPE_TOKEN
    if(accessToken)
    {
      config.headers['Authorization'] = 'Bearer ' + accessToken;
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});


export default instance;
