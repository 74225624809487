import React, {Component} from "react";
import {Form, Col, Button} from "react-bootstrap";
import DataConnectionAPI from "../../../../../services/dataConnections";
import NewConnectionMeta from "./NewConnectionMeta";
import {Textbox} from "react-inputs-validation";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import {toast} from "react-toastify";

const initialState = {
    updatingContent: true,
    conectionTitle: "",
    conectionDesc: "",
    connectionType: "",

    connectionSettingsUserName: "",
    connectionSettingsPassword: "",
    connectionSettingsClientSecret: "",
    connectionSettingsServerAddress: "",
    connectionSettingsSecurityCode: "",
    connectionSettingEnabled: true,
    baseExternalConnectionUrl: "",


    hasConectionTitleError: true,
    hasConectionDescError: true,
    hasConnectionSettingsUserNameError: true,
    hasConnectionSettingsPasswordError: true,

    validate: false,
    isConnectionVerified: false,
    isConnectionTested: "TEST",
    isLoaded: false,
    enabled: true,
    disableOnEdit: false
};
const editConnection = {
    disableOnEdit: false
};
const hasNoError = {
    hasConectionTitleError: false,
    hasConectionDescError: false,
    hasConnectionSettingsApiKeyError: true,
    hasConnectionSettingsUserNameError: true,
    hasConnectionSettingsPasswordError: true,

    isConnectionVerified: true,
    isConnectionTested: "PASS"
};

class ShopifyBasic extends Component {

    constructor(props) {
        super(props);

        this.state = {initialState, editConnection};
    }

    componentDidMount() {
        this.setState(initialState);
        this.setState({connectionType: this.props.formType});
        if (this.props.editConnection) {
            this.editConnectionDefaults();
            this.setState(hasNoError);
        }
    }

    componentWillReceiveProps(nextProps) {
        let checkChange = JSON.parse(localStorage.getItem("checkChange"));
        if (!checkChange) {
            if (nextProps.editConnection) {
                this.editConnectionDefaults();
            } else {
                if (this.props.editConnection) {
                    this.resetData();
                }
            }
        }
    }

    resetData = () => {
        this.setState(initialState);
        localStorage.setItem("checkChange", false);
    };

    editConnectionDefaults = () => {
        const selectedConnection = this.props.selectedConnection;
        const connectionSettings = selectedConnection
          .connectionSettings;
        
        this.setState({
            conectionTitle: selectedConnection.name,
            conectionDesc: selectedConnection.description,
            connectionType: selectedConnection.connectionType,
            connectionSettingEnabled: selectedConnection.enabled,
            connectionSettingsUserName: connectionSettings.userName,
            connectionSettingsPassword: connectionSettings.password,
            connectionSettingsClientSecret: connectionSettings.clientSecret,
            connectionSettingsServerAddress: connectionSettings.serverAddress,
            connectionSettingsSecurityCode: connectionSettings.securityCode,
            baseExternalConnectionUrl: connectionSettings.baseExternalConnectionUrl,
        });
    };

    validateForm = e => {
        e.preventDefault();
        this.setState({validate: true});
        const {
            hasConectionTitleError,
            hasConectionDescError,
            hasConnectionSettingsUserNameError,
            hasConnectionSettingsPasswordError,
        } = this.state;

        if (
            !hasConectionTitleError &&
            !hasConectionDescError &&
            !hasConnectionSettingsUserNameError &&
            !hasConnectionSettingsPasswordError
        ) {
            this.testConnection();
        } else {
            toast.warning("Please enter valid data in fields");
        }
    };

    addConnection = async e => {
        e.preventDefault();
        this.setState({ updatingContent: true });
        if (this.state.isConnectionVerified) {
            let formData = {
                name: this.state.conectionTitle,
                description: this.state.conectionDesc,
                connectionType: this.props.formType,
                connectionSettings: {
                    userName: this.state.connectionSettingsUserName,
                    password: this.state.connectionSettingsPassword,
                    clientSecret: this.state.connectionSettingsClientSecret,
                    serverAddress: this.state.connectionSettingsServerAddress,
                    securityCode: this.state.connectionSettingsSecurityCode,
                    baseExternalConnectionUrl: this.state.baseExternalConnectionUrl,
                },
                enabled: this.state.connectionSettingEnabled
            };
            if (this.props.editConnection) {
                let formUpdate = {
                    id: this.props.selectedConnection.id,
                    name: this.state.conectionTitle,
                    description: this.state.conectionDesc,
                    connectionType: this.state.connectionType,
                    enabled: this.state.connectionSettingEnabled,
                    connectionSettings: {
                        userName: this.state.connectionSettingsUserName,
                        password: this.state.connectionSettingsPassword,
                        baseExternalConnectionUrl: this.state.baseExternalConnectionUrl,
                        clientSecret: this.state.connectionSettingsClientSecret,
                        serverAddress: this.state.connectionSettingsServerAddress,
                        securityCode: this.state.connectionSettingsSecurityCode,
                    }
                };
                try {
                    await DataConnectionAPI.updateConnection(
                        formUpdate,
                        this.props.selectedConnection.id
                    ).then(response => {
                        toast.success("Connection updated successfully");
                        this.resetData();

                        this.props.onAddingConnection(response.id);
                    });
                } catch (err) {
                    toast.error("Connection updating error");
                }
            } else {
                try {
                    await DataConnectionAPI.newConnection(formData).then(response => {
                        toast.success("Connection created successfully");
                        this.resetData();
                        this.props.onAddingConnection(response.id);
                    });
                } catch (err) {
                    toast.error("Connection creation error");
                }
            }
        } else {
            toast.warning("Please verify connection before saving changes");
        }
        this.setState({updatingContent: false});
    };

    sanitizeShopUrl = (s) => {
        return s.replace(/(^\w+:|^)\/\//, '');
    }

    testConnection = async () => {
        let formData = {
            connectionType: this.props.formType,
            connectionSettings: {
                userName: this.state.connectionSettingsUserName,
                password: this.state.connectionSettingsPassword,
                baseExternalConnectionUrl: this.sanitizeShopUrl(this.state.baseExternalConnectionUrl)
            }
        };

        this.setState({isConnectionTested: "TESTING", isLoaded: true});
        try {
            await DataConnectionAPI.testConnection(formData).then(response => {
                if (response) {
                    this.setState({
                        isConnectionVerified: true,
                        updatingContent: false,
                        isConnectionTested: "PASS"
                    });
                    toast.success("Connection verified");
                }
                if (!response) {
                    this.setState({
                        isConnectionVerified: false,
                        isConnectionTested: "FAIL"
                    });
                    toast.warning("Connection verification failed");
                }
            });
        } catch (err) {
            this.setState({
                isConnectionVerified: false,
                isConnectionTested: "FAIL"
            });
            toast.error("Connection verified error");
        }
    };

    testConnectioButtonStatus = () => {
        switch (this.state.isConnectionTested) {
            case "TEST":
                return (
                    <Button variant="secondary" onClick={this.validateForm}>
                        <span className="icon-check icon"/>
                        Test Connection
                    </Button>
                );
                break;
            case "PASS":
                return (
                    <Button variant="secondary">
                        <span className="icon-check_circle icon active"/>
                        Connection Tested
                    </Button>
                );
                break;
            case "FAIL":
                return (
                    <Button variant="secondary" onClick={this.validateForm}>
                        <span className="icon-close icon fail"/>
                        Test Connection Fail
                    </Button>
                );
                break;

            default:
                break;
        }
    };

    onCheckChange = () => {
        this.setState(
            {isConnectionTested: "TEST", isLoaded: false},
            this.props.onCheckChange()
        );
    };

    render() {
        const {
            conectionTitle,
            conectionDesc,
            connectionSettingsUserName,
            connectionSettingsPassword,
            validate,
            updatingContent,
            disableOnEdit,
            baseExternalConnectionUrl,
            isConnectionTested,
            isLoaded
        } = this.state;

        return (
            <React.Fragment>
                <NewConnectionMeta
                    formType={this.props.formType}
                    onBackPress={this.props.onBackPress}
                    editConnection={this.props.editConnection}
                />
                <Form onSubmit={this.addConnection}>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridName">
                            <Form.Label>Title</Form.Label>
                            <Textbox
                                classNameInput="form-control"
                                classNameContainer="custome-input"
                                tabIndex="1"
                                id={"conectionTitle"}
                                name="conectionTitle"
                                type="text"
                                validate={validate}
                                validationCallback={res =>
                                    this.setState({
                                        hasConectionTitleError: res,
                                        validate: false
                                    })
                                }
                                value={conectionTitle}
                                onChange={(conectionTitle, e) => {
                                    this.setState({conectionTitle});
                                    this.setState(
                                        {updatingContent: false, isConnectionVerified: false},
                                        () => this.onCheckChange()
                                    );
                                }}
                                onBlur={e => {
                                }}
                                validationOption={{
                                    name: "Title",
                                    check: true,
                                    required: true
                                }}
                            />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridDesc">
                            <Form.Label>Description</Form.Label>
                            <Textbox
                                classNameInput="form-control"
                                classNameContainer="custome-input"
                                tabIndex="2"
                                id={"conectionDesc"}
                                name="conectionDesc"
                                type="text"
                                validate={validate}
                                validationCallback={res =>
                                    this.setState({
                                        hasConectionDescError: res,
                                        validate: false
                                    })
                                }
                                value={conectionDesc}
                                onChange={(conectionDesc, e) => {
                                    this.setState({conectionDesc});
                                    this.setState(
                                        {updatingContent: false, isConnectionVerified: false},
                                        () => this.onCheckChange()
                                    );
                                }}
                                onBlur={e => {
                                }}
                                validationOption={{
                                    name: "Description",
                                    check: true,
                                    required: true
                                }}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridIP">
                            <Form.Label>Username</Form.Label>
                            <Textbox
                                classNameInput="form-control"
                                classNameContainer="custome-input"
                                tabIndex="3"
                                disabled={disableOnEdit}
                                id={"connectionSettingsUserName"}
                                name="connectionSettingsUserName"
                                autoComplete="new-password"
                                type="text"
                                validate={validate}
                                validationCallback={res =>
                                    this.setState({
                                        hasConnectionSettingsUserNameError: res,
                                        validate: false
                                    })
                                }
                                value={connectionSettingsUserName}
                                onChange={(connectionSettingsUserName, e) => {
                                    this.setState({connectionSettingsUserName});
                                    this.setState({ updatingContent: false,isConnectionVerified: false }, () =>
                                        this.onCheckChange()
                                    );
                                }}
                                onBlur={e => {}}
                                validationOption={{
                                    name: "Username",
                                    check: true,
                                    required: true
                                }}
                            />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridIP">
                            <Form.Label>Password</Form.Label>
                            <Textbox
                                classNameInput="form-control"
                                classNameContainer="custome-input"
                                tabIndex="4"
                                disabled={disableOnEdit}
                                id={"connectionSettingsPassword"}
                                name="connectionSettingsPassword"
                                autoComplete="new-password"
                                type="password"
                                validate={validate}
                                validationCallback={res =>
                                    this.setState({
                                        hasConnectionSettingsPasswordError: res,
                                        validate: false
                                    })
                                }
                                value={connectionSettingsPassword}
                                onChange={(connectionSettingsPassword, e) => {
                                    this.setState({connectionSettingsPassword});
                                }}
                                onBlur={e => {}}
                                validationOption={{
                                    name: "Password",
                                    check: true,
                                    required: true
                                }}
                            />
                        </Form.Group>
                    </Form.Row>
       
                    <Form.Row>
                        <Form.Group as={Col} controlId="formProductCatalog">
                            <Form.Label>Shop Url</Form.Label>
                            <Textbox
                                classNameInput="form-control"
                                classNameContainer="custome-input"
                                tabIndex="6"
                                id={"baseExternalConnectionUrl"}
                                name="baseExternalConnectionUrl"
                                type="text"
                                value={baseExternalConnectionUrl}
                                onChange={(baseExternalConnectionUrl, e) => {
                                    this.setState({baseExternalConnectionUrl});
                                    this.setState(
                                        {updatingContent: false, isConnectionVerified: false},
                                        () => this.onCheckChange()
                                    );
                                }}
                                onBlur={e => {
                                }}
                                validationOption={{
                                    name: "Shop Url",
                                    check: true,
                                    required: true
                                }}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row className="btn-wrap">
                        <Button variant="primary" disabled={updatingContent} type="submit">
                            Save Changes
                        </Button>

                        {isLoaded ? (
                            isConnectionTested === "TESTING" ? (
                                <Button variant="secondary">
                                    <span className="icon-distil-logo icon spin"/>
                                    Testing Connection
                                </Button>
                            ) : (
                                this.testConnectioButtonStatus()
                            )
                        ) : (
                            this.testConnectioButtonStatus()
                        )}

                        {!disableOnEdit && (
                            <Button
                                variant="secondary"
                                onClick={this.props.onCancelConnection}
                            >
                                <span className="icon-cancel icon"/>
                                Cancel
                            </Button>
                        )}
                    </Form.Row>
                </Form>
            </React.Fragment>
        );
    }
}

export default ShopifyBasic;
