import React, {Component} from "react";
import {Button, ListGroup} from "react-bootstrap";
import "./assets/style.css";
import ViewDetail from "./viewDetail";
import CustomersAPI from "../../../../../services/customers";
import _ from "lodash";

class View extends Component {
  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  state = {
    showDetail: false,
    isLoading: true,
    dropDown: false,
    dataViews: [],
    dataView: null,
    newDataView: false,
    currentView: {
      id: null,
      name: "Default View",
      showAttributes: []
    },
    defaultView: {
      id: null,
      name: "Default View",
      showAttributes: []
    },

    newDataViewContent: {
      name: "View",
      showAttributes: []
    },
    isUpdateState: false,
    viewSize: "380px"
  };

  componentDidMount() {
    this.getDataView();

    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) &&
      event.target.className !== "default-view btn btn-light") {
      if (this.state.showDetail) {
        this.handleViewUpdate();
      }

      this.setState({
        showDetail: false,
        dropDown: false
      });
    }
  }

  updateStatus() {
    this.props.onChangeView(this.state.currentView.id, this.state.isUpdateState);
  }

  getDataView = async event => {
    try {
      await CustomersAPI.getDataView().then(response => {
        if (response.length > 0) {
          this.setState(
            {
              dataViews: response,
              isLoading: false
            },
            () => this.setCurrentView()
          );
        } else {
          this.setState({
            isLoading: false
          });
        }
      });
    } catch (err) {
    }
  };

  setCurrentView = () => {
    if (this.props.dataViewId !== null) {
      let currentView = this.state.dataViews.filter(item => item.id === this.props.dataViewId);

      this.setState({currentView: currentView[0]});
    } else {
      //this.setState({ currentView: this.state.result[0] });
    }
  };

  handleHideDetail = () => {
    this.setState({newDataView: false, showDetail: false},
      () => this.handleViewUpdate()
    );
  };

  selectCurrentView = list => {
    this.setState({currentView: list, dropDown: false});
    this.props.onChangeView(list.id);
  };

  handleNewDataView = async event => {
    this.detailList();
    this.setState({newDataView: true});
    var newDataViewContent = this.state.newDataViewContent;
    var count = this.state.dataViews.length + 1;

    newDataViewContent.name = "View " + count;
    try {
      await CustomersAPI.newDataView(newDataViewContent).then(response => {
        let dataViews = this.state.dataViews;
        dataViews = [...dataViews, response];

        this.setState({
          dataViews,
          currentView: response,
          showDetail: true,
          isUpdateState: true
        });
      });
    } catch (err) {}
  };

  handleViewUpdate = async () => {
    const currentView = this.state.currentView;
    currentView.showAttributes = [];
    const dataView = this.state.dataView;
    dataView.map(section => {
      section.toShow = null;
      section.attributes.map(att => {
        att.toShow = null;
        if (!!att.isActive) {
          currentView.showAttributes.push({
            dataSourceId: section.datasourceId,
            attributeId: att.id,
            attributeDistilName: att.distilName
          });
        }
      });
    });
    this.setState({currentView}, () => this.updateView());
  };

  updateView = async () => {
    try {
      await CustomersAPI.updateDataView(
        this.state.currentView.id,
        this.state.currentView
      ).then(_ => this.updateStatus());
    } catch (err) {}
  };

  deleteView = async id => {
    try {
      await CustomersAPI.deleteView(id).then(_ => {
        const dataViews = this.state.dataViews;
        const newDataViews = dataViews.filter(item => item.id !== id);

        this.setState(
          {
            dataViews: newDataViews,
            currentView: this.state.dataViews[0],
            showDetail: false,
          },
          () => this.props.onChangeView(this.state.currentView.id)
        );
      });
    } catch (err) {}
  };

  showDetail = detailList => {
    detailList.showAttributes.forEach(attr => attr.isActive = true);

    this.setState({
      showDetail: true,
      editContent: detailList,
      isUpdateState: true
    }, () => this.matchAttribute(detailList));
  };

  matchAttribute(currentView) {
    const dataView = this.state.dataView;

    if (!dataView) {
      return;
    }

    dataView.flatMap(section => section.attributes).forEach(attr => attr.isActive = false);

    dataView.map(section => {
      let sectionAttributes = section.attributes;
      section.isActive = false;
      for (let i = 0; i < section.attributes.length; i++) {
        currentView.showAttributes.filter(sa => sectionAttributes[i].distilName === sa.attributeDistilName).forEach(_ => {
            sectionAttributes[i].isActive = true;
            section.isActive = true;
        });
      }
    });
    this.setState({dataView, currentView});
  }

  renderViewList() {
    let defaultView = this.state.defaultView;
    // if (this.state.dataViews.length > 0) {
    const sortedUsers = _.orderBy(
      this.state.dataViews,
      [view => view.name.toLowerCase()],
      ["asc"]
    );

    return (
      <ListGroup as="ul">
        <ListGroup.Item
          as="li"
          action
          className={"defaultView.id"}
          key={defaultView.id !== null ? defaultView.id : 0}
          title={defaultView.name}
        >
          <span onClick={() => this.selectCurrentView(defaultView)}>
            <i className="icon-chevron-thin-right info-arrow"/>
            {defaultView.name}
          </span>
          {defaultView.id !== null && (
            <i
              className="icon-edit edit-list"
              onClick={() => this.showDetail(defaultView)}
            />
          )}
        </ListGroup.Item>

        {sortedUsers.map(list => (
          <ListGroup.Item
            as="li"
            action
            className={
              list.id === this.state.currentView.id
                ? "active position-relative"
                : " position-relative"
            }
            key={list.id !== null ? list.id : 0}
            title={list.name}
          >
            <span onClick={() => this.selectCurrentView(list)}>
              <i className="icon-chevron-thin-right info-arrow"/>
              {list.name}
            </span>
            {list.id !== null && (
              <i
                className="icon-edit edit-list"
                onClick={() => this.showDetail(list)}
              />
            )}
          </ListGroup.Item>
        ))}
        {sortedUsers.length === 0 && (
          <ListGroup.Item as="li">
            <span className="noElipse">Click <strong>Create New</strong> to make a new view of your Customer list showing only a selection of attributes</span>
          </ListGroup.Item>
        )}
      </ListGroup>
    );
  }

  toggleDropdown = () => {
    this.detailList();

    if (this.state.dropDown) {
      this.handleViewUpdate();
    }

    this.setState({dropDown: !this.state.dropDown});
  };

  detailList() {
    if (this.props.dataSchema !== null) {
      let result = this.props.dataSchema.schemaSections
          .filter( this.filterDataSources())
          .map(section => {
        let attributes = section.attributes.map(attr => {
          let o = Object.assign({}, attr);
          o.isActive = true;
          return o;
        });
        let o = Object.assign({}, section);
        o.attributes = attributes;
        o.isActive = true;
        o.isFiltered = false;
        return o;
      });
      this.setState({dataView: result}, () => this.getAllAttribute(result));
    }
  }

  /**
   * Use only enabled and non "CUSTOMER_ENGAGEMENT" data sources.
   * @returns {function(*)}
   */
  filterDataSources() {
    return s => !s.disabled && s.dataSourceType !== "CUSTOMER_ENGAGEMENT";
  }

  getAllAttribute(result) {
    let allAttribute = [];
    if (result.length > 0) {
      result.map(item => {
        item.attributes.map(att => {
          allAttribute.push({
            attributeId: att.id,
            dataSourceId: item.datasourceId,
            attributeDistilName: att.distilName
          });
        });
      });
    }

    const newDataViewContent = this.state.newDataViewContent;
    newDataViewContent.showAttributes = allAttribute;
    this.setState({newDataViewContent});
  }

  handleToggleSection = (group) => {
    const dataView = this.state.dataView;
    let sectionIndex = dataView.findIndex(item => item.datasourceId === group.datasourceId);

    dataView[sectionIndex].isActive = !dataView[sectionIndex].isActive;

    dataView[sectionIndex].attributes.filter(it => it.toShow !== false)
      .forEach(attr => (attr.isActive = dataView[sectionIndex].isActive));

    this.setState({dataView});
  };

  handleToggleAttribute = (group, attribute) => {
    const dataView = this.state.dataView;
    let sectionIndex = dataView.findIndex(item => item.datasourceId === group.datasourceId);
    let attributeIndex = dataView[sectionIndex].attributes.findIndex(attr => attr.id === attribute.id);

    dataView[sectionIndex].attributes[attributeIndex].isActive = !dataView[sectionIndex].attributes[attributeIndex].isActive;

    if (dataView[sectionIndex].attributes.some(item => item.isActive === true)) {
      dataView[sectionIndex].isActive = true;
    }
    this.setState({dataView});
  };

  handleViewName = e => {
    let currentView = this.state.currentView;
    currentView.name = e.target.value;
    this.setState({currentView});
  };

  handleSearchAll = (searchValue, dataViewSaved) => {
    let filteredResult = [];
    let dataView = dataViewSaved;

    if (searchValue !== "") {
      for (let i = 0; i < dataView.length; i++) {

        if (
          dataView[i].sectionName
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          dataView[i].toShow = true;
          dataView[i].isActive = true;
          dataView[i].attributes.forEach(attr => attr.toShow = null)
          filteredResult.push(dataView[i]);
        } else {
          let dataViewItem = {...dataView[i]};
          dataViewItem.attributes = [];
          dataViewItem.toShow = false;

          let attributes = [];
          for (let j = 0; j < dataView[i].attributes.length; j++) {
            const attr = {...dataView[i].attributes[j]};

            if (attr.displayName.toLowerCase().includes(searchValue.toLowerCase())) {
              dataViewItem.toShow = true;
              dataViewItem.isActive = true;
              attr.toShow = true;
              dataViewItem.attributes[j] = attr;
              dataViewItem.attributes[j].toShow = true;
            } else {
              attr.toShow = false;
            }
            attributes.push(attr)
          }
          dataViewItem.attributes = attributes;
          filteredResult.push(dataViewItem);
        }
      }
    } else {
      dataView.forEach(dv => {
        dv.toShow = null;
        dv.attributes.forEach(attr => attr.toShow = null);
      });
      filteredResult = dataView;
    }
    this.setState({dataView: filteredResult});
  };

  render() {
    const {
      // editContent,
      dropDown,
      // handleDataView,
      isLoading,
      viewSize
    } = this.state;
    if (!isLoading) {
      return (
        <div className="default-wrap d-none d-md-block">
          <Button
            variant="light"
            className="default-view"
            onClick={() => this.toggleDropdown()}
            title={this.state.currentView.name}
          >
            <span>{this.state.currentView.name}</span>
          </Button>
          {dropDown && (
            <section
              ref={this.setWrapperRef}
              className="m-auto list-view-main d-flex flex-row justify-content-center"
              style={{width: viewSize}}
            >
              <section className="view-list position-relative">
                <dl className="d-flex flex-row justify-content-between align-items-center  mb-0 p-3">
                  <dt>All Views</dt>
                  <dd>
                    <button
                      className="btn-create"
                      onClick={() => this.handleNewDataView()}
                    >
                      Create New
                    </button>
                  </dd>
                </dl>
                {this.renderViewList()}

                {
                  (this.state.showDetail && (
                    <ViewDetail
                      viewSize={size => this.setState({viewSize: size})}
                      onhideDetail={this.handleHideDetail}
                      currentView={this.state.currentView}
                      onDeleteView={this.deleteView}
                      newdata={this.state.newDataView}
                      showDetail={this.state.showDetail}
                      dataView={this.state.dataView.filter( this.filterDataSources())}
                      onSearch={this.handleSearchAll}
                      ontoggleSection={this.handleToggleSection}
                      ontoggleAttribute={this.handleToggleAttribute}
                      newDataViewContent={this.state.newDataViewContent}
                      onNameEdit={this.handleViewName}
                    />
                  ))}
              </section>
            </section>
          )}{" "}
        </div>
      );
    } else {
      return " ";
    }
  }
}

export default View;
