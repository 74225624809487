import React, { Component } from "react";
import CommonMessage from "../../../../components/CommonMessage/commonMessage";
import AuthenticationService from "../../../../services/authentication";

import { Link } from "react-router-dom";

import { Textbox } from "react-inputs-validation";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import { ListGroup } from "react-bootstrap";

import { toast } from "react-toastify";
import _ from "lodash";
// import { globalConstants } from '../../../variables/globalVariables';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.myEmail = React.createRef();
    this.myPassword = React.createRef();
  }
  state = {
    email: "",
    password: "",
    isPasswordField: "password",
    errors: {},
    passwordValidation: [
      {
        type: "8 characters minimum",
        isValid: false,
        isValidType: /[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~0-9a-zA-Z]{8,}/
      },
      { type: "One number", isValid: false, isValidType: ".*[0-9].*" },
      {
        type: "One uppercase character",
        isValid: false,
        isValidType: "(?=.*[A-Z])"
      },
      {
        type: "One lowercase character",
        isValid: false,
        isValidType: "(?=.*[a-z])"
      }
    ],
    validateEmail: true,
    allValid: false
  };

  doLogin = async event => {
    event.preventDefault();
    const email = this.state.email;
    const password = this.state.password;
    const errInMyEmail = this.myEmail.current.state.err;
    const errInMymyPassword = this.myPassword.current.state.err;
    if (!errInMyEmail && !errInMymyPassword) {
      try {
        await AuthenticationService.login(email, password).then(response => {
          window.location = "/";
        });
      } catch (ex) {
        toast.error("Invalid Username and/or Password provided");
      }
    }
  };

  componentDidMount() {

  }
  togglePasswordField = () => {
    this.setState({
      isPasswordField:
        this.state.isPasswordField === "password" ? "text" : "password"
    });
  };

  passwordFieldValidation = res => {
    var passwordValidationState = [...this.state.passwordValidation];
    for (let i = 0; i < this.state.passwordValidation.length; i++) {
      var pattern = new RegExp(this.state.passwordValidation[i].isValidType);
      // console.log(i, " - ", pattern.test(res), pattern, res);
      if (pattern.test(res)) {
        this.setState();
        passwordValidationState[i].isValid = false;
        this.setState({ passwordValidation: passwordValidationState });
      } else {
        passwordValidationState[i].isValid = true;
        this.setState({ passwordValidation: passwordValidationState });
      }
    }
    this.isValidPassword();
  };

  isValidPassword = () => {
    const errInMymyPassword =
      _.filter(this.state.passwordValidation, { isValid: true }).length > 0
        ? true
        : false;

    if (!errInMymyPassword) {
      this.setState({ allValid: true });
    } else {
      this.setState({ allValid: false });
    }
  };

  setPassword = event => {
    event.preventDefault();
    if (this.state.allValid) {
      this.setState({ allValid: false });
      this.props.onSetPassword(this.state.password);
    }
  };

  render() {
    return (
      <div className="content-wrap bg-white">
        <div className="content-inner-wrap col-sm-9">
          <div className="top-wrap d-flex flex-column">
            <h1 className="font-weight-heavy">Reset Your Password</h1>
          </div>

          {/* <h1 className="font-weight-heavy">Sign In</h1> */}

          <div className="form-wrap">
            <form onSubmit={this.setPassword}>
              <div className="form-group new-form-group">
                <label htmlFor="password">
                  Password{" "}
                  <span onClick={this.togglePasswordField}>
                    {" "}
                    <i
                      className={`${
                        this.state.isPasswordField === "password"
                          ? "icon-visibility"
                          : "icon-visibility_off"
                      }`}
                    ></i>{" "}
                    Show{" "}
                  </span>
                </label>
                <Textbox
                  ref={this.myPassword}
                  classNameInput="form-control"
                  classNameContainer="custome-input"
                  tabIndex="1" // Optional.[String or Number].Default: none.
                  id={"password"} // Optional.[String].Default: "".  Input ID.
                  name="password" // Optional.[String].Default: "". Input name.
                  type={this.state.isPasswordField} // Optional.[String].Default: "text". Input type [text, password, number].
                  value={this.state.password} // Optional.[String].Default: "".
                  placeholder="Your password here" // Optional.[String].Default: "".
                  onChange={(password, e) => {
                    this.setState({ password }, () =>
                      this.passwordFieldValidation(password)
                    );
                    console.log(e);
                  }} // Required.[Func].Default: () => {}. Will return the value.
                  onBlur={e => {
                    console.log(e);
                  }}
                  // Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                  validationOption={{
                    name: "Password", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
                    required: false // Optional.[Bool].Default: true. To determin if it is a required field.
                  }}
                />
                <ListGroup as="ul" className="p-strength ">
                  {this.state.passwordValidation.map(field => (
                    <ListGroup.Item
                      as="li"
                      className={!field.isValid ? " " : "isError"}
                    >
                      {field.type}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </div>

              <div className="btn-wrap">
                <button
                  type="submit"
                  disabled={!this.state.allValid}
                  className="btn btn-primary green-btn"
                >
                  Submit
                </button>

                <Link className="return" to="/">
                Return to Log in
                </Link>
              </div>
            </form>
          </div>

          <div className="bottom-wrap">
            <div className="right-text light-black-text-40">
              <p>
                ©2018–2019 All Rights Reserved. Distil.ai® is a registered
                trademark of Big Business Intelligence Limited.{" "}
                <a href="#;">Cookie Preferences</a>, <a href="#;">Privacy</a>,
                and <a href="#;">Terms</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
