import React, { Component } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import equal from "fast-deep-equal";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    maintainAspectRatio: false,
    scales: {
        x: {
            grid: {
                display: false,
            },
        },
        y: {
            grid: {
                drawBorder: false,
                display: false,
            },
        },
    },
    indexAxis: "x",
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    responsive: true,
    plugins: {

        title: {
            display: false,
        },
        legend: {
            labels: {
                boxWidth: 24,
                boxHeight: 1,
            },
            display: false,
            position: "bottom",
            align: "start"
        },
    },
};

class LineChart extends Component {
    /* eslint-disable */
    constructor(props) {
        super(props);

        this.state = {
            labels: [],
            dataActual: [],
            dataForecast: [],
            dataLowerBound: [],
            dataHigherBound: [],
            data: {
                labels: '',
                datasets: [],
            },
        };
    }

    componentDidMount() {
        this.mappingValue();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!equal(this.props.cardData, prevProps.cardData)) {
            this.mappingValue();
        }
    }

    mappingValue() {
        const { cardData } = this.props;

        if (cardData) {
            const labels = cardData?.data?.yearData?.chartData.map((value, index) => {
                return value.date;
            });
            let dataActual = [];
            let dataForecast = [];
            let dataLowerBound = [];
            let dataHigherBound = [];

            dataActual = cardData?.data?.yearData?.chartData.map((value, index) => {
                return value?.actual ? value?.actual : null;
            });

            cardData?.data?.yearData?.chartData.forEach((value) => {
                dataForecast.push(value?.actual ? null : value?.forecast);
            });
            dataForecast?.shift();

            dataActual &&
            dataForecast?.splice(
                dataActual?.indexOf(null) - 1,
                0,
                dataActual[dataActual?.indexOf(null) - 1]
            );

            dataLowerBound = cardData?.data?.yearData?.chartData.map(
                (value, index) => {
                    return value?.actual ? null : value?.lowerBound;
                }
            );
            dataLowerBound?.shift();
            dataLowerBound?.splice(
                dataActual?.indexOf(null) - 1,
                0,
                dataActual[dataActual?.indexOf(null) - 1]
            );

            dataHigherBound = cardData?.data?.yearData?.chartData.map(
                (value, index) => {
                    return value?.actual ? null : value?.higherBound;
                }
            );
            dataHigherBound?.shift();
            dataHigherBound?.splice(
                dataActual.indexOf(null) - 1,
                0,
                dataActual[dataActual.indexOf(null) - 1]
            );

            this.setState({
                dataActual,
                dataForecast,
                dataLowerBound,
                dataHigherBound,
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: "Actual",
                            data: dataActual ? dataActual : null,
                            fill: true,
                            borderColor: "#0A83C4",
                            tension: 0.1,
                            pointBorderWidth: 0,
                            pointRadius: 0,
                            borderWidth: 3,
                        },
                        {
                            label: "Forecast",
                            data: dataForecast ? dataForecast : null,
                            fill: true,
                            borderColor: "#3DCBFC",
                            tension: 0.1,
                            pointBorderWidth: 0,
                            pointRadius: 0,
                            borderWidth: 3,
                        },
                        {
                            label: "Lower Bound",
                            data: dataLowerBound ? dataLowerBound : null,
                            fill: false,
                            borderColor: "#3DCBFC",
                            tension: 0.1,
                            pointBorderWidth: 0,
                            pointRadius: 0,
                            borderWidth: 1,
                        },
                        {
                            label: "Higher Bound",
                            data: dataHigherBound ? dataHigherBound : null,
                            fill: false,
                            borderColor: "#3DCBFC",
                            tension: 0.1,
                            pointBorderWidth: 0,
                            pointRadius: 0,
                            borderWidth: 1,
                        },
                    ],
                }
            })
        }
    }

    render() {
        return (
            <>
                <Line options={options} data={this.state.data}/>
                {/* <img src="http://localhost:3000/chart" /> */}
            </>
        );

    }
};

export default LineChart;
