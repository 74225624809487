import React, { Component } from "react";
import { Form, Col, Button } from "react-bootstrap";
import DataConnectionAPI from "../../../../../services/dataConnections";
import NewConnectionMeta from "./NewConnectionMeta";
import { Textbox } from "react-inputs-validation";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import { toast } from "react-toastify";

const initialState = {
    updatingContent: true,
    conectionTitle: "",
    conectionDesc: "",
    connectionType: "",
    connectionSettingsApiKey: "",

    connectionSettingsUserName: "",
    connectionSettingsPassword: "",
    connectionSettingsClientSecret: "",
    connectionSettingsServerAddress: "",
    connectionSettingsSecurityCode: "",
    connectionSettingsBaseExternalConnectionUrl: "",

    connectionSettingEnabled: true,

    hasConectionTitleError: true,
    hasConectionDescError: true,
    hasConnectionSettingsApiKeyError: true,
    hasConnectionSettingsUserNameError: true,

    validate: false,
    isConnectionVerified: false,
    isConnectionTested: "TEST",
    isLoaded: false,
    enabled: true,
    disableOnEdit: false,
};
const editConnection = {
    disableOnEdit: false
};
const hasNoError = {
    hasConectionTitleError: false,
    hasConectionDescError: false,
    hasConnectionSettingsApiKeyError: false,

    isConnectionVerified: true,
    isConnectionTested: "PASS"
};

class Braze extends Component {
    constructor(props) {
        super(props);

        this.state = { initialState,
            editConnection,
            isEnabledDeleteFromDest: this.props.selectedConnection?.connectionSettings?.enableDeleteFromDestination};
    }

    resetData = () => {
        this.setState(initialState);
        localStorage.setItem("checkChange", false);
    };

    componentDidMount() {
        this.setState(initialState);
        this.setState({ connectionType: this.props.formType });
        if (this.props.editConnection) {
            this.editConnectionDefaults();
            // this.setState({ updatingContent: true });
            this.setState(hasNoError);
        }
    }

    componentWillReceiveProps(nextProps) {
        let checkChange = JSON.parse(localStorage.getItem("checkChange"));
        if (!checkChange) {
            if (nextProps.editConnection) {
                this.editConnectionDefaults();
            } else {
                if (this.props.editConnection) {
                    this.resetData();
                }
            }
        }
    }

    editConnectionDefaults = () => {
        this.setState({
            conectionTitle: this.props.selectedConnection.name,
            conectionDesc: this.props.selectedConnection.description,
            connectionType: this.props.selectedConnection.connectionType,
            connectionSettingEnabled: this.props.selectedConnection.enabled,
            connectionSettingsApiKey: this.props.selectedConnection.connectionSettings
                .apiKey,
            connectionSettingsUserName: this.props.selectedConnection
                .connectionSettings.userName,

            connectionSettingsPassword: this.props.selectedConnection
                .connectionSettings.password,
            connectionSettingsClientSecret: this.props.selectedConnection
                .connectionSettings.clientSecret,
            connectionSettingsServerAddress: this.props.selectedConnection
                .connectionSettings.serverAddress,
            connectionSettingsSecurityCode: this.props.selectedConnection
                .connectionSettings.securityCode,
            connectionSettingsBaseExternalConnectionUrl: this.props.selectedConnection
                .connectionSettings.baseExternalConnectionUrl
        });
    };

    validateForm = e => {
        e.preventDefault();
        this.setState({ validate: true });
        const {
            hasConectionTitleError,
            hasConectionDescError,
            hasConnectionSettingsApiKeyError
        } = this.state;

        if (
            !hasConectionTitleError &&
            !hasConectionDescError &&
            !hasConnectionSettingsApiKeyError
        ) {
            this.testConnection();
        } else {
            toast.warning("Please enter valid data in fields");
        }
    };

    buildUrl = urlBraze => {
        if(urlBraze.includes(".com")) {
            return urlBraze.replaceAll("dashboard", "rest.iad");
        } else if (urlBraze.includes(".eu")) {
            return urlBraze.replaceAll("dashboard", "rest.fra");
        } else {
            return null;
        }
    }

    addConnection = async e => {
        e.preventDefault();
        this.setState({ updatingContent: true });
        if (this.state.isConnectionVerified) {
            let formData = {
                name: this.state.conectionTitle,
                description: this.state.conectionDesc,
                connectionType: this.props.formType,
                connectionSettings: {
                    apiKey: this.state.connectionSettingsApiKey,
                    userName: this.state.connectionSettingsUserName,
                    password: this.state.connectionSettingsPassword,
                    clientSecret: this.state.connectionSettingsClientSecret,
                    serverAddress: this.state.connectionSettingsServerAddress,
                    securityCode: this.state.connectionSettingsSecurityCode,
                    baseExternalConnectionUrl: this.state.connectionSettingsBaseExternalConnectionUrl,
                    enableDeleteFromDestination: this.state.isEnabledDeleteFromDest
                },
                enabled: this.state.connectionSettingEnabled
            };
            if (this.props.editConnection) {
                let formUpdate = {
                    id: this.props.selectedConnection.id,
                    name: this.state.conectionTitle,
                    description: this.state.conectionDesc,
                    connectionType: this.state.connectionType,
                    enabled: this.state.connectionSettingEnabled,
                    connectionSettings: {
                        apiKey: this.state.connectionSettingsApiKey,
                        userName: this.state.connectionSettingsUserName,
                        password: this.state.connectionSettingsPassword,
                        clientSecret: this.state.connectionSettingsClientSecret,
                        serverAddress: this.state.connectionSettingsServerAddress,
                        securityCode: this.state.connectionSettingsSecurityCode,
                        baseExternalConnectionUrl: this.state.connectionSettingsBaseExternalConnectionUrl,
                        enableDeleteFromDestination: this.state.isEnabledDeleteFromDest
                    }
                };
                try {
                    await DataConnectionAPI.updateConnection(
                        formUpdate,
                        this.props.selectedConnection.id
                    ).then(response => {
                        toast.success("Connection updated successfully");
                        this.resetData();

                        this.props.onAddingConnection(response.id);
                    });
                } catch (err) {
                    toast.error("Connection updating error");
                }
            } else {
                try {
                    await DataConnectionAPI.newConnection(formData).then(response => {
                        toast.success("Connection created successfully");
                        this.resetData();
                        this.props.onAddingConnection(response.id);
                    });
                } catch (err) {
                    toast.error("Connection creation error");
                }
            }
        } else {
            toast.warning("Please verify connection before saving changes");
        }
        this.setState({ updatingContent: false });
    };

    changeAbleDeleteUsers = () => {
        this.props.selectedConnection.connectionSettings.enableDeleteFromDestination = !this.props.selectedConnection.connectionSettings.enableDeleteFromDestination;
        this.setState({
            isEnabledDeleteFromDest: this.props.selectedConnection.connectionSettings.enableDeleteFromDestination,
            updatingContent: false
        })

    }

    testConnection = async () => {
        let url = this.buildUrl(this.state.connectionSettingsBaseExternalConnectionUrl);
        let formData = {
            connectionType: this.props.formType,
            connectionSettings: {
                apiKey: this.state.connectionSettingsApiKey,
                userName: this.state.connectionSettingsUserName,
                serverAddress: url
            }
        };

        this.setState({ isConnectionTested: "TESTING", isLoaded: true });
        try {
            await DataConnectionAPI.testConnection(formData).then(response => {
                if (response) {
                    this.setState({
                        connectionSettingsServerAddress: url,
                        isConnectionVerified: true,
                        updatingContent: false,
                        isConnectionTested: "PASS"
                    });
                    toast.success("Connection verified");
                }
                if (!response) {
                    this.setState({
                        isConnectionVerified: false,
                        isConnectionTested: "FAIL"
                    });
                    toast.warning("Connection verification failed");
                }
            });
        } catch (err) {
            this.setState({
                isConnectionVerified: false,
                isConnectionTested: "FAIL"
            });
            toast.error("Connection verified error");
        }
    };
    testConnectioButtonStatus = () => {
        switch (this.state.isConnectionTested) {
            case "TEST":
                return (
                    <Button variant="secondary" onClick={this.validateForm}>
                        <span className="icon-check icon" />
                        Test Connection
                    </Button>
                );
                break;
            case "PASS":
                return (
                    <Button variant="secondary">
                        <span className="icon-check_circle icon active" />
                        Connection Tested
                    </Button>
                );
                break;
            case "FAIL":
                return (
                    <Button variant="secondary" onClick={this.validateForm}>
                        <span className="icon-close icon fail" />
                        Test Connection Fail
                    </Button>
                );
                break;

            default:
                break;
        }
    };

    onCheckChange = () => {
        this.setState(
            { isConnectionTested: "TEST", isLoaded: false },
            this.props.onCheckChange()
        );
    };

    render() {
        const {
            conectionTitle,
            conectionDesc,
            connectionSettingsApiKey,

            connectionSettingsUserName,
            connectionSettingsBaseExternalConnectionUrl,

            validate,
            updatingContent,
            disableOnEdit,
            isConnectionTested,
            isLoaded
        } = this.state;

        return (
            <React.Fragment>
                <NewConnectionMeta
                    formType={this.props.formType}
                    onBackPress={this.props.onBackPress}
                    editConnection={this.props.editConnection}
                />
                <Form onSubmit={this.addConnection}>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridName">
                            <Form.Label>Title</Form.Label>
                            <Textbox
                                classNameInput="form-control"
                                classNameContainer="custome-input"
                                tabIndex="1"
                                id={"conectionTitle"}
                                name="conectionTitle"
                                type="text"
                                validate={validate}
                                validationCallback={res =>
                                    this.setState({
                                        hasConectionTitleError: res,
                                        validate: false
                                    })
                                }
                                value={conectionTitle}
                                onChange={(conectionTitle, e) => {
                                    this.setState({ conectionTitle });
                                    this.setState(
                                        { updatingContent: false, isConnectionVerified: false },
                                        () => this.onCheckChange()
                                    );
                                }}
                                onBlur={e => {}}
                                validationOption={{
                                    name: "Title",
                                    check: true,
                                    required: true
                                }}
                            />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridDesc">
                            <Form.Label>Description</Form.Label>
                            <Textbox
                                classNameInput="form-control"
                                classNameContainer="custome-input"
                                tabIndex="2"
                                id={"conectionDesc"}
                                name="conectionDesc"
                                type="text"
                                validate={validate}
                                validationCallback={res =>
                                    this.setState({
                                        hasConectionDescError: res,
                                        validate: false
                                    })
                                }
                                value={conectionDesc}
                                onChange={(conectionDesc, e) => {
                                    this.setState({ conectionDesc });
                                    this.setState(
                                        { updatingContent: false, isConnectionVerified: false },
                                        () => this.onCheckChange()
                                    );
                                }}
                                onBlur={e => {}}
                                validationOption={{
                                    name: "Description",
                                    check: true,
                                    required: true
                                }}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridIP">
                            <Form.Label>API Key</Form.Label>
                            <Textbox
                                classNameInput="form-control"
                                classNameContainer="custome-input"
                                tabIndex="3"
                                disabled={disableOnEdit}
                                id={"connectionSettingsApiKey"}
                                name="connectionSettingsApiKey"
                                type="text"
                                validate={validate}
                                validationCallback={res =>
                                    this.setState({
                                        hasConnectionSettingsApiKeyError: res,
                                        validate: false
                                    })
                                }
                                value={connectionSettingsApiKey}
                                onChange={(connectionSettingsApiKey, e) => {
                                    this.setState(
                                        {
                                            connectionSettingsApiKey,
                                            updatingContent: false,
                                            isConnectionVerified: false
                                        },
                                        () => this.onCheckChange()
                                    );
                                }}
                                onBlur={e => {}}
                                validationOption={{
                                    name: "API Key",
                                    check: true,
                                    required: true
                                }}
                            />
                        </Form.Group>
                    </Form.Row>

                    {/*<Form.Row>*/}
                    {/*    <Form.Group as={Col} controlId="formGridIP">*/}
                    {/*        <Form.Label>Username</Form.Label>*/}
                    {/*        <Textbox*/}
                    {/*            classNameInput="form-control"*/}
                    {/*            classNameContainer="custome-input"*/}
                    {/*            tabIndex="4"*/}
                    {/*            disabled={disableOnEdit}*/}
                    {/*            id={"connectionSettingsUserName"}*/}
                    {/*            name="connectionSettingsUserName"*/}
                    {/*            type="text"*/}
                    {/*            autocomplete="false"*/}
                    {/*            value={connectionSettingsUserName}*/}
                    {/*            onChange={(connectionSettingsUserName, e) => {*/}
                    {/*                this.setState(*/}
                    {/*                    { connectionSettingsUserName, updatingContent: false },*/}
                    {/*                    () => this.props.onCheckChange()*/}
                    {/*                );*/}
                    {/*            }}*/}
                    {/*            onBlur={e => {}}*/}
                    {/*            validationOption={{*/}
                    {/*                name: "Username",*/}
                    {/*                check: false,*/}
                    {/*                required: false*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*    </Form.Group>*/}
                    {/*</Form.Row>*/}
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridIP">
                            <Form.Label><a href="https://www.braze.com/docs/api/basics/#endpoints" target="_blank">Braze URL</a> (i.e. https://rest.fra-01.braze.eu)</Form.Label>
                            <Textbox
                                classNameInput="form-control"
                                classNameContainer="custome-input"
                                tabIndex="5"
                                disabled={disableOnEdit}
                                id={"connectionSettingsBaseExternalConnectionUrl"}
                                name="connectionSettingsBaseExternalConnectionUrl"
                                type="text"
                                value={ connectionSettingsBaseExternalConnectionUrl}
                                onChange={( connectionSettingsBaseExternalConnectionUrl, e) => {
                                    this.setState(
                                        { connectionSettingsBaseExternalConnectionUrl, updatingContent: false },
                                        () => this.props.onCheckChange()
                                    );
                                }}
                                onBlur={e => {}}
                                validationOption={{
                                    name: "Braze URL",
                                    check: false,
                                    required: false
                                }}
                            />
                        </Form.Group>

                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Button
                                variant="secondary"
                                onClick={this.changeAbleDeleteUsers}
                            >
                                {this.state.isEnabledDeleteFromDest ? (
                                    <React.Fragment>
                                        <span className="icon-toggle-on active" />
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <span className="icon-toggle-off" />
                                    </React.Fragment>
                                )}
                                <strong className="ml-1">Delete users from Braze</strong>
                            </Button>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row className="btn-wrap">
                        <Button
                            variant="primary"
                            disabled={updatingContent}
                            type="submit"
                            // disabled={updatingContent}
                        >
                            Save Changes
                        </Button>

                        {isLoaded ? (
                            isConnectionTested === "TESTING" ? (
                                <Button variant="secondary">
                                    <span className="icon-distil-logo icon spin" />
                                    Testing Connection
                                </Button>
                            ) : (
                                this.testConnectioButtonStatus()
                            )
                        ) : (
                            this.testConnectioButtonStatus()
                        )}

                        {!disableOnEdit && (
                            <Button
                                variant="secondary"
                                onClick={this.props.onCancelConnection}
                            >
                                <span className="icon-cancel icon" />
                                Cancel
                            </Button>
                        )}

                        {/* {!disableOnEdit && (
              <Button variant="secondary" onClick={this.resetData}>
                Cancel
              </Button>
            )} */}
                    </Form.Row>
                </Form>
            </React.Fragment>
        );
    }
}

export default Braze;
