import React, {Component} from "react";
import {
  divide,
  formatNumber,
  formatToMilli, getCountChangePercent, getPluralEnding,
  getSegmentAverageType,
  getSegmentChangeIcon,
  getSegmentChangeStyle,
  getSegmentGrowthType,
  isSegmentProcessing,
  NOT_PROCESSED,
  roundTo
} from "../segmentUtils";
import EnrichmentAPI from "../../../../../services/enrichments";
import SegmentsAPI from "../../../../../services/customers.js";
import LineChart from "../../../Newsfeed/components/Cardgraphs/lineChart";
import SegmentPieChart from "./SegmentPieChart";
import {monthNames} from "../../../../../variables/globalValues.json";
import noDataImage from "../../assets/img/analytics/no-chart-data.jpg";
import Loader from "../../../../../components/Loader/loader";

class AnalyticsSummary extends Component {
  enrichmentEnabledStatuses = ['ENABLED', 'ENABLED_WAITING_FOR_FIRST_RUN'];

  state = {
    spendBracketEnrichment: null,
    engagementEnrichment: null,
    growthChartData: [{data: []}],
    minimalPieRenderPercent: 5
  }

  componentWillReceiveProps(props) {
    this.rerenderHistory(props);
  }

  constructor(props) {
    super(props);
    this.rerenderHistory(props);
  }

  rerenderHistory(props) {
    if (props.segment.id) {
      this.getSegmentHistory(props.segment.id).then(data => this.drawGrowthChart(data))
    }
  }

  componentDidMount() {
    this.getEnrichment();
  }

  getEnrichment() {
    EnrichmentAPI.getEnrichment("SPEND_BRACKET").then(e => this.setState({spendBracketEnrichment: e}))
  }

  getSegmentHistory(id) {
    return SegmentsAPI.segmentHistory(id).then(e => e)
  }

  getSegmentValueChartData(segment, allCustomersSegment) {
    let all = allCustomersSegment?.currentStats?.spendInLastYearInSegment;
    let part = segment?.currentStats?.spendInLastYearInSegment;
    let renderPart = Math.max(segment?.currentStats?.spendInLastYearInSegment || 0, all / 100 * this.state.minimalPieRenderPercent)

    return [
      {
        id: "segmentValue",
        label: "Segment Value",
        name: "Segment Value",
        sectionName: "Segment\nValue",
        subTitle: `${divide(part, all)}% of last Year`,
        realValue: part,
        value: renderPart
      },
      {
        id: "allSegmentValue",
        name: "Other",
        value: all - renderPart
      }
    ]
  }

  getSegmentSizeChartData(segment, allCustomersSegment, customerPercent) {
    let all = allCustomersSegment?.cachedCustomerCount || 0;
    let part = segment?.cachedCustomerCount || 0;
    let renderPart = Math.max(segment?.cachedCustomerCount || 0, all / 100 * this.state.minimalPieRenderPercent)

    return [
      {
        id: "segmentCount",
        label: "Segment Size",
        name: "Segment Size",
        sectionName: "Segment\nSize",
        subTitle: `${customerPercent}% of All Customers`,
        realValue: part,
        value: renderPart,
      },
      {
        id: "allSegmentCount",
        name: "Other",
        value: all - renderPart
      }
    ];
  }

  getSpendBracketStatisticsContent(spendBracketEnrichment, segment) {
    if (spendBracketEnrichment?.enrichmentProcessedStatus === 'DISABLED' || spendBracketEnrichment?.enrichmentProcessedStatus === 'ENABLED_WAITING_FOR_FIRST_RUN') {
      return <></>;
      // }
      // else if (spendBracketEnrichment?.enrichmentProcessedStatus === 'ENABLED_WAITING_FOR_FIRST_RUN') {
      //   return <p>Spend data will appear here once the Spend bracket enrichment has been processed for the first
      //     time. <a href="#">more...</a></p>;
    } else if (isSegmentProcessing(segment)) {
      return <Loader adaptive={true} message="Spend Bracket data is processing" size={25}
                     style={{
                       "margin-left": "0px",
                       "margin-right": "16px",
                       "justify-content": "left",
                       "align-items": "center",
                       "flex-direction": "row"
                     }}
      />;
    } else if (!!spendBracketEnrichment) {
      return this.printSpendBracketStats();
    } else {
      return <></>
    }
  }

  printSpendBracketStats() {
    let {allCustomersSegment, segment} = this.props;
    let allSegmentStats = allCustomersSegment?.currentStats;
    let segmentStats = segment?.currentStats;

    let averageOfAll = divide(allSegmentStats?.spendInLastYearInSegment, allCustomersSegment?.cachedCustomerCount, 0, 2, false);
    let averageOfCurrent = divide(segmentStats?.spendInLastYearInSegment, segment?.cachedCustomerCount, 0, 2, false);

    return <>
      <div className="total-spend-summary">
        <h1>£{formatToMilli(segmentStats?.totalSpendInSegment)}</h1>
        <p>Spent by Customers in this Segment.</p>
        <p><strong>{Math.round((segmentStats?.percentageOfTotal || 0) * 100)}%</strong> of total</p>
        {this.printSpendInfo(allSegmentStats?.spendInLastYearInSegment, segmentStats?.spendInLastYearInSegment, 'last year')}
        {this.printSpendInfo(allSegmentStats?.spendInLast180DaysInSegment, segmentStats?.spendInLast180DaysInSegment, 'last 6-month')}
        {this.printSpendInfo(allSegmentStats?.spendInLast60DaysInSegment, segmentStats?.spendInLast60DaysInSegment, 'last 2-month')}
        {this.printSpendInfo(allSegmentStats?.spendInLast30DaysInSegment, segmentStats?.spendInLast30DaysInSegment, 'last 1-month')}
      </div>
      <div className="average-spend-summary">
        <h5>£{averageOfCurrent}</h5>
        <p>Average Customer Value</p>
        {allCustomersSegment.id !== segment.id &&
        <>
          {getSegmentAverageType(averageOfAll, averageOfCurrent)}
          <h6>£{averageOfAll}</h6>
          <p>the average Customer over the past year.</p>
        </>
        }
      </div>
    </>
  }

  printSpendInfo(all, part, period) {
    let percent = divide(part, all);
    return percent === NOT_PROCESSED ? <></> :
      <p><strong>£{formatNumber(roundTo(part, 2))}</strong> in the {period}, <strong>{percent}%</strong> of total
      </p>
  }

  toUTCString(date) {
    return `${String(date.getUTCDate()).padStart(2, '0')}\n${monthNames[date.getUTCMonth().toString()].shortName}\n${date.getUTCFullYear()}`
  }

  toUTCTooltipString(date) {
    return `${monthNames[date.getUTCMonth().toString()].longName} ${String(date.getUTCDate()).padStart(2, '0')}, ${date.getUTCFullYear()}`
  }

  drawGrowthChart(history, segmentId) {
    let data = {
      id: 'id',
      color: '#9FCE1D',
      data: [],
      minY: Number.MAX_SAFE_INTEGER
    };

    for (let i = 0; i < history.length; i++) {
      let h = history[i];
      let hPrev = history[i - 1] || history[i];
      let x = this.toUTCString(new Date(h.dateCreated))
      let diff = h.totalMembership - hPrev.totalMembership;
      let y = divide(diff, hPrev.totalMembership, 0);

      if (h.totalMembership < data.minY) {
        data.minY = h.totalMembership;
      }

      data.data.push({
        index: h.id,
        realTimestamp: new Date(h.dateCreated).toUTCString(),
        x: x,
        y: h.totalMembership,
        status: true,
        color: '#9FCE1D',
        datetime: h.dateCreated,
        tooltip: <div className="custom-tooltip">
          <strong>{this.toUTCTooltipString(new Date(h.dateCreated))}</strong>
          <strong>{h.totalMembership || 0} Total</strong>
          <strong>{diff > 0 ? '+' : ''}{formatNumber(diff)}</strong>
          {this.printPercents(y)}
        </div>
      })
    }

    this.setState({growthChartData: [data], segmentId: segmentId})
  }

  printPercents(y) {
    return <strong className={y > 0 ? 'draw-positive' : y < 0 ? 'draw-negative' : 'draw-neutral'}>
      {y > 0 ? '+' : ''}{y}%</strong>;
  }

  renderMainSummarySection() {
    let {allCustomersSegment, segment, allCountOfCustomerIsLoaded} = this.props;
    let {spendBracketEnrichment, growthChartData} = this.state;

    let customerPercent = divide(segment.cachedCustomerCount, allCustomersSegment.cachedCustomerCount);

    let showSpentPie = spendBracketEnrichment?.enrichmentProcessedStatus === 'ENABLED' &&
      allCustomersSegment?.currentStats?.spendInLastYearInSegment > 0 &&
      !isSegmentProcessing(segment);
    let showCustomerPie = customerPercent !== NOT_PROCESSED;

    return <>
      <div className="left-summary-section">
        <div className="summary-last-30-days">
          <strong>Last 30 days</strong>
          <div className="customer-change">
            <div className={`segment-change-info`}>
              <p
                className={`customer-change-text ${getSegmentChangeStyle(segment.percentageChangeLast30Days, segment.customerSegmentGrowthType)}`}>
                Number of Customers has changed by
                <strong
                  className={getSegmentChangeStyle(segment.percentageChangeLast30Days, segment.customerSegmentGrowthType)}> {getCountChangePercent(segment.percentageChangeLast30Days)}</strong>
                <i
                  className={`${getSegmentChangeStyle(segment.percentageChangeLast30Days, segment.customerSegmentGrowthType)} ${getSegmentChangeIcon(segment.percentageChangeLast30Days)}`}/>
              </p>
            </div>
          </div>
        </div>
        <div className="total-customer-stat">
          <h1 style={{position: "relative", height: '40px'}}>
            {(!allCountOfCustomerIsLoaded && segment.customerSegmentType === 'ALL_CUSTOMERS') ?
              (<span className="icon-distil-logo icon spin analytics-spin"/>) :
              (segment?.cachedCustomerCount?.toLocaleString(navigator.language, {minimumFractionDigits: 0}) || 0)}</h1>
          <p>Customers in this Segment.</p>
          {customerPercent !== NOT_PROCESSED && <p><strong>{customerPercent}%</strong> of total</p>}
        </div>
        <div className="spend-bracket-summary">
          {this.getSpendBracketStatisticsContent(spendBracketEnrichment, segment)}
        </div>
      </div>
      <div className="right-summary-section">
        <div className="segment-grow-info segment-rules-info">
          {segment.customerSegmentGrowthType !== 'SIZE_DOES_NOT_MATTER' ?
            <strong>You are trying to {getSegmentGrowthType(segment.customerSegmentGrowthType)} this
              Segment</strong> :
            <strong>Segment growth does not matter</strong>
          }
        </div>
        <div className="analytics-charts-wrapper">
          {(showSpentPie || showCustomerPie) && <div className="pie-charts-wrapper">
            {showSpentPie &&
            <SegmentPieChart width={350}
                             data={this.getSegmentValueChartData(segment, allCustomersSegment)}/>}
            {showCustomerPie && <SegmentPieChart width={350}
                                                 data={this.getSegmentSizeChartData(segment, allCustomersSegment, customerPercent)}/>}
          </div>}
          {this.state.growthChartData[0]?.data?.length > 1 ?
            <div className="segment-growth-chart chart-views">
              <>
                <strong>Segment Growth</strong>
                <LineChart
                  data={growthChartData}
                  tooltipLabel="Segment Growth"
                  enableBottom={true}
                  xAxisLabelsCount={7}
                  enablePoints={false}
                  enableGridY={false}
                  enableCrosshair={true}
                />
              </>
            </div>
            :
            <div className="segment-growth-chart chart-views">
              <strong className="draw-light-gray">Segment growth&nbsp;</strong>
              <strong className="draw-light-black">Your distilled data graph is on its way.</strong>
              <img src={noDataImage} alt="no segment data chart"/>
            </div>
          }
        </div>
      </div>
    </>
  }

  render() {
    return (
      <React.Fragment>
        <div className="segment-analytics-summary">
          {this.renderMainSummarySection()}
        </div>
      </React.Fragment>
    );
  }
}

export default AnalyticsSummary;
