import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import FormCheck from "react-bootstrap/FormCheck";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Select } from "react-inputs-validation";
import AttributeRule from "../components/attributeRule";
import _ from "lodash";
class Recomendations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customAttribute: false,
      // fieldValue: null,
      fieldValue: "PRODUCT_RECOMMENDATIONS_MIX",
      recommendationCount: "1",
      emptyAttribute: {
        attributeID: "",
        field: null,
        segmentCondition: null,
        value: null,
        isSaved: false
      },
      attributeList: [],
      hasError: true
    };
  }

  componentDidMount() {
    let newDestination = this.props.newDestination;
    if (newDestination.recomendation !== undefined) {
      this.setState({
        // fieldValue: newDestination.recomendation.fieldValue,
        fieldValue: "PRODUCT_RECOMMENDATIONS_MIX",
        attributeList: newDestination.recomendation.attributeList,
        customAttribute: newDestination.recomendation.customAttribute,
        recommendationCount: newDestination.recomendation.recommendationCount,
        hasError: newDestination.recomendation.hasError
      });
    }
  }
  
  componentWillUnmount() {
    let recomendation = {
      fieldValue: this.state.fieldValue,
      attributeList: this.state.attributeList.filter(
        item => item.isSaved === true
      ),
      customAttribute: this.state.customAttribute,
      recommendationCount: this.state.recommendationCount,
      hasError: this.checkSectionError()
    };

    this.props.onPageChange("recomendation", recomendation);
  }

  checkSectionError() {
    if (this.state.fieldValue === null) {
      return true;
    } else if (
      this.state.fieldValue !== null &&
      this.state.customAttribute &&
      this.state.attributeList.filter(item => item.isSaved === true).length ===
        0
    ) {
      return true;
    } else {
      return false;
    }
  }

  enableCustomAttribute = () => {
    const customAttribute = this.state.customAttribute;
    // let attributeList = this.state.attributeList;
    // if (attributeList.length === 0) {
    //   let blankAttr = this.state.emptyAttribute;
    //   attributeList.push(blankAttr);
    //   this.setState(
    //     { attributeList },
    //     localStorage.setItem("checkChange", true)
    //   );
    // }
    let attributeList=[];
    attributeList.push(this.state.emptyAttribute);


    this.setState({ customAttribute: !customAttribute, attributeList });
  };
  addMore = () => {
    let attributeList = this.state.attributeList;
    let blankAttr = this.state.emptyAttribute;
    attributeList.push(blankAttr);
    this.setState({ attributeList });
  };
  removeItem = index => {
    let attributeList = this.state.attributeList;

    let newlist = attributeList.filter(item => item != attributeList[index]);

    newlist.length === 0 && this.setState({ customAttribute: false });
    this.setState({ attributeList: newlist });
  };
  handleUpdateRule = (index, item) => {
    item.isSaved = true;
    const attributeList = this.state.attributeList;
    attributeList[index] = item;
    this.setState({ attributeList });
  };
  handleRecommendationCount = recommendationCount => {
    this.setState(
      { recommendationCount },
      localStorage.setItem("checkChange", true)
    );
    this.props.newDestination.destination !== undefined &&
      this.updatedDestination(
        this.props.newDestination.destination,
        recommendationCount
      );
  };

  updatedDestination = (destination, recommendationCount) => {
    let hasMoreAttr = destination.filter(
      selectedDestination =>
        this.attributesSelectionCheck(
          selectedDestination.attributes,
          recommendationCount
        ) > selectedDestination.numExternalFields
    );
    hasMoreAttr.length !== 0
      ? (destination.hasError = true)
      : (destination.hasError = false);

    // console.log(hasMoreAttr, destination);
    // this.setState({ destination });
    this.props.onPageChange("destination", destination);
  };

  updatedDestinationss = (destination, recommendationCount) => {
    let hasMoreAttr = destination.filter(
      selectedDestination =>
        selectedDestination.numExternalFields <
        selectedDestination.selectedAttributesCount
    );
    hasMoreAttr.length !== 0
      ? (destination.hasError = true)
      : (destination.hasError = false);

    let attrSize = [];

    // debugger;
    destination.map(item => {
      if (
        this.attributesSelectionCheck(item.attributes, recommendationCount) >
        item.numExternalFields
      ) {
        attrSize.push(
          this.attributesSelectionCheck(item.attributes, recommendationCount)
        );
      }
    });

    attrSize.length !== 0
      ? (destination.hasError = true)
      : (destination.hasError = false);

    // console.log(attrSize, destination);
  };

  attributesSelectionCheck = (attributes, recommendationCount) => {
    const productTags = [
      "PRODUCT_EXTERNAL_ID",
      "PRODUCT_NAME",
      "PRODUCT_SHOP_URL",
      "PRODUCT_IMAGE_URL",
      "PRODUCT_THUMBNAIL_URL",
      "PRODUCT_PRECIS",
      "PRODUCT_AVAILABLE",
      "PRODUCT_LIST_PRICE_EX_TAX",
      "PRODUCT_LIST_PRICE_INC_TAX",
      "PRODUCT_PRICE_BREAKS_DESCRIPTION"
    ];
    let selectedAttributes = [];
    let selectedAttributesCount = 0;

    (attributes || []).map(attribute => {
      selectedAttributes.push(attribute);
      if (
        _.includes(
          productTags,
          attribute.attributeTag || attribute.attributeDataTag
        )
      ) {
        selectedAttributesCount += parseInt(recommendationCount);
      } else {
        selectedAttributesCount++;
      }
    });
    return selectedAttributesCount;
  };

  render() {
    const { customAttribute } = this.state;
    return (
      <div className="recomendations">
        <form>
          <div className="recomendations-top">
            <div className="form-group">
              <h4>Choose Number of Recommendations to include</h4>
              {/* <p>
                Pick the Recommendation Algorithm you wish to use plus the
                number of Product recommendations you would like to include.
                <br />
                Between (1 and 5)
              </p> */}
            </div>
            {/* <Form.Group>
              <Select
                name={"recommendation"}
                value={this.state.fieldValue}
                disabled={false}
                optionList={[
                  { id: null, name: "Select Recommendation Type" },
                  {
                    id: "PRODUCT_RECOMMENDATIONS_MIX",
                    name: "Recommendation mix"
                  },
                  {
                    id: "PRODUCT_RECOMMENDATIONS_VIEWED_NOT_PURCHASED",
                    name: "Products viewed not purchased"
                  },
                  {
                    id: "PRODUCT_RECOMMENDATIONS_VIEWED_TOGETHER",
                    name: "Products viewed together"
                  },
                  {
                    id: "PRODUCT_RECOMMENDATIONS_PURCHASED_TOGETHER",
                    name: "Products purchased together"
                  },
                  {
                    id: "PRODUCT_RECOMMENDATIONS_FOLLOW_ON_PURCHASE",
                    name: "Follow on purchases"
                  },
                  {
                    id: "PRODUCT_RECOMMENDATIONS_TRENDING",
                    name: "Trending products"
                  },
                  {
                    id: "PRODUCT_RECOMMENDATIONS_DEFAULT_PRODUCTS",
                    name: "Default products"
                  }
                ]}
                classNameSelect="select-control"
                classNameWrapper="form-control select-control-wrapper recom-type"
                classNameContainer=""
                classNameOptionListContainer="select-control-option"
                classNameOptionListItem=""
                customStyleSelect={{}}
                customStyleWrapper={{}}
                customStyleContainer={{}}
                customStyleOptionListContainer={{}}
                customStyleOptionListItem={{}}
                onChange={(condition, e) =>
                  this.setState(
                    { fieldValue: condition },
                    localStorage.setItem("checkChange", true)
                  )
                }
              />
            </Form.Group> */}
            <Form.Group>
              {/* <Form.Label>
                Number of Product Recommendations to include
              </Form.Label> */}
              <Select
                name={"number"}
                value={this.state.recommendationCount}
                disabled={false}
                optionList={[
                  { id: 1, name: 1 },
                  { id: 2, name: 2 },
                  { id: 3, name: 3 },
                  { id: 4, name: 4 },
                  { id: 5, name: 5 }
                ]}
                classNameSelect="select-control"
                classNameWrapper="form-control select-control-wrapper number"
                classNameContainer="dropdown-without-title"
                classNameOptionListContainer="select-control-option"
                onChange={attr => this.handleRecommendationCount(attr)}
              />
            </Form.Group>
          </div>
          <div className="recomendations-bottom">
            <div className="mb-3">
              <label
                className={
                  customAttribute
                    ? "recomendations-check-label active"
                    : "recomendations-check-label"
                }
                onClick={() => this.enableCustomAttribute()}
              >
                <i className="icon-check"></i>
                Specify Filter Criteria
              </label>
              <div className="recomendations-check-text">
                Only include recommendations that fulfill these attribute-value
                requirements
              </div>
              <div className="recomendations-check-op">
                {this.state.customAttribute &&
                  this.state.attributeList.map((item, index) => (
                    <AttributeRule
                      attrItem={item}
                      index={index}
                      onRemove={this.removeItem}
                      onUpdateRule={this.handleUpdateRule}
                    />
                  ))}
                {this.state.attributeList.filter(item => item.isSaved === false)
                  .length > 0
                  ? ""
                  : customAttribute && (
                      <div className="recomendations-check-btn">
                        <Button onClick={() => this.addMore()}>
                          + Add more
                        </Button>
                      </div>
                    )}
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default Recomendations;
