import React, { Component } from "react";
import { debounce } from "lodash";
import SearchAPI from "../../services/search";

import "./assets/styles.css";
import {getPluralEnding} from "../../views/Main/Customers/customerdata/segmentUtils";
import Loader from "../Loader/loader"

class Search extends Component {
  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      showResult: false,
      criteria: "",
      searchResult: [],
      isLoading: false
    };
  }
  componentDidMount() {
    this.setState({ criteria: this.props.searchCriteria });
    if (this.props.searchCriteria.length > 0) {
      this.doSearch(this.props.searchCriteria);
    }
    this.mySearch.focus();
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside(e) {
 
    
     
    if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
      if (
        e.target.id !== "notice-btn-wrapper" &&
        e.target.id !== "notice-btn"
      ) {
        this.props.onEscape();
      }
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  closeSearch = () => {
    this.props.onCloseSearch();
  };

  doSearch = async searchWhat => {
    // console.log(searchWhat);
    try {
      await SearchAPI.searchData(searchWhat).then(response => {
        this.setState({
          searchResult: response,
          showResult: true,
          isLoading: false
        });
      });
    } catch (err) {}
  };

  raiseDoSearchWhenUserStoppedTyping = debounce(() => {
    if (this.state.criteria.length > 0) {
      this.doSearch(this.state.criteria);
    } else {
      let searchResult = {
        segmentResults: "",
        customerResults: ""
      };
      this.setState({
        searchResult,
        showResult: true,
        isLoading: false
      });
    }
    this.props.onSearchCriteria(this.state.criteria);
  }, 500);

  onSearchStart = e => {
    this.setState({ criteria: e.currentTarget.value, isLoading: true}, () => {
      this.raiseDoSearchWhenUserStoppedTyping();
    });
  };

  render() {
    const { showResult, searchResult, isLoading} = this.state;
    const { onCustomerDetail } = this.props;

    let customerResults = searchResult?.customerResults || [];
    let segmentResults = searchResult?.segmentResults || [];

    return (
      <div className="search-result secondary-font"  ref={this.setWrapperRef}>
        <div className="search-wrap">
          <div className="top-wrap bg-white position-relative">
            <input
              
              ref={(input) => { this.mySearch = input; }}
              type="text"
              placeholder="Search Phrase"
              value={this.state.criteria}
              onChange={this.onSearchStart}
            />
            {isLoading && (
              <Loader adaptive={true} size={50}
                      style={{
                        "justify-content": "center",
                        "align-items": "center",
                      }}
              />
            )}
            <span className="close" onClick={this.closeSearch}>
              <span className="icon-cancel" />
            </span>
          </div>

          {showResult && (
            <div className="search-content-wrap">
              {segmentResults.length > 0 && (
                  <div className="segment-result">
                    {segmentResults.map((segments, key) => (
                      <div className="record">
                        <div className="record-name">
                          {segments.name} {segments.description}
                        </div>
                        <span className="light-black-text-40 ">
                          {segments.cachedCustomerCount} Record{getPluralEnding(segments.cachedCustomerCount)}
                        </span>
                      </div>
                    ))}
                  </div>
                )}

              {customerResults.length > 0 && (
                  <div className="customers-result">
                    <div className="font-12 section-head light-black-text-40">
                      Customers
                    </div>

                    {customerResults.map((customers, key) => (
                      <div
                        className="record"
                        key={key}
                        onClick={() => onCustomerDetail(customers.id)}
                      >
                        <div className="record-name">
                          {customers.firstName} {customers.lastName}
                          <span className="icon-chevron-thin-right ml-auto" />
                        </div>
                        <div className="record-content d-flex">
                          <span className="light-black-text-70">
                            {customers.countryCode}{" "}
                            <strong className="email-line"> {customers.emailAddress.join('\n')} </strong>
                          </span>
                         
                        </div>
                      </div>
                    ))}
                  </div>
                )}

              {segmentResults.length === 0 && customerResults.length === 0 && (
                  <div className="customers-result">
                    <div className="font-12 section-head light-black-text-40">
                      No search result available
                    </div>
                  </div>
                )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Search;
