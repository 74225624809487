import React, {Component} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import DataConnectionAPI from "../../../../../services/dataConnections";
import NewConnectionMeta from "./NewConnectionMeta";
import Zapier from "../../../../../services/zapier";
import {Select, Textbox} from "react-inputs-validation";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import {toast} from "react-toastify";
import {zapierTypes} from "../../../../../variables/globalValues.json";
import {connectionIcons, dataSourceTypes} from "../../../../../variables/globalValues.json";
import {defaultMargin} from "nivo";

const initialState = {
  updatingContent: false,
  connectionTitle: "",
  connectionDesc: "",
  connectionType: "",
  dataSourceType: null,
  iconType: "ZAPIER",


  apiPermission: "READ_WRITE_OWN_AND_READ_ALL",
  connectionSettingsApiKey: "",
  apiConnectionId: null,

  connectionSettingsClientSecret: "",
  connectionSettingsServerAddress: "",
  connectionSettingsSecurityCode: "",
  connectionSettingEnabled: true,

  hasConnectionTitleError: true,
  hasConnectionDescError: true,
  hasDataSourceTypeError: true,

  validate: false,
  isLoaded: false,
  enabled: true,
  disableOnEdit: false
};

const editConnection = {
  disableOnEdit: false
};
const hasNoError = {
  hasConnectionTitleError: false,
  hasConnectionDescError: false,
  hasDataSourceTypeError: false
};

class ZapierConnection extends Component {
  constructor(props) {
    super(props);
    this.state = {initialState, editConnection, attrs: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  resetData = () => {
    this.setState(initialState);
    localStorage.setItem("checkChange", false);
  };

  componentDidMount() {
    this.setState(initialState);
    this.setState({connectionType: this.props.formType});
    if (this.props.editConnection) {

      DataConnectionAPI.getDataSource(this.props.selectedConnection.id).then(value => {
        let dataSourceAttrs = [];
        value[0].attributes.map(attr =>
            dataSourceAttrs.push({id: parseInt(attr.id),
            name: attr.attributeSourceName,
            displayName: attr.attributeDisplayName,
            type: attr.attributeType.toLocaleLowerCase() === "double" ? "decimal" : attr.attributeType.toLocaleLowerCase(),
            editableKey: false,
            editableType: false,
            deleted: attr.attributeDataTag === null ? false : true
      }));
        this.setState({attrs: dataSourceAttrs})
      })
      this.editConnectionDefaults();
      this.setState({updatingContent: false});
      this.setState(hasNoError);
    }
    }

  componentWillReceiveProps(nextProps, _) {
    let checkChange = JSON.parse(localStorage.getItem("checkChange"));
    if (!checkChange) {
      if (nextProps.editConnection) {
        this.editConnectionDefaults();
      } else {
        if (this.props.editConnection) {
          this.resetData();
        }
      }
    }
  }

  editConnectionDefaults = () => {
    this.setState({
      connectionTitle: this.props.selectedConnection.name,
      connectionDesc: this.props.selectedConnection.description,
      connectionType: this.props.selectedConnection.connectionType,
      iconType: this.props.selectedConnection.iconType,
      apiPermission: this.props.selectedConnection.apiPermission,
      connectionSettingEnabled: this.props.selectedConnection.enabled,
      connectionSettingsApiKey: this.props.selectedConnection.connectionSettings.apiKey,
      connectionSettingsClientSecret: this.props.selectedConnection.connectionSettings.clientSecret,
      connectionSettingsServerAddress: this.props.selectedConnection.connectionSettings.serverAddress,
      connectionSettingsSecurityCode: this.props.selectedConnection.connectionSettings.securityCode,
      dataSourceType: this.props.selectedConnection.connectionSettings.dataSourceType
    });
  };


  addFormFields() {
    this.state.attrs.push({editableType: true, editableKey: true, deleted: false});
    this.setState({attrs: Array.from(this.state.attrs)})
  }

  changeKey(i, e) {
    let attrs = this.state.attrs;
    attrs[i]["name"] = e;
    this.setState({ attrs });
  }

  changeName(i, e) {
    let attrs = this.state.attrs;
    attrs[i]["displayName"] = e;
    this.setState({attrs});
  }

  changeType(i, e) {
    let attrs = this.state.attrs;
    attrs[i]["type"] = e;
    this.setState({attrs});
  }

  handleSubmit(event) {
    event.preventDefault();
    alert(JSON.stringify(this.state.attributes));
  }

  removeFormFields = (i) => {
    let formValues = this.state.attrs;
    formValues.splice(i, 1);
    this.setState({ attrs: formValues });
  }

  validateForm = e => {
    let validAttrs = true;
    e.preventDefault();
    this.setState({updatingContent: false});
    const {
      hasConnectionTitleError,
      hasConnectionDescError,
      hasDataSourceTypeError,
    } = this.state;

    if(this.state.editConnection) {
      validAttrs = this.validateAttrs(this.state.attrs)
    }
    if (
      !hasConnectionTitleError &&
      !hasConnectionDescError &&
      !hasDataSourceTypeError &&
      validAttrs
    ) {
      this.addConnection();
    }
  };

  validateAttrs = (attrs) => {
    for (const attr of attrs) {
      const isDisplayNameValid = /^[A-Za-z0-9.\s_-]+$/.test(attr.displayName);
      const isNameValid = /^[A-Za-z0-9_]+$/.test(attr.name);
      const isTypePresent = !!attr.type;

      if (!isDisplayNameValid) {
        toast.error("Invalid Attribute Display Name!");
        return false;
      }

      if (!isNameValid) {
        toast.error("Invalid Attribute Name!")
        return false;
      }

      if (!isTypePresent) {
        toast.error("Invalid Attribute Type!")
        return false;
      }
    }

    const displayNames = attrs.map(value => value.displayName)
    const keys = attrs.map(value => value.name)

    if (this.toFindDuplicates(displayNames).length > 0 || this.toFindDuplicates(keys).length > 0) {
      toast.error("Attribute names and display names can't be the same!")
      return false;
    }

    return true;
  }

  toFindDuplicates = attrs => attrs.filter((item, index) => attrs.indexOf(item) !== index);

  addConnection = async () => {
    this.setState({updatingContent: true});
    let formData = {
      name: this.state.connectionTitle,
      description: this.state.connectionDesc,
      connectionType: 'ZAPIER',
      iconType: this.state.iconType,
      apiPermission: this.state.apiPermission,
      connectionSettings: {
        apiKey: null,
        userName: null,
        password: null,
        clientSecret: this.state.connectionSettingsClientSecret,
        serverAddress: this.state.connectionSettingsServerAddress,
        securityCode: this.state.connectionSettingsSecurityCode,
        dataSourceType: this.state.dataSourceType,
      },
      enabled: this.state.connectionSettingEnabled
    };

    if (this.props.editConnection) {
      formData.id = this.props.selectedConnection.id;
      formData.connectionSettings.apiKey = this.state.connectionSettingsApiKey;

      try {
        await Zapier.updateAttr(this.props.selectedConnection.id, this.state.attrs)

        await DataConnectionAPI.updateConnection(formData, this.props.selectedConnection.id)
          .then(response => {
            toast.success("Connection updated successfully");
            this.resetData();
            this.props.onAddingConnection(response.id);
        });
      } catch (err) {
        toast.error("Connection updating error");
      }
    } else {
      try {
        await DataConnectionAPI.newConnection(formData).then(response => {
          toast.success("Connection created successfully");
          this.resetData();
          this.props.onAddingConnection(response.id);
        });
      } catch (err) {
        toast.error("Connection creation error");
      }
    }
    this.setState({updatingContent: false});
  };

  copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    toast.success("Copied to clipboard")
  };

  onCheckChange = () => {
    this.setState(
      { isLoaded: false },
      this.props.onCheckChange()
    );
    this.props.onCheckChange()
  };

  render() {
    const {
      connectionTitle,
      connectionDesc,
      dataSourceType,
      validate,
      updatingContent,
      disableOnEdit,
      connectionSettingsApiKey,
    } = this.state;

    return (
      <React.Fragment>
        <NewConnectionMeta
          formType={this.props.formType}
          onBackPress={this.props.onBackPress}
          editConnection={this.props.editConnection}
        />
        <Form onSubmit={this.validateForm}>
          <Form.Row>
            <Form.Group as={Col} controlId="formGridName">
              <Form.Label>Title</Form.Label>
              <Textbox
                classNameInput="form-control"
                classNameContainer="custome-input"
                tabIndex="1"
                id={"connectionTitle"}
                name="connectionTitle"
                type="text"
                validate={validate}
                validationCallback={res =>
                  this.setState({
                    hasConnectionTitleError: res,
                    validate: false
                  })
                }
                value={connectionTitle}
                onChange={(connTitle, _) => {
                  this.setState({connectionTitle: connTitle, updatingContent: false, isConnectionVerified: false },
                    () => this.onCheckChange()
                  );
                }}
                onBlur={_ => {}}
                validationOption={{
                  name: "Title",
                  check: true,
                  required: true
                }}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridDesc">
              <Form.Label>Description</Form.Label>
              <Textbox
                classNameInput="form-control"
                classNameContainer="custome-input"
                tabIndex="2"
                id={"connectionDesc"}
                name="connectionDesc"
                type="text"
                validate={validate}
                validationCallback={res =>
                  this.setState({hasConnectionDescError: res, validate: false})
                }
                value={connectionDesc}
                onChange={(connDesc, _) => {
                  this.setState({connectionDesc: connDesc}, () => this.onCheckChange());
                }}
                onBlur={_ => {}}
                validationOption={{
                  name: "Description",
                  check: true,
                  required: true
                }}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridIP">
              <Form.Label>Data Source Type</Form.Label>
              <Select
                  name={"dataSourceTypeSelector"}
                  tabIndex="3"
                  disabled={this.props.editConnection}
                  value={dataSourceType}
                  optionList={dataSourceTypes}
                  classNameSelect="select-control"
                  classNameWrapper="form-control select-control-wrapper selection-type"
                  classNameContainer=""
                  classNameOptionListContainer="select-control-option"
                  classNameOptionListItem=""
                  validate={validate}
                  validationCallback={res =>
                      this.setState({hasDataSourceTypeError: res, validate: false})
                  }
                  customStyleSelect={{}}
                  customStyleWrapper={{}}
                  customStyleContainer={{}}
                  customStyleOptionListContainer={{
                    maxHeight: "300px",
                    overflow: "auto"
                  }}
                  customStyleOptionListItem={{}}
                  onChange={(condition, _) => {
                    this.setState({dataSourceType: condition},() => this.onCheckChange());
                  }}
                  onBlur={_ => {}}
                  validationOption={{
                    name: "Data Source Type",
                    check: true,
                    required: true
                  }}
              />
            </Form.Group>
          </Form.Row>

          {this.props.editConnection && <Form.Row>
            <Form.Group as={Col}
                        data-tooltip={"Click to copy"}
                        onClick={() => this.copyToClipboard(connectionSettingsApiKey)}
            >
              <Form.Label>Api Key</Form.Label>
              <Textbox
                name={"ApiConnectionKey"}
                tabIndex="2"
                value={connectionSettingsApiKey}
                type="text"
                classNameContainer=""
                customStyleOptionListContainer={{
                  maxHeight: "300px",
                  overflow: "auto"
                }}

              />
            </Form.Group>
          </Form.Row>}

          {this.props.editConnection &&
            <Form.Group as={Col} controlId={"formGridAttr"}>
            <Form.Label className={"m-3"}>Attributes</Form.Label>
              {
                this.state.attrs.length === 0 ? null : this.state.attrs.map((attr, index) => (
                  <Form.Row  key={index} onSubmit={this.handleSubmit} className={"m-2"}>
                    <Col>
                      <Textbox
                          tabIndex="4"
                          type="text"
                          name="key"
                          disabled={!attr.editableKey}
                          value={attr.name || ""}
                          onChange={e => this.changeKey(index, e)}
                      />
                    </Col>

                    <Col>
                      <Textbox
                          tabIndex="5"
                          type="text"
                          name="name"
                          value={attr.displayName || ""}
                          onChange={e => this.changeName(index, e)}
                      />
                    </Col>

                    <Col>
                      <Select
                        classNameSelect="select-control"
                        classNameWrapper="form-control select-control-wrapper selection-type"
                        classNameContainer=""
                        classNameOptionListContainer="select-control-option"
                        classNameOptionListItem=""
                        value={attr.type}
                        customStyleSelect={{}}
                        customStyleWrapper={{}}
                        customStyleContainer={{}}
                        optionList={zapierTypes}
                        customStyleOptionListContainer={{}}
                        onChange={e => this.changeType(index, e)}
                        disabled={!attr.editableType}/>
                    </Col>

                      <Button
                          variant="secondary"
                          type="button"
                          disabled={attr.deleted}
                          className="button remove"
                          onClick={() => this.removeFormFields(index)}
                      >
                        Delete
                      </Button>
                  </Form.Row>
                ))
              }
              <div className="button-section">
                <Button className={"m-3"} variant="secondary" type="button" onClick={() => this.addFormFields()}>+ Add New Attribute</Button>
              </div>
          </Form.Group>}

          <Form.Row className="btn-wrap">
            <Button
              variant="primary"
              disabled={updatingContent}
              type="submit"
            >
              Done
            </Button>

            {!disableOnEdit && (
              <Button
                variant="secondary"
                onClick={this.props.onCancelConnection}
              >
                <span className="icon-cancel icon"/>
                Cancel
              </Button>
            )}
          </Form.Row>
        </Form>
      </React.Fragment>
    );
  }
}

export default ZapierConnection;
