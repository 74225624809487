import {lowerCase} from 'lodash';
import moment from 'moment';

export function formatData(attrType, attrTag, value) {

  switch (attrType) {
    case 'BOOLEAN':
      const formattedValue = lowerCase(value);
      return (formattedValue === null || formattedValue === undefined)
        ? ''
        : (formattedValue === 'true' || formattedValue === '1')
          ? 'true'
          : (formattedValue === 'false' || formattedValue === '0')
            ? 'false'
            : '';
    case 'INTEGER':
    case 'DOUBLE':
    case 'FLOAT':
    case 'LONG':
    case 'DECIMAL':
    case 'BIGINT':
      if (value !== null) {
        return Number(value).toLocaleString(navigator.language, {
          minimumFractionDigits: attrType === 'INTEGER' ? 0 : 2
        });
      }
      return value;
    case 'DATE':
      return value ? moment.unix(value / 1000).format('YYYY-MM-DD') : '';
    case 'TIMESTAMP':
      return value ? moment.unix(value / 1000).format('YYYY-MM-DD HH:mm:ss') : '';
    case 'CUSTOMER_TRAIT':
      return value || false;
    default:
      switch (attrTag) {
        case 'CUSTOMER_TRAIT':
          return value || 'false';
        default:
          return value;
      }

  }
}
