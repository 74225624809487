import React, { Component } from "react";

import {formatData} from "../../utils";

class DataRow extends Component {

  renderSectionData = sections => {

    return sections.map((section, keys) =>
      section.attributes.map((attr, key) => (
        <td
          key={key}
          className={`${keys === 0 ? "firstSection" : ""} section_${
            keys % 2 ? "odd" : "even"
          }`}
        >

          {formatData(attr.attributeType, attr.attributeDataTag, attr.value)}
        </td>
      ))
    );
  };

  render() {
    const { customerList } = this.props;
    return (
      <tbody>
        {customerList.map((customer, key) => (
          <tr
            key={key}
            onClick={() => {
              this.props.onCustomerDetail(customer.id)
            }}
          >
            {this.renderSectionData(customer.dataRecordSections)}
          </tr>
        ))}
      </tbody>
    );
  }
}

export default DataRow;
