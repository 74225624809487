import React from "react";
import "./styles.scss";

const MarketingAttributeRow = ({ data}) => {
    return (
        <div className="row display-flex-max576 display-none pb-3 border-top1px-dotted pt-2">
            <div className="col-1">
            </div>
            <div className="col-11">
                <div className="content-row pt-2"
                     style={{display: 'flex', justifyContent: 'space-evenly'}}>
                    <p className="font-size-14px color-brown width168px text-right">
                        <strong>Marketing Attribute</strong>
                    </p>
                    <div className="content-row"
                         style={{display: 'flex', justifyContent: 'space-evenly'}}>
                        <p className=" text-left color-706 font-size-15px" style={{width: '60px'}}>
                            {data}
                        </p>
                        <div className="pers pt-1" style={{visibility: 'hidden'}}>
                            <svg width="11" height="9" viewBox="0 0 11 9" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.45538 9L0.157987 0.750001L10.7528 0.75L5.45538 9Z"
                                      fill="#ED6A5E"/>
                            </svg>
                        </div>
                    </div>
                    <p/>
                </div>
            </div>
        </div>
    );
};

export default MarketingAttributeRow;