import React, { Component } from "react";
import smoothscroll from "smoothscroll-polyfill";

const checkNavigtorTimer = "";
class DataHeadTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabsCount: "",
      isLoading: false,
      tabNavigatorClass: ["tab-navigator"],
      disableTabNavigator: false,
      onClickState: false,
      sectionIcon: {
        DEFAULT: "DEFAULT.ico",
        API_CONNECTION: "API_CONNECTION.svg",
        CORE_DATA: "DEFAULT.ico",
        POSTGRESQL: "POSTGRESQL.ico",
        MYSQL: "MYSQL.ico",
        SQLSERVER: "SQLSERVER.png",
        REDSHIFT: "REDSHIFT.png",
        MAILCHIMP: "MAILCHIMP.ico",
        HUBSPOT: "HUBSPOT.png",
        INSIGHTLY: "INSIGHTLY.png",
        SALESFORCE: "SALESFORCE.ico",
        ZAPIER: "ZAPIER.ico",
        CAMPAIGN_MONITOR: "CAMPAIGN_MONITOR.ico",
        ZEN_DESK: "ZEN_DESK.ico",
        DOT_DIGITAL: "DOT_DIGITAL.ico",
        DOT_DIGITAL_V3: "DOT_DIGITAL_V3.ico",
        KLAVIYO: "KLAVIYO.png",
        KLAVIYO_V2: "KLAVIYO_V2.png",
        ACTIVE_CAMPAIGN: "ACTIVE_CAMPAIGN.jpeg",
        EVENT_BRITE: "EVENT_BRITE.png",
        FACEBOOK_LEAD_ADS: "FACEBOOK_LEAD_ADS.png",
        INSTAGRAM: "INSTAGRAM.png",
        INTERCOM: "INTERCOM.png",
        LINKED_IN: "LINKED_IN.png",
        MATTER: "MATTER.png",
        POWER_BI: "POWER_BI.jpeg",
        TWITTER: "TWITTER.png",
        SHOPIFY: "SHOPIFY.png",
        YOTPO: "YOTPO.png",
        OPENTABLE: "OPENTABLE.png",
        WIRELESS_SOCIAL: "WIRELESS_SOCIAL.jpeg"
      }
    };
  }
  componentDidMount() {
    smoothscroll.polyfill();
    this.setState(
      {
        tabsCount: this.props.customerList[0].dataRecordSections.length
      },
      () => this.getGridPos()
    );
    this.isNavigatorRequired();
    this.setTabPosition();
  }

  componentWillReceiveProps(prevProps) {
    smoothscroll.polyfill();
    this.getGridPos();
    // this.setTabPosition();
    this.isNavigatorRequired();
    // if (!prevProps.isScrolling) {
    this.tabOnScroll();
    // }
  }
  componentWillUnmount() {
    clearInterval(checkNavigtorTimer);
  }

  getGridPos = () => {
    let gridTabs = document.querySelector(".grid-tabs");
    const tableHead = gridTabs.getBoundingClientRect();
    let top = tableHead.top;
    let height = tableHead.height;
    const getGridPos = { top, height };
    this.setState({ getGridPos, isLoading: true });
  };

  setTabPosition = () => {
    let tab0 = document.querySelector(".grid-tabs .tab_0");
    if (tab0 != undefined) {
      let tab0Class = tab0.getAttribute("class");
      tab0.setAttribute("class", `activeTab ${tab0Class}`);
      this.props.isError(false);
    } else {
      this.props.isError(true);
    }
  };

  removeAcitve = () => {
    let gridTabs = document.querySelector(".grid-tabs");
    let tab = gridTabs.getElementsByClassName("tab");

    for (var i = 0; i < tab.length; i++) {
      tab[i].classList.remove("activeTab");
    }
  };

  scrollLeft = () => {
    this.setState({ onClickState: true });

    var activeTab = document.querySelector(".grid-tabs .activeTab");
    let activeTabno = activeTab.getAttribute("data-tabno");
    let tabno = parseInt(activeTabno) - 1;

    if (tabno >= 0) {
      let tabToScroll = document.querySelector(".grid-tabs .tab_" + tabno);
      let tabPos = tabToScroll.getAttribute("data-scrolltab");
      let headingPos = tabToScroll.getAttribute("data-scrollheading");

      var tableContainer = document.querySelector(".table-wrapper.grid-data");
      var gridTabs = document.querySelector(".grid-tabs");

      gridTabs.scroll({ left: tabPos, behavior: "smooth" });
      tableContainer.scroll({ left: headingPos, behavior: "smooth" });

      this.removeAcitve();

      let selectedTabClass = tabToScroll.getAttribute("class");

      tabToScroll.setAttribute("class", `activeTab ${selectedTabClass}`);

      // Add Disable if on Left most Tab
      let lastTabWidth = document.querySelector(".grid-tabs .tab_last").clientWidth;

      if (gridTabs.clientWidth >= gridTabs.scrollWidth - lastTabWidth - 5) {
        if (tabno <= 1) {
          let tabNavigatorClass = this.state.tabNavigatorClass;
          let checkme = tabNavigatorClass.indexOf("disable-nav");
          if (checkme === -1) {
            tabNavigatorClass.push("disable-nav");
          }
          this.setState({ tabNavigatorClass });
        }
      }
    }
    setTimeout(() => {
      this.setState({ onClickState: false });
    }, 500);
  };
  scrollRight = () => {
    this.setState({ onClickState: true });

    var activeTab = document.querySelector(".grid-tabs .activeTab");
    let activeTabno = activeTab.getAttribute("data-tabno");
    let tabno = parseInt(activeTabno) + 1;

    if (tabno < this.state.tabsCount) {
      let tabToScroll = document.querySelector(".grid-tabs .tab_" + tabno);
      let tabPos = tabToScroll.getAttribute("data-scrolltab");
      let headingPos = tabToScroll.getAttribute("data-scrollheading");

      var tableContainer = document.querySelector(".table-wrapper.grid-data");
      var gridTabs = document.querySelector(".grid-tabs");

      gridTabs.scroll({ left: tabPos, behavior: "smooth" });
      tableContainer.scroll({ left: headingPos, behavior: "smooth" });

      this.removeAcitve();

      let selectedTabClass = tabToScroll.getAttribute("class");

      tabToScroll.setAttribute("class", `activeTab ${selectedTabClass}`);
    }
    setTimeout(() => {
      this.setState({ onClickState: false });
    }, 500);
  };

  /// For Scroll on go

  isInViewport = (elem, tabno) => {
    let tableWrapper = document.querySelector(".table-wrapper.grid-data");
    let tabsCount = this.state.tabsCount;

    let nextTab = tabno < tabsCount - 1 ? tabno + 1 : tabno;
    var myGridNext_heading = document.querySelector(
      ".thead-wrapper .tab_" + nextTab
    );

    if (!myGridNext_heading) {
      return;
    }

    var myGridNext = myGridNext_heading.getBoundingClientRect();

    var myGridLastTab_heading = document.querySelector(
      ".thead-wrapper tr th:last-child"
    );
    var myGridLastTab = myGridLastTab_heading.getBoundingClientRect();

    let sideNav = document.querySelector(".sideNav");
    var sideNavSize = sideNav.getBoundingClientRect();

    let fixedTab = document.querySelector(".table-wrapper.grid-data .tab_0");
    var fixedTabSize = fixedTab.getBoundingClientRect();

    var distance = elem.getBoundingClientRect();

    var spaceLeft = sideNavSize.width + fixedTabSize.width + 10;
    var myLeftSpace = sideNavSize.width + 10;
    let sectionRightPos = 0;
    if (tabno !== tabsCount - 1) {
      sectionRightPos = myGridNext.x;
    } else {
      sectionRightPos = myGridLastTab.x + myGridLastTab.width + 10;
    }
    let isonRight = sectionRightPos - myLeftSpace >= tableWrapper.clientWidth;
    return distance.x >= spaceLeft || isonRight;
  };

  tabOnScroll = () => {
    if (!this.state.onClickState) {
      this.removeAcitve();
      for (let i = 1; i < this.state.tabsCount; i++) {
        let findMe = document.querySelector(
          `.table-wrapper.grid-data .tab_${i}`
        );
        if (this.isInViewport(findMe, i)) {
          let tabToScroll = document.querySelector(".grid-tabs .tab_" + i);
          let tabPos = tabToScroll.getAttribute("data-scrolltab");
          let selectedTabClass = tabToScroll.getAttribute("class");
          tabToScroll.setAttribute("class", `activeTab ${selectedTabClass}`);
          var gridTabs = document.querySelector(".grid-tabs");
          gridTabs.scroll({ left: tabPos, behavior: "smooth" });
          break;
        }
      }
    }
  };

  scrollTab = e => {
    this.setState({ onClickState: true });
    this.removeAcitve();

    let element = e.target;
    element.classList.remove("activeTab");

    let selectedTabClass = e.target.getAttribute("class");

    e.target.setAttribute("class", `activeTab ${selectedTabClass}`);

    var tableContainer = document.querySelector(".table-wrapper.grid-data");
    var gridTabs = document.querySelector(".grid-tabs");

    tableContainer.scroll({
      left: e.target.getAttribute("data-scrollheading"),
      behavior: "smooth"
    });

    gridTabs.scroll({
      left: e.target.getAttribute("data-scrolltab"),
      behavior: "smooth"
    });
    setTimeout(() => {
      this.setState({ onClickState: false });
    }, 500);

    this.checkNavNeeds(element);
  };

  checkNavNeeds = element => {
    let lastTabWidth = document.querySelector(".grid-tabs .tab_last")
      .clientWidth;
    let gridTabs = document.querySelector(".grid-tabs");
    let tabnavigator = document.querySelector(".tab-navigator");
    if (tabnavigator !== null) {
      // Add Disble if on Left most Tab
      if (gridTabs.clientWidth >= gridTabs.scrollWidth - lastTabWidth - 5) {
        this.enableDisableTab(element);
      }
    }
  };

  isNavigatorRequired = () => {
    try {
      let lastTab = document.querySelector(".grid-tabs .tab_last");
      if (lastTab != null) {
        checkNavigtorTimer = setInterval(() => {
          let lastTabWidth = lastTab.clientWidth;
          let gridTabs = document.querySelector(".grid-tabs");
          let tabnavigator = document.querySelector(".tab-navigator");
          if (tabnavigator !== null) {
            // Add Disble if on Left most Tab
            if (
              gridTabs.clientWidth >=
              gridTabs.scrollWidth - lastTabWidth - 5
            ) {
              let tabNavigatorClass = this.state.tabNavigatorClass;
              let checkme = tabNavigatorClass.indexOf("disable-nav");
              if (checkme === -1) {
                tabNavigatorClass.push("disable-nav");
              }
              this.setState({ tabNavigatorClass });
            }
            clearInterval(checkNavigtorTimer);
          }
        }, 100);
      } else {
        clearInterval(checkNavigtorTimer);
      }
    } catch (err) {
      clearInterval(checkNavigtorTimer);
    }
  };

  // Add Enable/Disble Class for Tabs on depending conditions

  enableDisableTab = element => {
    let activeTabno = element.getAttribute("data-tabno");
    let tabno = parseInt(activeTabno);
    if (tabno > 1) {
      let tabNavigatorClass = this.state.tabNavigatorClass;
      let checkme = tabNavigatorClass.indexOf("disable-nav");
      if (checkme === 1) {
        tabNavigatorClass.pop("disable-nav");
      }
      this.setState({ tabNavigatorClass });
    } else {
      let tabNavigatorClass = this.state.tabNavigatorClass;
      let checkme = tabNavigatorClass.indexOf("disable-nav");
      if (checkme === -1) {
        tabNavigatorClass.push("disable-nav");
      }
      this.setState({ tabNavigatorClass });
    }
  };

  renderSectionTabs = sections => {
    return sections.map((section, key) => (
      <div
        key={key}
        className={`tab section_${key % 2 ? "odd" : "even"} tab_${key}`}
        style={{
          paddingLeft: "40px",
          backgroundImage:
            "url(" +
            require(`../../../../../assets/img/icons/${
              this.state.sectionIcon[
                section.iconType === undefined || section.iconType === ""
                  ? "DEFAULT"
                  : section.iconType
              ]
            }`),
          backgroundSize: 20,
          backgroundRepeat: "no-repeat",
          backgroundPosition: 10
        }}
        onClick={e => {
          this.scrollTab(e);
        }}
        data-scrollheading="0"
        data-scrolltab="0"
      >
        {section.sectionName}
      </div>
    ));
  };

  render() {
    const { customerList } = this.props;
    const { getGridPos, isLoading, tabNavigatorClass } = this.state;

    return (
      <React.Fragment>
        <div className="grid-tabs">
          {this.renderSectionTabs(customerList[0].dataRecordSections)}
          <div className={`tab_last`} />
        </div>
        {isLoading && (
          <div
            className={tabNavigatorClass.join(" ")}
            // style={{
            //   right: `${10}px`,
            //   top: `${getGridPos.top}px`
            // }}
          >
            <span
              className="icon-chevron-thin-left"
              onClick={this.scrollLeft}
            />
            <span
              className="icon-chevron-thin-right"
              onClick={this.scrollRight}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default DataHeadTabs;
