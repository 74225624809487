import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

// import { Provider} from 'react-redux'
// import { createStore } from 'redux';
// import {reducers} from "./reducers";
import ADMIN, {
  CONTRIBUTOR,
  TECHNICAL,
  READER,
  NEWSFEED
} from "../../../routes/settings";



class Settings extends Component {
  componentWillUnmount() {
    // this.props.onContextChange(null);
    this.props.onContextIdChange(null);
  }

  redirectPage = () => {
    let currentUser = JSON.parse(sessionStorage.getItem("selectedMembers"));
    switch (currentUser.membershipType) {
      case "NEWSFEED":
      case "READER":
        return <Redirect from="/settings" to="/settings/myprofile" />;
        break;
      case "CONTRIBUTOR":
      case "TECHNICAL":
        return <Redirect from="/settings" to="/settings/tracking" />;
        break;

      default:
        return <Redirect from="/settings" to="/settings/users" />;
        break;
    }
  };

  render() {
    let currentUser = JSON.parse(sessionStorage.getItem("selectedMembers"));
    let pageRouter = ADMIN;
    switch (currentUser.membershipType) {
      case "CONTRIBUTOR":
        pageRouter = CONTRIBUTOR;
        break;
      case "TECHNICAL":
        pageRouter = TECHNICAL;
        break;
      case "READER":
        pageRouter = READER;
        break;
      case "NEWSFEED":
        pageRouter = NEWSFEED;
        break;
      default:
        pageRouter = ADMIN;
        break;
    }

    return (
      <Switch>
        {pageRouter.map((route, idx) => {
          return route.component ? (
            <Route
              key={idx}
              path={route.path}
              exact={route.exact}
              name={route.name}
              render={props => (
                <route.component
                  contextUser={this.props.contextUser}
                  updateContextUser={this.props.updateContextUser}
                  onCheckChange={this.props.onCheckChange}
                  onVerifyChange={this.props.onVerifyChange}
                  onContextChange={this.props.onContextChange}
                  onContextIdChange={this.props.onContextIdChange}
                  {...props}
                />
              )}
            />
          ) : null;
        })}
        {this.redirectPage()}
      </Switch>
    );
  }
}

export default Settings;
