import React from "react";
import "./styles.scss";
import CountValue from "./CountValue";

export function capitalizeTheFirstLetterOfEachWord(words) {
    let separateWord = words.toLowerCase().split(' ');
    for (let i = 0; i < separateWord.length; i++) {
        separateWord[i] = separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
    }
    return separateWord.join(' ')
}

const FunnelStage = ({funnelMetric, funnelMetric_formatted}) => {

    const isSkipped = (attr) => {
        if(attr?.funnelStageAttributionChannel === 'TOTAL') {
            return false;
        }
        if(attr?.funnelStageAttributionChannel === 'DIRECT_UNKNOWN') {
            return false;
        }
        return true;
    }

    return (
        <>
            <div className="col-10 ps-md-0 marketing-attributed">
                <div>
                    <div className="mess">
                        <p className="char color-706">Marketing Attributed</p>
                        <p className="num color-706">
                            {funnelMetric_formatted?.marketingAttributedCustomerCount_formatted}

                        </p>
                    </div>
                </div>

                <div className="analysis">
                    {funnelMetric?.funnelStageAttributions
                            ?.filter(item => isSkipped(item) === true)
                            .map((data, index) => (
                        <div key={index} className="col fix-pad fix-mar padding-right-02em">
                            <div className="box">
                                <p className="social color-706 text-center display-tablecell-verticalbottom padding-bottom-05em"
                                   style={{
                                       height: '40px',
                                       width: '55px'
                                   }}>{capitalizeTheFirstLetterOfEachWord(data?.funnelStageAttributionChannel.replace("_", " "))}</p>
                                <div className="per-20-3 flex-column">
                                    <div className="per-20 color-c4">
                                        {funnelMetric_formatted?.funnelStageAttributions_formatted[index].attributionPercent_formatted}
                                    </div>
                                    <div className="pers">
                                        {data.deltaToPrevious < 0 ?
                                            <svg width="11" height="9" viewBox="0 0 11 9"
                                                 fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.45538 9L0.157987 0.750001L10.7528 0.75L5.45538 9Z"
                                                    fill="#ED6A5E"/>
                                            </svg>
                                            : data.deltaToPrevious === 0 ?
                                                <svg width="9" height="13" viewBox="0 0 9 13"
                                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M9 6.5L0.749999 12.1292L0.75 0.870834L9 6.5Z"
                                                        fill="#B7B7B7"/>
                                                </svg> :
                                                <svg width="13" height="9" viewBox="0 0 13 9"
                                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.5 0L12.1292 8.25H0.870835L6.5 0Z"
                                                          fill="#9FCE1D"/>
                                                </svg>
                                        }
                                        <div className="per-3 color-706">
                                            {funnelMetric_formatted?.funnelStageAttributions_formatted[index].deltaToPrevious_formatted}
                                        </div>
                                    </div>
                                </div>
                                <svg className="arrow color-c4" width="23" height="32"
                                     viewBox="0 0 23 32" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M10.2694 31.0607C10.8552 31.6464 11.8049 31.6464 12.3907 31.0607L21.9367 21.5147C22.5224 20.9289 22.5224 19.9792 21.9367 19.3934C21.3509 18.8076 20.4011 18.8076 19.8153 19.3934L11.33 27.8787L2.84477 19.3934C2.25898 18.8076 1.30923 18.8076 0.723447 19.3934C0.13766 19.9792 0.13766 20.9289 0.723447 21.5147L10.2694 31.0607ZM9.83005 6.96736e-08L9.83005 30L12.83 30L12.83 -6.96736e-08L9.83005 6.96736e-08Z"
                                        fill="#C4C4C4"/>
                                </svg>
                            </div>
                        </div>
                    ))}

                </div>
            </div>

            <div className="col-2 parent-v-split">
                <div className="v-split"/>

                <div className="direct">
                    <div className="mess">
                        <p className="char color-706">Direct / Unknown</p>
                        <p className="num color-706">
                            <span className="num color-706">{funnelMetric_formatted?.directUnknownCount_formatted}</span>
                        </p>
                    </div>
                </div>
                <div className="">
                    <div className="box">
                        <div className="pers ">
                            {funnelMetric?.unknownCustomerCountDelta < 0 ?
                                <svg width="11" height="9" viewBox="0 0 11 9" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.45538 9L0.157987 0.750001L10.7528 0.75L5.45538 9Z"
                                          fill="#ED6A5E"/>
                                </svg>
                                : funnelMetric?.unknownCustomerCountDelta === 0 ?
                                    <svg width="9" height="13" viewBox="0 0 9 13" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 6.5L0.749999 12.1292L0.75 0.870834L9 6.5Z"
                                              fill="#B7B7B7"/>
                                    </svg> :
                                    <svg width="13" height="9" viewBox="0 0 13 9" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.5 0L12.1292 8.25H0.870835L6.5 0Z"
                                              fill="#9FCE1D"/>
                                    </svg>
                            }
                            <p className="per-3 color-706">
                                {funnelMetric_formatted?.unknownCustomerCountDelta_formatted}
                            </p>
                        </div>
                        <svg className="arrow color-c4" width="23" height="32" viewBox="0 0 23 32"
                             fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M10.2694 31.0607C10.8552 31.6464 11.8049 31.6464 12.3907 31.0607L21.9367 21.5147C22.5224 20.9289 22.5224 19.9792 21.9367 19.3934C21.3509 18.8076 20.4011 18.8076 19.8153 19.3934L11.33 27.8787L2.84477 19.3934C2.25898 18.8076 1.30923 18.8076 0.723447 19.3934C0.13766 19.9792 0.13766 20.9289 0.723447 21.5147L10.2694 31.0607ZM9.83005 6.96736e-08L9.83005 30L12.83 30L12.83 -6.96736e-08L9.83005 6.96736e-08Z"
                                fill="#C4C4C4"/>
                        </svg>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FunnelStage;