import React, {Component} from "react";
import {Button, Col, Form, ListGroup, Modal, Table} from "react-bootstrap";
import {Select} from "react-inputs-validation";
import DatePicker from "react-datepicker";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";
import { calculatedValues, customerTraitTypes, orderStatuses, defaultOrderStatus, defaultTraitType} from "../../../../../variables/globalValues.json";
import ValidationMessages from "./validationMessages";
import {getPluralEnding} from "../../customerdata/segmentUtils";
import PropTypes from "prop-types";
import {debounce} from "lodash";
import TraitAPI from "../../../../../services/traits";
import ProgressBar from "react-bootstrap/ProgressBar";
import {toHumanReadableFormat} from "../traitUtils";

const VALIDATION_KEY_TRAIT_RULE_TYPE = "traitRuleType";
const VALIDATION_KEY_ORDER_STATUS = "orderStatus";
const VALIDATION_KEY_DATE_RANGE = "ruleDateRange";
const VALIDATION_KEY_NUMBER_RANGE = "ruleNumberRange";
const VALIDATION_KEY_PRODUCT_ID = "ruleProductId";

const pageSize = 20;

const activeProductFilterTypes = [
  {id: "ACTIVE", name: "Active Products"},
  {id: "INACTIVE", name: "Inactive Products"}
];

const propTypes = {
  rule: PropTypes.object.isRequired,
  orderPaymentStatuses: PropTypes.array.isRequired,
  editable: PropTypes.bool.isRequired,
  ruleIndex: PropTypes.number.isRequired,
  groupIndex: PropTypes.number.isRequired,
  onRuleSave: PropTypes.func.isRequired,
  onDeleteRule: PropTypes.func.isRequired,
};

const initialState = {
  editRule: false,
  traitRuleType: "",
  orderStatus: "",
  fromDate: null,
  toDate: null,
  numberOfPurchaseFrom: "",
  numberOfPurchaseTo: "",
  currentProductId: "",
  currentProductName: "",

  validation: {
    failed: true,
    messages: {}
  },

  showProductSearch: false,
  isProductSearchLoading: false,
  productSearchResult: [],
  productSearchTerm: "",
  productSearchActiveFilter: "ACTIVE",

  loadMore: true,
  isEndOfData: false,
};

class Rule extends Component {

  constructor(props) {
    super(props);

    this.state = initialState;
    this._timeout = null;
  }

  componentWillMount() {
     this.setState({
       traitRuleType: this.props.rule.traitRuleType || defaultTraitType,
       fromDate: this.props.rule.ruleConditions?.fromDate,
       toDate: this.props.rule.ruleConditions?.toDate,
       numberOfPurchaseFrom: this.props.rule.ruleConditions?.numberOfPurchaseFrom || "",
       numberOfPurchaseTo: this.props.rule.ruleConditions?.numberOfPurchaseTo || "",
       currentProductId: this.props.rule.targetId || "",
       currentProductName: this.props.rule.targetName || (this.props.rule.targetId ? "<Missing product name>" : ""),
       orderStatus: this.props.rule.ruleConditions?.orderStatus || defaultOrderStatus,
       new:this.props.rule.new
    });
     if(this.state.editRule) {
       this.validate();
     }
  }

  componentWillUnmount() {
    clearTimeout(this._timeout);
  }

  componentWillReceiveProps( prevProps){

  }

  onEdit = () => {
    this.setState({ editRule: true, validation: { failed: false, messages: {} } }, this.validate);
  };
  onCancel = (ruleIndex, groupIndex) => {
    if ( this.state.new ) {
      this.props.onDeleteRule(ruleIndex, groupIndex);
    } else {
      this.setState({
        currentProductId: this.props.rule.targetId,
        currentProductName: this.props.rule.targetName,
        traitRuleType: this.props.rule.traitRuleType,
        fromDate: this.props.rule.ruleConditions.fromDate,
        toDate: this.props.rule.ruleConditions.toDate,
        numberOfPurchaseFrom: this.props.rule.ruleConditions.numberOfPurchaseFrom,
        numberOfPurchaseTo: this.props.rule.ruleConditions.numberOfPurchaseTo,
        orderStatus: this.props.rule.ruleConditions.orderStatus,
        editRule: false,
        new: this.props.rule.new,
      });
    }
  };

  onSave = () => {
    this.setState({ editRule: false });

    let rule = {
      targetId: this.state.currentProductId,
      targetName: this.state.currentProductName,
      traitRuleType: this.state.traitRuleType,
      ruleConditions: {
        ruleType: this.state.traitRuleType,
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
        numberOfPurchaseFrom: this.state.numberOfPurchaseFrom,
        numberOfPurchaseTo: this.state.numberOfPurchaseTo,
        orderStatus: this.state.orderStatus === defaultOrderStatus ? null : this.state.orderStatus,
      },
      new:false
    };
    let ruleIndex = this.props.ruleIndex;
    let groupIndex = this.props.groupIndex;
    this.props.onRuleSave(rule, groupIndex, ruleIndex);
  };


  handleFromDateChange = (date) => {
    // const fieldValue = moment(date).format('DD/MM/YYYY');
    this.setState({ fromDate: date}, this.validate);
  }

  handleToDateChange = (date) => {
    this.setState({ toDate: date }, this.validate);
  }

  validate = () => {
    const { validation } = this.state;
    validation.messages = {};

    if(this.state.traitRuleType === "") {
      validation.messages[VALIDATION_KEY_TRAIT_RULE_TYPE] = "Please select a trait type";
    }

    if(this.state.orderStatus === "") {
      validation.messages[VALIDATION_KEY_ORDER_STATUS] = "Please select an order status";
    }

    if(this.state.fromDate !== null && this.state.toDate !== null && this.state.fromDate > this.state.toDate) {
      validation.messages[VALIDATION_KEY_DATE_RANGE] = "'From' date cannot be greater than 'To' date";
    }

    if(this.state.currentProductId === "") {
      validation.messages[VALIDATION_KEY_PRODUCT_ID] = "Please select a product";
    }

    const numberMessages = [];
    const numberFrom = parseInt(this.state.numberOfPurchaseFrom);
    const numberTo = parseInt(this.state.numberOfPurchaseTo);
    if(this.state.numberOfPurchaseFrom) {
      if(isNaN(numberFrom)) {
        numberMessages.push("'From' number of purchase must be a number");
      } else if(numberFrom < 0) {
        numberMessages.push("'From' number of purchase cannot be less than 0");
      }
    }
    if(this.state.numberOfPurchaseTo) {
      if(isNaN(numberTo)) {
        numberMessages.push("'To' number of purchase must be a number");
      } else if(numberTo < 0) {
        numberMessages.push("'To' number of purchase cannot be less than 0");
      }
    }
    if(numberMessages.length === 0 && numberFrom > numberTo) {
      numberMessages.push("'From' number of purchase cannot be greater than 'To' number of purchase");
    }

    if(numberMessages.length > 0) {
      validation.messages[VALIDATION_KEY_NUMBER_RANGE] = numberMessages.join(", ");
    }

    validation.failed = Object.keys(validation.messages).length > 0;

    this.setState({ validation });
  };

  isTraitRuleTypeValidationFailed = () => {
    const { validation } = this.state;
    return validation.failed && validation.messages[VALIDATION_KEY_TRAIT_RULE_TYPE];
  }

  isOrderStatusValidationFailed = () => {
    const { validation } = this.state;
    return validation.failed && validation.messages[VALIDATION_KEY_ORDER_STATUS];
  }

  isProductValidationFailed = () => {
    const { validation } = this.state;
    return validation.failed && validation.messages[VALIDATION_KEY_PRODUCT_ID];
  }

  isDateRangeValidationFailed = () => {
    const { validation } = this.state;
    return validation.failed && validation.messages[VALIDATION_KEY_DATE_RANGE];
  }

  isNumberRangeValidationFailed = () => {
    const { validation } = this.state;
    return validation.failed && validation.messages[VALIDATION_KEY_NUMBER_RANGE];
  }

  getValidationMessages = () => {
    const { validation } = this.state;
    return validation.failed && Object.keys(validation.messages).map(key => validation.messages[key]).join("\n");
  }
  handleNumberOfPurchaseFromChange = (e) => {
    this.setState({ numberOfPurchaseFrom: e.target.value }, this.validate);
  }

  handleNumberOfPurchaseToChange = (e) => {
    this.setState({ numberOfPurchaseTo: e.target.value }, this.validate);
  }

  renderTraitTypeOptions() {
    return customerTraitTypes;
  }

  renderOrderStatusOptions() {
    const { orderPaymentStatuses } = this.props;
    return [...orderStatuses, ...orderPaymentStatuses.map((e, i) => ({ id: e, name: toHumanReadableFormat(e) }))];
  }

  getTagOptionList(attributeDataTag) {
    return calculatedValues[attributeDataTag || "ERROR"];
  }

  handleCloseProducts() {
    this.setState({ showProductSearch: false });
  }

  handleShowProducts() {
    const { currentProductId } = this.state;

    this.setState({
      showProductSearch: true,
      productSearchTerm: currentProductId,
      isProductSearchLoading: true,
      isEndOfData: false
    }, () => {
      if (this.searchInput) {
        this.searchInput.focus();
        this.searchInput.value = currentProductId;
      }
      this.raiseDoSearchWhenUserStoppedTyping.cancel();
      this.raiseDoSearchWhenUserStoppedTyping();
    });
  }

  handleTraitRuleTypeChange = (traitRuleType, e) => {
    this.setState({ traitRuleType }, this.validate);
  };

  handleOrderStatusChange = (orderStatus, e) => {
    const statusToSet = orderStatus === "" || orderStatus === String(null) ? null : orderStatus;
    this.setState({ orderStatus: statusToSet }, this.validate);
  };

  renderDatesRange = () => {
    const { fromDate, toDate } = this.state;
    if(fromDate && toDate) {
      if(fromDate === toDate) {
        return `On the date ${moment(fromDate).format("DD/MM/YYYY")}`;
      }
      return `Between the dates ${moment(fromDate).format("DD/MM/YYYY")} and ${moment(toDate).format("DD/MM/YYYY")}`;
    } else if(fromDate) {
      return `On or After the date ${moment(fromDate).format("DD/MM/YYYY")}`;
    } else if(toDate) {
      return `On or Before the date ${moment(toDate).format("DD/MM/YYYY")}`;
    }
    return '';
  }

  renderNumberOfPurchaseRange = () => {
    const { numberOfPurchaseFrom, numberOfPurchaseTo } = this.state;
    if(numberOfPurchaseFrom && numberOfPurchaseTo) {
      if(numberOfPurchaseFrom === numberOfPurchaseTo) {
        return `Exactly ${numberOfPurchaseFrom} time${getPluralEnding(numberOfPurchaseFrom)}`;
      }
      return `Between ${numberOfPurchaseFrom} and ${numberOfPurchaseTo} times`;
    } else if(numberOfPurchaseFrom) {
      return `Greater than or equal to ${numberOfPurchaseFrom} time${getPluralEnding(numberOfPurchaseFrom)}`;
    } else if(numberOfPurchaseTo) {
      return `Less than or equal to ${numberOfPurchaseTo} time${getPluralEnding(numberOfPurchaseTo)}`;
    }
    return '';
  }

  doSearchParams = async (searchWhat, active) => {
    try {
      const search = {term: searchWhat, pageSize: pageSize, available: active};
      await TraitAPI.searchProducts(search).then(response => {
        const isEndOfData = response.products.length < pageSize;
        this.setState({
          productSearchResult: response.products,
          productSearchScrollId: response.scrollId,
          isProductSearchLoading: false,
          isEndOfData: isEndOfData,
          loadMore: false,
        }, () => {if(!isEndOfData) { this.subscribeScrollEvents() }});
      });
    } catch (err) {}
  };

  doSearch = async () => {
    const { productSearchTerm, productSearchActiveFilter } = this.state;
    this.setState({
      isProductSearchLoading: true,
    });
    await this.doSearchParams(productSearchTerm, productSearchActiveFilter && productSearchActiveFilter === "ACTIVE");
  }

  doScrollSearch = async () => {
    try {
      const { productSearchScrollId } = this.state;
      const search = {scrollId: productSearchScrollId};
      await TraitAPI.searchProducts(search).then(response => {
        const isEndOfData = response.products.length < pageSize;
        this.setState(prevState => ({
          productSearchResult: [...prevState.productSearchResult, ...response.products],
          productSearchScrollId: response.scrollId,
          isProductSearchLoading: false,
          isEndOfData: isEndOfData,
          loadMore: false,
        }), () => {if(isEndOfData) { this.unsubscribeScrollEvents() }});
      });
    } catch (err) {}
  };

  raiseDoSearchWhenUserStoppedTyping = debounce(() => {
    this.doSearch();
  }, 500);

  onSearchStart = e => {
    this.setState({ productSearchTerm: e.currentTarget.value, isProductSearchLoading: true}, () => {
      this.raiseDoSearchWhenUserStoppedTyping();
    });
  };

  handleSelectProduct = (product) => {
    this.setState({
      currentProductId: product.externalId,
      currentProductName: product.name,
      showProductSearch: false
    }, this.validate);
  }

  handleActiveProductFilterChange = (filter) => {
    this.setState({ productSearchActiveFilter: filter }, this.raiseDoSearchWhenUserStoppedTyping )
  }

  renderProductsList = () => {

    const { productSearchResult, isProductSearchLoading } = this.state;
    if (isProductSearchLoading) {
      return null;
    }

    return (
        <Table>
          <thead>
            <tr>
              <th className="id">ID</th>
              <th className="product-name">Product Name</th>
              <th className="product-categories">Product Categories</th>
            </tr>
          </thead>
          <tbody>
          {productSearchResult.length > 0 ? (
              productSearchResult.map((item, index) => {
                const categories = item.categories ? item.categories.join(", ") : '';
                return (
                    <tr key={index}
                        onClick={() => this.handleSelectProduct(item)}>
                      <td className="id"
                          title={item.externalId}>{item.externalId}</td>
                      <td className="product-name"
                          title={item.name}>{item.name}</td>
                      <td className="product-categories" title={categories}>{categories}</td>
                </tr>
                )
              })
          ) : (
              <tr>
                <td colSpan={3}>No results found</td>
              </tr>
          )}
          </tbody>
        </Table>
    );
  }

  loadMore = () => {
    if (!this.state.isProductSearchLoading && !this.state.loadMore && !this.state.isEndOfData) {
      this.setState({ loadMore: true }, () => this.doScrollSearch());
    }
  };

  subscribeScrollEvents = () => (
    this.productSearchResults?.parentElement?.addEventListener("scroll", this.checkOnScrolling, false)
);
  unsubscribeScrollEvents = () => (
    this.productSearchResults?.parentElement?.removeEventListener("scroll", this.checkOnScrolling)
);

  checkOnScrolling = () => {
    clearTimeout(this._timeout);
    this._timeout = setTimeout(() => {
      // Run the callback
      const element = this.productSearchResults?.parentElement;
      const scrollTop = element.scrollTop;
      const scrollHeight = element.scrollHeight;
      const offsetHeight = element.offsetHeight;
      const contentHeight = scrollHeight - offsetHeight;
      if (contentHeight <= scrollTop) {
        this.loadMore();
      }
    }, 66);
  };

  render() {
    const {
      rule,
      onDeleteRule,
      groupIndex,
      ruleIndex,
      editable,
    } = this.props;
    const {
      editRule,
      traitRuleType,
      orderStatus,
      fromDate,
      toDate,
      numberOfPurchaseFrom,
      numberOfPurchaseTo,
      currentProductName,
      validation,
      loadMore,
      isProductSearchLoading
    } = this.state;


    if (!editRule && !rule.new) {
      return (
        <ListGroup.Item as="li">
          <div className="trait-rule-view">
            <p className="m-0">
              <span> Purchased state of '</span>
              <strong>{orderStatus && orderStatus !== defaultOrderStatus ? (toHumanReadableFormat(orderStatus)) : 'Any Status'}</strong>
              <span>' for </span>
              <strong>{currentProductName}</strong>
            </p>
            <p className="m-0 date-range-view">
              <span>{this.renderDatesRange()}</span>
            </p>
            <p className="m-0 num-range-view">
              <span>{this.renderNumberOfPurchaseRange()}</span>
            </p>
          </div>

          <dl className="d-flex ml-auto mb-0 section-icon">
            <dt>
              <Button variant="secondary" hidden={!editable} onClick={() => this.onEdit()}>
                <i className="icon-edit-light l-edit" />
              </Button>
            </dt>
            <dd>
              <Button
                variant="secondary"
                hidden={!editable}
                onClick={() => onDeleteRule(ruleIndex, groupIndex)}
              >
                <i className="icon-delete-light l-delete" />
              </Button>
            </dd>
          </dl>
        </ListGroup.Item>
      );
    } else {
      return (
        <ListGroup.Item as="li" className="bg-light">
          <div className="mr-3">
            <Form.Row>
              <Form.Group as={Col} className={"d-flex " + (this.isTraitRuleTypeValidationFailed() ? "validation-error" : "")} >
                <Select
                  tabIndex="4"
                  id={VALIDATION_KEY_TRAIT_RULE_TYPE}
                  name={VALIDATION_KEY_TRAIT_RULE_TYPE}
                  value={traitRuleType || ""}
                  disabled={false}
                  optionList={this.renderTraitTypeOptions()}
                  classNameSelect="select-control"
                  classNameWrapper="form-control select-control-wrapper"
                  classNameContainer=""
                  classNameOptionListContainer="select-control-option"
                  classNameOptionListItem=""
                  customStyleSelect={{}}
                  customStyleWrapper={{}}
                  customStyleContainer={{}}
                  customStyleOptionListContainer={{}}
                  customStyleOptionListItem={{}}
                  onChange={(traitRuleType, e) =>
                    this.handleTraitRuleTypeChange(traitRuleType, e)
                  }
                  onBlur={() => {}}
                  validationOption={{
                    name: "Customer Trait Type",
                    check: true,
                    required: true
                  }}
                />
              </Form.Group>

              <Form.Group as={Col} className={"d-flex " + (this.isOrderStatusValidationFailed() ? "validation-error" : "")} >
                <Form.Text>Of</Form.Text>
                <Select
                    tabIndex="4"
                    label={"Of"}
                    id={VALIDATION_KEY_ORDER_STATUS}
                    name={VALIDATION_KEY_ORDER_STATUS}
                    value={orderStatus || ""}
                    disabled={false}
                    optionList={this.renderOrderStatusOptions()}
                    classNameSelect="select-control"
                    classNameWrapper="form-control select-control-wrapper order-payment-status-select"
                    classNameContainer=""
                    classNameOptionListContainer="select-control-option"
                    classNameOptionListItem=""
                    customStyleSelect={{}}
                    customStyleWrapper={{}}
                    customStyleContainer={{}}
                    customStyleOptionListContainer={{}}
                    customStyleOptionListItem={{}}
                    onChange={(orderStatus, e) =>
                        this.handleOrderStatusChange(orderStatus, e)
                    }
                    onBlur={() => {}}
                    validationOption={{
                      name: "Order Status",
                      check: false,
                      required: true
                    }}
                />
              </Form.Group>

              <Form.Group as={Col} className={"d-flex " + (this.isProductValidationFailed() ? "validation-error" : "")} >
                <Form.Text>For</Form.Text>
                <Form.Control
                    className="product-name"
                    type="text"
                    readOnly
                    placeholder="Please Select a Product"
                    value={ this.state.currentProductName || ""}
                    onClick={() => this.handleShowProducts()}
                />
                <Button
                    onClick={() => this.handleShowProducts()}
                    className="btn btn-default"
                >
                  <i className="icon-dots-three-horizontal" />
                </Button>
              </Form.Group>
            </Form.Row>


            <Form.Row className={"no-grow " + (this.isDateRangeValidationFailed() ? "validation-error" : "")}>
              <Form.Group as={Col} className="d-flex range-text">
                <Form.Text>Within Date Range:</Form.Text>
              </Form.Group>
              <Form.Group as={Col} className="d-flex">
                <Form.Text>From</Form.Text>
                <DatePicker
                    wrapperClassName="date-range-input"
                    selected={fromDate}
                    onChange={this.handleFromDateChange}
                    dateFormat="dd/MM/yyyy"

                />
              </Form.Group>
              <Form.Group as={Col} className="d-flex">
                <Form.Text>To</Form.Text>
                <DatePicker
                    wrapperClassName="date-range-input"
                    selected={toDate}
                    onChange={this.handleToDateChange}
                    dateFormat="dd/MM/yyyy"

                />
              </Form.Group>
              <Form.Group as={Col} className="d-flex">
                <ValidationMessages show={this.isDateRangeValidationFailed()}
                                    message={validation.messages[VALIDATION_KEY_DATE_RANGE]} />
              </Form.Group>
            </Form.Row>


            <Form.Row className={"no-grow " + (this.isNumberRangeValidationFailed() ? "validation-error" : "")}>
              <Form.Group as={Col} className="d-flex range-text">
                <Form.Text>Number of Times:</Form.Text>
              </Form.Group>
              <Form.Group as={Col} className="d-flex">
                <Form.Text>From</Form.Text>
                <Form.Control
                    className="num-range-input"
                    type="text"
                    value={numberOfPurchaseFrom}
                    onChange={this.handleNumberOfPurchaseFromChange}
                />
              </Form.Group>
              <Form.Group as={Col} className="d-flex">
                <Form.Text>To</Form.Text>
                <Form.Control
                    className="num-range-input"
                    type="text"
                    value={numberOfPurchaseTo}
                    onChange={this.handleNumberOfPurchaseToChange}
                />
              </Form.Group>
              <Form.Group as={Col} className="d-flex">
                <ValidationMessages show={this.isNumberRangeValidationFailed()}
                                    message={validation.messages[VALIDATION_KEY_NUMBER_RANGE]} />
              </Form.Group>
            </Form.Row>
          </div>

          <dl className="d-flex ml-auto mb-0 section-icon">
            <dt>
              <Button
                variant="secondary"
                disabled={validation.failed}
                onClick={() => this.onSave()}
                title={this.getValidationMessages()}
              >
                {validation.failed && <i className="icon-disable" />}
                <strong className="save">Save</strong>
              </Button>
            </dt>
            <dd>
              <Button
                variant="secondary"
                onClick={() => this.onCancel(ruleIndex, groupIndex)}
              >
                Cancel
              </Button>
            </dd>
          </dl>
          <Modal
              show={this.state.showProductSearch}
              className="select-product"
              // onShow={this.handleShow}
              onHide={() => this.handleCloseProducts()}
          >
            <Modal.Header closeButton>
              <Modal.Title>Search & Select Product</Modal.Title>
              <div className="search position-relative">
                <span className="icon-search" />
                <Form.Control
                    ref={input => { this.searchInput = input; }}
                    type="text"
                    tabIndex="1"
                    onChange={this.onSearchStart}
                    placeholder="Search here..."
                    value={this.state.productSearchTerm}
                />
              </div>
              <div className="search-filters">
                <label>
                  Apply filters to search results
                  <Select
                      tabIndex="2"
                      id={"productSearchActiveFilter"}
                      name={"productSearchActiveFilter"}
                      value={this.state.productSearchActiveFilter}
                      disabled={false}
                      optionList={activeProductFilterTypes}
                      classNameSelect="select-control"
                      classNameWrapper="form-control select-control-wrapper"
                      classNameContainer="dropdown-without-title"
                      classNameOptionListContainer="select-control-option"
                      classNameOptionListItem=""
                      customStyleSelect={{}}
                      customStyleWrapper={{}}
                      customStyleContainer={{}}
                      customStyleOptionListContainer={{}}
                      customStyleOptionListItem={{}}
                      onChange={e => this.handleActiveProductFilterChange(e)}
                  />
                </label>
              </div>
            </Modal.Header>
            <Modal.Body>
              <div
                  ref={input => { this.productSearchResults = input; }}
                  className="product-search-result-list">
                {this.renderProductsList()}
              </div>
              {(loadMore || isProductSearchLoading) && (
                  <div className="progress-full">
                    <ProgressBar
                        animated
                        variant="info"
                        now={100}
                        label="Fetching list..."
                    />
                  </div>
              )}
            </Modal.Body>
          </Modal>
        </ListGroup.Item>
      );
    }
  }
}

Rule.propTypes = propTypes;

export default Rule;
