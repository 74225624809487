import React, {Component} from "react";
import "./assets/css/styles.scss";
import ToggleButton from "./ToggleButton/ToggleButton";
import { Select } from "react-inputs-validation";
import FunnelMetricsApi from "../../../services/funnelMetrics";
import FunnelMetric from "./FunnelMetricsComponents/funnelFull/FunnelMetric";
import FunnelMetricMob from "./FunnelMetricsComponents/funnelMob/FunnelMetricMob";

export function monthName(mon) {
  return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][mon - 1];
}

class FunnelMetrics extends Component {
  constructor(props) {
    super(props);
    let selectedMembers = JSON.parse(sessionStorage.getItem("selectedMembers"));

    const periods = {
      left: {
        title: "Monthly",
        value: "month"
      },
      center: {
        title: "Quarterly",
        value: "quarter"
      },
      right: {
        title: "Yearly",
        value: "year"
      }
    };

    this.state = {
      selectedMembers: selectedMembers || {},
      selectedPeriodType: "month",
      periods: periods,

      leftFunnelSelectValue: "",
      leftFunnelSelectList: [
        { id: null, name: "Please Select Month" }
      ],

      rightFunnelSelectValue: "",
      rightFunnelSelectList: [
        { id: null, name: "Please Select Month" }
      ],

      leftFunnelSelectQuarterValue: "",
      leftFunnelSelectQuarterList: [
        { id: null, name: "Please Select Quarter" }
      ],

      rightFunnelSelectQuarterValue: "",
      rightFunnelSelectQuarterList: [
        { id: null, name: "Please Select Quarter" }
      ],

      leftFunnelSelectYearValue: "",
      leftFunnelSelectYearList: [
        { id: null, name: "Please Select Year" }
      ],

      rightFunnelSelectYearValue: "",
      rightFunnelSelectYearList: [
        { id: null, name: "Please Select Year" }
      ],

      leftCardData: {},
      rightCardData: {},
    };

    this.onChange = (value) => {
      this.resetSelectors(value);
      FunnelMetricsApi.abortRequestPeriod();
      this.setState({ selectedPeriodType: value});
    }
  }


  resetSelectors(period) {
    if(period === 'month') {
      this.buildMonthLeftFunnel(this.state.leftFunnelSelectValue);
      this.buildMonthRightFunnel(this.state.rightFunnelSelectValue);
    } else if (period === 'quarter') {
      this.buildQuarterLeftFunnel(this.state.leftFunnelSelectQuarterValue);
      this.buildQuarterRightFunnel(this.state.rightFunnelSelectQuarterValue);
    } else {
      this.buildYearLeftFunnel(this.state.leftFunnelSelectYearValue);
      this.buildYearRightFunnel(this.state.rightFunnelSelectYearValue);
    }
  }

  componentWillUnmount() {
    FunnelMetricsApi.clearQueue();
  }

  componentDidMount() {
    this.getData().then(r => {});
  }

  getData = async event => {
    try {
      await FunnelMetricsApi.getAvailableMonths().then(response => {
        const buildList = (data, list) => {
          data.forEach((item) => {
            list.push({"id": `${item[0]}-${item[1] < 10 ? "0"+item[1] : item[1]}`,
                        "name": `${monthName(item[1])} ${item[0]} Cohort`})
          });
          return list;
        }

        const leftFunnelSelectList = buildList(response, this.state.leftFunnelSelectList);
        const rightFunnelSelectList = buildList(response, this.state.rightFunnelSelectList);

        this.setState({
                        leftFunnelSelectList: leftFunnelSelectList,
                        rightFunnelSelectList: rightFunnelSelectList,
                        leftFunnelSelectValue: leftFunnelSelectList[2].id,
                        rightFunnelSelectValue: rightFunnelSelectList[1].id,
                      });

        this.buildMonthLeftFunnel(leftFunnelSelectList[2].id);
        this.buildMonthRightFunnel(rightFunnelSelectList[1].id);
      });

      await FunnelMetricsApi.getAvailableQuarters().then(response => {
        const buildList = (data, list) => {
          data.forEach((item) => {
            list.push({"id": `${item.yearMonth[0]}-${item.yearMonth[1] < 10 ? "0"+item.yearMonth[1] : item.yearMonth[1]}`,
                        "name": `${item.name}`})
          });
          return list;
        }

        const leftFunnelSelectQuarterList = buildList(response, this.state.leftFunnelSelectQuarterList);
        const rightFunnelSelectQuarterList = buildList(response, this.state.rightFunnelSelectQuarterList);

        this.setState({
                        leftFunnelSelectQuarterList: leftFunnelSelectQuarterList,
                        rightFunnelSelectQuarterList: rightFunnelSelectQuarterList,
                        leftFunnelSelectQuarterValue: leftFunnelSelectQuarterList[2].id,
                        rightFunnelSelectQuarterValue: rightFunnelSelectQuarterList[1].id,
                      });
      });

      await FunnelMetricsApi.getAvailableYears().then(response => {
        const buildList = (data, list) => {
          data.forEach((item) => {
            list.push({"id": `${item[0]}-${item[1] < 10 ? "0"+item[1] : item[1]}`,
                        "name": `${item[0]}`})
          });
          return list;
        }

        const leftFunnelSelectYearList = buildList(response, this.state.leftFunnelSelectYearList);
        const rightFunnelSelectYearList = buildList(response, this.state.rightFunnelSelectYearList);


        this.setState({
                        leftFunnelSelectYearList: leftFunnelSelectYearList,
                        rightFunnelSelectYearList: rightFunnelSelectYearList,
                        leftFunnelSelectYearValue: leftFunnelSelectYearList[2].id,
                        rightFunnelSelectYearValue: rightFunnelSelectYearList[1].id,
                      });
      });
    } catch (err) {
    }
  };

 cancelPreviousRequestMonthLeft(yearMonth) {
    FunnelMetricsApi.abortRequestConcreteFunnelMetricsLeft();
    this.buildMonthLeftFunnel(yearMonth);
 }

 cancelPreviousRequestMonthRight(yearMonth) {
    FunnelMetricsApi.abortRequestConcreteFunnelMetricsRight();
    this.buildMonthRightFunnel(yearMonth);
 }

  buildMonthLeftFunnel = (yearMonth) => {
    this.setState({ leftFunnelSelectValue: yearMonth });
    const build = async event => {
      try {
        await FunnelMetricsApi.buildForMonth(yearMonth, true).then(response => {
          this.setState({ leftCardData: response });
        });
      } catch (err) {
      }
    };
    build().then();
  }

  buildMonthRightFunnel = (yearMonth) => {
    this.setState({ rightFunnelSelectValue: yearMonth });
    const build = async event => {
      try {
        await FunnelMetricsApi.buildForMonth(yearMonth, false).then(response => {
          this.setState({ rightCardData: response });
        });
      } catch (err) {
      }
    };
    build().then();
  }

  cancelPreviousRequestQuarterLeft(yearMonth) {
    FunnelMetricsApi.abortRequestConcreteFunnelMetricsLeft();
    this.buildQuarterLeftFunnel(yearMonth);
  }

  cancelPreviousRequestQuarterRight(yearMonth) {
    FunnelMetricsApi.abortRequestConcreteFunnelMetricsRight();
    this.buildQuarterRightFunnel(yearMonth);
  }

  buildQuarterLeftFunnel = (yearMonth) => {
    this.setState({ leftFunnelSelectQuarterValue: yearMonth });
    const build = async event => {
      try {
        await FunnelMetricsApi.buildForQuarter(yearMonth, true).then(response => {
          this.setState({ leftCardData: response });
        });
      } catch (err) {
      }
    };
    build().then();
  }

  buildQuarterRightFunnel = (yearMonth) => {
    this.setState({ rightFunnelSelectQuarterValue: yearMonth });
    const build = async event => {
      try {
        await FunnelMetricsApi.buildForQuarter(yearMonth, false).then(response => {
          this.setState({ rightCardData: response });
        });
      } catch (err) {
      }
    };
    build().then();
  }

  replaceDateForYear(yearMonth) {
    return yearMonth.replace(/-.*/i, `-01`);
  }

  cancelPreviousRequestYearLeft(yearMonth) {
    FunnelMetricsApi.abortRequestConcreteFunnelMetricsLeft();
    this.buildYearLeftFunnel(yearMonth);
  }

  cancelPreviousRequestYearRight(yearMonth) {
    FunnelMetricsApi.abortRequestConcreteFunnelMetricsRight();
    this.buildYearRightFunnel(yearMonth);
  }

  buildYearLeftFunnel = (yearMonth) => {
    this.setState({ leftFunnelSelectYearValue: yearMonth });
    const build = async event => {
      try {
        await FunnelMetricsApi.buildForYear(this.replaceDateForYear(yearMonth), true).then(response => {
          this.setState({leftCardData: response });
        });
      } catch (err) {
      }
    };
    build().then();
  }

  buildYearRightFunnel = (yearMonth) => {
    this.setState({ rightFunnelSelectYearValue: yearMonth });
    const build = async event => {
      try {
        await FunnelMetricsApi.buildForYear(this.replaceDateForYear(yearMonth), false).then(response => {
          this.setState({rightCardData: response });
        });
      } catch (err) {
      }
    };
    build().then();
  }

  renderSelectors(type, isLeft) {
    function getSelect(type, value, list, action) {
      return (
              <Select
                      tabIndex="5"
                      id={"select_" + type}
                      name={"select_" + type}
                      value={ value || ""}
                      disabled={false}
                      optionList={list || []}
                      classNameSelect={"select-control"}
                      classNameWrapper={"form-control select-control-wrapper"}
                      classNameContainer=""
                      classNameOptionListContainer={"select-control-option"}
                      classNameOptionListItem=""
                      customStyleSelect={{ height: "46px"}}
                      customStyleWrapper={{ width: "250px", height: "46px"}}
                      customStyleContainer={{}}
                      customStyleOptionListContainer={{}}
                      customStyleOptionListItem={{}}
                      onChange={(yearMonth, e) => action.call(this, yearMonth)}
              />
      );
    }

    if(isLeft) {
      return ( <>
                {type === 'month' ? getSelect.call(this, "month_left", this.state.leftFunnelSelectValue, [...this.state.leftFunnelSelectList], this.cancelPreviousRequestMonthLeft) : <></>}
                {type === 'quarter' ? getSelect.call(this, "quarter_left", this.state.leftFunnelSelectQuarterValue, [...this.state.leftFunnelSelectQuarterList], this.cancelPreviousRequestQuarterLeft): <></>}
                {type === 'year' ? getSelect.call(this, "year_left", this.state.leftFunnelSelectYearValue, [...this.state.leftFunnelSelectYearList], this.cancelPreviousRequestYearLeft): <></>}
              </>
      )
    } else {
      return ( <>
                {type === 'month' ? getSelect.call(this, "month_right",this.state.rightFunnelSelectValue, [...this.state.rightFunnelSelectList], this.cancelPreviousRequestMonthRight): <></>}
                {type === 'quarter' ? getSelect.call(this, "quarter_right", this.state.rightFunnelSelectQuarterValue, [...this.state.rightFunnelSelectQuarterList], this.cancelPreviousRequestQuarterRight): <></>}
                {type === 'year' ? getSelect.call(this, "year_right", this.state.rightFunnelSelectYearValue, [...this.state.rightFunnelSelectYearList], this.cancelPreviousRequestYearRight): <></>}
              </>
      )
    }
  }

  render() {
    return (
        <div className="funnel-metrics-wrap bg-white ">
          <div className="content-width new-top ">
            <div id="period-selector">
              <ToggleButton labels={this.state.periods} onChange={this.onChange} />
            </div>
            <div id="work-area-full" className="display-none-max1600">
              <div id="work-area-full-left" className="funnel-block">
                {this.renderSelectors(this.state.selectedPeriodType, true)}
                <br/>
                <div className="funnel-block-content">
                  {this.state.leftCardData.cardData ? <FunnelMetric cardData={{data: this.state.leftCardData.cardData}} /> : <></>}
                </div>
              </div>
              <div id="work-area-full-right" className="funnel-block">
                {this.renderSelectors(this.state.selectedPeriodType, false)}
                <br/>
                <div className="funnel-block-content">
                  {this.state.rightCardData.cardData ? <FunnelMetric cardData={{data: this.state.rightCardData.cardData}} /> : <></>}
                </div>
              </div>
            </div>
            <div id="work-area-full" className="display-none-min1600 display-none-min1040">
              <div id="work-area-full-left" className="funnel-block" style={{width: "100%"}}>
                {this.renderSelectors(this.state.selectedPeriodType, false)}
                <br/>
                <div className="funnel-block-content">
                  {this.state.rightCardData.cardData ? <FunnelMetric cardData={{data: this.state.rightCardData.cardData}} /> : <></>}
                </div>
              </div>
            </div>
          <div id="work-area-full" className="display-none-min1600 display-none-max1040">
              <div id="work-area-full-left" className="funnel-block" style={{width: "100%"}}>
                {this.renderSelectors(this.state.selectedPeriodType, false)}
                <br/>
                <div className="funnel-block-content">
                  {this.state.rightCardData.cardData ? <FunnelMetricMob cardData={{data: this.state.rightCardData.cardData}} /> : <></>}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  }
}

export default FunnelMetrics;
