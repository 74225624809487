import React, {Component} from "react";
import CustomersAPI from "../../../../../services/customers";
import DataGrid from "../../common/DataGrid";
import PropTypes from "prop-types";

const propTypes = {
  segmentId: PropTypes.number.isRequired,
  dataViewId: PropTypes.number,
  customerList: PropTypes.array.isRequired,
  isUpdateState: PropTypes.bool.isRequired,
  onCustomerDetail: PropTypes.func.isRequired,
};

class SegmentCustomersDataGrid extends Component {

  getCustomerList = (formData) => {
    return CustomersAPI.getCustomerList({
      segmentId: formData.targetId,
      dataViewId: formData.dataViewId,
      pageNo: formData.pageNo
    });
  }

  getCustomerListMobile = (formData) => {
    return CustomersAPI.getCustomerListMobile({
      segmentId: formData.targetId,
      dataViewId: formData.dataViewId,
      pageNo: formData.pageNo
    });
  }

  render() {
    const {segmentId, dataViewId, isUpdateState, onCustomerDetail, customerList} = this.props;
    return (
        <DataGrid
            targetId={segmentId}
            dataViewId={dataViewId}
            isUpdateState={isUpdateState}
            customerList={customerList}
            onCustomerDetail={onCustomerDetail}
            getCustomerList={this.getCustomerList}
            getCustomerListMobile={this.getCustomerListMobile}
            emptyTitle="This Segment is empty."
        />
    );
  }
}

SegmentCustomersDataGrid.propTypes = propTypes;

export default SegmentCustomersDataGrid;
