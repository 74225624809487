import React, {Component} from "react";
import {ListGroup} from "react-bootstrap";
import DEFAULT from "../../../../../assets/img/icons/iconDefault.png";
import REDSHIFT from "../../../../../assets/img/icons/REDSHIFT.png";
import POSTGRESQL from "../../../../../assets/img/icons/POSTGRESQL.ico";
import MYSQL from "../../../../../assets/img/icons/MYSQL.ico";
import SQLSERVER from "../../../../../assets/img/icons/SQLSERVER.png";
import MAILCHIMP from "../../../../../assets/img/icons/MAILCHIMP.ico";
import HUBSPOT from "../../../../../assets/img/icons/HUBSPOT.png";
import INSIGHTLY from "../../../../../assets/img/icons/INSIGHTLY.png";
import SALESFORCE from "../../../../../assets/img/icons/SALESFORCE.ico";
import ZAPIER from "../../../../../assets/img/icons/ZAPIER.ico";
import CAMPAIGN_MONITOR from "../../../../../assets/img/icons/CAMPAIGN_MONITOR.ico";
import ZEN_DESK from "../../../../../assets/img/icons/ZEN_DESK.ico";
import KLAVIYO from "../../../../../assets/img/icons/KLAVIYO.png";
import KLAVIYO_V2 from "../../../../../assets/img/icons/KLAVIYO_V2.png";


import ACTIVE_CAMPAIGN from "../../../../../assets/img/icons/ACTIVE_CAMPAIGN.jpeg";
import EVENT_BRITE from "../../../../../assets/img/icons/EVENT_BRITE.png";
import FACEBOOK_LEAD_ADS from "../../../../../assets/img/icons/FACEBOOK_LEAD_ADS.png";
import INSTAGRAM from "../../../../../assets/img/icons/INSTAGRAM.png";
import INTERCOM from "../../../../../assets/img/icons/INTERCOM.png";
import LINKED_IN from "../../../../../assets/img/icons/LINKED_IN.png";
import MATTER from "../../../../../assets/img/icons/MATTER.png";
import POWER_BI from "../../../../../assets/img/icons/POWER_BI.jpeg";
import TWITTER from "../../../../../assets/img/icons/TWITTER.png";
import OPENTABLE from "../../../../../assets/img/icons/OPENTABLE.png";
import WIRELESS_SOCIAL from "../../../../../assets/img/icons/WIRELESS_SOCIAL.jpeg";

class CardSidebar extends Component {
  state = {
    sectionIcon: {
      DEFAULT: DEFAULT,
      CORE_DATA: DEFAULT,
      POSTGRESQL: POSTGRESQL,
      MYSQL: MYSQL,
      SQLSERVER: SQLSERVER,
      REDSHIFT: REDSHIFT,
      MAILCHIMP: MAILCHIMP,
      HUBSPOT: HUBSPOT,
      INSIGHTLY: INSIGHTLY,
      SALESFORCE: SALESFORCE,
      ZAPIER: ZAPIER,
      CAMPAIGN_MONITOR: CAMPAIGN_MONITOR,
      ZEN_DESK: ZEN_DESK,
      KLAVIYO: KLAVIYO,
      KLAVIYO_V2: KLAVIYO_V2,
      ACTIVE_CAMPAIGN: ACTIVE_CAMPAIGN,
      EVENT_BRITE: EVENT_BRITE,
      FACEBOOK_LEAD_ADS: FACEBOOK_LEAD_ADS,
      INSTAGRAM: INSTAGRAM,
      INTERCOM: INTERCOM,
      LINKED_IN: LINKED_IN,
      MATTER: MATTER,
      POWER_BI: POWER_BI,
      TWITTER: TWITTER,
      OPENTABLE: OPENTABLE,
      WIRELESS_SOCIAL: WIRELESS_SOCIAL
    }
  };

  renderListItem(connection, dataSource, key) {
    const {onChooseHistoryGraph, unSelectedDataSourcesId, selectedDataSourcesId} = this.props;

    let dsDisabled = !dataSource.enabled;
    let connDisabled = !connection.enabled;

    let dataSourceIcon = this.state.sectionIcon[dataSource.iconType || connection.connectionType] || DEFAULT;

    return unSelectedDataSourcesId.includes(dataSource.id) ? null : (
      <ListGroup.Item
        key={key}
        className={
          (dataSource.id === selectedDataSourcesId ? "active" : "") +
          (dataSource.lastSyncStatus === "ERROR" ? "sync-fail" : "")
        }

        as="li"
        action
        href={dataSource.type}
        onClick={() => onChooseHistoryGraph(dataSource, connection)}
      >
        <img src={dataSourceIcon} className="icon" alt=""/>
        <div className="list-data sync-fail d-flex flex-column align-items-start">
          <h6 className={"ellipsis" + ((dsDisabled || connDisabled) ? "title-disable" : "")} title={dataSource.name}>
            {dataSource.name}
            {dsDisabled && !connDisabled && <span className="icon-disable"/>}
          </h6>
          <span className={'sub-head' + (connDisabled ? 'title-disable' : '')}>
            {connection.name}
            {connDisabled && <span className="icon-disable"/>}
          </span>
        </div>
      </ListGroup.Item>
    );
  }

  render() {
    return (
      <div className="segments-list">
        <ListGroup as="ul">
          {this.props.isDataSourceExists ? this.props.data.map((connection, key) =>
            connection.dataSources.map((dataSource, key) =>
              this.renderListItem(connection, dataSource, key)
            )
          ) : null}
        </ListGroup>
      </div>
    );
  }
}

export default CardSidebar;
