import React, { Component } from "react";
 
import { Link } from 'react-router-dom';
class InvitaionResult extends Component {
  state = {};
  render() {
    const { data } = this.props;
    if (data !== null) {
      return (
        <div className="content-wrap bg-white">
          <div className="content-inner-wrap forgot-email-sent  col-sm-9">
            <div className="top-wrap d-flex flex-column">
              <h1 className="font-weight-heavy">{data.title}</h1>
            </div>

            <div className="form-wrap">
              <p>{data.description}</p>

              <div className="btn-wrap">
                <Link className="btn btn-primary green-btn" to={data.actionPath}>
                  {data.actionLabel}
                </Link>
              </div>
            </div>

            <div className="bottom-wrap">
              <div className="right-text light-black-text-40">
                <p>
                  ©2018–2020 All Rights Reserved. Distil.ai® is a registered
                  trademark of Big Business Intelligence Limited.{" "}
                  <a href="#;">Cookie Preferences</a>, <a href="#;">Privacy</a>,
                  and <a href="#;">Terms</a>.
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return " ";
    }
  }
}

export default InvitaionResult;
