import React from "react";
import PropTypes from "prop-types";
import "./styles.css";

const valueType = PropTypes.oneOfType([
                                        PropTypes.string,
                                        PropTypes.number,
                                        PropTypes.bool
                                      ]);

const propTypes = {
  labels: PropTypes.shape({
                            left: PropTypes.shape({
                              title: PropTypes.string.isRequired,
                              value: valueType
                            }),
                            center: PropTypes.shape({
                              title: PropTypes.string.isRequired,
                              value: valueType
                            }),
                            right: PropTypes.shape({
                              title: PropTypes.string.isRequired,
                              value: valueType
                            })
                          }),
  onChange: PropTypes.func.isRequired,
  styles: PropTypes.object
};

const defaultProps = {
  labels: {
    left: {
      title: "left",
      value: "left"
    },
    center: {
      title: "center",
      value: "center"
    },
    right: {
      title: "right",
      value: "right"
    }
  },
  onChange: (value) => console.log("value:", value)
};

class ToggleButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      switchPosition: "left",
      animation: null
    };
  }

  change = (value, position) => {
    const { switchPosition } = this.state;
    this.props.onChange(value);
    this.setState({ switchPosition: position });
  };

  render() {
    const { labels } = this.props;

    return (
            <div className="main-container">
              <input
                      defaultChecked
                      onChange={(e) => this.change(e.target.value, "left")}
                      name="map-switch"
                      id="left"
                      type="radio"
                      value={labels.left.value}
              />
              <label
                      className={`left-label ${
                              this.state.switchPosition === "left" && "black-font"
                      }`}
                      htmlFor="left"
              >
                <h6>{labels.left.title}</h6>
              </label>

              <input
                      onChange={(e) => this.change(e.target.value, "center")}
                      name="map-switch"
                      id="center"
                      type="radio"
                      value={labels.center.value}
              />
              <label
                      className={`center-label ${
                              this.state.switchPosition === "center" && "black-font"
                      }`}
                      htmlFor="center"
              >
                <h6>{labels.center.title}</h6>
              </label>

              <input
                      onChange={(e) => this.change(e.target.value, "right")}
                      name="map-switch"
                      id="right"
                      type="radio"
                      value={labels.right.value}
              />
              <label
                      className={`right-label ${
                              this.state.switchPosition === "right" && "black-font"
                      }`}
                      htmlFor="right"
              >
                <h6>{labels.right.title}</h6>
              </label>
            </div>
    );
  }
}

ToggleButton.propTypes = propTypes;
ToggleButton.defaultProps = defaultProps;

export default ToggleButton;
