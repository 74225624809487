import TenantApiService from "./tenantApiService";

function getHeaderDailyInsight() {
    const requestUrl = `/shopify-ux/header`;
    return TenantApiService.get(requestUrl).then(response => {
        return response.data;
    });
}

function getDataDailyInsight(date) {
    const requestUrl = `/shopify-ux/data/${date}?&mock=false`;
    return TenantApiService.get(requestUrl).then(response => {
        return response.data;
    });
}

export default {
    getHeaderDailyInsight,
    getDataDailyInsight,
};
