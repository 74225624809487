import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { debounce } from "lodash";

import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import "./assets/style.css";


function isNumber(evt) {
  evt = (evt) ? evt : window.event;
  var charCode = (evt.which) ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
  }
  return true;
}


class Mixer extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      mixerValue: 11,
      isEnable: true,
      overDays: this.props.overDays,
      itemName: {
        mixerValue: "",
        isEnable: "",
        overDays: ""
      }
    };
  }
  componentDidMount() {
    this.setState({
      mixerValue: this.props.mixerValue,
      isEnable: this.props.isEnable,
      overDays: this.props.overDays,
      itemName: this.props.itemName
    });
  }

  componentWillReceiveProps(prevProps) {

    this.setState(
      {
        mixerValue: prevProps.mixerValue,
        isEnable: prevProps.isEnable,
        overDays: this.state.overDays || prevProps.overDays,
        itemName: this.props.itemName
      },
      this.renderState
    );
  }

  changeMixerSetting = selectedValue => {
    let mixerSetting = selectedValue;
    this.setState(
      {
        mixerValue: mixerSetting
      }
      // this.mixerUpdated
    );
  };

  changeCheckboxSetting = () => {
    this.setState({ isEnable: !this.state.isEnable }, this.mixerUpdated);
  };

  changeDaysSetting = days => {
    this.setState({ overDays: days }, this.mixerUpdated);
  };

  mixerUpdated = debounce(() => {
    if (this.state.mixerValue !== this.props.mixerValue) {
      this.props.onSliderUpdate(
        `${this.state.itemName.mixerValue}${this.props.mixerName}`,
        this.state.mixerValue
      );
    }
    if (this.state.isEnable !== this.props.isEnable) {
      this.props.onSliderUpdate(
        `${this.state.itemName.isEnable}${this.props.mixerName}`,
        this.state.isEnable
      );
    }
    if (this.state.overDays !== this.props.overDays) {
      this.props.onSliderUpdate(
        `${this.state.itemName.overDays}${this.props.mixerName}OverDays`,
        this.state.overDays
      );
    }
  }, 500);

  render() {
    const {
      type,
      ids,
      isSliderEnable,
      reqOverDays,
      numberOfItems
    } = this.props;
    const sliderSize = 100 / numberOfItems;
    const { isEnable, mixerValue, overDays } = this.state;
    return (
      <div
        className={`slider ${isSliderEnable ? "" : " slider-disable"} ${
          !isEnable ? " mixer-disable" : ""
        }`}
        style={{ width: sliderSize + "%" }}
      >
        <Form className="slider-check-box">
          <div className="mb-3 justify-content-center d-flex">
            <Form.Check
              custom
              inline
              checked={isEnable}
              onChange={this.changeCheckboxSetting}
              label=""
              type="checkbox"
              id={`custom-inline-${ids}`}
            />
          </div>
        </Form>
        <div className="value text-center">{mixerValue}</div>
        <Slider
          min={0}
          max={11}
          step={1}
          value={mixerValue}
          orientation="vertical"
          onChange={this.changeMixerSetting}
          onChangeComplete={this.mixerUpdated}
        />
        <p className="text-center text-product">{type}</p>
        <div className="mt-2 mb-2 slider-input-value">
          {reqOverDays && (
            <Form.Control
              type="text"
              value={overDays}
              disabled={!isEnable}
              onChange={(e, evt) => {

                const re = /^[0-9\b]+$/;

                if (e.target.value === '' || re.test(e.target.value)) {
                  this.mixerUpdated()
                  this.setState({ overDays: e.target.value })
                }
              }}
            />
          )}
        </div>
        {reqOverDays && <p className="text-center">day(s)</p>}
        {!isSliderEnable && (
          <p className="text-center">Unavailable no Product data</p>
        )}
      </div>
    );
  }
}

export default Mixer;
