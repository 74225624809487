import React, { Component } from "react";
import AuthenticationService from "../../services/users";
import { toast } from "react-toastify";
class EmailNotification extends Component {
  state = {
    btnDisabled: false
  };
  resendVerificationInvite = async () => {
    this.setState({ btnDisabled: true });
    try {
      await AuthenticationService.resendVerificationInvite().then(response => {
        toast.success("Password verification request sent ", {
          onClose: () => (window.location = "/")
        });
      });
    } catch (ex) {
      toast.error("Invalid Email");
    }
  };

  render() {
    return (
      <div className="alert alert-warning m-0 border-radius-0 pt-3 pb-3 d-flex align-items-center">
        <ul className="d-flex m-0 p-0 mr-auto align-items-center list-unstyled ">
          <li className="font-weight-light d-trial">
            <strong>Note:</strong> Please verify your email address by following
            the link emailed to you earlier.{" "}
            {this.state.btnDisabled ? (
              <span
                style={{ color: "inherit" }}
                target="_blank"
                className="btn-link disable"
              >
                Link resent
              </span>
            ) : (
              <span
                style={{ color: "inherit" }}
                target="_blank"
                onClick={() => this.resendVerificationInvite()}
                className="btn-link"
              >
                Resend the link
              </span>
            )}
          </li>
        </ul>
      </div>
    );
  }
}

export default EmailNotification;
