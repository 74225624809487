import React, {Component} from "react";
import ListGroup from 'react-bootstrap/ListGroup'
import NoData from "../NoData/nodata";
import {Textbox} from "react-inputs-validation";
import _ from "lodash";
import DestinationAPI from "../../services/destination";
import {getNumbers, mapAttr, notNull, PRODUCT_TAGS} from "./attributeProcessor";
import Loader from "../Loader/loader";

/**
 * props:
 *   attributesData - list of all groups with attributes
 */

class MapAttributes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mappedData: {},
      restrictedNames: [],
      isLoading: true,
      validate: false
    };
  }

  componentDidMount() {
    const {integrationId, connectionId, attributesData, recommendationCount, enrichmentType} = this.props;

    const mappedData = attributesData.map(d => {
      const attributes = d.attributes.flatMap(a => {
        if (PRODUCT_TAGS.includes(a.attributeTag)) {
          return getNumbers(recommendationCount || 1)
            .map(recNo => {
              let pos = recNo + 1;
              const externalId = (a.externalId?.split(',') || [])[recNo];
              return {
                attributeId: a.attributeId,
                pos: pos,
                name: `${a.name} ${pos}`,
                uxAttributeId: `${a.attributeId}-${pos}`,
                externalId: externalId,
              }
            });
        } else {
          return [{
            attributeId: a.attributeId,
            uxAttributeId: a.attributeId?.toString(),
            pos: -1,
            name: a.name,
            externalId: a.externalId,
          }];
        }
      });

      return {
        sectionName: d.sectionName,
        attributes: attributes
      }
    });

    DestinationAPI.getAttributesMapping(enrichmentType, recommendationCount, {
      connectionId: connectionId,
      id: integrationId,
      attributes: attributesData.flatMap(d => d.attributes).map(a => mapAttr(a))
    }).then(mapping => {
      mappedData.flatMap(d => d.attributes)
        .forEach(a => {
          let field = (mapping.fields || []).find(f => this.buildCompositeId(f) === a.uxAttributeId);
          a.fieldState = field?.fieldState;

          if (!a.externalId) {
            a.externalId = field?.field?.name;
          }
        });

      const data = this.checkAndFillDataValidation(mappedData).mappedData;
      this.setState({isLoading: false, mappedData: data, restrictedNames: mapping.accountUsedFields ? mapping.accountUsedFields : []});
    })
  }

  updateExternalId(attrId, externalId) {
    let mappedData = this.state.mappedData;

    mappedData.flatMap(d => d.attributes)
      .filter(a => a.uxAttributeId === attrId)
      .forEach(a => {
        a.externalId = externalId
        a.valid = this.validateValue(a.externalId);
        a.errMessage = null;
        a.fieldState = 'NEW';
      });

    this.setState({mappedData, validate: false});
  }

  validateValue(value) {
    return Boolean(value && value.match(/^[a-zA-Z][a-zA-Z_\-0-9]{0,19}$/));
  }

  buildCompositeId(attr) {
    if (!PRODUCT_TAGS.includes(attr.attributeTag)) {
      return attr.uxAttributeId?.toString();
    }

    return [attr.uxAttributeId, attr.position].filter(notNull).join("-");
  }

  validateOrThrow() {
    let validatedData = this.checkAndFillDataValidation(this.state.mappedData);

    this.setState({mappedData: validatedData.mappedData, validate: true});

    if(validatedData.errors.length !== 0) {
      throw new Error(validatedData.errors.filter(s => s !== "").join("\n"));
    }

    return true;
  }

  checkAndFillDataValidation(mappedData) {
    const restrictedNames = this.state.restrictedNames;
    const allNamesAllowed = this.props.allNamesAllowed;

    let noRestrictedFields = true;
    let hasDuplicates = false;

    const externalIds = [];
    const duplicates = [];
    const errors = [];

    const allIsValid = mappedData
      .flatMap(d => d.attributes)
      .map(a => {
        let externalId = a.externalId;
        a.errMessage = null;
        a.valid = true;

        if (externalIds.includes(externalId)) {
          duplicates.push(externalId);
          hasDuplicates = true;
        }

        externalIds.push(externalId);

        let isAllowed = allNamesAllowed || a.fieldState !== 'NEW' || !restrictedNames.includes(externalId);

        let isValidValue = this.validateValue(a.externalId);

        if (!isValidValue) {
          this.updateErrMessage(a, "Attribute Name is invalid");
        } else if (!isAllowed) {
          noRestrictedFields = false;
          this.updateErrMessage(a, "Name already mapped");
        }

        a.valid = isValidValue && isAllowed;
        return a.valid;
      }).every(valid => valid);

    // duplicates final check
    mappedData.flatMap(d => d.attributes).forEach(a => {
      let isDuplicate = duplicates.includes(a.externalId);

      if (isDuplicate) {
        this.updateErrMessage(a, "Invalid name - duplicate");
      }

      a.valid = a.valid && !isDuplicate
    });

    if (!allIsValid || hasDuplicates) {
      errors.push("");
    }

    if (!noRestrictedFields) {
      errors.push("The labels highlighted as 'Name already mapped' are used in other destinations - or already exist as contact data fields in Dotdigital and cannot be re-used");
    }

    return {mappedData, errors};
  }

  updateErrMessage = (a, message) => {
    if (!a.errMessage) {
      a.errMessage = message;
    }
}

  getUpdatedData() {
    this.validateOrThrow();

    const idsAndNames = {};
    _.chain(this.state.mappedData)
      .flatMap(d => d.attributes)
      .groupBy(d => d.attributeId)
      .map((value, key) => idsAndNames[key] = value.map(a => a.externalId).filter(notNull).join(","))
      .value();

    return idsAndNames;
  }

  render() {
    let {mappedData, isLoading} = this.state;

    if (mappedData.length > 0) {
      return (
        <div className="d-block mySize attribute-accordion">
          {mappedData.map((item, idx) => (
            <div className="attribute-section d-flex flex-column-reverse" key={idx}>

              <div className="attribute-section-body">
                <ListGroup className="section-attribute-list">
                  {item.attributes.map((attribute, attrIdx) => (
                    <ListGroup.Item
                      key={attrIdx}
                      className={"section-attribute list_active position-relative"}
                    >
                      <div className="attribute-mapping text-truncate">
                        <div className="w-50 text-truncate">
                          {attribute.name}
                        </div>
                        <Textbox
                          onRef={ref => ref.check()}
                          classNameInput={"form-control"}
                          classNameContainer="custome-input"
                          id={`attrName${attribute.attributeId}`} name="attrName" type="text"
                          value={attribute.externalId}
                          autoComplete="off"
                          onChange={(value) => {this.updateExternalId(attribute.uxAttributeId, value?.toUpperCase())}}
                          onBlur={_ => {}}
                          validate={this.state.validate}
                          maxLength="20"
                          disabled={attribute.fieldState === 'CREATED_AND_MAPPED'}
                          validationOption={{
                            name: `Attribute Name`,
                            check: true,
                            required: true,
                            reg: /^[a-zA-Z][a-zA-Z_\-0-9]{0,19}$/,
                            regMsg: "Invalid characters in name",
                            customFunc: () => attribute.valid || attribute.errMessage
                          }}
                        />
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </div>
              <div
                className={"attribute-section-header list_active"}>
                <div className="attribute-section-toggle">
                  <strong className="text-truncate">{item.sectionName}</strong>
                </div>
              </div>
            </div>
          ))}
        </div>
      )
    } else {
      if (isLoading) {
        return <Loader adaptive={true} message="Loading data"/>;
      } else {
        return (
          <NoData
            customTitle="No attributes available"
            customSubTitle=""
          />
        )
      }

    }
  }
}

export default MapAttributes;
