import React, { Component } from "react";
import CommonMessage from "../../../../components/CommonMessage/commonMessage";
import AuthenticationService from "../../../../services/authentication";

import { Link } from "react-router-dom";

import { Textbox } from "react-inputs-validation";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import { ListGroup } from "react-bootstrap";

import { toast } from "react-toastify";

// import { globalConstants } from '../../../variables/globalVariables';
const emailRegx=/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
class ResetRequest extends Component {
  constructor(props) {
    super(props);
    this.myEmail = React.createRef();
    this.myPassword = React.createRef();
  }
  state = {
    email: "",
    password: "",
    isPasswordField: "password",
    errors: {},
    validate: false,
    hasEmailError: true,
  };

  requestReset = async event => {
    event.preventDefault();
    this.setState({hasEmailError: true})
    const email = this.state.email;
    try {
      await AuthenticationService.passwordResetRequest(
        email
      ).then(response => {
        toast.success("If an account with this email address exists we have sent you a password reset link.", {
          onClose:()=> (window.location = "/")
        });
      });
    } catch (ex) {
      this.setState({hasEmailError: false})
      toast.error("Request not submitted sucessfully please try again");
    }
  };

  checkEmail=()=>{
    this.setState({hasEmailError:test.emailRegx(this.state.email)})
  }

  componentDidMount() {

  }

  render() {
    return (
      <div className="content-wrap bg-white">
        <div className="content-inner-wrap  col-xl-9 col-md-11">
          <div className="top-wrap ">
            <h1 className="font-weight-heavy">Reset Your Password</h1>
            <p>Enter your registered email address and we’ll email you instructions to reset your password.</p>
            <p>
              If you don’t have access to your email anymore - no worries - just drop us a line at
              <Link
                className="px-1"
                to={{
                  pathname: "/signup"
                }}
              >
                {"support@distil.ai"}
              </Link>
            </p>
          </div>

          {/* <h1 className="font-weight-heavy">Sign In</h1> */}

          <div className="form-wrap">
            <form onSubmit={this.requestReset}>
              <div className="form-group new-form-group">
                {/* For dummmy form  Start*/}
                <input
                  id="email"
                  type="text"
                  autocomplete="off"
                  value=""
                  className="d-none"
                ></input>

                {/* For dummmy form  End */}
                <label htmlFor="email">Enter Registered Email Address</label>
                <Textbox
                  ref={this.myEmail}
                  classNameInput="form-control"
                  classNameContainer="custome-input"
                  tabIndex="1" // Optional.[String or Number].Default: none.
                  id={"email"} // Optional.[String].Default: "".  Input ID.
                  name="email" // Optional.[String].Default: "". Input name.
                  type="text" // Optional.[String].Default: "text". Input type [text, password, number].
                  value={this.state.email} // Optional.[String].Default: "".
                  placeholder="Your Email Address here" // Optional.[String].Default: "".
                  autoComplete="off"
                  onChange={(email, e) => {
                    this.setState({ email , hasEmailError:!emailRegx.test(email)});
                    console.log(e);
                  }} // Required.[Func].Default: () => {}. Will return the value.
                  onBlur={e => {
                    console.log(e);
                  }} // Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                  validationCallback={res =>
                    this.setState({
                      hasEmailError: res,
                      validate: false
                    })
                  }
                  validationOption={{
                    name: "Email Address", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
                    check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                    required: true, // Optional.[Bool].Default: true. To determin if it is a required field.

                    reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // Optional.[Bool].Default: "" Custom regex.
                    regMsg: "Enter valid Email Address" // Optional.[String].Default: "" Custom regex error message.
                  }}
                />
              </div>

              <div className="btn-wrap">
                <button
                  type="submit"
                  disabled={this.state.hasEmailError}
                  className="btn btn-primary green-btn"
                >
                  Submit
                </button>
                <Link className="return" to="/">
                Return to Log in
                </Link>
              </div>
            </form>
          </div>

          <div className="bottom-wrap">
            <div className="right-text light-black-text-40">
              <p>
                ©2018–2019 All Rights Reserved. Distil.ai® is a registered
                trademark of Big Business Intelligence Limited.{" "}
                <a href="#;">Cookie Preferences</a>, <a href="#;">Privacy</a>,
                and <a href="#;">Terms</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetRequest;
