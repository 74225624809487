import React, { Component } from "react";
import EnrichmentsAPI from "../../../../../../../services/enrichments";

class ListName extends Component {
  state = {
    item: null,
    isLoading: false
  };

  componentWillMount() {
    this.handleDefaultContentItem();
  }

  handleDefaultContentItem = async event => {
    try {
      await EnrichmentsAPI.getSingleContent(this.props.item).then(response => {
        this.setState({
          item: response,
          isLoading: true
        });
      });
    } catch (err) {}
  };

  render() {
    const { item, isLoading } = this.state;
    return (
      isLoading && (
        <div className="content-single-list">
          <span className="icon-menu" /> 
          
          {item.title} 
          <span
            className="icon-close"
            onClick={() => this.props.onDelete(item)}
          />
        </div>
      )
    );
  }
}

export default ListName;
