import React, { Component, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import footerLogo from "../../assets/img/brand/Footer-logo.png";
import { build } from "../../routes/main";
import Page403 from '../../views/Error/Page403/Page403';

import { globalConstants } from "../../variables/globalVariables";

class DefaultLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      myHeight: window.innerHeight,
      lockScreens: {
          'customers': 'customerDataStudio',
          'destinations': 'destinations',
          'funnel_analytics': 'funnelAnalytics',
          'analytics': 'marketingAnalytics'
      }
    };
  }
  componentDidMount() {
    let selectedMembers = JSON.parse(sessionStorage.getItem("selectedMembers"));

    if (selectedMembers !== null) {
      document.title = "Distil - " + selectedMembers.orgName;
    }

    window.addEventListener("resize", this.updateSize);
    window.addEventListener("load", this.updateSize);
    window.addEventListener("hashchange", this.updateSize);
  }
  componentWillReceiveProps(nextProps) {
    this.updateSize();
  }

  updateSize = () => {
    let myHeight = window.innerHeight;
    let myWidth = window.innerWidth;
    let innerWrapper = document.getElementsByClassName("inner-wrapper");
    let innerWrapperTop = 0;
    if (innerWrapper[0]) {
      innerWrapperTop = innerWrapper[0].offsetTop;
    }

    this.setState({ myHeight: myHeight - innerWrapperTop });
    this.setState({ myWidth: myWidth });
  };

  checkHideLogo = (permissions) => {
    let userLocation = window.location.pathname;
    let mainPathName = userLocation.split('/')[1];

    if(mainPathName) {
      let getUserPermissionsForPage = this.state.lockScreens[mainPathName];
      return !(!permissions[getUserPermissionsForPage] && this.state.myWidth < 1000);
    }

    return false;
  }

  render() {
    const { currentPage } = this.props;
    let selectedMembers = JSON.parse(sessionStorage.getItem("selectedMembers"));
    const classes = currentPage + " inner-wrapper";
    let showLogo = false; // this.checkHideLogo(selectedMembers.cdpPermissions);
    let pageRouter;
    let { CONTRIBUTOR, TECHNICAL, READER, NEWSFEED, ADMIN } = build(selectedMembers.cdpPermissions);
    switch (selectedMembers.membershipType) {
      case "CONTRIBUTOR":
        pageRouter = CONTRIBUTOR;
        break;
      case "TECHNICAL":
        pageRouter = TECHNICAL;
        break;
      case "READER":
        pageRouter = READER;
        break;
      case "NEWSFEED":
        pageRouter = NEWSFEED;
        break;
      default:
        pageRouter = ADMIN;
        break;
    }

    return (
      <div
        className={classes}
        style={{ height: this.state.myHeight, minHeight: this.state.myHeight }}
      >
        <Suspense>
          <Switch>
            {pageRouter.map((route, idx) => {
              return route.component ? (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={props => (
                    <route.component
                      contextUser={this.props.contextUser}
                      membershipType={selectedMembers.membershipType}
                      pinnedUpdated={this.props.pinnedUpdated}
                      updateContextUser={this.props.updateContextUser}
                      onTogglePin={this.props.onTogglePin}
                      onCustomerDetail={this.props.onCustomerDetail}
                      onContextChange={this.props.onContextChange}
                      onContextIdChange={this.props.onContextIdChange}
                      onCheckChange={this.props.onCheckChange}
                      hasNoData={this.props.hasNoData}
                      onVerifyChange={this.props.onVerifyChange}
                      onPinnedCard={this.props.onPinnedCard}
                      newsFeedcards={this.props.newsFeedcards}
                      onCardHistory={this.props.onCardHistory}
                      onPageChange={this.props.onPageChange}
                      {...props}
                    />
                  )}
                />
              ) : null;
            })}
            {/*Forbidden Page*/}
            <Route path={'/403'} exact={true} name={'Forbidden'} component={Page403}/>
            <Redirect from="/" to="/daily-insight" />
          </Switch>
        </Suspense>
        <div className="footer-logo">
          { showLogo ?
              <img
                  src={footerLogo}
                  alt="footer-logo"
                  title={globalConstants.VERSION}
              />
              : null
          }
        </div>
      </div>
    );
  }
}

export default DefaultLayout;
