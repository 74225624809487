import React from "react";
import "./styles.scss";

import {Row} from "react-bootstrap";
import ChannelPerformanceAndProjectedLTV from "./ChannelPerformanceAndProjectedLTV";
import MainBlock from "./MainBlock";
import FunnelStage from "./FunnelStage";
import FunnelTrapezium from "./FunnelTrapezium";
import FunnelTable from "./FunnelTable";

const FunnelMetric = ({cardData}) => {

    return (
        <div className="funnel-metric">
            <div className="card-body">
                <div className="content ">

                    {/* ------------------------------ MAIN BLOCK ----------------------------- */}
                    <MainBlock data={cardData?.data}/>
                    {/* ----------------------------------------------------------------------- */}

                    {/* ---------------- Channel Performance and Projected LTV ---------------- */}
                    <ChannelPerformanceAndProjectedLTV roiAndLifetimeValuesData={cardData?.data?.roiAndLifetimeValues}
                                                       roiAndLifetimeValuesData_formatted={cardData?.data?.roiAndLifetimeValues_formatted}/>
                    {/* ----------------------------------------------------------------------- */}

                    {/* ---------------- Funnel ---------------- */}
                    <Row className="w-100">
                        <div className="main">
                            <div className="row">
                                <div className="col">
                                    <p className="month color-brown">
                                        {cardData?.data?.cohortName?.split(" ")[0]} Funnel</p>
                                </div>
                            </div>
                        </div>
                    </Row>
                    {/* ---------------- FULL page view START ---------------- */}
                    <Row className="w-100">
                        <div className="main">
                            <div className="row border-t display-none-max576">
                                <FunnelStage funnelMetric={cardData?.data?.funnelMetrics[0]} funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[0]}/>
                            </div>

                            <Row className="row border-t mg-t-3 display-none-max576">
                                <FunnelTrapezium funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[0]}
                                                 title={"First Visit"}
                                                 trapeziumName={"Unique Visitors"}
                                                 position={1}/>
                            </Row>
                        </div>
                    </Row>
                    <Row className="content_funnel">
                        <div className="main">
                            <div className="row border-t display-none-max576">
                                <FunnelStage funnelMetric={cardData?.data?.funnelMetrics[1]} funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[1]}/>
                            </div>

                            <Row className="row border-t mg-t-3 display-none-max576">
                                <FunnelTrapezium funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[1]}
                                                 title={"Registered"}
                                                 trapeziumName={"Customers"}
                                                 position={2}/>
                                <FunnelTable funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[1]}
                                             position={2}/>
                            </Row>

                        </div>
                    </Row>
                    <Row className="content_funnel">
                        <div className="main display-none-max576">
                            <div className="row border-t display-none-max576">
                                <FunnelStage funnelMetric={cardData?.data?.funnelMetrics[2]} funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[2]}/>
                            </div>

                            <Row className="row border-t mg-t-3">
                                <FunnelTrapezium funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[2]}
                                                 title={"1st Purchase"}
                                                 trapeziumName={"Customers"}
                                                 position={3}/>
                                <FunnelTable funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[2]}
                                             position={3}/>
                            </Row>

                        </div>
                    </Row>
                    <Row className="content_funnel">
                        <div className="main display-none-max576">
                            <div className="row border-t display-none-max576">
                                <FunnelStage funnelMetric={cardData?.data?.funnelMetrics[3]} funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[3]}/>
                            </div>

                            <Row className="row border-t mg-t-3">
                                <FunnelTrapezium funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[3]}
                                                 title={"2nd Purchase"}
                                                 trapeziumName={"Customers"}
                                                 position={4}/>
                                <FunnelTable funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[3]}
                                             position={4}/>
                            </Row>
                        </div>
                    </Row>
                    <Row className="content_funnel">

                        <div className="main display-none-max576">
                            <div className="row border-t display-none-max576">
                                <FunnelStage funnelMetric={cardData?.data?.funnelMetrics[4]} funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[4]}/>
                            </div>

                            <Row className="row border-t mg-t-3">
                                <FunnelTrapezium funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[4]}
                                                 title={"3rd+ Purchase"}
                                                 trapeziumName={"Customers"}
                                                 position={5}/>
                                <FunnelTable funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[4]}
                                             position={5}/>
                            </Row>
                        </div>
                    </Row>
                    {/* ---------------- FULL page view END ---------------- */}
                </div>
            </div>
        </div>

    );
};

export default FunnelMetric;
