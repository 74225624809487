import ApiService from "./apiService";
import TenantApiService from "./tenantApiService";

function handleAccount(response) {
  //   successful response if there's a jwt token in the response
  if (response && response.contextUser) {
    // store user details and jwt token in local storage to keep user logged in between page refreshes

    let userAccountData = {
      ...response
    };

    // store session user AccountData in storage

    localStorage.setItem("userAccountData", JSON.stringify(userAccountData));

    return userAccountData;
  }

  return null;
}

function getAccount() {
  const requestUrl = "context/";
  return ApiService.get(requestUrl)
    .then(response => {
      return response.data;
    })
    .then(handleAccount);
}

function getOrgInfo(id) {
  const requestUrl = "context/"+id;
  return ApiService.get(requestUrl)
    .then(response => {
      return response.data;
    })
    .then(handleAccount);
}

function getOrgInfoWithoutSaveToStore(id) {
  const requestUrl = "context/"+id;
  return ApiService.get(requestUrl)
    .then(response => {
      return response.data;
    })
}

 function getShopifyTracking() {
  const requestUrl = `/shopify-ux/tracking`;

  return TenantApiService.get(requestUrl)
    .then(response => {
      return response.data;
    })
}

function updateAccount(updateData) {
  const requestUrl = "context/";
  return ApiService.put(requestUrl, updateData)
    .then(response => {
      return response.data;
    })
    .then(handleAccount);
}

function updatePassword(updateData) {
  const requestUrl = "auth/change-password";
  return ApiService.post(requestUrl, updateData).then(response => {
    return response.data;
  });
}

function activateTrialMode(id) {
  const requestUrl = "private/account/status/" + id + "/activateTrialMode";
  return ApiService.put(requestUrl)
    .then(response => {
      return response.data;
    });
}

function activateFreePlan(id) {
  const requestUrl = "private/account/status/" + id + "/activateFreePlan";
  return ApiService.put(requestUrl)
    .then(response => {
      return response.data;
    });
}

function manageBillingPlan(orgId) {
  const requestUrl = "stripe/private/create/session/" + orgId;
  return ApiService.post(requestUrl)
    .then(response => {
      return response.data;
    });
}

function getPaymentProductLink(orgId, priceID) {
  const requestUrl = "stripe/private/payment/product/" + orgId + "/" + priceID;
  return ApiService.get(requestUrl)
    .then(response => {
      return response.data;
    });
}

function getCurrentPlanInfo(productId) {
  const requestUrl = "stripe/products/" + productId;
  return ApiService.get(requestUrl)
    .then(response => {
      return response.data;
    });
}
function getAllStripeProducts() {
  const requestUrl = "stripe/products";
  return ApiService.get(requestUrl)
    .then(response => {
      return response.data;
    });
}

function managePaymentMethod(orgId) {
  const requestUrl = "stripe/private/manage/payment/" + orgId;
  return ApiService.post(requestUrl)
    .then(response => {
      return response.data;
    });
}

function activateOrg(shopifyHost) {
  const requestUrl = `shopify/private/activate?shopifyHost=${shopifyHost}`
  return ApiService.post(requestUrl).then(response => {
    return response.data;
  })
}

async function activateTrialModeWithUpdateStorage(id)  {
  await activateTrialMode(id);
  let orgInfo = await getOrgInfo(id);
  sessionStorage.setItem("selectedMembers", JSON.stringify(orgInfo.orgMembership));
  window.location.reload();
}

export default {
  activateTrialModeWithUpdateStorage,
  getShopifyTracking,
  getAccount,
  updateAccount,
  updatePassword,
  getOrgInfo,
  getOrgInfoWithoutSaveToStore,
  activateTrialMode,
  activateFreePlan,
  manageBillingPlan,
  getPaymentProductLink,
  getCurrentPlanInfo,
  getAllStripeProducts,
  managePaymentMethod,
  activateOrg
};
