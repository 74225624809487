import React, {Component, useEffect, useState} from "react";
import {Col, Container, Row, Table} from "react-bootstrap";
import "../assets/css/progressFriday.scss";
import "../assets/css/general-styles.scss";
import iconHeaderFriday from "../assets/images/friday-icon.svg";
import iconBackground from "../assets/images/background-icon.svg";
import iconSaturday from '../assets/images/saturday-footer-icon.svg';
import BarChartProgressFriday from "./chart/BarChartProgressFriday";
import LineChart from "./chart/LineChart";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfo} from "@fortawesome/free-solid-svg-icons";
import equal from "fast-deep-equal";

class Friday extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startLastWeekDate: "",
            endLastWeekDate: "",
        };

    }

    componentDidMount() {
        this.setStartLastAndEndLastWeek();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!equal(this.props.cardData, prevProps.cardData)) {
            this.setStartLastAndEndLastWeek();
        }
    }

    setStartLastAndEndLastWeek() {
        const { cardData } = this.props;

        this.setState({
            startLastWeekDate: moment(cardData?.data?.lastWeekDate).format("dddd Do MMMM"),
            endLastWeekDate: moment(cardData?.data?.lastWeekDate).add(6, "days").format("dddd Do MMMM"),
        })
    };

    getForecastPosition(data) {
        if (!!data && data.last20weeksValues) {
            const res = data.last20weeksRanking;
            if (res === 1) {
                return `${res}st`
            }
            if (res === 2) {
                return `${res}nd`
            }
            if (res === 3) {
                return `${res}rd`
            }
            return `${res}th`
        }
        return '-';
    }

    render() {
        const { cardData, date } = this.props;
        const { startLastWeekDate, endLastWeekDate } = this.state;

        return (
            <>
                <Container className="progress-friday" fluid>
                    <Row>
                        <Col>
                            <div className="card m-0 p-2 rounded shadow-sm analytic-card container-monday">
                                <div className="card-header border-bottom-1 pl-3 position-relative padding-top4em">
                                    <div className="row card-header-row">
                                        <div className="col-1 d-flex card-col-icon align-items-center">
                                            <div className="card-icon">
                                                <img className="icon-background" src={iconBackground} alt="header..."/>
                                                <img className="icon-header" src={iconHeaderFriday} alt="header..."/>
                                            </div>
                                        </div>
                                        <div className="col-11 card-title-col">
                                            <div className=" fs-l fw-bold text-black">
                                                Wrap-Up Friday
                                            </div>
                                            <p className="fs-m text-muted mb-1 mb-md-2">{date}</p>
                                            <div className="text-wrap pr-3 w-75">
                                                <p className="card-text fs-m">
                                                    Well, that went fast! It’s Friday again and time to step back and
                                                    see how the week went.
                                                    This card gives it to you straight, helping you see what went well
                                                    and what went less well,
                                                    setting you up for an even better week to follow.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="card-offset">
                                            <p className="card-info small">
                                                <span className="text-infor"> How to read this card?</span>
                                                    <span className="card-sm-icon m-2">
                                                    <a href="https://help.distil.ai/hc/en-gb/articles/4419117046929-Daily-Insights-Wrap-up-Friday" className="" rel="noreferrer" target="_blank">
                                                       <FontAwesomeIcon icon={faInfo}/>
                                                    </a>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="resonse-header-mobile">
                                        <p className="card-text-mobile card-text fs-m">
                                            Well, that went fast! It’s Friday again and time to step back and see how
                                            the week went.
                                            This card gives it to you straight, helping you see what went well and what
                                            went less well,
                                            setting you up for an even better week to follow.
                                        </p>
                                    </div>
                                </div>
                                <div className="card-body m-0 m-md-3 border-bottom-1">
                                    <div>
                                        <Row>
                                            <Col>
                                                <div className="">
                                                    <div className="card-title fs-l fw-bold text-black">
                                                        Last Week
                                                    </div>
                                                    <div className="fs-s text-gray mb-1 mb-md-2">
                                                        {startLastWeekDate} to {endLastWeekDate}
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={6} className="space-between left-block-chart">
                                                <div>
                                                    <p className="text-title fs-s">
                                                        Sales so far this week
                                                    </p>
                                                    <p className="text-value text-black">
                                                        {cardData?.data?.salesSoFarThisWeek_formatted}
                                                    </p>
                                                    {cardData?.data?.salesPercentageChangeOverThisWeekLastYear &&
                                                    <p className="small text-title">
                                                        {cardData?.data?.salesPercentageChangeOverThisWeekLastYear < 0 ?
                                                            <span className="text-red fw-bold">
                                            {cardData?.data?.salesPercentageChangeOverThisWeekLastYear_formatted}
                                          </span> : <span className="text-green fw-bold">
                                            {cardData?.data?.salesPercentageChangeOverThisWeekLastYear_formatted}
                                          </span>}
                                                        {" "} over this week last year
                                                    </p>
                                                    }
                                                </div>
                                                <div>
                                                    <div className="chart-model" style={{maxHeight: "354px"}}>
                                                        <BarChartProgressFriday cardData={cardData}/>
                                                    </div>
                                                    <div>
                                                        <Table bordered
                                                               className="friday-bar-chart friday-bar-chart-legend">
                                                            <tbody>
                                                            <tr>
                                                                <td className="fill-actual"><span
                                                                    className="fs-xm text-white">Actual</span></td>
                                                                <td className="fill-forecast"><span
                                                                    className="fs-xm text-white">Forecast</span></td>
                                                            </tr>
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xs={12} md={6} className={"left-block-chart"}>
                                                <div>
                                                    <p className="text-title fs-s">
                                                        End of Year Forecast
                                                    </p>
                                                    <p className="text-value text-black">
                                                        {cardData?.data?.endOfYearForecast_formatted}
                                                    </p>
                                                    <p className="small text-title">
                                                        Your End of Year Forecast is{" "}
                                                        {cardData?.data?.changeInEndOfYearForecast < 0 ?
                                                            <span className="text-red fw-bold">Down</span> :
                                                            <span className="text-green fw-bold">Up</span>
                                                        }
                                                        <span
                                                            className="text-gray fw-bold">{" "}{cardData?.data?.changeInEndOfYearForecast_formatted} </span>
                                                        from Monday
                                                    </p>
                                                </div>
                                                <div className="d-flex forecast-vl">
                                                    <div className="space-between">
                                                        <p className="text-title fs-s">Week Forecast</p>
                                                        <p className="text-gray text-value text-value-small">
                                                            {cardData?.data?.startWeekForecast_formatted ?? 0}
                                                        </p>
                                                    </div>
                                                    <span className="icon-move">{'>>>'}</span>
                                                    <div>
                                                        <p className="text-title fs-s">
                                                            Week forecast (updated)
                                                        </p>
                                                        <p className="text-green fw-bold text-value text-value-small">
                                                            {cardData?.data?.endWeekForecast_formatted ?? 0}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div style={{marginBottom: "18px"}}>
                                                    {/* <p className="text-title fs-13">
                          <b>
                            {cardData?.data?.endWeekForecast -
                              cardData?.data?.startWeekForecast >
                            0 ? (
                              <span className="text-green fw-bold">Up </span>
                            ) : (
                              <span className="text-red fw-bold">Down </span>
                            )}
                            £
                            {Math.abs(
                              Math.round(
                                (cardData?.data?.endWeekForecast -
                                  cardData?.data?.startWeekForecast) *
                                  100
                              ) / 100
                            )}
                            k{" "}
                          </b>
                          over the start of the week forecast.
                        </p> */}
                                                    <p className="text-title fs-m">
                                                        If you achieve this forecast it would rank
                                                        <span
                                                            className="text-gray fw-bold"> {this.getForecastPosition(cardData.data)} </span>
                                                        out of the last <span
                                                        className="text-gray fw-bold">20</span> weeks.
                                                    </p>
                                                </div>
                                                <div>
                                                    <div style={{maxHeight: "500px", minHeight: "220px"}}>
                                                        <LineChart cardData={cardData}/>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <Row>
                                        <Col lg={2}/>
                                        <Col ls={8} className="section-text-footer">
                                            {/*<div className='fs-l fw-bold text-black'>That's a Wrap</div>*/}
                                            <div className="fs-xm text-gray">We’ll keep working over the weekend and
                                                have an updated forecast ready for you first thing Monday morning.
                                            </div>
                                            <div className="card-icon"><img src={iconSaturday} alt="footer..."/></div>
                                        </Col>
                                        <Col lg={2}/>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
};

export default Friday;
