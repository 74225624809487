import React, {Component} from "react";
import img from "../Customers/assets/img/screen.png";
import destination from "./assets/img/destination.svg";
import checkIcon from "./assets/img/check-icon.svg";
import AccountAPI from "../../../services/account";
import "./assets/css/destination-styles.scss";
import "../../../assets/css/_empty-pages.scss";
import { globalConstants } from "../../../variables/globalVariables";
import Loader from "../../../components/Loader/loader";

class EmptyDestinations extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: false };
        this.activateTrial = this.activateTrial.bind(this);
    }

    openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    activateTrial() {
        let selectedMembers = JSON.parse(sessionStorage.getItem("selectedMembers"));
        this.setState({ loading: true });
        // ACTIVATED_TRIAL status has id 1
        AccountAPI.activateTrialModeWithUpdateStorage(selectedMembers.id).then();
    }

    redirectWithinTheWebSite = (route) => {
        this.props.history.push(route);
    }

    changeButton() {
        let selectedMembers = JSON.parse(sessionStorage.getItem("selectedMembers"));
        let accountType = selectedMembers.accountType;
        return accountType === 'NEW_SIGN_UP';
    }

    render() {
        const { loading } = this.state;

        return (
            <div className="analytics-portal-wrap bg-white lock-screen-component empty-destination-component">
                <div className="content-width new-top content">
                    <div className="flex-block">
                        <div className="top-text flex-block-item">
                            <h3 className="font-weight-Avenirmedium mb-4">Don’t just analyse, activate!</h3>
                            <p className="font-on-top primary-font">
                                As much as we love crunching numbers and revealing your Customers' previously hidden
                                traits and patterns, Distil’s primary focus is always on helping you achieve results!
                                That means - actually helping you activate this newly enriched data by connecting with
                                your existing channels to help you grow your customers.<br/><br/>
                            </p>
                            <div className="btn-wrap">
                                {this.changeButton() ?
                                    (<>
                                        <button
                                            type="submit"
                                            className="btn btn-primary black-btn"
                                            onClick={this.activateTrial}
                                            disabled={loading}
                                        >
                                            Start a free {globalConstants.TRIAL_PERIOD_DAYS} day trial
                                        </button>
                                        { loading && <Loader/> }
                                    </> ) :
                                    <button
                                        type="submit"
                                        className="btn btn-primary green-btn mt-1"
                                        onClick={() => this.redirectWithinTheWebSite('/settings/billing')}
                                    >
                                        Choose a Billing plan
                                    </button>
                                }
                            </div>
                            <div className="content-items">
                                <div className="content-item">
                                    <div className="content-item-icon">
                                        <img src={checkIcon} alt="checkIcon"/>
                                    </div>
                                    <div>
                                        <p className="content-item-text">
                                            <b>Optimise your marketing data flows</b> - save time and money and optimise
                                            your Campaigns by syncing your Segmented Customer data directly to your
                                            Marketing Platforms; Email, Facebook, Google Ads and more...
                                        </p>
                                    </div>
                                </div>
                                <div className="content-item">
                                    <div className="content-item-icon">
                                        <img src={checkIcon} alt="checkIcon"/>
                                    </div>
                                    <div>
                                        <p className="content-item-text">
                                            <b>Create truly personalised messaging</b> - sync Customer “next best
                                            action” recommendations to your Campaign tools to create truly personalised
                                            campaigns that drive high engagement.
                                        </p>
                                    </div>
                                </div>
                                <div className="content-item">
                                    <div className="content-item-icon">
                                        <img src={checkIcon} alt="checkIcon"/>
                                    </div>
                                    <div>
                                        <p className="content-item-text">
                                            <b>Turbo charge your in-house analytics</b> - it’s your data so let us help
                                            you get hold of it! From all data in your complete Single Customer View
                                            right down to every click made on your store - Distil can provide SQL Access
                                            so you can build detailed analytics in exactly the way you want. We support
                                            100% in-house teams or can speed you along with our Tailored Analytics
                                            service.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-image">
                        <img src={destination} alt="destination"/>
                    </div>
                </div>
            </div>
        );
    }
}

export default EmptyDestinations;