import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { debounce } from "lodash";

import "../../assets/styles.css";

class ContentSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customerList: [],
      isSearching:false
    };
  }

  handleSearch = selectedValue => {
    let searchWhat = selectedValue.currentTarget.value;

    this.setState(
      {
        searchValue: searchWhat
      },
      this.searchUpdated
    );
  };

  searchUpdated = debounce(() => {
    this.props.onContentSelect(this.state.searchValue);
    this.setState({isSearching:true})
  }, 1000);

  render() {
    const { customerList, showSearchResult ,isSearching} = this.state;
    return (
      <section>
        <div className="col-sm-12 pl-0 pr-0">
          <Form onSubmit={e => e.preventDefault()}>
            <Form.Group controlId="formBasicEmail" style={{display:"flex"}}>
              <Form.Control
                type="text"
                autoComplete="off"
                placeholder="Type to search..."
                onChange={e => this.handleSearch(e)}
              />
              {this.props.searchDone &&<span class="icon-sync searchingInfo"></span>}
            </Form.Group>
          </Form>
        </div>
      </section>
    );
  }
}

export default ContentSearch;
