// import React, { Component } from "react";
import React from "react";
import "./css/style.css";
const CommonMessage = props => {
  return (
    <h6 className="waiting">
      {props.msg !== ""
        ? props.msg
        : "This feature will be available shortly."}
      {props.backUrl !== undefined && (
        <a href="javascript:void()">
          <strong onClick={props.backUrl.goBack}> Go Back </strong>
        </a>
      )}
    </h6>
  );
};

export default CommonMessage;
