import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import _ from "lodash";

class ReviewDestination extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      pageErrors,
      newDestination,
      currentCardType,
      currentCardSteps
    } = this.props;

    const hasSomeError =
      (_.includes(currentCardSteps, "SELECT_SEGMENT")
        ? pageErrors.isSegment_HasError
        : false) ||
      (_.includes(currentCardSteps, "RECOMMENDATIONS")
        ? pageErrors.isRecomendation_HasError
        : false) ||
      (_.includes(currentCardSteps, "UTM_INFO")
        ? pageErrors.isUtmInfo_HasError
        : false) ||
      (_.includes(currentCardSteps, "DESTINATIONS")
        ? pageErrors.isDestination_HasError
        : false);

    const cardTypes = {
      STANDARD_SYNC: "Multi-Channel Audience",
      HYPER_PERSONALISED: "Personalised Recommendations",
      DATA_WAREHOUSE: "Campaign",
      SEGMENT_PUSH: "Campaign",
      ENRICH_CRM: "Campaign"
    };
    return (
      <div className="review-activate">
        {hasSomeError ? (
          <React.Fragment>
            <h2>There are some errors!</h2>
            <div className="review-activate-text">
              <p>
                To continue creating campaign you need to fill valid data in all
                fields. Review the choices in the left and enter valid data in
                error fields. You can make any ammendments by navigating to the
                appropriate section using the top menu.
              </p>
            </div>

            {/* <div className="review-activate-btn has-error">
              <Link className="btn-red">Create Audience</Link>
            </div> */}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <h2>You’re all set!</h2>
            <div className="review-activate-text">
              <p>
                You’re ready to create the {cardTypes[currentCardType]}. Review
                the choices in the left and when you’re ready press create
                campaign. You can make any ammendments by navigating to the
                appropriate section using the top menu.
              </p>
            </div>
            <div className="review-activate-btn">
              {newDestination.id === undefined ? (
                <Button
                  className="btn-red"
                  onClick={() => this.props.createUpdateAudience(false)}
                >
                  Create Campaign
                </Button>
              ) : (
                <Button
                  className="btn-red"
                  onClick={() => this.props.createUpdateAudience(true)}
                >
                  Update Campaign
                </Button>
              )}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default ReviewDestination;
