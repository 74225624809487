import React, { Component } from "react";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from "chart.js";

import {Bar} from "react-chartjs-2";
import equal from "fast-deep-equal";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const labels = ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""];
const CURRENT_WEEK_COLOR = "#0A83C4"
const HIGHER_WEEK_COLOR = "#9FCE1D"
const LOSER_WEEK_COLOR = "#C4C4C4"

class AnalyticChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      last20weeksRanking: "",
      last20weeksValues: "",
      last20weeksValuesFormatted: "",
    };

  }

  componentDidMount() {
    this.setDataChart();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!equal(this.props.cardData, prevProps.cardData)) {
      this.setDataChart();
    }
  }

  setDataChart() {
    const { cardData } = this.props;

    if (cardData) {
      this.setState({
        last20weeksRanking: cardData?.data?.last20weeksRanking,
        last20weeksValues: cardData?.data?.last20weeksValues,
        last20weeksValuesFormatted: cardData?.data?.last20weeksValues_formatted,
      })
    }
  }

  render() {
    const { last20weeksValues, last20weeksRanking, last20weeksValuesFormatted } = this.state;

    const data = {
      labels,
      datasets: [
        {
          data: last20weeksValues ? Object.values(last20weeksValues) : null,
          backgroundColor: last20weeksValues ? last20weeksValues.map((value, index) => {
            return (index + 1) === last20weeksRanking ? CURRENT_WEEK_COLOR :
                (index + 1) > last20weeksRanking ? LOSER_WEEK_COLOR : HIGHER_WEEK_COLOR
          }) : HIGHER_WEEK_COLOR,

        },
      ],
    };

    const options = {
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          ticks: {
            display: false,
          },
          grid: {
            drawBorder: true,
            display: false,
          },
        },
      },
      indexAxis: "y",
      elements: {
        bar: {
          borderWidth: 0,
        },
      },
      responsive: true,
      plugins: {
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            title: (items) => {
              return `${items[0].dataIndex + 1}`;
            },
            label: (items) => {
              return `${last20weeksValuesFormatted[0][items.dataIndex]}`;
            }
          }
        },
      },
    };

    return (
        <>
          <Bar options={options} data={data}/>
        </>
    );
  }
};
export default AnalyticChart;
