import React, {Component, useEffect} from "react";
import {Col, Container, Row} from "react-bootstrap";
import "./assets/css/analytic-header.scss";
import CountValue from "../FunnelMetrics/FunnelMetricsComponents/funnelFull/CountValue";
import {shopProperties} from "./utils";

class AnalyticHeader extends Component {
  constructor(props) {
    super(props);
    const selectedMembers = JSON.parse(sessionStorage.getItem("selectedMembers"));

    this.state = {
      selectedMembers: selectedMembers,
    };
    this.redirectToFunnelMetrics = this.redirectToFunnelMetrics.bind(this)
    this.redirectToCustomerPage = this.redirectToCustomerPage.bind(this)
  }

  redirectToFunnelMetrics() {
    this.props.history.push("/funnel_analytics");
  }

  redirectToCustomerPage(section) {
    this.props.history.push({
      pathname: '/customers/segments',
      search: `?section=${section}`,
    });
  }

  render() {
    const { cardHeader } = this.props;

    return (
        <Container
            className="analytic-header px-4 bg-gray border border-bottom-1 header-info-mobile header"
            fluid
        >
          <div className={"header-card-containers"}>
            <div className="py-3 section-col first-container grid-container">
              <div className="section-one">
                <p className="mb-0 lh-1 text-muted">Yesterday's Sales Total</p>
              </div>
              <div className="section-two">
                <p className="mb-1 value-money" onClick={this.redirectToFunnelMetrics}>
                  {cardHeader?.yesterdaySales_formatted??shopProperties.currency + 0}
                </p>
              </div>
              <div className="section-three">
                  <br/>
              </div>
              <div className="section-four">
                <p className="small">
                { cardHeader?.yesterdaySales > cardHeader?.last7daysAverageSales ?
                  (
                    <span>
                      <span className="text-green-up">Up</span> from your <br/>
                      <b>{cardHeader?.last7daysAverageSales_formatted}</b> rolling 7-days average
                    </span>

                  ) : cardHeader?.yesterdaySales < cardHeader?.last7daysAverageSales ? (
                    <span>
                      <span className="text-red-down">Down</span> from your <br/>
                      <b>{cardHeader?.last7daysAverageSales_formatted}</b> rolling 7-days average
                    </span>
                  ) : (
                    <span>
                      No change from your <br/>
                      <b>{cardHeader?.last7daysAverageSales_formatted}</b> rolling 7-days average
                    </span>
                  )
                }
                </p>
              </div>
            </div>
            <div className="py-3 section-col second-container grid-container show-header-2-col">
              <div className="section-one">
                <p className="mb-0 lh-1 text-muted">
                  {" "}
                  Yesterday's Average Order Value
                </p>
              </div>
              <div className="section-two">
                <p className="mb-1 value-money" onClick={this.redirectToFunnelMetrics}>
                  {cardHeader?.yesterdayAverageOrderValue_formatted??shopProperties.currency + 0}
                </p>
              </div>
              <div className="section-three">
                <br/>
              </div>
              <div className="section-four">
                <p className="small">
                  { cardHeader?.yesterdayAverageOrderValue > cardHeader?.last7daysAverageOrderValue ?
                  (
                    <span>
                      <span className="text-green-up">Up</span> from your <br/>
                      <b>{cardHeader?.last7daysAverageOrderValue_formatted}</b> rolling 7-days average
                    </span>

                  ) : cardHeader?.yesterdayAverageOrderValue < cardHeader?.last7daysAverageOrderValue ? (
                    <span>
                      <span className="text-red-down">Down</span> from your <br/>
                      <b>{cardHeader?.last7daysAverageOrderValue_formatted}</b> rolling 7-days average
                    </span>
                  ) : (
                    <span>
                      No change from your <br/>
                      <b>{cardHeader?.last7daysAverageOrderValue_formatted}</b> rolling 7-days average
                    </span>
                  )
                }

                </p>
              </div>
            </div>
            <div className="py-3 section-col show-header-3-col third-container grid-container">
              <div className="section-one">
                <p className="mb-0 lh-1 text-muted">
                  30 day Active <br/>
                  Customer Count
                </p>
              </div>
              <div className="section-two">
                <p className="mb-1 value-money" onClick={() => {
                  this.redirectToCustomerPage("a-i--active-customers")
                }}
                >{cardHeader?.last30daysActiveCustomers_formatted??0}</p>
              </div>
              <div className="section-three">
                <p className="small text-muted">

                </p>
              </div>
              <div className="section-four" >
                <p className="mb-1 value-money"
                   onClick={() => {
                     this.redirectToCustomerPage("a-i--lost-customers")
                   }}><CountValue value={cardHeader?.last30daysLostCustomers??0}/></p>
                <p className="small mb-0  text-muted">Lost Customers</p>
              </div>
            </div>
            <div className="py-3 section-col show-header-3-col fourth-container grid-container">
              <div className="section-one">
                <p className="mb-0 lh-1 text-muted">
                  Today you have
                </p>
              </div>
              <div className="section-two">
                <p className="mb-1 value-money" onClick={() => {
                  this.redirectToCustomerPage("a-i--active-customers-at-risk")
                }}
                >{cardHeader?.riskOfLeavingCustomers??0}</p>
              </div>
              <div className="section-three">
                <p className="small text-muted">
                  At Risk of Leaving.
                </p>
              </div>
              <div className="section-four" >
                <span className="text-red-down fw-bold"> <CountValue value={cardHeader?.loyalRiskOfLeavingCustomersPercentage??0}/>%</span> of them are{" "}
                <b>loyal</b> or <b>high-value.</b>
              </div>
            </div>
          </div>
        </Container>
    );
  }
};

export default AnalyticHeader;