import React from "react";

export const PRODUCT_TAGS = [
  "PRODUCT_EXTERNAL_ID",
  "PRODUCT_NAME",
  "PRODUCT_SHOP_URL",
  "PRODUCT_IMAGE_URL",
  "PRODUCT_THUMBNAIL_URL",
  "PRODUCT_PRECIS",
  "PRODUCT_AVAILABLE",
  "PRODUCT_LIST_PRICE_EX_TAX",
  "PRODUCT_LIST_PRICE_INC_TAX",
  "PRODUCT_PRICE_BREAKS_DESCRIPTION"
];

export function mapAttr(attribute) {
  return {
    id: attribute.id,
    attributeId: attribute.attributeTag === null || attribute.attributeDataTag === null
        ? attribute.attributeId
        : null,
    attributeDataTag: attribute.attributeDataTag ?? attribute.attributeTag,
    label: attribute.label,
    externalId: attribute.externalId
  }
}

export function notNull(obj) {
  return !isNull(obj);
}

export function isNull(obj) {
  return obj === null || obj === undefined;
}

export function getNumbers(length) {
  return [...Array(Number(length)).keys()]
}

export function attributeToShow() {
  return it => it.toShow === true || it.toShow === undefined || it.toShow === null;
}

export function attributeToShowCheck(it) {
  return it.toShow === true || it.toShow === undefined || it.toShow === null;
}
