import React, { Component } from "react";
import { Select } from "react-inputs-validation";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";

class AttributeRule extends Component {
  state = {
    attributesTypes: {
      String: [
        { id: null, name: "Please Select Condition" },
        { id: "EQUALS", name: "Equals" },
        { id: "NOT_EQUALS", name: "Not Equals" },
        { id: "CONTAINS", name: "Contains" },
        { id: "DOES_NOT_CONTAIN", name: "Does Not Contain" },
        { id: "STARTS_WITH", name: "Starts With" },
        { id: "ENDS_WITH", name: "Ends With" },
        { id: "IS_BLANK", name: "Is Blank" },
        { id: "IS_NOT_BLANK", name: "Is Not Blank" }
      ],
      Text: [
        { id: null, name: "Please Select Condition" },
        { id: "EQUALS", name: "Equals" },
        { id: "NOT_EQUALS", name: "Not Equals" },
        { id: "CONTAINS", name: "Contains" },
        { id: "DOES_NOT_CONTAIN", name: "Does Not Contain" },
        { id: "STARTS_WITH", name: "Starts With" },
        { id: "ENDS_WITH", name: "Ends With" },
        { id: "IS_BLANK", name: "Is Blank" },
        { id: "IS_NOT_BLANK", name: "Is Not Blank" }
      ],
      Integer: [
        { id: null, name: "Please Select Condition" },
        { id: "EQUALS", name: "Equals" },
        { id: "NOT_EQUALS", name: "Not Equals" },
        { id: "GREATER_THAN", name: "Greater Than" },
        { id: "LESS_THAN", name: "Less Than" },
        { id: "IS_BLANK", name: "Is Blank" },
        { id: "IS_NOT_BLANK", name: "Is Not Blank" }
      ],
      Long: [
        { id: null, name: "Please Select Condition" },
        { id: "EQUALS", name: "Equals" },
        { id: "NOT_EQUALS", name: "Not Equals" },
        { id: "GREATER_THAN", name: "Greater Than" },
        { id: "LESS_THAN", name: "Less Than" },
        { id: "IS_BLANK", name: "Is Blank" },
        { id: "IS_NOT_BLANK", name: "Is Not Blank" }
      ],
      Decimal: [
        { id: null, name: "Please Select Condition" },
        { id: "EQUALS", name: "Equals" },
        { id: "NOT_EQUALS", name: "Not Equals" },
        { id: "GREATER_THAN", name: "Greater Than" },
        { id: "LESS_THAN", name: "Less Than" },
        { id: "IS_BLANK", name: "Is Blank" },
        { id: "IS_NOT_BLANK", name: "Is Not Blank" }
      ],
      Double: [
        { id: null, name: "Please Select Condition" },
        { id: "EQUALS", name: "Equals" },
        { id: "NOT_EQUALS", name: "Not Equals" },
        { id: "GREATER_THAN", name: "Greater Than" },
        { id: "LESS_THAN", name: "Less Than" },
        { id: "IS_BLANK", name: "Is Blank" },
        { id: "IS_NOT_BLANK", name: "Is Not Blank" }
      ],
      Boolean: [
        { id: null, name: "Please Select Condition" },
        { id: "EQUALS", name: "Equals" },
        { id: "NOT_EQUALS", name: "Not Equals" }
      ],
      Date: [
        { id: null, name: "Please Select Condition" },
        { id: "EQUALS", name: "Equals" },
        { id: "NOT_EQUALS", name: "Not Equals" },
        { id: "AFTER", name: "After" },
        { id: "BEFORE", name: "Before" },
        { id: "IS_BLANK", name: "Is Blank" },
        { id: "IS_NOT_BLANK", name: "Is Not Blank" }
      ]
    },
    attributes: [
      { id: "PRODUCT_EXTERNAL_ID", name: "Product ID", type: "Integer" },
      { id: "PRODUCT_NAME", name: "Product Name", type: "String" },
      { id: "PRODUCT_SHOP_URL", name: "Product Url", type: "Text" },
      { id: "PRODUCT_IMAGE_URL", name: "Product Image url", type: "Text" },
      {
        id: "PRODUCT_THUMBNAIL_URL",
        name: "Product Image thumbnail url",
        type: "Text"
      },
      { id: "PRODUCT_PRECIS", name: "Product Precis", type: "Text" },
      { id: "PRODUCT_AVAILABLE", name: "Product Available", type: "Boolean" },
      {
        id: "PRODUCT_LIST_PRICE_EX_TAX",
        name: "Product List price excluding tax",
        type: "decimal"
      },
      {
        id: "PRODUCT_LIST_PRICE_INC_TAX",
        name: "Product List price inc tax",
        type: "decimal"
      },
      {
        id: "PRODUCT_PRICE_BREAKS_DESCRIPTION",
        name: "Product Description",
        type: "String"
      }
    ],
    isSaved: false,
    errors: { fieldValue: null }
  };
  componentWillMount() {
    this.setState(
      { item: this.props.attrItem, isSaved: this.props.attrItem.isSaved },
      () => this.checkList()
    );
  }

  componentWillReceiveProps(prevProps) {
    this.setState({
      item: prevProps.attrItem,
      isSaved: prevProps.attrItem.isSaved
    });
  }

  checkList = () => {
    if (this.state.item.fieldValue !== null) {
      this.setState({ isSaved: false });
    } else {
      this.setState({ isSaved: true });
    }
  };

  renderTypeOptions() {
    const { attributesTypes, item, isSaved } = this.state;

    let selctOptions;

    switch (item.type) {
      case "BOOLEAN":
        return (selctOptions = attributesTypes.Boolean);
        break;
      case "DATE":
        return (selctOptions = attributesTypes.Date);
        break;
      case "DOUBLE":
        return (selctOptions = attributesTypes.Double);
        break;
      case "INTEGER":
        return (selctOptions = attributesTypes.Integer);
        break;
      case "LONG":
        return (selctOptions = attributesTypes.Long);
        break;
      case "STRING":
        return (selctOptions = attributesTypes.String);
        break;
      case "TEXT":
        return (selctOptions = attributesTypes.Text);
        break;
      case "DECIMAL":
        return (selctOptions = attributesTypes.Decimal);
        break;
    }
  }
  dropDownValue = value => {
    const item = this.state.item;
    item.value = value;
    this.setState({ item });
  };
  setField = attr => {
    let attributes = this.state.attributes;
    let selectedAttribute = attributes.filter(e => e.id === attr);
    let errors = this.state.errors;
    errors.fieldValue = null;
    const item = { ...this.state.item };
    item.field = attr;

    item.type = selectedAttribute[0].type.toUpperCase();
    item.segmentCondition = null;
    item.value = null;

    this.setState({ item ,errors});
  };
  setCondition = attr => {
    const item = { ...this.state.item };
    item.segmentCondition = attr;
   
    if (attr === "IS_BLANK" || attr === "IS_NOT_BLANK") {
      item.value = attr;
    }
    else {
      item.value = null;
    }
    this.setState({ item });
  };

  handleTextChange = (e, type) => {
   
    const regexp = new RegExp(`^-?[0-9]*$`);
    const deciType = new RegExp(`^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$`);
    const errors = this.state.errors;
    const item = { ...this.state.item };

    const newValue = e.target.value;
    if (type === "INTEGER") {
      if (!regexp.test(newValue)) {
        errors.fieldValue = "Must be a whole number";
      } else {
        item.value = newValue;
        this.setState({ item }) ;
        errors.fieldValue = null;
      }
      this.setState({ errors });
    }
    else if (type === "DECIMAL") {
      if (!deciType.test(newValue)) {
        errors.fieldValue = "Must be a number";
      } else {
        item.value = newValue;
        this.setState({ item });
        errors.fieldValue = null;
      }
      this.setState({ errors });
    } else {
      item.value = newValue;
      this.setState({ item });
    }
  };

  checkBlankType() {
    if (
      this.state.item.segmentCondition === "IS_BLANK" ||
      this.state.item.segmentCondition === "IS_NOT_BLANK"
    ) {
      return;
    } else {
      return this.renderFieldType();
    }
  }

  renderFieldType() {
    const { item, fieldValue, validated } = this.state;

    switch (item.type) {
      case "BOOLEAN":
        return (
          <Select
            tabIndex="4"
            id={"fieldType"}
            name={"fieldType"}
            value={this.state.item.value}
            disabled={false}
            optionList={[
              { id: null, name: "Please Select Value" },
              { id: "TRUE", name: "TRUE" },
              { id: "FALSE", name: "FALSE" }
            ]}
            classNameSelect="select-control"
            classNameWrapper="form-control select-control-wrapper value"
            classNameContainer=""
            classNameOptionListContainer="select-control-option"
            classNameOptionListItem=""
            customStyleSelect={{}}
            customStyleWrapper={{}}
            customStyleContainer={{}}
            customStyleOptionListContainer={{}}
            customStyleOptionListItem={{}}
            onChange={condition => this.dropDownValue(condition)}
            onBlur={() => {}}
            validationOption={{
              name: "Attribute Value",
              check: true,
              required: true
            }}
          />
        );
        break;

      case "DOUBLE":
        return (
          <Form.Control
            className={this.state.errors.fieldValue && "error"}
            type="text"
            className="value"
            value={this.state.item.value}
            placeholder={"Please enter valid value"}
            onChange={e => this.handleTextChange(e, "DECIMAL")}
          />
        );
        break;
      case "INTEGER":
        return (
          <Form.Control
            type="text"
            className="value"
            value={this.state.item.value}
            validated={validated}
            placeholder={"Please enter valid value"}
            onChange={e => this.handleTextChange(e, "INTEGER")}
          />
        );
        break;
      case "LONG":
        return (
          <Form.Control
            type="text"
            className="value"
            value={this.state.item.value}
            placeholder={"Please enter valid value"}
            onChange={e => this.handleTextChange(e, "INTEGER")}
          />
        );
        break;
      case "STRING":
        return (
          <Form.Control
            type="text"
            className="value"
            value={this.state.item.value}
            placeholder={"Please enter valid value"}
            onChange={e => this.handleTextChange(e, "STRING")}
          />
        );
        break;
      case "TEXT":
        return (
          <Form.Control
            type="text"
            value={this.state.item.value}
            className="value"
            placeholder={"Please enter valid value"}
            onChange={e => this.handleTextChange(e, "STRING")}
          />
        );
        break;
      case "DECIMAL":
        return (
          <Form.Control
            type="text"
            value={this.state.item.value}
            className="value"
            placeholder={"Please enter valid value"}
            onChange={e => this.handleTextChange(e, "DECIMAL")}
          />
        );
        break;
    }
  }

  handleEditItem = index => {
    if (
      this.props.attrItem.value !== null &&
      this.props.attrItem.field !== null &&
      this.props.attrItem.segmentCondition !== null
    ) {
      this.setState({
        isSaved: !this.state.isSaved,
        item: this.props.attrItem
      });
    } else {
      this.props.onRemove(index);
    }
  };
  btnStatus() {
    const item = this.state.item;
    const errors = this.state.errors;
    console.log( item.segmentCondition !== null &&
      item.field !== null &&
      item.value !== null &&
      item.value !== "" &&
      errors.fieldValue === null && errors.fieldValue === "")
    if (
      item.segmentCondition !== null &&
      item.field !== null &&
      item.value !== null &&
      item.value !== "" &&
      errors.fieldValue === null 
    ) {
      return false;
    } else {
      return true;
    }
  }
  render() {
    const { index, attrItem } = this.props;
    const { item, isSaved } = this.state;

    return (
      <div className="recomendations-check-select">
        {isSaved ? (
          <React.Fragment>
            <div className="recomendations-check-select-saved">
              <div className="recomendations-saved-text">
                <strong>{item.field} </strong>
                <span> {item.segmentCondition} </span>
                <strong> {item.value} </strong>
              </div>
              <div className="recomendations-saved-btns">
                <button
                  type="button"
                  onClick={() => this.handleEditItem(index)}
                  className="btn btn-secondary"
                >
                  <i className="icon-edit-light l-edit"></i>
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => this.props.onRemove(index)}
                >
                  <i className="icon-delete-light l-delete"></i>
                </button>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Select
              name={"fieldType"}
              value={this.state.item.field}
              disabled={false}
              optionList={[
                { id: null, name: "Select Attribute" },
                {
                  id: "PRODUCT_EXTERNAL_ID",
                  name: "Product ID"
                },
                { id: "PRODUCT_NAME", name: "Product Name", type: "String" },
                { id: "PRODUCT_SHOP_URL", name: "Product Url", type: "Text" },
                {
                  id: "PRODUCT_IMAGE_URL",
                  name: "Product Image url"
                },
                {
                  id: "PRODUCT_THUMBNAIL_URL",
                  name: "Product Image thumbnail url"
                },
                { id: "PRODUCT_PRECIS", name: "Product Precis" },
                {
                  id: "PRODUCT_AVAILABLE",
                  name: "Product Available"
                },
                {
                  id: "PRODUCT_LIST_PRICE_EX_TAX",
                  name: "Product List price excluding tax"
                },
                {
                  id: "PRODUCT_LIST_PRICE_INC_TAX",
                  name: "Product List price inc tax"
                },
                {
                  id: "PRODUCT_PRICE_BREAKS_DESCRIPTION",
                  name: "Product Description"
                }
              ]}
              classNameSelect="select-control"
              classNameWrapper="form-control select-control-wrapper attribute"
              classNameContainer=""
              classNameOptionListContainer="select-control-option"
              classNameOptionListItem=""
              customStyleSelect={{}}
              customStyleWrapper={{}}
              customStyleContainer={{}}
              customStyleOptionListContainer={{}}
              customStyleOptionListItem={{}}
              onChange={attr => this.setField(attr)}
              onBlur={() => {}}
              validationOption={{
                name: "Attribute Type",
                check: true,
                required: true
              }}
            />

            {item.field !== null && (
              <Select
                tabIndex="4"
                name={"equals"}
                value={item.segmentCondition}
                disabled={false}
                optionList={this.renderTypeOptions()}
                classNameSelect="select-control"
                classNameWrapper="form-control select-control-wrapper equals"
                classNameContainer=""
                classNameOptionListContainer="select-control-option"
                classNameOptionListItem=""
                customStyleSelect={{}}
                customStyleWrapper={{}}
                customStyleContainer={{}}
                customStyleOptionListContainer={{}}
                customStyleOptionListItem={{}}
                onChange={attr => this.setCondition(attr)}
                onBlur={() => {}}
                validationOption={{
                  name: "condition",
                  check: true,
                  required: true
                }}
              />
            )}
            <div className="position-relative">
              {item.segmentCondition !== null && this.checkBlankType()}
              {this.state.errors !== null &&
                this.state.errors.fieldValue !== "" && (
                  <span className="text-danger">
                    {this.state.errors.fieldValue}
                  </span>
                )}
            </div>

            <div className="save-cancel-btn-div">
              <Button
                className="fa fa-save"
                disabled={this.btnStatus()}
                onClick={() =>
                  this.props.onUpdateRule(this.props.index, this.state.item)
                }
              >
                <i className="icon-disable"></i>
                Save
              </Button>
              <span
                onClick={() => this.handleEditItem(index)}
                className="fa fa-close"
              >
                Cancel
              </span>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default AttributeRule;
