import React, {Component} from "react";
import {Form, ListGroup} from "react-bootstrap";
import ViewAttributes from "./attributeList";

class ViewDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataView: null,
      searchStarted: false,
      searchStartedBegin: false
    };
  }

  componentDidMount() {
    this.setState({ dataView: this.props.dataView });
  }

  componentWillReceiveProps = nextProps => {
    this.setState({
      searchStarted: nextProps.dataView !== this.state.dataView
    });
  };

  onSearch = (e, dataView) => {
    let searchWhat = e.target.value;

    this.setState({ searchStartedBegin: searchWhat !== ""}, () =>
      this.props.onSearch(searchWhat, dataView)
    );

  };

  render() {
    if (this.props.editContent !== null) {
      return (
        <section
          className={
            this.props.showDetail ? "view-select p-select" : "view-select "
          }
        >
          <dl className="d-flex flex-row justify-content-between align-items-center  mb-0 p-3">
            <dt>
              <i
                className="icon-chevron-thin-left left-arrow"
                onClick={this.props.onhideDetail}
              />
              <Form.Control
                type="text"
                onChange={this.props.onNameEdit}
                value={this.props.currentView.name}
                placeholder="Filter Name"
                maxLength="25"
              />
            </dt>
            <button
              className="btn-update mr-3"
              onClick={this.props.onhideDetail}
            >Save
            </button>
            <dd>
              <i
                className="icon-delete-light delete-list"
                onClick={() =>
                  this.props.onDeleteView(this.props.currentView.id)
                }
              />
            </dd>
          </dl>
          <ListGroup as="ul">
            {/* <h6>Select Elements to View</h6> */}
            <div className="view-list-top">
              <div className="filter-input">
                <Form.Group>
                  <span className="icon-search"/>
                  <Form.Control
                    type="text"
                    placeholder="Search Attributes to View..."
                    autoComplete="off"
                    ref={input => {
                      this.mySearch = input;
                    }}
                    onChange={e => this.onSearch(e, this.state.dataView)}
                  />
                </Form.Group>
              </div>
            </div>

            <ViewAttributes
              showDisplayName={true}
              enableOnlyCoreAttributes={this.props.newdata}
              attributesData={this.props.dataView}
              onToggleAttributeGroup={this.props.ontoggleSection}
              onToggleAttribute={this.props.ontoggleAttribute}
              allAttributesStateChanged={() => {}}
            />

          </ListGroup>
        </section>
      );
    } else {
      return "Loading";
    }
  }
}

export default ViewDetail;
