import React, { Component } from "react";

import { Nav } from "react-bootstrap";
class FilterSection extends Component {
  constructor() {
    super();
    this.state = {
      usersInvites: [],
      isMobile: "",
      inviteKeys: "",
      rollUpKeys: "",
      inviteUsersLoading: false,
      inviteStates: {
        NOT_SENT: "Not Sent",
        SENT_NO_RESPONSE: "Pending",
        CLICKED: ["Created"],
        ACCOUNT_CREATED: "Created",
        ACCOUNT_LINKED: "Created",
        VERIFICATION_APPLIED: "Created",
        EXPIRED: "Expired"
      },
      rollUpStatus: {
        NOT_SENT: "Not Sent",
        SENT_NO_RESPONSE: "Pending",
        CREATED: "Created",
        EXPIRED: "Expired"
      },
      createdGroup: [
        "CLICKED",
        "ACCOUNT_CREATED",
        "ACCOUNT_LINKED",
        "VERIFICATION_APPLIED"
      ]
    };
  }

  filteringInviteCount = filtereWhat => {
    function isAutobot(transformer) {
      return transformer.rollUpStatus === filtereWhat;
    }
    var filteredInvite =
      filtereWhat !== "All"
        ? this.state.usersInvites.filter(isAutobot)
        : this.state.usersInvites;
    return filteredInvite.length;
  };
  componentDidMount() {
    this.setState({
      usersInvites: this.props.usersInvites,
      isMobile: this.props.isMobile,
      inviteKeys: this.props.inviteKeys,
      rollUpKeys: this.props.inviteKeys,
      inviteUsersLoading: true
    });
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    if (nextProps.usersInvites !== this.props.usersInvites) {
      this.setState({
        usersInvites: nextProps.usersInvites,
        isMobile: nextProps.isMobile,
        inviteKeys: nextProps.inviteKeys,
        rollUpKeys: nextProps.inviteKeys
      });
    }
  }

  render() {
    const { isMobile, inviteKeys, inviteUsersLoading, rollUpKeys } = this.state;

    return (
      inviteUsersLoading && (
        <React.Fragment>
          {!isMobile ? (
            <Nav
              defaultActiveKey="All"
              className="flex-column item-menu"
              as="ul"
              onSelect={selectedKey =>
                this.props.onFilteringInvite({ selectedKey })
              }
            >
              <Nav.Link className="position-relative" as="li" eventKey="All">
                All
                <span>{this.filteringInviteCount("All")}</span>
              </Nav.Link>
              {rollUpKeys.map((rollUpKeys, key) => (
                <Nav.Link
                  key={key}
                  className="position-relative"
                  as="li"
                  eventKey={rollUpKeys}
                >
                  {this.state.rollUpStatus[rollUpKeys]}
                  <span>{this.filteringInviteCount(rollUpKeys)}</span>
                </Nav.Link>
              ))}
            </Nav>
          ) : (
            <Nav
              defaultActiveKey="All"
              as="ul"
              onSelect={selectedKey =>
                this.props.onFilteringInvite({ selectedKey })
              }
            >
              <Nav.Link as="li" eventKey="All" className="display-block w-100">
                All
                <span className="float-right">
                  {this.filteringInviteCount("All")}
                </span>
              </Nav.Link>
              {rollUpKeys.map((rollUpKeys, key) => (
                <Nav.Link
                  key={key}
                  as="li"
                  eventKey={rollUpKeys}
                  className="display-block w-100"
                >
                  {this.state.rollUpStatus[rollUpKeys]}
                  <span className="float-right">
                    {this.filteringInviteCount(rollUpKeys)}
                  </span>
                </Nav.Link>
              ))}
            </Nav>
          )}
        </React.Fragment>
      )
    );
  }
}

export default FilterSection;
