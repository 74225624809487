const connectionTypes = [
  { name: "POSTGRESQL", image: require("./assets/img/post.svg") }
  , { name: "REDSHIFT", image: require("./assets/img/redshift.svg") }
  , { name: "MYSQL", image: require("./assets/img/mysql.svg") }
  , { name: "SQLSERVER", image: require("./assets/img/sqlserver.svg") }
  , { name: "OPENTABLE", image: require("./assets/img/opentable.svg") }
  , { name: "WIRELESS_SOCIAL", image: require("./assets/img/wireless_social.jpeg") }
];

const hiddenConnectionTypes = [ "OPENTABLE", "WIRELESS_SOCIAL" ];

const connectionTypeLabels = {
  "POSTGRESQL": "PostgreSQL",
  "REDSHIFT": "Redshift",
  "MYSQL": "MySQL",
  "SQLSERVER": "MS SQL Server",
  "OPENTABLE": "OpenTable API",
  "WIRELESS_SOCIAL": "Wireless Social"
}

export default connectionTypes;
export { hiddenConnectionTypes, connectionTypeLabels };
