import React, {Component} from "react";
import {ListGroup} from "react-bootstrap";

class RadioButton extends Component {
  constructor(props) {
    super(props);

    const firstOption = props.value || props.options[0].id;
    this.props.onSelect(firstOption);
  }

  render() {
    const {options, value} = this.props;
    return (
      <section className="enrichment">
        <div className="body-content-wrap no-scroll bg-white w-100 p-0 mw-100">
          <div className="content-section w-100 mw-100">
            <ListGroup className="p-3 w-100 m-0">
              {
                options.map((option, idx) =>
                  <ListGroup.Item
                    key={idx}
                    className="d-flex flex-row"
                    as="li"
                    onClick={_ => this.setOption(option)}
                  >
                    <i
                      className={value === option.id ? "icon-check_circle active" : "icon-check_circle"}
                    />
                    <p className="p-0 m-0">
                      <span>{option.title}</span>
                      {option.subTitle}
                    </p>
                  </ListGroup.Item>
                )
              }
            </ListGroup>
          </div>
        </div>
      </section>
    );
  }

  setOption(option) {
    this.props.onSelect(option.id);
  }
}

export default RadioButton;
