import React, {Component} from "react";
import TraitsAPI from "../../../../../services/traits";
import DataGrid from "../../common/DataGrid";
import PropTypes from "prop-types";

const propTypes = {
  traitId: PropTypes.number.isRequired,
  dataViewId: PropTypes.number,
  customerList: PropTypes.array.isRequired,
  isUpdateState: PropTypes.bool.isRequired,
  onCustomerDetail: PropTypes.func.isRequired,
};

class TraitCustomersDataGrid extends Component {

  getCustomerList = (formData) => {
    return TraitsAPI.getCustomerList({
      traitId: formData.targetId,
      dataViewId: formData.dataViewId,
      pageNo: formData.pageNo
    });
  }

  getCustomerListMobile = (formData) => {
    return TraitsAPI.getCustomerListMobile({
      traitId: formData.targetId,
      dataViewId: formData.dataViewId,
      pageNo: formData.pageNo
    });
  }

  render() {
    const {traitId, dataViewId, isUpdateState, onCustomerDetail, customerList} = this.props;
    return (
        <DataGrid
            targetId={traitId}
            dataViewId={dataViewId}
            isUpdateState={isUpdateState}
            onCustomerDetail={onCustomerDetail}
            customerList={customerList}
            getCustomerList={this.getCustomerList}
            getCustomerListMobile={this.getCustomerListMobile}
            emptyTitle="This Customer Trait is empty."
        />
    );
  }
}

TraitCustomersDataGrid.propTypes = propTypes;

export default TraitCustomersDataGrid;
