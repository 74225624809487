import React, {Component} from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import {Bar} from "react-chartjs-2";
import equal from "fast-deep-equal";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const labels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

class BarChartProgressFriday extends Component {

    constructor(props) {
        super(props);

        this.state = {
            weekData: "",
            weekDataFormatted: "",
            barData: [],
            barDataFormatted: [],
            data: {
                labels: '',
                datasets: [],
            },
        };
    }

    componentDidMount() {
        this.setWeekData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!equal(this.props.cardData, prevProps.cardData)) {
            this.setWeekData();
        }
    }

    setWeekData() {
        const {cardData} = this.props;

        if (cardData) {
            this.setState({
                weekData: cardData?.data?.weekData,
                weekDataFormatted: cardData?.data?.weekData_formatted,
            })
        }
        if (cardData?.data?.weekData) {
            this.addData(['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
                cardData?.data?.weekData, cardData?.data?.weekData_formatted);
        }
    }

    addData(days, weekData, weekDataFormatted) {
        const barDataResult = [];
        const barDataFormattedResult = [];

        for (let i = 0; i < days.length; i++) {
            if (weekData) {
                const item = weekData[`${days[i]}`];
                barDataResult.push(!!item.actual ? item.actual : item.forecast);
            }
            if (weekDataFormatted) {
                const itemFormatted = weekDataFormatted[`${days[i]}_formatted`];
                barDataFormattedResult.push(itemFormatted.actual_formatted !== '-' ? itemFormatted.actual_formatted : itemFormatted.forecast_formatted);
            }
          }

        this.setState({
            barData: barDataResult,
            barDataFormatted: barDataFormattedResult,
        })
    }

    getColor(day) {
        const {weekData} = this.state;

        if (!!weekData) {
            return !!weekData[day]?.actual ? "#0a83c4" : "#00BDFF";
        }
        return "#0a83c4";
    }

    render() {
        let data = {
            labels: labels,
            datasets: [
                {
                    data: this.state.barData,
                    backgroundColor: [
                        this.getColor("monday"),
                        this.getColor("tuesday"),
                        this.getColor("wednesday"),
                        this.getColor("thursday"),
                        this.getColor("friday"),
                        this.getColor("saturday"),
                        this.getColor("sunday")
                    ],
                    borderWidth: 0,
                },
            ],
        };
        let options = {
            maintainAspectRatio: false,
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                },
                y: {
                    ticks: {
                        display: true,
                    },
                    grid: {
                        drawBorder: false,
                        display: false,
                    },
                },
            },
            indexAxis: "x",
            elements: {
                bar: {
                    borderWidth: 2,
                },
            },
            responsive: true,
            plugins: {
                title: {
                    display: false,
                },
                legend: {
                    display: false,
                    position: "bottom",
                    align: "start"
                },
                tooltip: {
                    callbacks: {
                        label: (items) => {
                            return `${this.state.barDataFormatted[items.dataIndex]}`;
                        }
                    }
                },
            },
        }

        return (
            <>
                <Bar options={options} data={data}/>
            </>
        );
    }
};
export default BarChartProgressFriday;
