import ApiService from "./tenantApiService";

function getDestination() {
    const requestUrl = "/destination";

    return ApiService.get(requestUrl).then(response => {
        return response.data;
    });
}

function createOrUpdateCampaign(data, isUpdate) {
    const requestUrl = "/destination";
    if (!isUpdate) {
        return ApiService.post(requestUrl, data).then(response => response.data);
    } else {
        return ApiService.put(requestUrl + "/" + data.id, data).then(response => response.data);
    }
}

function getAttributesMapping(enrichmentType, recommendationCount, integrationPreview) {
    const enrichmentTypePart = enrichmentType ? `enrichmentType=${enrichmentType}&` : "";

    const requestUrl = `/destination/fields/mapping?${enrichmentTypePart}recommendationCount=${recommendationCount}`;
    return ApiService.post(requestUrl, integrationPreview).then(response => response.data);
}

function deleteDestination(id) {
    const requestUrl = "/destination/" + id;

    return ApiService.delete(requestUrl).then(response => response.data);
}

function getIntegration(excludeTypes) {
    const excludeTypesParam = (!excludeTypes || excludeTypes.length === 0) ? '' : '&excludeTypes=' + excludeTypes.join(',');
    const requestUrl = "/connection?filter=integration&enabledOnly=true" + excludeTypesParam;

    return ApiService.get(requestUrl).then(response => response.data);
}

function getIntegrationAttr(integrationId, destinationType) {
    const dataSrcAttrUrl = "/destination/" + destinationType + "/attributelist/" + integrationId;

    return ApiService.get(dataSrcAttrUrl).then(response => response.data);
}

function getAttributesByDestAttrs(attrs) {
    const dataSrcAttrUrl = "/destination/attrs";

    return ApiService.post(dataSrcAttrUrl, attrs).then(response => response.data);
}
function startSyncNow(distinationId) {
    const requestUrl = "/destination/sync_now?destinationId=" + distinationId;
    return ApiService.post(requestUrl, distinationId).then(response => response.status);
}

export default {
    getDestination,
    createOrUpdateCampaign,
    deleteDestination,
    getIntegration,
    getAttributesMapping,
    getIntegrationAttr,
    startSyncNow,
    getAttributesByDestAttrs
};
