import React, {Component} from "react";

import LeftSideBar from "./components/leftSideBar";
import TopNavigation from "./components/topNavigation";
import TopTitle from "../components/topTitle";
import SelectSegment from "./pages/selectSegment";
import Destinations from "./pages/destinations";
import ReviewDestination from "./pages/reviewDestination";
import Recomendations from "./pages/recomendations";
import UtmInfo from "./pages/utminfo";

import DestinationAPI from "../../../../services/destination";
import _, {get} from "lodash";
import {toast} from "react-toastify";

class Destination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: "Create a Hyper Personalised Campaign",
      currentSection: "SELECT_SEGMENT",
      currentSectionItems: {},
      availableNavItems: [
        {
          title: "Select Segment",
          pageTag: "SELECT_SEGMENT",
          leftTitle: "Select Segment"
        },
        {
          title: "Destinations",
          pageTag: "DESTINATIONS",
          leftTitle: "Destinations Push"
        },
        {
          title: "Recommendations",
          pageTag: "RECOMMENDATIONS",
          leftTitle: "Recommendations"
        },
        {
          title: "UTM Info",
          pageTag: "UTM_INFO",
          leftTitle: "UTM Codes and Tracking"
        },
        {
          title: "Review & Activate",
          pageTag: "REVIEW_ACTIVATE",
          leftTitle: "Review & Activate Campaign"
        }
      ],
      navItems: [],
      currentCard: {},
      newDestination: [],
      pageErrors: {
        isSegment_HasError: null,
        isRecomendation_HasError: null,
        isUtmInfo_HasError: null,
        isDestination_HasError: null
      }
    };
  }

  componentDidMount() {
    this.updateNavItems(this.props.location.state.selectedCard.steps);
    this.setState({currentCard: this.props.location.state.selectedCard});
    this.setState({pageTitle: this.props.location.state.selectedCard.name});
    if (this.props.location.state.selectedContent) {
      this.setState({newDestination: this.props.location.state.selectedContent}, this.checkCurrentPage);

      this.props.onContextChange("DESTINATION");
      this.props.onContextIdChange(this.props.location.state.selectedContent.id);
    }
  }

  componentWillUnmount() {
    this.props.refreshData();
    this.props.onContextChange(null);
    this.props.onContextIdChange(null);
  }

  checkCurrentPage = () => {
    if (this.props.location.state.currentSection) {
      this.handleChangeSection(this.props.location.state.currentSection);
    }
  };

  updatedNewDestination = (type, data) => {
    let newDestination = this.state.newDestination;
    newDestination[type] = data;

    this.setState({newDestination}, this.checkIsAllValid);
  };

  checkIsAllValid = () => {
    let {navItems, newDestination, pageErrors} = this.state;

    navItems.map(items => {
      switch (items.pageTag) {
        case "SELECT_SEGMENT":
          pageErrors.isSegment_HasError =
            newDestination.segment !== undefined
              ? newDestination.segment.hasError
              : true;

        case "RECOMMENDATIONS":
          pageErrors.isRecomendation_HasError =
            newDestination.recomendation !== undefined
              ? newDestination.recomendation.hasError
              : true;

        case "UTM_INFO":
          pageErrors.isUtmInfo_HasError =
            newDestination.utmInfo !== undefined &&
            newDestination.utmInfo.hasError;

        case "DESTINATIONS":
          pageErrors.isDestination_HasError =
            newDestination.destination !== undefined &&
            newDestination.destination.hasError;
      }
      this.setState({pageErrors});
    });
  };

  updateNavItems = steps => {
    let navItems = [];
    steps.map(item => {
      let findItem = _.filter(this.state.availableNavItems, {pageTag: item});
      navItems.push(findItem[0]);
    });
    this.setState({navItems}, this.handleChangeSection);
  };

  createUpdateAudience = isUpdate => {
    let destnationData = this.state.newDestination;
    let currentdestinationType = this.state.currentCard.enum;

    //Segment Section Start
    let segmentData = {
      title: destnationData.segment.title,
      segmentId: destnationData.segment.segmentID,
      description: destnationData.segment.description
    };
    //Segment Section Start

    //Recomendation Section Start
    let recomendationData = {
      numberRecommendations: null,
      enrichmentType: null,
      filters: null
    };

    if (
      _.includes(this.state.currentCard.steps, "DESTINATIONS") &&
      destnationData.recomendation !== undefined
    ) {

      let filters = [];
      destnationData.recomendation.attributeList.map(attribute => {
        let att = {
          productAttributeDataTag: attribute.field,
          matchCondition: attribute.segmentCondition,
          dataSourceAttributeType: attribute.type,
          value: attribute.value
        };
        filters.push(att);
      });

      recomendationData = {
        numberRecommendations: destnationData.recomendation.recommendationCount,
        enrichmentType: destnationData.recomendation.fieldValue,
        filters: filters
      };
    }
    //Recomendation Section End

    //UTM Section Start
    let utmData = "";
    if (destnationData.utmInfo !== undefined) {
      utmData = {
        utmSourceEnabled: destnationData.utmInfo.utmOptions[0].selected,
        utmSourceText: destnationData.utmInfo.utmOptions[0].selected
          ? destnationData.utmInfo.utmOptions[0].value
          : null,
        utmMediumEnabled: destnationData.utmInfo.utmOptions[1].selected,
        utmMediumText: destnationData.utmInfo.utmOptions[1].selected
          ? destnationData.utmInfo.utmOptions[1].value
          : null,
        utmCampaignEnabled: destnationData.utmInfo.utmOptions[2].selected,
        utmCampaignText: destnationData.utmInfo.utmOptions[2].selected
          ? destnationData.utmInfo.utmOptions[2].value
          : null,
        allowTrackingParameter: destnationData.utmInfo.utmAllowDistil
      };
    } else {
      utmData = {
        utmSourceEnabled: false,
        utmSourceText: null,
        utmMediumEnabled: false,
        utmMediumText: null,
        utmCampaignEnabled: false,
        utmCampaignText: null,
        allowTrackingParameter: false
      };
    }
    //UTM Section End

    //Destination Section Start
    let integrationData = [];

    (destnationData?.destination || []).forEach(destination => {
      integrationData.push({
        connectionId: destination.destinationId,
        id: destination.id,
        listId: destination.listId,
        integrationSettings: destination.integrationSettings,
        attributes: destination.attributes.map(attribute => {
          return {
            id: attribute.id,
            attributeId:
              attribute.attributeTag === null || attribute.attributeDataTag === null
                ? attribute.attributeId
                : null,
            attributeDataTag: attribute.attributeDataTag ?? attribute.attributeTag,
            label: attribute.label,
            externalId: attribute.externalId,
          };
        })
      })
    });

    // Destination Section End

    let newAudience = {
      type: currentdestinationType,
      enabled: true,
      autoSync: true,
      id: destnationData.id,
      destinationIntegrations: integrationData,
      ...segmentData,
      ...recomendationData,
      ...utmData
    };
    this.createOrUpdateCampaign(
      newAudience,
      integrationData,
      destnationData,
      isUpdate
    );
  };

  createOrUpdateCampaign = async (
    newAudience,
    integrationData,
    destnationData,
    isUpdate = false
  ) => {
    try {
      await DestinationAPI.createOrUpdateCampaign(newAudience, isUpdate).then(response => {
        let destinationResult = response;

        destnationData.destination.forEach(d => {
          // find created integration by connection id (stored as destinationId on FE)
          d.id = response.destinationIntegrations.find(i => i.connectionId === d.destinationId).id;
        })

        let message = `Campaign ${isUpdate ? "updated" : "created"} successfully`;

        toast.success(
          message,
          this.props.campaignCreated(
            destnationData,
            destinationResult.id,
            destinationResult.apiAccessUrl,
            this.state.currentCard
          ),
          1000
        );
        localStorage.setItem("checkChange", false);
      })
    } catch (err) {
      if (get(err, 'response.status') === 500) {
        toast.error(err.response.data.debugMessage);
      } else {
        toast.error("Error while creating campaign");
      }
    }
  };

  renderSection(section) {
    switch (section) {
      case "SELECT_SEGMENT":
        return (
          <SelectSegment
            newDestination={this.state.newDestination}
            onPageChange={this.updatedNewDestination}
            pageErrors={this.statepageErrors}
            cardType={this.state.currentCard.enum}
          />
        );
      case "DESTINATIONS":
        return (
          <Destinations
            enrichmentType={this.state.newDestination?.recomendation?.fieldValue}
            currentCardType={this.state.currentCard.enum}
            newDestination={this.state.newDestination}
            onPageChange={this.updatedNewDestination}
          />
        );
      case "REVIEW_ACTIVATE":
        return (
          <ReviewDestination
            newDestination={this.state.newDestination}
            onPageChange={this.updatedNewDestination}
            pageErrors={this.state.pageErrors}
            createUpdateAudience={this.createUpdateAudience}
            currentCardType={this.state.currentCard.enum}
            currentCardSteps={this.state.currentCard.steps}
          />
        );

      case "RECOMMENDATIONS":
        return (
          <Recomendations
            newDestination={this.state.newDestination}
            onPageChange={this.updatedNewDestination}
          />
        );

      case "UTM_INFO":
        return (
          <UtmInfo
            newDestination={this.state.newDestination}
            onPageChange={this.updatedNewDestination}
          />
        );
    }
  }

  handleChangeSection = (currentSection = "SELECT_SEGMENT") => {
    let findItem = _.filter(this.state.availableNavItems, {
      pageTag: currentSection
    });

    this.setState({currentSection, currentSectionItems: findItem[0]});
  };

  render() {
    const {
      currentSection,
      navItems,
      currentSectionItems,
      pageErrors,
      currentCard
    } = this.state;
    return (
      <div className="destination-wrap">
        <TopTitle title={this.state.pageTitle}/>
        <TopNavigation
          navItems={navItems}
          currentSection={currentSection}
          onChangeSection={this.handleChangeSection}
          onVerifyChange={this.props.onVerifyChange}
        />
        <div className="body-Section">
          <LeftSideBar
            navItems={navItems}
            newDestination={this.state.newDestination}
            currentSection={currentSectionItems}
            pageErrors={pageErrors}
          />
          <div className="right-Section">
            {this.renderSection(currentSection)}
          </div>
        </div>
      </div>
    );
  }
}

export default Destination;
