import React, { Component } from "react";
import NewsfeedAPI from "../../../../../../services/newsfeed";
import _ from "lodash";

// import AreaBump from "../../Cardgraphs/areaBumpChart";
import LineChart from "../../Cardgraphs/lineChartMulti";
import TrendingBarChart from "../../Cardgraphs/trendingBarChart";
import moment from "moment";
class TrendingCardWrap extends Component {
  constructor(props) {
    super(props);
    this.myChartView = React.createRef();
    this.state = {
      result: null,
      selectedDate: "",
      myDataLength: null,
      containerSize: 750
    };
  }

  componentWillMount() {
    this.setState({ selectedDate: this.props.selectedDate }, () =>
      this.handleCardHistory(this.props.id)
    );
    window.addEventListener("resize", this.containerSize);
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ selectedDate: nextProps.selectedDate }, () =>
      this.dataFormating()
    );
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.containerSize);
  }

  containerSize = () => {
    if (this.myChartView.current !== null) {
      const containerSize = this.myChartView.current.offsetWidth;
      this.setState({ containerSize });
    }
  };

  handleCardHistory = async id => {
    try {
      await NewsfeedAPI.getCardHistory(id).then(response => {
        const result = response.filter(
          item =>
            item.newsfeedStackCardType.cardType ===
            "PRODUCT_RECOMMENDATIONS_DAILY_SUMMARY_TRENDING"
        );

        if (result.length > 0) {
          this.setState({ data: result }, () => this.dataFormating());
        } else {
          this.setState({ data: null });
        }
      });
    } catch (err) {}
  };

  dataFormating = () => {
    if (this.state.data !== null && this.state.data !== undefined) {
      let loopLength = this.state.data[0].cardDetails.trendingItems.length;
      let result = [];

      if (loopLength > 0) {
        for (let i = 0; i < loopLength; i++) {
          result.push({
            id: this.state.data[0].cardDetails.trendingItems[i].productName,
            data: []
          });

          for (let j = 0; j < this.state.data.length; j++) {
            if (this.props.selectedDate !== undefined) {
              if (
                moment(this.state.data[j].dateShowAfter).startOf("day") <=
                moment(this.state.selectedDate).startOf("day")
              ) {
                result[i].data.push({
                  x: this.state.data[j].dateShowAfter,
                  y: this.state.data[j].cardDetails.trendingItems[i]
                    .tendingScore
                });

                // myDataLength = result[i].data.length;
              }
            } else {
              result[i].data.push({
                x: this.state.data[j].dateShowAfter,
                y: this.state.data[j].cardDetails.trendingItems[i].tendingScore
              });
            }
          }
          // this.setState({myDataLength:result[i].data.length})
        }
      }
      this.setState(
        { result, myDataLength: result[0].data.length },
        this.containerSize
      );
    }
  };
  getSubdata(date, items) {
    let subData = [];
    items.map(item =>
      subData.push({
        x: date,
        y: item.tendingScore
      })
    );

    return subData;
  }

  renderBarChart = trendingSingleData => {
    let trendingData = [];
    for (let i = 0; i < trendingSingleData.length; i++) {
      trendingData.push({
        name: trendingSingleData[i].id,
        records: trendingSingleData[i].data[0].y
      });
    }

    return (
      <TrendingBarChart
        data={trendingData}
        size={(this.state.containerSize - 40) / 2}
      />
    );
  };

  render() {
    if (this.state.result !== null) {
      if (this.state.result[0].data.length === 1) {
        return (
          <div ref={this.myChartView}>
            {this.renderBarChart(this.state.result)}
          </div>
        );
      } else {
        return (
          this.state.result !== null && <LineChart data={this.state.result} />
        );
      }
    } else {
      return <p></p>;
    }
  }
}

export default TrendingCardWrap;
