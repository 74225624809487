import React, { Component } from "react";
import connectionTypes from "../connectionTypes";
import { Button } from "react-bootstrap";
import {globalConstants} from "../../../../../variables/globalVariables";
import PropTypes from "prop-types";

const propTypes = {
  formType: PropTypes.string.isRequired,
  onBackPress: PropTypes.func.isRequired,
  editConnection: PropTypes.bool,
  customHelp: PropTypes.element,
  helpArticleUri: PropTypes.string
};

const defaultProps = {
  editConnection: false,
  helpArticleUri: "/articles/10763742502813-Introduction-to-Integrations"
}

class NewConnectionMeta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formtype: {
        MAILCHIMP: "Mailchimp",
        HUBSPOT: "Hubspot",
        SALESFORCE: "Salesforce",
        CAMPAIGN_MONITOR: "Campaign Monitor",
        KLAVIYO: "Klaviyo",
        KLAVIYO_V2: "Klaviyo",
        DOT_DIGITAL: "Dotdigital",
        DOT_DIGITAL_V3: "Dotdigital",
        SHOPIFY: "Shopify",
        ZEN_DESK: "Zendesk",
        GOOGLE_ADS: "Google Ads",
        PAYPAL: "PayPal",
        FACEBOOK_LEAD_ADS: "Facebook Ads",
        BRAZE: "Braze"
      }
    };
  }

  connectionIcon() {
    const fileterdData = connectionTypes.filter(
      item => item.name === this.props.formType
    );
    return (
      <div className="logo-wrap">
        <img src={fileterdData[0].image} alt="connectionLogo" />
      </div>
    );
  }

  render() {
    const {formtype}=this.state;
    return (
      <React.Fragment>
        <div className="post-icon">
          <div className="top-img">
            <Button
              variant="secondary"
              className="d-block d-md-none back-arrow"
              onClick={this.props.onBackPress}
            >
              <span className="icon-arrow-left" />{" "}
            </Button>

            {this.connectionIcon()}
          </div>
        </div>
        {/* <div className="top-section">
          <h4 className="font-weight-semiBold">
            {!this.props.editConnection && "New"} {this.props.formType}{" "}
            Connection
          </h4>
        </div> */}

        <div className="top-section">
          <h4 className="font-weight-semiBold">
            {!this.props.editConnection && "New"} {formtype[this.props.formType]} Connection
          </h4>
        </div>

        <div className="configure-section">
          <div className="configrations">
            <p>
              {this.props.customHelp ? (this.props.customHelp) : (
                  <React.Fragment>
                    For detailed description on how to connect - please visit the
                    <a href={globalConstants.HELP_BASE_URL + this.props.helpArticleUri}
                       target="_blank"
                       rel="noopener noreferrer">
                      <span>Distil documentation</span>
                    </a>
                  </React.Fragment>
              )}
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

NewConnectionMeta.propTypes = propTypes;
NewConnectionMeta.defaultProps = defaultProps;

export default NewConnectionMeta;
