import React, { Component } from "react";
import { ListGroup } from "react-bootstrap";
import Enrichment from "../enrichment";
class Sidebar extends Component {
  renderList() {
    const {
      enrichments,
      onContextChange,
      currentEnrichmentType
    } = this.props;
    const Enrichments = {
      CHRONOTYPE: "Chronotype",
      DEMOGRAPHIC: "Demographics",
      ENGAGEMENT: "Engagement",
      RFIM: "Recency, Frequency, Intensity, Momentum",
      PRODUCT_RECOMMENDATIONS_MIX: "Product Recommendations ",
      CONTENT_RECOMMENDATIONS_MIX: "Content Recommendations ",
      SPEND_BRACKET: "Spend Brackets"
    };
    var showEnrichments = [
      "CHRONOTYPE",
      "ENGAGEMENT",
      "SPEND_BRACKET",
      "PRODUCT_RECOMMENDATIONS_MIX"
    ];

    if (enrichments.length > 0) {
      return (
        <ListGroup as="ul">
          {enrichments.map(
            (list, key) =>
              showEnrichments.indexOf(list.enrichmentType) > -1 && (
                <ListGroup.Item
                  as="li"
                  key={key}
                  className={
                    currentEnrichmentType === list.enrichmentType && "active"
                  }
                  onClick={() => onContextChange(list, key)}
                >
                  <i className={`icon-${list.enrichmentType}`} />
                  {Enrichments[list.enrichmentType]}
                </ListGroup.Item>
              )
          )}
        </ListGroup>
      );
    } else {
      return "not Found";
    }
  }
  render() {
    return (
      <section
        className={
          this.props.sidebarStatus ? "sideNav disable " : "sideNav enable"
        }
      >
        {this.renderList()}
      </section>
    );
  }
}

export default Sidebar;
