import React, {Component} from "react";
import AnalyticsRules from "./AnalyticsRules";
import AnalyticsSummary from "./AnalyticsSummary";
import analyticsIcon from "../../assets/img/analytics/analytics-icon.svg";
import analyticsIconActive from "../../assets/img/analytics/analytics-icon-active.svg";

class Analytics extends Component {
  state = {
    activeSection: 'Summary',
    sections: [
      {name: 'Summary'},
      {name: 'Rules'}
    ]
  }

  setCurrentSection(activeSection) {
    this.setState({activeSection})
  }

  renderSectionTabs = sections => {
    return sections.map((section, key) => (
      <div
        key={key}
        className={`tab ${section.name === this.state.activeSection && 'active-analytics-tab'}`}
        style={{
          paddingLeft: "40px",
          backgroundImage: section.name === this.state.activeSection ? `url(${analyticsIconActive})` : `url(${analyticsIcon})`,
          backgroundSize: 20,
          backgroundRepeat: "no-repeat",
          backgroundPosition: 10
        }}
        onClick={e => {
          this.setCurrentSection(section.name);
        }}
        data-scrollheading="0"
        data-scrolltab="0"
      >
        {section.name}
      </div>
    ));
  };

  renderTab() {
    let {allCustomersSegment, segment, dataSchema, allCountOfCustomerIsLoaded} = this.props;

    switch (this.state.activeSection) {
      case "Rules":
        return <AnalyticsRules segment={segment} dataSchema={dataSchema}/>;
      case "Summary":
        return <AnalyticsSummary allCountOfCustomerIsLoaded={allCountOfCustomerIsLoaded} allCustomersSegment={allCustomersSegment} segment={segment}/>;
    }
  }

  render() {
    let {sections} = this.state;
    return (
      <React.Fragment>
        <div className="analytics-tabs-holder d-md-block">
          <div className="grid-tabs analytics-tabs">
            {this.renderSectionTabs(sections)}
            <div className={`tab_last`}/>
          </div>
        </div>
        <div className="analytics-holder">
          {this.renderTab()}
        </div>
      </React.Fragment>
    );
  }
}

export default Analytics;
