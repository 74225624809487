import React, { Component } from "react";
import moment from "moment";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import CardControlers from "./components/cardControlers";
import { get } from "lodash";

class DefaultCard extends Component {
  state = {};

  render() {
    const { cardContent, onPinnedCard, onCardHistory, keyIndex } = this.props;
    const cardTitle = {
      DATA_SOURCE_SYNC_COMPLETED:
        "The Sync for Data Source " + cardContent.stackTitle + " has completed",
      CREATED:
        "A new Data Source called " +
        cardContent.stackTitle +
        " has been created!",
      CHANGED:
        "A new Data Source called " +
        cardContent.stackTitle +
        " has been changed!"
    };
    return (
      <div className="card data-source">
        <div className="card-thumbnail">
          <span className="icon-data-source"></span>
        </div>
        <div className="card-content">
        <CardControlers
            onPinnedCard={onPinnedCard}
            cardContent={cardContent}
            onCardHistory={onCardHistory}
          />
          <div className="card-detail">
            <h6 className="card-type">
              {cardContent.stackTitle}/{cardContent.cardTitle}
            </h6>
            <h5>{cardTitle[cardContent.newsfeedStackCardType.cardType]}</h5>
            <p className="date">
              {" "}
              {moment(cardContent.dateShowAfter)
                .local()
                .format("MMMM DD, YYYY")}
              <span className="ml-1">
                {moment(cardContent.dateShowAfter)
                  .local()
                  .format("  h:mm a")}
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default DefaultCard;
