import axios from 'axios';



function getAccessToken(clientID, secret) {
    const requestUrl =  "/v1/oauth2/token?grant_type=client_credentials";
    axios.defaults.baseURL = "https://api-m.sandbox.paypal.com"
    axios.defaults.headers.post["Authorization"] = "Basic " + btoa(clientID + ":" + secret);
    axios.defaults.headers.post["Accept"] = "application/json";
    axios.defaults.headers.post["Accept-Language"] = "en_US";
    return axios.post(requestUrl).then((resposne) => {
        return resposne;
    })
}

export default {
    getAccessToken
};