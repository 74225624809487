import {globalConstants} from "./globalVariables";

export const distilShopifyAccountConfig = {
    scope:
        "read_products,write_products," +
        "read_customers,write_customers," +
        "read_all_orders," +
        "read_orders,write_orders",
    apiKey: "4f30300202829b35b7251e9c08309c58",
    redirectUrl: globalConstants.APP_URL + "/settings/integrations",
    redirectToSuccess: globalConstants.APP_URL + "/integrated/shopify",
    grant: "value"
}

export const distilGoogleAdsAccountConfig = {
    scope: "https://www.googleapis.com/auth/adwords",
    access_type: "offline",
    include_granted_scopes: "true",
    response_type: "code",
    state: "state_parameter_passthrough_value",
    client_id: "771680091595-rpkslvtm64jt5t9eebfdlnrh33k9qo4u.apps.googleusercontent.com",
    redirectUrl: globalConstants.APP_URL + "/settings/integrations",
}

export const distilFacebookAdsAccountConfig = {
    client_id: "876336253077816",
    redirect_uri: globalConstants.APP_URL + "/settings/integrations/",
    scope: "ads_management"
}
