import ApiService from "./tenantApiService";

function getDataConnections() {
  const requestUrl = "/connection?filter=database";

  return ApiService.get(requestUrl).then(response => {
    return response.data;
  });
}
function getIntegration() {
  const requestUrl = "/connection?filter=integration";

  return ApiService.get(requestUrl).then(response => {
    return response.data;
  });
}
function getDataSource(connectionId) {
  const dataSrcUrl = "/connection/" + connectionId + "/datasource";

  return ApiService.get(dataSrcUrl).then(response => {
    return response.data;
  });
}

function getDataSourceAttr(connectionId, dataSourceId) {
  const dataSrcAttrUrl =
    "/connection/" + connectionId + "/datasource/" + dataSourceId;

  return ApiService.get(dataSrcAttrUrl).then(response => {
    return response.data;
  });
}

function updateDataSourceAttr(dataSource) {
  const dataSrcAttrUrl =
    "/connection/" + dataSource.connectionId + "/datasource/" + dataSource.id;

  return ApiService.put(dataSrcAttrUrl, dataSource).then(response => {
    return response.data;
  });
}

function getOnlyDsTypesAttr(ids) {
  const dataSrcAttrUrl = "/connection/datasource/custom/attr";

  return ApiService.post(dataSrcAttrUrl, ids).then(response => {
    return response.data;
  });
}

function updateDataSourcesState(dataSourceIds, connectionId, syncTurnedOn) {
  const dataSourceStateUpdateUrl = "/connection/" + connectionId + "/datasources/sync-turned-on/" + syncTurnedOn;

  return ApiService.put(dataSourceStateUpdateUrl, dataSourceIds).then(response => {
    return response.data;
  });
}

function testConnection(data) {
  const testSrcUrl = "/testconnection";

  return ApiService.put(testSrcUrl, data).then(response => {
    return response.data;
  });
}

function refreshConnection(connectionId) {
  const refreshSrcUrl = "/connection/" + connectionId + "/refreshschema";

  return ApiService.put(refreshSrcUrl).then(response => {
    return response.data;
  });
}

function newConnection(formData) {
  const requestUrl = "/connection";

  // use axios rather than API service for login, so 401 doesn't redirect
  return ApiService.post(requestUrl, formData)
    .then(response => {
      return response.data;
    })
    .then();
}
function updateConnection(formData, id) {
  const requestUrl = "/connection/" + id;

  // use axios rather than API service for login, so 401 doesn't redirect
  return ApiService.put(requestUrl, formData)
    .then(response => {
      return response.data;
    })
    .then();
}

function disableConnection(connection, checkConnection = true) {
  const disableSrcUrl = "/connection/" + connection.id + "?checkConnection=" + checkConnection;

  return ApiService.put(disableSrcUrl, connection).then(response => {
    return response.data;
  });
}

export default {
  getOnlyDsTypesAttr,
  getDataConnections,
  getIntegration,
  getDataSource,
  testConnection,
  refreshConnection,
  disableConnection,
  newConnection,
  updateConnection,
  getDataSourceAttr,
  updateDataSourceAttr,
  updateDataSourcesState
};
