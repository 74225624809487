import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { Checkbox } from "react-inputs-validation";
import { BannerPanel } from "../../../components/Branding";
import Notification from "../../../components/Notification";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

class TrialExpired extends Component {
  constructor(props, context) {
    super(props, context);
    let selectedMembers = JSON.parse(sessionStorage.getItem("trialMember")) ||
        JSON.parse(sessionStorage.getItem("selectedMembers"));

    this.state = {
      checked: false,
      delChecked: false,
      phoneNumber: "",
      Ischecked: false,
      submitDisable: true,
      hasError: false,
      selectedMembers: selectedMembers || {}
    };
  }
  componentDidMount() {
    document.title = "Distil - Get Distil";
  }
  organizationChange = () => {
    sessionStorage.removeItem("trialMember");
    sessionStorage.removeItem("selectedMembers");
    window.location = "/";
  };
  trialOptions = () => {
    if (this.state.checked && this.state.phoneNumber == "") {
      this.setState({ hasError: true });
      toast.error("Please enter phone number");
    } else {
      // toast.success("Trial Extended");
    }
  };

  getBack = () => {
    if (sessionStorage.getItem("trialMember") !== null
      || sessionStorage.getItem("selectedMembers") !== null) {
      sessionStorage.setItem("selectedMembers", JSON.stringify(this.state.selectedMembers));
      sessionStorage.removeItem("trialMember");
      this.props.onGetBack();
      if(window.location.pathname === "/getdistil") {
        window.location = "/";
      }
    } else {
      this.organizationChange();
    }
  };

  render() {
    const { phoneNumber, selectedMembers } = this.state;

    return (
      <div className="app">
        <div className="fixed-top">
          <Notification
            selectedMembers={selectedMembers}
            isOrgTrialAccount={true}
          />
        </div>
        <div className="login">
          <div className="container-fluid">
            <div className="row no-gutters h-100">
              <div className="col-lg-5">
                <BannerPanel />
              </div>
              <div className="col-lg-7">
                <div className="content-wrap bg-white expire-trial">
                  <div className="content-inner-wrap col-xl-9 col-md-11">
                    <div className="top-wrap ">
                      <h4>Get Distil.ai</h4>
                      <p>What would you like to do?</p>
                    </div>
                    <div className="form-wrap">
                      <div className="check-supported account-checkbox ">
                        <Checkbox
                          id={"call"}
                          name={"call"}
                          onBlur={() => {}}
                          checked={this.state.checked}
                          onChange={() => {
                            this.setState({
                              checked: !this.state.checked,
                              submitDisable: this.state.checked,
                              delChecked: false
                            });
                          }}
                          labelHtml={
                            <div className="label-wrap">
                              <p>
                                Please give me a call - I would like to extend
                                my trial.
                              </p>
                            </div>
                          }
                        />
                        <div className="label-wrap form-control-trial">
                          <Form.Control
                            type="text"
                            value={phoneNumber}
                            placeholder="My phone nymber is "
                            maxlength={10}
                            onChange={(e, evt) => {
                              const re = /^[0-9\b]+$/;

                              // if value is not blank, then test the regex

                              if (
                                e.target.value === "" ||
                                re.test(e.target.value)
                              ) {
                                this.setState({
                                  phoneNumber: e.target.value
                                });
                              }
                            }}
                          />
                          {phoneNumber != "" && (
                            <p className="confirm-msg">
                              Ok great, we will be in touch.
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="check-supported account-checkbox">
                        <Checkbox
                          id={"delAccout"}
                          name={"delAccout"}
                          onBlur={() => {}}
                          checked={this.state.delChecked}
                          onChange={() => {
                            this.setState({
                              delChecked: !this.state.delChecked,
                              submitDisable: this.state.delChecked,
                              checked: false
                            });
                          }}
                          labelHtml={
                            <div className="label-wrap">
                              <p>Please delete my acccount and the data.</p>
                              {this.state.delChecked && (
                                <p>
                                  Your account and data will be scheduled for
                                  deletion - to cancel this please choose give
                                  me a call option above
                                </p>
                              )}
                            </div>
                          }
                        />
                      </div>

                      <div className="btn-wrap">
                        <button
                          type="button"
                          onClick={this.trialOptions}
                          className="btn btn-primary green-btn"
                          disabled={this.state.submitDisable}
                        >
                          Submit
                        </button>
                        <Link
                            className="return"
                            onClick={this.getBack}
                        >
                          Cancel
                        </Link>
                        <Link
                          className="return"
                          onClick={this.organizationChange}
                        >
                          Return to choose Account
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TrialExpired;
