import React, { Component } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Image from "react-bootstrap/Image";
import "./css/leftSideBar.css";
import audience from "./images/audience.svg";
class LeftSideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSegment_HasError: false,
      isRecomendation_HasError: false,
      isUtmInfo_HasError: false,
      isDestination_HasError: false
    };
  }

  render() {
    const recommendations = {
      PRODUCT_RECOMMENDATIONS_MIX: "Recommendation mix",
      PRODUCT_RECOMMENDATIONS_VIEWED_NOT_PURCHASED: "Products viewed not purchased",
      PRODUCT_RECOMMENDATIONS_VIEWED_TOGETHER: "Products viewed together",
      PRODUCT_RECOMMENDATIONS_PURCHASED_TOGETHER: "Products purchased together",
      PRODUCT_RECOMMENDATIONS_FOLLOW_ON_PURCHASE: "Follow on purchases",
      PRODUCT_RECOMMENDATIONS_TRENDING: "Trending products",
      PRODUCT_RECOMMENDATIONS_DEFAULT_PRODUCTS: "Default products"
    };
    const { currentSection, newDestination, navItems, pageErrors } = this.props;
    return (
      <div className="leftSideBar">
        <div className="head">
          <Image src={audience} alt="audience" fluid />
          <h4>{currentSection.leftTitle}</h4>
        </div>
        <ListGroup>
          {navItems.map((items, key) => {
            switch (items.pageTag) {
              case "SELECT_SEGMENT":
                return (
                  <ListGroup.Item
                    key={key}
                    className={pageErrors.isSegment_HasError ? " hasError" : ""}
                  >
                    <span className="d-block">Segment</span>
                    {newDestination.segment === undefined ||
                    newDestination.segment.segmentName === ""
                      ? "Not set"
                      : newDestination.segment.segmentName}
                  </ListGroup.Item>
                );
              case "RECOMMENDATIONS":
                return (
                  <ListGroup.Item
                    key={key}
                    className={
                      pageErrors.isRecomendation_HasError ? " hasError" : ""
                    }
                  >
                    <span className="d-block">Product Recommendations</span>
                    {newDestination.recomendation === undefined ||
                    newDestination.recomendation.fieldValue === null
                      ? "Not set"
                      : newDestination.recomendation.recommendationCount + " x " + recommendations[
                          newDestination.recomendation.fieldValue
                        ]}
                  </ListGroup.Item>
                );
              case "UTM_INFO":
                return (
                  <ListGroup.Item
                    key={key}
                    className={pageErrors.isUtmInfo_HasError ? " hasError" : ""}
                  >
                    <span className="d-block">UTM Info</span>
                    {newDestination.utmInfo === undefined ||
                    newDestination.utmInfo.utmOptions.filter(
                      utm => utm.selected === true
                    ).length === 0
                      ? "Not set"
                      : newDestination.utmInfo.utmOptions
                          .filter(utm => utm.selected === true)
                          .map((items, key) => {
                            let seprator =
                              key > 0 && key < newDestination.utmInfo.length
                                ? ", "
                                : " ";
                            return seprator + items.title;
                          })}
                  </ListGroup.Item>
                );
              case "DESTINATIONS":
                return (
                  <ListGroup.Item
                    key={key}
                    className={
                      pageErrors.isDestination_HasError ? " hasError" : ""
                    }
                  >
                    <span className="d-block">Destination Push</span>
                    {newDestination.destination === undefined ||
                    newDestination.destination.length === 0
                      ? "Not set"
                      : newDestination.destination.map((items, key) => {
                          let seprator =
                            key > 0 && key < newDestination.destination.length
                              ? ", "
                              : "";
                          return seprator + items.destinationName;
                        })}
                  </ListGroup.Item>
                );
            }
          })}
        </ListGroup>
      </div>
    );
  }
}

export default LeftSideBar;
