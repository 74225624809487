import React, {Component} from 'react';
import {Accordion, ListGroup, ListGroupItem} from 'react-bootstrap';
import '../assets/css/style.css';
import {formatData} from '../../../utils';

class CustomerDataDetail extends Component {

  render() {
    const {customerData} = this.props;
    return (
      <div className="customer-data--wrapper contentWrap">
        <h4 className="section-heading d-none d-lg-block">Customer Data</h4>

        <Accordion className="detail-list" defaultActiveKey="0">
          {customerData.map((item, idx) => (
            <div className="section-detail d-flex flex-column-reverse">
              <Accordion.Collapse eventKey={`${idx}`} className="detail-collapsed-area">
                <ListGroup className="list-content">
                  {item.fields.map((subItem, fieldIdx) => (
                    <ListGroupItem eventKey={fieldIdx}>
                      <span>{subItem.name}</span>
                      <span className={"overflow-hidden"}>{formatData(subItem.type, subItem.attributeDataTag, subItem.value)}</span>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </Accordion.Collapse>
              <Accordion.Toggle eventKey={`${idx}`} as="div" className="icon-detail-accordion-toggle cursor-pointer">
                <h6>{item.sectionName}</h6>
              </Accordion.Toggle>
            </div>
          ))}
        </Accordion>
      </div>
    );
  }
}

export default CustomerDataDetail;
