import React from "react";
import "./styles.scss";
import {Col, Row} from "react-bootstrap";

const FunnelTable = ({funnelMetric_formatted, position}) => {

    return (
        <Row className="registered-container">
            <Col className="title-container">
                <div className="title">Conversion</div>
                <div className="title">Avg. Conversion Time</div>
                { position === 2 ? <></> : <div className="title">Revenue</div>}
                { position === 2 || position === 3 ? <></> : <div className="title">% of Registered</div>}
            </Col>
            <Col className="value-container">
                <div className="value">
                    {funnelMetric_formatted?.conversion_formatted}
                </div>

                <div className="value">
                    {funnelMetric_formatted?.averageConversionTimeInSeconds_formatted}
                </div>

                { position === 2 ? <></> :
                    <div className="value">
                        {funnelMetric_formatted?.revenue_formatted}
                    </div>
                }

                { position === 2 || position === 3? <></> :
                    <div className="value">
                        {funnelMetric_formatted?.registeredCustomersPercentage_formatted}
                    </div>
                }

            </Col>
        </Row>
    );
};

export default FunnelTable;