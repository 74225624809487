import ApiService from "./tenantApiService";

function getTrackingInfo() {
  const requestUrl = "/tracking";

  return ApiService.get(requestUrl).then(response => {
    return response.data;
  });
}

export default {
  getTrackingInfo
};
