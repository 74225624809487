import React, {Component, useEffect} from "react";
import {Container} from "react-bootstrap";
import customerProfilingSegmentation from "./assets/images/welcome/customer_profiling_segmentation.svg";
import dailyNewsfeed from "./assets/images/welcome/daily_newsfeed.svg";
import dataActivation from "./assets/images/welcome/data_activation.svg";
import marketingFunnelMetrics from "./assets/images/welcome/marketing_funnel_metrics.svg";
import "./assets/css/general-styles.scss";

class NoData extends Component {
    constructor(props) {
        super(props);
        const selectedMembers = JSON.parse(sessionStorage.getItem("selectedMembers"));

        this.state = {
            selectedMembers: selectedMembers,
        };
    }

    render() {

        return (
            <Container className="analytic-header px-4 bg-gray header-info-mobile header" fluid>
                <div>
                    <div className="header">
                        <div className="header-char">
                            <h2 className="once">
                                Welcome to Distil: AI Business Analytics
                            </h2>
                            <h5 className="once subtitle--text">
                                Your store data is loading and being analysed by the Distil AI
                            </h5>
                            <p className="header-text">
                                This can take up to 24 hours, depending on the amount of data in your in your store. We'll
                                drop you an email to let you know when it's done.
                            </p>
                        </div>
                    </div>
                    <div className="row spacer">
                        <div className="col-1 d-flex card-col-icon align-items-center">
                            <div className="welcome-item-icon">
                                <img src={dailyNewsfeed} alt="dailyNewsfeed"/>
                            </div>
                        </div>
                        <div className="col-11 card-title-col">
                            <div className=" fs-l fw-bold">Daily newsfeed of store performance, insights and business forecasts</div>
                            <div className="text-wrap pr-3 w-75">
                                <p className="card-text fs-m">
                                    <u>Perpetually Free</u> daily insights into your stores performance to keep you
                                    on top of the key data points that help drive high-growth.
                                </p>
                                <p className="card-text fs-m">
                                    Including Customer Segmentation, Marketing Funnel, Revenue Forecasting and
                                    more...
                                </p>
                            </div>
                        </div>
                    </div>
                    <hr className="separator"/>
                    <div className="row ">
                        <div className="col-1 d-flex card-col-icon align-items-center">
                            <div className="welcome-item-icon">
                                <img src={marketingFunnelMetrics} alt="marketingFunnelMetrics"/>
                            </div>
                        </div>
                        <div className="col-11 card-title-col">
                            <div className=" fs-l fw-bold">Marketing Funnel Metrics</div>
                            <div className="text-wrap pr-3 w-75">
                                <p className="card-text fs-m">
                                    Where do your High-LTV Customers come from?
                                </p>
                                <p className="card-text fs-m">
                                    Full view of your marketing funnel from acquisition to retention
                                    and High-LTV loyalty. Driven by a detailed linear attribution model to
                                    understand each channel's contribution.
                                </p>
                            </div>
                        </div>
                    </div>
                    <hr className="separator"/>
                    <div className="row ">
                        <div className="col-1 d-flex card-col-icon align-items-center">
                            <div className="welcome-item-icon">
                                <img src={customerProfilingSegmentation} alt="customerProfilingSegmentation"/>
                            </div>
                        </div>
                        <div className="col-11 card-title-col">
                            <div className=" fs-l fw-bold">Customer Profiling & Segmentation</div>
                            <div className="text-wrap pr-3 w-75">
                                <p className="card-text fs-m">
                                    What do your High-LTV Customers look like?
                                </p>
                                <p className="card-text fs-m">
                                    Build data-driven Customer Profiles and deep Segmentation to refine the Products
                                    you sell, Marketing Strategy and Communications.
                                </p>
                            </div>
                        </div>
                    </div>
                    <hr className="separator"/>
                    <div className="row ">
                        <div className="col-1 d-flex card-col-icon align-items-center">
                            <div className="welcome-item-icon">
                                <img src={dataActivation} alt="dataActivation"/>
                            </div>
                        </div>
                        <div className="col-11 card-title-col">
                            <div className=" fs-l fw-bold">Data Activation</div>
                            <div className="text-wrap pr-3 w-75">
                                <p className="card-text fs-m">
                                    Don't just Analyse, Activate!
                                </p>
                                <p className="card-text fs-m">
                                    Distil integrates with your existing marketing stack
                                    so you can use your enriched Customer Profiles & Segments directly
                                    in your campaigns.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </Container>
        );
    }
};

export default NoData;