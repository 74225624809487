import React, { Component } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//API Service
import AuthenticationService from "./services/authentication";

//Styles
import "./assets/fonts/font.css";
import "./assets/css/app.css";

//Layout
import Main from "./views/Main";
import AuthLayout from "./layouts/AuthLayout";
import JoinUser from "./views/Auth/join/JoinUser";
import Verify from './views/Auth/Forgot/Verify';
import VerifyUser from './views/Auth/Verify/VerifyUser';
import createHistory from 'history/createBrowserHistory';
import { ErrorBoundary } from 'react-error-boundary';

import * as Sentry from "@sentry/react";


let refreshAccessTokenPolling = "";
const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

export const history = createHistory();

/**
 * Redirect to 403 page and send error to Sentry.io.
 *
 * @param error  error obj.
 * @param message error message.
 */
export function processForbiddenError(error, message) {
  const fetchError = new Error(message);
  fetchError.cause = error;
  Sentry.captureException(fetchError);
  history.push('/403')
}

class App extends Component {
  constructor(props) {
    super(props);
    if (window.performance) {
      if(AuthenticationService.needToResetSession()) {
        AuthenticationService.resetOldSession();
      } else if (performance.navigation && performance.navigation.type === 1) {
        AuthenticationService.refreshAccessToken();
      } else {
      }
    }

    const isLogin = AuthenticationService.isAuthenticated();

    if (!isLogin) {
      this.state = {
        isLogin: false
      };
    } else {
      this.state = {
        isLogin: true
      };
    }
  }

  componentWillUnmount() {
    clearInterval(refreshAccessTokenPolling);
  }

  render() {

  function errorHandler (error, info){
    if(error?.detailError) {
     console.log(error?.detailError, info)
    } else {
      console.log(error, info)
    }
  }

  /**
   * Error view component.
   * @param error error object
   * @returns {JSX.Element}
   * @constructor
   */
  function ErrorFallback(error) {
    return (<div> <span>{JSON.stringify(error)}</span></div>)
  }

    return (
      <React.Fragment>
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler}>
        <ToastContainer />
        <Router history={history}>
          <React.Suspense fallback={loading()}>
            {this.state.isLogin ? (
              <Switch>
                <Route
                  exact
                  path="/reset/password"
                  name="Forgot Page"
                  render={props => <Verify {...props} />}
                />
                <Route
                  exact
                  path="/verify"
                  name="Verify User"
                  render={props => <VerifyUser {...props} />}
                />
                <Route
                  exact
                  path="/join/org"
                  name="Forgot Page"
                  render={props => <JoinUser {...props} />}
                />
                <Route
                  path="/"
                  name="Main"
                  render={props => <Main {...props} />}
                />
              </Switch>
            ) : (
              <Switch>
                <Route
                  exact
                  path="/join/org"
                  name="Forgot Page"
                  render={props => <JoinUser {...props} />}
                />
                <Route
                  exact
                  path="/reset/password"
                  name="Forgot Page"
                  render={props => <Verify {...props} />}
                />
                <Route
                  exact
                  path="/verify"
                  name="Verify User"
                  render={props => <VerifyUser {...props} />}
                />
                <Route
                  path="/"
                  name="Auth"
                  render={props => <AuthLayout {...props} />}
                />
              </Switch>
            )}
          </React.Suspense>
        </Router>
        </ErrorBoundary>
      </React.Fragment>
    );
  }
}

export default App;
