import React from "react";
import "./styles.scss";
import {Col} from "react-bootstrap";

const FunnelStage = ({ title, trapeziumName, funnelMetric_formatted, position}) => {

    return (
        <>
            <Col className="col-3">
                <p className="first">{title}</p>
            </Col>

            <Col className={`col-9 trapezium-wrap-${position}`}>
                <div className="trapezium">
                    <div className="">
                        <p className="unique color-706">{trapeziumName}</p>
                        <p className="num-100 color-706">{funnelMetric_formatted?.totalCustomerNumber_formatted ?
                                funnelMetric_formatted?.totalCustomerNumber_formatted :
                                funnelMetric_formatted?.metricCustomerNumber_formatted}</p>
                    </div>
                </div>
            </Col>
        </>
    );
};

export default FunnelStage;