import React, { Component } from "react";
import Loader from "../../../components/Loader/loader";

const step = ['Preparing your Data Distillery', 'Provisioning Storage', 'Setting up your Distillery'];

class Prepare extends Component {

  state = { textIdx: 0 };

  componentDidMount() {
    this.timeout = setInterval(() => {
      let currentIdx = this.state.textIdx;
      let nextIndex = currentIdx === step.length - 1 ? currentIdx : currentIdx + 1;
      this.setState({ textIdx: nextIndex });
    }, 10_000);
  }

  componentDidUnmount() {
    clearInterval(this.timeout);
  }

  render() {
    let textThatChanges = step[this.state.textIdx % step.length];
    return (
      <div className="content-wrap bg-white">
        <div className="content-inner-wrap mw-100">
          <div className="prepare-data">
            <h3>Creating {this.props.orgName}</h3>
            <span>{textThatChanges}</span>
            <div className="loading">
              <Loader />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Prepare;
