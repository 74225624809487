import React, {Component} from "react";
import {Modal, Nav} from "react-bootstrap";
import "./assets/style.css";
import Loader from "../../../../../components/Loader/loader";
import HistoryDetails from "./cardHistoryDetail";
import NewsfeedAPI from "../../../../../services/newsfeed";
import CardSidebar from "./cardSidebar";
import SourceView from "./sourceView";
import NoData from "../../../../../components/NoData/nodata";

class CardHistory extends Component {
  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {
      isLoaded: false,
      cardFilters: false,
      isFilterOpen: false,
      historyResponse: [],
      dataSourceStatuses: [],
      selectedDataSource: {},
      selectedConnection: {},
      unSelectedDataSourcesId: [],
      availableDataSource: 0,
      isSelectAllDataSource: true,
      isDataSourceExists: false
    };
  }

  componentDidMount() {
    this.getDataSourcesAndStatuses().then();
  }

  getDataSourcesAndStatuses = async () => {
    let unSelectedDataSourcesId = [];
    let historyResponse = [];
    let dataSourceStatuses = [];

    try {
      await NewsfeedAPI.getDataSourcesStatus().then(response => {
            dataSourceStatuses = response;
            unSelectedDataSourcesId = (response || []).filter(ds => ds.filterState === "DISABLED").map(ds => ds.id);
      });
    } catch (err) {
    }

    try {
      await NewsfeedAPI.getDataSources().then(response => historyResponse = response || []);
    } catch (err) {
    }

    this.setState({unSelectedDataSourcesId, historyResponse, dataSourceStatuses},
        () => this.checkIsSelectedConnection().then(this.updateUI));
  };

  updateUI = () => {
    let response = this.state.historyResponse;
    this.countDataSource(response);

    const index = response.findIndex(connection => connection.dataSources.length > 0);

    this.setState({
          selectedDataSource: response[index]?.dataSources[0] || {},
          selectedConnection: response[index] || {},
          isDataSourceExists: index >= 0,
          isLoaded: true
        }, () => this.checkSelectAllStatus()
    );

    document.addEventListener("mousedown", this.handleClickOutside);
  };

  selectAllDataSource = () => {
    this.setState({isSelectAllDataSource: !this.state.isSelectAllDataSource}, this.updateAllDataSourcesStatus);
  };

  checkSelectAllStatus = () => {
    this.setState({isSelectAllDataSource: this.state.unSelectedDataSourcesId.length === 0});
  };

  setDataSourcesStatuses = async () => {
    let {dataSourceStatuses, unSelectedDataSourcesId} = this.state;

    let newDataSources = dataSourceStatuses.map(status => {
      return {
        id: status.id,
        filterState: unSelectedDataSourcesId.includes(status.id) ? "DISABLED" : "ENABLED"
      };
    })

    try {
      return NewsfeedAPI.setDataSourcesStatus(newDataSources);
    } catch (err) {
    }
    return Promise.resolve();
  };

  /* this function triggered by click on Select All filter option
  * and set connection and data sources "DISABLED" or "ENABLED" status  */
  updateAllDataSourcesStatus = async () => {
    let {historyResponse, isSelectAllDataSource} = this.state;
    let unSelectedDataSourcesId = [];

    let filteredDataSource = historyResponse.flatMap(conn => conn.dataSources).map(source => {
      return {
          id: source.id,
          filterState: isSelectAllDataSource ? "ENABLED" : "DISABLED"
        }
    });

    historyResponse.forEach(item => item.isSelected = isSelectAllDataSource);

    if (!isSelectAllDataSource) {
      unSelectedDataSourcesId = filteredDataSource.map(item => item.id);
    }

    this.setState({unSelectedDataSourcesId, historyResponse});

    try {
      return NewsfeedAPI.setDataSourcesStatus(filteredDataSource);
    } catch (err) {
    }
    return Promise.resolve();
  };

  countDataSource = (historyResponse) => {
    let availableDataSource = historyResponse.map(ds => ds?.dataSources?.length || 0).reduce((a, b) => a + b, 0);
    this.setState({availableDataSource});
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({isFilterOpen: false}, this.setDataSourcesStatuses);
    }
  }

  closeDropDown = () => {
    let newFilterState = !this.state.isFilterOpen;
    this.setState({isFilterOpen: newFilterState}, () => !newFilterState && this.setDataSourcesStatuses());
  };

  checkIsSelectedConnection = async () => {
    return new Promise(resolve => {
      let {historyResponse, unSelectedDataSourcesId} = this.state;
      historyResponse.forEach(conn => conn.isSelected = conn?.dataSources?.some(ds => !unSelectedDataSourcesId.includes(ds.id)));
      this.setState({historyResponse}, resolve);
    });
  };

  updateDataSourcesSelection = id => {
    let {unSelectedDataSourcesId} = this.state;
    if (unSelectedDataSourcesId.includes(id)) {
      unSelectedDataSourcesId.splice(unSelectedDataSourcesId.indexOf(id), 1);
    } else {
      unSelectedDataSourcesId.push(id);
    }
    this.setState({unSelectedDataSourcesId},
        () => this.checkIsSelectedConnection().then(_ => {
          this.checkSelectAllStatus()
          this.setDataSourcesStatuses();
        })
    );
  };

  updateConnectionsSelection = id => {
    let {unSelectedDataSourcesId, historyResponse} = this.state;

    let connection = historyResponse.find(item => item.id === id);
    connection.isSelected = !connection.isSelected;

    connection.dataSources.forEach(dataSource => {
      let idx = unSelectedDataSourcesId.indexOf(dataSource.id);

      if (connection.isSelected && idx !== -1) {
        unSelectedDataSourcesId.splice(idx, 1);
      } else if (!connection.isSelected && idx === -1) {
        unSelectedDataSourcesId.push(dataSource.id);
      }
    });

    this.setState({historyResponse, unSelectedDataSourcesId},
        () => this.checkIsSelectedConnection().then(_ => {
          this.setDataSourcesStatuses();
          this.checkSelectAllStatus();
        })
    );

  };

  handleClose = () => {
    this.props.onClose();
  };

  handleChoose = (selectedDataSource, selectedConnection) => {
    this.setState({selectedDataSource, selectedConnection});
  };

  render() {
    const {
      isLoaded, isFilterOpen, cardFilters, historyResponse, selectedConnection, selectedDataSource,
      unSelectedDataSourcesId, availableDataSource, isDataSourceExists, isSelectAllDataSource
    } = this.state;

    return (
      <div className="user-detail-popup">
        <Modal
          show={this.props.status}
          onHide={this.handleClose}
          className="user-detail-popup newsfeed-modal"
        >
          {isLoaded ? (
            <React.Fragment>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span className="icon-CHRONOTYPE align-baseline"/>
                  <div>Data Source History</div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="p-0 ">
                <div className="cardFilters w-100" ref={this.setWrapperRef}>
                  <Nav className="list-inline">
                    <Nav.Item
                      className={`filter-sort ${isFilterOpen && "active"}`}
                      onClick={this.closeDropDown}
                    >
                      <span className="icon-sort"/> Filter{" "}
                      <span>
                        {" "}
                        {(unSelectedDataSourcesId.length > 0 || availableDataSource !== unSelectedDataSourcesId.length) && (
                          <React.Fragment>
                            ({availableDataSource - unSelectedDataSourcesId.length}/{availableDataSource} Data Sources showing)
                          </React.Fragment>
                        )}
                      </span>
                    </Nav.Item>
                  </Nav>
                  <span className={`filter-sort position-relative ${isFilterOpen && "active"}`}>
                    <SourceView
                      isSelectAllDataSource={isSelectAllDataSource}
                      data={historyResponse}
                      onSelectAllDataSource={this.selectAllDataSource}
                      onUpdateConnectionsSelection={this.updateConnectionsSelection}
                      unSelectedDataSourcesId={unSelectedDataSourcesId}
                      onUpdateDataSourcesSelection={this.updateDataSourcesSelection}
                    />
                  </span>
                </div>

                <div className="d-flex main-area ">
                  <main className="position-relative area-main secondary-font customer-data">
                    <section
                      className={cardFilters ? " sideNav active " : "sideNav"}
                    >
                      <CardSidebar
                        data={historyResponse}
                        onChooseHistoryGraph={this.handleChoose}
                        unSelectedDataSourcesId={unSelectedDataSourcesId}
                        selectedDataSourcesId={selectedDataSource.id}
                        isDataSourceExists={isDataSourceExists}
                      />
                    </section>
                    {isDataSourceExists ? (
                      <div className="right-list-area">
                        {selectedDataSource.syncHistory.length > 0 && !unSelectedDataSourcesId.includes(selectedDataSource.id) ? (
                          <HistoryDetails
                            selectedDataSources={selectedDataSource}
                            selectedConnection={selectedConnection}
                          />
                        ) : (
                          <NoData customTitle="No Sync History to display" customSubTitle="Please choose a data source to view the history for"/>
                        )}
                      </div>
                    ) : (
                      <NoData customTitle="No Data Connection History" customSubTitle=" "/>
                    )}
                  </main>
                </div>
              </Modal.Body>
            </React.Fragment>
          ) : (
            <Loader/>
          )}
        </Modal>
      </div>
    );
  }
}

export default CardHistory;
