import React, {Component} from "react";
import LineChart from "../Cardgraphs/lineChart";
import LineChartStatus from "../Cardgraphs/lineChartStatus";
import NoData from "../../../../../components/NoData/nodata";
import moment from "moment";
import _ from "lodash";

import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import Loader from "../../../../../components/Loader/loader";

import millify from "millify";

let numUpdated = [];
let numDeleted = [];
let numNew = [];
let numberOfRecords = [];
let statusData = [];
let numDuration = [];

class HistoryDetails extends Component {
  constructor(props) {
    super(props);
    this.myChartView = React.createRef();

    this.state = {
      updating: true,
      minDays: 5,
      showMin: 5,
      dataSource: {},
      numberOfRecords,
      numUpdated,
      numDeleted,
      numNew,
      numDuration,
      numErrors: 0,
      statusData,
      containerSize: 750,
      range: [0, 1],
      dataSourceRange: [],
      dataSourceRangePercent: {},
      hasRange: false,
      syncHistory: null
    };
  }
  componentDidMount() {
    this.setState({ updating: false });

    let startRange = Math.max(0, this.props.selectedDataSources.syncHistory.length - this.state.showMin);
    let endRange = this.props.selectedDataSources.syncHistory.length - 1;
    this.setState(
      {
        dataSource: this.props.selectedDataSources,
        range: [startRange, endRange]
      },
      () => {
        this.createRangeSliderData();
        this.updateDataSourceValue(this.props.selectedDataSources);
      }
    );

    window.addEventListener("resize", this.containerSize);

  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.selectedDataSources.id === _.get(this.state, 'dataSource.id')) {
      return;
    }
    this.setState({ updating: false });

    let startRange = Math.min(Math.max(0, nextProps.selectedDataSources.syncHistory.length - this.state.showMin), this.state.range[0]);
    let endRange = nextProps.selectedDataSources.syncHistory.length - 1;

    this.setState(
      {
        dataSource: nextProps.selectedDataSources,
        range: [startRange, endRange]
      },
      () => {
        this.createRangeSliderData();
        this.updateDataSourceValue(nextProps.selectedDataSources);
      }
    );
  }


  updateDataSourceValue = selectedDataSources => {
    const { range } = this.state;
    let selectedCardsyncHistory = _.slice(_.orderBy(
      selectedDataSources.syncHistory,
      "dateStarted",
      "asc"
    ), range[0], range[1] + 1);

    this.setState({
      numberOfRecords: this.mapData(
        selectedCardsyncHistory,
        "numberOfRecords",
        "#85CEE0",
        (result) => this.aggregateData(result,
          (a, b) => {a.y = a.datetime > b.datetime ? a.y : b.y; return a;},
          (history) => history.status),
        {datetime: 0, y: 0}
      ),
      numUpdated: this.mapData(
        selectedCardsyncHistory,
        "numUpdated",
        "#43ABC4",
        this.aggregateData
      ),
      numNew: this.mapData(selectedCardsyncHistory,
        "numNew",
        "#A8D322",
        this.aggregateData
      ),
      numDeleted: this.mapData(
        selectedCardsyncHistory,
        "numDeleted",
        "#E54024",
        this.aggregateData
      ),
      numDuration: this.mapData(
        selectedCardsyncHistory,
        "durationSeconds",
        "#DDB1CD",
        (result) => this.aggregateData(result, this.calcAverage, (history) => history.status, {y: 0})
      ),
      statusData: this.mapData(
        selectedCardsyncHistory,
        "statusData",
        "#85CEE0",
        (d) => _.chain(d).groupBy(d => d.x).map((values, date) => {
          const successCount = values.filter(({status}) => status).length;

          return {
            ...values[0],
            additionalStatus: successCount === 0 ? 'ERROR' : (successCount === values.length) ? 'SUCCESS' : 'HAS_ERRORS',
            tooltip: `${successCount} success out of ${values.length}`
          }
        }).value()
      ),
      numErrors: selectedCardsyncHistory.filter(item => item.syncStatus !== 'SUCCESS').length,
      updating: true
    });
  };

  mapData = (syncHistory, field, colour, mergeFunction) => {
    let minY = Number.MAX_SAFE_INTEGER;

    const result = syncHistory.map(item => {
      let y = field === "statusData" ? 50 : item[field];

      if (y < minY) {
        minY = y;
      }

      return {
        index: item.id,
        x: moment(item.dateStarted).format("Do MMM YYYY"),
        y: y,
        status: item.syncStatus === "SUCCESS",
        datetime: item.dateStarted
      }
    });
    const filteredData = {
      id: field,
      color: colour,
      minY: minY,
      data: mergeFunction ? mergeFunction(result) : result
    };

    return [filteredData];
  };

  aggregateData = (result, aggregate = (a, b) => {a.y = a.y + b.y; return a;}, filter = () => true, defaultValue = {y: 0}) => {
    return _.chain(result).groupBy(d => d.x).map((values, date) => {
      let filteredValues = values.filter(filter);
      return filteredValues.reduce(aggregate, {...(filteredValues[0] || {}), ...defaultValue});
    }).value();
  }

  calcAverage = (total, amount, index, array) => {
    total.y += amount.y;
    if (index === array.length - 1) {
      total.y = Math.round(total.y / array.length / 1000);
      return total;
    } else {
      return total;
    }
  }

  createRangeSliderData = () => {
    let syncHistory = _.sortBy(this.state.dataSource.syncHistory, [
      function(o) {
        return o.dateStarted;
      }
    ]);

    let syncHistoryPercent = {};

    let newkey = 0;
    let days = _.chain(syncHistory).map(h => h.dateStarted).filter(h => h).map(h => new Date(h).setHours(0,0,0,0)).uniq().value();
    days.forEach((item, key) => {
      if (key === 0) {
        syncHistoryPercent["min"] = item;
      }
      if (key === days.length - 1) {
        syncHistoryPercent["max"] = item;
      }
      if (key > 0 && key < days.length - 1) {
        syncHistoryPercent[newkey.toString() + "%"] = item;
      }

      newkey = 100 / (days.length - 1) + newkey;
    });

    this.setState({
      syncHistoryPercent,
      syncHistory,
      hasRange: true
    });
  };

  onSlide = (render, handle, value, un, percent) => {
    let first = this.state.syncHistory.filter(o => o.dateStarted > value[0]).reduce((h1, h2) => h1.dateStarted < h2.dateStarted ? h1 : h2)
    let last  = this.state.syncHistory.filter(o => o.dateStarted < value[1]).reduce((h1, h2) => h1.dateStarted > h2.dateStarted ? h1 : h2)

    let range = [
      this.state.syncHistory.findIndex((o) => o === first),
      this.state.syncHistory.findIndex((o) => o === last)
    ]

    this.setState({ range }, () =>
      this.updateDataSourceValue(this.props.selectedDataSources)
    );
  };

  lineChartView = () => {

    return (
      <React.Fragment>
        {this.state.hasRange &&
          this.state.syncHistory.length >= this.state.minDays && (
            <Nouislider
              connect={true}
              start={[
                this.state.syncHistory[this.state.range[0]].dateStarted,
                this.state.syncHistory[this.state.range[1]].dateStarted
              ]}
              behaviour="drag"
              snap={true}
              range={this.state.syncHistoryPercent}
              tooltips={[{to: (v) => moment(v).format("MMM DD YYYY")}, {to: (v) => moment(v).format("MMM DD YYYY")}]}
              onSlide={_.debounce(this.onSlide, 500)}
            />
          )}
        <div className="hasLineCharts">
          {this.state.range[1] - this.state.range[0] > 0 ? (
            <React.Fragment>
              <LineChartStatus
                data={this.state.statusData}
                chartTitle="Status"
                enableTop="true"
                tooltip
                noLine={true}
                isLinesOnly={true}
              />
              <LineChart
                data={this.state.numberOfRecords}
                tooltipLabel="total records"
                chartTitle="Total records"
              />
              <LineChart
                data={this.state.numUpdated}
                tooltipLabel="updated"
                chartTitle="Updated"
              />
              <LineChart
                data={this.state.numNew}
                tooltipLabel="inserted"
                chartTitle="Inserted"
              />
              <LineChart
                data={this.state.numDeleted}
                tooltipLabel="deleted"
                chartTitle="Deleted"
              />
              <LineChart
                isLinesOnly={true}
                data={this.state.numDuration}
                tooltipLabel="seconds"
                chartTitle="Duration"
              />
            </React.Fragment>
          ) : (
            <NoData
              customTitle="There is no data in here."
              customSubTitle="Please update timeline slider"
            />
          )}
        </div>

        {this.state.hasRange &&
          this.state.syncHistory.length >= this.state.minDays && (
            <Nouislider
              connect={true}
              start={[
                this.state.syncHistory[this.state.range[0]].dateStarted,
                this.state.syncHistory[this.state.range[1]].dateStarted
              ]}
              behaviour="drag"
              snap={true}
              range={this.state.syncHistoryPercent}
              tooltips={[{to: (v) => moment(v).format("hh:mm ddd, MMM DD YYYY")}, {to: (v) => moment(v).format("hh:mm ddd, MMM DD YYYY")}]}
              onSlide={_.debounce(this.onSlide, 500)}
            />
          )}
      </React.Fragment>
    );
  };

  containerSize = () => {
    const containerSize = this.myChartView.current.offsetWidth;
    this.setState({ containerSize });
  };

  kFormatter = num => {
    return millify(num);
  };

  render() {
    return this.state.updating ? (
      <React.Fragment>
        <div className="modal-top-wrap">
          <div className="top-head-wrap">
            <h4>
              {this.props.selectedConnection.name}:{" "}
              <strong> {this.props.selectedDataSources.name} </strong>
            </h4>
            <span className="process-time">
              {" "}
              Last{" "}Processed{" "}
              {this.state.syncHistory !== null &&
                moment(
                  _.get(_.last(this.state.syncHistory), 'dateStarted')
                ).format("hh:mm ddd, MMM DD YYYY")}
            </span>
          </div>

          <div className="graphic-top-wrap">
            <div className="records">
              <span className="record-head">Records processed</span>
              <span className="total">
                {this.state.numberOfRecords.length > 0 &&
                  this.kFormatter(
                    _.sumBy(this.state.numberOfRecords[0].data, "y")
                  )}
              </span>
              <div
                className="bottom-bar"
                style={{ backgroundColor: "#85CEE0" }}
              />
            </div>

            <div className="records">
              <span className="record-head">Records updated</span>
              <span className="total">
                {this.state.numUpdated.length > 0 &&
                  this.kFormatter(_.sumBy(this.state.numUpdated[0].data, "y"))}
              </span>
              <div
                className="bottom-bar"
                style={{ backgroundColor: "#43ABC4" }}
              />
            </div>

            <div className="records">
              <span className="record-head">Records added</span>
              <span className="total">
                {this.state.numNew.length > 0 &&
                  this.kFormatter(_.sumBy(this.state.numNew[0].data, "y"))}
              </span>
              <div
                className="bottom-bar"
                style={{ backgroundColor: "#A8D322" }}
              />
            </div>

            <div className="records">
              <span className="record-head">Records deleted</span>
              <span className="total">
                {this.state.numDeleted.length > 0 &&
                  this.kFormatter(_.sumBy(this.state.numDeleted[0].data, "y"))}
              </span>
              <div
                className="bottom-bar"
                style={{ backgroundColor: "#E54024" }}
              />
            </div>
            <div className="records">
              <span className="record-head">Errors</span>
              <span className="total">
                {this.state.numErrors}
              </span>
              <div
                className="bottom-bar"
                style={{ backgroundColor: "#F8BB1F" }}
              />
            </div>
            <div className="records">
              <span className="record-head">Duration</span>
              <span className="total">
                {this.state.numDuration.length > 0 &&
                this.state.range[1] - this.state.range[0] > 0 ? (
                  _.meanBy(this.state.numDuration[0].data, "y").toFixed(0)): 0}
                s
              </span>
              <div
                className="bottom-bar"
                style={{ backgroundColor: "#DDB1CD" }}
              />
            </div>
          </div>
        </div>
        <div className="chart-views" ref={this.myChartView}>
          {this.lineChartView()}
        </div>
      </React.Fragment>
    ) : (
      <Loader />
    );
  }
}

export default HistoryDetails;
