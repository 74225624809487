import React, { Component } from "react";
import CommonMessage from "../../../../components/CommonMessage/commonMessage";
import AuthenticationService from "../../../../services/authentication";

import { Link } from "react-router-dom";

import { Textbox } from "react-inputs-validation";
import { toast } from "react-toastify";

class ResetPasswordError extends Component {
  state={
    btnDisabled:false
  }
  requestReset = async () => {
    try {
      await AuthenticationService.passwordResetRequest(this.props.email).then(
        response => {
          this.setState({btnDisabled:true})
          toast.success("Password reset request sent ", {
            onClose:()=> (window.location = "/")
          } );
        }
      );
    } catch (ex) {
      toast.error("Invalid Email");
    }
  };

  rederButton() {
    if (this.props.email !== null) {
      return (
        <button
          onClick={() => this.requestReset()}
          className="btn btn-primary green-btn"
          disabled ={this.state.btnDisabled}
        >
          Resend E-mail
        </button>
      );
    } else {
      return (
        <Link to="/forgot" className="btn btn-primary green-btn">
          Resend E-mail
        </Link>
      );
    }
  }
  render() {
    return (
      <div className="content-wrap bg-white">
        <div className="content-inner-wrap forgot-email-sent  col-sm-9">
          <div className="top-wrap d-flex flex-column">
            <h1 className="font-weight-heavy">Reset Your Password</h1>
          </div>

          <div className="form-wrap">
            <p>
              {" "}
              This verification link is no longer active, click on the button
              below to send a new verification email.{" "}
            </p>

            <div className="btn-wrap">{this.rederButton()}</div>
          </div>

          <div className="bottom-wrap">
            <div className="right-text light-black-text-40">
              <p>
                ©2018–2019 All Rights Reserved. Distil.ai® is a registered
                trademark of Big Business Intelligence Limited.{" "}
                <a href="#;">Cookie Preferences</a>, <a href="#;">Privacy</a>,
                and <a href="#;">Terms</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPasswordError;
