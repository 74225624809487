import React, { Component } from "react";

import { Form, Col, ButtonGroup, Button, Image, Badge } from "react-bootstrap";
import "../assets/styles.css";
import UserAPI from "../../../../../../services/users.js";
import AuthAPI from "../../../../../../services/authentication.js";

import resetIcon from "../assets/img/reset.svg";
import disableIcon from "../assets/img/disable.svg";
// import User from "../assets/img/user.jpg";

import moment from "moment";
import { dataFormat } from "../../../../../../variables/globalVariables";
import { toast } from "react-toastify";
import { Select } from "react-inputs-validation";
import {alertingLevelList, userRoleList} from "../../../../../../variables/globalValues.json";

class ManageUser extends Component {
  constructor() {
    super();
    this.state = {
      selectedUser: "",
      isLoadingManagerUser: false,
      showModel: false,
      isDisabledRole: false,
      isDisabledPassword: false,
      isDisabledSubmit:false
    };
    // this.setState({selectedUser:this.props.manageUser})
  }

  componentDidMount() {
    this.setState({
      selectedUser: this.props.manageUser,
      isLoadingManagerUser: true
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.manageUser !== this.props.manageUser) {
      this.setState({ isLoadingManagerUser: false });
      //Perform some operation
      this.setState({
        selectedUser: nextProps.manageUser,
        isLoadingManagerUser: true
      });
    }
  }
  resetPassword = async () => {
    this.setState({ isDisabledPassword: true });
    try {
      await AuthAPI.passwordResetRequest(this.state.selectedUser.emailAddress).then(
        response => {
          this.setState({
            selectedUser: response,
            isLoadingManagerUser: true,
            isDisabledPassword: false
          });
          toast.success("Password reset is being requested", {
            className: "user-tost",
            progressClassName: "tost-progress"
          });
        }
      );
    } catch (ex) {
      this.setState({ isDisabledPassword: false });
      // console.log(ex);
      toast.error("Password reset requested have some problem");
    }
  };

  saveChanges = async e => {
    this.setState({isDisabledSubmit:true})
    e.preventDefault();
    try {
      await UserAPI.updateUser(this.state.selectedUser).then(response => {
        this.setState({
          selectedUser: response,
          isLoadingManagerUser: true
        });

        toast.success("Changes saved successfully");
        this.updateSelectedUser(response);
        localStorage.setItem("checkChange", false);
      });
    } catch (ex) {
      // console.log(ex);
      toast.error("Problem while saving changes");
    }
    this.setState({isDisabledSubmit:false})
  };
  updateSelectedUser = updatedRecord => {
    this.props.onUpdateSelectedUser(updatedRecord);
  };

  updateMemberRole = async () => {
    const selectedUser = this.state.selectedUser;
    let oldmembershipType = this.state.selectedUser.membershipType;
    selectedUser.membershipType =
      this.state.selectedUser.membershipType === "DISABLED"
        ? "READER"
        : "DISABLED";
    this.setState({ selectedUser, isDisabledRole: true });

    try {
      await UserAPI.updateUser(this.state.selectedUser).then(response => {
        this.setState({
          selectedUser: response,
          isLoadingManagerUser: true,
          isDisabledRole: false
        });
        this.updateSelectedUser(response);
        toast.success(
          "User role updated to " + response.membershipType + " successfully",
          {
            className: "user-tost",
            progressClassName: "tost-progress"
          }
        );
      });
    } catch (ex) {
      // console.log(ex);
      selectedUser.membershipType = oldmembershipType;
      this.setState({ selectedUser, isDisabledRole: false });

      toast.error("Problem while updating membership Type");
    }
  };

  // updateRole = e => {
  //   const selectedUser = { ...this.state.selectedUser };
  //   selectedUser.membershipType = e.target.value;
  //   this.setState({ selectedUser }, () => this.props.onCheckChange());
  // };
  updateRole = e => {
    const selectedUser = { ...this.state.selectedUser };
    selectedUser.membershipType = e;
    this.setState({ selectedUser }, () => this.props.onCheckChange());
  };

  updateAlertLevel = e => {
    const selectedUser = { ...this.state.selectedUser };
    selectedUser.alertingLevel = e;
    this.setState({ selectedUser }, () => this.props.onCheckChange());
  };

  getNameFromOptionsList(list, id) {
    return list.find(level => level.id === (id || null)).name;
  }

  renderBadge = membershipType => {
    switch (membershipType) {
      case "ADMIN":
        return (
          <Badge variant={"none"} className="badge badge-admin">
            {" "}
            {membershipType}
          </Badge>
        );
      case "CONTRIBUTOR":
        return (
          <Badge className="badge badge-contributor" variant="primary">
            {membershipType}
          </Badge>
        );
      case "TECHNICAL":
        return (
          <Badge className="badge badge-technical" variant="warning">
            {membershipType}
          </Badge>
        );
      case "READER":
        return (
          <Badge className="badge badge-reader" variant="danger">
            {membershipType}
          </Badge>
        );
      case "DISABLED":
        return (
          <Badge className="badge badge-disabled" variant="danger">
            {membershipType}
          </Badge>
        );
      case "NEWSFEED":
        return (
          <Badge className="badge badge-newsfeed" variant="danger">
            {membershipType}
          </Badge>
        );
      default:
        return <Badge variant="primary">{membershipType}</Badge>;
    }
  };

  render() {
    const { selectedUser, isDisabledRole, isDisabledPassword,isDisabledSubmit } = this.state;
    const { currentUser } = this.props;
    let currentUserAlertLevel = this.getNameFromOptionsList(alertingLevelList, selectedUser.alertingLevel);
    let currentUserRole = this.getNameFromOptionsList(userRoleList, selectedUser.membershipType);
    return (
      <div className="right-body-content right-list-area bg-white h-100">
        <Button
          onClick={this.props.onBackPress}
          aria-label="Basic example"
          className="d-block d-md-none back-btn"
          variant="secondary"
        >
          Back
        </Button>
        <ButtonGroup
          aria-label="Basic example"
          className="float-right d-none d-md-block"
        >
        {!selectedUser.isShopify ? (
          <Button
              disabled={isDisabledPassword}
              variant="secondary"
              className="reset"
              onClick={this.resetPassword} >
            <img src={resetIcon} alt="icon" /> Reset Password
          </Button>
          ) : "" }
        <Button
            disabled={isDisabledRole}
            variant="secondary"
            onClick={this.updateMemberRole}>
          <img src={disableIcon} alt="icon" />{" "}
            {selectedUser.membershipType === "DISABLED" ? "Enable" : "Disable"}{" "}User
        </Button>
        </ButtonGroup>
        <div className="form-body-section">
          <div className="user-header clearfix d-flex align-items-end">
            <div className="left-section d-flex align-items-center">
              {/* <Image src={User} roundedCircle /> */}
              <div className="profile-user dark">
                <span
                  className={`user-${selectedUser.membershipType}-bg user-${selectedUser.membershipType}-border`}
                >
                  {selectedUser.firstName !== undefined
                    ? selectedUser.firstName.charAt(0)
                    : ""}
                  {selectedUser.lastName !== undefined
                    ? selectedUser.lastName.charAt(0)
                    : ""}
                </span>
              </div>
              <div className="user-content">
                <h6 className="font-weight-heavy mb-0">
                  {selectedUser.firstName} {selectedUser.lastName}
                </h6>
                {this.renderBadge(selectedUser.membershipType)}
              </div>
            </div>
            <div className="right-section ml-auto">
              <span>
                Date created{" "}
                <strong>
                  {" "}
                  {moment(selectedUser.dateCreated).format(dataFormat.MEDIUM)}
                </strong>
              </span>
              <span>
                Last Logged In{" "}
                <strong>
                  {moment(selectedUser.dateLastSeen).format(dataFormat.MEDIUM)}
                </strong>
              </span>
            </div>
          </div>

          <Form onSubmit={this.saveChanges}>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridAddress1">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  defaultValue={selectedUser.firstName}
                  disabled
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridAddress1">
                <Form.Label>Surname</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Last Name"
                  defaultValue={selectedUser.lastName}
                  disabled
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  defaultValue={selectedUser.isShopify ? selectedUser.marketingEmailAddress : selectedUser.emailAddress}
                  disabled
                />
              </Form.Group>
            </Form.Row>

            {!selectedUser.isShopify && (
            <Form.Row>
              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>Alert Level</Form.Label>
                {currentUser.id !== selectedUser.id ? (
                    <Select
                        name={"alertingLevel"}
                        value={selectedUser.alertingLevel || "null"}
                        disabled={false}
                        optionList={alertingLevelList}
                        classNameSelect="select-control"
                        classNameWrapper="form-control select-control-wrapper manage-user-type"
                        classNameContainer=""
                        classNameOptionListContainer="select-control-option"
                        classNameOptionListItem=""
                        customStyleSelect={{}}
                        customStyleWrapper={{}}
                        customStyleContainer={{}}
                        customStyleOptionListContainer={{}}
                        customStyleOptionListItem={{}}
                        onChange={e => this.updateAlertLevel(e)}
                    />
                ) : (
                    <Form.Control
                        type="text"
                        placeholder="Your alert level"
                        defaultValue={currentUserAlertLevel}
                        disabled
                    />
                )}
              </Form.Group>
            </Form.Row>
            )}

            <Form.Row>
              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>Role</Form.Label>
                {currentUser.id !== selectedUser.id ? (
                  <Select
                    name={"membershipType"}
                    value={selectedUser.membershipType}
                    disabled={false}
                    optionList={userRoleList}
                    classNameSelect="select-control"
                    classNameWrapper="form-control select-control-wrapper manage-user-type"
                    classNameContainer=""
                    classNameOptionListContainer="select-control-option"
                    classNameOptionListItem=""
                    customStyleSelect={{}}
                    customStyleWrapper={{}}
                    customStyleContainer={{}}
                    customStyleOptionListContainer={{}}
                    customStyleOptionListItem={{}}
                    onChange={e => this.updateRole(e)}
                  />
                ) : (
                  <Form.Control
                    type="text"
                    placeholder="Your role"
                    defaultValue={currentUserRole}
                    disabled
                  />
                )}
              </Form.Group>
            </Form.Row>
            <ButtonGroup
              aria-label="Basic example"
              className="d-block d-md-none on-mobile"
            >
              {!selectedUser.isShopify ? (

                <Button
                  variant="secondary"
                  className="reset"
                  onClick={this.resetPassword}
                >
                  <img src={resetIcon} alt="icon" /> Reset Password
                </Button>

              ) : ""}

              <Button variant="secondary" onClick={this.updateMemberRole}>
                <img src={disableIcon} alt="icon" />{" "}
                {selectedUser.membershipType === "DISABLED"
                  ? "Enable"
                  : "Disable"}{" "}
                User
              </Button>
            </ButtonGroup>
            {currentUser.id !== selectedUser.id && (
              <Button variant="primary submit" type="submit" disabled={isDisabledSubmit}>
                Save Changes
              </Button>
            )}
          </Form>
        </div>
      </div>
    );
  }
}

export default ManageUser;
