import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import React, { Component } from "react";
import equal from "fast-deep-equal";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
      align: "start",
      labels: {
        padding:15,
        boxWidth: 16,
        boxHeight: 16,
      }
    },
    title: {
      display: false
    }
  }
};

class LineChartTuesday extends Component {

  constructor(props) {
    super(props);

    this.state = {
      labels: [],
      averageValueSegmentData: [],
      highValueSegmentData: [],
      lowValueSegmentData: [],
      data: {
        labels: '',
        datasets: [],
      },
    };
  }

  componentDidMount() {
    this.mappingValue();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!equal(this.props.cardData, prevProps.cardData)) {
      this.mappingValue();
    }
  }

  mappingValue() {
    let averageValueSegmentData;
    let highValueSegmentData;
    let lowValueSegmentData;
    let labels;
    const { cardData } = this.props;

    if (cardData) {
      labels = cardData?.data?.averageValueSegmentData?.chartData.map((value, index) => {
        return value.date
      })
      averageValueSegmentData = cardData?.data?.averageValueSegmentData?.chartData.map((value, index) => {
        return value?.value
      })
      highValueSegmentData = cardData?.data?.highValueSegmentData?.chartData.map((value, index) => {
        return value?.value
      })
      lowValueSegmentData = cardData?.data?.lowValueSegmentData?.chartData.map((value, index) => {
        return value?.value
      })
  }

  this.setState({
                  labels: labels,
                  averageValueSegmentData: averageValueSegmentData,
                  highValueSegmentData: highValueSegmentData,
                  lowValueSegmentData: lowValueSegmentData,
                  data: {
                    labels: labels,
                    datasets: [
                      {
                        label: "High Value Customers",
                        data: highValueSegmentData,
                        borderColor: "#ABD439",
                        backgroundColor: "#ABD439",
                        pointRadius: 0,
                        pointBorderWidth: 0,

                      },
                      {
                        label: "Avg Value Customers",
                        data: averageValueSegmentData,
                        borderColor: "#C4C4C4",
                        backgroundColor: "#C4C4C4",
                        pointRadius: 0,
                        pointBorderWidth: 0,

                      },
                      {
                        label: "Low Value Customers",
                        data: lowValueSegmentData,
                        borderColor: "#F05A28",
                        backgroundColor: "#F05A28",
                        pointRadius: 0,
                        pointBorderWidth: 0,

                      }
                    ]
                  },
                })
  }

  render() {
    return (
        <>
          <Line options={options} data={this.state.data}/>
        </>
    );
  }
};
export default LineChartTuesday;