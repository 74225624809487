import React from "react";

export const Exclamation = ({ ...props }) => (
  <svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <path
      d="M8.991 0C4.023 0 0 4.032 0 9s4.023 9 8.991 9C13.968 18 18 13.968 18 9s-4.032-9-9.009-9z"
      fill={props.color == undefined ? "#E54024" : props.color}
    />
    <path
      d="M9 16.434A7.433 7.433 0 011.565 9 7.433 7.433 0 019 1.565 7.433 7.433 0 0116.435 9 7.433 7.433 0 019 16.434z"
      fill={props.color == undefined ? "#fff" : props.color}
    />
    <path
      d="M7.826 11.739h2.348v2.354H7.826v-2.354zM7.826 4.695h2.348v5.478H7.826V4.695z"
      fill={props.color == undefined ? "#E54024" : props.color}
    />
  </svg>
);

export const CheckCircle = ({ ...props }) => (
  <svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <path
      d="M8.935 16.37A7.433 7.433 0 011.5 8.935 7.433 7.433 0 018.935 1.5a7.433 7.433 0 017.435 7.435 7.433 7.433 0 01-7.435 7.435z"
      fill={props.color == undefined ? "#FFFFFF" : props.color}
    />
    <path
      d="M9 0C4.032 0 0 4.032 0 9s4.032 9 9 9 9-4.032 9-9-4.032-9-9-9zm0 16.2c-3.969 0-7.2-3.231-7.2-7.2 0-3.969 3.231-7.2 7.2-7.2 3.969 0 7.2 3.231 7.2 7.2 0 3.969-3.231 7.2-7.2 7.2zm-1.8-5.247l5.931-5.931L14.4 6.3l-7.2 7.2-3.6-3.6 1.269-1.269L7.2 10.953z"
      fill={props.color == undefined ? "#9FCE1D" : props.color}
    />
    <path
      d="M9 0C4.032 0 0 4.032 0 9s4.032 9 9 9 9-4.032 9-9-4.032-9-9-9zm0 16.2c-3.969 0-7.2-3.231-7.2-7.2 0-3.969 3.231-7.2 7.2-7.2 3.969 0 7.2 3.231 7.2 7.2 0 3.969-3.231 7.2-7.2 7.2zm-1.8-5.247l5.931-5.931L14.4 6.3l-7.2 7.2-3.6-3.6 1.269-1.269L7.2 10.953z"
      fill={props.color == undefined ? "#000000" : props.color}
    />
  </svg>
);
