import React, {Component} from "react";
import {Button, Form, ListGroup} from "react-bootstrap";
import {Select, Textarea, Textbox} from "react-inputs-validation";
import {toast} from "react-toastify";
//services
import OrdersAPI from '../../../../../services/orders';
import TraitsAPI from '../../../../../services/traits';

import Loader from "../../../../../components/Loader/loader";

import {
  getPluralEnding,
} from "../../customerdata/segmentUtils";
import { isTraitProcessing} from "../traitUtils";
import {Link} from "react-router-dom";
import TraitCategoriesModal from "../category/TraitCategoriesModal";
import RuleGroup from "./ruleGroup";
import View from "../../common/DataView/view";
import TraitCustomersDataGrid from "../DataGrid/TraitCustomersDataGrid";

const initialState = {
  updatingTrait: false,

  ruleCount: 0,
  editable: true,
  traitDetails: {
    name: "",
    description: "",
    traitCategoryId: null,
    ruleGroupAndOr: "AND",
    cachedCustomerCount: 0,
    buildStatus: 'READY',
    traitRuleGroups: [
      {
        clausesAndOr: "AND",
        traitRules: [
          {
            new: true
          }
        ]
      }
    ],
    dateCreated: null,
    dateModified: null,
    createdByUserName: null,
    modifiedByUserName: null,
    traitType: "CUSTOMER_TRAIT" //hardcoded for now. There are no other types yet.
  },
  blankRule: {
    new: true
  },
  blankGroup: {
    clausesAndOr: "AND",
    traitRules: [
      {
        new: true
      }
    ]
  },
  validate: false,
  newTrait: false,
  isLoading: true,
  newRule: true,
  newGroup: false,
  enablePreview: false,
  isPreviewLoading: false,
  attributesWarningDisplayed: false,
  traitCategoriesModal: false,
};

class NewTrait extends Component {
  poolingForUpdatingTraitInfo;

  constructor(props) {
    super(props);
    const { traitDetails } = initialState;
    traitDetails.traitCategoryId = props.traitCategories.find(item => item.isDefault).id;
    this.state = {...initialState, traitDetails };
  }

  componentWillMount() {
    this.getSingleTrait();
  }

  getSingleTrait = async event => {
    OrdersAPI.getOrderPaymentStatuses().then( orderPaymentStatuses => {
      this.setState({orderPaymentStatuses});
      if (this.props.currentTraitId !== null) {
        TraitsAPI.getSingleTrait(this.props.currentTraitId).then(
            response => {
              this.setState({
                traitDetails: response,
                editable: !(response?.traitDependencies?.segmentDependencies.length || response?.traitDependencies?.destinationDependencies.length ),
                newRule: false,
                isLoading: false
              }, () => this.rulesCount());
            }
        ).catch(error => {
          toast.error("Failed to Load Trait");
          this.setState({ isLoading: false });
        });
      } else {
        this.setState({newTrait: true, isLoading: false});
      }
    })
    .catch(error => {
      toast.error("Failed to Load Orders Payment Statuses");
      this.setState({ isLoading: false });
    })
  };

  checkTraitDependency = async event => {
    try {
      await TraitsAPI.traitDependency(this.props.currentTraitId).then(
        response => {
          if (response === true) {
            toast.error(
              "This Trait has dependent Segments or Destinations. Delete these first in order to continue"
            );
          } else {
            this.props.onDeleteTrait(this.props.currentTraitId);
          }
        }
      );
    } catch (err) {
      toast.error("Failed to Check Trait Dependency");

      this.setState({
        isLoading: false
      });
    }
  };

  rulesCount() {
    const trait = this.state.traitDetails;
    let ruleCount = 0;
    trait.traitRuleGroups.map(ruleGroup => {
      ruleCount = ruleCount + ruleGroup.traitRules.length;
      this.setState({ruleCount});
    });
  }

  handleNewTrait = async event => {
    this.setState({isLoading: true});
    try {
      await TraitsAPI.createNewTrait(this.state.traitDetails).then(
        response => {
          this.setState(
            {
              traitDetails: response,
              isLoading: false
            },
            () => this.props.onSaveNewTrait(this.state.traitDetails, true)
          );
          toast.success("Created New Trait");
        }
      );
    } catch (err) {
    }
  };

  handlePreviewTrait = async event => {
    this.setState({isPreviewLoading: false, enablePreview: true});

    try {
      await TraitsAPI.traitPreview(
        this.state.traitDetails,
        this.state.dataViewId
      ).then(response => {
        this.setState({
          customerList: response.firstPageCustomers,
          totalSize: response.totalRecordsCount,
          isPreviewLoading: true
        });

      });
    } catch (err) {
    }
  };

  componentWillReceiveProps(prevProps) {

    const {dataViewId, isPreviewLoading} = this.state;

    if (prevProps.dataViewId !== dataViewId && isPreviewLoading) {
      this.setState({dataViewId: prevProps.dataViewId}, () =>
          this.handlePreviewTrait()
      );
    }
  }


  componentWillUnmount() {
    clearTimeout(this.poolingForUpdatingTraitInfo);
  }

  handleUpdateTrait = async event => {

    if (this.props.currentTraitId !== null) {
      try {
        this.setState({updatingTrait: true}, (async () => {
          const trait = await TraitsAPI.updateTrait(
            this.props.currentTraitId,
            this.state.traitDetails
          );

          this.props.onUpdateTrait(this.props.currentTraitId, true, trait);
          this.setState({updatingTrait: false});

          if (isTraitProcessing(trait)) {
            this.restartTraitInfoTimeout(this.props.currentTraitId)
          }
        }))

      } catch (err) {
      }
    } else {
      this.setState({newTrait: true, isLoading: false});
    }
  };

  restartTraitInfoTimeout(traitId) {
    clearTimeout(this.poolingForUpdatingTraitInfo)
    this.poolingForUpdatingTraitInfo = setTimeout(() => {
      TraitsAPI.getSingleTrait(traitId).then(response => {
        if (response.buildStatus === 'READY') {
          this.props.onUpdateTrait(this.props.currentTraitId, true, response, false);
          clearTimeout(this.poolingForUpdatingTraitInfo);
        } else {
          this.restartTraitInfoTimeout(traitId);
        }
      })
    }, 10_000)
  }

  handleChange = e => {
    const { traitDetails } = this.state;
    traitDetails[e.target.name] = e.target.value;
    this.setState({traitDetails}, () => this.props.onCheckChange());
  };

  handleTraitCategoryId = (categoryId) => {
    const { traitDetails } = this.state;
    traitDetails.traitCategoryId = categoryId;
    this.setState({traitDetails}, () => this.props.onCheckChange());
  };

  getBtnStatus() {
    return this.state.ruleCount === 0 ||
      this.state.ruleCount === "undefined" ||
      this.state.traitDetails.name === "" ||
      this.state.newRule === true ||
      this.state.traitDetails.description === "" ||
      this.state.traitDetails.traitCategoryId === null ||
      this.state.updatingTrait
  }

  renderButtonType(isHidden) {
    if (this.state.newTrait) {
      return (
        <Button
          disabled={this.getBtnStatus()}
          onClick={() => this.handleNewTrait()}
        >
          Save{" "}
        </Button>
      );
    } else {
      return (
        <Button
          disabled={this.getBtnStatus()}
          hidden={isHidden}
          onClick={() => this.handleUpdateTrait()}
        >
          {" "}
          {this.state.updatingTrait ? 'Updating' : 'Update'}{" "}
        </Button>
      );
    }
  }

  checkGroupStatus = () => {
    const { traitDetails } = this.state;

    const res = traitDetails.traitRuleGroups.filter((o) => {
      if (o.traitRules) {
        return o.traitRules.filter(f => f.new === true).length;
      }
    });

    return res;
  };

  openTraitCategoriesModal = () => {
    this.setState({traitCategoriesModal: true});
  }

  closeTraitCategoriesModal = () => {
    this.setState({traitCategoriesModal: false});
  }

  handleTraitCategoryCreated = (category) => {
    this.handleTraitCategoryId(category.id);
    this.props.onTraitCategoryCreated(category);
  }

  handleTraitCategoryUpdated = (category) => {
    this.props.onTraitCategoryUpdated(category);
  }

  handleTraitCategoryDeleted = (categoryId) => {
    const { traitDetails } = this.state;
    if(traitDetails.traitCategoryId === categoryId) {
      this.handleTraitCategoryId(null);
    }
    this.props.onTraitCategoryDeleted(categoryId);
  }

  handleGroupRule = (e, groupid) => {
    const { traitDetails } = this.state;
    traitDetails.traitRuleGroups[groupid].clausesAndOr = e;

    this.setState({traitDetails}, () => this.props.onCheckChange());
  };

  handleRule = (e, groupid) => {
    const { traitDetails } = this.state;
    traitDetails.ruleGroupAndOr = e;
    this.setState({traitDetails}, () => this.props.onCheckChange());
  };

  handleAddRule = groupIndex => {
    const { traitDetails } = this.state;
    const blankRule = { ...this.state.blankRule };
    traitDetails.traitRuleGroups[groupIndex].traitRules.push(blankRule);

    this.rulesCount();
    this.setState({traitDetails, newRule: true}, () =>
        this.props.onCheckChange()
    );
  };
  handleRuleSave = (rule, groupIndex, ruleIndex) => {
    const { traitDetails } = this.state;

    traitDetails.traitRuleGroups[groupIndex].traitRules[ruleIndex] = rule;
    this.rulesCount();
    this.setState({traitDetails, newRule: false}, () =>
        this.props.onCheckChange()
    );
  };

  handleAddGroup = () => {
    const { traitDetails } = this.state;
    let blankGroup = {
      clausesAndOr: "AND",
      traitRules: [
        {
          new: true
        }
      ]
    };
    traitDetails.traitRuleGroups.push(blankGroup);
    this.setState({traitDetails, newRule: true}, () =>
        this.props.onCheckChange()
    );
  };
  handleDeleteRule = (ruleIndex, groupIndex) => {
    const { traitDetails } = this.state;
    if (traitDetails.traitRuleGroups[groupIndex].traitRules.length === 1) {
      traitDetails.traitRuleGroups.splice(groupIndex, 1);
    } else {
      traitDetails.traitRuleGroups[groupIndex].traitRules.splice(ruleIndex, 1);
    }

    this.setState({traitDetails, newRule: false, newGroup: false}, () => this.props.onCheckChange());

  };
  handleRuleMatchCondition = (e, ruleIndex, groupIndex) => {
    const { traitDetails } = this.state;
    traitDetails.traitRuleGroups[groupIndex].traitRules[ruleIndex].matchCondition =
        e.target.value;

    this.setState({traitDetails}, () => this.props.onCheckChange());
  };

  render() {
    const {
      totalSize,
      traitDetails,
      orderPaymentStatuses,
      isLoading,
      isPreviewLoading,
      validate,
      newTrait,
      enablePreview,
      traitCategoriesModal,
      editable
    } = this.state;

    const {onBack, dataSchema, dataViewId} = this.props;
    if (!isLoading) {
      return (
          <React.Fragment>
        <main
          className={`position-relative area-main secondary-font add-trait pt-0 ${enablePreview &&
          `has-grid`}`}
        >
          <section className="addsection-top pt-3 pb-3">
            <div className="container-fluid">
              {" "}
              <div className="row">
                <div className="col-sm-4 col-md-3 d-flex align-items-center custom-edit-trait">
                  <div className="heading d-flex">
                    <i
                      className="icon-chevron-thin-left mr-2"
                      onClick={() => onBack(this.props.currentTraitId)}
                    />
                    <h6 className="m-0">
                      {newTrait ? "Create a Customer Trait" : "Edit a Customer Trait"}
                    </h6>
                  </div>
                </div>
                <div className="col-sm-8 col-md-9 d-flex">
                  {enablePreview && (
                    <>
                      <View
                          dataSchema={dataSchema}
                          dataViewId={dataViewId}
                          onChangeView={this.props.onChangeView}
                      />
                      {isPreviewLoading && <div className="trait-info ml-4">
                        <h6>Preview</h6>
                        <span>
                          {totalSize} record{getPluralEnding(totalSize)}
                        </span>
                      </div>}
                    </>
                  )}
                  <div className="right-btn ">
                    {!enablePreview ? (
                      <React.Fragment>
                        {!newTrait && (
                          <Button
                            variant="secondary"
                            hidden={!editable}
                            onClick={() => this.checkTraitDependency()}
                          >
                            <span className="icon-delete-light"/> Delete
                          </Button>
                        )}

                        <Button
                          variant="secondary"
                          onClick={() => onBack(this.props.currentTraitId)}
                        >
                          Cancel
                        </Button>

                        <Button
                          variant="secondary"
                          disabled={this.state.newRule}
                          hidden={!editable}
                          onClick={() => this.handlePreviewTrait()}
                        >
                          Preview
                        </Button>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Button
                          variant="secondary"
                          onClick={() =>
                            this.setState({enablePreview: false})
                          }
                        >
                          Cancel
                        </Button>
                      </React.Fragment>
                    )}

                    {this.renderButtonType(!editable)}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="sideNav">
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Customer Trait Name</Form.Label>

              <Textbox
                disabled={!editable}
                classNameInput="form-control"
                classNameContainer="custome-input"
                tabIndex="1"
                id={"name"}
                name="name"
                type="text"
                validate={validate}
                validationCallback={res =>
                  this.setState({
                    hasTraitNameError: res,
                    validate: false
                  })
                }
                value={traitDetails.name}
                onChange={(val, e) => this.handleChange(e)}
                onBlur={e => {
                }}
                validationOption={{
                  name: "Customer Trait name",
                  check: true,
                  max: 30,
                  required: true
                }}
              />
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
              <Form.Label>Customer Trait Description</Form.Label>

              <Textarea
                disabled={!editable}
                classNameInput="form-control"
                classNameContainer="custome-input"
                tabIndex="1"
                id={"description"}
                name="description"
                type="text"
                validate={validate}
                validationCallback={res =>
                  this.setState({
                    hasTraitDescError: res,
                    validate: false
                  })
                }
                value={traitDetails.description}
                onChange={(val, e) => this.handleChange(e)}
                onBlur={e => {
                }}
                validationOption={{
                  name: "Customer Trait description",
                  check: true,
                  required: true
                }}
              />
            </Form.Group>

            <Form.Group controlId="formBasicDropDown">
              <Form.Label className="mb-0">Customer Trait Category</Form.Label>
              <Select
                  name={"traitCategory"}
                  value={traitDetails.traitCategoryId}
                  disabled={!editable}
                  optionList={this.props.traitCategories}
                  classNameSelect="select-control"
                  classNameWrapper="form-control-custom base-select-wrapper"
                  classNameContainer=""
                  classNameOptionListContainer="select-control-option"
                  classNameOptionListItem=""
                  customStyleSelect={{}}
                  customStyleWrapper={{}}
                  customStyleContainer={{}}
                  customStyleOptionListContainer={{}}
                  customStyleOptionListItem={{}}
                  onChange={e => this.handleTraitCategoryId(e)}
              />
            </Form.Group>

            <Form.Group controlId="traitCategoryModalLink">
              {editable && (<Link className="manage-trait-categories common-link" to="#" onClick={this.openTraitCategoriesModal} >
                Manage Trait Categories
              </Link>) }
            </Form.Group>
          </section>

          {enablePreview ? (
            <section className="right-list-area position-relative ">
              {isPreviewLoading ? (
                  <TraitCustomersDataGrid
                      traitId={this.props.currentTraitId}
                      dataViewId={this.props.dataViewId}
                      customerList={this.state.customerList}
                      onCustomerDetail={this.props.onCustomerDetail}
                      isUpdateState={this.props.isUpdateState}
                  />
              ) : (
                <Loader/>
              )}
            </section>
          ) : (
            <section className="right-list-area">
              <div className="content-body-top mt-3">

                {!editable && (
                  <h6>
                    <strong>Customer Trait cannot be altered as it is currently used in live segment or destinations</strong>
                  </h6>)}

                {!editable && (
                    <ListGroup style={{marginBottom: "30px"}} className="list-group-flush">
                      { }
                    </ListGroup>
                )}

                <h6>
                  <strong>Customer Trait Conditions</strong>
                </h6>
                <ListGroup as="ul" className="c-match">
                  <ListGroup.Item as="li">
                    <label>
                      Find Customers that match
                      <Select
                        tabIndex="4"
                        id={"fieldType"}
                        name={"fieldType"}
                        value={traitDetails.ruleGroupAndOr}
                        disabled={!editable}
                        optionList={[
                          {id: "OR", name: "ANY"},
                          {id: "AND", name: "ALL"}
                        ]}
                        classNameSelect="select-control"
                        classNameWrapper="form-control select-control-wrapper"
                        classNameContainer="dropdown-without-title"
                        classNameOptionListContainer="select-control-option"
                        classNameOptionListItem=""
                        customStyleSelect={{}}
                        customStyleWrapper={{}}
                        customStyleContainer={{}}
                        customStyleOptionListContainer={{}}
                        customStyleOptionListItem={{}}
                        onChange={e => this.handleRule(e)}
                      />
                      <span> of the specified conditions </span>{" "}
                    </label>
                  </ListGroup.Item>
                </ListGroup>
              </div>

              {traitDetails.traitRuleGroups.map((ruleGroup, key) => (
                  <RuleGroup
                      key={key}
                      groupIndex={key}
                      ruleGroup={ruleGroup}
                      orderPaymentStatuses={orderPaymentStatuses}
                      onGroupRuleChange={this.handleGroupRule}
                      onAddRule={this.handleAddRule}
                      onDeleteRule={this.handleDeleteRule}
                      onRuleMatchCondition={this.handleRuleMatchCondition}
                      onRuleSave={this.handleRuleSave}
                      editable={editable}
                  />
              ))}

              {this.checkGroupStatus().length <= 0 && (
                <Button
                  variant="secondary  mb-4 align-self-start add-link"
                  hidden={!editable}
                  onClick={this.handleAddGroup}
                >
                  {traitDetails.traitRuleGroups.length > 0
                    ? "+ Add Another Group"
                    : " + Add Rule Group"}
                </Button>
              )}

            </section>
          )}

          <section className="panel panel-default mb-0 device-info mt-5">
            <p className="panel-body text-center font-weight-bold">
              Not Supported in Small Devices
            </p>
          </section>
        </main>
            {traitCategoriesModal && (
                <TraitCategoriesModal
                    modalstate={traitCategoriesModal}
                    traitCategories={this.props.traitCategories}
                    onCloseModal={this.closeTraitCategoriesModal}
                    onTraitCategoryCreated={this.handleTraitCategoryCreated}
                    onTraitCategoryUpdated={this.handleTraitCategoryUpdated}
                    onTraitCategoryDeleted={this.handleTraitCategoryDeleted}
                />
            )}
          </React.Fragment>
      );
    } else {
      return <Loader/>;
    }
  }
}

export default NewTrait;
