import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from "@sentry/browser";
import {CaptureConsole} from "@sentry/integrations";

const getUrlVars = (url) => {
  const vars = {};
  url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    vars[key] = value;
  });
  return vars;
};

const distilAnonymousId = getUrlVars(window.location.href)["cfid"];

if (distilAnonymousId && distilAnonymousId.length === 36) {
  try {
    
    if(!window.localStorage.getItem('distil-ls-key')) {
      window.localStorage.setItem('distil-ls-key', '{}');
    }
    let currentDistilObj = JSON.parse(window.localStorage.getItem('distil-ls-key'));

    currentDistilObj['distil-anonymousId-key'] = distilAnonymousId;
    window.localStorage.setItem('distil-ls-key', JSON.stringify(currentDistilObj));
  } catch (e) {
  }

}


Sentry.init({
  dsn: "https://0ac21b02a2f34b90a2c024da67a3eb3c@o1047096.ingest.sentry.io/6024187",
  integrations: [new CaptureConsole()],
  environment: process.env.REACT_APP_ENV,
  autoSessionTracking: true,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.6,
});

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
