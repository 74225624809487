import React, {Component} from "react";
import "../assets/tracking-shopify.scss";
import shopifyTrackingInstruction from "../assets/img/shopify-tracking-instuction.svg";
import firstStep from "../assets/img/first-step.svg";
import secondStep from "../assets/img/second-step.svg";
import secondStepActive from "../assets/img/second-step-active.svg";
import link from "../assets/img/link.svg";
import AccountAPI from "../../../../../services/account";
import Loader from "../../../../../components/Loader/loader";
import CardLoader from "../../../DailyInsight/components/CardLoader";
import {toast} from "react-toastify";

class TrackingShopify extends Component {
    constructor(props) {
        super(props);
        const selectedMembers = JSON.parse(sessionStorage.getItem("selectedMembers"));

        this.state = {
            isFirstStep: true,
            selectedMembers: selectedMembers,
            checkValidation: false,
            isInvalidTracking: false,
        }
    }

    runValidation = () => {
        this.setState({checkValidation: true});
        AccountAPI.getShopifyTracking().then((response) => {
            this.setState({
                checkValidation: false,
                trackingIdCorrect: response.trackingIdCorrect,
                isInvalidTracking: !response.trackingIdCorrect || !response.extTrackingBlockEnabled
            });
            this.props.trackingIdIsUpdated(response.trackingIdCorrect);
        }).catch(() => {
            this.setState({
                checkValidation: false,
                trackingIdCorrect: false,
                isInvalidTracking: true,
            });
            toast.error("Something went wrong, try again");
        });
    }

    clickToTheLink = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
        this.setState({isFirstStep: false});
    };

    render() {
        const {isFirstStep, checkValidation, isInvalidTracking} = this.state;
        const {trackingId, linkShopify, loaded} = this.props;

        return (
            <>
                <React.Fragment>
                    <section className="area-instruction tracking-shopify">
                        {loaded ?
                            <>
                                <div className="first-step">
                                    <div className="first-step-title">
                                        <img src={firstStep} alt="first-step"/>
                                        <p>Copy your Distil Tracking code</p>
                                    </div>
                                    <div className="first-step-content">
                                        <p>Copy and paste your unique identifier into the Tracking Block section of your
                                            stores
                                            theme
                                            settings, found in your <b>Shopify Store Configuration</b> section. </p>
                                        <span className="tracking-code">{trackingId}</span>
                                        <div className="tracking-link">
                                            <a target="_blank" onClick={() => {
                                                this.clickToTheLink(linkShopify)
                                            }}>
                                                <img src={link} alt="link"/>Open Store Configuration
                                            </a>
                                            <span> in a new tab.</span>
                                        </div>
                                        <img className="instruction-image" src={shopifyTrackingInstruction}
                                             alt="shopify-tracking-instruction"/>
                                    </div>
                                </div>
                                <div className="second-step">
                                    <div className="second-step-title">
                                        <img src={isFirstStep ? secondStep : secondStepActive} alt="second-step"/>
                                        <p>Validate everything is setup correctly</p>
                                    </div>
                                    <div className="second-step-content">
                                        <p>Return here to auto-validate everything has been setup correctly.</p>
                                        {!isFirstStep &&
                                        (<div
                                            className="noTracking_basic_btn check-button  check-button--row check-button--shift">
                                            {checkValidation ?
                                                <div className="check-button_loading">
                                                    <Loader/>
                                                    <h6>Checking....</h6>
                                                </div>
                                                :
                                                <>
                                                    <button onClick={this.runValidation}>
                                                        Run Validation
                                                    </button>
                                                    <p className="text-warn" hidden={!isInvalidTracking}>
                                                        An issue has been detected with your tracking setup. Please
                                                        contact <b><u>support@distil.ai</u></b>
                                                    </p>
                                                </>
                                            }
                                        </div>)
                                        }
                                    </div>
                                </div>
                            </> : <CardLoader label={"Loading Shopify tracking configuration "}/>}
                    </section>
                </React.Fragment>
            </>
        );
    }
}

export default TrackingShopify;
