import React, {Component} from "react";
import {Card, Col} from "react-bootstrap";
import Loader from "../../../../../components/Loader/loader";
import Button from "react-bootstrap/Button";
import SweetAlert from "react-bootstrap-sweetalert";

class DataSource extends Component {
  constructor(props) {
    super(props);
    this.state = {
        showDialog: false
      };
  }

  showDialog = () => {
    this.setState({showDialog: true})
  };

  closeDialog = () => {
    this.setState({showDialog: false})
  };

  getAllEnabled = () => {
    return !this.props.dataSourceList.some(ds => ds.syncTurnedOn !== true);
  };

  getConfirmationText = () => {
    return this.getAllEnabled() ? `Turn syncs off for all ${this.props.title}?`
      : `Turn all syncs on for ${this.props.title}?`
  };

  switchDataSourceGroupState = () => {
    const currentState = this.getAllEnabled();
    const newState = !currentState;

    const connectionId = this.props.dataSourceList[0].connectionId;

    this.updateDataSources(this.props.dataSourceList.map(ds => ds.id), connectionId, newState).then(_ => this.closeDialog());
  };

  updateDataSources = async (dataSourceIds, connectionId, syncTurnedOn) => {
    try {
      await this.props.onUpdateDataSourcesState(dataSourceIds, connectionId, syncTurnedOn);
    } catch (err) {}
  };

  render() {
    const {isInfoLoaded} = this.props;
    const {showDialog} = this.state;
    const allDataSourcesEnabled = this.getAllEnabled();
    return (
      <Col lg={3}>
        <Card className="d-block align-top bg-white">
          <Card.Body style={{position: "relative"}}>
            <Card.Title>{this.props.title}</Card.Title>

            {showDialog && <SweetAlert
              custom
              showCancel
              confirmBtnText="Yes"
              cancelBtnText="No"
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="default"
              allowEscape={false}
              closeOnClickOutside={true}
              title={this.getConfirmationText()}
              onConfirm={() => this.switchDataSourceGroupState()}
              onCancel={() => this.closeDialog()}
            />}

            {isInfoLoaded ?
              this.props.dataSourceList.length > 0 ? (
                <div>
                  <div className="d-flex ds-state-switch flex-row mb-3"
                       onClick={() => this.showDialog()}>
                    <Button className="d-flex flex-row"
                            disabled={allDataSourcesEnabled}
                            variant="secondary"
                    >
                    </Button>
                    <span className={allDataSourcesEnabled ? "icon-toggle-on active" : "icon-toggle-off"}/>
                    <strong className={"text-ellipsis"}>Enable / Disable All</strong>
                  </div>
                  {this.props.dataSourceList.map(item => (
                    <div
                      className="detail-wrap cursor-pointer"
                      key={item.id}
                      onClick={() => this.props.onSelectDataSource(item)}
                    >
                      <div className="h6 font-weight-heavy">
                        <span className={"text-ellipsis"} title={item.name}>{item.name}</span>
                        {!item.syncTurnedOn ? (
                          <span className="icon-disable icon-little-margin"/>
                        ) : (
                          <i className={!item.lastDataSourceSyncStatus || item.lastDataSourceSyncStatus === "SUCCESS" ?
                            "icon-check_circle active" : item.lastDataSourceSyncStatus === 'SYNC_IN_PROGRESS' ?
                              "icon-sync refreshConnection" : item.lastDataSourceSyncStatus === 'SYNC_IN_PROGRESS_WRITING_TO_RT' ?
                                      "icon-sync refreshConnection" : "icon-check_circle error"}/>
                        )}
                      </div>
                      <div className="sub-head">
                        {" "}
                        {item.numberAttributesTotal}{" "}
                        Attributes available
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p>No data sources of this type available</p>
              )
              : <Loader size={30} style={{marginTop: 10, marginLeft: -15, position: "relative"}}/>}
          </Card.Body>
        </Card>
      </Col>
    );
  }
}

export default DataSource;
