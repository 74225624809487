import React, { Component } from "react";
import { Form } from "react-bootstrap";

class Options extends Component {
  state = {};

  render() {
     const { currentEnrichment,onAttributeChange } = this.props;
   
    return (
      <div className="options">
        <p>
          Enrich Customer data with the mix recommendation as well as individual
          recommedations from ...
        </p>
        <Form>
          {["checkbox"].map(type => (
            <div key={`custom-inline-${type}`} className="mb-3">
              <Form.Check
                custom
                label="Products Viewed but not purchased "
                type={type}
                disabled={!currentEnrichment.productRecOkToUseViewedNotPurchased}
                checked={currentEnrichment.productRecWriteViewedNotPurchased}
                id={`option-custom-inline-${type}-1`}
               onChange={()=>onAttributeChange("productRecWriteViewedNotPurchased",!currentEnrichment.productRecWriteViewedNotPurchased)}
              />
              <Form.Check
                custom
                label="Products Viewed together"
                type={type}
                disabled={!currentEnrichment.productRecOkToUseViewedTogether}
                checked={currentEnrichment.productRecWriteViewedTogether}
                id={`option-custom-inline-${type}-2`}
                onChange={()=>onAttributeChange("productRecWriteViewedTogether",!currentEnrichment.productRecWriteViewedTogether)}
              
              />
              <Form.Check
                custom
                label="Products Purchased together"
                type={type}
                disabled={!currentEnrichment.productRecOkToUsePurchasedTogether}
                checked={currentEnrichment.productRecWritePurchasedTogether}
                id={`option-custom-inline-${type}-3`}
                onChange={()=>onAttributeChange("productRecWritePurchasedTogether",!currentEnrichment.productRecWritePurchasedTogether)}
              
              />
              <Form.Check
                custom
                label="Follow-on purchase "
                type={type}
                disabled={!currentEnrichment.productRecOkToUseFollowOnPurchase}
                checked={currentEnrichment.productRecWriteFollowOnPurchase}
                id={`option-custom-inline-${type}-4`}
                onChange={()=>onAttributeChange("productRecWriteFollowOnPurchase",!currentEnrichment.productRecWriteFollowOnPurchase)}
              
              />
              <Form.Check
                custom
                label="Trending Products"
                type={type}
                id={`option-custom-inline-${type}-5`}
                disabled={!currentEnrichment.productRecOkToUseTrending}
                checked={currentEnrichment.productRecWriteTrending}
                onChange={()=>onAttributeChange("productRecWriteTrending",!currentEnrichment.productRecWriteTrending)}
              
              />
              <Form.Check
                custom
                label="Default Content "
                type={type}
                id={`option-custom-inline-${type}-6`}
                disabled={!currentEnrichment.productRecOkToUseDefaultProducts}
                checked={currentEnrichment.productRecWriteDefault}
                onChange={()=>onAttributeChange("productRecWriteDefault",!currentEnrichment.productRecWriteDefault)}
              
              />
              
            </div>
          ))}
        </Form>
      </div>
    );
  }
}

export default Options;
