import React, { Component } from "react";
import { Navbar, Nav, ListGroup, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./css/topnav.css";

class TopNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSection: "",
      nextSection: "",
      prevSection: ""
    };
  }

  componentWillReceiveProps = nextProps => {
    this.updatedSection(nextProps.currentSection, nextProps.navItems);
  };

  updatedSection = (currentSection, navItems) => {
    let index = navItems.findIndex(x => x.pageTag === currentSection);

    this.setState({ prevSection: navItems[index - 1] }); //Update Previous Section
    this.setState({ nextSection: navItems[index + 1] }); //Update Next Section
  };

  // Section Navigation
  navigateSection = direction => {
    if (this.state[direction] !== undefined) {
      this.props.onChangeSection(
        this.state[direction].pageTag,
        this.props.navItems
      );
    }
  };

  render() {
    const { navItems, currentSection, onChangeSection } = this.props;
    const { prevSection, nextSection } = this.state;
    return (
      <React.Fragment>
        <div className="tob-nav-bg">
          <Navbar className="border-0">
            <Nav className="mr-auto left-nav">
              {navItems.map((nav, key) => (
                <Nav.Link
                  key={key}
                  nav-label={nav.title}
                  onClick={() =>
                    onChangeSection(nav.pageTag, this.props.navItems)
                  }
                  className={` ${
                    nav.pageTag === currentSection ? "current" : ""
                  }`}
                >
                  {nav.title}
                </Nav.Link>
              ))}
            </Nav>
            <div className="section-right-nav ml-auto">
              <Nav>
                <ListGroup className="flex-row s-right-nav">
                  <Link
                    to="/destinations"
                    className="cancel"
                    onClick={e => this.props.onVerifyChange(e, "/destinations")}
                  >
                    Cancel
                  </Link>
                  {prevSection !== undefined && (
                    <Button
                    variant="secondary"
                      className="prev"
                      onClick={() => this.navigateSection("prevSection")}
                    >
                      Previous
                    </Button>
                  )}
                  {nextSection !== undefined && (
                    <Button
                      className="next"
                      onClick={() => this.navigateSection("nextSection")}
                    >
                      Next
                    </Button>
                  )}
                </ListGroup>
              </Nav>
            </div>
          </Navbar>
        </div>
      </React.Fragment>
    );
  }
}

export default TopNavigation;
