import React, { Component } from "react";
import UserSetting from "../Profile/UserSetting";
import Items from "./Items/Items";

import settingsroutes from "../../routes/settings";
import customerroutes from "../../routes/customers";

import "react-perfect-scrollbar/dist/css/styles.css";
// import PerfectScrollbar from "react-perfect-scrollbar";

class Header extends Component {
  state = {
    currentNav: []
  };
  search = () => {
    this.props.onSearch();
  };
  toggleSidebar = () => {
    this.props.onToggleSidebar();
  };
  togglePin = () => {
    this.props.onTogglePin();
  };
  userSetting = () => {
    this.props.onUserSetting();
  };

  renderSubNavigation() {
    const { selectedMembers, currentPage } = this.props;

    let currentNav = [];
    if (currentPage === "Settings") {
      currentNav = settingsroutes;
    } else if (currentPage === "Customer Data Studio" && selectedMembers?.cdpPermissions?.customerDataStudio) {
      currentNav = customerroutes;
    }

    if (currentNav.length > 0) {
      return (
        <div className="scrollbar-container">
          <ul className="mr-auto d-flex list-unstyled main-nav m-0 p-0">
            {currentNav.map((route, key) => {
              let hasRole = route.roles;
              let haveRole = selectedMembers.membershipType.toUpperCase();

              return route.component
                ? hasRole.indexOf(haveRole) > -1 && (
                    <Items
                      onVerifyChange={this.props.onVerifyChange}
                      routeItem={route}
                      key={key}
                    />
                  )
                : null;
            })}
          </ul>
        </div>
      );
    }
  }

  render() {
    const { currentPage } = this.props;
    let currentUser = JSON.parse(sessionStorage.getItem("selectedMembers"));

    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light pt-0 pb-0 pr-0 align-items-center secondary-font">
        <div onClick={this.toggleSidebar} className="nav-toggler" id="nav-button">
          <span className="toggle-menu d-inline-block">
            <i className="icon-menu" />
          </span>
          <span className="navbar-brand p-0 ">{currentPage}</span>
        </div>
        {this.renderSubNavigation()}
        <ul className="navbar-right d-flex ml-auto m-0 list-unstyled">
          <li onClick={() => this.props.onHelpModal(true)}>
            {/* <a
              href="https://bigconsultancy.atlassian.net/wiki/spaces/DHD/overview"
              target="_blank"
              rel="noopener noreferrer"
              className="q-nqme d-inline-block"
            >
              <i className="icon-question" />
            </a> */}
            <a className="q-nqme d-inline-block">
              <i className="icon-question" />
            </a>
          </li>
          {currentUser?.membershipType !== 'NEWSFEED' && currentUser?.membershipType !== 'TECHNICAL' && (
          <li
            onClick={this.search}
            className={this.props.isSearch ? "active" : ""}
          >
            <span className="search d-inline-block">
              <i className="icon-search" />
            </span>
          </li>
          )}
          <li className="userSection">
            <UserSetting contextUser={this.props.contextUser} />
          </li>

          {/*NOTE: This is the icon in the top right to expand the slide out section.  Hidden for now, with the intention That it will be re-instated later*/}
          {/*<li*/}
          {/*  className={`list-user ${this.props.pinToggle ? "active" : ""}`}*/}
          {/*  onClick={this.togglePin}*/}
          {/*  id="notice-btn-wrapper"*/}
          {/*>*/}
          {/*  <i id="notice-btn" className="icon-list" />*/}
          {/*  {this.props.newsfeedNotificationCount > 0 && (*/}
          {/*    <span className="info-note" />*/}
          {/*  )}*/}
          {/*</li>*/}
        </ul>
      </nav>
    );
  }
}

export default Header;
