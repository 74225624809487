import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { build } from "../../routes/main";
import "./assets/sidebar.css";

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let selectedMembers = JSON.parse(sessionStorage.getItem("selectedMembers"));
    let pageRouter;
    let { CONTRIBUTOR, TECHNICAL, READER, NEWSFEED, ADMIN } = build(selectedMembers.cdpPermissions);
    switch (selectedMembers.membershipType) {
      case "CONTRIBUTOR":
        pageRouter = CONTRIBUTOR;
        break;
      case "TECHNICAL":
        pageRouter = TECHNICAL;
        break;
      case "READER":
        pageRouter = READER;
        break;
      case "NEWSFEED":
        pageRouter = NEWSFEED;
        break;
      default:
        pageRouter = ADMIN;
        break;
    }
    return (
      <nav id="sidebar">
        <ul className="list-unstyled secondary-font">
          {pageRouter.map(route => {
            return route.component ? (
              <li className={route.className} key={route.className}>
                <NavLink
                  className=""
                  to={{
                    pathname: route.path
                  }}
                  onClick={e => this.props.currentPage(e, route)}
                >
                  <i className={route.icon} />
                  {route.name}
                </NavLink>
                { route.className === "destination" ?
                  <div className="div-line"/> : null}
              </li>
            ) : null;
          })}
        </ul>
      </nav>
    );
  }
}

export default Sidebar;
