import React, {Component} from "react";
import "./assets/css/styles.css";
import img from "../Customers/assets/img/screen.png";
import headphones from "./assets/img/headphones.svg";
import tailoredAnalytics from "./assets/img/tailored-analytics.svg";

class AnalyticsPortal extends Component {
  constructor(props) {
    super(props);
    let selectedMembers = JSON.parse(sessionStorage.getItem("selectedMembers"));

    this.state = {
      selectedMembers: selectedMembers || {},
      analyticsPortalUrl: selectedMembers.analyticsPortalUrl
    };
  }

  openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  render() {
    if (this.state.analyticsPortalUrl !== null && this.state.analyticsPortalUrl !== "") {
      return (
        <div className="analytics-portal-wrap bg-white lock-screen-component analytics-portal-main">
          <div className=" content-width new-top content">
            <div className="flex-block">
              <div className="top-text flex-block-item">
                <h3 className="font-weight-Avenirmedium mb-4">Your Customers, Your Data…</h3>
                <p className="font-on-top primary-font">
                  You now have access to the full potential of your Customer Data - jump in!<br/><br/>
                </p>
                <button
                    type="submit"
                    onClick={() => {
                      this.openInNewTab(this.state.analyticsPortalUrl)
                    }}
                    className="btn btn-primary green-btn"
                >
                  Go to Analytics Portal
                </button>
              </div>
            </div>
            <div className="content-image">
              <img src={tailoredAnalytics} alt="dataStudio"/>
            </div>
          </div>
        </div>
      );
    } else {
      return (
          <div className="analytics-portal-wrap bg-white lock-screen-component analytics-portal-main">
            <div className=" content-width new-top content">
              <div className="flex-block">
                <div className="top-text flex-block-item">
                  <h3 className="font-weight-Avenirmedium mb-4">Your Customers, Your Data…</h3>
                  <p className="font-on-top primary-font">
                    You now have access to the full potential of your Customer Date - give us a call, and we will guide
                    you around your custom portal<br/><br/>
                  </p>
                  <button
                      type="submit"
                      className="btn btn-primary black-btn mt-1"
                      onClick={() => this.openInNewTab("https://distil.ai/get-distil/#demo")}
                  >
                    <img className="top-picture" src={headphones} alt=""/>
                    Book a call now
                  </button>
                </div>
              </div>
              <div className="content-image">
                  <img src={tailoredAnalytics} alt="dataStudio"/>
              </div>
            </div>
        </div>
      );
    }
  }
}

export default AnalyticsPortal;
