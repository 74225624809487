import StripeService from "./stripeService";
import ApiService from "./apiService";

function getAllProducts() {
  const request = "/v1/products?active=true";
  return StripeService.get(request)
    .then(response => {
      return response.data;
    });
}

function getAllPricesForProduct(productId) {
  const request = "/v1/prices?active=true&product=" + productId;
  return StripeService.get(request).then(response => {
    return response.data;
  });
}

export default {
  getAllProducts,
  getAllPricesForProduct
};

