import ApiService from "./tenantApiService";

function get(url) {
  return ApiService.get(url).then(response => {
    return response.data;
  });
}

function post(url, payload) {
  return ApiService.post(url, payload).then(response => {
    return response.data;
  });
}

function put(url, payload) {
  return ApiService.put(url, payload).then(response => {
    return response.data;
  });
}

function performDelete(url) {
  return ApiService.delete(url).then(response => {
    return response.data;
  });
}

function getTraits() {
  return get("/trait");
}

function getTraitsWithRules() {
  return get("/trait/private");
}

function getSingleTrait(id) {
  return get(`/trait/${id}`);
}

function createNewTrait(data) {
  return post("/trait", data);
}

function deleteTrait(id) {
  return performDelete(`/trait/${id}`);
}

function updateTrait(id, data) {
  return put(`/trait/${id}`, data);
}

function traitDependency(id) {
  return Promise.resolve(Math.random()>=0.5);
}

function traitPreview(trait, dataViewId) {
  let view = dataViewId != null ? `?view_id=${dataViewId}` : "";
  const requestUrl = "/trait/customer/preview" + view;

  return ApiService.post(requestUrl, trait).then(response => {
    return response.data;
  });
}

function getCustomerList(formData) {
  // works for preview only for now
  return Promise.resolve({firstPageCustomers: [], totalRecordsCount: 0});
}

function getCustomerListMobile() {
  // works for preview only for now
  return Promise.resolve({firstPageCustomers: [], totalRecordsCount: 0});
}

function getTraitCategories() {
  return get("/trait/category");
}

function getSingleTraitCategory(id) {
  return get(`/trait/category/${id}`);
}

function createNewTraitCategory(data) {
  return post("/trait/category", data);
}

function deleteTraitCategory(id) {
  return performDelete(`/trait/category/${id}`);
}

function updateTraitCategory(id, data) {
  return put(`/trait/category/${id}`, data);
}

function getTraitCustomersCount(id) {
  return get(`/trait/${id}/match_count`).then(response => response.currentMatchCount);
}

function getTraitBuildStatus(id) {
  return get(`/trait/${id}/build_status`);
}

function searchProducts(search) {
  if(search.scrollId) {
    return get("/trait/product/search?scrollId=" + search.scrollId);
  }
  return get(`/trait/product/search?searchTerm=${search.term}&pageSize=${search.pageSize || 20}&available=${!!search.available}`);
}

export default {
  getTraits,
  getTraitsWithRules,
  getSingleTrait,
  createNewTrait,
  deleteTrait,
  updateTrait,
  traitDependency,
  traitPreview,
  getCustomerList,
  getCustomerListMobile,
  getTraitCategories,
  getSingleTraitCategory,
  createNewTraitCategory,
  deleteTraitCategory,
  updateTraitCategory,
  getTraitCustomersCount,
  getTraitBuildStatus,
  searchProducts
}
