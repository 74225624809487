import React, {Component} from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import equal from "fast-deep-equal";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const labels = ["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"];

class BarChartVertical  extends Component {

  constructor(props) {
    super(props);

    this.state = {
      weekData: "",
      weekDataFormatted: "",
    };
  }

  componentDidMount() {
    this.setWeekData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!equal(this.props.cardData, prevProps.cardData)) {
      this.setWeekData();
    }
  }

  setWeekData() {
    const { cardData } = this.props;

    if (cardData) {
      this.setState({
        weekData: cardData?.data?.weekData,
        weekDataFormatted: cardData?.data?.weekData_formatted,
      })

      if (!!cardData?.data?.weekData) {
        this.addData(['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
            cardData?.data?.weekData, cardData?.data?.weekData_formatted);
      }
    }
  }

  addData(days, weekData, weekDataFormatted) {
    const barDataResult = [];
    const barDataFormattedResult = [];

    for (let i = 0; i < days.length; i++) {
       if (weekData) {
         const item = weekData[`${days[i]}`];
          barDataResult.push(item.forecast);
       }
       if (weekDataFormatted) {
          const itemFormatted = weekDataFormatted[`${days[i]}_formatted`];
          barDataFormattedResult.push(itemFormatted.forecast_formatted);
       }
    }

    this.setState({
      barData: barDataResult,
      barDataFormatted: barDataFormattedResult,
    })
  }

  render() {
    const { barDataFormatted, barData } = this.state;

    const data = {
      labels,
      datasets: [
        {
          label: "Forecast",
          data: barData,
          borderColor: "#00BDFF",
          backgroundColor: "#00BDFF",
        },
      ],
    };

    const options = {
      maintainAspectRatio: false,
      layout: {
        padding: {
          left: 20,
          right: 0,
          top: 40,
          bottom: 0,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          ticks: {
            display: true,
          },
          grid: {
            drawBorder: false,
            display: false,
          },
        },
      },
      indexAxis: "x",
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      plugins: {
        title: {
          display: false,
        },
        legend: {
          display: false,
          position: "bottom",
          align: "start"
        },
        tooltip: {
          callbacks: {
            label: (items) => {
              return `${barDataFormatted[items.dataIndex]}`;
            }
          }
        },
      },
    };
    console.log('barDataFormatted', barDataFormatted);
    return (
        <>
          <Bar options={options} data={data}/>
        </>
    );
  }
};
export default BarChartVertical;
