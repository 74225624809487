export function isTraitProcessing(trait) {
  return trait.buildStatus !== 'READY';
}

export function getTraitCategoriesSort() {
  return (a, b) => a.name.localeCompare(b.name);
}

export function toHumanReadableFormat(value) {
  if (value === undefined || value === null) {
    return "";
  }
  return value.toString().split(/_/g).map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
}

export function getTraitIconClass(trait, markAsUsed = false) {
  let baseIconClass = "icon icon-relative icon-customer_trait";
  const deps = trait.traitDependencies;

  if (deps.segmentDependencies.length > 0 || deps.destinationDependencies.length > 0) {
    baseIconClass += " trait-linked";
  }

  if (markAsUsed
      || deps.segmentDependencies.filter(seg => seg.destinationDependencies.filter(d => d.autoSync).length > 0).length > 0
      || deps.destinationDependencies.filter(d => d.autoSync).length > 0) {
    baseIconClass += " trait-used";
  }

  return baseIconClass;
}
