var localDebuggingConstants = {
  // BASE_URL: "https://staging-api.distil.ai:8888",
  // BASE_URL: "https://internal-api.distil.ai:9888",
  // BASE_URL: "https://development-api.distil.ai:8888",
  APP_URL: "http://localhost:3000",
  BASE_URL: "http://localhost:8086",
  BASE_MESSAGE: "Local",
  HELP_BASE_URL: "https://help.distil.ai/hc/en-gb",
  STRIPE_TOKEN: "sk_test_51LOFyoC2INs38zqgnEAymBtM55TCSCkySnYbgSgwn06mjDa1EKVvZaZNFtLlT3i8yyLkMnhGGRv9UWPobsRPJdcw00iBClpUgB",
  // HELP_BASE_URL: window.location.origin
};

var localGlobalConstants = {
  APP_URL: "http://localhost:3000",
  BASE_URL: "http://localhost:8086",
  BASE_MESSAGE: "Local",
  HELP_BASE_URL: "https://help.distil.ai/hc/en-gb",
  STRIPE_TOKEN: "sk_test_51LOFyoC2INs38zqgnEAymBtM55TCSCkySnYbgSgwn06mjDa1EKVvZaZNFtLlT3i8yyLkMnhGGRv9UWPobsRPJdcw00iBClpUgB",
};

var developmentGlobalConstants = {
  APP_URL: "https://development-api.distil.ai",
  BASE_URL: "https://development-api.distil.ai:8888",
  BASE_MESSAGE: "Development",
  HELP_BASE_URL: "https://help.distil.ai/hc/en-gb",
  STRIPE_TOKEN: "sk_test_51LOFyoC2INs38zqgnEAymBtM55TCSCkySnYbgSgwn06mjDa1EKVvZaZNFtLlT3i8yyLkMnhGGRv9UWPobsRPJdcw00iBClpUgB",
};

var stagingGlobalConstants = {
  APP_URL: "https://staging.distil.ai",
  BASE_URL: "https://staging-api.distil.ai:8888",
  BASE_MESSAGE: "Staging",
  HELP_BASE_URL: "https://help.distil.ai/hc/en-gb",
  STRIPE_TOKEN: "sk_test_51LOFyoC2INs38zqgnEAymBtM55TCSCkySnYbgSgwn06mjDa1EKVvZaZNFtLlT3i8yyLkMnhGGRv9UWPobsRPJdcw00iBClpUgB",
};

var productionGlobalConstants = {
  APP_URL: "https://data.distil.ai",
  BASE_URL: "https://internal-api.distil.ai:9888",
  BASE_MESSAGE: "Production",
  HELP_BASE_URL:"https://help.distil.ai/hc/en-gb",
  STRIPE_TOKEN: "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",
}

var config = localDebuggingConstants;
if (process.env.REACT_APP_ENV === "production") {
  config = productionGlobalConstants;
} else if (process.env.REACT_APP_ENV === "staging") {
  config = stagingGlobalConstants;
} else if (process.env.REACT_APP_ENV === "development") {
  config = developmentGlobalConstants;
} else if (process.env.REACT_APP_ENV === "local") {
  config = localGlobalConstants;
} else {
  config = localDebuggingConstants;
}

export const globalConstants = {
  // Add common config values here
  URL_INITIAL: "/internal-api/v0_0/",
  VERSION: "4.0.1",
  TRIAL_PERIOD_DAYS: 14,
  ...config
};

export const dataFormat = {
  MEDIUM: "MMM DD, YYYY",
  LONG: "MMM DD,YYYY (HH:MM:SS A)"
};
