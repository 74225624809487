import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import CommonMessage from "../../components/CommonMessage/commonMessage";
import { BannerPanel } from "../../components/Branding";
import logolight from "../../assets/img/brand/Logo-light.png";
import logo from "../../assets/img/brand/Logo.png";
import { Link } from "react-router-dom";
import { Textbox, Select, Checkbox } from "react-inputs-validation";
import { Form } from "react-bootstrap";
import AuthenticationService from "../../services/customerDetail";
import { toast } from "react-toastify";
import {
  businessSectors,
  countriesAndCodes,
  jobRoles
} from "../../../src/variables/globalValues.json";
import Prepare from "./pages/prepare";
import NewTrial from "./pages/NewTrial";
import Error500 from "./pages/Error500";

const domainNameRegx = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;

class CreateAccount extends Component {
  constructor(props) {
    super(props);
    this.myEmail = React.createRef();
    this.myPassword = React.createRef();
    this.state = {
      orgName: "",
      domainName: "",
      businessSectors: "null",
      countriesAndCodes: "GB",
      jobRoles: "null",
      supportedOnboarding: false,

      validForm: false,
      isPasswordField: "password",
      activeSection: "CREATE"
    };
  }
  componentDidMount() {
    document.title = "Distil - Create new Organisation";

    let userAccountData = JSON.parse(localStorage.getItem("userAccountData"));
    if (userAccountData === undefined) {
      window.location = "/";
    } else {
      this.setState({ isLoading: false });
      this.props.history.push("/createaccount");
    }
  }
  validateForm = () => {
    const orgNameValidation = this.state.orgName !== "";
    const domainNameValidation =
      this.state.domainName !== ""
        ? domainNameRegx.test(this.state.domainName)
        : true;
    const businessSectorsValidation = this.state.businessSectors !== "null";
    const countriesAndCodesValidation = this.state.countriesAndCodes !== "null";
    const jobRolesValidation = this.state.jobRoles !== "null";

    if (
      orgNameValidation &&
      domainNameValidation &&
      businessSectorsValidation &&
      countriesAndCodesValidation &&
      jobRolesValidation
    ) {
      this.setState({ validForm: true });
    } else {
      this.setState({ validForm: false });
    }
  };

  createOrganisation = async event => {
    event.preventDefault();
    const data = {
      orgName: this.state.orgName,
      primaryDomain: this.state.domainName,
      companySector: this.state.businessSectors,
      hqCountryCode: this.state.countriesAndCodes,
      jobRole: this.state.jobRoles,
      supportedOnboarding: this.state.supportedOnboarding
    };
    this.setState({ activeSection: "LOADER" });
    this.props.history.push("/createaccount/creatingorganisation");
    try {
      await AuthenticationService.createOrganisation(data).then(response => {
        setTimeout(() => {
          this.setState({ activeSection: "TRAIL" });
          this.props.history.push("/createaccount/organisationready");
        }, 5000);

        return response;
      });
    } catch (ex) {
      console.log(ex.response.status);
      let code = ex.response.data.code;

      if (code === "ENTITY_EXISTS") {
        this.setState({ activeSection: "CREATE" });
        this.props.history.push("/createaccount");
        toast.error("This orgnaisation exists");
      } else if (ex.response.status === 500) {
        this.setState({ activeSection: "Error500" });
        this.props.history.push("/createaccount/error");
        toast.error("Error whilst creating organisation");
      } else {
        this.props.history.push("/createaccount");
        toast.error("Error whilst creating organisation");
      }
    }
  };
  renderSection() {
    if (this.state.activeSection === "TRAIL") {
      return <NewTrial orgName={this.state.orgName} />;
    } else if (this.state.activeSection === "LOADER") {
      return <Prepare orgName={this.state.orgName} />;
    } else if (this.state.activeSection === "Error500") {
      return <Error500 orgName={this.state.orgName} />;
    } else {
      return (
        <div className="content-wrap bg-white choose-account create-trial">
          <div className="content-inner-wrap col-xl-9 col-md-11 ">
            <div className="top-wrap d-flex flex-column">
              <h1 className="font-weight-heavy">
                Create a New Trial Organisation
              </h1>
              <p>
                Unlock the facts that will help you speak to your whole audience
                in a uniquely personal way.
              </p>
            </div>

            {/* <h1 className="font-weight-heavy">Sign In</h1> */}

            <div className="form-wrap">
              <form onSubmit={this.createOrganisation}>
                <div className="form-group new-form-group">
                  <label>Organisation name</label>
                  <Textbox
                    classNameInput="form-control"
                    classNameContainer="custome-input"
                    tabIndex="1"
                    name="name"
                    type="text"
                    value={this.state.orgName}
                    placeholder="Organisation name"
                    autoComplete="off"
                    onChange={(orgName, e) => {
                      this.setState({ orgName }, () => this.validateForm());
                    }}
                    onBlur={e => {}}
                    validationOption={{
                      name: "Organisation name",
                      check: true,
                      required: true
                    }}
                  />
                </div>

                <div className="row-wrap d-flex">
                  <Form.Group className="selection-group">
                    <label>Business Sector</label>
                    <Select
                      name={"businessSectors"}
                      tabIndex="2"
                      value={this.state.businessSectors}
                      disabled={false}
                      optionList={businessSectors}
                      classNameSelect="select-control"
                      classNameWrapper="form-control select-control-wrapper selection-type"
                      classNameContainer=""
                      classNameOptionListContainer="select-control-option"
                      classNameOptionListItem=""
                      customStyleSelect={{}}
                      customStyleWrapper={{}}
                      customStyleContainer={{}}
                      customStyleOptionListContainer={{
                        maxHeight: "300px",
                        overflow: "auto"
                      }}
                      customStyleOptionListItem={{}}
                      onChange={(condition, e) =>
                        this.setState({ businessSectors: condition }, () =>
                          this.validateForm()
                        )
                      }
                      onBlur={e => {}}
                      validationOption={{
                        name: "Business Sectors",
                        check: true,
                        required: true
                      }}
                    />
                  </Form.Group>

                  <Form.Group className="selection-group">
                    <label>Country</label>
                    <Select
                      name={"countriesAndCodes"}
                      value={this.state.countriesAndCodes}
                      tabIndex="3"
                      disabled={false}
                      optionList={countriesAndCodes}
                      classNameSelect="select-control"
                      classNameWrapper="form-control select-control-wrapper selection-type"
                      classNameContainer=""
                      classNameOptionListContainer="select-control-option"
                      classNameOptionListItem=""
                      customStyleSelect={{}}
                      customStyleWrapper={{}}
                      customStyleContainer={{}}
                      customStyleOptionListContainer={{
                        maxHeight: "300px",
                        overflow: "auto"
                      }}
                      customStyleOptionListItem={{}}
                      onChange={(condition, e) =>
                        this.setState({ countriesAndCodes: condition }, () =>
                          this.validateForm()
                        )
                      }
                      onBlur={e => {}}
                      validationOption={{
                        name: "Country",
                        check: true,
                        required: true
                      }}
                    />
                  </Form.Group>
                </div>

                <div className="form-group new-form-group">
                  <label>Organisation domain name</label>
                  <Textbox
                    classNameInput="form-control"
                    classNameContainer="custome-input"
                    tabIndex="4"
                    name="name"
                    type="text"
                    value={this.state.domainName}
                    placeholder="Organisation domain name"
                    autoComplete="off"
                    onChange={(domainName, e) => {
                      this.setState({ domainName }, () => this.validateForm());
                    }}
                    onBlur={e => {}}
                    validationOption={{
                      name: "Domain name",
                      check: true,
                      required: false,
                      reg: /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/,
                      regMsg: "Enter valid domain name"
                    }}
                  />
                </div>

                <Form.Group className="selection-group">
                  <label>Your job role in the organisation</label>
                  <Select
                    name={"jobRoles"}
                    value={this.state.jobRoles}
                    tabIndex="5"
                    disabled={false}
                    optionList={jobRoles}
                    classNameSelect="select-control"
                    classNameWrapper="form-control select-control-wrapper selection-type"
                    classNameContainer=""
                    classNameOptionListContainer="select-control-option"
                    classNameOptionListItem=""
                    customStyleSelect={{}}
                    customStyleWrapper={{}}
                    customStyleContainer={{}}
                    customStyleOptionListContainer={{
                      maxHeight: "300px",
                      overflow: "auto"
                    }}
                    customStyleOptionListItem={{}}
                    onChange={(condition, e) =>
                      this.setState({ jobRoles: condition }, () =>
                        this.validateForm()
                      )
                    }
                    onBlur={e => {}}
                    validationOption={{
                      name: "Job Roles",
                      check: true,
                      required: true
                    }}
                  />
                </Form.Group>

                <div className="check-supported">
                  <Form.Group>
                    <Checkbox
                      id={"privateSegment"}
                      name={"privateSegment"}
                      onBlur={() => {}}
                      value={this.state.supportedOnboarding}
                      checked={this.state.supportedOnboarding}
                      labelHtml={
                        <div style={{ color: "#4a4a4a", marginTop: "2px" }}>
                          Supported Onboarding
                        </div>
                      }
                      onChange={() => {
                        this.setState(
                          {
                            supportedOnboarding: !this.state.supportedOnboarding
                          },
                          () => this.validateForm()
                        );
                      }}
                      onBlur={e => {}}
                      validationOption={{
                        name: "privateSegment",
                        check: true,
                        required: true
                      }}
                    />
                  </Form.Group>

                  {!this.state.supportedOnboarding ? (
                    <p>
                      Would you like us to help you onboard your data, make sure
                      the integrations you need are in place, as well as give
                      you a personalised tour of Distil? There is no cost or
                      obligation if you check this option. If you choose not to
                      do this now, it is available via the Help section.
                    </p>
                  ) : (
                    <p>
                      Ok great, one of our team will be in touch to arrange a
                      call. Please look out for an email from
                      onboarding@distil.ai.
                    </p>
                  )}
                </div>

                <div className="btn-wrap">
                  <button
                    disabled={!this.state.validForm}
                    type="submit"
                    className="btn btn-primary green-btn"
                  >
                    Next
                  </button>

                  <Link className="return" to="/chooseaccount">
                    Return to Choose Account
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="app">
        <div className="login">
          <div className="container-fluid">
            <div className="row no-gutters h-100">
              <div className="col-lg-5">
                <BannerPanel />
              </div>

              <div className="col-lg-7">{this.renderSection()}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateAccount;
