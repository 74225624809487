import React, { Component } from "react";
import { ResponsiveLine } from "@nivo/line";
import moment from "moment";

function handler(e) {
  var startSection = document.getElementsByClassName("topRecomendation")[0];
  e = e || window.event;
  let chartViews = document.getElementsByClassName("chart-views")[0];
  let chartOffsetLeft = chartViews !== undefined ? chartViews.offsetLeft : 0;
  if (window.innerWidth < 991) {
    var pageX = e.pageX - chartOffsetLeft;
    console.log(pageX);
  } else {
    var pageX = e.pageX - chartOffsetLeft - 130;
    var pageY = e.pageY;
  }

  // IE 8
  if (pageX === undefined) {
    pageX = e.clientX + startSection.scrollLeft + startSection.scrollLeft;
    pageY =
      e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
  }

  // console.log(pageX);
  return pageX;
}

class LineChart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="trendingChart">
        <ResponsiveLine
          data={this.props.data}
          margin={{
            top: 30,
            right: 30,
            bottom: 50,
            left: 30
          }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear",
            stacked: false,
            min: "auto",
            max: "auto"
          }}
          curve="natural"
          axisBottom={{
            orient: "bottom",
            tickSize: 0,
            tickPadding: 30,
            tickRotation: 0,
            legend: "",
            legendOffset: -40,
            legendPosition: "start",
            renderTick: ({
              textAnchor,
              textBaseline,
              textX,
              textY,
              value,
              x,
              y
            }) => {
              return (
                <g transform={`translate(${x},${y})`}>
                  <text
                    alignmentBaseline={textBaseline}
                    textAnchor={textAnchor}
                    transform={`translate(${textX},${textY})`}
                  >
                    {moment(value).format("DD MMM YY ")}
                  </text>
                </g>
              );
            }
          }}
          axisRight={null}
          axisTop={null}
          axisLeft={{
            orient: "left",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "",
            legendOffset: -40,
            legendPosition: "middle"
          }}
          tooltip={e => {
            // init();
            var mouseX = handler();
            let chartViews = document.getElementsByClassName("chart-views")[0];
            let chartOffsetWidth = chartViews !== undefined ? chartViews.offsetWidth : 336;
            console.log(mouseX);
            var chartWrapper = chartOffsetWidth;
            if (mouseX < chartWrapper / 2) {
              // var toolTip = 'left';
              return (
                <div
                  className="chordToolTips"
                  style={{
                    left: 0,
                    position: "absolute",
                    top: 15,
                    width: "max-content"
                  }}
                >
                  {/* {chartPosition()} */}
                  {/* <span className="dataTime">
                      {moment(e.point.data.x).format("HH:mm a")}
                    </span>
                    <br /> */}
                  <div
                    className="colorBox"
                    style={{ background: e.point.serieColor }}
                  />
                  {e.point.serieId} :<strong>{e.point.data.y}</strong>{" "}
                </div>
              );
            } else {
              return (
                <div
                  className="chordToolTips"
                  style={{
                    right: 0,
                    position: "absolute",
                    top: 15,
                    width: "max-content"
                  }}
                >
                  <div
                    className="colorBox"
                    style={{ background: e.point.serieColor }}
                  />
                  {e.point.serieId} :<strong>{e.point.data.y}</strong>{" "}
                </div>
              );
            }
          }}
          lineWidth={1}
          pointSize={9}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabel="yFormatted"
          pointLabelYOffset={-24}
          enableArea={false}
          areaOpacity={0.6}
          useMesh={true}
          legends={[]}
        />
      </div>
    );
  }
}

export default LineChart;
