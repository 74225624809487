import Customers from "../views/Main/Customers/Customers";
import Destinations from "../views/Main/Destinations/Destinations";
import Settings from "../views/Main/Settings/Settings";
import AnalyticsPortal from "../views/Main/AnalyticsPortal/AnalyticsPortal";
import EmptyCustomers from "../views/Main/Customers/EmptyCustomers";
import EmptyDestinations from "../views/Main/Destinations/EmptyDestinations";
import AnalyticsPortalEmpty from "../views/Main/AnalyticsPortal/AnalyticsPortalEmpty";
import FunnelMetricsEmpty from "../views/Main/FunnelMetrics/FunnelMetricsEmpty";
import FunnelMetrics from "../views/Main/FunnelMetrics";
import DailyInsight from "../views/Main/DailyInsight/DailyInsight";

function getPermissions(permissions) {
  return {
        customerDataStudioComponent: permissions.customerDataStudio ? Customers : EmptyCustomers,
        destinationsComponent: permissions.destinations ? Destinations : EmptyDestinations,
        tailoredAnalyticsComponent: permissions.tailoredAnalytics ? AnalyticsPortal : AnalyticsPortalEmpty,
        funnelAnalyticsComponent: permissions.funnelAnalytics ? FunnelMetrics : FunnelMetricsEmpty,
    }
}

export function build(permissions) {
    if(!permissions) {
        return {};
    }

    const {
        customerDataStudioComponent,
        destinationsComponent,
        tailoredAnalyticsComponent,
        funnelAnalyticsComponent
    } = getPermissions(permissions);

    return {
        ADMIN : [
            { path: "/", exact: true, name: "Main", className: "main-route" },
            { path: "/daily-insight", name: "Daily Insight", component: DailyInsight , className:"daily-insight-link", icon:"icon-daily_insights"},
            { path: "/funnel_analytics", name: "Funnel Analytics", component: funnelAnalyticsComponent, className:"funnel_analytics" , icon:"icon-funnel_analytics"},
            { path: "/customers", name: "Customer Data Studio", component: customerDataStudioComponent, className:"customer-link", icon:"icon-multi_user"},
            { path: "/destinations", name: "Destinations", component: destinationsComponent, className:"destination" , icon:"icon-settings_input_antenna"},
            { path: "/analytics", name: "Tailored Analytics", component: tailoredAnalyticsComponent, className:"analytics_portal" , icon:"icon-tailored_analytics"},
            { path: "/settings", name: "Settings", component: Settings , className:"setting-link", icon:"icon-settings"}
        ],
        CONTRIBUTOR : [
          { path: "/", exact: true, name: "Main", className: "main-route" },
          { path: "/daily-insight", name: "Daily Insight", component: DailyInsight , className:"daily-insight-link", icon:"icon-daily_insights"},
          { path: "/funnel_analytics", name: "Funnel Analytics", component: funnelAnalyticsComponent, className:"funnel_analytics" , icon:"icon-funnel_analytics"},
          { path: "/customers", name: "Customer Data Studio", component: customerDataStudioComponent , className:"customer-link", icon:"icon-multi_user"},
          { path: "/destinations", name: "Destinations", component: destinationsComponent, className:"destination" , icon:"icon-settings_input_antenna"},
          { path: "/analytics", name: "Tailored Analytics", component: tailoredAnalyticsComponent, className:"analytics_portal" , icon:"icon-tailored_analytics"},
          { path: "/settings", name: "Settings", component: Settings , className:"setting-link", icon:"icon-settings"}
        ],
        TECHNICAL : [
          { path: "/", exact: true, name: "Main", className: "main-route" },
          { path: "/daily-insight", name: "Daily Insight", component: DailyInsight , className:"daily-insight-link", icon:"icon-daily_insights"},
          { path: "/settings", name: "Settings", component: Settings , className:"setting-link", icon:"icon-settings"}
        ],
        READER : [
          { path: "/", exact: true, name: "Main", className: "main-route" },
          { path: "/daily-insight", name: "Daily Insight", component: DailyInsight , className:"daily-insight-link", icon:"icon-daily_insights"},
          { path: "/funnel_analytics", name: "Funnel Analytics", component: funnelAnalyticsComponent, className:"funnel_analytics" , icon:"icon-funnel_analytics"},
          { path: "/customers", name: "Customer Data Studio", component: customerDataStudioComponent , className:"customer-link", icon:"icon-multi_user"},
          { path: "/destinations", name: "Destinations", component: destinationsComponent, className:"destination" , icon:"icon-settings_input_antenna"},
          { path: "/analytics", name: "Tailored Analytics", component: tailoredAnalyticsComponent, className:"analytics-portal" , icon:"icon-tailored_analytics"},
          { path: "/settings", name: "Settings", component: Settings , className:"setting-link", icon:"icon-settings"}
        ],
        NEWSFEED : [
          { path: "/", exact: true, name: "Main", className: "main-route" },
          { path: "/daily-insight", name: "Daily Insight", component: DailyInsight , className:"daily-insight-link", icon:"icon-daily_insights"},
          { path: "/settings", name: "Settings", component: Settings , className:"setting-link", icon:"icon-settings"}
        ]
    }
}