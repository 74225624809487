import React from "react";
import "./styles.scss";
import CountValue from "./CountValue";
import {Col, Row} from "react-bootstrap";
import downTriangle from "../../assets/img/down-triangle.svg";
import BrownTriangle from "../../assets/img/brown-triangle.svg";
import straight from "../../assets/img/straight-triangle.svg";
import Top3Products from "./Top3Products";
import LeadingPurchase from "./LeadingPurchase";

const MainBlock = ({data}) => {

    return (
        <Row className="content_cohort">
            <Row className="row-wrap-1">
                <Col lg={12}>
                    <Row
                        className="cohort-title display-none-max576">Funnel Metrics for {data?.cohortName} Cohort</Row>
                    <Row>
                        <div className="cohort-text">
                            <span>This Cohort is {" "}</span>
                            <span className="cohort-text_highlight">{data?.cohortAge}</span>
                            <span>{" "}days old. Starts on{" "}</span>
                            <span className="cohort-text_highlight">{data?.cohortStartDate}</span>
                            <span>{" "}and runs
                                            until{" "}</span>
                            <span className="cohort-text_highlight">{data?.cohortEndDate}</span>

                        </div>

                    </Row>
                </Col>
            </Row>
            <Row className="row-wrap-2">
                <Col lg={6}>
                    <Row className="revenue-block">
                        <Row className="revenue">Revenue from {data?.cohortName} Cohort</Row>
                        <Row className="big-revenue">{data?.cohortRevenue_formatted}</Row>
                        <Row className="change">

                            {data?.cohortRevenueDelta < 0 ?
                                <div>
                                    <img src={downTriangle} alt="" className="change_icon"/>
                                    <span
                                        className="change_number">
                                                    <CountValue
                                                        isPercent={true}
                                                        value={data?.cohortRevenueDelta ?? 0}
                                                        className="change_number"/>
                                                </span>
                                </div>
                                : data?.cohortRevenueDelta === 0 ? <div>
                                        <img src={BrownTriangle} alt="" className="change_icon"/>
                                        <span className="change_number">
                                                                <CountValue isPercent={true}
                                                                            value={data?.cohortRevenueDelta ?? 0}
                                                                            className="change_number"/>
                                                            </span>
                                    </div> :
                                    <div>
                                        <img src={straight} alt="" className="change_icon"/>
                                        <span className="change_number">
                                                                <CountValue isPercent={true}
                                                                            value={data?.cohortRevenueDelta ?? 0}
                                                                            className="change_number"/>
                                                    </span>
                                    </div>
                            }

                        </Row>
                    </Row>
                    <Row className="attributed-marketing">
                        <Row>
                            <div className="l-text ">
                                            <span className="fw-bold">
                                                <strong>
                                                    {data?.percentageRevenueAttributableToMarketingChannels_formatted ?? 0}{" "}
                                                </strong>
                                            </span>
                                <span>can be attributed to Marketing.{" "}</span>
                                <br/>
                                <span>
                                                <strong>
                                                    {data?.percentageRevenueAttributableToUnknown_formatted ?? 0}
                                                </strong>{" "}is Direct or Unknown.</span>
                            </div>

                        </Row>

                        <Row className="order-wrap">
                            <Col className="orders-statistic-block">
                                <Row className="order-wrap_text">Orders</Row>
                                <Row className="padding-left-0em  order-wrap_number">
                                    {data?.cohortTotalOrderNumber_formatted ?? 0}
                                </Row>
                                <Row>
                                    {data?.cohortTotalOrderDelta < 0 ?
                                        <Col className="order-wrap_icon">

                                            <img src={downTriangle} alt="" className="change_icon"/>
                                            <span className="change_number">
                                                            {data?.cohortTotalOrderDelta_formatted ?? 0}
                                                        </span>


                                        </Col> : data?.cohortTotalOrderDelta === 0 ?
                                            <Col className="order-wrap_icon">
                                                <img src={BrownTriangle} alt="" className="change_icon"/>
                                                <span className="change_number">
                                                                {data?.cohortTotalOrderDelta_formatted ?? 0}
                                                            </span>
                                            </Col> :
                                            <Col className="order-wrap_icon">
                                                <img src={straight} alt="" className="change_icon"/>
                                                <span className="change_number">
                                                                {data?.cohortTotalOrderDelta_formatted ?? 0}
                                                            </span>
                                            </Col>
                                    }
                                </Row>
                            </Col>
                            <Col>
                                <Row className="order-wrap_text">New customers</Row>
                                <Row className="padding-left-0em order-wrap_number">
                                    {data?.cohortTotalCustomerNumber_formatted ?? 0}
                                </Row>
                                <Row>
                                    {data?.cohortTotalCustomerDelta < 0 ?
                                        <Col className="order-wrap_icon">

                                            <img src={downTriangle} alt="" className="change_icon"/>
                                            <span className="change_number">
                                                            {data?.cohortTotalCustomerDelta_formatted ?? 0}
                                                        </span>

                                        </Col> : data?.cohortTotalCustomerDelta === 0 ?
                                            <Col className="order-wrap_icon">

                                                <img src={BrownTriangle} alt="" className="change_icon"/>
                                                <span className="change_number">
                                                                {data?.cohortTotalCustomerDelta_formatted ?? 0}
                                                            </span>
                                            </Col> :
                                            <Col className="order-wrap_icon">
                                                <img src={straight} alt="" className="change_icon"/>
                                                <span className="change_number">
                                                                {data?.cohortTotalCustomerDelta_formatted ?? 0}
                                                            </span>
                                            </Col>
                                    }
                                </Row>
                            </Col>
                            <Col/>
                        </Row>
                    </Row>
                </Col>

                {/*<Col lg={6} className="row-1-col-2 display-none-max576">*/}
                {/*    <LeadingPurchase leadingPurchase={data?.leadingPurchase} leadingPurchase_formatted={data?.leadingPurchase_formatted}/>*/}
                {/*    <Top3Products topThreeProducts={data?.topThreeProducts} topThreeProducts_formatted={data?.topThreeProducts_formatted}/>*/}
                {/*</Col>*/}
            </Row>
        </Row>
    );
};

export default MainBlock;