import React, { Component } from "react";

import { Link } from "react-router-dom";

class NewShopifyTrial extends Component {


  componentDidMount() {
    sessionStorage.setItem('isShopify', 'true');
  }

  render() {
    return (
      <div className="content-wrap bg-white">
        <div className="content-inner-wrap newtrial mw-100">
          <div className=" my-auto d-flex flex-column align-items-center">
            <h3>Your Distil account is ready!</h3>
            <Link className="btn btn-primary green-btn" to="/chooseaccount?isShopify=true">
              Let's Go
            </Link>
            
          </div>
        </div>
      </div>
    );
  }
}

export default NewShopifyTrial;
