import React, { Component } from "react";
import { Form, Col, Button, Collapse } from "react-bootstrap";
import DataConnectionAPI from "../../../../../services/dataConnections";
import NewConnectionMeta from "./NewConnectionMeta";
import { Textbox, Textarea } from "react-inputs-validation";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import { toast } from "react-toastify";

const initialState = {
  updatingContent: true,
  conectionName: "",
  conectionDesc: "",
  connectionType: "",
  connectionSettingsUserName: "",
  connectionSettingsPassword: "",
  connectionDatabaseName: "",
  connectionSettingsServerAddress: "",
  connectionSettingsPort: "",
  connectionSettingsSchema: "",
  connectionSettingsSsh_enabled: false,
  connectionSettingsSsh_serverAddress: "",
  connectionSettingsSsh_port: "",
  connectionSettingsSsh_userName: "",
  connectionSettingsSsh_password: "",
  connectionSettingsSsh_key: "",
  connectionSettingsSsh_passphrase: "",

  hasConectionNameError: true,
  hasConectionDescError: true,

  hasConnectionSettingsUserNameError: true,
  hasConnectionSettingsPasswordError: true,
  hasConnectionDatabaseNameError: true,
  hasConnectionSettingsServerAddressError: true,
  hasConnectionSettingsPortError: true,
  hasConnectionSettingsSchemaError: true,
  hasConnectionSettingsSsh_enabledError: true,
  hasConnectionSettingsSsh_serverAddressError: true,
  hasConnectionSettingsSsh_portError: true,
  hasConnectionSettingsSsh_userNameError: true,
  hasConnectionSettingsSsh_passwordError: true,
  hasConnectionSettingsSsh_keyError: true,
  hasConnectionSettingsSsh_passphraseError: true,

  validate: false,
  validateSetting: false,
  isConnectionVerified: false,
  isConnectionTested: "TEST",
  isLoaded: false,
  enabled: true
};
const editConnection = {
  disableOnEdit: false
};
const hasNoError = {
  hasConectionNameError: false,
  hasConectionDescError: false,
  hasConnectionSettingsUserNameError: false,
  hasConnectionSettingsPasswordError: false,
  hasConnectionDatabaseNameError: false,
  hasConnectionSettingsServerAddressError: false,
  hasConnectionSettingsPortError: false,
  hasConnectionSettingsSchemaError: false,
  hasConnectionSettingsSsh_enabledError: false,
  hasConnectionSettingsSsh_serverAddressError: false,
  hasConnectionSettingsSsh_portError: false,
  hasConnectionSettingsSsh_userNameError: false,
  hasConnectionSettingsSsh_passwordError: false,
  hasConnectionSettingsSsh_keyError: false,
  hasConnectionSettingsSsh_passphraseError: false,
  isConnectionVerified: true,
  isConnectionTested: "PASS"
};

class PostgreForm extends Component {
  constructor(props) {
    super(props);
    this.validateForm = this.validateForm.bind(this);
    this.validateSettings = this.validateSettings.bind(this);
    this.state = { initialState, editConnection };
  }

  resetData = () => {
    this.setState(initialState);
    localStorage.setItem("checkChange", false);
  };

  componentDidMount() {
    this.setState(initialState);
    this.setState({ connectionType: this.props.formType });
    if (this.props.editConnection) {
      this.editConnectionDefaults();
      // this.setState({ disableOnEdit: true });
      this.setState(hasNoError);
    }
  }

  componentWillReceiveProps(nextProps) {
    let checkChange = JSON.parse(localStorage.getItem("checkChange"));
    if (!checkChange) {
      if (nextProps.editConnection) {
        this.editConnectionDefaults();
      } else {
        if (this.props.editConnection) {
          this.resetData();
        }
      }
    }
  }

  editConnectionDefaults = () => {
    this.setState({
      conectionName: this.props.selectedConnection.name,
      conectionDesc: this.props.selectedConnection.description,
      connectionType: this.props.selectedConnection.connectionType,
      connectionSettingsUserName: this.props.selectedConnection
        .connectionSettings.userName,
      connectionSettingsPassword: this.props.selectedConnection
        .connectionSettings.password,
      connectionDatabaseName: this.props.selectedConnection.connectionSettings
        .databaseName,
      connectionSettingsServerAddress: this.props.selectedConnection
        .connectionSettings.serverAddress,
      connectionSettingsPort: this.props.selectedConnection.connectionSettings
        .port,
      connectionSettingsSchema: this.props.selectedConnection.connectionSettings
        .schema,
      connectionSettingsSsh_enabled: this.props.selectedConnection
        .connectionSettings.ssh_enabled,
      connectionSettingsSsh_serverAddress: this.props.selectedConnection
        .connectionSettings.ssh_serverAddress,
      connectionSettingsSsh_port: this.props.selectedConnection
        .connectionSettings.ssh_port,
      connectionSettingsSsh_userName: this.props.selectedConnection
        .connectionSettings.ssh_userName,
      connectionSettingsSsh_password: this.props.selectedConnection
        .connectionSettings.ssh_password,
      connectionSettingsSsh_key: this.props.selectedConnection
        .connectionSettings.ssh_key,
      connectionSettingsSsh_passphrase: this.props.selectedConnection
        .connectionSettings.ssh_passphrase,
      enabled: this.props.selectedConnection.enabled
    });
  };

  toggleValidatingInfo(validate) {
    this.setState({ validate });
  }

  toggleValidatingSetting(validateSetting) {
    this.setState({ validateSetting });
  }

  validateForm(e) {
    e.preventDefault();

    this.toggleValidatingInfo(true);
    this.toggleValidatingSetting(true);

    const {
      hasConectionNameError,
      hasConectionDescError,
      hasConnectionSettingsUserNameError,
      hasConnectionSettingsPasswordError,
      hasConnectionDatabaseNameError,
      hasConnectionSettingsServerAddressError,
      hasConnectionSettingsPortError,
      hasConnectionSettingsSchemaError,

      connectionSettingsSsh_enabled,
      hasConnectionSettingsSsh_serverAddressError,
      hasConnectionSettingsSsh_portError,
      hasConnectionSettingsSsh_userNameError,
      hasConnectionSettingsSsh_passwordError,
      hasConnectionSettingsSsh_keyError,
      hasConnectionSettingsSsh_passphraseError
    } = this.state;

    if (
      !hasConectionNameError &&
      !hasConectionDescError &&
      !hasConnectionSettingsUserNameError &&
      !hasConnectionSettingsPasswordError &&
      !hasConnectionDatabaseNameError &&
      !hasConnectionSettingsServerAddressError &&
      !hasConnectionSettingsPortError &&
      !hasConnectionSettingsSchemaError
    ) {
      this.setState({ updatingContent: true });
      if (connectionSettingsSsh_enabled) {
        if (
          !hasConnectionSettingsSsh_serverAddressError &&
          !hasConnectionSettingsSsh_portError &&
          !hasConnectionSettingsSsh_userNameError &&
          !hasConnectionSettingsSsh_passwordError &&
          !hasConnectionSettingsSsh_keyError &&
          !hasConnectionSettingsSsh_passphraseError
        ) {
          this.addConnection();
        } else {
          toast.warning("Please enter valid data in SSH fields");
        }
      } else {
        this.setState({
          connectionSettingsSsh_enabled: false,
          connectionSettingsSsh_serverAddress: "",
          connectionSettingsSsh_port: "",
          connectionSettingsSsh_userName: "",
          connectionSettingsSsh_password: "",
          connectionSettingsSsh_key: "",
          connectionSettingsSsh_passphrase: ""
        });
        this.addConnection();
      }
    } else {
      toast.warning("Please enter valid data in fields");
    }
  }

  validateSettings() {
    this.toggleValidatingSetting(true);
    const {
      hasConnectionSettingsUserNameError,
      hasConnectionSettingsPasswordError,
      hasConnectionDatabaseNameError,
      hasConnectionSettingsServerAddressError,
      hasConnectionSettingsPortError,
      hasConnectionSettingsSchemaError,

      connectionSettingsSsh_enabled,
      hasConnectionSettingsSsh_serverAddressError,
      hasConnectionSettingsSsh_portError,
      hasConnectionSettingsSsh_userNameError,
      hasConnectionSettingsSsh_passwordError,
      hasConnectionSettingsSsh_keyError,
      hasConnectionSettingsSsh_passphraseError
    } = this.state;

    if (
      !hasConnectionSettingsUserNameError &&
      !hasConnectionSettingsPasswordError &&
      !hasConnectionDatabaseNameError &&
      !hasConnectionSettingsServerAddressError &&
      !hasConnectionSettingsPortError &&
      !hasConnectionSettingsSchemaError
    ) {
      if (connectionSettingsSsh_enabled) {
        if (
          !hasConnectionSettingsSsh_serverAddressError &&
          !hasConnectionSettingsSsh_portError &&
          !hasConnectionSettingsSsh_userNameError &&
          !hasConnectionSettingsSsh_passwordError &&
          !hasConnectionSettingsSsh_keyError &&
          !hasConnectionSettingsSsh_passphraseError
        ) {
          this.testConnection();
        } else {
          toast.warning("Please enter valid data in SSH fields");
        }
      } else {
        this.setState({
          connectionSettingsSsh_enabled: false,
          connectionSettingsSsh_serverAddress: "",
          connectionSettingsSsh_port: "",
          connectionSettingsSsh_userName: "",
          connectionSettingsSsh_password: "",
          connectionSettingsSsh_key: "",
          connectionSettingsSsh_passphrase: ""
        });
        this.testConnection();
      }
    } else {
      toast.warning("Please enter valid data in connection fields");
    }
  }

  addConnection = async () => {
    this.setState({ updatingContent: true });
    if (this.state.isConnectionVerified) {
      let formData = {
        name: this.state.conectionName,
        description: this.state.conectionDesc,
        connectionType: this.state.connectionType,
        connectionSettings: {
          userName: this.state.connectionSettingsUserName,
          password: this.state.connectionSettingsPassword,
          databaseName: this.state.connectionDatabaseName,
          serverAddress: this.state.connectionSettingsServerAddress,
          port: this.state.connectionSettingsPort,
          schema: this.state.connectionSettingsSchema,
          ssh_enabled: this.state.connectionSettingsSsh_enabled,
          ssh_serverAddress: this.state.connectionSettingsSsh_serverAddress,
          ssh_port: this.state.connectionSettingsSsh_port,
          ssh_userName: this.state.connectionSettingsSsh_userName,
          ssh_password: this.state.connectionSettingsSsh_password,
          ssh_key: this.state.connectionSettingsSsh_key,
          ssh_passphrase: this.state.connectionSettingsSsh_passphrase
        }
      };
      if (this.props.editConnection) {
        let formUpdate = {
          id: this.props.selectedConnection.id,
          name: this.state.conectionName,
          description: this.state.conectionDesc,
          connectionType: this.state.connectionType,
          connectionSettings: {
            userName: this.state.connectionSettingsUserName,
            password: this.state.connectionSettingsPassword,
            databaseName: this.state.connectionDatabaseName,
            serverAddress: this.state.connectionSettingsServerAddress,
            port: this.state.connectionSettingsPort,
            schema: this.state.connectionSettingsSchema,
            ssh_enabled: this.state.connectionSettingsSsh_enabled,
            ssh_serverAddress: this.state.connectionSettingsSsh_serverAddress,
            ssh_port: this.state.connectionSettingsSsh_port,
            ssh_userName: this.state.connectionSettingsSsh_userName,
            ssh_password: this.state.connectionSettingsSsh_password,
            ssh_key: this.state.connectionSettingsSsh_key,
            ssh_passphrase: this.state.connectionSettingsSsh_passphrase
          },
          enabled: this.state.enabled
        };
        try {
          await DataConnectionAPI.updateConnection(
            formUpdate,
            this.props.selectedConnection.id
          ).then(response => {
            toast.success("Connection updated successfully");
            this.resetData();

            this.props.onAddingConnection(response.id);
            localStorage.setItem("checkChange", false);
          });
        } catch (err) {
          toast.error("Connection updating error");
        }
      } else {
        try {
          await DataConnectionAPI.newConnection(formData).then(response => {
            toast.success("Connection created successfully");
            this.resetData();
            this.props.onAddingConnection(response.id);
            localStorage.setItem("checkChange", false);
          });
        } catch (err) {
          toast.error("Connection creation error");
        }
      }
    } else {
      toast.warning("Please verify connection before saving changes", {
        onClose: ()=>this.setState({updatingContent:false})
      });
    }
  };

  wantSSH = () => {
    this.setState({
      connectionSettingsSsh_enabled: !this.state.connectionSettingsSsh_enabled
    });
  };

  testConnection = async () => {
    let formData = {
      connectionType: this.state.connectionType,
      connectionSettings: {
        userName: this.state.connectionSettingsUserName,
        password: this.state.connectionSettingsPassword,
        databaseName: this.state.connectionDatabaseName,
        serverAddress: this.state.connectionSettingsServerAddress,
        port: this.state.connectionSettingsPort,
        schema: this.state.connectionSettingsSchema,
        ssh_enabled: this.state.connectionSettingsSsh_enabled,
        ssh_serverAddress: this.state.connectionSettingsSsh_serverAddress,
        ssh_port: this.state.connectionSettingsSsh_port,
        ssh_userName: this.state.connectionSettingsSsh_userName,
        ssh_password: this.state.connectionSettingsSsh_password,
        ssh_key: this.state.connectionSettingsSsh_key,
        ssh_passphrase: this.state.connectionSettingsSsh_passphrase
      }
    };
    this.setState({ isConnectionTested: "TESTING", isLoaded: true });
    try {
      await DataConnectionAPI.testConnection(formData).then(response => {
        if (response) {
          this.setState({
            isConnectionVerified: true,
            isConnectionTested: "PASS"
          });
          toast.success("Connection verified");
        }
        if (!response) {
          this.setState({
            isConnectionVerified: false,
            isConnectionTested: "FAIL"
          });
          toast.warning("Connection verification failed");
        }
      });
    } catch (err) {
      this.setState({
        isConnectionVerified: false,
        isConnectionTested: "TEST"
      });
      toast.error("Connection verified error");
    }
  };

  testConnectioButtonStatus = () => {
    switch (this.state.isConnectionTested) {
      case "TEST":
        return (
          <Button variant="secondary" onClick={this.validateSettings}>
            <span className="icon-check icon" />
            Test Connection
          </Button>
        );
        break;
      case "PASS":
        return (
          <Button variant="secondary">
            <span className="icon-check_circle icon active" />
            Connection Tested
          </Button>
        );
        break;
      case "FAIL":
        return (
          <Button variant="secondary" onClick={this.validateSettings}>
            <span className="icon-close icon fail" />
            Test Connection Fail
          </Button>
        );
        break;

      default:
        break;
    }
  };

  onCheckChange = () => {
    this.setState(
      { isConnectionTested: "TEST", isLoaded: false },
      this.props.onCheckChange()
    );
  };

  render() {
    const {
      conectionName,
      conectionDesc,
      connectionSettingsUserName,
      connectionSettingsPassword,
      connectionDatabaseName,
      connectionSettingsServerAddress,
      connectionSettingsPort,
      connectionSettingsSchema,
      connectionSettingsSsh_enabled,
      connectionSettingsSsh_serverAddress,
      connectionSettingsSsh_port,
      connectionSettingsSsh_userName,
      connectionSettingsSsh_password,
      connectionSettingsSsh_key,
      connectionSettingsSsh_passphrase,

      validate,
      validateSetting,
      updatingContent,
      disableOnEdit,
      isConnectionVerified,
      isConnectionTested,
      isLoaded
    } = this.state;

    return (
      <React.Fragment>
        <NewConnectionMeta
          formType={this.props.formType}
          onBackPress={this.props.onBackPress}
          editConnection={this.props.editConnection}
        />
        <Form onSubmit={this.validateForm}>
          <Form.Row>
            <Form.Group as={Col} controlId="formGridName">
              <Form.Label>Connection Name</Form.Label>
              <Textbox
                classNameInput="form-control"
                classNameContainer="custome-input"
                tabIndex="1"
                id={"conectionName"}
                name="conectionName"
                type="text"
                validate={validate}
                validationCallback={res =>
                  this.setState({
                    hasConectionNameError: res,
                    validate: false
                  })
                }
                value={conectionName}
                onChange={(conectionName, e) => {
                  this.setState({ conectionName });
                  this.setState(
                    { updatingContent: false, isConnectionVerified: false },
                    () => this.onCheckChange()
                  );
                }}
                onBlur={e => {}}
                validationOption={{
                  name: "Connection Name",
                  check: true,
                  required: true
                }}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridDesc">
              <Form.Label>Description</Form.Label>
              <Textbox
                classNameInput="form-control"
                classNameContainer="custome-input"
                tabIndex="2"
                id={"conectionDesc"}
                name="conectionDesc"
                type="text"
                validate={validate}
                validationCallback={res =>
                  this.setState({
                    hasConectionDescError: res,
                    validate: false
                  })
                }
                value={conectionDesc}
                onChange={(conectionDesc, e) => {
                  this.setState({ conectionDesc });
                  this.setState(
                    { updatingContent: false, isConnectionVerified: false },
                    () => this.onCheckChange()
                  );
                }}
                onBlur={e => {}}
                validationOption={{
                  name: "Connection Description",
                  check: true,
                  required: true
                }}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridServer">
              <Form.Label>Server IP Address</Form.Label>
              <Textbox
                classNameInput="form-control"
                classNameContainer="custome-input"
                tabIndex="3"
                disabled={disableOnEdit}
                id={"connectionSettingsServerAddress"}
                name="connectionSettingsServerAddress"
                type="text"
                validate={validateSetting}
                validationCallback={res =>
                  this.setState({
                    hasConnectionSettingsServerAddressError: res,
                    validateSetting: false
                  })
                }
                value={connectionSettingsServerAddress}
                onChange={(connectionSettingsServerAddress, e) => {
                  this.setState(
                    {
                      connectionSettingsServerAddress,
                      isConnectionVerified: false
                    },
                    () => this.onCheckChange()
                  );
                }}
                onBlur={e => {}}
                validationOption={{
                  name: "Server IP Address",
                  check: true,
                  required: true
                  // reg: /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
                  // regMsg: "Enter Valid IP Address"
                }}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridIP">
              <Form.Label>Server Port</Form.Label>
              <Textbox
                classNameInput="form-control"
                classNameContainer="custome-input"
                tabIndex="4"
                disabled={disableOnEdit}
                id={"connectionSettingsPort"}
                name="connectionSettingsPort"
                type="text"
                validate={validateSetting}
                validationCallback={res =>
                  this.setState({
                    hasConnectionSettingsPortError: res,
                    validateSetting: false
                  })
                }
                value={connectionSettingsPort}
                onChange={(connectionSettingsPort, e) => {
                  this.setState(
                    { connectionSettingsPort, isConnectionVerified: false },
                    () => this.onCheckChange()
                  );
                }}
                onBlur={e => {}}
                validationOption={{
                  name: "Server Port",
                  check: true,
                  required: true,

                  reg: /^([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/,
                  regMsg: "Enter Valid Server Port"
                }}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridIP">
              <Form.Label>Database Name</Form.Label>
              <Textbox
                classNameInput="form-control"
                classNameContainer="custome-input"
                tabIndex="5"
                disabled={disableOnEdit}
                id={"connectionDatabaseName"}
                name="connectionDatabaseName"
                type="text"
                validate={validateSetting}
                validationCallback={res =>
                  this.setState({
                    hasConnectionDatabaseNameError: res,
                    validateSetting: false
                  })
                }
                value={connectionDatabaseName}
                onChange={(connectionDatabaseName, e) => {
                  this.setState(
                    { connectionDatabaseName, isConnectionVerified: false },
                    () => this.onCheckChange()
                  );
                }}
                onBlur={e => {}}
                validationOption={{
                  name: "Database Name",
                  check: true,
                  required: true
                }}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridServer">
              <Form.Label>Schema</Form.Label>
              <Textbox
                classNameInput="form-control"
                classNameContainer="custome-input"
                tabIndex="6"
                disabled={disableOnEdit}
                id={"connectionSettingsSchema"}
                name="connectionSettingsSchema"
                type="text"
                validate={validateSetting}
                validationCallback={res =>
                  this.setState({
                    hasConnectionSettingsSchemaError: res,
                    validateSetting: false
                  })
                }
                value={connectionSettingsSchema}
                onChange={(connectionSettingsSchema, e) => {
                  this.setState(
                    { connectionSettingsSchema, isConnectionVerified: false },
                    () => this.onCheckChange()
                  );
                }}
                onBlur={e => {}}
                validationOption={{
                  name: "Server Schema",
                  check: true,
                  required: true
                }}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridIP">
              <Form.Label>Username</Form.Label>
              <Textbox
                classNameInput="form-control"
                classNameContainer="custome-input"
                tabIndex="7"
                disabled={disableOnEdit}
                id={"connectionSettingsUserName"}
                name="connectionSettingsUserName"
                type="text"
                autoComplete="new-password"
                validate={validateSetting}
                validationCallback={res =>
                  this.setState({
                    hasConnectionSettingsUserNameError: res,
                    validateSetting: false
                  })
                }
                value={connectionSettingsUserName}
                onChange={(connectionSettingsUserName, e) => {
                  this.setState(
                    { connectionSettingsUserName, isConnectionVerified: false },
                    () => this.onCheckChange()
                  );
                }}
                onBlur={e => {}}
                validationOption={{
                  name: "Username",
                  check: true,
                  required: true
                }}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridServer">
              <Form.Label>Password</Form.Label>
              <Textbox
                classNameInput="form-control"
                classNameContainer="custome-input"
                tabIndex="8"
                disabled={disableOnEdit}
                id={"connectionSettingsPassword"}
                name="connectionSettingsPassword"
                type="password"
                autoComplete="new-password"
                validate={validateSetting}
                validationCallback={res =>
                  this.setState({
                    hasConnectionSettingsPasswordError: res,
                    validateSetting: false
                  })
                }
                value={connectionSettingsPassword}
                onChange={(connectionSettingsPassword, e) => {
                  this.setState(
                    { connectionSettingsPassword, isConnectionVerified: false },
                    () => this.onCheckChange()
                  );
                }}
                onBlur={e => {}}
                validationOption={{
                  name: "Password",
                  check: true,
                  required: true
                }}
              />
            </Form.Group>
          </Form.Row>

          <Button
            variant="info"
            size="sm"
            className="has-icon"
            onClick={this.wantSSH}
            aria-controls="example-collapse-text"
            aria-expanded={connectionSettingsSsh_enabled}
          >
            <i
              className={`icon-check_circle ${connectionSettingsSsh_enabled &&
                "active"}`}
            />{" "}
            Enable SSH
          </Button>

          <Collapse in={connectionSettingsSsh_enabled}>
            <div className="change-password-section">
              {connectionSettingsSsh_enabled && (
                <React.Fragment>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridUsername">
                      <Form.Label>SSH Server Address</Form.Label>
                      <Textbox
                        classNameInput="form-control"
                        classNameContainer="custome-input"
                        tabIndex="9"
                        disabled={disableOnEdit}
                        id={"connectionSettingsSsh_serverAddress"}
                        name="connectionSettingsSsh_serverAddress"
                        type="text"
                        validate={validateSetting}
                        validationCallback={res =>
                          this.setState({
                            hasConnectionSettingsSsh_serverAddressError: res,
                            validateSetting: false
                          })
                        }
                        value={connectionSettingsSsh_serverAddress}
                        onChange={(connectionSettingsSsh_serverAddress, e) => {
                          this.setState(
                            {
                              connectionSettingsSsh_serverAddress,
                              isConnectionVerified: false
                            },
                            () => this.onCheckChange()
                          );
                          console.log(e);
                        }}
                        onBlur={e => {
                          console.log(e);
                        }}
                        validationOption={{
                          name: "SSH Server Address",
                          check: true,
                          required: true
                          // reg: /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
                          // regMsg: "Enter Valid SSH Server Address"
                        }}
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPassword">
                      <Form.Label>SSH Port</Form.Label>
                      <Textbox
                        classNameInput="form-control"
                        classNameContainer="custome-input"
                        tabIndex="10"
                        disabled={disableOnEdit}
                        id={"connectionSettingsSsh_port"}
                        name="connectionSettingsSsh_port"
                        type="text"
                        validate={validateSetting}
                        validationCallback={res =>
                          this.setState({
                            hasConnectionSettingsSsh_portError: res,
                            validateSetting: false
                          })
                        }
                        value={connectionSettingsSsh_port}
                        onChange={(connectionSettingsSsh_port, e) => {
                          this.setState(
                            {
                              connectionSettingsSsh_port,
                              isConnectionVerified: false
                            },
                            () => this.onCheckChange()
                          );
                          console.log(e);
                        }}
                        onBlur={e => {
                          console.log(e);
                        }}
                        validationOption={{
                          name: "SSH Port",
                          check: true,
                          required: true,
                          reg: /^([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/,
                          regMsg: "Enter Valid SSH Port"
                        }}
                      />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridDatabase">
                      <Form.Label>SSH Username</Form.Label>
                      <Textbox
                        classNameInput="form-control"
                        classNameContainer="custome-input"
                        tabIndex="11"
                        disabled={disableOnEdit}
                        id={"connectionSettingsSsh_userName"}
                        name="connectionSettingsSsh_userName"
                        type="text"
                        validate={validateSetting}
                        validationCallback={res =>
                          this.setState({
                            hasConnectionSettingsSsh_userNameError: res,
                            validateSetting: false
                          })
                        }
                        value={connectionSettingsSsh_userName}
                        onChange={(connectionSettingsSsh_userName, e) => {
                          this.setState(
                            {
                              connectionSettingsSsh_userName,
                              isConnectionVerified: false
                            },
                            () => this.onCheckChange()
                          );
                          console.log(e);
                        }}
                        onBlur={e => {
                          console.log(e);
                        }}
                        validationOption={{
                          name: "SSH Username",
                          check: true,
                          required: false,
                          customFunc: res => {
                            if (
                              res === "" &&
                              (this.state.connectionSettingsSsh_passphrase ===
                                "" ||
                                this.state.connectionSettingsSsh_key === "")
                            ) {
                              return "SSH Username required in if no SSH Passphrase/Key";
                            }
                            return true;
                          }
                        }}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridSchema">
                      <Form.Label>SSH Password</Form.Label>
                      <Textbox
                        classNameInput="form-control"
                        classNameContainer="custome-input"
                        tabIndex="12"
                        disabled={disableOnEdit}
                        id={"connectionSettingsSsh_password"}
                        name="connectionSettingsSsh_password"
                        type="password"
                        validate={validateSetting}
                        validationCallback={res =>
                          this.setState({
                            hasConnectionSettingsSsh_passwordError: res,
                            validateSetting: false
                          })
                        }
                        value={connectionSettingsSsh_password}
                        onChange={(connectionSettingsSsh_password, e) => {
                          this.setState(
                            {
                              connectionSettingsSsh_password,
                              isConnectionVerified: false
                            },
                            () => this.onCheckChange()
                          );
                          console.log(e);
                        }}
                        onBlur={e => {
                          console.log(e);
                        }}
                        validationOption={{
                          name: "SSH Passoword",
                          check: true,
                          required: false,
                          customFunc: res => {
                            if (
                              res === "" &&
                              (this.state.connectionSettingsSsh_passphrase ===
                                "" ||
                                this.state.connectionSettingsSsh_key === "")
                            ) {
                              return "SSH Passoword required in if no SSH Passphrase/Key";
                            }
                            return true;
                          }
                        }}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridUsername">
                      <Form.Label>SSH Passphrase</Form.Label>
                      <Textbox
                        classNameInput="form-control"
                        classNameContainer="custome-input"
                        tabIndex="13"
                        disabled={disableOnEdit}
                        id={"connectionSettingsSsh_passphrase"}
                        name="connectionSettingsSsh_passphrase"
                        type="text"
                        validate={validateSetting}
                        validationCallback={res =>
                          this.setState({
                            hasConnectionSettingsSsh_passphraseError: res,
                            validateSetting: false
                          })
                        }
                        value={connectionSettingsSsh_passphrase}
                        onChange={(connectionSettingsSsh_passphrase, e) => {
                          this.setState(
                            {
                              connectionSettingsSsh_passphrase,
                              isConnectionVerified: false
                            },
                            () => this.onCheckChange()
                          );
                          console.log(e);
                        }}
                        onBlur={e => {
                          console.log(e);
                        }}
                        validationOption={{
                          name: "SSH Passphrase",
                          check: true,
                          required: false,
                          customFunc: res => {
                            if (
                              res === "" &&
                              (this.state.connectionSettingsSsh_userName ===
                                "" ||
                                this.state.connectionSettingsSsh_password ===
                                  "")
                            ) {
                              return "SSH Passphrase required in if no SSH UserName/Password";
                            }
                            return true;
                          }
                        }}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                      <Form.Label>SSH Key</Form.Label>
                      <Textarea
                        rows="5"
                        classNameInput="form-control"
                        classNameContainer="custome-input"
                        tabIndex="14"
                        disabled={disableOnEdit}
                        id={"connectionSettingsSsh_key"}
                        name="connectionSettingsSsh_key"
                        type="text"
                        validate={validateSetting}
                        validationCallback={res =>
                          this.setState({
                            hasConnectionSettingsSsh_keyError: res,
                            validateSetting: false
                          })
                        }
                        value={connectionSettingsSsh_key}
                        onChange={(connectionSettingsSsh_key, e) => {
                          this.setState(
                            {
                              connectionSettingsSsh_key,
                              isConnectionVerified: false
                            },
                            () => this.onCheckChange()
                          );
                          console.log(e);
                        }}
                        onBlur={e => {
                          console.log(e);
                        }}
                        validationOption={{
                          name: "SSH Key",
                          check: true,
                          required: false,
                          customFunc: res => {
                            if (
                              res === "" &&
                              (this.state.connectionSettingsSsh_userName ===
                                "" ||
                                this.state.connectionSettingsSsh_password ===
                                  "")
                            ) {
                              return "SSH Key required in if no SSH UserName/Password";
                            }
                            return true;
                          }
                        }}
                      />
                    </Form.Group>
                  </Form.Row>
                </React.Fragment>
              )}
            </div>
          </Collapse>
          <Form.Row className="btn-wrap">
            <Button variant="primary" type="submit" disabled={updatingContent}>
              Save Changes
            </Button>

            {isLoaded ? (
              isConnectionTested === "TESTING" ? (
                <Button variant="secondary">
                  <span className="icon-distil-logo icon spin" />
                  Testing Connection
                </Button>
              ) : (
                this.testConnectioButtonStatus()
              )
            ) : (
              this.testConnectioButtonStatus()
            )}

            {!disableOnEdit && (
              // <Button variant="secondary" onClick={this.resetData}>
              //   Cancel
              // </Button>
              <Button
                variant="secondary"
                onClick={this.props.onCancelConnection}
              >
                <span className="icon-cancel icon" />
                Cancel
              </Button>
            )}
          </Form.Row>
        </Form>
      </React.Fragment>
    );
  }
}

export default PostgreForm;
