const connectionTypes = [
  { name: "MAILCHIMP", image: require("./assets/img/mailchimp.svg") },
  { name: "KLAVIYO", image: require("./assets/img/klaviyo.svg") },
  { name: "KLAVIYO_V2", image: require("./assets/img/klaviyo_v2.png") },
  { name: "CAMPAIGN_MONITOR", image: require("./assets/img/campaignmonitor.svg") },
  { name: "SALESFORCE", image: require("./assets/img/salesforce.svg") },
  { name: "DOT_DIGITAL", image: require("./assets/img/dotdigital.png")},
  { name: "DOT_DIGITAL_V3", image: require("./assets/img/dotdigitalv3.png")},
  { name: "ZAPIER", image: require("./assets/img/zapier.svg")},
  { name: "HUBSPOT", image: require("./assets/img/hubspot.png")},
  { name: "API_CONNECTION", image: require("./assets/img/api-icon.svg")},
  { name: "ZEN_DESK", image: require("./assets/img/zendesk.png")},
  { name: "SHOPIFY", image: require("./assets/img/shopify.svg")},
  { name: "GOOGLE_ADS", image: require("./assets/img/adwords.svg")},
  { name: "AIRSHIP", image: require("./assets/img/airship.png")},
  { name: "ITERABLE", image: require("./assets/img/iterable.png")},
  { name: "PAYPAL", image: require("./assets/img/paypal.png")},
  { name: "FACEBOOK_LEAD_ADS", image: require("./assets/img/facebook_logo.png")},
  { name: "YOTPO", image: require("./assets/img/yotpo.png")},
  { name: "BRAZE", image: require("./assets/img/braze.png")},
  { name: "ACTIVE_CAMPAIGN", image: require("./assets/img/activecampaign.jpeg")},
  { name: "EVENT_BRITE", image: require("./assets/img/eventbrite.png")},
  { name: "INSTAGRAM", image: require("./assets/img/instagram.png")},
  { name: "INTERCOM", image: require("./assets/img/intercom.png")},
  { name: "LINKED_IN", image: require("./assets/img/linkedin.png")},
  { name: "MATTER", image: require("./assets/img/matter.png")},
  { name: "POWER_BI", image: require("./assets/img/powerbi.jpeg")},
  { name: "TWITTER", image: require("./assets/img/twitter.png")},
  { name: "CORE_DATA", image: require("./assets/img/distil.svg") },
];

const hiddenConnectionTypes = [ "KLAVIYO", "GOOGLE_ADS", "AIRSHIP",
  "ITERABLE", "PAYPAL", "YOTPO", "ACTIVE_CAMPAIGN", "EVENT_BRITE",
  "INSTAGRAM", "INTERCOM", "LINKED_IN", "MATTER", "POWER_BI", "TWITTER",
  "CORE_DATA", "DOT_DIGITAL" ];

const connectionTypeLabels = {
  "MAILCHIMP": "Mailchimp",
  "KLAVIYO": "Klaviyo",
  "KLAVIYO_V2": "Klaviyo",
  "CAMPAIGN_MONITOR": "Campaign Monitor",
  "SALESFORCE": "Salesforce",
  "DOT_DIGITAL": "Dotdigital",
  "DOT_DIGITAL_V3": "Dotdigital",
  "ZAPIER": "Zapier",
  "HUBSPOT": "Hubspot",
  "API_CONNECTION": "API Connection",
  "ZEN_DESK": "Zen Desk",
  "SHOPIFY": "Shopify",
  "GOOGLE_ADS": "Google Ads",
  "AIRSHIP": "Airship",
  "ITERABLE": "Iterable",
  "PAYPAL": "Paypal",
  "FACEBOOK_LEAD_ADS": "Facebook Lead Ads",
  "YOTPO": "Yotpo",
  "BRAZE": "Braze",
  "ACTIVE_CAMPAIGN": "Active Campaign",
  "EVENT_BRITE": "Event Brite",
  "INSTAGRAM": "Instagram",
  "INTERCOM": "Intercom",
  "LINKED_IN": "Linked In",
  "MATTER": "Matter",
  "POWER_BI": "Power BI",
  "TWITTER": "Twitter",
  "CORE_DATA": "Core Data",
};

export default connectionTypes;
export { hiddenConnectionTypes, connectionTypeLabels };
