import React, { Component } from "react";
import { Row, Col, Form, Table } from "react-bootstrap";

import Mixer from "../../../component/Mixer/Mixer";
import CustomeSearch from "../../../component/CustomeSearch";
import Loader from "../../../../../../../components/Loader/loader";
import EnrichmentsAPI from "../../../../../../../services/enrichments";
import moment from "moment";

class MixingDeck extends Component {
  state = {
    currentEnrichment: {},
    customerList: [],
    selectedCustomerId: "",
    selectedCustomerName: "",
    getRecommendations: {
      customerId: "",
      productPurchases: [],
      recommendationResults: []
    },
    itemName: {
      mixerValue: "productRecFactor",
      isEnable: "productRecEnabled",
      overDays: "productRec"
    },
    isLoadingRecomendation: false
  };

  onSliderUpdate = (name, value) => {
    this.props.onAttributeChange(name, value);

    const currentEnrichment = this.state.currentEnrichment;
    currentEnrichment[name] = value;
    this.setState({ currentEnrichment }, this.updateSettings);
  };

  updateSettings = () => {
    this.props.onUpdateValue(this.state.currentEnrichment);
    if (this.state.selectedCustomerId !== "") {
      this.getRecommendations(this.state.selectedCustomerId);
    }
  };

  componentDidMount() {
    this.setState({
      currentEnrichment: this.props.currentEnrichment
    });
  }

  componentWillReceiveProps(prevProps) {
    if (this.state.currentEnrichment !== prevProps.currentEnrichment) {
      this.setState({
        currentEnrichment: prevProps.currentEnrichment
      });
    }
  }

  customerSelected = async searchWhat => {
    this.setState({ searchWhat });
    try {
      await EnrichmentsAPI.customerSearch(searchWhat).then(response => {
        this.setState({ customerList: response });
        // console.log(response);
      });
    } catch (err) {}
  };

  onCustomerSelection = (id, name) => {
    this.setState({ selectedCustomerId: id, selectedCustomerName: name });
    this.getRecommendations(id);
  };

  getRecommendations = async id => {
    this.setState({ isLoadingRecomendation: true });
    try {
      await EnrichmentsAPI.getRecommendationsProduct(
        id,
        this.state.currentEnrichment
      ).then(response => {
        this.setState({ getRecommendations: response });
      });
    } catch (err) {}
    this.setState({ isLoadingRecomendation: false });
  };

  searchDone = () => {
    this.setState({ customerList: [] });
  };

  onAlreadyPurchased = () => {
    if (this.state.selectedCustomerId !== "") {
      this.getRecommendations(this.state.selectedCustomerId);
    }
    this.props.onAttributeChange(
      "productRecExcludeAlreadyPurchased",
      !this.state.currentEnrichment.productRecExcludeAlreadyPurchased
    );
  };

  render() {
    const {
      currentEnrichment,
      getRecommendations,
      selectedCustomerName,
      itemName,
      isLoadingRecomendation
    } = this.state;

    return (
      <Row className="no-gutters">
        <Col xs={12} xl={5}>
          <section className="slider-feature">
            <h6>Recommendation Mixing Deck</h6>
            <p>
              Tune the algorithm using the mixing deck then test the results for
              each customer.
            </p>
            <div className="bg-slider d-flex mb-4">
              <Mixer
                type="Viewed Not Purchased"
                ids="1"
                mixerValue={
                  currentEnrichment.productRecFactorViewedNotPurchased
                }
                disabled={!currentEnrichment.productRecOkToUseViewedNotPurchased}
                isEnable={currentEnrichment.productRecEnabledViewedNotPurchased}
                isSliderEnable={
                  currentEnrichment.productRecOkToUseViewedNotPurchased
                }
                itemName={itemName}
                mixerName={"ViewedNotPurchased"}
                onSliderUpdate={this.onSliderUpdate}
                numberOfItems={6}
              />
              <Mixer
                type="Viewed Together"
                ids="2"
                mixerValue={currentEnrichment.productRecFactorViewedTogether}
                isEnable={currentEnrichment.productRecEnabledViewedTogether}
                disabled={!currentEnrichment.productRecOkToUseViewedTogether}
                isSliderEnable={
                  currentEnrichment.productRecOkToUseViewedTogether
                }
                itemName={itemName}
                mixerName={"ViewedTogether"}
                onSliderUpdate={this.onSliderUpdate}
                numberOfItems={5}
              />
              <Mixer
                type="Purchased Together"
                ids="3"
                mixerValue={currentEnrichment.productRecFactorPurchasedTogether}
                isEnable={currentEnrichment.productRecEnabledPurchasedTogether}
                disabled={!currentEnrichment.productRecOkToUsePurchasedTogether}
                isSliderEnable={
                  currentEnrichment.productRecOkToUsePurchasedTogether
                }
                itemName={itemName}
                mixerName={"PurchasedTogether"}
                onSliderUpdate={this.onSliderUpdate}
                numberOfItems={5}
              />
              {/* <Mixer
                type="Follow On Purchase"
                ids="4"
                mixerValue={currentEnrichment.productRecFactorFollowOnPurchase}
                isEnable={currentEnrichment.productRecEnabledFollowOnPurchase}
                disabled={!currentEnrichment.productRecOkToUseFollowOnPurchase}
                isSliderEnable={
                  currentEnrichment.productRecOkToUseFollowOnPurchase
                }
                itemName={itemName}
                mixerName={"FollowOnPurchase"}
                onSliderUpdate={this.onSliderUpdate}
                numberOfItems={5}
              /> */}
              <Mixer
                type="Trending over past"
                ids="5"
                mixerValue={currentEnrichment.productRecFactorTrending}
                isEnable={currentEnrichment.productRecEnabledTrending}
                disabled={!currentEnrichment.productRecOkToUseTrending}
                isSliderEnable={currentEnrichment.productRecOkToUseTrending}
                reqOverDays={true}
                overDays={currentEnrichment.productRecTrendingOverDays}
                itemName={itemName}
                mixerName={"Trending"}
                onSliderUpdate={this.onSliderUpdate}
                numberOfItems={5}
              />
              <Mixer
                type="Default Products"
                ids="6"
                mixerValue={currentEnrichment.productRecFactorDefaultProducts}
                isEnable={currentEnrichment.productRecEnabledDefaultProducts}
                disabled={!currentEnrichment.productRecOkToUseDefaultProducts}
                isSliderEnable={
                  currentEnrichment.productRecOkToUseDefaultProducts
                }
                itemName={itemName}
                mixerName={"DefaultProducts"}
                onSliderUpdate={this.onSliderUpdate}
                numberOfItems={5}
              />
            </div>
            <div className="d-flex align-self-left exclude-purchase">
              <Form>
                {["checkbox"].map(type => (
                  <div
                    key={`custom-inline-${type}`}
                    className="mb-3 justify-content-center d-flex"
                  >
                    <Form.Check
                      custom
                      checked={
                        currentEnrichment.productRecExcludeAlreadyPurchased
                      }
                      onChange={this.onAlreadyPurchased}
                      inline
                      label="Exclude already purchased products"
                      type={type}
                      id={`custom-inline-${type}-6`}
                    />
                  </div>
                ))}
              </Form>
            </div>
          </section>
        </Col>
        <Col xs={12} xl={7}>
          <section className="area-filter pt-5">
            <CustomeSearch
              onCustomerSelect={this.customerSelected}
              customerList={this.state.customerList}
              onCustomerSelection={this.onCustomerSelection}
              onSearchDone={this.searchDone}
            />
            {isLoadingRecomendation && (
              <div className="over-lay">
                <Loader />
              </div>
            )}
            <section className="pl-5 pb-2 table-search-bg">
              <h5>{selectedCustomerName}</h5>

              {this.state.selectedCustomerName.length > 0 ? (
                <div className="tableWrapper">
                  <h6>Recommendations</h6>
                  <div className="table-search-container">
                    <Table>
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>Total</th>
                          <th>Viewed Not Purchased</th>
                          <th>Viewed Together</th>
                          <th>Purchased Together</th>
                          {/* <th>Follow On Purchase</th> */}
                          <th>Trending</th>
                          <th>Default Products</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getRecommendations.recommendationResults.length > 0 ? (
                          getRecommendations.recommendationResults.map(item => (
                            <tr>
                              <td>{item.productName}</td>
                              <td>{item.total}</td>
                              <td>{item.viewedNotPurchasedResult}</td>
                              <td>{item.viewedTogetherResult}</td>
                              <td>{item.purchasedTogetherResult}</td>
                              {/* <td>{item.followOnPurchaseResult}</td> */}
                              <td>{item.trendingResult}</td>
                              <td>{item.defaultProductsResult}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8}>
                              {this.state.selectedCustomerName.length > 0
                                ? "No customer recommendations available"
                                : "Please search for a customer to show recommendations for"}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>

                  <div className="pt-4 purchased-table">
                    <h6>Purchase History</h6>
                    <div className="row no-gutters">
                      <div className="col-lg-12 col-xl-8">
                        <div className="table-search-container">
                          <Table responsive="lg">
                            <thead>
                              <tr>
                                <th>Product</th>
                                <th>Purchased on</th>
                                <th>Times Purchased</th>
                              </tr>
                            </thead>
                            <tbody>
                              {getRecommendations.productPurchases.length >
                              0 ? (
                                getRecommendations.productPurchases.map(
                                  item => (
                                    <tr>
                                      <td>{item.productName}</td>
                                      <td>
                                        {moment
                                          .utc(item.purchasedOn)
                                          .format("MMM DD, YYYY")}
                                      </td>
                                      <td>{item.numTimesPurchased}</td>
                                    </tr>
                                  )
                                )
                              ) : (
                                <tr>
                                  <td colSpan={3}>
                                    {this.state.selectedCustomerName.length > 0
                                      ? "Customer has no purchase history"
                                      : "Please search for a customer to show recommendations for"}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                "Search for a Customer to see the Recommendations these settings generate"
              )}
            </section>
          </section>
        </Col>
      </Row>
    );
  }
}

export default MixingDeck;
