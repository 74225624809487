import React, { Component } from "react";
class NoRecord extends Component {
  state = {};
  render() {
    return (
      <div className={`no-Record ${this.props.classNaming}`}>
        <h4 className="section-Heading">{this.props.title}</h4>
        <p>This customer record currently appears in no <strong>{this.props.subTitle}</strong></p>
      </div>
    );
  }
}

export default NoRecord;
