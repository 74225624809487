import React, { Component } from "react";
import { ResponsiveLine } from "@nivo/line";
import millify from "millify";

function handler(e) {
  var startSection = document.getElementsByClassName("topRecomendation")[0];
  e = e || window.event;

  if (window.innerWidth < 991) {
    var pageX =
      e.pageX - document.getElementsByClassName("chart-views")[0].offsetLeft;

  } else {
    var pageX =
      e.pageX -
      document.getElementsByClassName("chart-views")[0].offsetLeft -
      130;
    var pageY = e.pageY;
  }

  // IE 8
  if (pageX === undefined) {
    pageX = e.clientX + startSection.scrollLeft + startSection.scrollLeft;
    pageY =
      e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
  }

  // console.log(pageX);
  return pageX;
}

class lineChartStatus extends Component {
  render() {
    const { data, isLinesOnly, noLine, tooltipLabel } = this.props;

    const CustomSymbol = ({ size, colors, borderWidth, borderColor, datum }) =>

      (datum.additionalStatus === 'SUCCESS') ? (
        <g transform={`translate(-9, -10)`}>
          <path
            d="M9 0C4.032 0 0 4.032 0 9s4.032 9 9 9 9-4.032 9-9-4.032-9-9-9zm0 16.2c-3.969 0-7.2-3.231-7.2-7.2 0-3.969 3.231-7.2 7.2-7.2 3.969 0 7.2 3.231 7.2 7.2 0 3.969-3.231 7.2-7.2 7.2zm-1.8-5.247l5.931-5.931L14.4 6.3l-7.2 7.2-3.6-3.6 1.269-1.269L7.2 10.953z"
            fill={"#9FCE1D"}
          />
        </g>
      ) : (
        <g transform={`translate(-9, -10)`}>
          <path
            d="M8.991 0C4.023 0 0 4.032 0 9s4.023 9 8.991 9C13.968 18 18 13.968 18 9s-4.032-9-9.009-9z"
            fill={datum.additionalStatus === 'ERROR' ? "#E54024" : "#F8BB1F"}
          />
          <path
            d="M9 16.434A7.433 7.433 0 011.565 9 7.433 7.433 0 019 1.565 7.433 7.433 0 0116.435 9 7.433 7.433 0 019 16.434z"
            fill={"#FFFFFF"}
          />
          <path
            d="M7.826 11.739h2.348v2.354H7.826v-2.354zM7.826 4.695h2.348v5.478H7.826V4.695z"
            fill={datum.additionalStatus === 'ERROR' ? "#E54024" : "#F8BB1F"}
          />
        </g>
      );

    return (
      <React.Fragment>
        <h3 className="lineChartTitle">{this.props.chartTitle}</h3>
        {data.length > 0 && (
          <div className="lineChart" style={{ height: "150px" }}>
            <ResponsiveLine
              data={data}
              margin={{
                top: 50,
                right: 40,
                bottom: 0,
                left: 40
              }}
              xScale={{ type: "point" }}
              yFormat={(v) => millify(v)}
              yScale={{
                type: "linear",
                stacked: false,
                min: "auto",
                max: "auto"
              }}
              curve={isLinesOnly ? "linear" : "monotoneX"}
              axisTop={{
                orient: "top",
                tickSize: 0,
                tickPadding: 30,
                tickRotation: 0,
                legend: "Timeline",
                legendOffset: -8,
                legendPosition: "start",
                renderTick: ({
                  textAnchor,
                  textBaseline,
                  textX,
                  textY,
                  value,
                  x,
                  y,
                  key
                }) => {
                  let dataSize = data[0].data.length - 1;
                  let startVal = 0;
                  let endVal = dataSize;
                  let midVal = Math.ceil(dataSize / 2);
                  return (
                    <g transform={`translate(${x},${y})`}>
                      <text
                        alignmentBaseline={textBaseline}
                        textAnchor={textAnchor}
                        transform={`translate(${textX},${textY})`}
                      >
                        {value === data[0].data[startVal].x ||
                        value === data[0].data[endVal].x ||
                        value === data[0].data[midVal].x
                          ? value
                          : ""}
                      </text>
                    </g>
                  );
                }
              }}
              axisRight={null}
              axisBottom={null}
              axisLeft={null}
              tooltip={e => {
                var mouseX = handler();

                var chartWrapper = document.getElementsByClassName(
                  "chart-views"
                )[0].offsetWidth;
                return (
                  <div
                    className="chordToolTips"
                    style={{
                      left: mouseX < chartWrapper / 2 ? 0 : "unset",
                      right: mouseX >= chartWrapper / 2 ? 0 : "unset",
                      position: "absolute",
                      top: 15,
                      width: "max-content"
                    }}
                  >
                    <strong>{tooltipLabel || e.point.data.tooltip}</strong>
                  </div>
                );
              }}
              layers={[
                "grid",
                "axes",
                "areas",
                "lines",
                "points",
                "mesh",
                "slices",
                "markers"
              ]}
              enableGridY={false}
              colors={[data[0].color]}
              lineWidth={noLine ? 0 : 1}
              pointSize={0}
              pointColor={{ theme: "background" }}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabel="yFormatted"
              pointLabelYOffset={-24}
              enableArea={!isLinesOnly}
              areaOpacity={0.6}
              useMesh={true}
              pointSymbol={CustomSymbol}
              legends={[]}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default lineChartStatus;
