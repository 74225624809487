import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";

class UserSetting extends Component {
  state = {
    showModel: false
  };
  chooseOrganisation = () => {
    let checkChange = JSON.parse(localStorage.getItem("checkChange"));
    this.setState({orgChanges:true})
    if (checkChange) {
      this.setState({ showModel: true });
    } else {
      this.organizationChange();
      this.setState({orgChanges:false})
    }
  };
  organizationChange = () => {
    sessionStorage.removeItem("selectedMembers");
    window.location = "/";
  };
  logout = () => {
    let checkChange = JSON.parse(localStorage.getItem("checkChange"));
    this.setState({logout:true})
    if (checkChange) {
      this.setState({ showModel: true });
    } else {
      this.logoutProcess();
      this.setState({logout:false})
    }
  };

  logoutProcess = () => {
    // todo remove access token 
    sessionStorage.clear();
    localStorage.clear();
    if (this.props.contextUser.isShopify) {
      window.location = 'https://distil.ai/';
    } else {
      window.location = "/";
    }
  };

  handleDiscard = () => {
    localStorage.setItem("checkChange", false);
    this.setState({ showModel: false });
    if(this.state.orgChanges) { this.organizationChange();}
    if(this.state.logout) { this.logoutProcess();}

    
  };

  render() {
    return (
      <React.Fragment>
        <ul className="list-unstyled">
          <li
            className="profile-user"
            data-toggle="dropdown"
            aria-expanded="false">
            <span>
              {this.props.contextUser.firstName.charAt(0)}
              {this.props.contextUser.lastName.charAt(0)}
            </span>
          </li>
          <li
            className="user-name "
            data-toggle="dropdown"
            aria-expanded="false">
            {this.props.contextUser.firstName}
          </li>

          <div className="dropdown-menu dropdown-menu-right">
            {!this.props.contextUser.isShopify && (
                <>
                  <span className="dropdown-item" onClick={this.chooseOrganisation}>
                    Change Account
                  </span>

                  <div className="dropdown-divider" />
                </>
            )}
             <span className="dropdown-item" onClick={this.logout}>Logout</span>

          </div>

        </ul>


        {this.state.showModel && (
          <SweetAlert
            custom
            showCancel
            confirmBtnText="Discard"
            cancelBtnText="Cancel"
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="default"
            allowEscape="false"
            closeOnClickOutside="false"
            title="Discard Changes "
            onConfirm={() => this.handleDiscard()}
            onCancel={() => this.setState({ showModel: false })}
          >
            Changes will be discarded by clicking discard
          </SweetAlert>
        )}
      </React.Fragment>
    );
  }
}

export default UserSetting;
