import React, {PureComponent} from 'react';
import {Pie, PieChart, ResponsiveContainer, Sector} from 'recharts';
import {formatNumber, roundTo} from "../segmentUtils";

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value} = props;

  const tooltipRenderAngle = Math.min(midAngle, 30)

  const sin = Math.sin(-RADIAN * tooltipRenderAngle);
  const cos = Math.cos(-RADIAN * tooltipRenderAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  let sectionLines = payload.sectionName.split('\n');
  let lineHeight = 16;
  let yStart = cy - lineHeight / 2 * (sectionLines.length - 1)

  function getSectionName() {
    return <g>
      {sectionLines.map((t, index) =>
        <text key={index} x={cx} y={yStart + index * lineHeight} dy={8} textAnchor="middle" fill={fill} fontWeight={600} color="#595959">
          {t}
        </text>
      )}
    </g>;
  }

  return (

    <g>
      {getSectionName()}

      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill="#9FCE1D"
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} fontWeight={700} textAnchor={textAnchor}
            fill="#333">{`${payload.id === 'segmentValue' ? '£' : ''}${formatNumber(roundTo(payload.realValue,2) || 0)}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {payload.subTitle}
      </text>
    </g>
  );
};

export default class SegmentPieChart extends PureComponent {

  state = {
    activeIndex: 0,
  };

  onPieEnter = (_, index) => {
    this.setState({
      activeIndex: index,
    });
  };

  render() {
    let {data, width} = this.props;

    return (
      <div style={{width: width, height: 132, marginBottom: 8}}>

        <ResponsiveContainer>
          <PieChart className="customer-pie-chart" margin={{top: 0, right: 0, bottom: 0, left: 25}}>
            <Pie className="customer-pie"
                 activeIndex={this.state.activeIndex}
                 activeShape={renderActiveShape}
                 data={data}
                 cx={65}
                 cy={66}
                 innerRadius={45}
                 outerRadius={55}
                 fill="#8B8B8B"
                 dataKey="value"
            />
          </PieChart>
        </ResponsiveContainer>
      </div>

    );
  }
}
