import React, { Component } from "react";
import { ListGroup, Modal, Button, Row, Col, Form } from "react-bootstrap";
import DataConnectionAPI from "../../../../../services/dataConnections";
import { Textbox, Textarea, Checkbox } from "react-inputs-validation";
import { toast } from "react-toastify";
import moment from "moment";

class DataSourceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: null,
      attributes: [],
      syncTurnedOn: false,
      showAttributeID: null,
      isLoading: false,
      editName: false,
      validateDataSourceName: false,
      hasDataSourceNameError: false
    };
  }

  componentDidMount() {
    const syncTurnedOn = this.props.content.syncTurnedOn;
    const content = this.props.content;
    const attributes = this.props.content.attributes;
    this.setState({
      syncTurnedOn,
      content,
      attributes,
      isLoading: true
    });
  }

  enableAttributeEdit = id => {
    this.setState({ showAttributeID: id });
  };

  undoAttributeEdit = key => {
    const attributes = [...this.state.attributes];
    let attribute = { ...attributes[key] };
    attribute.attributeDisplayName = this.props.content.attributes[
      key
      ].attributeDisplayName;
    attributes[key] = attribute;
    this.setState({ showAttributeID: null, attributes });
  };

  onEditAttribute = (e, key) => {
    const attributes = [...this.state.attributes];
    let attribute = { ...attributes[key] };
    attribute.attributeDisplayName = e.currentTarget.value;
    attributes[key] = attribute;
    this.setState({ attributes });
  };

  attributesAvailable() {
    // const attributesAvailable = this.props.content.attributes;
    const { showAttributeID, content, attributes } = this.state;
    if (attributes.length > 0) {
      return (
        <ListGroup as="ul">
          {attributes.map((list, key) => (
            // <DataSourceAttributes attribute={list} seq={key} />

            <ListGroup.Item
              as="li"
              className="position-relative border-0 editableAttribute"
              key={list.id}
            >
              {showAttributeID !== null && showAttributeID === list.id ? (
                <i
                  className="icon-history position-absolute"
                  onClick={() => this.undoAttributeEdit(key)}
                />
              ) : (
                <i
                  className="icon-edit position-absolute displayOnHover"
                  onClick={() => this.enableAttributeEdit(list.id)}
                />
              )}

              {this.state.showAttributeID === list.id ? (
                <Form.Control
                  type="text"
                  onChange={e => this.onEditAttribute(e, key)}
                  value={list.attributeDisplayName}
                  placeholder="Attribute Name"
                  className="data-source-attribute editAttribute"
                />
              ) : (
                <Form.Control
                  type="text"
                  value={list.attributeDisplayName}
                  placeholder="Attribute Name"
                  className="data-source-attribute"
                />
              )}
            </ListGroup.Item>
          ))}
        </ListGroup>
      );
    } else {
      return "All Selected";
    }
  }

  onEnableDisable = status => {
    this.setState({ syncTurnedOn: !status });
  };

  onEditName = e => {
    const content = { ...this.state.content };
    content.name = e.currentTarget.value;
    this.setState({ content });
  };

  onEditDescription = e => {
    const content = { ...this.state.content };
    content.description = e.currentTarget.value;
    this.setState({ content });
  };

  onApplyChanges = () => {
    const content = { ...this.state.content };
    const syncTurnedOn = this.state.syncTurnedOn;
    const attributes = [...this.state.attributes];
    content.attributes = attributes;
    content.syncTurnedOn = syncTurnedOn;
    this.setState({ content }, () => this.updateDataSourceAttr());
  };

  updateDataSourceAttr = async event => {
    try {
      await DataConnectionAPI.updateDataSourceAttr(this.state.content).then(
        response => {
          // this.setState({
          //   content: response
          // isLoading: true
          // });
          this.props.onAttrUpdate(response);
          toast.success("Data Source Updated Successfully");
          return this.props.onCloseModal();
        }
      );
    } catch (err) {}
  };

  render() {
    const {
      isLoading,
      editName,
      content,
      validateDataSourceName,
      hasDataSourceNameError
    } = this.state;
    return content !== null ? (
      <Modal
        className="model-attribute"
        show={this.props.modalstate}
        onHide={this.props.onCloseModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="border-bottom-0 pb-0">
          <Modal.Title className="" id="contained-modal-title-vcenter ">
            <div className="top-wrap">
              <div className="top-left-wrap data-source">
                <span>Data Source </span>
                {/* <Form.Control
                  type="text"
                  onChange={e => this.onEditName(e)}
                  value={content.name}
                  placeholder="Name"
                  className="data-source-name"
                /> */}
                <Textbox
                  classNameInput="form-control data-source-name"
                  classNameContainer="custome-input"
                  tabIndex="1"
                  id={"name"}
                  name="name"
                  type="text"
                  placeholder="Name"
                  autocomplete="off"
                  validate={validateDataSourceName}
                  validationCallback={res =>
                    this.setState({
                      hasDataSourceNameError: res,
                      validateDataSourceName: false
                    })
                  }
                  value={content.name}
                  onChange={(val, e) => this.onEditName(e)}
                  onBlur={e => {}}
                  validationOption={{
                    name: "Data source name",
                    check: true,
                    max: 200,
                    required: true
                  }}
                />
              </div>

              <button
                type="button"
                className={`btn btn-lg btn-toggle ${
                  this.state.syncTurnedOn ? "active" : ""
                }`}
                onClick={() => this.onEnableDisable(this.state.syncTurnedOn)}
              >
                <div className="handle" />
              </button>
            </div>
            <textarea
              onChange={e => this.onEditDescription(e)}
              value={content.description}
              placeholder="Description"
              className="form-control data-source-description"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <Row>
            <Col xs={12}>
              <h6>Attributes</h6>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6}>
              <section className="simple-attribute">
                {isLoading && this.attributesAvailable()}
              </section>
            </Col>
            <Col xs={12} sm={6}>
              <section className="active-attribute">
                <h6>
                  Last sync status
                  <span>
                     {this.state.content.lastDataSourceSyncStatus === null ? "Not synced yet" :
                       this.state.content.lastDataSourceSyncStatus === 'SYNC_IN_PROGRESS' ? "Sync in progress" :
                         this.state.content.lastDataSourceSyncStatus === 'SUCCESS' ? "Success" : "Error"}
                  </span>
                </h6>
                <h6>
                  Number of records
                  <span>
                    {this.state.content.numberOfRecords !== null
                      ? this.state.content.numberOfRecords
                      : "N/a"}
                  </span>
                </h6>
                {this.state.content.lastDataSourceSyncDate && this.state.content.lastDataSourceSyncStatus &&
                <h6>
                  Last sync date{" "}
                  <span>
                    {moment(this.state.content.lastDataSourceSyncDate).format("YYYY-MM-DD HH:mm:ss")}
                  </span>
                </h6>
                }
              </section>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="pl-0">
          <Button
            onClick={this.onApplyChanges}
            className="border-0"
            disabled={hasDataSourceNameError}
          >
            Apply
          </Button>
          <Button
            onClick={this.props.onCloseModal}
            className="border-0 btn-cancel"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    ) : (
      <p> Fetching....</p>
    );
  }
}

export default DataSourceModal;
