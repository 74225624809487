import React, {Component} from "react";
import {Button, ButtonGroup, Col, Container, ListGroup, ListGroupItem, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import "../assets/css/topview.css";
import DestinationAPI from "../../../../services/destination";
import {toast} from "react-toastify";
import SweetAlert from "react-bootstrap-sweetalert";
import {get} from 'lodash';
import equal from "fast-deep-equal";
import Loader from '../../../../components/Loader/loader';

class DestinationView extends Component {
  interval = 0;

  constructor(props) {
    super(props);

    this.state = {
      cardSteps: [],
      cardContent: [],
      showConfirmationModel: false,
      isDisabled: false,
      isAutoSyncDisabled: false,
      isSyncActive: false,
      availableIntegration: [],
      integrationsSyncStatus: [],
      currentIntegrationSyncId: [],
      syncPercent: 0,
      currentDestination: null,
      isSyncError: false,
      isFinishedLoading: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!equal(this.props.cardData, prevProps.cardData)) {
      this.updateData(this.props.cardData.cardContent.id);
    }
  }

  componentDidMount() {
    let cardView = this.props.location.state;
    if (this.props.cardData.cardContent) {
      this.updateData(this.props.cardData.cardContent.id);
    } else if (cardView.prevPath === "/destinations/overview" || cardView.prevPath === "/destinations/view") {
      this.updateData(cardView.cardContent.id);
    }
  }

  updateData(syncDestinationId) {
    let integrationsSyncStatus = [];

    this.getIntegration().then(() => {
      this.getDestination(syncDestinationId).then(destination => {
        if (destination) {

          integrationsSyncStatus = destination.destinationIntegrations.map(item => {
            const integrationCurrentSyncStatus = get(item, 'currentSyncStatus', null);
            return {
              id: item.connectionId,
              status: integrationCurrentSyncStatus !== null ? get(integrationCurrentSyncStatus, 'destinationIntegrationSyncStatus', null)
                : get(item, 'lastSyncStatus.destinationIntegrationSyncStatus', null)
            };
          });


          this.setState({integrationsSyncStatus: integrationsSyncStatus}, () => {

            this.updateSync(syncDestinationId).then(() => {
              integrationsSyncStatus.forEach(item => {
                if (item.status === "COMPLETED_LIST_LIMIT_ERROR") {
                  let name = this.state.availableIntegration.find(integration => integration.id === item.id).name;
                  toast.error(
                    `The ${name} integration has reached the list limit according to your account type
                      - please configure the external system to allow additional lists to be added `
                  );
                }
              });
            });
          });
        }
      });
    });

    let cardView = this.props.location.state;
    if (cardView.prevPath !== "/destinations/overview" && cardView.prevPath !== "/destinations/view") {

      let cardContent;
      if (this.props.cardContent) {
        cardContent = this.props.cardContent;
      } else {
        cardContent = this.props.cardData.cardContent;
      }

      this.setState(
          {
            cardContent: cardContent,
            cardSteps: this.props.cardData.selectedCard.steps,
            selectedCard: this.props.cardData.selectedCard,
          },
          () => this.props.onContextIdChange(this.state.cardContent.id));
    } else {
      this.setState(
          {
            cardContent: cardView.cardContent,
            cardSteps: cardView.selectedCard.steps,
            selectedCard: cardView.selectedCard,
          },
      () => this.props.onContextIdChange(this.state.cardContent.id));
    }

    this.props.onContextChange("DESTINATION");
  }


  componentWillUnmount() {
    this.props.onContextChange(null);
    this.props.onContextIdChange(null);
    this.getIntegration().then();
    clearInterval(this.interval);
  }

  deleteDestination() {
    this.props.onDeleteDestination(this.state.cardContent.id)
  }

  getIntegration = async event => {
    try {
      await DestinationAPI.getIntegration().then(response => {
        let availableIntegration = response;
        this.setState({availableIntegration});
      });
    } catch (err) {
    }
  };

  updateCampaign = (updateCampaignTo, updateCampaignSyncTo) => {
    let destinationData = this.state.cardContent;
    let currentDestinationType = this.state.selectedCard.enum;

    //Segment Section Start
    let segmentData = {
      title: destinationData.segment.title,
      segmentId: destinationData.segment.segmentID,
      description: destinationData.segment.description
    };
    //Segment Section Start

    //Recomendation Section Start
    let filters = [];
    destinationData.recomendation.attributeList.map(attribute => {
      let att = {
        productAttributeDataTag: attribute.field,
        matchCondition: attribute.segmentCondition,
        dataSourceAttributeType: attribute.type,
        value: attribute.value
      };
      filters.push(att);
    });


    let recommendationData = {
      numberRecommendations: destinationData.recomendation.recommendationCount,
      enrichmentType: destinationData.recomendation.fieldValue,
      filters: filters
    };
    //Recomendation Section End

    //UTM Section Start
    let utmData = "";
    if (destinationData.utmInfo ?? false) {
      let utmOptions = destinationData.utmInfo.utmOptions;
      utmData = {
        utmSourceEnabled: utmOptions[0].selected,
        utmSourceText: utmOptions[0].selected ? utmOptions[0].value : null,
        utmMediumEnabled: utmOptions[1].selected,
        utmMediumText: utmOptions[1].selected ? utmOptions[1].value : null,
        utmCampaignEnabled: utmOptions[2].selected,
        utmCampaignText: utmOptions[2].selected ? utmOptions[2].value : null,
        allowTrackingParameter: destinationData.utmInfo.utmAllowDistil
      };
    } else {
      utmData = {
        utmSourceEnabled: false,
        utmSourceText: null,
        utmMediumEnabled: false,
        utmMediumText: null,
        utmCampaignEnabled: false,
        utmCampaignText: null,
        allowTrackingParameter: false
      };
    }
    //UTM Section End

    let destinationIntegrations = (destinationData?.destination || [])
      .map(integration => {
        return {
          id: integration.id,
          connectionId: integration.destinationId,
          integrationSettings: integration.integrationSettings,
          attributes: integration.attributes,
          listId: integration.listId
        }
      });

    let updateCampaign = {
      type: currentDestinationType,
      enabled: updateCampaignTo ?? this.state.cardContent.enabled ?? false,
      autoSync: updateCampaignTo ?? updateCampaignSyncTo ?? false,
      id: destinationData.id,
      ...segmentData,
      ...recommendationData,
      ...utmData,
      destinationIntegrations
    };

    if (updateCampaignSyncTo === undefined) {
      this.disableCampaign(updateCampaign, true);
    } else {
      this.disableAutoSync(updateCampaign, true);
    }
  };

  disableCampaign = async (data, isUpdate) => {
    this.setState({isDisabled: true});
    this.setState({isAutoSyncDisabled: true});
    try {
      await DestinationAPI.createOrUpdateCampaign(data, isUpdate).then(
        response => {
          toast.success(
            `Campaign ${data.enabled ? "enabled" : "disabled"} successfully`
          );
          let cardContent = this.state.cardContent;
          cardContent.enabled = data.enabled;
          cardContent.autoSync = data.autoSync;
          this.setState({cardContent, isDisabled: false});
          this.setState({cardContent, isAutoSyncDisabled: false});
        }
      );
    } catch (err) {
      this.setState({isDisabled: false});
      this.setState({isAutoSyncDisabled: false});
      toast.error("Campaign update fail");
    }
  };

  disableAutoSync = async (data, isUpdate) => {
    this.setState({isAutoSyncDisabled: true});
    try {
      await DestinationAPI.createOrUpdateCampaign(data, isUpdate).then(
        response => {
          toast.success(
            `Auto Sync ${data.autoSync ? "enabled" : "disabled"} successfully`
          );
          let cardContent = this.state.cardContent;
          cardContent.autoSync = data.autoSync;
          this.setState({cardContent, isAutoSyncDisabled: false});
        }
      );
    } catch (err) {
      this.setState({isAutoSyncDisabled: false});
      toast.error("Campaign update fail");
    }
  };


  startSync = async (id) => {
    this.setState({isSyncActive: true, isSyncError: false});
    let isSyncActive = this.state.isSyncActive;

    try {
      await DestinationAPI.startSyncNow(id).then(response => {
        if (response === 200) {
          isSyncActive = true;
          this.updateSync(id);
        }
        this.setState({isSyncActive: isSyncActive});
      })
    } catch (err) {
      if (err.response.status === 409) {
        isSyncActive = true;
        this.updateSync(id);
      } else {
        isSyncActive = false;
      }
    }
  };

  showIntegrationSyncStatus(syncStatus) {
    switch (syncStatus) {
      case "SYNC_IN_PROGRESS_READING":
        return "reading...";

      case "SYNC_IN_PROGRESS_WRITING":
        return "writing...";

      case "COMPLETED_SUCCESSFULLY":
        return "success";

      case "COMPLETED_WITH_ERRORS":
        return "synchronization complete - but some customer records rejected by integration.";

      case "COMPLETED_LIST_LIMIT_ERROR":
        return "didn't manage to create a list, please check your subscription status";

      case "REQUIRED_AUTHENTICATION":
        return "The Facebook authentication is no longer valid for the connection - please log into Distil and edit the Facebook connection to re-authenticate.";

      case "FB_PERMISSIONS_ERROR":
        return "Sync failed - permissions Facebook API error.";

      case "FB_UPDATE_AUDIENCE_ERROR":
        return "Sync failed - Facebook is still processing the previous data load.";

      case "FB_API_ERROR":
        return "Sync failed - unknown Facebook API error.";

      default:
        return "";
    }
  }

  changeStatusIndicator(status) {
    switch (status) {
      case "COMPLETED_SUCCESSFULLY":
        return 'icon-circle-green';

      case "COMPLETED_WITH_ERRORS":
      case "COMPLETED_LIST_LIMIT_ERROR":
      case "REQUIRED_AUTHENTICATION":
      case "FB_PERMISSIONS_ERROR":
      case "FB_UPDATE_AUDIENCE_ERROR":
      case "FB_API_ERROR":
        return 'icon-circle-red';

      case "SYNC_IN_PROGRESS_WRITING":
      case "SYNC_IN_PROGRESS_READING":
        return 'icon-circle-active';

      default:
        return '';
    }
  }

  getDestination = async (id) => {
    let currentDestination;
    try {
      return await DestinationAPI.getDestination().then(response => {
        currentDestination = response.filter(destin => destin.id === id);
        this.setState({currentDestination: currentDestination[0]});
        return currentDestination[0];
      })
    } catch (err) {
    }
  };

  updateSync = async (id) => {
    let process;
    let integrationsSyncStatus = this.state.integrationsSyncStatus;
    let currentIntegrationSyncId = this.state.currentIntegrationSyncId;

    // initialising integration ids
    await this.getDestination(id).then(destination => {
      destination.destinationIntegrations.forEach((integration, index) => {
        let integrationId = get(integration, 'currentSyncStatus.syncId', null);
        if (integrationId != null) {
          currentIntegrationSyncId[index] = integrationId;
        }
      });
      if (currentIntegrationSyncId.length > 0) {
        this.setState({isSyncActive: true});
      }
    });

    clearInterval(this.interval);
    this.interval = setInterval(() => {
      process = 0;

      this.getDestination(id).then(destination => {
        if (destination) {
          let integrationsLength = destination.destinationIntegrations.length;

          if (destination.destinationIntegrations.every((element, index) =>
            this.checkIntegrationSyncCompleted(element.currentSyncStatus, element.lastSyncStatus, index))) {
            integrationsSyncStatus = integrationsSyncStatus.map(element => {
              return {
                id: element.id,
                status: get(element, 'lastSyncStatus.destinationIntegrationSyncStatus', null)
              }
            });
            process = 1;
            clearInterval(this.interval);
            // why we need this delay?
            setTimeout(() => {
              this.setState({isSyncActive: false});
              this.state.syncPercent = 0;
            }, 1000);
          } else {
            let percentsPerIntegration = 0;
            destination.destinationIntegrations.forEach((integration, index) => {
              const currentSyncStatus = get(integration, 'currentSyncStatus', null);
              const lastSyncStatus = get(integration, 'lastSyncStatus', null);
              const destinationIntegrationCurrentSyncStatus = get(currentSyncStatus, 'destinationIntegrationSyncStatus', null);
              integrationsSyncStatus[index].status = destinationIntegrationCurrentSyncStatus;

              if (this.checkIntegrationSyncCompleted(currentSyncStatus, lastSyncStatus, index)) {
                if (get(lastSyncStatus, 'destinationIntegrationSyncStatus', null) === 'COMPLETED_WITH_ERRORS') {
                  this.setState({isSyncError: true});
                  clearInterval(this.interval);
                  // and this delay?
                  setTimeout(() => {
                    this.setState({isSyncActive: false});
                  }, 1500);
                }
                percentsPerIntegration = 1;
              } else {
                const processed = get(integration, 'currentSyncStatus.processed', 0);
                const currentRowsCount = get(integration, 'currentSyncStatus.currentRowsCount', 100);
                const beforeRowsCount = get(integration, 'currentSyncStatus.beforeRowsCount', 0);
                const allExternalRetrievedRowsCount = get(integration, 'currentSyncStatus.allExternalRetrievedRowsCount', 100);

                percentsPerIntegration = this.updateSyncPercents(processed, currentRowsCount, beforeRowsCount, allExternalRetrievedRowsCount, destinationIntegrationCurrentSyncStatus);
              }
              process += percentsPerIntegration / integrationsLength;
            });
          }
          this.setState({syncPercent: Math.floor(process * 100), integrationsSyncStatus});
        }
      });
    }, 10000);
  };

  checkIntegrationSyncCompleted(currentSyncStatus, lastSyncStatus, index) {
    return currentSyncStatus == null && this.state.currentIntegrationSyncId[index] === get(lastSyncStatus, 'syncId');
  }

  updateSyncPercents(processed, currentRowsCount, beforeRowsCount, allExternalRetrievedRowsCount, currentSyncStatus) {
    if (allExternalRetrievedRowsCount === 0) {
      allExternalRetrievedRowsCount = beforeRowsCount;
    }
    return (beforeRowsCount > 0 ? beforeRowsCount / allExternalRetrievedRowsCount / 2 : currentSyncStatus === 'SYNC_IN_PROGRESS_WRITING' ? 0.5 : 0)
      + (currentRowsCount > 0 ? processed / currentRowsCount / 2 : 0);
  }

  renderSection(section, key, user) {
    const {cardContent, integrationsSyncStatus} = this.state;
    const recommendations = {
      PRODUCT_RECOMMENDATIONS_MIX: "Recommendation mix",
      PRODUCT_RECOMMENDATIONS_VIEWED_NOT_PURCHASED: "Products viewed not purchased",
      PRODUCT_RECOMMENDATIONS_VIEWED_TOGETHER: "Products viewed together",
      PRODUCT_RECOMMENDATIONS_PURCHASED_TOGETHER: "Products purchased together",
      PRODUCT_RECOMMENDATIONS_FOLLOW_ON_PURCHASE: "Follow on purchases",
      PRODUCT_RECOMMENDATIONS_TRENDING: "Trending products",
      PRODUCT_RECOMMENDATIONS_DEFAULT_PRODUCTS: "Default products"
    };

    switch (section) {
      case "SELECT_SEGMENT":
        return (
          <Row noGutters={true} key={key}>
            <Col md={3}>
              <div className="left-wrap">
                <h6>Overview</h6>
              </div>
            </Col>
            <Col md={9}>
              <div className="right-wrap">
                <div className="content">
                  <ListGroup className="list-group-flush detail-list">
                    <ListGroupItem>
                      <h6>Title</h6>
                      <p>{cardContent.segment.title}</p>
                    </ListGroupItem>

                    <ListGroupItem>
                      <h6>Description</h6>
                      <p>{cardContent.segment.description}</p>
                    </ListGroupItem>

                    <ListGroupItem>
                      <h6>Segment</h6>
                      <p>{cardContent.segment.segmentName}</p>
                    </ListGroupItem>
                  </ListGroup>

                  {user?.membershipType !== 'READER' && (
                    <Link
                      className="btn btn-secondary edit-campaign"
                      to={{
                        pathname: "/destinations/overview",
                        state: {
                          selectedCard: this.state.selectedCard,
                          selectedContent: this.state.cardContent,
                          currentSection: section
                        }
                      }}
                    >
                      Click to Edit
                    </Link>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        );

      case "RECOMMENDATIONS":
        return (
          <Row noGutters={true} key={key}>
            <Col md={3}>
              <div className="left-wrap">
                <h6>Recommendations</h6>
              </div>
            </Col>
            <Col md={9}>
              <div className="right-wrap">
                <div className="content">
                  <ListGroup className="list-group-flush detail-list">
                    <ListGroupItem>
                      <h6>Product Recommendations</h6>
                      <p>
                        {recommendations[cardContent.recomendation.fieldValue]}
                      </p>
                    </ListGroupItem>

                    <ListGroupItem>
                      <h6>Number of Recommendations</h6>
                      <p>{cardContent.recomendation.recommendationCount}</p>
                    </ListGroupItem>

                    <ListGroupItem>
                      <h6>Filter Criteria</h6>
                      <p>
                        <span className="not-specify">
                          {cardContent.recomendation.customAttribute
                            ? cardContent.recomendation.attributeList.map(
                              att => (
                                <p>
                                  {att.field}{" "}
                                  <strong>{att.segmentCondition} </strong>
                                  <span>{att.value}</span>
                                </p>
                              )
                            )
                            : "None Specified"}
                        </span>
                      </p>
                    </ListGroupItem>
                  </ListGroup>

                  {user?.membershipType !== 'READER' && (
                    <Link
                      className="btn btn-secondary edit-campaign"
                      to={{
                        pathname: "/destinations/overview",
                        state: {
                          selectedCard: this.state.selectedCard,
                          selectedContent: this.state.cardContent,
                          currentSection: section
                        }
                      }}
                    >
                      Click to Edit
                    </Link>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        );
      case "UTM_INFO":
        return (
          <Row noGutters={true} key={key}>
            <Col md={3}>
              <div className="left-wrap">
                <h6>UTM Info</h6>
              </div>
            </Col>
            <Col md={9}>
              <div className="right-wrap">
                <div className="content">
                  <ListGroup className="list-group-flush detail-list">
                    <ListGroupItem>
                      <h6>UTM Codes</h6>
                      <ListGroup className="list-unstyled">
                        {cardContent.utmInfo !== undefined
                          ? cardContent.utmInfo.utmOptions.filter(
                            val => val.selected === true
                          ).length > 0
                            ? cardContent.utmInfo.utmOptions.map(
                              utm =>
                                utm.selected && (
                                  <ListGroupItem>{utm.title}</ListGroupItem>
                                )
                            )
                            : "None Set"
                          : "None Set"}
                      </ListGroup>
                    </ListGroupItem>

                    <ListGroupItem>
                      <h6>
                        Allow Distil to append tracking parameter to Product and
                        Content URLs
                      </h6>
                      <p>
                        {cardContent.utmInfo?.utmAllowDistil ? "True" : "False"}
                      </p>
                    </ListGroupItem>
                  </ListGroup>

                  {user?.membershipType !== 'READER' && (
                    <Link
                      className="btn btn-secondary edit-campaign"
                      to={{
                        pathname: "/destinations/overview",
                        state: {
                          selectedCard: this.state.selectedCard,
                          selectedContent: this.state.cardContent,
                          currentSection: section
                        }
                      }}
                    >
                      Click to Edit
                    </Link>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        );

      case "DESTINATIONS":
        return (
          <Row noGutters={true} key={key}>
            <Col md={3}>
              <div className="left-wrap">
                <h6>Destination Push</h6>
              </div>
            </Col>
            <Col md={9}>
              <div className="right-wrap">
                <div className="content">
                  <ListGroup className="list-group-flush detail-list">
                    <ListGroupItem>
                      <h6>Segment Destination</h6>

                      {cardContent.destination !== undefined
                        ? cardContent.destination.length > 0
                          ? cardContent.destination.map((integration, key) => (
                            <ListGroup className="list-unstyled" key={key}>
                              <ListGroupItem>
                                {integration.destinationName}
                              </ListGroupItem>
                              {/* <ListGroupItem>High Spenders</ListGroupItem> */}
                              <ListGroupItem>
                                {integration.attributes.length} Attributes to
                                Sync
                              </ListGroupItem>
                              {integrationsSyncStatus[key] && (
                                <div className="sync-integration-status">
                                  <span className={`icon-circle 
                                    ${this.changeStatusIndicator(integrationsSyncStatus[key].status)}`}
                                  />
                                  <span className={`sync-integration-status-text 
                                  ${this.changeStatusIndicator(integrationsSyncStatus[key].status) === 'icon-circle-red' ? 'error-text' : ''}`}
                                  >
                                    {this.showIntegrationSyncStatus(integrationsSyncStatus[key].status)}
                                  </span>
                                </div>)
                              }
                            </ListGroup>
                          ))
                          : "Not set"
                        : "Not set"}
                    </ListGroupItem>
                  </ListGroup>

                  {user?.membershipType !== 'READER' && (
                    <Link
                      className="btn btn-secondary edit-campaign"
                      to={{
                        pathname: "/destinations/overview",
                        state: {
                          selectedCard: this.state.selectedCard,
                          selectedContent: this.state.cardContent,
                          currentSection: section
                        }
                      }}
                    >
                      Click to Edit
                    </Link>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        );
    }
  }

  render() {
    const {cardSteps, cardContent, isDisabled, isAutoSyncDisabled, isSyncActive, syncPercent, isSyncError, isFinishedLoading} = this.state;
    let currentUser = JSON.parse(sessionStorage.getItem("selectedMembers"));

    return (
    <React.Fragment>
      {!this.props.segmentsIsLoaded ?
          <Loader/> :
          <React.Fragment>
            <div className="destination-wrap-view">
              <div className="view-title">
                <Link to="/destinations" className="title" onClick={this.props.handleReturnBack}>
                  <span className="icon-chevron-thin-left"></span>
                  {cardContent.segment !== undefined && (
                    <h6>{cardContent.segment.title}</h6>
                  )}
                </Link>

                {currentUser.membershipType !== 'READER' && (
                  <ButtonGroup>

                    {cardContent.destination !== undefined
                      ? cardContent.destination.length > 0
                        ?
                        (<div className="sync-buttons-container">
                          {isSyncActive && (
                            isSyncError ? (
                              <span className="col-md-auto sync-log-text-error">
                                Sync Failed
                              </span>
                            ) : (
                              <span className="col-md-auto sync-log-text">
                                {'Processing: ' + Math.min(100, Math.round(syncPercent)) + ' %'}
                              </span>)
                          )}
                          <Button
                            variant="secondary"
                            onClick={() => this.startSync(cardContent.id)}
                          >
                            <React.Fragment>
                              <div className={`${!isSyncActive ? 'sync-text-hover' : ''}`}>
                                <span className="icon-arrow-thin-up"/>
                                Sync Now
                              </div>
                            </React.Fragment>
                          </Button>
                        </div>)
                        : null
                      : null
                    }
                    {(cardContent.enabled || cardContent.enabled === undefined) && (cardContent.destination !== undefined && cardContent.destination.length > 0) ?
                      (<Button
                        variant="secondary"
                        style={{marginLeft: "16px"}}
                        disabled={isAutoSyncDisabled}
                        onClick={() => this.updateCampaign(undefined, !cardContent.autoSync)}
                      >
                        {cardContent.autoSync ? (
                          <React.Fragment>
                            <span className="icon-toggle-on active"/> Auto-sync on
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <span className="icon-toggle-off"/> Auto-sync off
                          </React.Fragment>
                        )}
                      </Button>) : null}
                    <Button
                      variant="secondary"
                      disabled={isDisabled}
                      onClick={() => this.updateCampaign(!cardContent.enabled, undefined)}
                    >
                      {cardContent.enabled || cardContent.enabled === undefined ? (
                        <React.Fragment>
                          <span className="icon-toggle-on active"/>
                          Enabled Campaign
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <span className="icon-toggle-off"/> Disabled Campaign
                        </React.Fragment>
                      )}
                      {/* <span className="icon-disable"></span>{" "}  */}
                      {/* {cardContent.enabled || cardContent.enabled===undefined  ? "Disable" : "Enable"} Campaign */}
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={() => this.setState({showConfirmationModel: true})}
                    >
                      <span className="icon-delete-light"></span>Delete
                    </Button>
                  </ButtonGroup>
                )}
              </div>
              <div className="body-wrap">
                <Container>
                  {cardSteps.map((step, key) => this.renderSection(step, key, currentUser))}
                  <Row noGutters={true}>
                    <Col md={3}>
                      <div className="left-wrap">
                        <h6>API Access</h6>
                      </div>
                    </Col>
                    <Col md={9}>
                      <div className="right-wrap">
                        <div className="content">
                          <ListGroup className="list-group-flush detail-list">
                            <ListGroupItem>
                              <h6>
                                Use the API to deploy these recommendations directly
                                in your platform and via programatically instigated
                                Customer Experiences via use of the API. For example
                                in “Thank you for your purchase” emails.
                              </h6>
                            </ListGroupItem>

                            <ListGroupItem>
                              <p>
                                <strong className="redirect">
                                  GET {cardContent.apiAccessUrl}
                                </strong>
                              </p>
                            </ListGroupItem>
                          </ListGroup>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
            {this.state.showConfirmationModel && (
              <SweetAlert
                custom
                showCancel
                confirmBtnText="Delete"
                cancelBtnText="Cancel"
                confirmBtnBsStyle="primary"
                cancelBtnBsStyle="default"
                allowEscape={false}
                closeOnClickOutside={false}
                title="Delete Campaign"
                onConfirm={() => this.deleteDestination()}
                onCancel={() => this.setState({showConfirmationModel: false})}
              >
                Are you sure you want to delete this campaign?
              </SweetAlert>
            )}
      </React.Fragment>
    }
    </React.Fragment>);
  }
}

export default DestinationView;
