import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Destination from "./Destination/destination";
import DestinationView from "./Destination/destinationView";
import DestinationHome from "./Destination/destinationHome";
import CustomersAPI from "../../../services/customers";
import DestinationAPI from "../../../services/destination";
import STANDARD_SYNC from "./assets/img/audience.svg";
import HYPER_PERSONALISED from "./assets/img/personalised.svg";
import {toast} from "react-toastify";
// import HelpModal from "../../../components/HelpModal/HelpModalAdmin";

class Destinations extends Component {
  constructor(props) {
    super(props);

    this.refreshData = this.refreshData.bind(this);
  }

  state = {
    cardContent: null,
    selectedCard: null,
    isSyncStarted: false,
    availableSegments: [],
    destinations: [],
    isUpdatedBeforeUpdatedDestination: false,
    loadingDestination: false,
    segmentsIsLoaded: false,
    availableIntegration: [],
    destinationData: [
      {
        enum: "STANDARD_SYNC",
        name: "Create Multi-Channel Audience",
        logo: STANDARD_SYNC,
        steps: ["SELECT_SEGMENT", "DESTINATIONS", "REVIEW_ACTIVATE"],
        description:
            "Choose a Segment to sync across multiple marketing platforms to create one unified Audience across multiple channels.",
        cardContent: []
      },
      {
        enum: "HYPER_PERSONALISED",
        name: "Create Personalised Recommendations",
        logo: HYPER_PERSONALISED,
        steps: [
          "SELECT_SEGMENT",
          "RECOMMENDATIONS",
          "UTM_INFO",
          "DESTINATIONS",
          "REVIEW_ACTIVATE"
        ],
        description:
            "Use Product Recommendations to create personalised experiences over email and on-platform.",
        cardContent: []
      }
    ],
  };

  componentDidMount() {
    this.props.onContextChange("DESTINATION");
    this.refreshData();
  }

  refreshData() {
    this.getIntegration().then(() => this.getSegments());
    this.getDestination().then(() => {
      if (this.state.isUpdatedBeforeUpdatedDestination) {
        this.getDestinationData(this.state.destinations);
      }
    });
    this.setState({loadingDestination: false});
  }

  componentWillUnmount() {
    this.props.onContextChange(null);
    this.props.onContextIdChange(null);
  }

  getSegments = async event => {
    try {
      await CustomersAPI.getSegmentsWithNoStats().then(response => {
        this.setState({availableSegments: response}, () => {
          if (this.state.loadingDestination) {
            this.getDestinationData(this.state.destinations);
          } else {
            this.setState({isUpdatedBeforeUpdatedDestination: true});
          }
          this.setState({segmentsIsLoaded: true});
        });
      });
    } catch (err) {
      toast.error("Failed to Load Destination Segments");
    }
  };

  getDestination = async event => {
    try {
      await DestinationAPI.getDestination().then(response => {
        this.setState({destinations: response});
        this.getDestinationData(response);
        this.setState({loadingDestination: true});
      });
    } catch (err) {
      toast.error("Failed to Load Destinations");
    }
  };

  getIntegration = async event => {
    try {
      await DestinationAPI.getIntegration().then(response => {
        this.setState({availableIntegration: response});
      });
    } catch (err) {
    }
  };

  getDestinationData = (destinationDataFromResponse) => {
    let destinationData = this.state.destinationData;

    destinationData.map(destinations => {
      destinations.cardContent.splice(0, destinations.cardContent.length)
      let destinationTypes = destinationDataFromResponse.filter(
          item => item["type"] === destinations.enum
      );
      let formatedData = [];

      destinationTypes.map(destinationData => {
        let segmentData = this.state.availableSegments.filter(
            segment => segment.id === destinationData.segmentId
        )[0];

        let segment = {
          title: destinationData.title,
          description: destinationData.description,
          hasError: false,
          segmentID: segmentData?.id ?? null,
          segmentName: segmentData?.name ?? null,
          // createdDate: destinationData.createdDate,
          records: segmentData?.cachedCustomerCount ?? null,
          isAdded: true
        };

        let attributeList = [];

        (destinationData?.filters ?? []).map(attribute => {
          attributeList.push({
            id: attribute.id,
            attributeID: attribute.id,
            field: attribute.productAttributeDataTag,
            segmentCondition: attribute.matchCondition,
            value: attribute.value,
            type: attribute.dataSourceAttributeType,
            isSaved: true
          });
        });

        let recomendation = {
          fieldValue:
              destinationData.enrichmentType ?? null,
          attributeList: attributeList,
          customAttribute: destinationData.filters !== undefined && destinationData.filters.length > 0,
          recommendationCount: destinationData.numberRecommendations ?? null,
          hasError: false
        };

        let utmInfo = {
          utmOptions: [
            {
              id: 1,
              title: "UTM Source",
              value:
                  destinationData.utmSourceText ?? null,
              selected:
                  destinationData.utmSourceEnabled ?? false,
              validateSource: false,
              validateHasError: false,
              hasError: false
            },
            {
              id: 2,
              title: "UTM Medium",
              value:
                  destinationData.utmMediumText ?? "",
              selected:
                  destinationData.utmMediumEnabled ?? false,
              validateMedium: false,
              validateHasError: false,
              hasError: false
            },
            {
              id: 3,
              title: "UTM Campaign",
              value: destinationData.utmCampaignText ?? "",
              selected: destinationData.utmCampaignEnabled ?? false,
              validateCampaign: false,
              validateHasError: false,
              hasError: false
            }
          ],
          utmAllowDistil: destinationData.allowTrackingParameter ?? false,
          hasError: false
        };

        let destination = [];

        if (destinationData.destinationIntegrations !== null) {
          let destinationIntegrations = [];
          destinationData.destinationIntegrations.map(integration => {
            let filtereIntegrationData = this.state.availableIntegration.filter(
                item => item.id === integration.connectionId
            );

            if (filtereIntegrationData !== null && filtereIntegrationData.length !== 0) {
              let items = {
                id: integration.id,
                destinationId: integration.connectionId,
                numExternalFields:
                    filtereIntegrationData[0].integrationSettings != null
                        ? filtereIntegrationData[0].integrationSettings
                            .customFieldsLimit
                        : -1,
                destinationName: filtereIntegrationData[0].name,
                attributes: integration.attributes,
                integrationSettings: integration.integrationSettings
              };
              destinationIntegrations.push(items);
            }
          });
          destination = destinationIntegrations;
        }

        formatedData = {
          id: destinationData.id,
          apiAccessUrl: destinationData.apiAccessUrl,
          enabled: destinationData.enabled,
          autoSync: destinationData.autoSync,
          createdDate: destinationData.createdDate,
          segment,
          recomendation,
          utmInfo,
          destination
        };

        destinations.cardContent.push(formatedData);
      });
    });

    this.setState({ destinationData });
  }

  getCardData = (cardEnum, contentDataId) => {
    let result = [];
    this.state.destinationData.forEach((item) => {
      if (item.enum === cardEnum) {
        item.cardContent.forEach((cardContentItem) => {
          if (cardContentItem.id === contentDataId) {
            result = ({
              selectedCard: item,
              cardContent: cardContentItem
            })
          }
        })
      }
    })
    return result;
  }

  campaignCreated = (data, id, apiAccessUrl, currentCard) => {
    let cardEnum = this.props.location.state.selectedCard.enum;
    let cardContent = { ...data, id: id, apiAccessUrl: apiAccessUrl };
    this.getDestination().then(r => this.state.destinationData.forEach((item) => {
      if (item.enum === cardEnum) {
        for (let cardContentItem of item.cardContent) {
          if (cardContentItem.id === id) {
            currentCard = item;
            cardContent = cardContentItem;
          }
        }
      }

      this.setState({ cardContent, selectedCard: currentCard });
      this.props.history.push({
        pathname: "/destinations/view",
        state: { cardContent: cardContent, selectedCard: currentCard, isSyncStarted: true, prevPath: "/destinations/overview" }
      });
    }));


  };

  handleDestinationDelete = async id => {
    try {
      await DestinationAPI.deleteDestination(id).then(() => {
          this.refreshData();
          toast.success(
                  "Campaign deleted successfully",
                  this.props.history.push("/destinations"),
                  500
          );
        }
      );
    }
    catch(err) {
    }
  };

  handleReturnBack = () => {
    this.setState({cardContent: null, selectedCard: null});
  };

  render() {
    return (
      <React.Fragment>
      {/* <HelpModal /> */}
        <Switch>
          <Route
            path="/destinations/overview"
            name="DestinationOverview"
            render={props => (
              <Destination
                onVerifyChange={this.props.onVerifyChange}
                onContextChange={this.props.onContextChange}
                onContextIdChange={this.props.onContextIdChange}
                {...props}
                campaignCreated={this.campaignCreated}
                refreshData={this.refreshData}
              />
            )}
          />
          <Route
            path="/destinations/view"
            name="DestinationView"
            render={props => (
              <DestinationView
                onVerifyChange={this.props.onVerifyChange}
                cardContent={this.state.cardContent}
                onContextChange={this.props.onContextChange}
                onContextIdChange={this.props.onContextIdChange}
                selectedCard={this.state.selectedCard}
                segmentsIsLoaded={this.state.segmentsIsLoaded || this.props.location.state.segmentsIsLoaded}
                onDeleteDestination={this.handleDestinationDelete}
                cardData={
                  this.getCardData(this.props.location.state.cardEnum, this.props.location.state.cardContentID)
                }
                handleReturnBack={this.handleReturnBack}
                {...props}
              />
            )}
          />
          <Route
            path="/destinations"
            name="DestinationHome"
            render={() => (
                <DestinationHome
                    onVerifyChange={this.props.onVerifyChange}
                    destinationData={this.state.destinationData}
                    loadingDestination={this.state.loadingDestination}
                    refreshData={this.refreshData}
                />
            )}
          />

          <Redirect from="/destinations" to="/destinations" />
        </Switch>
      </React.Fragment>
    );
  }
}

export default Destinations;
