import React, { Component } from "react";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import ResetRequest from "./Pages/ResetRequest";

class Forgot extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    isloading: true,
    token: null,
    currentSection: null
  };

  componentDidMount() {
    document.title = "Distil - Reset your password";
  }

  render() {
    return <ResetRequest />;
  }
}

export default Forgot;
