import React, {Component} from "react";
import AccountAPI from "../../../../services/account";
import StripeAPI from "../../../../services/stripe";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import {Form} from "react-bootstrap";


import "./assets/styles.css";
import {Link} from "react-router-dom";
import Loader from "../../../../components/Loader/loader";
import {Select} from "react-inputs-validation";

class BillingPlan extends Component {
  resizeObserver = null;

  constructor(props) {
    super(props);
    let selectedMembers = JSON.parse(sessionStorage.getItem("selectedMembers"));
    localStorage.setItem("gbp", '£')
    localStorage.setItem("usd", '$')
    localStorage.setItem("eur", '€')

    this.state = {
      selectedMembers: selectedMembers || {},
      currentBillingPlan: '',
      allStripeProducts: [],
      stripeProductsWithSelectedCurrency: [],
      stripeInfoLoaded: false,
      thisCurrency: 'gbp',
      height: 0,
    };

    this.getContextUserInfo(selectedMembers, false)
      .then(response => {
        if (response !== null) {
          sessionStorage.setItem("selectedMembers", JSON.stringify(response))
        }
      })
      .then(_ => this.getCurrentPlanInfo())


    this.initializeStripeProductData()
      .then(_ => {
        this.setState({stripeInfoLoaded: true}, () => console.log("Stripe data initialized"));
        if (document.readyState === "complete") {
          this.updateWindowDimensions();
          const billingContent = document.querySelector('.billing-content');
          if (billingContent) {
            this.resizeObserver = new ResizeObserver(this.updateWindowDimensions);
            this.resizeObserver.observe(document.querySelector('.billing-content'));
          }
        } else {
          window.addEventListener("load", this.updateWindowDimensions);
        }
      });
    this.getUrlBillingPlan = this.getUrlBillingPlan.bind(this);
  }

  componentWillUnmount() {
    if(this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
    window.removeEventListener("load", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    let billingContent = document.querySelector('.billing-content');
    if (billingContent) {
      this.setState({ height: billingContent.clientHeight });
    }
  }

  manageBillingPlan() {
    AccountAPI.manageBillingPlan(this.state.selectedMembers.id).then(response => {
        window.location.href = response;
      }
    ).then(_ => sessionStorage.setItem("isRedirectFromStripe", "true"));
  }

  getUrlBillingPlan(priceId, billingPlanName) {
    let selectedMembers = JSON.parse(sessionStorage.getItem("selectedMembers"));

    if (selectedMembers.accountType !== "ACTIVATED_PAYING" && billingPlanName.toLowerCase() === 'free') {
      AccountAPI.activateFreePlan(this.state.selectedMembers.id).then(_ => {
        this.getContextUserInfo(selectedMembers, true).then(response => {
          if (response !== null) {
            sessionStorage.setItem("selectedMembers", JSON.stringify(response))
            window.location.reload();
          }
        })
      });
    } else {
      AccountAPI.getPaymentProductLink(this.state.selectedMembers.id, priceId).then(response => {
            window.location.href = response;
          }
      ).then(_ => sessionStorage.setItem("isRedirectFromStripe", "true"));
    }
  }

  getContextUserInfo = async (selectedMembers, updateWithoutStripe) => {
    if (sessionStorage.getItem("isRedirectFromStripe") === "true" || updateWithoutStripe) {
      let members = selectedMembers;
      await AccountAPI.getOrgInfo(selectedMembers.id).then(response => {
        members = response.orgMembership;
        sessionStorage.removeItem("isRedirectFromStripe");
        window.dispatchEvent(new CustomEvent("storage", {detail: { name: 'updateOrgMemberShip'}}));
      });
      return members;
    }
    return selectedMembers;
  };

  getCurrentPlanInfo() {
    let fkStripeProductId = JSON.parse(sessionStorage.getItem("selectedMembers")).fkStripeProductId;
    if (fkStripeProductId == null) {
      this.setState({
        currentBillingPlan: fkStripeProductId
      })
      return;
    }
    AccountAPI.getCurrentPlanInfo(fkStripeProductId).then(response => {
      this.setState({
        currentBillingPlan: response
      })
    })
  }

  isCurrentPlan(billingPlan) {
    if (billingPlan != null && this.state.currentBillingPlan != null
            && this.state.currentBillingPlan.productName != null) {
      return billingPlan.name === this.state.currentBillingPlan.productName;
    } else {
      return false;
    }

  }

  isEnterprise(billingPlan) {
    if (billingPlan != null || this.state.currentBillingPlan != null) {
      return billingPlan.productName === 'Enterprise';
    } else {
      return false;
    }
  }

  initializeStripeProductData() {
    return StripeAPI.getAllProducts().then(this.handleResponse).then(response => this.changeCurrency(response));
  }

  handleResponse = async (response) => {
    const products = response.data || [];

    for (const product of products) {
      product.prices = await StripeAPI.getAllPricesForProduct(product.id).then(priceResponse => priceResponse.data)
    }
    await this.setState({allStripeProducts: products})
    return products;
  }

  changeCurrency(products) {
    const currency = this.getCurrentCurrency();
    products.forEach(product => {
      product.prices.forEach(price => {
        if (price.currency === currency) {
          product.convertedAmount = price.unit_amount === 0 ? localStorage.getItem(currency) + 0 : localStorage.getItem(currency) + price.unit_amount / 100;
          product.selectedCurrencyPrice = price
          product.isCurrentPlan = this.isCurrentPlan(product)
        }
      })
    })

    this.setState({
      stripeProductsWithSelectedCurrency: products.sort((a, b) => (a.selectedCurrencyPrice.unit_amount > b.selectedCurrencyPrice.unit_amount) ? 1 : -1)
    })
  }

  getCurrentCurrency() {
    let currency = this.state.selectedMembers.stripeCurrency;
    if (currency !== null) {

      return currency;
    } else if (localStorage.getItem("currency") !== null && localStorage.getItem("currency") === 'gbp'
      || localStorage.getItem("currency") === 'usd' || localStorage.getItem("currency") === 'eur') {
      currency = localStorage.getItem("currency");
      localStorage.removeItem("currency");
      return currency;
    } else {
      currency = this.state.selectedMembers.currency == null ? 'gbp' : this.state.selectedMembers.currency.toLowerCase();
    }

    if (currency === 'gbp' || currency === 'usd' || currency === 'eur') {
      return currency;
    } else {
      return 'gbp'
    }
  }

  parceDescription(description) {
    const elements = !!description ? description.split(",") : []
    let result = []
    let items = ''
    elements.forEach((el, index) => {
      items = (
        <h6 key={index} className="billing-plan-permissions">{el}</h6>
      );

      result.push(items)
    })

    return result;

  }


  render() {
    const selectedMembers = JSON.parse(sessionStorage.getItem("selectedMembers"));
    const stripeCurrencyIsPresent = selectedMembers.stripeCurrency !== null;
    const accountType = selectedMembers.accountType;

    const {
      currentBillingPlan,
      stripeInfoLoaded,
      stripeProductsWithSelectedCurrency,
      allStripeProducts,
      height,
    } = this.state;
    let isEnterprise = this.isEnterprise(currentBillingPlan);

    if (isEnterprise) {
      return stripeInfoLoaded ? (
        <main className="my-wrapper">
          <div className="my-container">
            <section className="form-bg">
              <section>
                <div className="top-section border-btm">
                  <h4 className="font-weight-light">Current Billing Plan</h4>
                  <h1
                    className="font-weight-normal">Enterprise</h1>
                </div>
                <div className="font-weight-light d-trial">
                  <p>You are on our best plan and we love you for it ❤️</p>
                </div>
              </section>
            </section>
          </div>
        </main>
      ) : (
        <Loader/>
      );
    } else {
      return stripeInfoLoaded ? (
        <main className="my-wrapper">
          <div className="my-container">
            <section className="form-bg">
              {this.displayCurrentPlan(currentBillingPlan)}
              <div className="flex-block billing-content">
                <div className={`flex-block-with-selector all-plans ${(height < 480) ? 'right-line' : 'bottom-line'}`}>
                  <section className={stripeCurrencyIsPresent ? "invisible-select" : "select"}>

                  <Form.Group controlId="formBasicDropDown">
                    <Select
                      value={this.state.thisCurrency}
                      optionList={[
                        {id: "gbp", name: "GBP"},
                        {id: "usd", name: "USD"},
                        {id: "eur", name: "EUR"}
                      ]}
                      classNameSelect="select-control"
                      classNameWrapper="form-control-custom base-select-wrapper"
                      classNameOptionListContainer="select-control-option"
                      onChange={(currency, e) => {
                        localStorage.setItem("currency", currency.toString().toLowerCase())
                        this.setState({thisCurrency: currency.toString().toLowerCase()})
                        this.changeCurrency(allStripeProducts)
                      }}
                      validationOption={{}}
                    />
                  </Form.Group>
                  </section>
                  <div className="flex-block">
                    <div className="plan-item">
                      <div className="rectangle-product">
                        <div className="rectangle-header">
                          <h5 className="rectangle-header-text">{stripeProductsWithSelectedCurrency[0].name}</h5>
                        </div>
                        <div
                          className={stripeProductsWithSelectedCurrency[0].isCurrentPlan || accountType === 'ACTIVATED_FREE'
                              ? "current-plan-rectangle-body" : "rectangle-body"}>
                          <div>
                            <h5 className="billing-plan-price">
                              {stripeProductsWithSelectedCurrency[0].convertedAmount} / mo
                            </h5>
                            {this.parceDescription(stripeProductsWithSelectedCurrency[0].description)}
                          </div>
                          <button
                            type="submit"
                            onClick={() => this.getUrlBillingPlan(stripeProductsWithSelectedCurrency[0].selectedCurrencyPrice.id,
                                stripeProductsWithSelectedCurrency[0].name)}
                            className={stripeProductsWithSelectedCurrency[0].isCurrentPlan
                            || selectedMembers.accountType === 'ACTIVATED_FREE' ? "empty-button" : "button"}>
                            <h6
                              className="button-text">Choose Plan</h6>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="plan-item">
                      <div className="rectangle-product">
                        <div className="rectangle-header">
                          <h5 className="rectangle-header-text">{stripeProductsWithSelectedCurrency[1].name}</h5>
                        </div>
                        <div
                          className={stripeProductsWithSelectedCurrency[1].isCurrentPlan ? "current-plan-rectangle-body" : "rectangle-body"}>
                          <div>
                            <h5 className="billing-plan-price">
                              {stripeProductsWithSelectedCurrency[1].convertedAmount} / mo
                            </h5>
                            {this.parceDescription(stripeProductsWithSelectedCurrency[1].description)}
                          </div>
                          <button
                            type="submit"
                            onClick={() => this.getUrlBillingPlan(stripeProductsWithSelectedCurrency[1].selectedCurrencyPrice.id,
                                stripeProductsWithSelectedCurrency[1].name)}
                            className={stripeProductsWithSelectedCurrency[1].isCurrentPlan ? "empty-button" : "button"}>
                            <h6
                              className="button-text">Choose Plan</h6>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="plan-item">
                      <div className="rectangle-product">
                        <div className="rectangle-header">
                          <h5 className="rectangle-header-text">{stripeProductsWithSelectedCurrency[2].name}</h5>
                        </div>
                        <div
                          className={stripeProductsWithSelectedCurrency[2].isCurrentPlan ? "current-plan-rectangle-body" : "rectangle-body"}>
                          <div>
                            <h5 className="billing-plan-price">
                              {stripeProductsWithSelectedCurrency[2].convertedAmount} / mo
                            </h5>
                            {this.parceDescription(stripeProductsWithSelectedCurrency[2].description)}
                          </div>
                          <button
                            type="submit"
                            onClick={() => this.getUrlBillingPlan(stripeProductsWithSelectedCurrency[2].selectedCurrencyPrice.id,
                                stripeProductsWithSelectedCurrency[2].name)}
                            className={stripeProductsWithSelectedCurrency[2].isCurrentPlan ? "empty-button" : "button"}>
                            <h6 className="button-text">Choose Plan</h6>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="plan-item">
                      <div className="rectangle-product">
                        <div className="rectangle-header">
                          <h5 className="rectangle-header-text">{stripeProductsWithSelectedCurrency[3].name}</h5>
                        </div>
                        <div
                          className={stripeProductsWithSelectedCurrency[3].isCurrentPlan ? "current-plan-rectangle-body" : "rectangle-body"}>
                          <div>
                            <h5 className="billing-plan-price">
                              {stripeProductsWithSelectedCurrency[3].convertedAmount} / mo
                            </h5>
                            {this.parceDescription(stripeProductsWithSelectedCurrency[3].description)}
                          </div>
                          <button
                            type="submit"
                            onClick={() => this.getUrlBillingPlan(stripeProductsWithSelectedCurrency[3].selectedCurrencyPrice.id,
                                stripeProductsWithSelectedCurrency[3].name)}
                            className={stripeProductsWithSelectedCurrency[3].isCurrentPlan ? "empty-button" : "button"}>
                            <h6
                              className="button-text">Choose Plan</h6>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex-block-with-selector flex-block-center-mobile enterprise-element">
                  <div className="invisible-select">

                  </div>
                  <div className="flex-block">
                    <div className="plan-item">
                      <div className="rectangle-product">
                        <div className="rectangle-header">
                          <h5 className="rectangle-header-text">Enterprise</h5>
                        </div>
                        <div className="rectangle-body">
                          <div>
                            <h5 className="billing-plan-price">POA</h5>
                            <h6 className="billing-plan-permissions">Daily Insights</h6>
                            <h6 className="billing-plan-permissions">Benchmark Tracking</h6>
                            <h6 className="billing-plan-permissions">Funnel Analytics</h6>
                            <h6 className="billing-plan-permissions">Customer Analytics</h6>
                            <h6 className="billing-plan-permissions">Analytics Portal</h6>
                            <h6 className="billing-plan-permissions">Tailored Analytics</h6>
                            <h6 className="billing-plan-permissions">SQL Data Warehouse</h6>
                          </div>
                          <button
                            type="submit"
                            className="button" onClick={() => this.openInNewTab("https://distil.ai/get-distil/#demo")}>
                            <h6 className="button-text">Book a call.</h6>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      ) : (
        <Loader/>
      );
    }
  }

  openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  displayCurrentPlan(currentBillingPlan) {
    const daysLeftInContract = this.state.selectedMembers.daysLeftInContract;
    const selectedMembers = JSON.parse(sessionStorage.getItem("selectedMembers"));
    const checkNewSignUp = selectedMembers.accountType === 'NEW_SIGN_UP';

    if (currentBillingPlan == null || currentBillingPlan === '') {
      return (
        <section>
          <div className="top-section border-btm">
            <h4 className="font-weight-light title-billing-screen">Current Billing Plan</h4>
            <h2
              className="font-weight-normal">{checkNewSignUp || selectedMembers.accountType === 'ACTIVATED_TRIAL'
                ? 'Trial'
                : 'Free'}</h2>
          </div>
          <div className="font-weight-light d-trial">
            {checkNewSignUp || selectedMembers.accountType === 'ACTIVATED_FREE' || selectedMembers.accountType === 'ACTIVATED_PAYING'
                ? <p/>
                : <p>{daysLeftInContract} Days left</p>}
          </div>
        </section>
      );
    } else {
      return (
        <section>
          <div className="top-section border-btm">
            <h4 className="font-weight-light">Current Billing Plan</h4>
            <h4
              className="font-weight-normal">{currentBillingPlan.productName}</h4>
          </div>
          <div className="font-weight-light d-trial">
            <p><strong>Download Invoices</strong> and update your <strong>Payment Method</strong> in the
              {" "}
              <React.Fragment>
                <button
                  type="submit"
                  onClick={() => this.manageBillingPlan()}
                  className={"link-button"}>
                  Billing Portal
                </button>
              </React.Fragment>
            </p>
          </div>
        </section>
      );
    }
  }

}

export default BillingPlan;
