import "../assets/css/analytic-header.scss";
import React from "react";
import "../assets/css/dailyCard/wednesday.scss";
import "../assets/css/general-styles.scss";

import { Col, Row } from "react-bootstrap";
import iconHeaderWednesday from "../assets/images/wednesday-icon.svg";
import iconShopping from "../assets/images/Icon Shopping Basket.svg";
import iconBackground from "../assets/images/background-icon.svg";
import iconWelcome from "../assets/images/welcome-icon.svg";

const WednesdayComingSoon = ({ date }) => {

  return (
          <div className="wednesday card m-0 p-2 rounded shadow-sm analytic-card container-monday">
            <div className="card-header border-bottom-1 pl-3 position-relative padding-top4em no-paddingbtm-max-576">
              <div className="row card-header-row">
                <div className="col-1 d-flex card-col-icon align-items-center">
                  <div className="card-icon">
                    <img className="icon-background" src={iconBackground} alt="header..."></img>
                    <img className="icon-header" src={iconHeaderWednesday} alt="header..."></img>
                  </div>
                </div>
                <div className="col-11 card-title-col">
                  <div className=" fs-l fw-bold text-black">Marketing Wednesday</div>
                  <p className="fs-m text-muted mb-2 mb-md-2">{date}</p>
                </div>
              </div>
            </div>
            <div className="card-body--soon">
              <div className="content ">
                <div className="d-inline-flex">
                  <div className="content_title m-lg-2">
                    <h2>Your data is being analysed <br/> by the Distil AI</h2>
                  </div>
                  <img
                          className="content_img"
                          src={iconWelcome}
                          alt=""
                  ></img>
                </div>

                <h6 className="light-green-p">Your setup has been verified and everything is correct</h6>
                <p className="content_text">
                  <b>Distil AI: Business Analytics</b> is gathering your engagement data and building your marketing funnel insights.
                  Check back on Wednesday to see your funnel start to unveil.
                </p>
              </div>
            </div>

            <div className="card-footer fs-m">
              <Row>
                <Col lg={2}></Col>
                <Col ls={8} className="section-text-footer">
                  <div className="fs-l fw-bold">
                    Tomorrow is Winners & Losers Thursday{" "}
                  </div>
                  <div className="fs-xm text-gray">
                    It’s hump day so let’s find out which products are flying off the
                    shelves.
                  </div>
                  <div className="card-icon">
                    <img src={iconShopping} alt="footer..."></img>
                  </div>
                </Col>
                <Col lg={2}></Col>
              </Row>
            </div>
          </div>
  );
};

export default WednesdayComingSoon;