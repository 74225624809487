import React, { Component } from "react";
import moment from "moment";

import { ListGroup, ListGroupItem } from "react-bootstrap";

import BarChart from "../Cardgraphs/barChart";
import CardControlers from "./components/cardControlers";

import ChordChat from "../Cardgraphs/chordChart";

import _ from "lodash";
import TrendingCardWrap from "./components/trendingCardWrap";

class ProductRecommendations extends Component {
  state = {};

  cardType() {
    const { cardContent, onPinnedCard, onCardHistory, keyIndex } = this.props;
    const cardDetails = _.get(cardContent, 'cardDetails', {});

    var currentCard = {};

    switch (cardContent.newsfeedStackCardType.cardType) {
      case "PRODUCT_RECOMMENDATIONS_ENABLED":
        return (currentCard = {
          cardTitle: "The Product Recommendations Enrichment was enabled",
          cardDesc: "",
          type: "MANUAL"
        });
      case "PRODUCT_RECOMMENDATIONS_DISABLED":
        return (currentCard = {
          cardTitle: "The Product Recommendations Enrichment was disabled",
          cardDesc: "",
          type: "MANUAL"
        });
      case "PRODUCT_RECOMMENDATIONS_SETTINGS_CHANGE":
        return (currentCard = {
          cardTitle: "The Product Recommendations Enrichment was changed",
          cardDesc:
            "The Product Recommendations Enrichment has had the following changes:",
          type: "MANUAL",
          data: this.changesUi(cardDetails.changes)
        });

      case "PRODUCT_RECOMMENDATIONS_DAILY_SUMMARY_TOP_PRODUCTS":
        return (currentCard = {
          cardTitle: "Daily Summary: Top Products",
          cardDesc: "",
          type: "AUTOMATED",
          data: this.dailySummaryUi(cardDetails.topProducts)
        });

      case "PRODUCT_RECOMMENDATIONS_DAILY_SUMMARY_TRENDING":
        return (currentCard = {
          cardTitle:
            "Daily Summary: Trending Products",
          cardDesc: "",
          type: "AUTOMATED",
          data: this.trendingSummaryUi(cardContent.newsfeedStackId)
        });

      case "PRODUCT_RECOMMENDATIONS_DAILY_SUMMARY_VIEWED_TOGETHER":
        return (currentCard = {
          cardTitle:
            "Daily Summary: Products Viewed Together",
          cardDesc: "",
          type: "AUTOMATED",
          data: this.chordUi(
            cardDetails.productViewMatrices,
            "viewedWith",
            "productName",
            "viewCount"
          )
        });

      case "PRODUCT_RECOMMENDATIONS_DAILY_SUMMARY_PURCHASED_TOGETHER":
        return (currentCard = {
          cardTitle:
            "Daily Summary: Products Purchased Together",
          cardDesc: "",
          type: "AUTOMATED",
          data: this.chordUi(
            cardDetails.productPurchaseMatrices,
            "purchasedWith",
            "productName",
            "purchaseCount"
          )
        });

        default :
        return (currentCard = {
          cardTitle:
            "Undefined CArd",
          cardDesc: "Nothing",
          type: " ",
           
        });

    }
  }

  changesUi(changes) {
    return (
      <div className="card-graphics-section feed-recommendation-bg">
        <ListGroup>
          {changes.map((item, key) => (
            <ListGroup.Item key={key}>
              <strong>{item}</strong>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </div>
    );
  }
  dailySummaryUi(content) {
    return (
      <div className="card-graphics-section card-chart">
        {content !== null && <BarChart data={content} grid={false} />}
      </div>
    );
  }
  trendingSummaryUi(id) {
    return (
      <div className="card-graphics-section card-chart">
        {id !== null && <TrendingCardWrap id={id} />}
      </div>
    );
  }

  chordUi(content, filterChord, filterKeys, filterField) {
   
    if(content!==undefined) { 
    let availableKeys = [];
    const graphKeys = content.map(item => _.map(item[filterChord], filterKeys));
    const graphChord = content.map(item =>
      _.map(item[filterChord], filterField)
    );
   
    /* Create Empty Matrix Start*/
    graphKeys.map((item,key) => {
      availableKeys.push(content[key].productName);
      item.map(subitem => {
        availableKeys.push(subitem);
      });
    });
    let keys = _.uniqBy(availableKeys, function(e) {
      return e;
    });

    let martixSize = keys.length;
    var data = [];

    for (var i = 0; i < martixSize; i++) {
      data.push(new Array(martixSize).fill(0));
    }
    /* Create Empty Matrix End*/

    for (let k = 0; k < martixSize; k++) {
      for (let i = 0; i < graphKeys.length; i++) {
        for (let j = 0; j < graphKeys[i].length; j++) {
          if (graphKeys[i][j] === keys[k]) {
            data[k][i] = graphChord[i][j];
          }
        }
      }
    }

    // console.log(keys, data);

    return (
      <div className="card-graphics-section card-chart">
        {content !== null && <ChordChat graphKeys={keys} graphChord={data} />}
      </div>
    );
  }
  }

  viewedTogetherUi(content) {
    let availableKeys = [];
    const graphKeys = content.map(item =>
      _.map(item.viewedWith, "productName")
    );
    const graphChord = content.map(item => _.map(item.viewedWith, "viewCount"));

    /* Create Empty Matrix Start*/
    graphKeys.map(item => {
      item.map(subitem => {
        availableKeys.push(subitem);
      });
    });
    let keys = _.uniqBy(availableKeys, function(e) {
      return e;
    });

    let martixSize = keys.length;
    var data = [];

    for (var i = 0; i < martixSize; i++) {
      data.push(new Array(martixSize).fill(0));
    }
    /* Create Empty Matrix End*/

    for (let k = 0; k < martixSize; k++) {
      for (let i = 0; i < graphKeys.length; i++) {
        for (let j = 0; j < graphKeys[i].length; j++) {
          // console.log("key","[",k,"]", "===", "[",i,"][",j,"]", graphKeys[i][j]);
          // console.log(k +"->"+ "["+i+"]["+j+"]");
          if (graphKeys[i][j] === keys[k]) {
            data[k][i] = graphChord[i][j];
          }
        }
      }
    }

    console.log(keys, data);

    return (
      <div className="card-graphics-section card-chart">
        {content !== null && <ChordChat graphKeys={keys} graphChord={data} />}
      </div>
    );
  }
  purchasedTogetherUi(content) {
    const graphKeys = _.map(content, "productName");
    const graphKeysPurchasedWith = content.map(item =>
      _.map(item.purchasedWith, "productName")
    );

    const graphChord = content.map(item =>
      _.map(item.purchasedWith, "purchaseCount")
    );

    return (
      <div className="card-graphics-section card-chart">
        {content !== null && (
          <ChordChat
            graphKeys={graphKeys}
            graphChord={graphChord}
            data={content}
          />
        )}
      </div>
    );
  }

  render() {
    const { cardContent, onPinnedCard, onCardHistory, key } = this.props;
    const currentCard = this.cardType();

    return (
      <div className="card product-recommendation">
        <div className="card-thumbnail">
          <span className="icon-PRODUCT_RECOMMENDATIONS_MIX"></span>
        </div>
        <div className="card-content">
          <CardControlers
            onPinnedCard={onPinnedCard}
            cardContent={cardContent}
            onCardHistory={onCardHistory}
          />

          <div className="card-detail">
            <h6 className="card-type">
              Enrichment / Product Recommendations
            </h6>
            <h5>{currentCard.cardTitle}</h5>
            <p className="date">
              {" "}
              {moment.utc(cardContent.dateShowAfter).format("MMMM DD, YYYY")}
              {currentCard.type === "MANUAL" && (
                <React.Fragment>
                  by {cardContent.userFirstName} {cardContent.userLastName}
                </React.Fragment>
              )}
            </p>
            <p className="desc">{currentCard.cardDesc} </p>
            {currentCard.data}
          </div>
        </div>
      </div>
    );
  }
}

export default ProductRecommendations;