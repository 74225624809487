import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { debounce } from "lodash";

import "../../assets/styles.css";
import SearchResult from "../../enrichmentData/enrichmentContent/ContentRecommendations/SearchResult";
// import "../../assets/style.css";
class CustomeSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customerList: [],
      showResult: false,
      selectedCustomer:'',
      searchValue:''
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.setState({ showResult: true });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = e => {
    if (!this.node.contains(e.target)) {
      // this.setState({searchValue:''});
      this.clearResult();
    }
  };

  clearResult = () => {
    this.setState({ showResult: false, customerList: [] });
    this.props.onSearchDone();
  };

  customerSelected = (id, index,selectedCustomer) => {
    this.setState({ selectedCustomer, searchValue:selectedCustomer });
    this.props.onCustomerSelection(id,selectedCustomer);
    this.clearResult();
  };

  handleSearch = selectedValue => {
    let searchWhat = selectedValue.currentTarget.value;
    if (searchWhat == "") {
      this.setState({ customerList: [] });
    }
    this.setState(
      {
        searchValue: searchWhat
      },
      this.searchUpdated
    );
  };

  searchUpdated = debounce(() => {
    if (this.state.searchValue !== "") {
      this.props.onCustomerSelect(this.state.searchValue);
    }
  }, 1000);

  componentWillReceiveProps(prevProps) {
    if (this.state.customerList != prevProps.customerList) {
      this.setState({
        customerList: prevProps.customerList,
        showResult: true
      });
    }
  }

  render() {
    const { customerList, showResult } = this.state;
    return (
      <section
        className="pl-5 pr-5 pb-3 pr-5 filter-input"
        ref={node => (this.node = node)}
      >
       <Form onSubmit={(e)=>e.preventDefault()}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label className="d-block">
              Customer Recommendation Preview
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Search Customer"
              autoComplete="off"
              value={this.state.searchValue}
              onChange={e => this.handleSearch(e)}
            />
          </Form.Group>
          </Form>
        {customerList.length > 0 && (
          <SearchResult
            showResult={showResult}
            customerList={customerList}
            onCustomerSelection={(id, index,selectedCustomer) =>
              this.customerSelected(id, index,selectedCustomer)
            }
          />
        )}
      </section>
    );
  }
}

export default CustomeSearch;
