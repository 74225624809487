import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import CustomerDetailAPI from "../../services/customerDetail";

import { toast } from "react-toastify";

import "./assets/style.css";
import Loader from "../Loader/loader";

import CommonMessage from "../CommonMessage/commonMessage";
import RecordView from "../../views/Main/Customers/customerdata/RecordView/recordView";
import NoRecord from "../../components/NoData/nodata";
class CustomerDetail extends Component {
  state = {
    noData: false,
    isloading: false,
    customerDetail: null,
    customerDetailsample: {
      firstName: "Gary",
      lastName: "Oldman",
      firstSeen: 1569386818,
      enrichments: {
        lifetimeValue: "£2345.78",
        spendBracket: "Low-Number / High Value spender",
        chronotype: "Early Birds",
        products: {
          recommendations: [
            {
              order: 1,
              name: "Product 1",
              id: "P1",
              url: "http://a.c.com",
              image: "https://via.placeholder.com/150"
            },
            {
              order: 1,
              name: "Product 1",
              id: "P1",
              url: "http://a.c.com",
              image: "https://via.placeholder.com/150"
            },
            {
              order: 1,
              name: "Product 1",
              id: "P1",
              url: "http://a.c.com",
              image: "https://via.placeholder.com/150"
            },
            {
              order: 2,
              name: "Product 2",
              id: "P2",
              url: "http://a.c.com",
              image: "https://via.placeholder.com/150"
            }
          ],
          viewHistory: [
            {
              order: 1,
              name: "Product 1",
              id: "P1",
              url: "http://a.c.com",
              image: "https://via.placeholder.com/150"
            },
            {
              order: 2,
              name: "Product 2",
              id: "P2",
              url: "http://a.c.com",
              image: "https://via.placeholder.com/150"
            }
          ],
          purchaseHistory: []
        }
      },
      destinations: [
        {
          title: "High Spenders",
          integrations: ["Inegration Title 1", "Inegration Title 2"]
        },
        {
          title: "High Value Customers",
          integrations: ["Inegration Title 3"]
        }
      ],
      segments: {
        current: [
          {
            title: "segment 1",
            customerCount: 1000
          },
          {
            title: "segment 2",
            customerCount: 77777
          }
        ],
        history: [
          {
            title: "segment 1",
            joined: 123456788900, //Date
            left: 123456788900 //Date
          },
          {
            title: "segment 2",
            joined: 123456788900, //Date
            left: 123456788900 //Date
          }
        ]
      },
      customerData: [
        {
          sectionName: "CoreData",
          fields: [
            {
              name: "First Name",
              value: "John"
            },
            {
              name: "Last Name",
              value: "Smith"
            }
          ]
        },
        {
          sectionName: "A other dataset",
          fields: [
            {
              name: "Job title",
              value: "IT Manager"
            },
            {
              name: "Facebook slug",
              value: "fb://blah"
            }
          ]
        },
        {
          sectionName: "A other dataset",
          fields: [
            {
              name: "Job title",
              value: "IT Manager"
            },
            {
              name: "Facebook slug",
              value: "fb://blah"
            }
          ]
        },
        {
          sectionName: "A other dataset",
          fields: [
            {
              name: "Job title",
              value: "IT Manager"
            },
            {
              name: "Job title",
              value: "IT Manager"
            },
            {
              name: "Job title",
              value: "IT Manager"
            },
            {
              name: "Job title",
              value: "IT Manager"
            },
            {
              name: "Job title",
              value: "IT Manager"
            },
            {
              name: "Job title",
              value: "IT Manager"
            },
            {
              name: "Job title",
              value: "IT Manager"
            },
            {
              name: "Job title",
              value: "IT Manager"
            },
            {
              name: "Facebook slug",
              value: "fb://blah"
            }
          ]
        }
      ]
    }
  };

  componentWillReceiveProps(prevProps) {
    if (prevProps.customerSelectedID !== null) {
      this.singleCustomer(prevProps.customerSelectedID);
    }
  }

  singleCustomer = async id => {
    try {
      await CustomerDetailAPI.getCustomerDetail(id).then(response => {
        if (response.lenght === 0) {
          this.setState({ noData: true });
        }
        this.setState({
          customerDetail: response,
          isloading: true
        });
      });
    } catch (err) {
      toast.error("Failed to Load Customer");
      this.setState({
        noData: true,
        isloading: false
      });
    }
  };

  handleClose = () => {
    this.setState({
      isloading: false,
      customerDetail: null
    });
    this.props.onhide();
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  render() {
    const { customerDetail, customerDetailsample } = this.state;
    return (
      <div className="user-detail-popup">
        <Modal
          show={this.props.customerSelected}
          onHide={this.handleClose}
          className="user-detail-popup"
        >
          <div className="record-view">
            <div className="top-head">
              <span
                className="icon-close back-arrow"
                onClick={this.handleClose}
              ></span>
              <div className="top-heading-wrap">
                <h4>
                  {customerDetail !== null &&
                  (customerDetail.firstName || "") +" "+ (customerDetail.lastName || "")}
                </h4>
              </div>
            </div>

            {this.state.isloading && customerDetail !== null ? (
              <RecordView
                onClose={this.handleClose}
                customerDetail={customerDetail}
              />
            ) : this.state.noData ? (
              <NoRecord />
            ) : (
              <Loader />
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

export default CustomerDetail;
