import React from "react";
import "./styles.scss";
import { capitalizeTheFirstLetterOfEachWord } from "../funnelFull/FunnelStage";

const FunnelStageAttributionTable = ({funnelStageAttributions, funnelStageAttributions_formatted}) => {
    return (
        <div className="row display-flex-max576 display-none pb-3">
            <div className="col-1">

            </div>
            <div className="col-11">
                {funnelStageAttributions?.map((data, index) => (
                    <div className="content-row pt-2" key={index}
                         style={{display: 'flex', justifyContent: 'space-evenly'}}>
                        <p className="unique color-706 width178px text-right">
                            <strong>{capitalizeTheFirstLetterOfEachWord(data?.funnelStageAttributionChannel?.replace("_", " "))}</strong>
                        </p>
                        <p className="unique color-706 width50px">
                            {(funnelStageAttributions_formatted ?? [])[index]?.attributionPercent_formatted}
                        </p>
                        <div className="pers width55px">
                            {data.deltaToPrevious < 0 ?
                                <svg width="11" height="9" viewBox="0 0 11 9" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.45538 9L0.157987 0.750001L10.7528 0.75L5.45538 9Z"
                                          fill="#ED6A5E"/>
                                </svg>
                                : data.deltaToPrevious === 0 ?
                                    <svg width="9" height="13" viewBox="0 0 9 13" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 6.5L0.749999 12.1292L0.75 0.870834L9 6.5Z"
                                              fill="#B7B7B7"/>
                                    </svg> :
                                    <svg width="13" height="9" viewBox="0 0 13 9" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.5 0L12.1292 8.25H0.870835L6.5 0Z"
                                              fill="#9FCE1D"/>
                                    </svg>
                            }
                            <p className="per-3 color-706">
                                {(funnelStageAttributions_formatted ?? [])[index]?.deltaToPrevious_formatted}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FunnelStageAttributionTable;