import React, { Component } from "react";
import { ListGroup, ListGroupItem, Figure } from "react-bootstrap";
import "../assets/css/style.css";
import noImage from "../../../../../../assets/img/no-image.svg";


class ProductDetail extends Component {
  render() {
    const { products } = this.props;
    const recommendations = products.recommendations;
    const viewHistory = products.viewHistory;
    const purchaseHistory = products.purchaseHistory;

    return (
      <div className="products-wrapper">
        <h4 className="section-heading d-none d-lg-block">Product</h4>

        <div className="all-product">
          <div className="product-wrap">
            <h6>Top Product Recommendations</h6>
            {recommendations.length > 0 ? (
              <ListGroup>
                {recommendations.map(item => (
                  <ListGroupItem>
                    <Figure>
                      <Figure.Image alt="product-thumb" src={item.image || noImage} onError={(e) => {e.target.onerror = null; e.target.src = noImage}}/>
                      <Figure.Caption>{item.name}</Figure.Caption>
                    </Figure>
                  </ListGroupItem>
                ))}
              </ListGroup>
            ) : (
              <p className="noData">This customer has no top recommendations</p>
            )}
          </div>

          <div className="product-wrap">
            <h6>Product View History</h6>
            {viewHistory.length > 0 ? (
              <ListGroup>
                {viewHistory.map(item => (
                  <ListGroupItem>
                    <Figure>
                      <Figure.Image alt="product-thumb" src={item.image || noImage} onError={(e) => {e.target.onerror = null; e.target.src = noImage}}/>
                      <Figure.Caption>{item.name}</Figure.Caption>
                    </Figure>
                  </ListGroupItem>
                ))}
              </ListGroup>
            ) : (
              <p className="noData">This customer has no view history</p>
            )}
          </div>

          <div className="product-wrap">
            <h6>Product Purchase History</h6>
            {purchaseHistory.length > 0 ? (
              <ListGroup>
                {purchaseHistory.map(item => (
                  <ListGroupItem>
                    <Figure>
                      <Figure.Image alt="product-thumb"  src={item.image || noImage} onError={(e) => {e.target.onerror = null; e.target.src = noImage}} />
                      <Figure.Caption>{item.name}</Figure.Caption>
                    </Figure>
                  </ListGroupItem>
                ))}
              </ListGroup>
            ) : (
              <p className="noData">This customer has no purchase history</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ProductDetail;
