import React from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import iconBackground from "../assets/images/background-icon.svg";
import iconHeaderThursday from "../assets/images/thursday-icon.svg";
import iconFriday from "../assets/images/friday-footer-icon.svg";
import "../assets/css/productThursday.scss";
import "../assets/css/tooltip.css";
import "../assets/css/dailyCard/thursday.scss";
import "../assets/css/dailyCard/monday.scss";
import WordCloud from "./chart/WordCloud";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";

const ThursdayMinimal = ({ date, cardData }) => {

  function getChangeClass(weekChange) {

    if(!weekChange){
      return '';
    }

    if(weekChange === 'New') {
      return 'text-blue-new';
    }

    if(weekChange === '0') {
      return '';
    }

    if(weekChange.startsWith('+')) {
      return 'text-blue';
    }

    if(weekChange.startsWith('-')) {
      return 'text-red';
    }

    return '';
  }

  return (
          <>
            <Container className="product-thursday" fluid>
              <Row>
                <Col>
                  <div className="card m-0 p-2 rounded shadow-sm analytic-card">
                    <div className="card-header border-bottom-1 pl-3 position-relative padding-top-30px">
                      <div className="row card-header-row">
                        <div className="col-1 d-flex card-col-icon align-items-center">
                            <div className="card-icon">
                                <img className="icon-background" src={iconBackground} alt="header..."/>
                                <img className="icon-header" src={iconHeaderThursday} alt="header..."/>
                            </div>
                        </div>
                        <div className="col-11 card-title-col">
                            <div className=" fs-l fw-bold text-black">
                              Winners & Losers Thursday
                            </div>
                            <p className="fs-m text-muted mb-1 mb-md-2">{date}</p>
                            <div className="text-wrap pr-3 w-75">
                                <p className="card-text fs-m">
                                It’s hump day so let’s find out which products are flying off the shelves. And which are
                                giving you, well, the hump.
                                Thursday’s card gets right to the heart of your portfolio and reveals which products are
                                overperforming and which need more support.
                                </p>
                            </div>
                        </div>
                        <div className="card-offset">
                            <p className="card-info small">
                                <span className="text-infor"> How to read this card?</span>
                                    <span className="card-sm-icon m-2">
                                    <a href="https://help.distil.ai/hc/en-gb/articles/4419131531025-Daily-Insights-Winners-Losers-Thursday" className="" rel="noreferrer" target="_blank">
                                        <FontAwesomeIcon icon={faInfo}/>
                                    </a>
                                </span>
                            </p>
                        </div>
                      </div>
                    </div>
                    <div className="card-body m-md-3 m-0 border-bottom-1">
                      <div>
                        <div className="main-block">
                          <div className="week-data">
                            <p className="text-title mb-4">This Week</p>
                            <div className="content-left mb-5">
                              <p className="fw-bold text-value"># {cardData?.data?.differentProductsSold_formatted}</p>
                              <p className="text-gray">Different Products sold</p>
                            </div>
                            <div className="d-flex diff-prod-sold">
                              <div className="top">
                                <p className="text-small text-gray pb-0 mb-0">Top Climber this week</p>
                                <p className="text-lable text-blue">
                                  {cardData?.data?.topClimber?.name}
                                </p>
                              </div>
                              <div className="top-fail-block">
                                <p className="text-small text-gray pb-0 mb-0">Top Faller this week</p>
                                <p className="text-lable text-red">{cardData?.data?.topFaller?.name}</p>
                              </div>
                            </div>
                          </div>
                          <div className="image">
                            <WordCloud cardData={cardData}/>
                          </div>
                        </div>
                      </div>
                      <Row>
                        <Col xs={12} md={12} className="col-left padding-left-0-responsive">


                          <p className="mb-1 text-sub ">Movers and Shakers </p>
                          <p>
                            Your Sales Chart for the past 7 days including positions climbed, or fallen and any new entries.
                          </p>
                          <Table borderless className="table-data">
                            <thead>
                            <tr>
                              <th className="indexWidth"/>
                              <th className="itemNameWidth"/>
                              <th className="dataWidth">Change</th>
                              <th className="dataWidth"># Orders</th>
                              <th className="dataWidth">Value</th>
                            </tr>
                            </thead>
                            <tbody>
                            {cardData?.data?.moversAndShakers && cardData?.data?.moversAndShakers.map((item, index) => {
                              const otherOrTotal = item.name === "Other" || item.name === "Total";
                              return (
                                      <tr key={index}>
                                        <td className="padding-left-0 indexWidth">{otherOrTotal ? null : index + 1}</td>
                                        <td className={item.name === "Total" ? "fw-bold" : ""}>
                                          {item.name.length > 60 ? <div className="tooltipContent">
                                            {item.name.substring(0, 60)}&hellip;<span
                                                  className="tooltipText">{item.name}</span></div> : item.name}
                                        </td>
                                        {otherOrTotal ? <td/> :
                                            <td className={`${(getChangeClass(item.rankingChange))}`}>
                                              {item.rankingChange}
                                            </td>}
                                        <td className={item.name === "Total" ? "fw-bold" : ""}>

                                          { cardData?.data?.moversAndShakers_formatted &&
                                            cardData?.data?.moversAndShakers_formatted[index]?.lastWeekValue_formatted
                                          }
                                        </td>
                                        <td className={item.name === "Total" ? "fw-bold" : ""}>
                                          { cardData?.data?.moversAndShakers_formatted &&
                                            cardData?.data?.moversAndShakers_formatted[index]?.totalValue_formatted
                                          }
                                        </td>
                                      </tr>
                              );
                            })}
                            </tbody>
                          </Table>
                          <div className="pt-50 mb-5">
                            <p className="text-sub mb-1">Subscription Opportunities</p>
                            <p>
                              Products that are bought more than once in the last
                              three months by the same Customer.
                            </p>
                            <Table borderless className="table-data">
                              <thead>
                              <tr>
                                <th className="indexWidth"/>
                                <th className="itemNameWidth"/>
                                <th className="dataWidth">Change</th>
                                <th className="dataWidth">Repeat Rate</th>
                                <th className="dataWidth">Repeat Value</th>
                              </tr>
                              </thead>
                              <tbody>
                              {cardData?.data?.subscriptionOpportunities && cardData?.data?.subscriptionOpportunities.map((item, index) => {
                                const otherOrTotal = item.name === "Other" || item.name === "Total";
                                return (
                                        <tr key={index}>
                                          <td className="padding-left-0 indexWidth">{item.name === "Other" ? null : index + 1}</td>
                                          
                                          <td>{item.name.length > 60 ? <div className="tooltipContent">
                                            {item.name.substring(0, 60)}&hellip;<span
                                                  className="tooltipText">{item.name}</span></div> : item.name}</td>
                                          
                                          {otherOrTotal ? <td/> :
                                            <td className={`${(getChangeClass(item.rankingChange))}`}>
                                              {item.rankingChange}
                                            </td>}
                                          
                                          <td>{cardData?.data?.subscriptionOpportunities_formatted &&
                                            cardData?.data?.subscriptionOpportunities_formatted[index]?.lastWeekValuePercent_formatted
                                          }
                                          </td>
                                          <td>
                                            { cardData?.data?.subscriptionOpportunities_formatted &&
                                              cardData?.data?.subscriptionOpportunities_formatted[index]?.totalValue_formatted
                                            }
                                          </td>
                                        </tr>
                                )
                              })}
                              <tr>
                                <td/>
                                <td className="fw-bold">Total</td>
                                <td/>
                                <td className="fw-bold"/>
                                <td className="fw-bold">
                                  <div>{cardData?.data?.subscriptionOpportunitiesTotal_formatted}</div>
                                </td>
                              </tr>
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                        {/* <Col xs={12} md={5}>
                    <div className="mb-188">
                      <p className="text-title">Customer Intent Profile</p>
                      <p className="text-value">#{cardData?.data?.allCustomersCount}</p>
                      <p className="text-normal">
                        Customers with Intent Profiles. That’s <b>{cardData?.data?.indentCustomersPercent}</b> of the
                        Active Customer Segment
                      </p>

                      <p className="text-value">#{cardData?.data?.indentCustomerCount}</p>
                      <p className="text-normal">
                        Customer Unique Intent Profiles.
                      </p>

                      <p className="text-normal">Customers with this profile are likely to buy one or more of the following products:</p>
                      {cardData?.data?.customerIndentProfile && cardData?.data?.customerIndentProfile.map((item, index) => (
                        <div key={index}>
                          <div className="thursday-hr"></div>
                          <p className="text-child">Intent Profile #{index + 1}</p>
                          <p className="text-top">{item.customersCount}</p>
                          <p className="text-bottom">
                            Customers with this intent
                          </p>
                          <p className="text-top">£ {item.value}</p>
                          <p className="text-bottom">Value if activated</p>

                          <p className="text-bottom">
                            Customers with this profile are likely to buy one or
                            more of the following products
                          </p>
                          <div>
                            {item.productNames && item.productNames.map((i, index) => (
                              <p key={index} className="text-light">
                                {i}
                              </p>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </Col> */}
                      </Row>
                    </div>
                    <div className="card-footer fs-m pt-5">
                      <Row className="text-center">
                        <Col lg={2}/>
                        <Col ls={8} className="section-text-footer">
                          <div className="fs-l fw-bold">Tomorrow is Wrap-Up Friday</div>
                          <div className="fs-xm text-gray pt-1">
                            It’s time to step back and see how the week went compared to the
                            high hopes you had on Monday morning.
                          </div>
                          <div className="card-icon pt-4 w-100 h-100">
                            <img src={iconFriday} alt="footer..."/>
                          </div>
                        </Col>
                        <Col lg={2}/>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </>
  );
};

export default ThursdayMinimal;