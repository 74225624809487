import ApiService from "./tenantApiService";

function getCustomersSchema() {
  const requestUrl = "/customer/schema";
  return ApiService.get(requestUrl).then(response => {
    return response.data;
  });
}

function getDataView() {
  const requestUrl = "/customer/dataview";

  return ApiService.get(requestUrl).then(response => {
    return response.data;
  });
}

function newDataView(data) {
  const requestUrl = "/customer/dataview";

  return ApiService.post(requestUrl, data).then(response => {
    return response.data;
  });
}

function updateDataView(id, data) {
  const requestUrl = "/customer/dataview/" + id;

  return ApiService.put(requestUrl, data).then(response => {
    return response.data;
  });
}
function deleteView(id) {
  const requestUrl = "/customer/dataview/" + id;
  return ApiService.delete(requestUrl).then(response => {
    return response.data;
  });
}
function getSegments() {
  const requestUrl = "/customer/segment?include_rules=" + false;

  return ApiService.get(requestUrl).then(response => {
    return response.data;
  });
}
function getSegmentsWithRules() {
  const requestUrl = "/customer/segment?include_rules=true";

  return ApiService.get(requestUrl).then(response => {
    return response.data;
  });
}

function getSegmentsWithNoStats() {
  const requestUrl = "/customer/segment?include_rules=false&no_stats=true";

  return ApiService.get(requestUrl).then(response => {
    return response.data;
  });
}

function getCustomersCount() {
  const requestUrl = `/customer/count`;

  return ApiService.get(requestUrl).then(response => {
    return response.data;
  });
}


function getSingleSegment(id) {
  const requestUrl = "/customer/segment/" + id;

  return ApiService.get(requestUrl).then(response => {
    return response.data;
  });
}

function createNewSegment(data) {
  const requestUrl = "/customer/segment";

  return ApiService.post(requestUrl, data).then(response => {
    return response.data;
  });
}

function segmentHistory(id) {
  const requestUrl = "/customer/segment/" + id + "/history";

  return ApiService.get(requestUrl).then(response => {
    return response.data;
  });
}

function delteSegment(id) {
  const requestUrl = "/customer/segment/" + id;

  return ApiService.delete(requestUrl).then(response => {
    return response.data;
  });
}

function segmentDependency(id) {
  const requestUrl = "/customer/segment/" + id + "/has_dependancies";

  return ApiService.get(requestUrl).then(response => {
    return response.data;
  });
}

function segmentPreview(data, dataViewId) {
  let view = dataViewId != null ? `?view_id=${dataViewId}` : "";

  const requestUrl = "/customer/segment_preview" + view;

  return ApiService.post(requestUrl, data).then(response => {
    return response.data;
  });
}

function segmentationFieldAutoSuggest(data) {
  const requestUrl = "/customer/segmentation-field-auto-suggest";

  return ApiService.post(requestUrl, data).then(response => {
    return response.data;
  });
}

function updateSegment(id, data) {
  const requestUrl = "/customer/segment/" + id;
  return ApiService.put(requestUrl,data).then(response => {
    return response.data;
  });
}

//Customer Section
function getCustomerList(formData) {
  let { pageNo, segmentId, dataViewId } = formData;
  let params = ``;
  params = `page_no=${pageNo}`;
  segmentId != null && (params += `&segment_id=${segmentId}`);
  dataViewId != null && (params += `&view_id=${dataViewId}`);

  const requestUrl = "/customer/list?" + params;

  return ApiService.get(requestUrl).then(response => {
    return response.data;
  });
}

function getCustomerListMobile() {
  const requestUrl = "customer/mobilelist";

  return ApiService.get(requestUrl).then(response => {
    return response.data;
  });
}

function getUserPreferences() {
  const requestUrl = "userpreference";

  return ApiService.get(requestUrl).then(response => {
    return response.data;
  });
}
function updateUserPreferences(data) {
  const requestUrl = "userpreference";

  return ApiService.put(requestUrl, data).then(response => {
    return response.data;
  });
}

function downloadCustomerCSV(formData) {
  let { segmentId, dataViewId } = formData;
  let params = ``;
  segmentId != null && (params += `&segment_id=${segmentId}`);
  dataViewId != null && (params += `&view_id=${dataViewId}`);

  const requestUrl = "/customer/list/download?" + params;

  return ApiService.get(requestUrl)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
}

export default {
  getSegments,
  getSegmentsWithRules,
  getSegmentsWithNoStats,
  getCustomersCount,
  getSingleSegment,
  getDataView,
  createNewSegment,
  delteSegment,
  newDataView,
  deleteView,
  getCustomersSchema,
  getCustomerList,
  getCustomerListMobile,
  updateSegment,
  segmentDependency,
  segmentPreview,
  segmentationFieldAutoSuggest,
  updateDataView,
  getUserPreferences,
  updateUserPreferences,
  downloadCustomerCSV,
  segmentHistory,
};
