import React, {Component} from "react";
import {Button, Col, Row} from "react-bootstrap";

import connectionTypes from "../connectionTypes";

import "../assets/modal.css";
import DataSource from "./DataSource";
// import ConnnectionViews from "./ConnectionViews";
import AttributeModal from "./AttributeModal";
import DataSourceModal from "./DataSourceModal";
import NoData from "../../../../../components/NoData/nodata";
import {zendeskInfoAccount, dataSourceGroups} from "../../../../../variables/globalValues.json";
import zapier from "../../../../../services/zapier";
import BrazeInfo from "../../Integrations/forms/BrazeInfo";

class ConectionDetail extends Component {

  state = {
    attributeModal: false,
    dataSourceModal: false,
    isLoaded: false,
    currentAttributes: [],
    currentModalDataSource: [],
    isRefreshRequested: true,
    dataSourceSyncing: false,
    isConnectionTestedRequested: false,
    distilManagedTypes: ["ZAPIER", "API_CONNECTION"]
  };

  selectAttributes = att => {
    this.setState({ attributeModal: true, currentAttributes: att });
  };

  selectDataSource = dataSource => {
    this.setState({
      dataSourceModal: true,
      currentModalDataSource: dataSource
    });
  };

  connectionDataSourceTypes = types => {
    return this.props.currentDataSource
    .filter(item => types.includes(item.dataSourceType))
    .sort((a, b) => {
          if (a.syncTurnedOn === b.syncTurnedOn) {
            return a.name.localeCompare(b.name);
          }
          return b.syncTurnedOn - a.syncTurnedOn;
        });
  };

  connectionIcon() {
    if (this.props.dataDetailSelected.length > 0) {
      // Use custom connection type list if it was passed outside.
      const types = this.props.connectionTypesList ? this.props.connectionTypesList : connectionTypes

      const imgSrc = types.filter(
          item => item.name === this.props.dataDetailSelected[0].connectionType
      );

      return (
        imgSrc.length > 0 && (
          <div className="logo-wrap">
            <img src={imgSrc[0].image} alt={imgSrc[0].name} />
          </div>
        )
      );
    }
  }
  onRefreshConnection = () => {
    this.setState({ isRefreshRequested: false }, () =>
      this.props.onRefreshConnection()
    );
  };

  componentDidMount() {
    this.setState({ isRefreshRequested: true, isLoaded: true });
  }
  componentWillReceiveProps = nextProps => {
    this.setState({
      isRefreshRequested: nextProps.onRefreshRequestedDone,
      dataSourceSyncing: nextProps.onDataSourceSyncing,
      isLoaded: true
    });
  };

  refreshButton() {
    return !this.state.isRefreshRequested ? (
      <Button variant="secondary">
        {/* <i className="svg-download refreshConnection" /> */}
        <span className="icon-sync refreshConnection"/>
        Refresh List
      </Button>
    ) : this.state.dataSourceSyncing === true ? (
      <Button variant="secondary">
        {/* <i className="svg-download refreshConnection" /> */}
        <span className="icon-sync refreshConnection"/>
        Refresh in progress
      </Button>
    ) : !this.props.isDisabled ? (
      <Button variant="secondary" onClick={this.onRefreshConnection}>
        <span className="icon-sync"/>
        Refresh List
      </Button>
    ) : <></>;
  }

  testConnectioButtonStatus() {
    switch (this.props.isConnectionTested) {
      case "TEST":
        return (
          <Button variant="secondary" onClick={this.onTestConnection}>
            <span className="icon-check icon" />
            Test Connection
          </Button>
        );
      case "PASS":
        return (
          <Button variant="secondary">
            <span className="icon-check_circle icon active" />
            Connection Tested
          </Button>
        );
      case "FAIL":
        return (
          <Button variant="secondary" onClick={this.onTestConnection}>
            <span className="icon-close icon fail" />
            Test Connection Fail
          </Button>
        );

      default:
        break;
    }
  }

  closeModal = () => {
    this.setState({ attributeModal: false, dataSourceModal: false });
  };

  getDaysToExpirationToken(dataDetailSelected){
    const dateExpiresIn = new Date(dataDetailSelected.connectionSettings.tokenExpiration * 1000);
    return Math.ceil(Math.abs(dateExpiresIn.getTime() - new Date().getTime()) / (1000 * 3600 * 24));
  }

  onTestConnection = () => {
    this.props.ontestConnection();
    this.setState({ isConnectionTestedRequested: true });
  };

  showIntegrationSyncStatus(daysToExpirationToken, dataDetailSelected) {
    if (dataDetailSelected.connectionStatus === "REQUIRED_AUTHENTICATION") {
      return "Your Facebook authentication is no longer valid - please edit the connection to re-authenticate."
    } else {
      return "Your Facebook authentication is going to expire in " + daysToExpirationToken + " days - please edit the connection to re-authenticate.";
    }
  }

  showMessageOnTopConnectionScreen = dataDetailSelected => {
    return dataDetailSelected.connectionType === "FACEBOOK_LEAD_ADS"
        ? ("This integration is a write only Destination integration and as such there are no Data Sources to display")
        : ("There are no compatible Tables or Views found in this " + dataDetailSelected.name);
  };

  showConnectionDataSources = dataDetailSelected => {
    return dataDetailSelected.connectionType !== "FACEBOOK_LEAD_ADS" ? (
        <div className="result-wrap">
          <Row noGutters={true}>
            {dataSourceGroups.map((group, index) => (
                <DataSource
                    key={index}
                    title={`${group.name} data sources`}
                    onSelectAttributes={this.selectAttributes}
                    onSelectDataSource={this.selectDataSource}
                    dataSourceList={this.connectionDataSourceTypes(group.type)}
                    isInfoLoaded={this.props.isInfoLoaded}
                    onUpdateDataSourcesState={this.props.onUpdateDataSourcesState}
                />
            ))}
          </Row>
          <div className="view-result bg-white"/>
        </div>
    ) : null
  }

  render() {
    const {
      dataDetailSelected,
      onBackPress,
      currentDataSource,
      onEditConnection,
      isConnectionTested
    } = this.props;

    const {
      attributeModal,
      currentAttributes,
      dataSourceModal,
      currentModalDataSource,
      isLoaded,
      distilManagedTypes
    } = this.state;

    const failedCount = this.props.currentDataSource.filter(d => d.syncTurnedOn
        && d.lastDataSourceSyncStatus !== null
        && (d.lastDataSourceSyncStatus == 'FAILED_AS_DATA_SOURCE_UNAVAILABLE' || d.lastDataSourceSyncStatus == 'ERROR')).length;


    if (dataDetailSelected.length > 0) {

      const daysToExpirationToken = this.getDaysToExpirationToken(dataDetailSelected[0]);
      return (
        <React.Fragment>
          {failedCount > 0 && (
            <div className="status-error">
              Last data sync for connection has failed
            </div>
          )}

          {(daysToExpirationToken <= 10 || dataDetailSelected[0].connectionStatus === "REQUIRED_AUTHENTICATION") &&(
              <div className="status-error">
                {this.showIntegrationSyncStatus(daysToExpirationToken, dataDetailSelected[0])}
              </div>
          )}

          <div className="post-icon d-flex align-items-end">
            <div className="top-img">
              <Button
                variant="secondary"
                className="d-block d-md-none back-arrow"
                onClick={onBackPress}
              >
                <span className="icon-arrow-left" />{" "}
              </Button>
              {this.connectionIcon()}
            </div>
            <div className="top-btn-wrap ml-auto">
              {!distilManagedTypes.includes(this.props.dataDetailSelected[0].connectionType) && <>

                {this.props.dataDetailSelected[0].enabled && this.refreshButton()}

                {isLoaded ? (
                  isConnectionTested === "TESTING" ? (
                    <Button variant="secondary">
                      <span className="icon-distil-logo icon spin"/>
                      Testing Connection
                    </Button>
                  ) : (
                    this.testConnectioButtonStatus()
                  )
                ) : (
                  this.testConnectioButtonStatus()
                )}
              </>
              }

              <Button variant="secondary" onClick={onEditConnection}>
                <span className="icon-setting" /> Edit Connection
              </Button>
              <Button
                disabled={this.props.isDisabled}
                variant="secondary"
                onClick={this.props.onDisableConnection}
              >
                {!this.props.dataDetailSelected[0].enabled ? (
                  <React.Fragment>
                    <span className="icon-toggle-off" />
                    Disabled
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <span className="icon-toggle-on active" />{" "}
                    <strong>Enabled</strong>
                  </React.Fragment>
                )}
              </Button>
              {/* {this.statusButton()} */}
            </div>
          </div>
          <div className="top-section border-btm">
            <h4 className="font-weight-heavy">
              {" "}
              {dataDetailSelected[0].name}{" "}
            </h4>
            <p>{dataDetailSelected[0].description}</p>
          </div>
          <div className="connection-result-wrap">
            {
              dataDetailSelected[0].connectionType === "BRAZE" ?
                  (<BrazeInfo
                  connection = {this.props.dataDetailSelected[0]}/>) :
                  (<Row noGutters={true}>
                    <Col md={12}>
                      <div className="compatible-result">
                        {currentDataSource.length > 0 ? (
                            <p>
                              Distil has found the following <strong>compatible</strong>{" "}
                              tables and view in {dataDetailSelected[0].name}.
                            </p>
                        ) : (
                            <p>{this.showMessageOnTopConnectionScreen(dataDetailSelected[0])}</p>
                        )}
                        {dataDetailSelected[0].connectionType === "ZEN_DESK" ? (<p>{zendeskInfoAccount[dataDetailSelected[0].connectionSettings.accountType]}</p>) : null}
                        {this.showConnectionDataSources(dataDetailSelected[0])}

                      </div>
                    </Col>
                  </Row>
              )
            }
          </div>

          {attributeModal && (
            <AttributeModal
              modalstate={attributeModal}
              onCloseModal={this.closeModal}
              content={currentAttributes}
              connectionId={this.props.dataDetailSelected[0].id}
              onAttrUpdate={this.props.onAttrUpdate}
            />
          )}

          {dataSourceModal && (
            <DataSourceModal
              modalstate={dataSourceModal}
              onCloseModal={this.closeModal}
              content={currentModalDataSource}
              onAttrUpdate={this.props.onAttrUpdate}
            />
          )}
        </React.Fragment>
      );
    } else {
      return <NoData />;
    }
  }
}

export default ConectionDetail;
