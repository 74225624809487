import React, {Component} from "react";
import {ListGroup, ListGroupItem} from "react-bootstrap";
import "../assets/css/style.css";
import moment from "moment";
import {getPluralEnding} from "../../segmentUtils";

class SegmentDetail extends Component {
  render() {
    const { segments } = this.props;
    const current = segments.current;
    const history = segments.history;

    return (
      <div className="segment-wrapper contentWrap">
        <h4 className="section-heading d-none d-lg-block">Segments</h4>

        <ListGroup className="detail-list">
          <ListGroupItem>
            <h6>Current Segments</h6>

            <div className="list-content">
              {current !== null > 0 ? (
                <React.Fragment>
                  <span className="sub-content">
                    This customer record appears in the following{" "}
                    <strong>Segments</strong>
                  </span>
                  <ListGroup>
                    {current.map(item => (
                      <ListGroupItem>
                        <p>
                          {item.title} <i> {item.customerCount} Record{getPluralEnding(item.customerCount)} </i>
                          <i>
                            {" "}
                            {item.joined ? ` Joined ${moment.utc(item.joined).format("MMM DD, YYYY")}` : ''}
                          </i>
                        </p>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </React.Fragment>
              ) : (
                <span className="sub-content noData">
                  This customer record currently appears in no{" "}
                  <strong>Segments</strong>
                </span>
              )}
            </div>
          </ListGroupItem>

          <ListGroupItem>
            <h6>Segment History</h6>
            <div className="list-content">
              {history !== null ? (
                <React.Fragment>
                  <span className="sub-content">
                    This Customer has previously been part of the following{" "}
                    <strong>Segments</strong>
                  </span>
                  <ListGroup>
                    {history.map(item => item.left && (
                      <ListGroupItem>
                        <p>
                          {item.title}{" "}
                          <i>
                            {" "}
                            {item.joined ? `Joined ${moment.utc(item.joined).format("MMM DD, YYYY")}` : ''}
                            {item.left ? ` Left ${moment.utc(item.left).format("MMM DD, YYYY")}` : ''}
                          </i>
                        </p>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </React.Fragment>
              ) : (
                <span className="sub-content noData">
                  This customer has not previously been part of any{" "}
                  <strong>Segments</strong>
                </span>
              )}
            </div>
          </ListGroupItem>
        </ListGroup>
      </div>
    );
  }
}

export default SegmentDetail;
