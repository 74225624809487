import React, { Component } from "react";
import { ResponsiveBar } from "@nivo/bar";
import _ from "lodash";

class TrendingBarChart extends Component {
  render() {
    const { size, grid, data } = this.props;
    var sortedData = _.orderBy(data, "records", "asc");

    return (
      <div className="barChart">
        <ResponsiveBar
          data={sortedData}
          keys={["records"]}
          indexBy="name"
          margin={{ top: 0, right: size > 0 ? size : 155, bottom: 0, left: 0 }}
          padding={0.3}
          groupMode="grouped"
          layout="horizontal"
          colors={["#B4D749"]}
          reverse={true}
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          axisTop={null}
          axisRight={{
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0,
            legend: "",
            legendOffset: 0,
            renderTick: ({
              textAnchor,
              textBaseline,
              textX,
              textY,
              value,
              x,
              y
            }) => {
              return (
                <g transform={`translate(${x},${y})`}>
                  <text
                    alignmentBaseline={textBaseline}
                    textAnchor={textAnchor}
                    transform={`translate(${textX},${textY})`}
                  >
                    {value.substring(0, size > 0 ? size : 30)}
                    {value.length > 30 && size === undefined ? "..." : ""}
                  </text>
                </g>
              );
            }
          }}
          axisBottom={null}
          axisLeft={null}
          enableGridX={grid === undefined ? true : grid}
          enableGridY={false}
          labelSkipWidth={2}
          labelSkipHeight={2}
          labelTextColor={{ from: "color", modifiers: [["darker", "1.5"]] }}
          legends={[]}
          tooltip={({ label, value, indexValue }) => (
            <React.Fragment>
              {indexValue} : <strong>{value}</strong>
            </React.Fragment>
          )}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
        />
      </div>
    );
  }
}

export default TrendingBarChart;
