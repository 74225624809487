import React, { Component } from "react";
import CommonMessage from '../../../../components/CommonMessage/commonMessage';


class PaymentAndBilling extends Component {
  render() {
    // console.log(this.props);
    return (
      <div className="p-4 align-center">
         <CommonMessage />
 
      </div>
    );
  }
}

export default PaymentAndBilling;
