import ApiService from "./tenantApiService";
import UserService from "./apiService";

function getCustomerDetail(id) {
  const requestUrl = "/customer/details/" + id;
  return ApiService.get(requestUrl).then(response => {
    return response.data;
  });
}

function createOrganisation(data) {
  const requestUrl = "/org/";
  return ApiService.post(requestUrl, data).then(response => {
    return response.data;
  });
}

function updateOrganisation(data, id) {
  const requestUrl = "/org/" + id;
  return UserService.put(requestUrl, data).then(response => {
    return response.data;
  });
}

export default {
  getCustomerDetail,
  createOrganisation,
  updateOrganisation
};
