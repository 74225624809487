import CustomersData from '../views/Main/Customers/customerdata/customersData';
import Enrichment from '../views/Main/Customers/enrichment/enrichment';
import CustomerTraits
  from "../views/Main/Customers/customertraits/customerTraits";

const customerroutes = [
  {
    path: "/customers/segments",
    name: "Segments",
    component: CustomersData,
    className: "users-link",
    roles: ["ADMIN", "CONTRIBUTOR"]
  },
  {
    path: "/customers/enrichment",
    name: "Enrichment",
    component: Enrichment,
    className: "users-link",
    roles: ["ADMIN", "CONTRIBUTOR"]
  },
  {
    path: "/customers/traits",
    name: "Traits",
    component: CustomerTraits,
    className: "users-link",
    roles: ["ADMIN", "CONTRIBUTOR"]
  },
  // {
  //   path: "/destinations/view",
  //   name: "Destinations",
  //   component: Destinations,
  //   className: "users-link",
  //   roles: ["ADMIN","CONTRIBUTOR"]
  // },
  // {
  //   path: "/customers/datamarkers",
  //   name: "Data Markers",
  //   component: DataMarkers,
  //   className: "users-link",
  //   roles: ["ADMIN","CONTRIBUTOR","VIEWER"]
  // },

];

export default customerroutes;
