import React, { Component } from "react";
import "./assets/styles.css";
import { Tabs, Tab } from "react-bootstrap";
import CommonMessage from "../CommonMessage/commonMessage";

import NewsfeedCard from "../../views/Main/Newsfeed/components/card";
import NewsfeedAPI from "../../services/newsfeed";
import _ from "lodash";
class Profile extends Component {
  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      pinnedCards: null,
      isloading: false,
      contextCard: null,
      enrichmentCards: [
        "CHRONOTYPE",
        "DEMOGRAPHIC",
        "ENGAGEMENT",
        "RFIM",
        "PRODUCT_RECOMMENDATIONS_MIX",
        "CONTENT_RECOMMENDATIONS_MIX",
        "SPEND_BRACKET"
      ],
      enrichmentCardsEnum: {
        CHRONOTYPE: "ENRICHMENT_CHRONOTYPE",
        DEMOGRAPHIC: "ENRICHMENT_DEMOGRAPHIC",
        ENGAGEMENT: "ENRICHMENT_ENGAGEMENT",
        RFIM: "ENRICHMENT_RFIM",
        PRODUCT_RECOMMENDATIONS_MIX: "ENRICHMENT_PRODUCT_RECOMMENDATIONS",
        CONTENT_RECOMMENDATIONS_MIX: "ENRICHMENT_CONTENT_RECOMMENDATIONS",
        SPEND_BRACKET: "ENRICHMENT_SPEND_BRACKET"
      }
    };
  }
  componentDidMount() {
    this.handlePinnedCard();
    if (this.props.currentContext !== null) {
      this.handleContextCard();
    }
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  handlePinnedCard = async card => {
    try {
      await NewsfeedAPI.latestPinnedCards().then(response => {
        this.setState({ pinnedCards: response, isloading: true });
      });
    } catch (err) {
      return;
    }
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  setWrapperRef(node) {
    this.wrapperRef = node;
  }
  handleClickOutside(e) {
    if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
      if (
        e.target.id !== "notice-btn-wrapper" &&
        e.target.id !== "notice-btn"
      ) {
        this.props.onEscape();
      }
    }
  }

  renderContextTab() {
    const contextType = {
      CUSTOMER_SEGMENT: "Customer Segment", // Customer > Segment (if segment selected then ID will be send)
      CHRONOTYPE: "Chronotype", // Customer > Entrichment > Chronotype
      DEMOGRAPHIC: "Demographics", // Customer > Entrichment > Demographics
      ENGAGEMENT: "Engagement", // Customer > Entrichment > Engagement
      RFIM: "Recency, Frequency, Intensity, Momentum", // Customer > Entrichment > RFIM
      PRODUCT_RECOMMENDATIONS_MIX: "Product Recommendations ", // Customer > Entrichment > Product Recomendation
      CONTENT_RECOMMENDATIONS_MIX: "Content Recommendations ", // Customer > Entrichment > Content Recomendation
      SPEND_BRACKET: "Spend Brackets", // Customer > Entrichment > Chronotype > Spend Bracket

      DESTINATION: "Destination", // Destination
      CUSTOMER_SEGMENT_DESTINATION: "Customer Segment Destination", //Destination > Edit Selected segment

      USER: "User", // Setting > User > Manage Users
      INTEGRATION: "Integration", // Setting > Destination
      CONNECTION: "Connection", // Setting > Data Connections

      ENRICHMENT: "Enrichment", //Need explanation
      CUSTOMER: "Customer", //Need explanation

      DATA_SOURCE: "Data Source",
      CUSTOMER_DATA_MARKER: "Customer Data Marker",
      DATA_MARKERS: "Data Marker"

      //Available without prefix ENRICHMENT_
      // ENRICHMENT_CHRONOTYPE,
      // ENRICHMENT_DEMOGRAPHIC,
      // ENRICHMENT_ENGAGEMENT,
      // ENRICHMENT_RFIM,
      // ENRICHMENT_SPEND_BRACKET,
      // ENRICHMENT_PRODUCT_RECOMMENDATIONS,
      // ENRICHMENT_CONTENT_RECOMMENDATIONS,
    };

    if (this.props.currentContext !== null) {
      return contextType[this.props.currentContext];
    } else {
      return null;
      // return this.props.currentPage;
    }
  }
  handleContextCard = () => {
    const { enrichmentCardsEnum } = this.state;
    const currentContext = this.props.currentContext;
    if (_.includes(this.state.enrichmentCards, this.props.currentContext)) {
      this.contextPinnedCards(enrichmentCardsEnum[currentContext]);
    } else {
      this.contextPinnedCards(currentContext);
    }
  };
  contextPinnedCards = async card => {
    try {
      await NewsfeedAPI.contextPinnedCards(
        card,
        this.props.currentContextId
      ).then(response => {
        this.setState({ contextCard: response, isloading: true });
      });
    } catch (err) {
      return;
    }
  };

  handlePinned = async card => {
    const pinnedCards = this.state.pinnedCards;
    let cardStatus = card.pinned ? (card.pinned = false) : (card.pinned = true);
    let pinnedItem = { id: card.newsfeedStackId, pinned: cardStatus };

    try {
      await NewsfeedAPI.updatePinStatus(pinnedItem).then(response => {
        pinnedCards.map(currentcard => {
          if (currentcard.newsfeedStackId === card.newsfeedStackId) {
            currentcard.pinned = cardStatus;
          }
        });
      });
    } catch (err) {
      return;
    }
    this.setState({ pinnedCards }, () => this.props.updatedPinnedCard(card));
  };

  render() {
    const { onPinnedCard, onCardHistory } = this.props;
    const { pinnedCards, isloading, contextCard } = this.state;
    let trulyPinned = {};
    if (isloading && pinnedCards !== null) {
      trulyPinned = pinnedCards.filter(item => item.pinned === true);
    }

    return (
      <div className="profile-wrap" ref={this.setWrapperRef}>
        <div className="tab-area primary-font">
          <Tabs
            defaultActiveKey={
              this.props.opencontextTab ? "context" : "pinned_card"
            }
            id="uncontrolled-tab-example"
          >
            <Tab eventKey="pinned_card" title="Pinned Cards">
              <div className="profile-header primary-font"></div>

              <React.Fragment>
                {isloading ? (
                  trulyPinned.length > 0 ? (
                    <div className="newsfeed-container">
                      {trulyPinned.map((card, index) => (
                        <NewsfeedCard
                          keyIndex={index}
                          cardContent={card}
                          onPinnedCard={this.handlePinned}
                          onCardHistory={onCardHistory}
                        />
                      ))}
                    </div>
                  ) : (
                    <div className="profile-header primary-font">
                      <CommonMessage msg="You have not currently pinned any news cards - please visit the Newsfeed section and select the news you are interested in" />
                    </div>
                  )
                ) : (
                  "Loading"
                )}
              </React.Fragment>
            </Tab>
            <Tab eventKey="context" title={this.renderContextTab()}>
              <div className="profile-header primary-font context"></div>
              {contextCard !== null && contextCard.length > 0 ? (
                <React.Fragment>
                  <div className="newsfeed-container">
                    {contextCard.map((card, index) => (
                      <NewsfeedCard
                        keyIndex={index}
                        cardContent={card}
                        onPinnedCard={this.handlePinned}
                        onCardHistory={onCardHistory}
                      />
                    ))}
                  </div>
                </React.Fragment>
              ) : (
                <div className="profile-header primary-font context">
                  <CommonMessage msg="There are currently no news items to display for the selected area" />
                </div>
              )}
            </Tab>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default Profile;
