import ApiService from "./tenantApiService";
import axios from 'axios';
const queueLeft = [];
const queueRight = [];

function getAvailableMonths() {
  const requestUrl = "/funnel/months";

  return ApiService.get(requestUrl).then(response => {
    return response.data;
  });
}

function getAvailableQuarters() {
  const requestUrl = "/funnel/quarters";

  return ApiService.get(requestUrl).then(response => {
    return response.data;
  });
}
function getAvailableYears() {
  const requestUrl = "/funnel/years";

  return ApiService.get(requestUrl).then(response => {
    return response.data;
  });
}

function buildForMonth(yearMonth, isLeft) {
  const requestUrl = `/funnel/build/month?yearMonth=${yearMonth}`;
  const ourRequest = addRequestToQueue(isLeft);

  return ApiService.get(requestUrl,{
    cancelToken: ourRequest.token ,
  }).then(response => {
    return response.data;
  });
}

function buildForQuarter(yearMonth, isLeft) {
  const requestUrl = `/funnel/build/quarter?yearMonth=${yearMonth}`;
  const ourRequest = addRequestToQueue(isLeft);

  return ApiService.get(requestUrl, {
    cancelToken: ourRequest.token,
  }).then(response => {
    return response.data;
  });
}

function buildForYear(yearMonth, isLeft) {
  const requestUrl = `/funnel/build/year?yearMonth=${yearMonth}`;
  const ourRequest = addRequestToQueue(isLeft);

  return ApiService.get(requestUrl, {
    cancelToken: ourRequest.token,
  }).then(response => {
    return response.data;
  });
}

function abortRequestPeriod() {
  for (let i = 0; i < queueLeft.length - 1; i++) {
    queueLeft[i].cancel();
  }
  for (let i = 0; i < queueRight.length - 1; i++) {
    queueRight[i].cancel();
  }
}

function abortRequestConcreteFunnelMetricsLeft() {
  for (let i = 0; i < queueLeft.length; i++) {
    queueLeft[i].cancel();
  }
}

function abortRequestConcreteFunnelMetricsRight() {
  for (let i = 0; i < queueRight.length; i++) {
    queueRight[i].cancel();
  }
}

function clearQueue() {
  queueLeft.length = 0;
  queueRight.length = 0;
}

function addRequestToQueue(isLeft) {
  const ourRequest = axios.CancelToken.source();

  if (isLeft) {
    queueLeft.push(ourRequest);
  } else {
    queueRight.push(ourRequest);
  }

  return ourRequest;
}

export default {
  getAvailableMonths,
  getAvailableQuarters,
  getAvailableYears,
  buildForMonth,
  buildForQuarter,
  buildForYear,
  abortRequestPeriod,
  abortRequestConcreteFunnelMetricsLeft,
  abortRequestConcreteFunnelMetricsRight,
  clearQueue,
};
