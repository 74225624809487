import React, {Component} from "react";
import {Button, Form, ListGroup, Modal} from "react-bootstrap";
import {
  filterAiAndDefineAiStatus,
  formatToMilli, getCountChangePercent,
  getPluralEnding,
  getSegmentChangeIcon,
  getSegmentChangeStyle,
  getSegmentIconClass,
  getSegmentsSort
} from "./segmentUtils";

class SegmentsSidebar extends Component {
  segmentTypeSortingOrder = ["ALL_CUSTOMERS", "AI", "USER_CREATED"];

  state = {
    show: false,
    responsiveMode: false,
    filter: 'ALL'
  };

  componentWillMount() {
    this.setState({filter: localStorage.getItem("segmentFilter") || 'ALL'});
    this.updateUserSectionState();
    window.addEventListener("resize", this.updateUserSectionState.bind(this));
  }

  updateUserSectionState() {
    if (window.outerWidth < 1024) {
      this.setState({responsiveMode: true});
    } else {
      this.setState({responsiveMode: false});
    }
  }

  handleClose = () => {
    this.setState({show: false});
  };

  handleShow = () => {
    this.props.resetData();
    this.setState({show: true});
  };

  handleClickSegment(id) {
    this.props.onSegmentSelect(id);
    this.handleClose();
  }

  setFilterType(filterType) {
    localStorage.setItem("segmentFilter", filterType);
    this.setState({filter: filterType});
  }

  getFilter() {
    switch (this.state.filter) {
      case 'SHARED':
        return (s) => s.privateSegment === false && s.customerSegmentType !== "AI";
      case 'PRIVATE':
        return (s) => s.privateSegment === true;
      case 'AI':
        return (s) => s.customerSegmentType === "AI";
    }
    return (s) => s;
  }

  renderSegmentSpend(segment) {
    return formatToMilli(segment?.currentStats?.spendInLast30DaysInSegment || 0);
  }

  renderSegmentList() {
    const {allSegments, currentSegmentId, isSearching, allCustomersSegment, enrichment, allCountOfCustomerIsLoaded} = this.props;

    const segmentsWithAiData = filterAiAndDefineAiStatus(allCustomersSegment, allSegments.filter(this.getFilter()), enrichment.ENGAGEMENT, enrichment.SPEND_BRACKET);

    return segmentsWithAiData.segments.length > 0 ? (
      <ListGroup as="ul">

        {segmentsWithAiData.segments.sort(getSegmentsSort()).map((segment) => (

          <ListGroup.Item
            as="li"
            action
            key={segment.id}
            className={segment.id === currentSegmentId && "active"}
            onClick={() => this.handleClickSegment(segment.id)}
          >
            <div>
              <span className={getSegmentIconClass(segment)}/>
              <div className="list-data">
                <h6>{segment.name} </h6>
                <span className="sub-head">
                {(!allCountOfCustomerIsLoaded && segment.customerSegmentType === 'ALL_CUSTOMERS') ?
                    (<span className="icon-distil-logo icon spin" style={{marginRight: 0}}/>) :
                    (segment.cachedCustomerCount > 0 ? segment.cachedCustomerCount.toLocaleString(navigator.language,
                          {minimumFractionDigits: 0}) : 0)} Record{getPluralEnding(segment.cachedCustomerCount)}
              </span>
              </div>
            </div>

            <div className="last-30-days-stats">
              <div className="segment-change-info">
                <strong
                  className={getSegmentChangeStyle(segment.percentageChangeLast30Days, segment.customerSegmentGrowthType)}>{getCountChangePercent(segment.percentageChangeLast30Days)}</strong>
                {" "}
                <i
                  className={`${getSegmentChangeStyle(segment.percentageChangeLast30Days, segment.customerSegmentGrowthType)} ${getSegmentChangeIcon(segment.percentageChangeLast30Days)}`}/>
              </div>
              <strong>£ {this.renderSegmentSpend(segment)}</strong>
            </div>

          </ListGroup.Item>

        ))}
      </ListGroup>
    ) : (
      <ListGroup>
        <ListGroup.Item>
          <h6>{this.getSegmentSectionMessage(isSearching, segmentsWithAiData.aiNoData, segmentsWithAiData.aiNotProcessed)}</h6>
        </ListGroup.Item>
      </ListGroup>
    );
  }

  getSegmentSectionMessage(isSearching, currAiNoData, currAiNotProcessed) {
    if (isSearching) {
      return 'Not Found';
    }

    if (this.state.filter === 'AI') {
      if (currAiNotProcessed) {
        return "Data has been provided and your A.I. Segments are being generated. They will be available shortly";
      } else if (currAiNoData) {
        return "Your account does not yet have data to create A.I. Segments";
      }
    }

    return 'There are no segments in this category';
  }

  render() {
    const {onSort, onNewSegment, onSearch, currentSegment} = this.props;
    const {filter} = this.state;
    let currentUser = JSON.parse(sessionStorage.getItem("selectedMembers"));

    return (
      <React.Fragment>
        <section className="sideNav">
          <div className="top-head">
            <div className="segment d-flex ">
              <div className="search position-relative">
                <span className="icon-search"/>
                <Form.Control
                  type="text"
                  onChange={onSearch}
                  placeholder="Search here..."
                />
              </div>
              <div className="top-icon-wrap ml-auto d-flex">
                <span className="icon-sort" onClick={onSort}/>
                {currentUser.membershipType !== 'READER' &&
                <span className="icon-add" onClick={() => onNewSegment()}/>}
              </div>
            </div>

          </div>

          <div className="filters">
            <div className="selector mb-1">
              <strong className={`p-1 ml-2 ${filter === 'ALL' ? 'active' : ''}`}
                      onClick={() => this.setFilterType('ALL')}>All</strong>
              <strong className={`p-1 ml-2 ${filter === 'AI' ? 'active' : ''}`}
                      onClick={() => this.setFilterType('AI')}>A.I.</strong>
              <strong className={`p-1 ml-2 ${filter === 'SHARED' ? 'active' : ''}`}
                      onClick={() => this.setFilterType('SHARED')}>Shared</strong>
              <strong className={`p-1 ml-2 ${filter === 'PRIVATE' ? 'active' : ''}`}
                      onClick={() => this.setFilterType('PRIVATE')}>My Segments</strong>
            </div>
            <strong className="last-30-days mr-2 ml-2">Last 30-days</strong>
          </div>

          <div className="segments-list">{this.renderSegmentList()}</div>
        </section>
        {this.state.responsiveMode && (
          <div className="mobile-head d-lg-none">
            <Button
              variant="secondary"
              className="grid-menu"
              onClick={this.handleShow}
            >
              <span className="icon-np_grid-menu_654601_000000 gridicon"/>
            </Button>
            <Modal
              show={this.state.show}
              onHide={this.handleClose}
              className="customer-data"
            >
              <Modal.Body>
                <section className="sideNav ">
                  <div className="top-head">
                    <div className="segment d-flex ">
                      <h6>Segments</h6>
                      <span
                        onClick={this.handleClose}
                        className="icon-cancel cancel-modal ml-auto"
                      />
                    </div>

                    <div className="search position-relative">
                      <span className="icon-search"/>
                      <Form.Control
                        type="text"
                        onChange={onSearch}
                        placeholder="Search  here..."
                      />
                      <span className="icon-sort" onClick={onSort}/>
                    </div>
                  </div>

                  <div className="segments-list">
                    {this.renderSegmentList()}
                  </div>
                </section>
              </Modal.Body>
            </Modal>{" "}
            <div className="head-data ">
              <h6>{currentSegment.name}</h6>
              <span
                className="sub-head">{currentSegment.cachedCustomerCount > 0 ? currentSegment.cachedCustomerCount.toLocaleString(navigator.language, {minimumFractionDigits: 0}) : 0} Records</span>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default SegmentsSidebar;
