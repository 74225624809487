import React, {Component} from "react";
import {Textbox} from "react-inputs-validation";
import {toast} from "react-toastify";
import BottomWrap from "../../../components/BottomWrap/BottomWrap";
import UserAPI from "../../../services/users";
import {BannerPanel} from "../../../components/Branding";
import AccountService from "../../../services/account";
import { emailRex } from "../../../variables/utils";


class UpdateUserInfo extends Component {
  constructor(props) {
    super(props);
    this.myFirstName = React.createRef();
    this.myLastName = React.createRef();
    this.myEmail = React.createRef();
    this.myPassword = React.createRef();
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      selectedUser: JSON.parse(sessionStorage.getItem("registeredShopUser")),
      errors: {},
      allValid: false,
      validateEmail: true,
      isInvited: false,
      isShopify: sessionStorage.getItem("isShopify")
    };
  }

  componentDidMount() {
    document.title = "Distil - Update your Distil account";
    this.getAccount();
  }

  getAccount = async () => {
    try {
      await AccountService.getAccount().then(response => {
        this.setState({
          selectedUser: response.contextUser
        })
        sessionStorage.setItem("selectedUser", JSON.stringify(response))
        if (response.orgMemberships.length === 0) {
          this.setState({
            msg: "You are not currently a member of any Organisation"
          });
        }
      });
    } catch (ex) {
      toast.success("Error");
    }
  };

  validationForm = () => {
    const firstName = this.state.firstName;
    const lastName = this.state.lastName;
    const email = this.state.email;

    const errInFirstName = this.myFirstName.current.state.err;
    const errInMyLastName = this.myLastName.current.state.err;
    const errInMyEmail = this.myEmail.current.state.err;
    this.setState({validateEmail: !errInMyEmail});
    if (
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      emailRex.test(email) &&
      !errInFirstName &&
      !errInMyLastName
    ) {
      this.setState({allValid: true});
    } else {
      this.setState({allValid: false});
    }
  };

  updateUser = async event => {
    event.preventDefault();
    let userData = this.state.selectedUser;
    console.log("userData", userData)
    userData.firstName = this.state.firstName;
    userData.lastName = this.state.lastName;
    userData.marketingEmailAddress = this.state.email;
    userData.email = this.state.selectedUser.emailAddress;
    const shopToken = sessionStorage.getItem("shopToken");
    console.log("shopToken", shopToken)

    if (this.state.allValid) {
      try {
        await UserAPI.updateShopifyUser(userData).then(_ => {
          toast.success("Changes saved successfully");
          localStorage.setItem("checkChange", false);
          window.location = "/chooseaccount?isShopify=true";
        });
      } catch (ex) {
        toast.error("Problem while saving changes");
      }
    }
  };

  render() {
    return (
      <div className="app">
        <div className="login">
          <div className="container-fluid">
            <div className="row no-gutters h-100">
              <div className="col-lg-5">
                <BannerPanel/>
              </div>
              <div className="col-lg-7">
                <div className="content-wrap bg-white">
                  <div className="content-inner-wrap col-xl-9 col-md-11">
                    <div className="top-wrap">
                      <h1 className="font-weight-heavy">Update info about your Distil account</h1>
                    </div>

                    <div className="form-wrap">
                      <form onSubmit={this.updateUser}>
                        <div className="row-wrap d-flex">
                          <div className="form-group new-form-group">
                            <label>First Name</label>
                            <Textbox
                              ref={this.myFirstName}
                              classNameInput="form-control"
                              classNameContainer="custome-input"
                              tabIndex="1" // Optional.[String or Number].Default: none.
                              name="firstName" // Optional.[String].Default: "". Input name.
                              type="text" // Optional.[String].Default: "text". Input type [text, password, number].
                              value={this.state.firstName}
                              placeholder="Your First Name here" // Optional.[String].Default: "".
                              autoComplete="off"
                              onChange={(firstName, e) => {
                                this.setState({firstName}, () => this.validationForm());
                                // console.log(e);
                              }}
                              onBlur={e => {
                                console.log(e);
                              }} // Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                              validationOption={{
                                name: "First Name", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
                                check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                                required: true // Optional.[Bool].Default: true. To determin if it is a required field.
                              }}
                            />
                          </div>

                          <div className="form-group new-form-group">
                            <label>Last name</label>
                            <Textbox
                              ref={this.myLastName}
                              classNameInput="form-control"
                              classNameContainer="custome-input"
                              tabIndex="2" // Optional.[String or Number].Default: none.
                              name="lastName" // Optional.[String].Default: "". Input name.
                              type="text" // Optional.[String].Default: "text". Input type [text, password, number].
                              value={this.state.lastName}
                              placeholder="Your Last Name here" // Optional.[String].Default: "".
                              autoComplete="off"
                              onChange={(lastName, e) => {
                                this.setState({lastName}, () => this.validationForm());
                                console.log(e);
                              }}
                              onBlur={e => {
                                console.log(e);
                              }} // Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                              validationOption={{
                                name: "Last Name", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
                                check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                                required: true // Optional.[Bool].Default: true. To determin if it is a required field.
                              }}
                            />
                          </div>
                        </div>

                        <div className="form-group new-form-group">
                          {/* For dummmy form  Start*/}
                          <input
                            id="email"
                            type="text"
                            autoComplete="off"
                            value=""
                            className="d-none"
                          ></input>
                          {/* For dummmy form  End */}
                          <label htmlFor="emails">Email</label>
                          <Textbox
                            ref={this.myEmail}
                            classNameInput={
                              this.state.validateEmail
                                ? "form-control"
                                : "react-inputs-validation__error___2aXSp  form-control"
                            }
                            classNameContainer="custome-input"
                            tabIndex="3" // Optional.[String or Number].Default: none.
                            name="emails" // Optional.[String].Default: "". Input name.
                            type="text" // Optional.[String].Default: "text". Input type [text, password, number].
                            value={this.state.email} // Optional.[String].Default: "".
                            placeholder="Your Email here" // Optional.[String].Default: "".
                            autoComplete="off"
                            onChange={(email, e) => {
                              this.setState({email, validateEmail: true}, () =>
                                this.validationForm()
                              );
                              console.log(e);
                            }} // Required.[Func].Default: () => {}. Will return the value.
                            onBlur={e => {
                              console.log(e);
                            }} // Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                            validationOption={{
                              name: "Email Address", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
                              check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                              required: true, // Optional.[Bool].Default: true. To determin if it is a required field.

                              reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // Optional.[Bool].Default: "" Custom regex.
                              regMsg: "Enter valid Email Address" // Optional.[String].Default: "" Custom regex error message.
                            }}
                          />
                          {!this.state.validateEmail && (
                            <span className="hasError">
                    An account with this email address already exists.
                  </span>
                          )}
                        </div>

                        <div className="btn-wrap">
                          <button
                            type="submit"
                            className="btn btn-primary green-btn"
                            disabled={!this.state.allValid}
                          >
                            Update Distil Account Info
                          </button>
                        </div>
                      </form>
                    </div>
                    <BottomWrap/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UpdateUserInfo;
