import React, { Component } from "react";
import no_data from "./img/nodata.svg";
import "./css/loader.css";

class NoData extends Component {
  render() {
    const { customTitle, customSubTitle, userName, innerView } = this.props;
    return userName !== undefined ? (
      <div className="noDataUi">
        <div className="noDataTitle">Hi {userName}!</div>
        <div className="noDataInfo">
          <p>
          Welcome to your Distilled newsfeed - this is where things will get
          interesting!
        </p>
        <p>
          Distil stays connected to your data and analyses trends on-the-fly.
          The results will be shown in this feed. Pin the analytics widgets you
          want to view every time you’re here. Close and mark the ones you don’t
          need. It’s simple. It’s powerful. Let’s get that data flowing.
        </p>
        </div>
      </div>
    ) : (
      <div className={`nodata ${innerView ? "inner" : ""}`}>
        <p>{customTitle ?? "There is no data in here."}</p>
        <p>{customSubTitle ?? "We’re going to need a bigger haystack..."}</p>
        <img src={no_data} alt=""/>
      </div>
    );
  }
}

export default NoData;
