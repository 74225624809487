import React, { Component } from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";

class SearchResult extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  onSelection = (id,index,name) => {
    this.props.onCustomerSelection(id,index,name);
  };

  render() {
    const { customerList, showResult } = this.props;
    // console.log(customerList);
    return (
      customerList.length > 0 &&
      showResult && (
        <div className="result">
          <ListGroup as="ul">
            {customerList.length > 0 &&
              customerList.map((item, index) => (
                <ListGroupItem
                  as="li"
                  key={item.id}
                  onClick={() => this.onSelection(item.id, index,item.firstName+" "+item.lastName)}
                >
                  {item.firstName} {item.lastName}
                </ListGroupItem>
              ))}
          </ListGroup>
        </div>
      )
    );
  }
}

export default SearchResult;
