import React, { Component } from "react";
// import Form from "react-bootstrap/Form";
// import FormControl from "react-bootstrap/FormControl";
import { Textbox } from "react-inputs-validation";

import { ListGroup, ListGroupItem } from "react-bootstrap";
class UtmInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validate: false,
      utmInfo: {
        utmOptions: [
          {
            id: 1,
            title: "UTM Source",
            value: "",
            selected: false,
            validateSource: false,
            validateHasError: true
          },
          {
            id: 2,
            title: "UTM Medium",
            value: "",
            selected: false,
            validateMedium: false,
            validateHasError: true
          },
          {
            id: 3,
            title: "UTM Campaign",
            value: "",
            selected: false,
            validateCampaign: false,
            validateHasError: true
          }
        ],
        utmAllowDistil: false,
        hasError: false
      }
    };
  }

  componentDidMount() {
    let newDestination = this.props.newDestination;
    if (newDestination.utmInfo !== undefined) {
      this.setState({ utmInfo: newDestination.utmInfo });
    }
  }

  componentWillUnmount() {
    let utmInfo = this.state.utmInfo;
    if (utmInfo.id === null) {
      utmInfo.hasError = false;
      this.setState({ utmInfo }, () =>
        this.props.onPageChange("utmInfo", utmInfo)
      );
    } else {
      this.props.onPageChange("utmInfo", this.state.utmInfo);
    }
  }

  selectUTM = (utm, index) => {
    let utmInfo = this.state.utmInfo;

    utmInfo.utmOptions[index].selected = !utm.selected;
    if (utmInfo.utmOptions[index].selected) {
      utmInfo.utmOptions[index].value = "";
      utmInfo.utmOptions[index].hasError = true;
    } else {
      utmInfo.utmOptions[index].hasError = false;
    }

    utmInfo.hasError =
      utmInfo.utmOptions.filter(utm => utm.hasError === true).length > 0;

    this.setState({utmInfo},  localStorage.setItem("checkChange", true));
  };

  updateUtmValue = (value, index) => {
    let utmInfo = this.state.utmInfo;
    utmInfo.utmOptions[index].value = value;
    utmInfo.utmOptions[index].validateHasError = true;

    this.setState({ utmInfo, validate: true });
  };

  updateUtmAllowDistil = (value, index) => {
    let utmInfo = this.state.utmInfo;
    utmInfo.utmAllowDistil = value;
    this.setState({ utmInfo });
  };

  validatingUtmCode = (res, index) => {
    localStorage.setItem("checkChange", true)
    let utmInfo = this.state.utmInfo;
    utmInfo.utmOptions[index].validateHasError = false;
    if (res) {
      utmInfo.utmOptions[index].hasError = true;
    } else {
      utmInfo.utmOptions[index].hasError = false;
    }

    utmInfo.hasError =
      utmInfo.utmOptions.filter(utm => utm.hasError === true).length > 0;

    this.setState({ utmInfo });
  };

  render() {
    const { utmInfo } = this.state;
    return (
      <div className="utm-info">
        <form>
          <div className="utm-info-top">
            <div className="form-group">
              <h4>UTM Codes and Recommendation Tracking</h4>
              <p>
                To track the effectiveness of these recommendations Distil can
                append a code to the Product or Content URL. The usual way of
                doing this is via a UTM code in the Source and Campaign
                attributes.
              </p>
            </div>

            <div className="radio-btn utm-options">
              <ListGroup>
                {utmInfo.utmOptions.map((utm, key) => (
                  <ListGroupItem active={utm.selected} key={key}>
                    <span onClick={() => this.selectUTM(utm, key)}>
                      <i className="icon-check_circle"></i>
                      <label>{utm.title}</label>
                    </span>
                    {utm.selected && (
                      <div className="radio-btn-inner">
                        <Textbox
                          ref={this.title}
                          classNameInput="form-control"
                          classNameContainer="custome-input"
                          tabIndex="1"
                          id={"utmValue"}
                          name="utmValue"
                          type="text"
                          validate={utm.validateHasError}
                          validationCallback={res =>
                            this.setState(
                              {
                                validate: false
                              },
                              () => this.validatingUtmCode(res, key)
                            )
                          }
                          value={utm.value}
                          placeholder={utm.title}
                          autoComplete="off"
                          onChange={(utmValue, e) => {
                            this.updateUtmValue(utmValue, key);
                          }}
                          onBlur={e => {}}
                          validationOption={{
                            name: utm.title + " Code",
                            check: true,
                            required: true
                          }}
                        />
                      </div>
                    )}
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          </div>
          <div className="utm-info-bottom">
            <div className="utm-info-bottom-text">
              <p>
                As UTM codes are sometimes repurposed by third-party tools
                Distil will also add its own unique code. This enables Distil
                Tracking to understand how well Recommendations are working.
                Uncheck the following box if you don’t wish to do this.
              </p>
            </div>
            <div className="utm-info-bottom-check">
              <label
                onClick={() =>
                  this.updateUtmAllowDistil(!utmInfo.utmAllowDistil)
                }
                className={utmInfo.utmAllowDistil ? "active" : ""}
              >
                <i className="icon-check"></i>
                Allow Distil to append tracking parameter to Product and Content
                URLs
              </label>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default UtmInfo;
