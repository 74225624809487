import React, {Component} from "react";
import {Button, Col, Form} from "react-bootstrap";
import DataConnectionAPI from "../../../../../services/dataConnections";
import NewConnectionMeta from "./NewConnectionMeta";
import {Select, Textbox} from "react-inputs-validation";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import {toast} from "react-toastify";
import {connectionIcons} from "../../../../../variables/globalValues.json";
import {apiPermissions} from "../../../../../variables/globalValues.json";

const initialState = {
  updatingContent: false,
  connectionTitle: "",
  connectionDesc: "",
  connectionType: "",
  iconType: "null",
  apiPermission: "null",
  connectionSettingsApiKey: "",
  apiConnectionId: null,

  connectionSettingsClientSecret: "",
  connectionSettingsServerAddress: "",
  connectionSettingsSecurityCode: "",
  connectionSettingEnabled: true,

  hasConnectionTitleError: true,
  hasConnectionDescError: true,
  hasConnectionIconError: true,
  hasApiPermissionError: true,

  validate: false,
  isLoaded: false,
  enabled: true,
  disableOnEdit: false
};
const editConnection = {
  disableOnEdit: false
};
const hasNoError = {
  hasConnectionTitleError: false,
  hasConnectionDescError: false,
  hasConnectionIconError: false,
  hasApiPermissionError: false
};

class ApiConnection extends Component {
  constructor(props) {
    super(props);
    this.state = {initialState, editConnection};
  }

  resetData = () => {
    this.setState(initialState);
    localStorage.setItem("checkChange", false);
  };

  componentDidMount() {
    this.setState(initialState);
    this.setState({connectionType: this.props.formType});
    if (this.props.editConnection) {
      this.editConnectionDefaults();
      this.setState({updatingContent: false});
      this.setState(hasNoError);
    }
  }

  componentWillReceiveProps(nextProps) {
    let checkChange = JSON.parse(localStorage.getItem("checkChange"));
    if (!checkChange) {
      if (nextProps.editConnection) {
        this.editConnectionDefaults();
      } else {
        if (this.props.editConnection) {
          this.resetData();
        }
      }
    }
  }

  editConnectionDefaults = () => {
    this.setState({
      connectionTitle: this.props.selectedConnection.name,
      connectionDesc: this.props.selectedConnection.description,
      connectionType: this.props.selectedConnection.connectionType,
      iconType: this.props.selectedConnection.iconType,
      apiPermission: this.props.selectedConnection.apiPermission,
      connectionSettingEnabled: this.props.selectedConnection.enabled,
      connectionSettingsApiKey: this.props.selectedConnection.connectionSettings.apiKey,
      connectionSettingsClientSecret: this.props.selectedConnection.connectionSettings.clientSecret,
      connectionSettingsServerAddress: this.props.selectedConnection.connectionSettings.serverAddress,
      connectionSettingsSecurityCode: this.props.selectedConnection.connectionSettings.securityCode
    });
  };

  validateForm = e => {
    e.preventDefault();
    this.setState({updatingContent: false});
    const {
      hasConnectionTitleError,
      hasConnectionDescError,
      hasConnectionIconError,
      hasApiPermissionError,
    } = this.state;

    if (
      !hasConnectionTitleError &&
      !hasConnectionDescError &&
      !hasConnectionIconError &&
      !hasApiPermissionError
    ) {
      this.addConnection();
    } else {
      console.log(hasConnectionTitleError + " " + hasConnectionDescError + " " + hasConnectionIconError + " " + hasApiPermissionError);
      toast.warn("Please enter valid data in fields");
    }
  };

  addConnection = async () => {
    this.setState({updatingContent: true});
    let formData = {
      name: this.state.connectionTitle,
      description: this.state.connectionDesc,
      connectionType: 'API_CONNECTION',
      iconType: this.state.iconType,
      apiPermission: this.state.apiPermission,
      connectionSettings: {
        apiKey: null,
        userName: null,
        password: null,
        clientSecret: this.state.connectionSettingsClientSecret,
        serverAddress: this.state.connectionSettingsServerAddress,
        securityCode: this.state.connectionSettingsSecurityCode
      },
      enabled: this.state.connectionSettingEnabled
    };

    if (this.props.editConnection) {
      let formUpdate = {
        id: this.props.selectedConnection.id,
        name: this.state.connectionTitle,
        description: this.state.connectionDesc,
        connectionType: 'API_CONNECTION',
        iconType: this.state.iconType,
        apiPermission: this.state.apiPermission,
        enabled: this.state.connectionSettingEnabled,
        connectionSettings: {
          apiKey: this.state.connectionSettingsApiKey,
          userName: null,
          password: null,
          clientSecret: this.state.connectionSettingsClientSecret,
          serverAddress: this.state.connectionSettingsServerAddress,
          securityCode: this.state.connectionSettingsSecurityCode
        }
      };

      try {
        await DataConnectionAPI.updateConnection(
          formUpdate,
          this.props.selectedConnection.id
        ).then(response => {
          toast.success("Connection updated successfully");
          this.resetData();

          this.props.onAddingConnection(response.id);
        });
      } catch (err) {
        toast.error("Connection updating error");
      }
    } else {
      try {
        await DataConnectionAPI.newConnection(formData).then(response => {
          toast.success("Connection created successfully");
          this.resetData();
          this.props.onAddingConnection(response.id);
        });
      } catch (err) {
        toast.error("Connection creation error");
      }
    }
    this.setState({updatingContent: false});
  };

  copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    toast.success("Copied to clipboard")
  };

  onCheckChange = () => {
    this.setState(
      { isLoaded: false },
      this.props.onCheckChange()
    );
    this.props.onCheckChange()
  };

  render() {
    const {
      connectionTitle,
      connectionDesc,
      iconType,
      apiPermission,
      validate,
      updatingContent,
      disableOnEdit,
      connectionSettingsApiKey
    } = this.state;

    return (
      <React.Fragment>
        <NewConnectionMeta
          formType={this.props.formType}
          onBackPress={this.props.onBackPress}
          editConnection={this.props.editConnection}
          helpArticleUri="/articles/10759958078493-Distil-API"
        />
        <Form onSubmit={this.validateForm}>
          <Form.Row>
            <Form.Group as={Col} controlId="formGridName">
              <Form.Label>Title</Form.Label>
              <Textbox
                classNameInput="form-control"
                classNameContainer="custome-input"
                tabIndex="1"
                id={"connectionTitle"}
                name="connectionTitle"
                type="text"
                validate={validate}
                validationCallback={res =>
                  this.setState({
                    hasConnectionTitleError: res,
                    validate: false
                  })
                }
                value={connectionTitle}
                onChange={(connectionTitle, e) => {
                  this.setState({connectionTitle});
                  this.setState({ updatingContent: false, isConnectionVerified: false }, () =>
                    this.onCheckChange()
                  );
                }}
                onBlur={e => {
                }}
                validationOption={{
                  name: "Title",
                  check: true,
                  required: true
                }}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridDesc">
              <Form.Label>Description</Form.Label>
              <Textbox
                classNameInput="form-control"
                classNameContainer="custome-input"
                tabIndex="2"
                id={"connectionDesc"}
                name="connectionDesc"
                type="text"
                validate={validate}
                validationCallback={res =>
                  this.setState({hasConnectionDescError: res, validate: false})
                }
                value={connectionDesc}
                onChange={(connectionDesc, e) => {
                  this.setState({connectionDesc}, () => this.onCheckChange());
                }}
                onBlur={e => {
                }}
                validationOption={{
                  name: "Description",
                  check: true,
                  required: true
                }}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="formGridIP">
              <Form.Label>Connection Icon</Form.Label>
              <Select
                name={"connectionIconSelector"}
                tabIndex="2"
                value={iconType}
                optionList={connectionIcons}
                classNameSelect="select-control"
                classNameWrapper="form-control select-control-wrapper selection-type"
                classNameContainer=""
                classNameOptionListContainer="select-control-option"
                classNameOptionListItem=""
                validate={validate}
                validationCallback={res =>
                  this.setState({hasConnectionIconError: res, validate: false})
                }
                customStyleSelect={{}}
                customStyleWrapper={{}}
                customStyleContainer={{}}
                customStyleOptionListContainer={{
                  maxHeight: "300px",
                  overflow: "auto"
                }}
                customStyleOptionListItem={{}}
                onChange={(condition, e) => {
                  this.setState({iconType: condition},() => this.onCheckChange());
                }}
                onBlur={e => {
                }}
                validationOption={{
                  name: "Connection Icons",
                  check: true,
                  required: true
                }}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridIP">
              <Form.Label>Api Permission</Form.Label>
              <Select
                  name={"apiPermissionsSelector"}
                  tabIndex="3"
                  value={apiPermission}
                  optionList={apiPermissions}
                  classNameSelect="select-control"
                  classNameWrapper="form-control select-control-wrapper selection-type"
                  classNameContainer=""
                  classNameOptionListContainer="select-control-option"
                  classNameOptionListItem=""
                  validate={validate}
                  validationCallback={res =>
                      this.setState({hasApiPermissionError: res, validate: false})
                  }
                  customStyleSelect={{}}
                  customStyleWrapper={{}}
                  customStyleContainer={{}}
                  customStyleOptionListContainer={{
                    maxHeight: "300px",
                    overflow: "auto"
                  }}
                  customStyleOptionListItem={{}}
                  onChange={(condition, e) => {
                    this.setState({apiPermission: condition},() => this.onCheckChange());
                  }}
                  onBlur={e => {
                  }}
                  validationOption={{
                    name: "Api Permission",
                    check: true,
                    required: true
                  }}
              />
            </Form.Group>
          </Form.Row>

          {this.props.editConnection && <Form.Row>
            <Form.Group as={Col}
                        data-tooltip={"Click to copy"}
                        onClick={() => this.copyToClipboard(connectionSettingsApiKey)}
            >
              <Form.Label>Api Key</Form.Label>
              <Textbox
                name={"ApiConnectionKey"}
                tabIndex="2"
                value={connectionSettingsApiKey}
                type="text"
                classNameContainer=""
                customStyleOptionListContainer={{
                  maxHeight: "300px",
                  overflow: "auto"
                }}

              />
            </Form.Group>
          </Form.Row>}

          <Form.Row className="btn-wrap">
            <Button
              variant="primary"
              disabled={updatingContent}
              type="submit"
            >
              Done
            </Button>

            {!disableOnEdit && (
              <Button
                variant="secondary"
                onClick={this.props.onCancelConnection}
              >
                <span className="icon-cancel icon"/>
                Cancel
              </Button>
            )}
          </Form.Row>
        </Form>
      </React.Fragment>
    );
  }
}

export default ApiConnection;
