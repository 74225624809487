import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Checkbox } from "react-inputs-validation";
import CustomerService from "../../../src/services/customerDetail";
import _ from "lodash";

import { globalConstants } from "../../variables/globalVariables";

class HelpModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      contextUser: "",
      checkSupported: false,
      links: [
        {
          id: 1,
          name: "Drip",
          url: "/integrations/integrations-directory/drip/",
          image: require("./images/drip.jpg")
        },
        {
          id: 2,
          name: "Mailchimp",
          url: "/integrations/integrations-directory/mailchimp/",
          image: require("./images/mailchimp.jpg")
        },
        {
          id: 3,
          name: "Customer.io",
          url: "/integrations/integrations-directory/customer-io/",
          image: require("./images/customer-io.jpg")
        },
        {
          id: 4,
          name: "Mandrill",
          url: "/integrations/integrations-directory/mandrill/",
          image: require("./images/mandrill.jpg")
        },
        {
          id: 5,
          name: "Salesforce",
          url: "/integrations/integrations-directory/salesforce/",
          image: require("./images/salesforce.jpg")
        },
        {
          id: 6,
          name: "3dcart",
          url: "/integrations/integrations-directory/3dcart/",
          image: require("./images/3dcart.jpg")
        },
        {
          id: 7,
          name: "Autopilot",
          url: "/integrations/integrations-directory/autopilot/",
          image: require("./images/autopilot.jpg")
        },
        {
          id: 8,
          name: "Bigcommerce",
          url: "/integrations/integrations-directory/bigcommerce/",
          image: require("./images/bigcommerce.jpg")
        },
        {
          id: 9,
          name: "Chargebee",
          url: "/integrations/integrations-directory/chargebee/",
          image: require("./images/chargebee.jpg")
        },
        {
          id: 10,
          name: "Ecwid",
          url: "/integrations/integrations-directory/ecwid/",
          image: require("./images/ecwid.jpg")
        },
        {
          id: 11,
          name: "Eventbrite",
          url: "/integrations/integrations-directory/eventbrite/",
          image: require("./images/eventbrite.jpg")
        },
        {
          id: 12,
          name: "Facebook Lead Ads",
          url: "/integrations/integrations-directory/facebook-lead-ads/",
          image: require("./images/facebook-lead-ads.jpg")
        },
        {
          id: 13,
          name: "Google Analytics",
          url: "/integrations/integrations-directory/google-analytics",
          image: require("./images/googleAnalytics.jpg")
        },
        {
          id: 14,
          name: "Help Scout",
          url: "/integrations/integrations-directory/help-scout/",
          image: require("./images/help-scout.jpg")
        },
        {
          id: 4,
          name: "HubSpot",
          url: "/integrations/integrations-directory/hubspot/",
          image: require("./images/hubspot.jpg")
        },
        {
          id: 16,
          name: "Insightly",
          url: "/integrations/integrations-directory/insightly/",
          image: require("./images/insightly.jpg")
        },
        {
          id: 17,
          name: "Intercom",
          url: "/integrations/integrations-directory/intercom/",
          image: require("./images/intercom.jpg")
        },
        {
          id: 18,
          name: "Paypal",
          url: "/integrations/integrations-directory/paypal/",
          image: require("./images/paypal.jpg")
        },
        {
          id: 19,
          name: "Quickbooks",
          url: "/integrations/integrations-directory/quickbooks/",
          image: require("./images/quickbooks.jpg")
        },
        {
          id: 20,
          name: "Sage",
          url: "/integrations/integrations-directory/sage/",
          image: require("./images/sage.jpg")
        },
        {
          id: 21,
          name: "Shopify",
          url: "/integrations/integrations-directory/shopify/",
          image: require("./images/shopify.jpg")
        },
        {
          id: 22,
          name: "Stripe",
          url: "/integrations/integrations-directory/stripe/",
          image: require("./images/stripe.jpg")
        },
        {
          id: 23,
          name: "Trustpilot",
          url: "/integrations/integrations-directory/trustpilot/",
          image: require("./images/trustpilot.jpg")
        },
        {
          id: 24,
          name: "Xero",
          url: "/integrations/integrations-directory/xero/",
          image: require("./images/xero.jpg")
        },
        {
          id: 25,
          name: "Zapier",
          url: "/integrations/integrations-directory/zapier/",
          image: require("./images/zapier.jpg")
        },
        {
          id: 26,
          name: "Zendesk",
          url: "/integrations/integrations-directory/zendesk/",
          image: require("./images/zendesk.jpg")
        },
        {
          id: 27,
          name: "Zoho",
          url: "/integrations/integrations-directory/zoho/",
          image: require("./images/zoho.jpg")
        },
        {
          id: 28,
          name: "DotDigital",
          url: "/integrations/integrations-directory/dotdigital/",
          image: require("./images/dotdigital.jpg")
        },
        {
          id: 29,
          name: "Klaviyo",
          url: "/integrations/integrations-directory/klaviyo/",
          image: require("./images/klaviyo.jpg")
        },
        {
          id: 30,
          name: "Sendgrid",
          url: "/integrations/integrations-directory/sendgrid/",
          image: require("./images/sendgrid.jpg")
        },
        {
          id: 31,
          name: "GoogleAds",
          url: "/integrations/integrations-directory/googleads/",
          image: require("./images/adwords.jpg")
        }
      ]
    };
  }
  componentDidMount() {
    this.setState({
      showModal: this.props.showHelpModal,
      contextUser: this.props.contextUser,
      selectedMembers: this.props.selectedMembers,
      checkSupported: this.props.selectedMembers.supportedOnboarding
    });
    this.setState({ links: _.sortBy(this.state.links, ["name"]) });
  }

  onCloseModal = () => {
    this.props.onCloseModal();
    this.setState({ showModal: false });
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.showHelpModal) {
      this.setState({ showModal: nextProps.showHelpModal });
    }
  }

  updateOrganisation = async () => {
    console.log(this.state.contextUser);
    const supportedOnboarding = {
      supportedOnboarding: this.state.checkSupported
    };
    try {
      await CustomerService.updateOrganisation(
        supportedOnboarding,
        this.state.selectedMembers.id
      ).then(response => {
        // console.log(userAccountData);
      });
    } catch (ex) {
      console.log(ex.response);
    }
  };

  render() {
    const { selectedMembers } = this.props;
    const { checkSupported } = this.state;
    return (
      <div className="help-modal">
        <Modal
          show={this.state.showModal}
          onHide={this.onCloseModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="help-modal"
        >
          {selectedMembers.membershipType === "ADMIN" ||
          selectedMembers.membershipType === "TECHNICAL" ||
          selectedMembers.membershipType === "CONTRIBUTOR" ? (
            <Modal.Header closeButton>
              <Modal.Title>Hi {this.props.contextUser.firstName}!</Modal.Title>
              <p>
              Here are a few links to help you get the best out of Distil.ai.
              </p>
              <p>
              <a title="What is a customer data layer" href={globalConstants.HELP_BASE_URL + "/onboarding_guide/what-is-a-customer-data-layer/"} target="_blank">What is a Customer Data Layer</a> 
              {" "}explains the concepts behind Distil.ai
              </p>
              <div className="top-content-wrap">
                  <p>
                  If you are just starting with Distil.ai <a title="Supported Onboarding" href={globalConstants.HELP_BASE_URL + "/onboarding_guide/supported-onboarding/"} target="_blank">Supported Onboarding</a> 
                  {" "}will take you from zero to a fully fledged data-driven hero.
                  </p>

                <p>
                <a title="Onboarding Documentation" href={globalConstants.HELP_BASE_URL + "/onboarding_guide/onboarding-guide/"} target="_blank">Onboarding Documentation</a> 
                {" "}explains how to build your Customer Data Layer.
                </p>

                <div className="content-box ">
                  <span className="h6">
                    How about{" "}
                    <Link
                      to={{
                        pathname: "/settings/users",
                        state: {
                          selectedKey: "invites"
                        }
                      }}
                      // to="/settings/users"
                      onClick={() => {
                        this.onCloseModal();
                        this.props.onPageChange("Settings");
                      }}
                    >
                      Inviting Other Users?{" "}
                    </Link>{" "}
                  </span>
                  <p>
                  Start building your Distil.ai Team. Invite others to contribute and collaborate. Grant access to 
                  your engineering team to help with the setup process.{" "}
                  </p>
                </div>
              </div>
            </Modal.Header>
          ) : (
            <Modal.Header closeButton>
              <Modal.Title>Hi {this.props.contextUser.firstName}!</Modal.Title>
              <p>
                Here are few pointers to get you started - follow this popular
                link on{" "}
                <a
                  href={globalConstants.HELP_BASE_URL + "/distil_user_guide/distil-user-guide/"} 
                  target="_blank"
                >
                  {" "}
                  How to Use Distil
                </a>
                <br /> We know working with customer data is complex so feel
                free to ask us for help! We are keen to ensure your Distil goals
                are fully understood and data is there to deliver.
              </p>
            </Modal.Header>
          )}
          <Modal.Body>
            <div className="body-content">
              <div className="data-wrap">
                <div className="content-box ">
                  <span className="h6">Bring your data together in one place </span>
                  <p>
                  With Distil.ai you can build your <a title="Customer Data Layer" href={globalConstants.HELP_BASE_URL + "/onboarding_guide/what-is-a-customer-data-layer/"} target="_blank">Customer Data Layer</a> a single list of all the Customers you need to talk to in one place.
                  </p>
                  {selectedMembers.membershipType === "ADMIN" ||
                  selectedMembers.membershipType === "TECHNICAL" ||
                  selectedMembers.membershipType === "CONTRIBUTOR" ? (
                    <p>
                      We can integrate over 1,500 systems, either via the{" "}
                      <Link
                        to={"/settings/integrations"}
                        onClick={() => {
                          this.onCloseModal();
                          this.props.onPageChange("Settings");
                        }}
                      >
                        Settings/integrations
                      </Link>
                      {" "}section or through <a title="Supported Onboarding" href={globalConstants.HELP_BASE_URL + "/onboarding_guide/supported-onboarding/"} target="_blank">Supported Onboarding</a>.
                    </p>
                  ) : (
                    <p>
                      We can integrate over 1,500 systems through <a title="Supported Onboarding" href={globalConstants.HELP_BASE_URL + "/onboarding_guide/supported-onboarding/"} target="_blank">Supported Onboarding</a>.
                    </p>
                  )}
                </div>
                <p>
                  If you can’t see the integration listed - chances are we can
                  integrate it for you anyway - so please just get in touch{" "}
                  <a href="mailto:support@distil.ai">support@distil.ai </a>{" "}
                </p>
              </div>

              <div className="connection-list">
                {this.state.links.map((items, idx) => (
                  <div className="connect-name" key={idx}>
                    {items.url !== "" ? (
                      <a
                      title={items.name}
                        href={globalConstants.HELP_BASE_URL + items.url}
                        target="_blank"
                      >
                        <div className="icon-wd">
                          <img src={items.image} title={items.name}/>
                        </div>
                        <span>{items.name}</span>
                      </a>
                    ) : (
                      <React.Fragment>
                        <div className="icon-wd" title={items.name}>
                        <img src={items.image} title={items.name}/>
                        </div>
                        <span>{items.name}</span>
                      </React.Fragment>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={this.onCloseModal }>
                Close
            </Button>
            <Button variant="primary">
                Save Changes
            </Button>
            </Modal.Footer> */}
        </Modal>
      </div>
    );
  }
}

export default HelpModal;
