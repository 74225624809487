import React, {Component} from "react";
import "./assets/css/styles.scss";
import img from "../Customers/assets/img/screen.png";
import funnelAnalytics from "./assets/img/funnel-analytics.svg";
import funnelAnalyticsMobile from "./assets/img/funnel-analytics-mobile.svg";
import checkIcon from "../../../assets/img/check-mark-icon.svg";
import "../../../assets/css/_empty-pages.scss";
import AccountAPI from "../../../services/account";
import { globalConstants } from "../../../variables/globalVariables";
import Loader from "../../../components/Loader/loader";

class FunnelMetricsEmpty extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, loading: false };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.activateTrial = this.activateTrial.bind(this);
  }

  activateTrial() {
    let selectedMembers = JSON.parse(sessionStorage.getItem("selectedMembers"));
    this.setState({ loading: true });
    // ACTIVATED_TRIAL status has id 1
    AccountAPI.activateTrialModeWithUpdateStorage(selectedMembers.id).then();
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  changeButton() {
    let selectedMembers = JSON.parse(sessionStorage.getItem("selectedMembers"));
    let accountType = selectedMembers.accountType;
    return accountType === 'NEW_SIGN_UP';
  }

  redirectWithinTheWebSite = (route) => {
    this.props.history.push(route);
  }

  render() {
    const { width, loading } = this.state;

    return (
        <div className="funnel-metrics-wrap bg-white lock-screen-component">
          <div className=" content-width new-top content">
            <div className="flex-block">
              <div className="top-text flex-block-item">
                <h3 className="font-weight-Avenirmedium mb-4">
                  Give Marketing credit where credit’s due
                </h3>
                <p className="font-on-top primary-font">
                  Yes, this is the blame game – in a good way.<br/><br/>

                  Attribution to “marketing” isn’t just people clicking on your ad.
                  A customer's road from first encounter to final click can be long and winding,
                  or short and sweet. It includes everything that can reasonably be laid at Marketing’s door: emails,
                  campaigns, ads, affiliate activity, SMS, display advertising, print - the list goes on, even where
                  there’s no direct clickthrough to your store - so why just measure the final step?<br/><br/>

                  With Distil's Funnel Analytics you'll know which channels to increase investment in and which need
                  to be downgraded or withdrawn from the mix altogether.<br/><br/>

                </p>
                <div className="btn-wrap">
                  {this.changeButton() ?
                      (<>
                          <button
                            type="submit"
                            className="btn btn-primary black-btn  mt-1"
                            onClick={this.activateTrial}
                            disabled={loading}
                        >
                          Start a free {globalConstants.TRIAL_PERIOD_DAYS} day trial
                        </button>
                        { loading && <Loader/> }
                      </> ):
                      <button
                          type="submit"
                          className="btn btn-primary green-btn mt-1"
                          onClick={() => this.redirectWithinTheWebSite('/settings/billing')}
                      >
                        Choose a Billing plan
                      </button>
                  }
                </div>
                  <div className="content-items">
                    <div className="content-item">
                      <div className="content-item-icon">
                        <img src={checkIcon} alt="checkIcon"/>
                      </div>
                      <div>
                        <p className="content-item-text">
                          <b>Full Funnel Visualisation</b> showing how each channel acquires, converts and retains.
                        </p>
                      </div>
                    </div>
                    <div className="content-item">
                      <div className="content-item-icon">
                        <img src={checkIcon} alt="checkIcon"/>
                      </div>
                      <div>
                        <p className="content-item-text">
                          <b>Conversion Metrics</b> for every stage in your funnel; percentage,
                          time and how each channel is driving your business.
                        </p>
                      </div>
                    </div>
                    <div className="content-item">
                      <div className="content-item-icon">
                        <img src={checkIcon} alt="checkIcon"/>
                      </div>
                      <div>
                        <p className="content-item-text">
                          <b>Return on spend</b> for both direct purchases and LTV Customer acquisition.
                          Don’t shut off a channel that’s bringing new Customers just because they have a last click somewhere else.
                        </p>
                      </div>
                    </div>
                    <div className="content-item">
                      <div className="content-item-icon">
                        <img src={checkIcon} alt="checkIcon"/>
                      </div>
                      <div>
                        <p className="content-item-text">
                          <b>Linear Attribution Model </b>
                          that gives you a true representation of how each channel is performing - not channel owner bias.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <div className="content-image">
              <img src={ width < 1250 ? funnelAnalyticsMobile : funnelAnalytics } alt="funnelAnalytics"/>
            </div>
          </div>
        </div>
    );
  }
}

export default FunnelMetricsEmpty;
