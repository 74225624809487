import React, { Component } from "react";
import AuthenticationService from "../../../services/authentication";
import queryString from "query-string";
import { toast } from "react-toastify";
import InvitaionResult from "./InvitaionResult";
import Loader from "../../../components/Loader/loader";
import BannerPanel from '../../../components/Branding/BannerPanel/BannerPanel';

class JoinUser extends Component {
  state = {
    isLoading: true,
    result: null
  };

  componentDidMount() {
    const token = queryString.parse(this.props.location.search);
    if (token.token !== undefined && token.token !== "") {
      this.setState({ token: token.token });
      this.onVerify(token.token);
    }
  }

  onVerify = async token => {
    try {
      await AuthenticationService.userAuthentiction(token).then(response => {
        
        if (response.inviteResponse === "VERIFICATION_APPLIED") {
      
          toast.success("Email Verified");

          //Go directly to the signup page - passing the data and token
          this.props.history.push({
            pathname: '/signup',
            state: { invitedUser: response, token }
          });
        }
        
        this.setState({ isLoading: false, result: "success", invitedUserData: response });
        sessionStorage.removeItem("selectedMembers");
      });
    } catch (ex) {
      toast.error("Verification failed.", ex.response.data.message);
    }
  };

  render() {

    const {inviteResponse, orgName} = this.state.invitedUserData || {};

    const result = {
      EXPIRED: {
        title: "Your invitation has expired",
        description:
        `Your invitation to join ${orgName} has expired. Please contact the person who invited you for a new invite. The new link will be valid for 24 hours. `,
        actionLabel: "Create Account",
        actionPath: "/signup"
      },
      NOT_SENT: {
        title: "Token does not exist",
        description:
          `The token used in the link does not exists.  Please contact the person who invited you for a new invite. `,
        actionLabel: "Login",
        actionPath: "/login"
      },
      DEFAULT: {
        title: "There has been an issue",
        description:
          `Your invitation to join ${orgName} has not succeeded. Please contact the person who invited you for a new invite. The link will be valid for 24 hours. `,
        actionLabel: "Login",
        actionPath: "/login"
      },
      ACCOUNT_LINKED: {
        title: "Your invitation has been verified",
        description: `Your invitation has been verified - you are now a member of ${orgName}.  Please login and choose your new account`,
        actionLabel: "Login",
        actionPath: "/login"
      }
    };
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="app">
        <div className="login">
          <div className="container-fluid">
            <div className="row no-gutters h-100">
              <div className="col-lg-5">
                <BannerPanel/>
              </div>
              <div className="col-lg-7">
              <InvitaionResult data={result[inviteResponse] || result.DEFAULT} />
              </div>
            </div>
          </div>
        </div>
      </div>
      ) 
    }
  }
}

export default JoinUser;
