import React, {Component} from 'react';
import {toast} from 'react-toastify';
import _, {first, get} from 'lodash';
//services
import CustomersAPI from '../../../../services/customers';
import EnrichmentAPI from '../../../../services/enrichments';
import {Button, ButtonGroup} from 'react-bootstrap';
//components
import SegmentsSidebar from './segmentsSidebar';
import View from '../common/DataView/view';
import NewSegment from './segment/newSegment';
import Loader from '../../../../components/Loader/loader';
import Analytics from './Analytics/Analytics';
import SweetAlert from 'react-bootstrap-sweetalert';
import {getPluralEnding} from "./segmentUtils";
import queryString from 'query-string';
import SegmentCustomersDataGrid from "./DataGrid/SegmentCustomersDataGrid";
let pollingBeingBuild = '';

class CustomersData extends Component {
  state = {
    isLoading: false,
    viewType: 'analytics',
    segments: [],
    dataSchema: null,
    filtered: [],
    dataViews: [],
    dataView: [],
    enrichment: {},
    sortBy: 'asc',
    segmentFirst: null,
    isNewSegment: false,
    currentSegmentId: null,
    detailSegmentId: null,
    isSearching: false,
    isLoadPreference: false,
    segmentsIsLoaded: false,
    allCountOfCustomerIsLoaded: false,
    customersCount: null,
    dataViewId: null,
    savedCount: false,
    isUpdateState: false,
    currentPreferences: {
      lastSelectedCustomerDataViewId: null,
      lastSelectedCustomerSegmentId: null
    },
    hasUserPreference: false,
    currentSegment: [],
    csvDownloading: false,
    showModel: false
  };

  componentWillMount() {
    this.handleUserPreferences();
  }

  handleUserPreferences = async event => {
    try {
      CustomersAPI.getUserPreferences().then(response => {
        this.setState(
          {
            currentPreferences: response,
            isLoadPreference: true,
          }, () => {
            if (this.state.segmentsIsLoaded) {
                this.setPreferences();
            }
          }
        );
      });
      this.getSegments();
      this.getCustomerCount();
    } catch (err) {
    }
  };

  getSegments = () => {
    try {
      CustomersAPI.getSegmentsWithRules().then(segmentsWithRules => {
          EnrichmentAPI.getEnrichmentMinimalData().then(enrichment => {
            this.setState({
              enrichment,
              segments: segmentsWithRules,
              filtered: segmentsWithRules,
              isLoading: true,
              show: false,
            },() => {
              return this.getCustomersSchema();
            })
          })
        }
      );
    } catch (err) {
    }
  };

  updateSegments = (list, count) => {
    let item = list.find(x => x.customerSegmentType === 'ALL_CUSTOMERS');
    item.cachedCustomerCount = count;
    return item;
  };

  getCustomerCount = () => {
    try {
      CustomersAPI.getCustomersCount().then(customersCount => {
        this.setState({
          customersCount,
          allCountOfCustomerIsLoaded: true,
        })});
    } catch (err) {
    }
  };

  getCustomersSchema = async event => {
    try {
      await CustomersAPI.getCustomersSchema()
        .then(response => {
          this.setState({
            dataSchema: response,
            segmentsIsLoaded: true,
          });
        })
        .then(() => {
          if (this.state.isLoadPreference) {
            this.setPreferences()
          }
        });
    } catch (err) {
    }
  };

  componentDidUpdate(prevProps, prevState) {
    let {segments, filtered, savedCount, customersCount} = this.state;

    if (customersCount != null && segments.length && filtered.length && !savedCount) {
      this.updateSegments(segments, customersCount);
      this.updateSegments(filtered, customersCount);

      this.setState({
        savedCount: true,
      });
      this.setPreferences();
    }
  }

  setPreferences = () => {
    let params = queryString.parse(this.props.location.search);

    let segment = this.state.segments.find((x) => {
      return x.sectionPath === params.section;
    })

    if (segment) {
      this.setState(
          {
            dataViewId: +segment.id,
            detailSegmentId: +segment.id,
            currentSegmentId: +segment.id,
            hasUserPreference: true
          },
          () => {
            this.setCurrentSegment(+segment.id);
            localStorage.removeItem('redirectCustomerScreenSection');
          }
      );
    } else {
      this.setState(
        {
          dataViewId: this.state.currentPreferences
            .lastSelectedCustomerDataViewId,
          detailSegmentId: this.state.currentPreferences
            .lastSelectedCustomerSegmentId,
          currentSegmentId: this.state.currentPreferences
            .lastSelectedCustomerSegmentId,
          hasUserPreference: true
        },
        () =>
          this.setCurrentSegment(
            this.state.currentPreferences.lastSelectedCustomerSegmentId
          )
      );
    }
  };

  setCurrentSegment = id => {
    if (id === null) {
      this.props.onContextChange('CUSTOMER');
    } else {
      this.props.onContextChange('CUSTOMER_SEGMENT');
    }

    let currentSegment;
    if (id === null) {
      currentSegment = first(this.state.filtered.filter(item => item.customerSegmentType === 'ALL_CUSTOMERS'));
    } else {
      currentSegment = first(this.state.filtered.filter(item => item.id === id));

      if(!currentSegment) {
        currentSegment = first(this.state.filtered.filter(item => item.customerSegmentType === 'ALL_CUSTOMERS'));
      }
    }

    this.props.onContextIdChange(currentSegment?.id);
    this.setState({currentSegment: currentSegment});
  };

  startpolling = () => {
    let currentBuildStatus = get(
      this.state,
      'currentSegment.customerSegmentBuildStatus'
    );

    if (currentBuildStatus !== 'READY') {
      this.polling_Being_Build(this.state.currentSegment.id);
    } else {
      clearInterval(pollingBeingBuild);
    }
  };

  polling_Being_Build = id => {

    clearInterval(pollingBeingBuild);

    pollingBeingBuild = setInterval(() => {
      this.pollingBeingBuild(id);
    }, 2000);

  };

  pollingBeingBuild = async id => {
    try {
      await CustomersAPI.getSingleSegment(id).then(response => {

        if (response.customerSegmentBuildStatus === 'READY') {
          const filtered = this.state.filtered;
          const segments = this.state.segments;
          filtered[filtered.findIndex(item => item.id === response.id)] = response;
          segments[segments.findIndex(item => item.id === response.id)] = response;
          this.setState({
            currentSegment: response,
            filtered,
            segments
          });
          clearInterval(pollingBeingBuild);
        }
      });
    } catch (err) {
    }
  };

  componentWillUnmount() {
    clearInterval(pollingBeingBuild);
  }

  getAllCustomersSegment() {
    return this.state.segments.find(s => s.customerSegmentType === 'ALL_CUSTOMERS');
  }

  handleUpdateUserPreferences = async event => {
    try {
      await CustomersAPI.updateUserPreferences(
        this.state.currentPreferences
      ).then(response => {
        this.setState({
          currentPreferences: response
        });
      });
    } catch (err) {
    }
  };

  handleCurrentView = (viewID, status) => {
    const currentPreferences = this.state.currentPreferences;
    currentPreferences.lastSelectedCustomerDataViewId = viewID;

    this.setState(
      {dataViewId: viewID, isUpdateState: status, currentPreferences},
      () => this.handleUpdateUserPreferences()
    );
  };

  handleSearch = (e = null) => {
    let newList;
    // check for undefined, null and empty string
    let isSearching = !!e?.target?.value;

    if (isSearching) {
      newList = this.state.segments.filter(item => {
        const lc = item.name.toLowerCase();
        const filter = e.target.value.toLowerCase();
        return lc.includes(filter);
      });
    } else {
      newList = this.state.segments;
    }
    this.setState({
      filtered: newList,
      isSearching: isSearching
    });
  };

  onResetData = () => this.setState({filtered: this.state.segments});

  sortSegment = () => {
    this.setState({sortBy: this.getSortType()});
  };

  getSortType = () => {
    return this.state.sortBy === 'asc' ? 'desc' : 'asc';
  }

  handleNewSegment = (type = 'new') => {

    clearInterval(pollingBeingBuild);
    this.handleSearch();

    if (type === 'new') {
      this.setState({detailSegmentId: null});
    } else {
      this.setState({detailSegmentId: this.state.currentSegmentId});
    }

    this.setState({isNewSegment: true});
  };
  handleUpdateSegment = (id, status, data, select = true) => {
    const filtered = this.state.filtered;
    const segments = this.state.segments;
    const segmentIndex = filtered.findIndex(item => item.id === id);

    filtered[segmentIndex] = data;
    segments[segments.findIndex(item => item.id === id)] = data;

    this.setState(
      {filtered, segments}
    );

    if (this.state.currentSegmentId === id) {
      this.setState({currentSegment: filtered[segmentIndex]})
    }

    if (select) {
      this.setState({
        currentSegment: filtered[segmentIndex],
        isNewSegment: false,
        currentSegmentId: id,
        detailSegmentId: id,
        isUpdateState: status
      })
    }

    localStorage.setItem('checkChange', false);
  }

  saveNewSegment = (data, status) => {
    const filtered = [...this.state.filtered, data];
    const segments = [...this.state.segments, data];
    var currentPreferences = this.state.currentPreferences;
    currentPreferences.lastSelectedCustomerSegmentId = data.id;
    this.setState(
      {
        filtered,
        segments,
        isNewSegment: false,
        currentSegmentId: data.id,
        detailSegmentId: data.id,
        isUpdateState: status,
        currentSegment: data,
        currentPreferences
      },
      () => this.handleUpdateUserPreferences()
    );
    localStorage.setItem('checkChange', false);
    this.polling_Being_Build(data.id);
  };

  handleSegmentSelect = id => {
    var filtered = [...this.state.filtered];

    var currentPreferences = this.state.currentPreferences;
    var currentSegmentId = filtered.filter(segment => segment.id === id);
    currentPreferences.lastSelectedCustomerSegmentId = currentSegmentId[0].id;
    this.setCurrentSegment(currentSegmentId[0].id);
    this.setState(
      {
        currentSegmentId: currentSegmentId[0].id,
        detailSegmentId: currentSegmentId[0].id,
        currentPreferences,
        isUpdateState: false
      },
      () => this.handleUpdateUserPreferences()
    );
  };

  triggerBack = currentSegmentId => {
    let checkChange = JSON.parse(localStorage.getItem('checkChange'));

    if (checkChange) {
      this.setState({showModel: true, targetConnection: currentSegmentId});
    } else {
      this.getCustomersSchema();
      if (currentSegmentId !== null) {
        this.setState({
          isNewSegment: false,
          currentSegmentId: currentSegmentId,
          detailSegmentId: currentSegmentId
        });
      } else {
        this.setState({
          isNewSegment: false,
          currentSegmentId: this.state.filtered[0].id,
          detailSegmentId: this.state.filtered[0].id
        });
      }
    }
  };

  handleDiscard = () => {
    localStorage.setItem('checkChange', false);
    this.setState({showModel: false});
    this.triggerBack(this.state.targetConnection);
  };

  handleViewName = e => {
    let newDataViewContent = this.state.newDataViewContent;
    newDataViewContent.name = e.target.value;
    this.setState({newDataViewContent});
  };

  handleDeleteSegment = async id => {
    this.setState({isLoading: false});
    try {
      await CustomersAPI.delteSegment(id).then(response => {
        let segments = this.state.segments.filter(segment => segment.id !== id);
        let allCustomersSegment = segments.find(s => s.customerSegmentType === 'ALL_CUSTOMERS');
        localStorage.setItem("segmentFilter", "ALL")

        this.setState({
          segments,
          filtered: segments,
          isNewSegment: false,
          currentSegmentId: allCustomersSegment.id,
          detailSegmentId: allCustomersSegment.id,
          currentSegment: allCustomersSegment,
          isLoading: true
        });
        toast.success('Segment Deleted');
      });
    } catch (err) {
    }
  };

  renderDataGrid = () => {
    const {
      currentSegmentId,
      dataViewId,
      hasUserPreference
    } = this.state;

    if (hasUserPreference) {
      return (
        <SegmentCustomersDataGrid
          segmentId={currentSegmentId}
          dataViewId={dataViewId}
          isUpdateState={this.state.isUpdateState}
          onCustomerDetail={this.props.onCustomerDetail}
        />
      );
    }
  };

  renderAnalytics = (currentSegment, dataSchema, allCountOfCustomerIsLoaded) => {
    return <Analytics allCountOfCustomerIsLoaded={allCountOfCustomerIsLoaded}
                      allCustomersSegment={this.getAllCustomersSegment()} segment={currentSegment}
                      dataSchema={dataSchema}/>;
  };

  downloadcsv = async () => {
    var fileDownload = require('js-file-download');

    const {currentSegmentId, dataViewId, currentSegment} = this.state;
    const currentSegmentName = currentSegment.name;

    const formData = {
      segmentId: currentSegmentId,
      dataViewId: dataViewId
    };

    this.setState({csvDownloading: true});
    try {
      await CustomersAPI.downloadCustomerCSV(formData).then(response => {
        if (response.status === 'BAD_REQUEST') {
          toast.error(response.message);
        } else {
          fileDownload(response, currentSegmentName + '.csv');
        }
        this.setState({csvDownloading: false});
      });
    } catch (err) {
      this.setState({csvDownloading: false});
    }
  };

  render() {
    const {
      isLoading,
      viewType,
      filtered,
      enrichment,
      segmentsIsLoaded,
      isNewSegment,
      currentSegmentId,
      currentSegment,
      dataViewId,
      detailSegmentId,
      dataSchema,
      isSearching,
      allCountOfCustomerIsLoaded,
    } = this.state;

    let currentUser = JSON.parse(sessionStorage.getItem("selectedMembers"));

    let count = currentSegment?.cachedCustomerCount || 0;

    const cachedCustomerCount = get(
      this.state.currentSegment,
      'cachedCustomerCount'
    );

    return isLoading ? (
      <React.Fragment>
        {isNewSegment ? (
          <NewSegment
            dataViewId={dataViewId}
            onBack={this.triggerBack}
            currentSegmentId={detailSegmentId}
            dataSchema={dataSchema}
            onDeleteSegment={this.handleDeleteSegment}
            onsaveNewSegment={this.saveNewSegment}
            onUpdateSegment={this.handleUpdateSegment}
            onChangeView={this.handleCurrentView}
            onCheckChange={this.props.onCheckChange}
            onVerifyChange={this.props.onVerifyChange}
            onCustomerDetail={this.props.onCustomerDetail}
            isUpdateState={this.state.isUpdateState}
          />
        ) : (
          <main className="position-relative area-main secondary-font customer-data">
            <SegmentsSidebar
              resetData={this.onResetData}
              allCountOfCustomerIsLoaded={allCountOfCustomerIsLoaded}
              allSegments={filtered}
              onSearch={this.handleSearch}
              onSort={this.sortSegment}
              onNewSegment={this.handleNewSegment}
              onSegmentSelect={this.handleSegmentSelect}
              currentSegmentId={currentSegmentId}
              isSearching={isSearching}
              enrichment={enrichment}
              currentSegment={currentSegment}
              allCustomersSegment={this.getAllCustomersSegment()}
            />
              <section className="position-relative right-list-area">
                {!segmentsIsLoaded ? <Loader/> :
                  (<React.Fragment>
                    <div className="content-body-top">
                      <ButtonGroup className="segment-analytics-switch">
                        <Button
                            onClick={() => this.setState({viewType: "analytics"})}
                            className={`segment-view-type-button  ${viewType === "analytics" ? "active" : ""}`}
                        >
                          <span className={`icon-analytics ${viewType === "analytics" ? "active" : ""}`}/>
                          Analytics
                        </Button>
                        <Button
                            onClick={() => this.setState({viewType: "dataset"})}
                            className={`segment-view-type-button dataset ${viewType === "dataset" ? "active" : ""}`}
                        >
                          <span className="icon-list-bullet"/> Data
                        </Button>
                      </ButtonGroup>
                      {this.state.viewType === 'dataset' && <View
                          dataSchema={dataSchema}
                          onChangeView={this.handleCurrentView}
                          dataViewId={dataViewId}
                      />}
                      <div className="segment-info ml-4">
                        <h6>{currentSegment.name}</h6>
                        <span>
                      {(!allCountOfCustomerIsLoaded && currentSegment.customerSegmentType === 'ALL_CUSTOMERS') ?
                          (<span className="icon-distil-logo icon spin"/>) :
                          (count > 0 ? count.toLocaleString(navigator.language,
                              {minimumFractionDigits: 0}) : 0)} record{getPluralEnding(count)}
                    </span>
                      </div>
                      <div className="btn-wrap d-flex ml-auto">
                        {this.state.csvDownloading ? (
                            <Button variant="secondary" className="d-none d-md-block">
                        <span className="download-btn">
                          <i className="svg-download"/> Creating CSV
                        </span>
                            </Button>
                        ) : (
                            (cachedCustomerCount === null ||
                                cachedCustomerCount > 0) && (
                                <Button
                                    variant="secondary"
                                    className={this.state.viewType === 'dataset' ? "d-md-block csv-download-button" : "d-none"}
                                    onClick={this.downloadcsv}
                                >
                          <span className="download-btn">
                            <i className="icon-download"/> Download CSV
                          </span>
                                </Button>
                            )
                        )}

                        {currentUser.membershipType !== 'READER' && currentSegment.customerSegmentType === 'USER_CREATED' && (
                            <Button
                                variant="secondary"
                                onClick={() => this.handleNewSegment('editmode')}
                                className="d-none d-md-block"
                                dataschema={dataSchema}
                            >
                              {' '}
                              <span className="edit-btn">
                          {' '}
                                <i className="icon-edit-light l-edit"/> Edit Segment
                        </span>{' '}
                            </Button>
                        )}
                      </div>
                    </div>
                    {viewType === 'analytics' && this.renderAnalytics(currentSegment, dataSchema, allCountOfCustomerIsLoaded)}
                    {viewType === 'dataset' && this.renderDataGrid()}
                  </React.Fragment>)}
              </section>
          </main>
        )}
        {this.state.showModel && (
          <SweetAlert
            custom
            showCancel
            confirmBtnText="Discard"
            cancelBtnText="Cancel"
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="default"
            allowEscape="false"
            closeOnClickOutside="false"
            title="Discard Changes "
            onConfirm={() => this.handleDiscard()}
            onCancel={() => this.setState({showModel: false})}
          >
            Changes will be discarded by clicking discard
          </SweetAlert>
        )}{' '}
      </React.Fragment>
    ) : (
      <Loader/>
    );
  }
}

export default CustomersData;
