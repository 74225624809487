import React, {Component} from "react";
import {Redirect, Route, Switch} from "react-router-dom";

import Login from "../../views/Auth/Login";
import Signup from "../../views/Auth/Signup";
import Forgot from "../../views/Auth/Forgot";
import Page404 from "../../views/Error/Page404";
import Page500 from "../../views/Error/Page500";
import Activate from "../../views/Auth/Activate";
import NewShopifyTrial from "../../views/Account/pages/NewShopifyTrial";

import {BannerPanel} from "../../components/Branding";
import IntegrationAdded from "../../views/Auth/integration/IntegrationAdded";

class AuthLayout extends Component {
  render() {
    return (
      <div className="app">
        <div className="login">
          <div className="container-fluid">
            <div className="row no-gutters h-100">
              <div className="col-lg-5">
                <BannerPanel/>
              </div>

              <div className="col-lg-7">
                <Switch>
                  {/* <Route>
                    <TrialExpire />
                  </Route> */}
                  <Route
                    exact
                    path="/login"
                    name="Login Page"
                    render={props => <Login {...props} />}
                  />
                  <Route
                    exact
                    path="/auth-login"
                    name="Login Page"
                    render={props => <Login {...{...props, authMockLogin: true}} />}
                  />
                  <Route
                    exact
                    path="/signup"
                    name="Signup Page"
                    render={props => <Signup {...props} />}
                  />
                  <Route
                    exact
                    path="/integrated/shopify"
                    name="Integration Added"
                    render={props => <IntegrationAdded {...props} />}
                  />
                  {/* <Route
                      path="/createaccount"
                      name="CreateAccount"
                      render={props => <CreateAccount {...props} />}
                    /> */}
                  <Route
                    exact
                    path="/forgot"
                    name="Forgot Page"
                    render={props => <Forgot {...props} />}
                  />
                  <Route
                    exact
                    path="/404"
                    name="Page404 Page"
                    render={props => <Page404 {...props} />}
                  />
                  <Route
                    exact
                    path="/500"
                    name="Page500 Page"
                    render={props => <Page500 {...props} />}
                  />
                  <Route
                    exact
                    path="/activate"
                    name="Activate Page"
                    render={props => <Activate {...props} />}
                  />
                  <Route
                    exact
                    path="/signup/activated"
                    name="Account Activated"
                    render={props => <NewShopifyTrial {...props} />}
                  />
                  <Redirect from="/" to="/login"/>
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AuthLayout;
