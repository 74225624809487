import React from "react";
import "./styles.scss";
import {Row} from "react-bootstrap";

const ChannelPerformanceAndProjectedLTV = ({roiAndLifetimeValuesData, roiAndLifetimeValuesData_formatted}) => {
    return (
        <>
            <Row className="content_table w-100">
                <Row className="content_table_title font-size-20px color-brown">
                    Channel Performance and Projected LTV
                </Row>
                <Row className="content_table_content p-0 m-0">
                    <div className="content_table_content_block p-0 m-0">
                        <table className="w-100 p-0 m-0" >
                            <thead>
                            <tr>
                                <th/>
                                <th>Attribution</th>
                                <th>Avg Order</th>
                                <th>New Customers</th>
                                <th>Projected LTV</th>
                            </tr>
                            </thead>
                            <tbody>
                            {roiAndLifetimeValuesData?.map((data, i) => (
                                <tr key={i}>

                                    <td className="title-left">{data.channelName}</td>

                                    {data.channelName === "Total" || data.channelName === "Direct/Unknown" ?
                                        <td className="attribution">{roiAndLifetimeValuesData_formatted[i]?.attribution_formatted}</td>
                                        :
                                        <td className="attribution">
                                            {roiAndLifetimeValuesData_formatted[i]?.attribution_formatted}&nbsp;(
                                            {roiAndLifetimeValuesData_formatted[i]?.attributionPercent_formatted})
                                        </td>
                                    }

                                    <td>
                                        <div className="avg-order">{roiAndLifetimeValuesData_formatted[i]?.averageOrder_formatted} </div>
                                    </td>

                                    <td className="new-customer">{roiAndLifetimeValuesData_formatted[i]?.newCustomers_formatted}</td>
                                    <td>
                                        <div className={`${data.channelName === "Direct/Unknown" ? '' : `ltv-color ltv-color_${i}`}`}>
                                            {roiAndLifetimeValuesData_formatted[i]?.projectedLtv_formatted}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            {roiAndLifetimeValuesData?.find(element => element.channel === "DIRECT_UNKNOWN") === undefined ?
                                <tr>
                                    <td className="title-left">Direct / Unknown</td>
                                    <td className="attribution">{0}</td>
                                    <td className="avg-order">{0}</td>
                                    <td className="new-customer">{0}</td>
                                    <td className="project">{0}</td>
                                </tr>
                                : null
                            }

                            </tbody>
                        </table>
                    </div>
                </Row>
            </Row>
        </>
    );
};

export default ChannelPerformanceAndProjectedLTV;
