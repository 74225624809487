import React, { Component } from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import "../assets/css/style.css";

class DestinationDetail extends Component {
  render() {
    const { destinations } = this.props;
    return (
      <div className="destination-wrapper contentWrap">
        <h4 className="section-heading d-none d-lg-block">Destinations</h4>

        <ListGroup>
          {destinations.map(item => (
            <ListGroupItem>
              <h6>{item.title}</h6>
            </ListGroupItem>
          ))}
        </ListGroup>
      </div>
    );
  }
}

export default DestinationDetail;
