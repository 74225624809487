import React, { Component } from "react";
import "./assets/styles.css";

import { Container } from "react-bootstrap";

import Sortimage from "./assets/img/sort.svg";
import Setting from "./assets/img/setting.svg";
import InviteList from "./InviteList/InviteList";
import Addimage from "./assets/img/add.svg";

//import inviteAPI from "../../../../../services/users.js";

import FilterSection from "./Component/FilterSection";
import InviteUser from "./InviteForm/InviteUser";
import CommonMessage from "../../../../../components/CommonMessage/commonMessage";
class ManageInvites extends Component {
  constructor() {
    super();
    this.state = {
      inviteKeys: [],
      rollUpKeys:[],
      newInvite: false,
      searchValue: "",
      inviteUsersLoading: false,
      masterfilteredInvite: [],
      filteredInvite:[],
      showFilter: false,
      loadingFilteredInvite: true
    };
  }

  componentDidMount() {
    this.getInviteResponseKeys();
    this.setState({ filteredInvite: this.props.usersInvites, masterfilteredInvite:this.props.usersInvites });
  }

  membershipType = membershipType => {
    switch (membershipType) {
      case "ADMIN":
        return (
          <span className="d-inline-block text-primary">
            {" "}
            {membershipType}{" "}
          </span>
        );

      case "CONTRIBUTOR":
        return (
          <span className="d-inline-block text-muted">{membershipType}</span>
        );

      case "TECHNICAL":
        return (
          <span className="d-inline-block text-danger">{membershipType}</span>
        );

      case "READER":
        return (
          <span className="d-inline-block text-success">
            {" "}
            {membershipType}{" "}
          </span>
        );

      default:
        return (
          <span className="d-inline-block text-info">{membershipType}</span>
        );
    }
  };

  onSearch = (stateName, filterWhat, key1, key2) => {
    let currentList = [];
    let newList = [];
    if (filterWhat !== "") {
      currentList = this.state["master" + stateName];
      newList = currentList.filter(item => {
        const fileld1 = item[key1].toLowerCase();
        const fileld2 = item[key2].toLowerCase();

        const filter = filterWhat.toLowerCase();
        if (fileld1 !== undefined) {
          let result = fileld1.includes(filter);

          if (!result) {
            result = fileld2.includes(filter);
          }
          return result;
        }
      });
    } else {
      newList = this.state["master" + stateName];
    }

    this.setState({
      [stateName]: newList
    });
  };

  filteringInvite = filtereWhat => {
    this.setState({ loadingFilteredInvite: false,searchValue:'' });
    var filteredInvite =
      filtereWhat.selectedKey !== "All"
        ? this.props.usersInvites.filter(fd => {
            return fd.rollUpStatus === filtereWhat.selectedKey;
          })
        : this.props.usersInvites;

    setTimeout(() => {
      this.setState({ filteredInvite,masterfilteredInvite:filteredInvite, loadingFilteredInvite: true });
    }, 1);
  };

  getInviteResponseKeys = (usersInvites = "") => {
    let usersData =
      usersInvites !== "" ? usersInvites : this.props.usersInvites;
    const inviteUsersSorted = usersData.reduce(
      (inviteSoFar, { rollUpStatus, id }) => {
        if (!inviteSoFar[rollUpStatus]) inviteSoFar[rollUpStatus] = [];

        inviteSoFar[rollUpStatus].push(id);
        return inviteSoFar;
      },
      {}
    );

    const rollUpUsersSorted = usersData.reduce(
      (inviteSoFar, { rollUpStatus, id }) => {
        if (!inviteSoFar[rollUpStatus]) inviteSoFar[rollUpStatus] = [];

        inviteSoFar[rollUpStatus].push(id);
        return inviteSoFar;
      },
      {}
    );
    var inviteKeys = Object.keys(inviteUsersSorted);
    var rollUpKeys = Object.keys(rollUpUsersSorted);
    console.log(rollUpKeys);
    
    this.setState({ inviteKeys, inviteUsersLoading: true,rollUpKeys });
  };

  showFilter = () => {
    this.setState({ showFilter: !this.state.showFilter });
  };

  revokeInvite = invideId => {
    this.props.onRevokeInvite(invideId);
  };

  componentWillReceiveProps(nextProps) {
    console.log("nextProps",nextProps);
    if (nextProps.usersInvites !== this.props.usersInvites) {
      this.setState({
        usersInvites: nextProps.usersInvites,
        filteredInvite: nextProps.usersInvites,
        masterfilteredInvite:nextProps.usersInvites
      });
      this.getInviteResponseKeys(nextProps.usersInvites);
    }

    this.setState({ newInvite: nextProps.newInvite });
  }

  searchUsers = e => {
    this.setState({ searchValue: e.target.value });
    this.onSearch(
      "filteredInvite",
      e.target.value,
      "firstName",
      "lastName"
    );
  };

  clearSearch = () => {
    this.setState({ searchValue: "" });
    this.props.onResetState("usersInvites");
  };

  componentWillUnmount() {
    this.props.onResetState("usersInvites");
  }

  render() {
    const {
      inviteUsersLoading,
      inviteKeys,
      filteredInvite,
      showFilter,
      newInvite,
      rollUpKeys
    } = this.state;
    const { usersInvites } = this.props;
    // return ( <CommonMessage />)
    return (
      <React.Fragment>
        <main className="position-relative area-main">
          <section className="sideNav">
            {inviteUsersLoading && (
              <FilterSection
                isMobile={false}
                usersInvites={usersInvites}
                inviteKeys={rollUpKeys}
                filteredInvite={filteredInvite}
                onFilteringInvite={this.filteringInvite}
              />
            )}
          </section>
          {!newInvite ? (
            <section className="right-list-area">
              <Container fluid={true}>
                <div className="d-flex search-user ">
                  <ul className="d-flex ml-auto list-unstyled m-0 p-0">
                    <li className="position-relative item-search">
                      <input
                        type="text"
                        value={this.state.searchValue}
                        onChange={e => this.searchUsers(e)}
                      />
                      <i className="icon-search position-absolute" />
                    </li>

                    <div className="d-flex ml-auto">
                      <li className="item-sort" onClick={this.props.onSort}>
                        <img src={Sortimage} alt="sort" />
                      </li>
                      <li
                        className="item-add"
                        onClick={() => this.setState({ newInvite: true })}
                      >
                        <img src={Addimage} alt="setting" />
                      </li>
                      <li
                        className="item-menu position-relative"
                        onClick={this.showFilter}
                      >
                        <img src={Setting} alt="setting" />
                        {inviteUsersLoading && showFilter && (
                          <FilterSection
                            isMobile={true}
                            inviteKeys={rollUpKeys}
                            usersInvites={usersInvites}
                            filteredInvite={filteredInvite}
                            onFilteringInvite={this.filteringInvite}
                          />
                        )}
                      </li>
                    </div>
                  </ul>
                </div>

                <InviteList
                  filteredInvite={filteredInvite}
                  onRevokeInvite={this.revokeInvite}
                />
                {/* {filteredInvite.length === 0 && (
                  <p className="text-center">No invited user found</p>
                )} */}
              </Container>
            </section>
          ) : (
            <InviteUser
              emailError={this.props.emailError}
              isInviteRequestedProccession={this.props.isInviteRequestedProccession}
              onSubmitInvite={this.props.onSubmitInvite}
              isInviteSend={this.props.isInviteSend}
              cancleInvite={() => this.setState({ newInvite: false })}
            />
          )}
        </main>
      </React.Fragment>
    );
  }
}

export default ManageInvites;
