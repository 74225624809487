import React, {Component} from "react";
import "../assets/css/analytic-header.scss";
import "../assets/css/dailyCard/tuesday.scss";
import "../assets/css/dailyCard/wednesday.scss";
import "../assets/css/general-styles.scss";

import {Col, Row} from "react-bootstrap";
import iconHeaderWednesday from "../assets/images/wednesday-icon.svg";
import iconShopping from "../assets/images/Icon Shopping Basket.svg";
import iconBackground from "../assets/images/background-icon.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfo} from "@fortawesome/free-solid-svg-icons";
import InstallTracker from "./InstallTracker";
import WednesdayComingSoon from "./WednesdayComingSoon";
import MarketingAttributeRow from "../../FunnelMetrics/FunnelMetricsComponents/funnelMob/MarketingAttributeRow";
import CardLoader from "./CardLoader";
import FunnelStageAttributionTable from "../../FunnelMetrics/FunnelMetricsComponents/funnelMob/FunnelStageAttributionTable";
import DirectUnknownRow from "../../FunnelMetrics/FunnelMetricsComponents/funnelMob/DirectUnknownRow";
import PurchaseTable from "../../FunnelMetrics/FunnelMetricsComponents/funnelMob/PurchaseTable";
import RegisteredTable from "../../FunnelMetrics/FunnelMetricsComponents/funnelMob/RegisteredTable";
import FirstVisitTable from "../../FunnelMetrics/FunnelMetricsComponents/funnelMob/FirstVisitTable";
import ChannelPerformanceAndProjectedLTV from "../../FunnelMetrics/FunnelMetricsComponents/funnelFull/ChannelPerformanceAndProjectedLTV";
import MainBlock from "../../FunnelMetrics/FunnelMetricsComponents/funnelFull/MainBlock";
import FunnelStage from "../../FunnelMetrics/FunnelMetricsComponents/funnelFull/FunnelStage";
import FunnelTrapezium from "../../FunnelMetrics/FunnelMetricsComponents/funnelFull/FunnelTrapezium";
import FunnelTable from "../../FunnelMetrics/FunnelMetricsComponents/funnelFull/FunnelTable";

class Wednesday extends Component {
    constructor(props) {
        super(props);
        this.state = {
            noData: "",
            isLoading: "",
        };
        this.redirectToFunnelMetrics = this.redirectToFunnelMetrics.bind(this)
    }

    redirectToFunnelMetrics() {
        this.props.history.push("/funnel_analytics");
    }

    render() {
        const  { cardData, date, trackingIdCorrect } = this.props;
        const noData = !cardData?.data?.funnelMetrics;

        if (cardData === null || noData) {
            return <WednesdayComingSoon date={date}/>
        }

        return (

            <div className="wednesday tuesday card m-0 p-2 rounded shadow-sm analytic-card container-monday">
                <div
                    className="card-header border-bottom-1 pl-3 position-relative padding-top4em">
                    <div className="row card-header-row">
                        <div className="col-1 d-flex card-col-icon align-items-center">
                            <div className="card-icon">
                                <img className="icon-background" src={iconBackground} alt="header..."/>
                                <img className="icon-header" src={iconHeaderWednesday} alt="header..." style={{left: '14px'}}/>
                            </div>
                        </div>
                        <div className="col-11 card-title-col">
                            <div className=" fs-l fw-bold text-black">Marketing Wednesday</div>
                            <p className="fs-m text-muted mb-1 mb-md-2">{date}</p>
                            {trackingIdCorrect && <div className="text-wrap pr-3 w-75">
                                <p className="card-text fs-m">
                                    Time to catch up with how your latest cohort of customers are getting on. How loyal
                                    are
                                    they compared to those brought in by your previous sales promotion or marketing
                                    campaign? Are they on course to join your top spenders or already starting to drift?
                                    And
                                    why?
                                </p>
                            </div>
                            }
                        </div>
                        {trackingIdCorrect &&
                        <div className="card-offset">
                            <p className="card-info small">
                                <span className="text-infor"> How to read this card?</span>
                                <span className="card-sm-icon m-2">
                                <a href="https://help.distil.ai/hc/en-gb/articles/4419131524625-Daily-Insights-Marketing-Wednesday" className="" rel="noreferrer" target="_blank">
                                    <FontAwesomeIcon icon={faInfo}/>
                                </a>
                            </span>
                            </p>
                        </div>
                        }
                    </div>
                    {trackingIdCorrect &&
                        <div className="resonse-header-mobile">
                            <p className="card-text-mobile card-text fs-m pt-4 pb-3">
                                Time to catch up with how your latest cohort of customers are getting on. How loyal are
                                they
                                compared to those brought in by your previous sales promotion or marketing campaign? Are
                                they on
                                course to join your top spenders or already starting to drift? And why?
                            </p>
                            <p className="cohort-title"><strong>{cardData?.data?.cohortName} Cohort</strong></p>
                        </div>
                    }
                </div>
                {!trackingIdCorrect ?
                    <div className="card-body">
                        <InstallTracker shopifyHistory={this.props.history.shopifyHistory} history={this.props.history}/>
                    </div>
                    :
                    <div className="card-body">
                        <div>
                            <div
                                onClick={this.redirectToFunnelMetrics}
                                className="view-funnel-metrics-link">
                                View in Funnel Metrics
                            </div>
                            {/* ------------------------------ MAIN BLOCK ----------------------------- */}
                            <MainBlock data={cardData?.data}/>
                            {/* ----------------------------------------------------------------------- */}

                            {/* ---------------- Channel Performance and Projected LTV ---------------- */}
                            <ChannelPerformanceAndProjectedLTV
                                roiAndLifetimeValuesData={cardData?.data?.roiAndLifetimeValues}
                                roiAndLifetimeValuesData_formatted={cardData?.data?.roiAndLifetimeValues_formatted}/>
                            {/* ----------------------------------------------------------------------- */}

                            {/* ---------------- Funnel ---------------- */}
                            <Row className="w-100">
                                <div className="main">
                                    <div className="row">
                                        <div className="col">
                                            <p className="month color-brown">
                                                {cardData?.data?.cohortName?.split(" ")[0]} Funnel</p>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                            <Row className="w-100">
                                <div className="main" style={{width: "100%"}}>
                                    {/* ---------------- Mobile view START ---------------- */}
                                    {/* ---------------- First Visit ---------------- */}
                                    <FirstVisitTable
                                        funnelMetrics_formatted={cardData?.data?.funnelMetrics_formatted[0]}/>
                                    <MarketingAttributeRow
                                        data={cardData?.data?.funnelMetrics_formatted[0].marketingAttributedCustomerCount_formatted}/>
                                    <FunnelStageAttributionTable
                                        funnelStageAttributions={cardData?.data?.funnelMetrics[0]?.funnelStageAttributions}
                                        funnelStageAttributions_formatted={cardData?.data?.funnelMetrics_formatted[0]?.funnelStageAttributions_formatted}/>
                                    <DirectUnknownRow funnelMetric={cardData.data?.funnelMetrics[0]}
                                                      funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[0]}/>

                                    {/* ---------------- Registered ---------------- */}
                                    <RegisteredTable funnelMetric={cardData?.data?.funnelMetrics_formatted[1]}/>
                                    <MarketingAttributeRow
                                        data={cardData?.data?.funnelMetrics_formatted[1].marketingAttributedCustomerCount_formatted}/>
                                    <FunnelStageAttributionTable
                                        funnelStageAttributions={cardData?.data?.funnelMetrics[1]?.funnelStageAttributions}
                                        funnelStageAttributions_formatted={cardData?.data?.funnelMetrics_formatted[1]?.funnelStageAttributions_formatted}/>
                                    <DirectUnknownRow funnelMetric={cardData.data?.funnelMetrics[1]}
                                                      funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[1]}/>

                                    {/* ---------------- 1st Purchase ---------------- */}
                                    <PurchaseTable name="1st Purchase"
                                                   funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[2]}/>
                                    <MarketingAttributeRow
                                        data={cardData?.data?.funnelMetrics_formatted[2].marketingAttributedCustomerCount_formatted}/>
                                    <FunnelStageAttributionTable
                                        funnelStageAttributions={cardData?.data?.funnelMetrics[2]?.funnelStageAttributions}
                                        funnelStageAttributions_formatted={cardData?.data?.funnelMetrics_formatted[2]?.funnelStageAttributions_formatted}/>
                                    <DirectUnknownRow funnelMetric={cardData.data?.funnelMetrics[2]}
                                                      funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[2]}/>

                                    {/* ---------------- 2nd Purchase ---------------- */}
                                    <PurchaseTable name="2nd Purchase"
                                                   funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[3]}/>
                                    <MarketingAttributeRow
                                        data={cardData?.data?.funnelMetrics_formatted[3].marketingAttributedCustomerCount_formatted}/>
                                    <FunnelStageAttributionTable
                                        funnelStageAttributions={cardData?.data?.funnelMetrics[3]?.funnelStageAttributions}
                                        funnelStageAttributions_formatted={cardData?.data?.funnelMetrics_formatted[3]?.funnelStageAttributions_formatted}/>
                                    <DirectUnknownRow funnelMetric={cardData.data?.funnelMetrics[3]}
                                                      funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[3]}/>

                                    {/* ---------------- 3rd Purchase ---------------- */}
                                    <PurchaseTable name="3rd+ Purchase"
                                                   funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[4]}/>
                                    <MarketingAttributeRow
                                        data={cardData?.data?.funnelMetrics_formatted[4].marketingAttributedCustomerCount_formatted}/>
                                    <FunnelStageAttributionTable
                                        funnelStageAttributions={cardData?.data?.funnelMetrics[4]?.funnelStageAttributions}
                                        funnelStageAttributions_formatted={cardData?.data?.funnelMetrics_formatted[4]?.funnelStageAttributions_formatted}/>
                                    <DirectUnknownRow funnelMetric={cardData.data?.funnelMetrics[4]}
                                                      funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[4]}/>
                                    {/* ---------------- Mobile view END ---------------- */}
                                </div>
                            </Row>

                            {/* ---------------- FULL page view START ---------------- */}
                            <Row className="w-100">
                                <div className="main">
                                    <div className="row border-t display-none-max576">
                                        <FunnelStage funnelMetric={cardData?.data?.funnelMetrics[0]}
                                                     funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[0]}/>
                                    </div>

                                    <Row className="row border-t mg-t-3 display-none-max576">
                                        <FunnelTrapezium
                                            funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[0]}
                                            title={"First Visit"}
                                            trapeziumName={"Unique Visitors"}
                                            position={1}/>
                                    </Row>
                                </div>
                            </Row>
                            <Row className="content_funnel">
                                <div className="main">
                                    <div className="row border-t display-none-max576">
                                        <FunnelStage funnelMetric={cardData?.data?.funnelMetrics[1]}
                                                     funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[1]}/>
                                    </div>

                                    <Row className="row border-t mg-t-3 display-none-max576">
                                        <FunnelTrapezium
                                            funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[1]}
                                            title={"Registered"}
                                            trapeziumName={"Customers"}
                                            position={2}/>
                                        <FunnelTable funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[1]}
                                                     position={2}/>
                                    </Row>

                                </div>
                            </Row>
                            <Row className="content_funnel">
                                <div className="main display-none-max576">
                                    <div className="row border-t display-none-max576">
                                        <FunnelStage funnelMetric={cardData?.data?.funnelMetrics[2]}
                                                     funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[2]}/>
                                    </div>

                                    <Row className="row border-t mg-t-3">
                                        <FunnelTrapezium
                                            funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[2]}
                                            title={"1st Purchase"}
                                            trapeziumName={"Customers"}
                                            position={3}/>
                                        <FunnelTable funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[2]}
                                                     position={3}/>
                                    </Row>

                                </div>
                            </Row>
                            <Row className="content_funnel">
                                <div className="main display-none-max576">
                                    <div className="row border-t display-none-max576">
                                        <FunnelStage funnelMetric={cardData?.data?.funnelMetrics[3]}
                                                     funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[3]}/>
                                    </div>

                                    <Row className="row border-t mg-t-3">
                                        <FunnelTrapezium
                                            funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[3]}
                                            title={"2nd Purchase"}
                                            trapeziumName={"Customers"}
                                            position={4}/>
                                        <FunnelTable funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[3]}
                                                     position={4}/>
                                    </Row>
                                </div>
                            </Row>
                            <Row className="content_funnel">

                                <div className="main display-none-max576">
                                    <div className="row border-t display-none-max576">
                                        <FunnelStage funnelMetric={cardData?.data?.funnelMetrics[4]}
                                                     funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[4]}/>
                                    </div>

                                    <Row className="row border-t mg-t-3">
                                        <FunnelTrapezium
                                            funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[4]}
                                            title={"3rd+ Purchase"}
                                            trapeziumName={"Customers"}
                                            position={5}/>
                                        <FunnelTable funnelMetric_formatted={cardData?.data?.funnelMetrics_formatted[4]}
                                                     position={5}/>
                                    </Row>
                                </div>
                            </Row>
                            {/* ---------------- FULL page view END ---------------- */}
                        </div>
                    </div>
                }

                <div className="card-footer fs-m">
                    <Row>
                        <Col lg={2}/>
                        <Col ls={8} className="section-text-footer">
                            <div className="fs-l fw-bold">
                                Tomorrow is Winners & Losers Thursday{" "}
                            </div>
                            <div className="fs-xm text-gray">
                                It’s hump day so let’s find out which products are flying off the
                                shelves.
                            </div>
                            <div className="card-icon">
                                <img src={iconShopping} alt="footer..."/>
                            </div>
                        </Col>
                        <Col lg={2}/>
                    </Row>
                </div>
            </div>

        );
    }
};

export default Wednesday;
