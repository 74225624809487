import React, {Component} from "react";
import {getUrlVars} from "../../Main/Customers/customerdata/segmentUtils";

class IntegrationAdded extends Component {
  render() {
    let shop = getUrlVars(window.location.href).shop;

    return (
      <div className="integration-added-container">
        <h1>Thank you!</h1>
        <h1>Distil App has been successfully added to your {shop} shop</h1>
      </div>
    )
  }
}

export default IntegrationAdded;
