import React, {Component} from "react";
import {Button} from "react-bootstrap";
import moment from "moment";
import Demographics from "./enrichmentContent/demographics";
import Engagement from "./enrichmentContent/engagement";
import {Link} from "react-router-dom";

class Header extends Component {

  state = {trackingAccess: false}

  componentDidMount() {
    let trackingRoles = ['ADMIN', 'TECHNICAL', 'CONTRIBUTOR']

    let trackingAccess = trackingRoles.includes(JSON.parse(localStorage.getItem("userAccountData")).orgMembership.membershipType);
    this.setState({trackingAccess})
  }

  renderEnrichmentStatus() {
    const {currentStatus, currentEnrichment, onTogglePin} = this.props;

    if (currentStatus && currentEnrichment.dateLastProcessed === null) {

      return (
        <span className="response">
          Enrichment Enabled - Waiting to be processed
        </span>
      );
    }
    if (currentStatus && currentEnrichment.dateLastProcessed !== null) {
      var updated = moment.utc(currentEnrichment.dateLastProcessed);
      var dayDiff = moment.utc().diff(updated, 'days');

      var message = "";
      if (dayDiff === 0) {
        message = "today";
      } else if (dayDiff === 1) {
        message = "yesterday";
      } else {
        message = dayDiff + " days ago";
      }

      return (
        <span>
          Enrichment last processed: {message}.
          <a className="read-about" onClick={() => onTogglePin(true)}> Enrichment Newsfeed</a>
        </span>
      );
    }
  }

  renderEnrichmentMessage(enrichmentType, status) {
    switch (enrichmentType) {
      case "CHRONOTYPE":
      case "ENGAGEMENT":
        if (status === 'OK') {
          return <>Data for this Enrichment is being supplied to <a
            href="#">Distil.ai</a>. <br/>
            Switch this Enrichment on to start adding this insight to your Customer Profiles.</>
        } else {
          return <>This Enrichment requires Event Tracking. Go to the {this.getTrackingLink()} section
            to set this up and then begin using this Enrichment.</>
        }
      case "SPEND_BRACKET":
        return status === 'OK' ? <>Data for this Enrichment is being supplied to <a
            href="#">Distil.ai</a>.
            Switch this Enrichment on to start adding this insight to your Customer Profiles.</>
          : <>This Enrichment requires you provide Customer, Product and Purchase History datasets. <br/>
            Visit <a target="_blank" href="https://distil.ai/onboarding_guide/onboarding-task-list/bringing-in-your-data/">Distil.ai
              Onboarding Guide</a> to learn more or contact your Account Manager.</>
      case "PRODUCT_RECOMMENDATIONS_MIX":
        return this.getProductRecommendationMessage(status);
      default:
        return <></>;
    }
  }

  getTrackingLink() {
    if (this.state.trackingAccess) {
      return <Link to="/settings/tracking">Tracking</Link>
    } else {
      return 'Tracking';
    }
  }

  getProductRecommendationMessage(status) {
    switch (status) {
      case 'NO_REQUIRED_DATA_NOT_AVAILABLE':
        return <>This Enrichment requires you provide Customer and Product data as well as either Tracking and/or
          Purchase history data.<br/>
          Visit <a target="_blank" href="https://distil.ai/onboarding_guide/onboarding-task-list/bringing-in-your-data/">Distil.ai
              Onboarding Guide</a> to learn more or contact your Account Manager.</>;

          //TODO: Put back in when we have the link
          //For more information please visit <a href="#"> Enabling the Recommendation Engine</a>.;
      case 'PRODUCT_RECOMMENDATION_NO_PURCHASE_DATA':
        return <>Purchase History data has not been detected, so some of the recommendation components are
          unavailable.<br/>
          Visit <a target="_blank" href="https://distil.ai/onboarding_guide/onboarding-task-list/bringing-in-your-data/">Distil.ai
              Onboarding Guide</a> to learn more or contact your Account Manager.</>;

          //TODO: Put back in when we have the link
          //For more information please visit <a href="#"> Enabling the Recommendation Engine</a>.;
      case 'PRODUCT_RECOMMENDATION_NO_TRACKING_DATA':
        return <>Tracking data has not been detected, so some of the recommendation components are unavailable.<br/>
          Visit <a target="_blank" href="https://distil.ai/onboarding_guide/onboarding-task-list/bringing-in-your-data/">Distil.ai
              Onboarding Guide</a> to learn more or contact your Account Manager.</>;

          //TODO: Put back in when we have the link
          //For more information please visit <a href="#"> Enabling the Recommendation Engine</a>.;
      default:
        return <></>;
    }
  }

  render() {
    const Enrichments = {
      CHRONOTYPE: "Chronotype",
      DEMOGRAPHIC: "Demographics",
      ENGAGEMENT: "Engagement",
      RFIM: "Recency, Frequency, Intensity, Momentum",
      PRODUCT_RECOMMENDATIONS_MIX: "Product Recommendations ",
      CONTENT_RECOMMENDATIONS_MIX: "Content Recommendations ",
      SPEND_BRACKET: "Spend Brackets"
    };
    const {
      currentEnrichment,
      onEnableEnrichment,
      currentStatus,
      onEnrichmentCancel,
      onEnrichmentUpdate,
      applyButtonStatus,
      enrichmentActivateStatus
    } = this.props;

    return (
      <div className="top-content-wrap">
        <button
          type="button"
          className={
            currentStatus
              ? "btn btn-lg btn-toggle active"
              : "btn btn-lg btn-toggle "
          }
          disabled={!currentStatus && enrichmentActivateStatus === 'NO_REQUIRED_DATA_NOT_AVAILABLE'}
          onClick={() => onEnableEnrichment(currentStatus)}
        >
          <div className="handle"/>
        </button>

        <div className="heading-content">
          <h6>{Enrichments[currentEnrichment.enrichmentType]}</h6>
          <div className="below-heading">
            {this.renderEnrichmentStatus()}

            
            {/*
            TODO: Re-instate once we have the links
            <a href="#;" className="read-about">Read about this Enrichment</a>
            */}
            
            {!currentStatus && <h6
              className="mt-2"><br></br>{this.renderEnrichmentMessage(currentEnrichment.enrichmentType, enrichmentActivateStatus)}</h6>}
          </div>
        </div>
        {currentStatus && (
          <div className="btn-wrap">
            {applyButtonStatus && (
              <Button variant="secondary" onClick={() => onEnrichmentCancel()}>
                Reset
              </Button>
            )}
            <Button
              disabled={!applyButtonStatus}
              variant="primary"
              onClick={() => onEnrichmentUpdate()}
            >
              Apply Changes
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default Header;
