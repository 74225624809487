import React, {Component} from 'react';
import {toast} from 'react-toastify';
import {first, get, orderBy} from 'lodash';
//services
import CustomersAPI from '../../../../services/customers';
import TraitsAPI from '../../../../services/traits';
import {Button, ListGroup} from 'react-bootstrap';
//components
import Loader from '../../../../components/Loader/loader';
import SweetAlert from 'react-bootstrap-sweetalert';
import {getPluralEnding} from "../customerdata/segmentUtils";
import queryString from 'query-string';
import TraitsSidebar from "./traitsSidebar";
import NewTrait from "./trait/newTrait";
import NoData from "../../../../components/NoData/nodata";
import {getTraitCategoriesSort, isTraitProcessing} from "./traitUtils";
import * as moment from "moment";
import {Select} from "react-inputs-validation";
import RuleGroup from "../customertraits/trait/ruleGroup";
let pollingBeingBuild = '';

class CustomerTraits extends Component {
  state = {
    isLoading: false,
    traits: [],
    categories: [],
    filtered: [],
    dataViews: [],
    dataView: [],
    enrichment: {},
    sortBy: 'asc',
    isNewTrait: false,
    currentTraitId: null,
    detailTraitId: null,
    currentCategoryId: null,
    isSearching: false,
    search: "",
    isLoadPreference: false,
    traitIsLoaded: false,
    dataViewId: null,
    isUpdateState: false,
    currentPreferences: {
      lastSelectedCustomerDataViewId: null,
      lastSelectedCustomerTraitId: null
    },
    hasUserPreference: false,
    currentTrait: null,
    csvDownloading: false,
    showModel: false
  };

  componentWillMount() {
    this.handleUserPreferences();
  }

  handleUserPreferences = async event => {
    try {
      CustomersAPI.getUserPreferences().then(response => {
        this.setState(
          {
            currentPreferences: response,
            isLoadPreference: true,
          }, () => {
            if (this.state.traitIsLoaded) {
                this.setPreferences();
            }
          }
        );
      });
      this.getTraits();
    } catch (err) {
    }
  };

  getTraits = () => {
    try {
      TraitsAPI.getTraitCategories().then(traitCategories => {
        TraitsAPI.getTraitsWithRules().then(traitsWithRules => {
                this.setState({
                  traitCategories: [...traitCategories.sort(getTraitCategoriesSort())],
                  traits: traitsWithRules,
                  filtered: traitsWithRules,
                  isLoading: true,
                  show: false,
                }, () => {
                  return this.getCustomersSchema();
              })
            });
      });
    } catch (err) {
    }
  };

  getCustomersSchema = async event => {
    try {
      await CustomersAPI.getCustomersSchema()
      .then(response => {
        this.setState({
          dataSchema: response,
          traitIsLoaded: true,
        });
      })
      .then(() => {
        if (this.state.isLoadPreference) {
          this.setPreferences()
        }
      });
    } catch (err) {
    }
  };

  setPreferences = () => {
    let params = queryString.parse(this.props.location.search);

    let trait = this.state.traits.find((x) => {
      return x.id === params.id;
    })

    if (trait) {
      this.setState(
          {
            // dataViewId: +trait.id,
            detailTraitId: +trait.id,
            currentTraitId: +trait.id,
            hasUserPreference: true
          },
          () => {
            this.setCurrentTrait(+trait.id);
            localStorage.removeItem('redirectCustomerScreenSection');
          }
      );
    } else {
      this.setState(
        {
          dataViewId: this.state.currentPreferences
            .lastSelectedCustomerDataViewId,
          detailTraitId: this.state.currentPreferences
            .lastSelectedCustomerTraitId,
          currentTraitId: this.state.currentPreferences
            .lastSelectedCustomerTraitId,
          hasUserPreference: true
        },
        () =>
          this.setCurrentTrait(
            this.state.currentPreferences.lastSelectedCustomerTraitId
          )
      );
    }
  };

  setCurrentTrait = id => {
    let currentTrait;
    if (id === null) {
      currentTrait = first(this.state.filtered.filter(item => item.traitCategoryId === null));
    } else {
      currentTrait = first(this.state.filtered.filter(item => item.id === id));

      if(!currentTrait) {
        currentTrait = first(this.state.filtered);
      }
    }

    this.props.onContextIdChange(currentTrait?.id);
    this.setState({currentTrait}, () => this.startpolling());
  };

  startpolling = () => {
    const { currentTrait } = this.state;
    if (!!currentTrait && isTraitProcessing(currentTrait)) {
      this.polling_Being_Build(currentTrait.id);
    } else {
      clearInterval(pollingBeingBuild);
    }
  };

  polling_Being_Build = id => {

    clearInterval(pollingBeingBuild);

    pollingBeingBuild = setInterval(() => {
      this.pollingBeingBuild(id);
    }, 2000);

  };

  pollingBeingBuild = async id => {
    try {
      await TraitsAPI.getSingleTrait(id).then(response => {

        if (response.buildStatus === 'READY') {
          const filtered = this.state.filtered;
          const traits = this.state.traits;
          filtered[filtered.findIndex(item => item.id === response.id)] = response;
          traits[traits.findIndex(item => item.id === response.id)] = response;
          this.setState({
            currentTrait: response,
            filtered,
            traits
          });
          clearInterval(pollingBeingBuild);
        }
      });
    } catch (err) {
    }
  };

  componentWillUnmount() {
    clearInterval(pollingBeingBuild);
  }

  handleUpdateUserPreferences = async event => {
    try {
      await CustomersAPI.updateUserPreferences(
        this.state.currentPreferences
      ).then(response => {
        this.setState({
          currentPreferences: response
        });
      });
    } catch (err) {
    }
  };

  handleCurrentView = (viewID, status) => {
    const  currentPreferences = this.state.currentPreferences;
    currentPreferences.lastSelectedCustomerDataViewId = viewID;

    this.setState(
      {dataViewId: viewID, isUpdateState: status, currentPreferences},
      () => this.handleUpdateUserPreferences()
    );
  };

  handleSearch = (term = null) => {
    let {search} = this.state;
    if (term !== null) {
      search = term;
    }
    let newList;
    let isSearching = !!search;

    if (isSearching) {
      newList = this.state.traits.filter(item => {
        const lc = item.name.toLowerCase();
        const filter = search.toLowerCase();
        return lc.includes(filter);
      });
    } else {
      newList = this.state.traits;
    }
    this.setState({
      filtered: newList,
      isSearching: isSearching,
      search: search
    });
  };

  onResetData = () => this.setState({filtered: this.state.traits});

  sortTrait = () => {
    this.setState({sortBy: this.getSortType()});
  };

  getSortType = () => {
    return this.state.sortBy === 'asc' ? 'desc' : 'asc';
  }

  handleNewTrait = (type = 'new') => {

    clearInterval(pollingBeingBuild);
    this.handleSearch();

    if (type === 'new') {
      this.setState({detailTraitId: null});
    } else {
      this.setState(prevState => ({detailTraitId: prevState.currentTraitId}));
    }

    this.setState({isNewTrait: true});
  };
  handleUpdateTrait = (id, status, data, select = true) => {
    const filtered = this.state.filtered;
    const traits = this.state.traits;
    const traitIndex = filtered.findIndex(item => item.id === id);

    filtered[traitIndex] = data;
    traits[traits.findIndex(item => item.id === id)] = data;

    this.setState(
      {filtered, traits}
    );

    if (this.state.currentTraitId === id) {
      this.setState({currentTrait: filtered[traitIndex]})
    }

    if (select) {
      this.setState({
        currentTrait: filtered[traitIndex],
        isNewTrait: false,
        currentTraitId: id,
        detailTraitId: id,
        isUpdateState: status
      })
    }

    localStorage.setItem('checkChange', false);
  }

  saveNewTrait = (data, status) => {
    const filtered = [...this.state.filtered, data];
    const traits = [...this.state.traits, data];
    var currentPreferences = this.state.currentPreferences;
    currentPreferences.lastSelectedCustomerTraitId = data.id;
    this.setState(
      {
        filtered,
        traits,
        isNewTrait: false,
        currentTraitId: data.id,
        detailTraitId: data.id,
        isUpdateState: status,
        currentTrait: data,
        currentPreferences
      },
      () => this.handleUpdateUserPreferences()
    );
    localStorage.setItem('checkChange', false);
    this.polling_Being_Build(data.id);
  };

  handleTraitSelect = id => {
    var filtered = [...this.state.filtered];

    var currentPreferences = this.state.currentPreferences;
    var currentTraitId = filtered.filter(trait => trait.id === id);
    currentPreferences.lastSelectedCustomerTraitId = currentTraitId[0].id;
    this.setCurrentTrait(currentTraitId[0].id);
    this.setState(
      {
        currentTraitId: currentTraitId[0].id,
        detailTraitId: currentTraitId[0].id,
        currentPreferences,
        isUpdateState: false
      },
      () => this.handleUpdateUserPreferences()
    );
  };

  triggerBack = currentTraitId => {
    let checkChange = JSON.parse(localStorage.getItem('checkChange'));

    if (checkChange) {
      this.setState({showModel: true, targetConnection: currentTraitId});
    } else {
      this.getCustomersSchema();
      if (currentTraitId !== null) {
        this.setState({
          isNewTrait: false,
          currentTraitId: currentTraitId,
          detailTraitId: currentTraitId
        });
      } else {
        this.setState({
          isNewTrait: false,
          currentTraitId: this.state.filtered[0]?.id,
          detailTraitId: this.state.filtered[0]?.id
        });
      }
    }
  };

  handleDiscard = () => {
    localStorage.setItem('checkChange', false);
    this.setState({showModel: false});
    this.triggerBack(this.state.targetConnection);
  };

  handleViewName = e => {
    this.setState(prevState => {
      let newDataViewContent = prevState.newDataViewContent;
      newDataViewContent.name = e.target.value;
      return {newDataViewContent};
    });
  };

  handleDeleteTrait = async id => {
    this.setState({isLoading: false});
    try {
      await TraitsAPI.deleteTrait(id).then(response => {
        localStorage.setItem("traitFilter", null)
        this.setState(prevState => {
          let traits = prevState.traits.filter(trait => trait.id !== id);
          let allCustomersTrait = traits.find(s => s);
          return {
            traits,
            filtered: traits,
            isNewTrait: false,
            currentTraitId: allCustomersTrait.id,
            detailTraitId: allCustomersTrait.id,
            currentTrait: allCustomersTrait,
            isLoading: true
          }
        });
        toast.success('Trait Deleted');
      });
    } catch (err) {
    }
  };

  handleTraitCategoryCreated = (category) => {
    this.setState(prevState => {
      const traitCategories = [...prevState.traitCategories, category];
      traitCategories.sort(getTraitCategoriesSort());
      return {traitCategories};
    });
  }

  handleTraitCategoryUpdated = (category) => {
    this.setState(prevState => {
      const traitCategories = [...prevState.traitCategories];
      traitCategories.find(c => c.id === category.id).name = category.name;
      traitCategories.sort(getTraitCategoriesSort());
      return {traitCategories};
    });
  }

  handleTraitCategoryDeleted = (categoryId) => {
    this.setState(prevState => {
      const traitCategories = prevState.traitCategories.filter(c => c.id !== categoryId);
      return {traitCategories};
    });
  }

  printMembership(date, name, action) {
    return date ?
        <p>{action} {name && 'by '} {name && <strong>{name}</strong>} on <strong>{moment.unix(date / 1000)
        .format('Do MMMM YYYY')}</strong></p> : '';
  }

  renderReadOnlyView = () => {
    const {
      currentTrait,
      dataSchema,
    } = this.state;

    return (
        <React.Fragment>
          <div className="trait-rules-info secondary-font">
            {this.printMembership(currentTrait.dateCreated, currentTrait.createdByUserName, 'Created')}
            {currentTrait.dateCreated !== currentTrait.dateModified &&
                this.printMembership(currentTrait.dateModified, currentTrait.modifiedByUserName, 'Last edited')}
          </div>
          <main className="position-relative area-main secondary-font add-trait pt-0 h-auto">

            <section className="right-list-area m-0 h-auto flex-grow-0">
              <div className="content-body-top mt-3 pl-3 d-block">
                <h6>
                  <strong>Customer Trait Rules</strong>
                </h6>
                <ListGroup as="ul" className="c-match">
                  <ListGroup.Item as="li">
                    <label>
                      Find Customers that match
                      <Select
                          tabIndex="4"
                          id={"fieldType"}
                          name={"fieldType"}
                          value={currentTrait.ruleGroupAndOr || "AND"}
                          disabled={true}
                          optionList={[
                            {id: "OR", name: "ANY"},
                            {id: "AND", name: "ALL"}
                          ]}
                          classNameSelect="select-control"
                          classNameWrapper="form-control select-control-wrapper"
                          classNameContainer="dropdown-without-title"
                          classNameOptionListContainer="select-control-option"
                      />
                      <span> of the specified conditions </span>{" "}
                    </label>
                  </ListGroup.Item>
                </ListGroup>
              </div>
              <div className="pl-3">

                {currentTrait.traitRuleGroups.map((ruleGroup, key) => (
                    <RuleGroup
                        key={ruleGroup.id}
                        groupIndex={key}
                        ruleGroup={ruleGroup}
                        dataSchema={dataSchema}
                        editable={false}
                        onAddRule={() => {}}
                        onDeleteRule={() => {}}
                        onNewCancel={() => {}}
                        onRuleMatchCondition={() => {}}
                        onRuleSave={() => {}}
                        onGroupRuleChange={() => {}}
                        orderPaymentStatuses={[]}
                    />
                ))}
              </div>
            </section>
          </main>
        </React.Fragment>
    );
  }

  renderEmptyView = () => {
    const {
      isSearching,
    } = this.state;
    return (
        <div className=" d-none  d-md-block">
          <NoData customTitle={ isSearching ? "Customer Trait not found." : "Use the plus button above to start adding custom traits."}
                  customSubTitle=""
          />
        </div>
    );
  }

  renderMainView = () => {
    const {
      currentTrait,
    } = this.state;

    if(!currentTrait) {
      return this.renderEmptyView();
    }

    let currentUser = JSON.parse(sessionStorage.getItem("selectedMembers"));

    let count = currentTrait?.currentMatchCount || 0;

    return (<React.Fragment>
      <div className="content-body-top">
        <div className="trait-info">
          <div className="trait-name-and-count">
            <h5>{currentTrait.name}</h5>
            <span className="trait-customers-count">
            {(isTraitProcessing(currentTrait)) ?
                (<span className="icon-distil-logo icon spin"/>) :
                (count > 0 ? count.toLocaleString(navigator.language,
                    {minimumFractionDigits: 0}) : 0)} Record{getPluralEnding(count)}
            </span>
          </div>
          <span>{currentTrait.description}</span>
        </div>
        <div className="btn-wrap d-flex ml-auto">

          {currentUser.membershipType !== 'READER' && (
              <Button
                  variant="secondary"
                  onClick={() => this.handleNewTrait('editmode')}
                  className="d-none d-md-block"
              >
                {' '}
                <span className="edit-btn">
                          {' '}
                  <i className="icon-edit-light l-edit"/>Edit Customer Trait
                        </span>{' '}
              </Button>
          )}
        </div>
      </div>
      {this.renderReadOnlyView()}
    </React.Fragment>);
  }

  render() {
    const {
      isLoading,
      filtered,
      traitIsLoaded,
      isNewTrait,
      currentTraitId,
      currentTrait,
      detailTraitId,
      isSearching,
      traitCategories,
      sortBy,
      dataViewId,
      dataSchema,
    } = this.state;

    const orderedTraits = orderBy(
        filtered,
        [trait => trait.name.toLowerCase()],
        [sortBy])

    return isLoading ? (
      <React.Fragment>
        {isNewTrait ? (
            <NewTrait
                dataViewId={dataViewId}
                dataSchema={dataSchema}
                onBack={this.triggerBack}
                currentTraitId={detailTraitId}
                onDeleteTrait={this.handleDeleteTrait}
                onSaveNewTrait={this.saveNewTrait}
                onUpdateTrait={this.handleUpdateTrait}
                onChangeView={this.handleCurrentView}
                onCheckChange={this.props.onCheckChange}
                onVerifyChange={this.props.onVerifyChange}
                onCustomerDetail={this.props.onCustomerDetail}
                isUpdateState={this.state.isUpdateState}
                traitCategories={traitCategories}
                onTraitCategoryCreated={this.handleTraitCategoryCreated}
                onTraitCategoryUpdated={this.handleTraitCategoryUpdated}
                onTraitCategoryDeleted={this.handleTraitCategoryDeleted}
            />
        ) : (
          <main className="position-relative area-main secondary-font customer-data customer-traits">
            <TraitsSidebar
              resetData={this.onResetData}
              allTraits={orderedTraits}
              onSearch={this.handleSearch}
              onSort={this.sortTrait}
              onNewTrait={this.handleNewTrait}
              onTraitSelect={this.handleTraitSelect}
              currentTraitId={currentTraitId}
              isSearching={isSearching}
              currentTrait={currentTrait}
              traitCategories={traitCategories}
            />
              <section className="position-relative right-list-area">
                {!traitIsLoaded
                    ? <Loader/>
                    : (this.renderMainView())}
              </section>
          </main>
        )}
        {this.state.showModel && (
          <SweetAlert
            custom
            showCancel
            confirmBtnText="Discard"
            cancelBtnText="Cancel"
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="default"
            allowEscape="false"
            closeOnClickOutside="false"
            title="Discard Changes "
            onConfirm={() => this.handleDiscard()}
            onCancel={() => this.setState({showModel: false})}
          >
            Changes will be discarded by clicking discard
          </SweetAlert>
        )}{' '}
      </React.Fragment>
    ) : (
      <Loader/>
    );
  }
}

export default CustomerTraits;
