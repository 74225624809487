import React, { Component } from 'react';
import { ListGroup } from "react-bootstrap";
class Engagement extends Component {
    state = {  }
    render() { 
        const { currentEnrichment,onAttributeChange} = this.props
        return ( <div className="body-content-wrap bg-white">
              
        <div className="content-section">
            <p>
              Choose the Churn rate used to measure your customers.
            </p>

            <ListGroup as="ul">
              <ListGroup.Item as="li" onClick={()=>onAttributeChange( "engagementChurnCategories","DEFAULT")}>
              <i className={currentEnrichment.engagementChurnCategories === "DEFAULT" ?  "icon-check_circle active" :  "icon-check_circle "} />
                   
              <p>
              <span>Default</span>
              Customers as assigned into <strong> Active, 30-day, 60-day, 90-day</strong> and <strong>Inactive</strong>  Churn statuses. These are the industry standard Churn brackets    </p>
              </ListGroup.Item>
              <ListGroup.Item as="li"  onClick={()=>onAttributeChange( "engagementChurnCategories","HIGH_FREQUENCY")}>
              <i className={currentEnrichment.engagementChurnCategories === "HIGH_FREQUENCY" ?  "icon-check_circle active" :  "icon-check_circle "} />
              
              <p>
              <span>High Frequency</span>
              Customers are assigned into <strong> Active, 5-day, 10-day, 20-day, 30-day, 60-day, 90-day </strong>and <strong>Inactive</strong> Churn statuses. If you expect your Customers to visit your platform with a high frequency use this option.   </p>
              </ListGroup.Item>
              <ListGroup.Item as="li"  onClick={()=>onAttributeChange( "engagementChurnCategories","LOW_FREQUENCY")}>
              <i className={currentEnrichment.engagementChurnCategories === "LOW_FREQUENCY" ?  "icon-check_circle active" :  "icon-check_circle "} />
              
              <p>
              <span>Low Frequency</span>
              Customers are assigned into  <strong>Active, 2-month, 4-month, 6-month, 8-month, 1-year, 2-year</strong>, and <strong>Inactive</strong>  Churn statuses. If you do not expect much interaction with your Customers use this option.   </p>
              </ListGroup.Item>
              
            </ListGroup>

            <p>
            This Enrichment is typically set in conjunction with the <span className="highlight"> Recency, Frequency, Intensity & Momentum (RFI/M) </span> Enrichment, which is currently set to <strong> Default.</strong>
            </p>

          </div>

        </div>
);
    }
}
 
export default Engagement;