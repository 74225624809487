import React, {Component} from "react";
import "../assets/css/analytic-header.scss";
import "../assets/css/dailyCard/noTracking.scss";

class InstallTracker extends Component {
    redirectTo() {
        this.props.history.push("/settings/tracking");
    }

    render() {
        return (
            <div className="content noTracking">
                <div className="content-head content-head--center">
                    <div>
                        <h2>Is your marketing effective?</h2>
                    </div>
                </div>
                <div className="once">
                    <p className="mb-2"><b>Wednesday is all about seeing if your marketing is hitting the mark and how
                        to make it more
                        effective. </b></p>
                    <p>To calculate this <b>Distil AI: Business Analtyics</b> needs to capture engagement from your
                        store,
                        done using our JavaScript Tracking.</p>
                </div>
                <div className="content-head content-head--center noTracking_basic_btn">
                    <button onClick={() => {
                        this.redirectTo();
                    }}>
                        Setup Now ...
                    </button>
                </div>

                <p className="once">
                    This is only two short steps, and takes less than a minute.
                </p>

            </div>
        );
    }
};

export default InstallTracker;
