import React, { Component } from "react";
import { ListGroup, ListGroupItem, Row, Col } from "react-bootstrap";
import "./assets/css/style.css";
import ProductDetail from "./components/ProductDetail";
import DestinationDetail from "./components/DestinationDetail";
import SegmentDetail from "./components/SegmentDetail";
import CustomerDataDetail from "./components/CustomerDataDetail";
import EnrichmentSummary from "./components/enrichmentSummary";
import NoRecord from "./components/NoRecord";

import moment from "moment";

class RecordView extends Component {
  state = {
    width: window.innerWidth,
    currentSection: "ENRICHMENTS",
    oMobileNav: []
  };
  componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
    // this.sectionAvailable();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };
  handleCurrentSection = currentSection => {
    this.setState({ currentSection });
  };

  sectionAvailable = () => {
    const { customerDetail } = this.props;
    const oMobileNav = [];

    customerDetail.enrichments !== null &&
      oMobileNav.push({
        navTitle: "Enrichments",
        navTag: "ENRICHMENTS"
      });
    customerDetail.customerData.length !== 0 &&
      oMobileNav.push({
        navTitle: " Customer Data",
        navTag: "CUSTOMER_DATA"
      });
    customerDetail.segments !== null &&
      oMobileNav.push({
        navTitle: "Segments",
        navTag: "SEGMENTS"
      });
    customerDetail.destinations.length !== 0 &&
      oMobileNav.push({
        navTitle: "Destinations",
        navTag: "DESTINAIONS"
      });
    this.setState({ oMobileNav, currentSection: oMobileNav[0].navTag });
  };
  renderMobileView() {
    const currentSection = this.state.currentSection;
    const { customerDetail } = this.props;

    switch (currentSection) {
      case "ENRICHMENTS":
        return (
          <React.Fragment>
            {customerDetail.enrichments === null ? (
              <EnrichmentSummary
                lifetimeValue={null}
                spendBracket={null}
                chronotype={null}
              />
            ) : (
              <EnrichmentSummary
                lifetimeValue={customerDetail.enrichments.lifetimeValue}
                spendBracket={customerDetail.enrichments.spendBracket}
                chronotype={customerDetail.enrichments.chronotype}
              />
            )}

            <ProductDetail
              products={
                customerDetail.enrichments === null
                  ? {
                      viewHistory: [],
                      recommendations: [],
                      purchaseHistory: []
                    }
                  : customerDetail.enrichments.products
              }
            />
          </React.Fragment>
        );

        break;
      case "CUSTOMER_DATA":
        return customerDetail.customerData.length !== 0 ? (
          <CustomerDataDetail customerData={customerDetail.customerData} />
        ) : (
          <NoRecord
            classNaming="mobileView"
            title=""
            subTitle="Customer Data"
          />
        );
        break;

      case "SEGMENTS":
        return customerDetail.segments !== null ? (
          <SegmentDetail segments={customerDetail.segments} />
        ) : (
          ""
        );
        break;

      case "DESTINAIONS":
        return customerDetail.destinations.length !== 0 ? (
          <DestinationDetail destinations={customerDetail.destinations} />
        ) : (
          <NoRecord classNaming="mobileView" title="" subTitle="Destinations" />
        );
        break;
    }
  }
  renderFirstSeen() {
    if (this.props.customerDetail !== null) {
      return (
        <p className="seen-detail">
          {"Customer since " +
            moment
              .utc(this.props.customerDetail.firstSeen)
              .format("MMM DD, YYYY")}
        </p>
      );
    }
  }

  render() {
    const { width, currentSection } = this.state;
    const { customerDetail } = this.props;
    const oMobileNav = [];

    // customerDetail.enrichments !== null &&
    oMobileNav.push({
      navTitle: "Enrichments",
      navTag: "ENRICHMENTS"
    });
    // customerDetail.customerData.length !== 0 &&
    oMobileNav.push({
      navTitle: " Customer Data",
      navTag: "CUSTOMER_DATA"
    });
    // customerDetail.segments !== null &&
    oMobileNav.push({
      navTitle: "Segments",
      navTag: "SEGMENTS"
    });
    // customerDetail.destinations.length !== 0 &&
    oMobileNav.push({
      navTitle: "Destinations",
      navTag: "DESTINAIONS"
    });

    // const { width, currentSection } = this.state;
    // const { customerDetail } = this.props;
    const isMobile = width <= 991;
    return (
      <React.Fragment>
        {isMobile && (
          <div className="mobile-top-menu ">
            <ListGroup>
              {oMobileNav.map(item => (
                <ListGroupItem
                  nav-label={item.navTitle}
                  onClick={() => this.handleCurrentSection(item.navTag)}
                  className={item.navTag === currentSection ? " active" : ""}
                >
                  {item.navTitle}
                </ListGroupItem>
              ))}
            </ListGroup>
          </div>
        )}

        {!isMobile &&
          (customerDetail.enrichments === null ? (
            <EnrichmentSummary
              lifetimeValue={null}
              spendBracket={null}
              chronotype={null}
            />
          ) : (
            <EnrichmentSummary
              lifetimeValue={customerDetail.enrichments.lifetimeValue}
              spendBracket={customerDetail.enrichments.spendBracket}
              chronotype={customerDetail.enrichments.chronotype}
            />
          ))}

        <div className="body-detail-wrap">
          {isMobile ? (
            this.renderMobileView(oMobileNav[0].navTag)
          ) : (
            <Row noGutters={true}>
              <Col lg={4}>
                <ProductDetail
                  products={
                    customerDetail.enrichments === null
                      ? {
                          viewHistory: [],
                          recommendations: [],
                          purchaseHistory: []
                        }
                      : customerDetail.enrichments.products
                  }
                />
              </Col>
              {/* {customerDetail.destinations.length > 0 ||
                (customerDetail.segments !== null && ( */}
              <Col lg={4}>
                {customerDetail.destinations.length !== 0 ? (
                  <DestinationDetail
                    destinations={customerDetail.destinations}
                  />
                ) : (
                  <NoRecord title="Destinations" subTitle="Destinations" />
                )}
                {customerDetail.segments !== null ? (
                  <SegmentDetail segments={customerDetail.segments} />
                ) : (
                  <NoRecord title="Segment" subTitle="Segments" />
                )}
              </Col>
              {/* ))} */}
              <Col lg={4}>
                {customerDetail.customerData.length !== 0 ? (
                  <CustomerDataDetail
                    customerData={customerDetail.customerData}
                  />
                ) : (
                  <NoRecord title="Customer Data" subTitle="Customer Data" />
                )}
              </Col>
            </Row>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default RecordView;
