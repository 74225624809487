import React, { Component } from "react";

import { Form, ListGroup, Badge } from "react-bootstrap";
import "./assets/styles.css";
import Sortimage from "./assets/img/sort.svg";

import moment from "moment";
import { dataFormat } from "../../../../../variables/globalVariables";
import UserAPI from "../../../../../services/users.js";
import ManageUser from "./ManageUser/ManageUser";
import { CSSTransitionGroup as AnimationSection } from "react-transition-group";
import SweetAlert from "react-bootstrap-sweetalert";
class ManageUsers extends Component {
  constructor() {
    super();
    this.state = {
      manageUser: [],
      manageUsers: [],
      isLoadingManagerUser: false,
      selectedUser: "",
      userSectionState: false,
      addPosition: "",
      isLoadingUsers: false,
      searchValue: "",
      showModel: false
    };
  }
  componentDidMount() {
    this.setState({
      selectedUser: this.props.managerUsers[0],
      manageUsers: this.props.managerUsers,
      isLoadingManagerUser: true,
      userSectionState: true,
      isLoadingUsers: true
    });
    this.updateUserSectionState();
    window.addEventListener("resize", this.updateUserSectionState.bind(this));
    this.props.onContextChange("USER");
    this.props.onContextIdChange(this.props.managerUsers[0].id);
  }

  componentWillReceiveProps(prevProps) {
    // console.log(prevProps);
    if (prevProps.managerUsers) {
      this.setState({ manageUsers: prevProps.managerUsers });
    }
  }

  updateUserSectionState() {
    if (window.innerWidth < 768) {
      this.setState({ userSectionState: false });
    } else {
      this.setState({ userSectionState: true });
    }
  }

  searchUsers = e => {
    this.setState({ searchValue: e.target.value });
    this.props.onSearch(
      "managerUsers",
      e.target.value,
      "firstName",
      "lastName"
    );
  };

  clearSearch = () => {
    this.setState({ searchValue: "" });
    this.props.onResetState("managerUsers");
  };

  componentWillUnmount() {
    this.props.onResetState("managerUsers");
    this.props.onContextChange(null);
    this.props.onContextIdChange(null);
  }

  renderBadge = membershipType => {
    switch (membershipType) {
      case "ADMIN":
        return (
          <Badge variant={"none"} className="badge badge-admin">
            {" "}
            {membershipType}
          </Badge>
        );
      case "CONTRIBUTOR":
        return (
          <Badge className="badge badge-contributor" variant="primary">
            {membershipType}
          </Badge>
        );
      case "TECHNICAL":
        return (
          <Badge className="badge badge-technical" variant="warning">
            {membershipType}
          </Badge>
        );
      case "READER":
        return (
          <Badge className="badge badge-reader" variant="danger">
            {membershipType}
          </Badge>
        );
      case "DISABLED":
        return (
          <Badge className="badge badge-disabled" variant="danger">
            {membershipType}
          </Badge>
        );
      case "NEWSFEED":
        return (
          <Badge className="badge badge-newsfeed" variant="danger">
            {membershipType}
          </Badge>
        );
      default:
        return <Badge variant="primary">{membershipType}</Badge>;
    }
  };

  checkIsSave = userid => {
    let checkChange = JSON.parse(localStorage.getItem("checkChange"));

    if (checkChange) {
      this.setState({ showModel: true, navigateto: userid });
    } else {
      this.getUsers(userid);
    }
  };
  getUsers = async userid => {
    try {
      await UserAPI.getUser(userid).then(response => {
        this.setState({
          selectedUser: response,
          isLoadingManagerUser: true,
          userSectionState: true
        });
        this.props.onContextChange("USER");
        this.props.onContextIdChange(response.id);

        if (window.innerWidth < 768) {
          setTimeout(() => {
            this.setState({
              addPosition: "position-fixed"
            });
          }, 300);
        }
      });
    } catch (ex) {
      // console.log(ex);
    }
  };
  handleDiscard = () => {
    localStorage.setItem("checkChange", false);
    this.setState({ showModel: false });
    this.getUsers(this.state.navigateto);
  };

  render() {
    const { currentUser } = this.props;
    const {
      selectedUser,
      manageUsers,
      isLoadingManagerUser,
      userSectionState,
      addPosition,
      isLoadingUsers
    } = this.state;
    return (
      <div className="user-section-wrap position-relative">
        <div className="body-content h-100">
          <div className={`left-wrap sideNav ${addPosition}`}>
            <Form inline className="top-header">
              <div className="d-flex search-user ">
                <ul className="d-flex ml-auto list-unstyled m-0 p-0">
                  <li className="position-relative item-search">
                    <input
                      type="text"
                      value={this.state.searchValue}
                      onChange={e => this.searchUsers(e)}
                    />
                    <i className="icon-search position-absolute" />
                  </li>
                  <div className="d-flex ml-auto">
                    <li className="item-sort" onClick={this.props.onSort}>
                      <img src={Sortimage} alt="sort" />
                    </li>
                  </div>
                </ul>
              </div>
            </Form>
            {isLoadingUsers &&
              (manageUsers.length > 0 ? (
                <ListGroup as="ul" defaultActiveKey={manageUsers[0].id}>
                  {manageUsers.map((managerUsers, key) => (
                    <ListGroup.Item
                      as="li"
                      key={key}
                      action
                      className={
                        this.state.selectedUser.id === managerUsers.id
                          ? "active d-flex"
                          : " d-flex"
                      }
                      onClick={() => this.checkIsSave(managerUsers.id)}
                    >
                      <div className="user-detail ">
                        <h6 className="">
                          {managerUsers.firstName} {managerUsers.lastName}
                        </h6>
                        <span className="secondary-font">
                          Last Logged In{" "}
                          <strong>
                            {moment(managerUsers.dateLastSeen).format(
                              dataFormat.MEDIUM
                            )}
                          </strong>
                        </span>
                      </div>

                      <div className="user-id">
                        {this.renderBadge(managerUsers.membershipType)}
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              ) : (
                <ListGroup as="ul">
                  <ListGroup.Item as="li" className="d-flex">
                    <div className="user-detail ">
                      <h6 className="">No user found</h6>
                      <span className="secondary-font">Search again</span>
                    </div>
                    <div className="user-id" onClick={this.clearSearch}>
                      <ListGroup as="ul" />
                      <Badge variant="warning">View All</Badge>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              ))}
          </div>
          <AnimationSection
            transitionName="from-right"
            transitionEnterTimeout={300}
            transitionLeaveTimeout={300}
          >
            {manageUsers.length > 0 &&
              isLoadingManagerUser &&
              userSectionState && (
                <ManageUser
                  manageUser={selectedUser}
                  currentUser={currentUser}
                  onUpdateSelectedUser={this.props.onUpdateSelectedUser}
                  onCheckChange={this.props.onCheckChange}
                  onVerifyChange={this.props.onVerifyChange}
                  onBackPress={() =>
                    this.setState({ userSectionState: false, addPosition: "" })
                  }
                />
              )}
          </AnimationSection>
        </div>

        {this.state.showModel && (
          <SweetAlert
            custom
            showCancel
            confirmBtnText="Discard"
            cancelBtnText="Cancel"
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="default"
            allowEscape="false"
            closeOnClickOutside="false"
            title="Discard Changes "
            onConfirm={() => this.handleDiscard()}
            onCancel={() => this.setState({ showModel: false })}
          >
            Changes will be discarded by clicking discard
          </SweetAlert>
        )}
      </div>
    );
  }
}

export default ManageUsers;
