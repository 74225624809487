import React from "react";
import distil_loader from "./img/distil_loader.png";
import "./css/loader.css";

const LoaderNew = props => {
  if (props.message !== undefined) {
    return (
      <div className={`loader-text ${props.adaptive ? "adaptive" : ""}`} style={props.style}>
        {getLoaderImage(true)}
        <p className="mt-1 mb-1">{props.message}</p>
      </div>
    );
  } else {
    return getLoaderImage(props.adaptive)
  }

  function getLoaderImage(adaptive = false) {
    return <img
      style={props.style}
      className={`loader ${adaptive ? "adaptive" : ""}`}
      src={distil_loader}
      alt=""
      width={props.size}
      height={props.size}
    />;
  }
};

export default LoaderNew;
