import React, { Component } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import Account from "../../views/Account";
import CreateAccount from "../../views/Account/CreateAccount";

import { Redirect, Switch, Route } from "react-router-dom";

import Notification from "../../components/Notification";
import EmailNotification from "../../components/EmailNotification/EmailNotification";

import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Search from "../../components/Search";
import Profile from "../../components/Profile";
import CustomerDetail from "../../components/CustomerDetail/customerDetail";
import UpdateUserInfo from "../../views/Auth/Update/UpdateUserInfo"

import Organisation from "../../services/organisation";
import { get } from "lodash";
import AccountAPI from "../../services/account";

import SweetAlert from "react-bootstrap-sweetalert";
import TrialExpired from "../Account/pages/TrialExpired";
import { CSSTransitionGroup as AnimationSection } from "react-transition-group";
import CardHistory from "./Newsfeed/components/CardHistoryNew/cardHistory";
import HelpModal from "../../components/HelpModal/HelpModalAdmin";
import { build } from "../../routes/main";
import { globalConstants } from "../../variables/globalVariables";
import equal from "fast-deep-equal";
let notificationPolling = "";

class Main extends Component {
  constructor(props) {
    super(props);
    let selectedMembers = JSON.parse(sessionStorage.getItem("selectedMembers"));
    let userAccountData = JSON.parse(localStorage.getItem("userAccountData"));

    this.state = {
      isAccountSelected: selectedMembers !== null,
      contextUser: get(userAccountData, 'contextUser'),
      showHelpModal: false,
      selectedMembers: selectedMembers || {} ,
      sidebarSelected: "",
      isSearch: false,
      mainToggle: true,
      pinToggle: false,
      currentPage: "Daily Insight",
      customerSelected: false,
      customerSelectedID: null,
      currentContext: null,
      currentContextId: null,
      opencontextTab: false,
      searchCriteria: "",
      isChanged: false,
      routName: "",
      targetPage: null,
      newsFeedcards: null,
      showCardHistory: false,
      currentHistoryCard: null,
      pinnedUpdated: null,
      isExprired: false,
      isLoaded: true,
      isOrgTrialAccount: false,
      isRedirected:false
    };
    localStorage.setItem('isOpenNavMenu', `${this.state.mainToggle}`);
    window.addEventListener('storage', this.listenerSelectedMembers);
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.currentRouteName();
    });
  }

  componentDidMount() {
    let selectedMembers = JSON.parse(sessionStorage.getItem("selectedMembers"));
    let userAccountData = JSON.parse(localStorage.getItem("userAccountData"));

    localStorage.setItem("checkChange", false);
    if (selectedMembers !== null) {
      this.setState({
        isAccountSelected: true,
        selectedMembers: selectedMembers,
        contextUser: userAccountData.contextUser
      });

      this.currentRouteName();

      this.pollingNotificationCount();
      notificationPolling = setInterval(() => {
        this.pollingNotificationCount();
      }, 600000);
    }
    document.addEventListener("keydown", this.escFunction, false);

    if (performance.navigation.type === 1 && selectedMembers !== null) {
      this.setState(
        { isLoaded: false },
        () => this.reloadMemberInfo(selectedMembers),
        this.reloadMemberInfo(selectedMembers),

        // (selectedMembers !== null &&
        //   selectedMembers.membershipType === "ADMIN") ||
        //   selectedMembers.membershipType === "TECHNICAL" ||
        //   selectedMembers.membershipType === "CONTRIBUTOR"
        //   ? this.setState({ currentPage: "Settings" })
        //   : this.setState({ currentPage: "Newsfeed" })
      );
      console.log("This page is Refreshed?");
    }
  }

  listenerSelectedMembers = (e) => {
    try {
      let prevValue;

      if (e.oldValue) {
        prevValue = JSON.parse(e.oldValue);
      }

      let checkCustomUpdateOrg = e.detail && e.detail.name === "updateOrgMemberShip";

      if (e.type === 'storage' && Object.keys(this.state.selectedMembers).length !== 0 && (prevValue || checkCustomUpdateOrg)) {
        AccountAPI.getOrgInfoWithoutSaveToStore(this.state.selectedMembers.id).then((orgInfo) => {
          if (checkCustomUpdateOrg || !equal(prevValue.orgMembership, orgInfo.orgMembership)) {
            sessionStorage.setItem("selectedMembers", JSON.stringify(orgInfo.orgMembership));
            this.setState({
                 selectedMembers: orgInfo.orgMembership
            })
          }
        })
      }
    } catch (e) {
      console.log(e);
    }
  }


  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
    clearInterval(notificationPolling);
    this.unlisten();

    if (this.state.selectedMembers.daysLeftInContract < 0) {
      this.setState({
        isExprired: true
      });
    }
  }

  reloadMemberInfo = async selectedMembers => {
    try {
      await AccountAPI.getOrgInfo(selectedMembers.id).then(() => {
        this.setState({ isLoaded: true });
      });
    } catch (err) {
      this.setState({ isLoaded: true });
    }
  };

  escFunction = event => {
    if (event.keyCode === 27) {
      {
        this.setState({
          pinToggle: false,
          mainToggle: false,
          pinnedUpdated: null,
          isSearch: false
        });
      }
    }
  };

  pollingNotificationCount = async () => {
    await Organisation.checkOrganisationNotification().then(response => {
      const newsfeedNotificationCount = response;
      this.setState({ newsfeedNotificationCount });
    });
  };

  onSelectAccount = (contextUser, selectedMembers) => {
    sessionStorage.setItem("selectedMembers", JSON.stringify(selectedMembers));
      this.setState({
        isExprired: false,
        isAccountSelected: true
      });
    this.setState(
      {
        selectedMembers,
        contextUser
      },
      () => this.getContextUserInfo(selectedMembers)
    );

  };
  handleHasNoData = () => {
    let selectedMembers = JSON.parse(sessionStorage.getItem("selectedMembers"));
    if (
      selectedMembers !== null && !this.state.isRedirected &&
      (selectedMembers.membershipType === "ADMIN" ||
        selectedMembers.membershipType === "TECHNICAL" ||
        selectedMembers.membershipType === "CONTRIBUTOR")
    ) {
      this.setState({ currentPage: "Settings", isRedirected:true });
      this.props.history.push("/settings/integrations");
    } else {
      // this.setState({ currentPage: "Newsfeed" });
    }
  };

  getContextUserInfo = async selectedMembers => {
    try {
      await AccountAPI.getOrgInfo(selectedMembers.id).then(response => {
        const baseSetupDone = response.baseSetupDone;
        // console.log(response.baseSetupDone);
        this.setState({ showHelpModal: !baseSetupDone });
      });
    } catch (err) {
      this.setState({ showHelpModal: true });
    }
  };
  closeother = () => {
    this.setState({ isSearch: false, pinToggle: false, isUserSetting: false });
    if (window.innerWidth < 768) {
      this.setState({ mainToggle: false });
    }
  };
  onSearch = () => {
    this.closeother();
    this.setState({ isSearch: !this.state.isSearch });
  };
  onCloseSearch = () => {
    this.setState({ isSearch: false });
  };
  onToggleSidebar = () => {
    this.closeother();
    this.setState({ mainToggle: !this.state.mainToggle });
    localStorage.setItem('isOpenNavMenu', `${!this.state.mainToggle}`);
  };
  onTogglePin = (status = false) => {
    this.closeother();
    if (status) {
      this.setState({ opencontextTab: true });
    } else {
      this.setState({ opencontextTab: false });
    }
    if (this.state.pinToggle) {
      this.setState({ pinToggle: false });
    } else {
      this.setState({ pinToggle: true });
    }
  };

  handleOutSideClicked = () => {
    this.setState({
      currentContext: null,
      pinToggle: false,
      pinnedUpdated: null,
      isSearch: false
    });
  };

  handleCurrentContext = currentContext => {
    this.setState({ currentContext });
  };

  handleCurrentIdContext = currentContextId => {
    this.setState({ currentContextId });
  };

  updateContextUser = formData => {
    let contextUser = this.state.contextUser;
    contextUser.firstName = formData.firstName;
    contextUser.lastName = formData.lastName;
    contextUser.emailAddress = formData.emailAddress;
    this.setState({ contextUser });
  };

  handleCustomerDetail = customer => {
    this.setState({
      customerSelected: true,
      customerSelectedID: customer,
      isSearch: false
    });
  };
  hideCustomerDetail = () => {
    this.setState({ customerSelected: false, customerSelectedID: null });
  };
  onSearchCriteria = criteria => {
    this.setState({ searchCriteria: criteria });
  };

  handleCheckChange = () => {
    localStorage.setItem("checkChange", true);
  };

  handleVerifyChange = (e, path) => {
    let checkChange = JSON.parse(localStorage.getItem("checkChange"));

    if (checkChange) {
      e.preventDefault();

      this.setState({ showModel: true, nextPath: path, targetPage: null });
    } else {
      return true;
    }
  };

  closeSideBar = () => {
    if (window.innerWidth < 768) {
      this.onToggleSidebar();
    }
  };
  handleDiscard = () => {
    localStorage.setItem("checkChange", false);
    this.setState({ showModel: false });
    let currentpage = this.state.nextPath + "/";
    this.props.history.push(currentpage);
    if (this.state.targetPage !== null) {
      this.setState({ currentPage: this.state.targetPage });
    }
    this.closeSideBar();
  };

  handleMainPage = (e, route) => {
    let checkChange = JSON.parse(localStorage.getItem("checkChange"));
    this.setState({ routName: route.name });
    if (checkChange) {

      this.setState({
        showModel: true,
        nextPath: route.path,
        targetPage: route.name
      });
    } else {
      this.setState({ currentPage: route.name });
      this.closeSideBar();
    }
  };

  handleCardHistory = currentCard => {
    this.setState({ showCardHistory: true, currentHistoryCard: currentCard });
  };
  handleHistoryCardClose = () => {
    this.setState({ showCardHistory: false, currentHistoryCard: null });
  };

  handlePinnedCard = pinnedUpdated => {
    // console.log(pinnedUpdated)
    this.setState({ pinnedUpdated });
  };

  currentRouteName = () => {
    let selectedMembers = this.state.selectedMembers;
    let location = window.location;
    let currentPage;
    let pageRouter;
    let { CONTRIBUTOR, TECHNICAL, READER, NEWSFEED, ADMIN } = build(selectedMembers.cdpPermissions);
    switch (this.state.selectedMembers.membershipType) {
      case "CONTRIBUTOR":
        pageRouter = CONTRIBUTOR;
        break;
      case "TECHNICAL":
        pageRouter = TECHNICAL;
        break;
      case "READER":
        pageRouter = READER;
        break;
      case "NEWSFEED":
        pageRouter = NEWSFEED;
        break;
      default:
        pageRouter = ADMIN;
        break;
    }
    location = '/' + location.pathname.split('/')[1];
    if (selectedMembers.cdpPermissions) {
      let pageRouterLocation = pageRouter.find(route => route.path === location);
      if (pageRouterLocation !== undefined) {
        currentPage = pageRouterLocation.name;
      } else {
        currentPage = "Main";
      }
    } else {
      currentPage = "Main";
    }
    this.setState({currentPage});
  };

  render() {
    let {
      isAccountSelected,
      selectedMembers,
      isSearch,
      mainToggle,
      pinToggle,
      contextUser,
      currentPage,
      newsfeedNotificationCount,
      customerSelected,
      customerSelectedID,
      currentContext,
      currentContextId,
      opencontextTab,
      newsFeedcards,
      showCardHistory,
      currentHistoryCard,
      pinnedUpdated,
      isLoaded,
      isOrgTrialAccount
    } = this.state;

    const pinnedCards =
      newsFeedcards !== null &&
      newsFeedcards.filter(card => card.pinned === true);
    return !isAccountSelected && isLoaded ? (
        <Switch>
          <Route
            path="/chooseaccount"
            name="Account"
            render={props => (
              <Account onSelectAccount={this.onSelectAccount} {...props} />
            )}
          />
          <Route
            path="/createaccount"
            name="CreateAccount"
            render={props => (
              <CreateAccount
                {...props}
                onSelectAccount={this.onSelectAccount}
              />
            )}
          />
          <Route
            path="/getdistil"
            name="TrialExpired"
            render={props => (
              <TrialExpired
                {...props}
                selectedMembers={this.state.selectedMembers}
                onGetBack={() =>
                    this.setState({ isOrgTrialAccount: false })
                }
              />
            )}
          />
          <Route
            exact
            path="/update"
            name="Update User Info Page"
            render={props => <UpdateUserInfo {...props} />}
          />

          <Redirect from="/" to="/chooseaccount" />
        </Switch>

    ) : (
      <React.Fragment>
        {(
          <div
            className={`main ${mainToggle ? "main-toggle" : ""} ${
                this.state.selectedMembers.isOrgTrialAccount ? "" : "hidden-trial"
            } ${currentPage === "Customers" && " hide-navscroll"}`}
          >
            <Sidebar currentPage={this.handleMainPage} />
            <div className="fixed-top">
              {(selectedMembers.accountType !== 'ACTIVATED_PAYING' || selectedMembers.fkStripeProductId === 0) && (
                  <Notification
                    selectedMembers={this.state.selectedMembers}
                    isOrgTrialAccount={isOrgTrialAccount}
                    onIsOrgTrialAccount={() =>
                      this.setState({ isOrgTrialAccount: true })
                    }
                  />
                )}
              {contextUser.emailVerified == null && (
                <EmailNotification
                  selectedMembers={selectedMembers}
                  contextUser={contextUser}
                />
              )}
              {
                <CustomerDetail
                  customerSelected={customerSelected}
                  customerSelectedID={customerSelectedID}
                  onhide={this.hideCustomerDetail}
                />
              }
              <Header
                onHelpModal={() => this.setState({ showHelpModal: true })}
                currentPage={currentPage}
                contextUser={contextUser}
                selectedMembers={selectedMembers}
                isSearch={isSearch}
                newsfeedNotificationCount={newsfeedNotificationCount}
                pinToggle={pinToggle}
                onSearch={this.onSearch}
                onToggleSidebar={this.onToggleSidebar}
                onTogglePin={this.onTogglePin}
                onVerifyChange={this.handleVerifyChange}
              />
            </div>

            <AnimationSection
              transitionName="from-right"
              transitionEnterTimeout={300}
              transitionLeaveTimeout={300}
            >
              {isSearch && (
                <Search
                  onCustomerDetail={this.handleCustomerDetail}
                  onCloseSearch={this.onCloseSearch}
                  searchCriteria={this.state.searchCriteria}
                  onSearchCriteria={this.onSearchCriteria}
                  onEscape={this.handleOutSideClicked}
                />
              )}
              {pinToggle && (
                <Profile
                  currentPage={currentPage}
                  currentContext={currentContext}
                  currentContextId={currentContextId}
                  opencontextTab={opencontextTab}
                  onEscape={this.handleOutSideClicked}
                  updatedPinnedCard={this.handlePinnedCard}
                  pinnedCards={pinnedCards}
                  onCardHistory={this.handleCardHistory}
                />
              )}
            </AnimationSection>

            <DefaultLayout
              contextUser={contextUser}
              pinnedUpdated={pinnedUpdated}
              currentPage={currentPage}
              updateContextUser={this.updateContextUser}
              onTogglePin={this.onTogglePin}
              onCustomerDetail={this.handleCustomerDetail}
              onContextChange={this.handleCurrentContext}
              hasNoData={this.handleHasNoData}
              onContextIdChange={this.handleCurrentIdContext}
              onCheckChange={this.handleCheckChange}
              onVerifyChange={this.handleVerifyChange}
              onPinnedCard={this.handlePinnedCard}
              newsFeedcards={newsFeedcards}
              onCardHistory={this.handleCardHistory}
              onPageChange={currentPage => this.setState({ currentPage })}
            />

            {this.state.showModel && (
              <SweetAlert
                custom
                showCancel
                confirmBtnText="Discard"
                cancelBtnText="Cancel"
                confirmBtnBsStyle="primary"
                cancelBtnBsStyle="default"
                allowEscape="false"
                closeOnClickOutside="false"
                title="Discard Changes "
                onConfirm={() => this.handleDiscard()}
                onCancel={() => this.setState({ showModel: false })}
              >
                Changes will be discarded by clicking discard
              </SweetAlert>
            )}

            <CardHistory
              status={showCardHistory}
              currentHistoryCard={currentHistoryCard}
              onClose={this.handleHistoryCardClose}
            />
            <HelpModal
              contextUser={contextUser}
              selectedMembers={selectedMembers}
              showHelpModal={this.state.showHelpModal}
              onCloseModal={() => this.setState({ showHelpModal: false })}
              onPageChange={currentPage => this.setState({ currentPage })}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default Main;
