import React, {Component} from "react";
import {ListGroup} from "react-bootstrap";
import {toast} from "react-toastify";
import {CSSTransitionGroup as AnimationSection} from "react-transition-group";
import "./assets/style.css";

import DataConnectionAPI from "../../../../services/dataConnections";
import ConectionDetail from "../Dataconnections/connectiondetail/ConectionDetail";

import connectionTypes, {
  connectionTypeLabels,
  hiddenConnectionTypes
} from "./connectionTypes";

import MailChimp from "./forms/MailChimp";
import Klaviyo from "./forms/Klaviyo";
import ApiConnection from './forms/ApiConnection';
import CampaignMonitor from "./forms/CampaignMonitor";
import SalesForce from "./forms/SalesForce";
import Loader from "../../../../components/Loader/loader";
import NoData from "../../../../components/NoData/nodata";

import SweetAlert from "react-bootstrap-sweetalert";
import DotDigital from "./forms/DotDigital";
import {find, get} from "lodash";
import ZapierConnection from "./forms/ZapierConnection";
import Hubspot from "./forms/HubSpot";
import Shopify from "./forms/Shopify";
import Airship from "./forms/Airship";
import Zendesk from "./forms/Zendesk";
import ShopifyBasic from './forms/ShopifyBasic';
import GoogleAds from './forms/GoogleAds';
import Iterable from "./forms/Iterable";
import PayPal from "./forms/PayPal";
import Yotpo from "./forms/Yotpo";
import FacebookAds from './forms/FacebookAds';
import Braze from "./forms/Braze";

class Integrations extends Component {

  dataSourcePolling = 0;
  lastCallTime = 0;

  state = {
    defaultConnection: 1,
    dataconnections: [],
    isLoading: false,
    dataDetailSelected: [],
    selectedID: "",
    addPosition: null,
    currentDataSource: [],
    isMobile: false,
    newConnection: false,
    newForm: null,
    editConnection: false,
    showModel: false,
    targetConnection: null,
    targetconnectionId: null,
    isRefreshRequested: true,
    dataSourceSyncing: false,
    isConnectionTested: "TEST",
    isInfoLoaded: false,
    isDisabled: false
  };

  componentWillMount() {
    this._isMounted = true;
    const selectedConnection = get(this.props, 'location.state.selectedID');

    this.props.onContextChange("INTEGRATION");
    this.getIntegration(selectedConnection).then();

    this.updateUserSectionState();
    window.addEventListener("resize", this.updateUserSectionState.bind(this));
    if (this.getUrlVars(window.location.href)["code"]) {
      // todo remove hardcode
      if (this.getUrlVars(window.location.href)["scope"]){
        this.setState({newForm: "GOOGLE_ADS", newConnection: true});
      } else {
        this.setState({newForm: "FACEBOOK_LEAD_ADS", newConnection: true});
      }
    }
  }

  getUrlVars(url) {
    var vars = {};
    url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value;
    });
    return vars;
  };


  componentWillUnmount() {
    this._isMounted = false;
    this.props.onContextChange(null);
    this.props.onContextIdChange(null);
    this.resetTimeout();
  }

  updateUserSectionState() {
    if (window.innerWidth < 768) {
      this.setState({ userSectionState: false });
    } else {
      this.setState({ userSectionState: true });
    }
  }

  resetTimeout = () => {
    clearTimeout(this.dataSourcePolling);
    this.lastCallTime = 0;
  };

  onUpdateDataSourcesState = (dataSourceIds, connectionId, syncTurnedOn) => {
    this.resetTimeout();
    return DataConnectionAPI.updateDataSourcesState(dataSourceIds, connectionId, syncTurnedOn)
      .then(_ => this.getDataSource(this.state.selectedID, true, false))
  };

  getIntegration = async selectedIntegration => {
    this.setState({ isInfoLoaded: false });
    try {
      await DataConnectionAPI.getIntegration().then(response => {
        response = (response || []).filter(({connectionType}) => connectionType !== 'DWH');
        this.setState({
          dataconnections: response,
          isLoading: true,
          isInfoLoaded: true
        });

        const selectedID = selectedIntegration || response[0].id;
        const dataDetailSelected = find(response, {id: selectedID});

        if (response.length > 0) {
          this.setState({
            dataDetailSelected: [dataDetailSelected],
            selectedID
          });
          this.props.onContextChange("INTEGRATION");
          this.props.onContextIdChange(selectedID);
        }
        return this.getDataSource(selectedID);
      });
    } catch (err) {
      this.setState({
        isInfoLoaded: true
      });
    }
  };

  getDataSource = async (id, isNew = false, changeLoadingStatus = true) => {
    if (!id) {
      return;
    }

    const requestDate = this.updateCallTime();

    this.props.onContextChange("INTEGRATION");
    this.props.onContextIdChange(id);

    if (changeLoadingStatus) {
      this.setState({ isInfoLoaded: false });
    }

    try {
      await DataConnectionAPI.getDataSource(id).then(response => {

        // check for correct connection data
        if (response.length !== 0 && response[0].connectionId != this.state.selectedID) {
          return;
        }

        // stop as expired background task
        if (this.lastCallTime !== requestDate) {
          return;
        }

        console.log(`Getting data sources for integration ${id} at ${new Date().toLocaleString()}`);

        this.setState({
          currentDataSource: response,
          isLoading: true,
          isInfoLoaded: true
        }, () => {
          this.aggregateDatasourceSyncStatuses(response);
        });
      });
    } catch (err) {
      this.setState({
        isInfoLoaded: true
      });
    }
  };

  aggregateDatasourceSyncStatuses = (dataSources) => {
    for (let i = 0; i < dataSources.length; i++) {
      if (dataSources[i].lastDataSourceSyncStatus === 'SYNC_IN_PROGRESS') {
        this.setState({dataSourceSyncing: true}, () => this.startPolling());
        return;
      }
    }
    this.setState({dataSourceSyncing: false});
  };

  startPolling = () => {
    if (!this._isMounted) {
      return;
    }

    this.resetTimeout();
    this.dataSourcePolling = setTimeout(() => {
      this.getDataSource(this.state.selectedID, true, false).then()
    }, 5000);
  };

  updateCallTime =() => {
    const newTime = new Date().getTime();
    this.lastCallTime = newTime;
    return newTime;
  };

  updateDatasrcAttr = data => {
    const currentDataSource = this.state.currentDataSource;
    const itemIndex = currentDataSource.findIndex(item => item.id === data.id);
    currentDataSource[itemIndex] = data;
    this.setState({ currentDataSource });
  };

  testConnection = async () => {
    this.setState({ isConnectionTested: "TESTING" });
    try {
      await DataConnectionAPI.testConnection(
        this.state.dataDetailSelected[0]
      ).then(response => {
        if (response) {
          this.setState({ isConnectionTested: "PASS" });
          toast.success("Connection is Working");
        } else {
          this.setState({ isConnectionTested: "FAIL" });
          toast.error("Connection is not working");
        }
      });
    } catch (err) {}
  };

  refreshConnection = async () => {
    try {
      await DataConnectionAPI.refreshConnection(
        this.state.dataDetailSelected[0].id
      ).then(response => {
        const dataDetailSelected = [{ ...response }];
        this.setState({ dataDetailSelected, isRefreshRequested: true });
        toast.success("Refresh Requested");
      });
    } catch (err) {}
  };

  disableConnection = async () => {
    this.setState({ isDisabled: true });
    const disableconnection = this.state.dataDetailSelected[0];
    disableconnection.enabled = !disableconnection.enabled;

    const dataconnections = this.state.dataconnections;

    const itemIndex = dataconnections.findIndex(
      item => item.id === disableconnection.id
    );

    // check connection only for enabling the ds
    const checkConnection = disableconnection.enabled;

    try {
      await DataConnectionAPI.disableConnection(disableconnection, checkConnection).then(
        response => {
          this.setState({ disableconnection, dataconnections });
          this.setState({ isDisabled: false });
          response.enabled ? toast.success("Connection Enabled")
            : toast.success("Connection Disabled");
        }
      );
    } catch (err) {
      dataconnections[itemIndex].enabled
        ? toast.error("The Connection test failed - please update the connection settings and try again")
        : toast.error("Connection Disabled Fail");

      dataconnections[itemIndex].enabled = !dataconnections[itemIndex].enabled;
      this.setState({ isDisabled: false });
    }
  };
  setDefaultTestState = () => {
      this.setState({isConnectionTested: "TEST"});
  };

  renderConnectionList() {
    if (this.state.isLoading) {
      return this.state.dataconnections.length > 0 ? (
        <ListGroup
          as="ul"
          defaultActiveKey={this.state.dataconnections[0].id}
          activeKey={this.state.selectedID}
          onSelect={selectedKey => this.onConnectionSelect({ selectedKey })}
        >
          {this.state.dataconnections.map(
            listItem =>
              listItem.id !== this.state.defaultConnection && (
                <ListGroup.Item
                  as="li"
                  action
                  eventKey={listItem.id}
                  key={listItem.id}
                  onClick={this.setDefaultTestState}
                >
                  <h6 className="">
                    {listItem.name}{" "}
                    {!listItem.enabled && (
                      <span className="icon-disable float-right" />
                    )}
                  </h6>
                  <span className="secondary-font">
                    {connectionTypeLabels[listItem.connectionType] || listItem.connectionType}
                    {listItem.numDataSources > 0 && ", "}
                    {listItem.numDataSources > 0 &&
                      listItem.numDataSources + " Data  Sources"}
                  </span>
                </ListGroup.Item>
              )
          )}
        </ListGroup>
      ) : (
        <ListGroup as="ul">
          <ListGroup.Item as="li" className="text-center pl-0 pr-0">
            No integrations configured yet
          </ListGroup.Item>
        </ListGroup>
      );
    } else {
      return (
        <ListGroup as="ul">
          <ListGroup.Item as="li" className="text-center pl-0 pr-0">
            Loading integration list
          </ListGroup.Item>
        </ListGroup>
      );
    }
  }

  onConnectionSelect = id => {
    let checkChange = JSON.parse(localStorage.getItem("checkChange"));

    if (checkChange) {
      this.setState({
        showModel: true,
        targetconnectionId: id,
        targetConnection: null
      });
    } else {
      this.handleConnectionSelect(id);
    }
  };

  handleConnectionSelect = id => {
    this.resetTimeout();
    const fileteddata = this.state.dataconnections.filter(
      item => item.id === JSON.parse(id.selectedKey)
    );
    this.setState({
      isLoading: false
    });

    this.setState({
      dataDetailSelected: fileteddata,
      isLoading: true,
      userSectionState: true,
      newConnection: false,
      selectedID: id.selectedKey
    }, () => {
      this.getDataSource(id.selectedKey).then();
    });

    if (window.innerWidth < 768) {
      setTimeout(() => {
        this.setState({
          addPosition: "position-fixed",
          isMobile: true
        });
      }, 300);
    }
  };

  onBackPress = () => {
    this.setState({ userSectionState: false });
    setTimeout(() => {
      this.setState({
        addPosition: ""
      });
    }, 300);
  };

  connectionOptions() {
    return connectionTypes.length > 0 ? (
      <ListGroup as="ul">
        {connectionTypes.filter(
            item => !hiddenConnectionTypes.includes(item.name)
        ).map(dataconnectionsItem => (
          <ListGroup.Item
            as="li"
            key={dataconnectionsItem.name}
            className="d-flex"
            onClick={() => this.onNewConnection(dataconnectionsItem.name)}
          >
            <img
              src={dataconnectionsItem.image}
              className="brand-logo"
              alt="connection-icon"
            />
          </ListGroup.Item>
        ))}
      </ListGroup>
    ) : (
      "Waiting For Integration Lists"
    );
  }

  onNewConnection = name => {
    let checkChange = JSON.parse(localStorage.getItem("checkChange"));

    if (checkChange) {
      this.setState({
        showModel: true,
        targetConnection: name,
        targetconnectionId: null
      });
    } else {
      this.handleNewConnection(name);
    }
  };
  handleNewConnection = name => {
    this.setState({
      newConnection: true,
      newForm: name,
      editConnection: false,
      userSectionState: true,
      selectedID: null
    });

    if (window.innerWidth < 768) {
      setTimeout(() => {
        this.setState({
          addPosition: "position-fixed",
          isMobile: true
        });
      }, 300);
    }
  };

  handleDiscard = () => {
    localStorage.setItem("checkChange", false);
    this.setState({ showModel: false });
    if (this.state.targetConnection !== null) {
      this.handleNewConnection(this.state.targetConnection);
    }
    if (this.state.targetconnectionId !== null) {
      this.handleConnectionSelect(this.state.targetconnectionId);
    }
    if (this.state.editingConnection) {
      // this.closeEditingConnection();
    }
    this.closeEditingConnection();
  };

  onAddingConnection = async newConnectionId => {
    this.resetTimeout();

    this.setState({selectedID: newConnectionId, isLoading: false});
    try {
      await DataConnectionAPI.getIntegration().then(response => {
        response = (response || []).filter(({connectionType}) => connectionType !== 'DWH');

        const fileteddata = response.filter(
          item => item.id === JSON.parse(newConnectionId)
        );

        this.getDataSource(newConnectionId, true).then();

        this.setState({
          dataconnections: response,
          dataDetailSelected: fileteddata,
          isLoading: true,
          userSectionState: true,
          newConnection: false,
          selectedID: newConnectionId
        });

        if (window.innerWidth < 768) {
          setTimeout(() => {
            this.setState({
              addPosition: "position-fixed",
              isMobile: true
            });
          }, 300);
        }
      });
    } catch (err) {}
  };

  onEditConnection = () => {
    this.setState({
      newConnection: true,
      newForm: this.state.dataDetailSelected[0].connectionType,
      userSectionState: true,
      editConnection: true
      // selectedID: null
    });

    if (window.innerWidth < 768) {
      setTimeout(() => {
        this.setState({
          addPosition: "position-fixed",
          isMobile: true
        });
      }, 300);
    }

    //this.activeList(0);
  };

  cancelConnection = () => {
    let checkChange = JSON.parse(localStorage.getItem("checkChange"));
    if (checkChange) {
      this.setState({
        showModel: true
      });
    } else {
      this.closeEditingConnection();
    }
  };
  closeEditingConnection = () => {
    this.setState({
      newConnection: false,
      newForm: null,
      editConnection: false,
      editingConnection: false,
      selectedID: this.state.dataDetailSelected[0].id
    });
  };

  getConnectionForm() {
    if (this.state.newForm !== null) {
      switch (this.state.newForm) {
        case "MAILCHIMP":
          return (
            <MailChimp
              onCancelConnection={this.cancelConnection}
              formType={this.state.newForm}
              editConnection={this.state.editConnection}
              selectedConnection={this.state.dataDetailSelected[0]}
              onBackPress={this.onBackPress}
              onAddingConnection={this.onAddingConnection}
              onCheckChange={this.props.onCheckChange}
            />
          );
        case "KLAVIYO":
          return (
            <Klaviyo
              onCancelConnection={this.cancelConnection}
              formType={this.state.newForm}
              editConnection={this.state.editConnection}
              selectedConnection={this.state.dataDetailSelected[0]}
              onBackPress={this.onBackPress}
              onAddingConnection={this.onAddingConnection}
              onCheckChange={this.props.onCheckChange}
            />
          );
        case "KLAVIYO_V2":
          return (
              <Klaviyo
                  onCancelConnection={this.cancelConnection}
                  formType={this.state.newForm}
                  editConnection={this.state.editConnection}
                  selectedConnection={this.state.dataDetailSelected[0]}
                  onBackPress={this.onBackPress}
                  onAddingConnection={this.onAddingConnection}
                  onCheckChange={this.props.onCheckChange}
              />
          );
        case "CAMPAIGN_MONITOR":
          return (
            <CampaignMonitor
              onCancelConnection={this.cancelConnection}
              formType={this.state.newForm}
              editConnection={this.state.editConnection}
              selectedConnection={this.state.dataDetailSelected[0]}
              onBackPress={this.onBackPress}
              onAddingConnection={this.onAddingConnection}
              onCheckChange={this.props.onCheckChange}
            />
          );
        case "SALESFORCE":
          return (
            <SalesForce
              formType={this.state.newForm}
              onCancelConnection={this.cancelConnection}
              editConnection={this.state.editConnection}
              selectedConnection={this.state.dataDetailSelected[0]}
              onBackPress={this.onBackPress}
              onAddingConnection={this.onAddingConnection}
              onCheckChange={this.props.onCheckChange}
            />
          );
        case "DOT_DIGITAL":
          return (
            <DotDigital
              onCancelConnection={this.cancelConnection}
              formType={this.state.newForm}
              editConnection={this.state.editConnection}
              selectedConnection={this.state.dataDetailSelected[0]}
              onBackPress={this.onBackPress}
              onAddingConnection={this.onAddingConnection}
              onCheckChange={this.props.onCheckChange}
            />
          );
        case "DOT_DIGITAL_V3":
          return (
            <DotDigital
                    onCancelConnection={this.cancelConnection}
                    formType={this.state.newForm}
                    editConnection={this.state.editConnection}
                    selectedConnection={this.state.dataDetailSelected[0]}
                    onBackPress={this.onBackPress}
                    onAddingConnection={this.onAddingConnection}
                    onCheckChange={this.props.onCheckChange}
            />
          );
        case "ZAPIER":
          return (
            <ZapierConnection
              onCancelConnection={this.cancelConnection}
              formType={this.state.newForm}
              editConnection={this.state.editConnection}
              selectedConnection={this.state.dataDetailSelected[0]}
              onBackPress={this.onBackPress}
              onAddingConnection={this.onAddingConnection}
              onCheckChange={this.props.onCheckChange}
            />
          );
        case "API_CONNECTION":
          return (
            <ApiConnection
              onCancelConnection={this.cancelConnection}
              formType={this.state.newForm}
              editConnection={this.state.editConnection}
              selectedConnection={this.state.dataDetailSelected[0]}
              onBackPress={this.onBackPress}
              onAddingConnection={this.onAddingConnection}
              onCheckChange={this.props.onCheckChange}
            />
          );
        case "SHOPIFY":
          return (
            <ShopifyBasic
              onCancelConnection={this.cancelConnection}
              formType={this.state.newForm}
              params={this.getUrlVars(window.location.href)}
              editConnection={this.state.editConnection}
              selectedConnection={this.state.dataDetailSelected[0]}
              onBackPress={this.onBackPress}
              onAddingConnection={this.onAddingConnection}
              onCheckChange={this.props.onCheckChange}
            />
          );
        case "AIRSHIP":
          return (
              <Airship
                  onCancelConnection={this.cancelConnection}
                  formType={this.state.newForm}
                  params={this.getUrlVars(window.location.href)}
                  editConnection={this.state.editConnection}
                  selectedConnection={this.state.dataDetailSelected[0]}
                  onBackPress={this.onBackPress}
                  onAddingConnection={this.onAddingConnection}
                  onCheckChange={this.props.onCheckChange}
              />
          );
        case "HUBSPOT":
          return (
              <Hubspot
                  onCancelConnection={this.cancelConnection}
                  formType={this.state.newForm}
                  editConnection={this.state.editConnection}
                  selectedConnection={this.state.dataDetailSelected[0]}
                  onBackPress={this.onBackPress}
                  onAddingConnection={this.onAddingConnection}
                  onCheckChange={this.props.onCheckChange}
              />
          );
        case "ITERABLE":
          return (
              <Iterable
                  onCancelConnection={this.cancelConnection}
                  formType={this.state.newForm}
                  editConnection={this.state.editConnection}
                  selectedConnection={this.state.dataDetailSelected[0]}
                  onBackPress={this.onBackPress}
                  onAddingConnection={this.onAddingConnection}
                  onCheckChange={this.props.onCheckChange}
              />
          );
        case "ZEN_DESK":
          return (
              <Zendesk
                  onCancelConnection={this.cancelConnection}
                  formType={this.state.newForm}
                  editConnection={this.state.editConnection}
                  selectedConnection={this.state.dataDetailSelected[0]}
                  onBackPress={this.onBackPress}
                  onAddingConnection={this.onAddingConnection}
                  onCheckChange={this.props.onCheckChange}
              />
          );
        case "GOOGLE_ADS":
          return (
              <GoogleAds
                  onCancelConnection={this.cancelConnection}
                  formType={this.state.newForm}
                  params={this.getUrlVars(window.location.href)}
                  editConnection={this.state.editConnection}
                  selectedConnection={this.state.dataDetailSelected[0]}
                  onBackPress={this.onBackPress}
                  onAddingConnection={this.onAddingConnection}
                  onCheckChange={this.props.onCheckChange}
              />
          );
        case "YOTPO":
          return (
              <Yotpo
                  onCancelConnection={this.cancelConnection}
                  formType={this.state.newForm}
                  params={this.getUrlVars(window.location.href)}
                  editConnection={this.state.editConnection}
                  selectedConnection={this.state.dataDetailSelected[0]}
                  onBackPress={this.onBackPress}
                  onAddingConnection={this.onAddingConnection}
                  onCheckChange={this.props.onCheckChange}
              />
          );
        case "FACEBOOK_LEAD_ADS":
          return (
              <FacebookAds
                  onCancelConnection={this.cancelConnection}
                  formType={this.state.newForm}
                  params={this.getUrlVars(window.location.href)}
                  editConnection={this.state.editConnection}
                  selectedConnection={this.state.dataDetailSelected[0]}
                  onBackPress={this.onBackPress}
                  onAddingConnection={this.onAddingConnection}
                  onCheckChange={this.props.onCheckChange}
              />
          );
        case "PAYPAL":
          return (
              <PayPal
                  onCancelConnection={this.cancelConnection}
                  formType={this.state.newForm}
                  editConnection={this.state.editConnection}
                  selectedConnection={this.state.dataDetailSelected[0]}
                  onBackPress={this.onBackPress}
                  onAddingConnection={this.onAddingConnection}
                  onCheckChange={this.props.onCheckChange}
              />
          );

        case "BRAZE":
          return (
              <Braze
              onCancelConnection={this.cancelConnection}
              formType={this.state.newForm}
              editConnection={this.state.editConnection}
              selectedConnection={this.state.dataDetailSelected[0]}
              onBackPress={this.onBackPress}
              onAddingConnection={this.onAddingConnection}
              onCheckChange={this.props.onCheckChange}
          />);
        default:
          return "Please select Connection Type";
      }
    }
  }

  render() {
    const {
      dataDetailSelected,
      addPosition,
      userSectionState,
      currentDataSource,
      newConnection,
      isLoading,
      isInfoLoaded,
      isDisabled
    } = this.state;

    return (
      <div className="position-relative data-connection common-wrap">
        <div className={`left-wrap sideNav ${addPosition}`}>
          <h5 className="list-heading font-weight-bold">Integrations</h5>
          {this.renderConnectionList()}
          <div className="add-connection-list connection-int">
            <h6>Add Connection</h6>
            {this.connectionOptions()}
          </div>
        </div>

        <AnimationSection
          transitionName="from-right"
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300}
        >
          {this.state.isLoading && userSectionState ? (
            <div className="right-body-content right-list-area bg-white h-100">
              {newConnection ? (
                this.getConnectionForm()
              ) : isLoading ? (
                <React.Fragment>
                  <ConectionDetail
                    connectionTypesList={connectionTypes}
                    isInfoLoaded={isInfoLoaded}
                    dataDetailSelected={dataDetailSelected}
                    currentDataSource={currentDataSource}
                    onBackPress={this.onBackPress}
                    ontestConnection={this.testConnection}
                    isConnectionTested={this.state.isConnectionTested}
                    onRefreshConnection={this.refreshConnection}
                    onRefreshRequestedDone={this.state.isRefreshRequested}
                    onDataSourceSyncing={this.state.dataSourceSyncing}
                    isDisabled={isDisabled}
                    onDisableConnection={this.disableConnection}
                    onEditConnection={this.onEditConnection}
                    onAttrUpdate={this.updateDatasrcAttr}
                    onCheckChange={this.props.onCheckChange}
                    onUpdateDataSourcesState={this.onUpdateDataSourcesState}
                  />
                </React.Fragment>
              ) : (
                <NoData />
              )}
            </div>
          ) : (
            !isLoading && (
              <div className="right-body-content right-list-area bg-white h-100">
                <Loader />
              </div>
            )
          )}
        </AnimationSection>

        {this.state.showModel && (
          <SweetAlert
            custom
            showCancel
            confirmBtnText="Discard"
            cancelBtnText="Cancel"
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="default"
            allowEscape="false"
            closeOnClickOutside="false"
            title="Discard Changes "
            onConfirm={() => this.handleDiscard()}
            onCancel={() => this.setState({ showModel: false })}
          >
            Changes will be discarded by clicking discard
          </SweetAlert>
        )}
      </div>
    );
  }
}

export default Integrations;
