import React, { Component } from "react";
import "./assets/styles.css";
import { Nav, Navbar } from "react-bootstrap";

import ManageUsers from "./ManageUsers";
import ManageInvites from "./ManageInvites";

import UsersAPI from "../../../../services/users.js";
import _ from "lodash";
import { toast } from "react-toastify";
import SweetAlert from "react-bootstrap-sweetalert";
class Users extends Component {
  constructor() {
    super();
    this.state = {
      selectedKey: "users",
      managerUsers: [],
      mastermanagerUsers: [],
      isLoadingManagerUser: false,
      usersInvites: [],
      masterusersInvites: [],
      isLoadingUserInvites: false,
      currentUser: "",
      sortBy: "asc",
      isInviteSend: true,
      onSucessSubmit: false,
      newInvite: false,
      showModel: false,
      emailError: null,
      isInviteRequestedProccession:false
    };
  }
  componentDidMount() {
    this.getAllUsers();
    this.getAllUsersInvite();

    let currentUser = JSON.parse(localStorage.getItem("userAccountData"))
      .contextUser;
    this.setState({ currentUser });

    if (this.props.location.state !== undefined) {
      this.setState({ selectedKey: this.props.location.state.selectedKey });
    }
  }
  getAllUsers = async () => {
    try {
      await UsersAPI.getAllUsers().then(response => {
        this.setState({
          managerUsers: response,
          mastermanagerUsers: response,
          isLoadingManagerUser: true
        });
        this.sortState("managerUsers", "firstName");
      });
    } catch (err) {
      // console.log(err);
    }
  };

  sortUser = () => {
    const sortBy =
      this.state.sortBy === "asc" ? (this.state.sortBy = "desc") : "asc";
    this.setState({ sortBy });
  };
  searchState = (stateName, filterWhat, key1, key2) => {
    let currentList = [];
    let newList = [];
    if (filterWhat !== "") {
      currentList = this.state["master" + stateName];
      newList = currentList.filter(item => {
        const fileld1 = item[key1].toLowerCase();
        const fileld2 = item[key2].toLowerCase();

        const filter = filterWhat.toLowerCase();
        if (fileld1 !== undefined) {
          let result = fileld1.includes(filter);

          if (!result) {
            result = fileld2.includes(filter);
          }
          return result;
        }
      });
    } else {
      newList = this.state["master" + stateName];
    }

    this.setState({
      [stateName]: newList
    });
  };

  resetState = stateName => {
    this.setState({
      [stateName]: this.state["master" + stateName]
    });
  };

  getAllUsersInvite = async () => {
    try {
      await UsersAPI.getAllUsersInvite().then(response => {
        this.setState({
          usersInvites: response,
          masterusersInvites: response,
          isLoadingUserInvites: true
        });
      });
    } catch (err) {
      // console.log(err);
    }
  };

  submitInvite = async formData => {
    try {
      await UsersAPI.checkInvite(formData.emailAddress).then(response => {
        if (response.status === 404) {
          this.setState({ isInviteSend: false, newInvite: true });
          this.createUsersInvite(formData);
        } else {
          this.setState({ isInviteSend: true, newInvite: true });
          toast.warning("User already invited");
        }
      });
    } catch (err) {
      // console.log(err);
    }
  };

  createUsersInvite = async formData => {
    this.setState({isInviteRequestedProccession:true})
    try {
      await UsersAPI.createUsersInvite(formData).then(response => {
        toast.success("Invite sent successfully");
        this.setState({ newInvite: false });
        this.getAllUsersInvite();
      });
    } catch (err) {
      switch (err.response.data.code) {
        case "ENTITY_EXISTS":
          toast.error(err.response.data.message);
          break;
        case "USER_DISABLED":
          toast.error(err.response.data.message);
          break;
        case "INVITE_EXISTS":
          toast.error(err.response.data.message);
          break;
        default:
          toast.error("Invite sent fail !");
          break;
      }

      this.setState({ newInvite: true, emailError: err.response.data.code });
    }
    this.setState({isInviteRequestedProccession:false})
  };

  revokeInvite = invideId => {
    let filteredArray = this.state.usersInvites.filter(
      item => item.id !== invideId
    );
    this.setState({
      usersInvites: filteredArray,
      masterusersInvites: filteredArray
    });
  };

  updateManagerUsers = updatedRecord => {
    var getIndex = this.state.managerUsers.findIndex(function(item) {
      return item.id === updatedRecord.id;
    });

    let managerUsers = this.state.managerUsers;
    managerUsers[getIndex] = updatedRecord;
    this.setState({ managerUsers, masterManagerUsers: managerUsers });
  };

  checkChange = selectedKey => {
    let checkChange = JSON.parse(localStorage.getItem("checkChange"));

    if (checkChange) {
      this.enableModel();

      this.setState({ navigateto: selectedKey });
    } else {
      this.setState({ selectedKey });
    }
    //
  };
  handleDiscard = () => {
    localStorage.setItem("checkChange", false);
    this.setState({ showModel: false });
    this.setState({ selectedKey: this.state.navigateto });
  };
  enableModel = () => {
    this.setState({ showModel: true });
  };

  render() {
    const {
      selectedKey,
      managerUsers,
      isLoadingManagerUser,
      usersInvites,
      isLoadingUserInvites,
      currentUser,
      sortBy,
      newInvite,
      onSucessSubmit
    } = this.state;

    const sortedManagerUsers = _.orderBy(
      managerUsers,
      [
        filtered =>
          filtered.firstName != null ? filtered.firstName.toLowerCase() : ""
      ],
      sortBy
    );
    console.log(usersInvites);

    const sortedUsersInvites = _.orderBy(
      usersInvites,
      [
        filtered =>
          filtered.firstName != null ? filtered.firstName.toLowerCase() : ""
      ],
      sortBy
    );

    return (
      <React.Fragment>
        <Navbar expand="lg" variant="dark" bg="dark" className="p-0 user-link">
          <Nav className="mr-auto flex-row" defaultActiveKey="users">
            <Nav.Link
              onClick={() => this.checkChange("users")}
              className={this.state.selectedKey === "users" ? "active" : ""}
            >
              Manage Users
            </Nav.Link>
            <Nav.Link
              onClick={() => this.checkChange("invites")}
              className={this.state.selectedKey === "invites" ? "active" : ""}
            >
              Manage Invites
            </Nav.Link>
          </Nav>
        </Navbar>
        {selectedKey === "users"
          ? isLoadingManagerUser && (
              <ManageUsers
                managerUsers={sortedManagerUsers}
                currentUser={currentUser}
                onUpdateSelectedUser={this.updateManagerUsers}
                onSort={this.sortUser}
                onSearch={this.searchState}
                onResetState={this.resetState}
                onCheckChange={this.props.onCheckChange}
                onVerifyChange={this.props.onVerifyChange}
                onContextChange={this.props.onContextChange}
                onContextIdChange={this.props.onContextIdChange}
              />
            )
          : isLoadingUserInvites && (
              <ManageInvites
                emailError={this.state.emailError}
                usersInvites={sortedUsersInvites}
                onRevokeInvite={this.revokeInvite}
                onSort={this.sortUser}
                onSearch={this.searchState}
                onResetState={this.resetState}
                onSucessSubmit={onSucessSubmit}
                isInviteRequestedProccession={this.state.isInviteRequestedProccession}
                onSubmitInvite={this.createUsersInvite}
                newInvite={newInvite}
              />
            )}

        {this.state.showModel && (
          <SweetAlert
            custom
            showCancel
            confirmBtnText="Discard"
            cancelBtnText="Cancel"
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="default"
            allowEscape="false"
            closeOnClickOutside="false"
            title="Discard Changes "
            onConfirm={() => this.handleDiscard()}
            onCancel={() => this.setState({ showModel: false })}
          >
            Changes will be discarded by clicking discard
          </SweetAlert>
        )}
      </React.Fragment>
    );
  }
}

export default Users;
