import React, { Component } from "react";
import "./assets/styles.css";
import DataRow from "./DataRow.js";
import DataList from "./DataList.js";

import DataHead from "./DataHead";
import DataHeadTabs from "./DataHeadTabs";

import ProgressBar from "react-bootstrap/ProgressBar";
import Loader from "../../../../../components/Loader/loader";
import NoData from "../../../../../components/NoData/nodata";
import PropTypes from "prop-types";

const propTypes = {
  targetId: PropTypes.number.isRequired,
  dataViewId: PropTypes.number,
  customerList: PropTypes.array,
  isUpdateState: PropTypes.bool.isRequired,
  onCustomerDetail: PropTypes.func.isRequired,
  getCustomerList: PropTypes.func.isRequired,
  getCustomerListMobile: PropTypes.func.isRequired,
  emptyTitle: PropTypes.string,
};

const initialState = {
  isLoading: false,
  customerList: [],

  customerListMobile: [],
  isNotMobile: true,
  pageNo: 0,
  isEndOfData: false,
  targetId: null,
  dataViewId: null,
  flowHackPos: "",
  scrolling: false,
  loadMore: true,
  isUpdate: false,
  isScrolling: false,
  hasError: false,
  loadingData: false
};

class DataGrid extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
    this._timeout = null;
  }

  resetData = () => {
    this.setState(initialState);
  };

  componentDidMount() {
    this.setState({ isNotMobile: window.innerWidth > 767 });

    this.setState({
      isUpdate: this.props.customerList !== undefined
    });
    if (this.props.customerList === undefined && this.state.isNotMobile) {
      if (this.props.targetId === null && this.props.dataViewId === null) {
        this.getCustomerList();
      } else {
        this.setState(
          {
            targetId: this.props.targetId,
            dataViewId: this.props.dataViewId
          },
          () => this.getCustomerList()
        );
      }
    } else {
      this.setState({ customerList: this.props.customerList, isLoading: true });
    }
  }

  componentWillUnmount() {
    clearTimeout(this._timeout);
  }

  componentWillReceiveProps(prevProps) {
    if (
      (prevProps.customerList === undefined &&
        (prevProps.targetId !== this.state.targetId ||
          prevProps.dataViewId !== this.state.dataViewId)) ||
      prevProps.isUpdateState
    ) {
      this.setState(
        () => ({
          targetId: prevProps.targetId,
          dataViewId: prevProps.dataViewId,
          customerList: [],
          isLoading: false,
          pageNo: 0,
          isEndOfData: false,
          scrolling: false,
          isUpdate: this.props.customerList !== undefined
        }),
        () => this.getCustomerList()
      );
    }
    if (prevProps.customerList !== undefined) {
      if (prevProps.customerList !== this.state.customerList) {
        this.setState({
          customerList: prevProps.customerList,
          isLoading: true
        });
      }
    }
  }

  getCustomerList = async (requiredMore = false) => {
    const { pageNo, targetId, dataViewId } = this.state;
    const isNotMobile = window.innerWidth > 767;
    const {getCustomerList, getCustomerListMobile} = this.props;

    const formData = {
      pageNo: pageNo,
      targetId: targetId,
      dataViewId: dataViewId
    };
    this.setState({ hasError: false, loadingData: true });
    if (isNotMobile) {
      try {
        await getCustomerList(formData).then(response => {
          if(targetId !== this.state.targetId) {
            return;
          }

          if (response.length === 0) {
            if (pageNo === 0) {
              this.setState({
                customerList: []
              });
            }
            this.setState({
              isEndOfData: true,
              loadMore: false,
              isLoading: true
            });
          } else {
            if (requiredMore) {
              this.setState({
                customerList: [...this.state.customerList, ...response],
                scrolling: false,
                loadMore: false,
                isLoading: true
              });
            } else {
              this.setState({
                customerList: response,
                scrolling: false,
                loadMore: false,
                isLoading: true
              });
            }
          }
          this.setState({ loadingData: false });
          this.handleScroll()
        });
      } catch (err) {}
    } else if (!isNotMobile) {
      try {
        await getCustomerListMobile().then(response => {
          if (response.length === 0) {
            this.setState({ isEndOfData: true, loadMore: false });
          } else {
            if (requiredMore) {
              this.setState({
                customerListMobile: [...this.state.customerList, ...response],
                scrolling: false,
                loadMore: false,
                isLoading: true
              });
            } else {
              this.setState({
                customerListMobile: response,
                scrolling: false,
                loadMore: false,
                isLoading: true
              });
            }
          }
          this.handleScroll();
        });
      } catch (err) {}
    } else {
      this.setState({ isEndOfData: true, loadMore: false });
    }
  };

  loadMore = () => {
    if (!this.state.loadingData && !this.state.isEndOfData) {
      this.setState(
        prevState => ({
          loadMore: true,
          pageNo: prevState.pageNo + 1,
          scrolling: true
        }),
        () => this.getCustomerList(true)
      );
    }
  };

  onError = status => {
    this.setState({ hasError: status });
  };

  handleScroll = () => {
    document
      .getElementById("dataTable")
      .addEventListener("scroll", this.checkOnScrolling, false);
  };

  checkOnScrolling = () => {
    clearTimeout(this._timeout);
    this._timeout = setTimeout(() => {
      // Run the callback
      // console.log("Scrolling has stopped.");
      this.setState({
        isScrolling: false
      });
      var scrollTop = document.getElementById("dataTable").scrollTop;
      var scrollHeight = document.getElementById("dataTable").scrollHeight;
      var offsetHeight = document.getElementById("dataTable").offsetHeight;
      var contentHeight = scrollHeight - offsetHeight;
      if (contentHeight <= scrollTop) {
        this.loadMore();
      }
    }, 66);
  };

  render() {
    const {
      customerList,
      loadMore,
      isUpdate,
      customerListMobile,
      isLoading,
      hasError
    } = this.state;
    return isLoading ? (
      <React.Fragment>
        {(customerList.length > 0) & hasError ? (
          <div className=" d-none  d-md-block">
            <NoData
              customTitle="The chosen data view has no attributes selected - please edit the data view and choose at least one data attribute in order to see some data!"
              customSubTitle=""
            />
          </div>
        ) : customerList.length === 0 || hasError ? (
          <div className=" d-none  d-md-block">
            <NoData customTitle={this.props.emptyTitle} />
          </div>
        ) : (
          <React.Fragment>
            <div className="table-wrapper grid-head d-none  d-md-block">
              <DataHeadTabs
                customerList={customerList}
                isScrolling={this.state.isScrolling}
                isError={this.onError}
              />
            </div>
            <div
              id="dataTable"
              className="table-wrapper grid-data d-none  d-md-block"
              // onScroll={this.handleScroll}
            >
              <table className="table">
                {customerList.length > 0 && (
                  <React.Fragment>
                    <DataHead customerList={customerList} />
                    <DataRow
                      onCustomerDetail={this.props.onCustomerDetail}
                      customerList={customerList}
                    />
                  </React.Fragment>
                )}
              </table>
              <div className="table-wrapperEnd" />
            </div>
          </React.Fragment>
        )}

        {loadMore && !isUpdate && (
          <div className="progress-full">
            <ProgressBar
              animated
              variant="info"
              now={100}
              label="Fetching list..."
            />
          </div>
        )}

        <section className="list-main-table d-md-none">
          <DataList
            onCustomerDetail={this.props.onCustomerDetail}
            customerList={customerListMobile}
          />
        </section>
      </React.Fragment>
    ) : (
      <Loader />
    );
  }
}

DataGrid.propTypes = propTypes;

export default DataGrid;
