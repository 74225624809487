import React from "react";
import AnalyticCardDetail from "./analytic/AnalyticCardDetail";
import "../assets/css/dailyCard/monday.scss";
import "../assets/css/progressFriday.scss";
import "../assets/css/productThursday.scss";
import "../assets/css/general-styles.scss";
import iconHeaderMonday from "../assets/images/header-monday.svg";
import iconTuesday from "../assets/images/tuesday-icon.svg";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";

function Monday({ date, cardData }) {

  return (
    <div className="card m-0 p-2 rounded shadow-sm analytic-card container-monday">
      <div className="card-header border-bottom-1 pl-3 position-relative padding-top4em">
        <div className="row card-header-row">
          <div className="col-1 d-flex card-col-icon align-items-center">
            <div className="card-icon">
              <img src={iconHeaderMonday} alt="header..."/>
            </div>
          </div>
          <div className="col-11 card-title-col">
            <div className=" fs-l fw-bold text-black">Briefing Monday</div>
            <p className="fs-m text-muted mb-1 mb-md-2">{date}</p>
            <div className="text-wrap pr-3 w-75">
              <p className="card-text fs-m">
                Start the week right by getting everyone right up to speed. Pour
                a strong coffee and get the lowdown on how the previous week
                went - and what targets you can set to make this one even
                better. This card also clues you up on where you are in the
                sales cycle and gives you the latest forecast for your
                end-of-quarter and end-of-year figures.
              </p>
            </div>
          </div>
          <div className="card-offset">
            <p className="card-info small">
              <span className="text-infor"> How to read this card?</span>
              <span className="card-sm-icon m-2">
                <a href="https://help.distil.ai/hc/en-gb/articles/10785903699869-Daily-Insights-Monday-Briefing" className="" rel="noreferrer" target="_blank">
                 <FontAwesomeIcon icon={faInfo}/>
                </a>
              </span>
            </p>
          </div>
        </div>
        <div className="resonse-header-mobile">
          <p className="card-text-mobile card-text fs-m">
            Start the week right by getting everyone right up to speed. Pour a
            strong coffee and get the lowdown on how the previous week went -
            and what targets you can set to make this one even better. This card
            also clues you up on where you are in the sales cycle and gives you
            the latest forecast for your end-of-quarter and end-of-year figures.
          </p>
        </div>
      </div>
      <div className="card-body m-md-3">
        <AnalyticCardDetail cardData={cardData}/>
      </div>
      {/* <div className="card-footer fs-m">
        <p className="mb-0">
          <b>Tomorrow:</b> Customer Segmentation Tuesday!
                </p>
        <p className="small text-muted">
          Delving into your Customer Segments, how they are changing and
          any actions you can take to drive growth.
                </p>
      </div> */}
      <div className="card-footer fs-m">
        <Row>
          <Col lg={2}/>
          <Col ls={8} className="section-text-footer">
            <div className="fs-l fw-bold text-black">
              Tomorrow is Customer Segmentation Tuesday{" "}
            </div>
            <div className="fs-xm text-gray">
              Delving into your Customer Segments, how they are changing and any
              actions you can take to drive growth.
            </div>
            <div className="card-icon">
              <img src={iconTuesday} alt="footer..."/>
            </div>
          </Col>
          <Col lg={2}/>
        </Row>
      </div>
    </div>
  );
}

export default Monday;
