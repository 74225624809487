import React, { Component } from "react";

import moment from "moment";
import CardControlers from "./components/cardControlers";
import { get } from "lodash";
import {Link} from "react-router-dom";
import millify from "millify"

class DataSourceCardSummary extends Component {
  state = {
    sentimentMessages: {
      positive: [
        "We’re rocking!",
        "Boom!",
        "Nice one!",
        "Marvelous!",
        "Stupendous!",
        "Ooo la la!",
        "Strewth!",
        "High-Five!",
        "Superlative!"
      ],
      negative: ["We’re rocking!", "Boom!"],
      other: ["Ooo la la!", "Strewth!", "High-Five!", "Superlative!"]
    },
    selectedMessages: ""
  };

  filterTagName(tag, index) {
    if (tag !== "NONE") {
      const colorCodes = [
        "#A8D322",
        "#7BC412",
        "#2FAA22",
        "#85CEE0",
        "#00ABCB",
        "#32A4E3",
        "#0087C5",
        "#DDB1CD",
        "#C482B3",
        "#EF5B88",
        "#AE72ED",
        "#8555E0",
        "#5A4A75",
        "#F05A51",
        "#DF171B",
        "#EF571B",
        "#F6921E",
        "#FFD300",
        "#DBE50A"
      ];
      const tagStlye = {
        backgroundColor: colorCodes[index]
      };

      const removalbles = ["CUSTOMER_", "PRODUCT_", " CONTENT_", "ORDER_"];

      var tagValue = tag;
      removalbles.map(item => {
        tagValue = tagValue.replace(item, "");
      });
      tagValue = tagValue.replace("_", " ").toLowerCase();
      return (
        <span style={tagStlye} className="data-firstname-bg">
          {tagValue}
        </span>
      );
    } else {
      return null;
    }
  }

  pad = num => {
    return ("" + num).slice(-2);
  };
  formatSeconds = secs => {
    var minutes = Math.floor(secs / 60);
    secs = secs % 60;
    var hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    if (hours === 0 && minutes > 0) {
      return (
        <React.Fragment>
          <strong>{this.pad(minutes)}</strong> Minutes{" "}
          <strong> {this.pad(secs)} </strong>{" "}
          {this.pad(secs) > 1 ? "Seconds" : "Second"}
        </React.Fragment>
      );
    } else if (minutes === 0) {
      return (
        <React.Fragment>
          <strong> {this.pad(secs)} </strong>{" "}
          {this.pad(secs) > 1 ? "Seconds" : "Second"}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <strong>{this.pad(hours)}</strong> Hours{" "}
          <strong>{this.pad(minutes)}</strong> Minutes{" "}
          <strong> {this.pad(secs)} </strong>{" "}
          {this.pad(secs) > 1 ? "Seconds" : "Second"}
        </React.Fragment>
      );
    }
  };
  componentDidMount() {
    this.sentimentMessages();
  }
  sentimentMessages = () => {
    var randMessage = Math.floor(
      Math.random() * Math.floor(this.state.sentimentMessages.positive.length)
    );
    this.setState({
      selectedMessages: this.state.sentimentMessages.positive[randMessage]
    });
  };

  render() {
    const {
      cardContent,
      onPinnedCard,
      onCardHistory,
      keyIndex,
      sampleDataSource,
      membershipType
    } = this.props;
    const cardDetails = get(cardContent, "cardDetails", {});
    if (cardDetails === null) {
      return (
        <div className="card fadeIn data-source">
          <div className="card-thumbnail">
            <span className="icon-data-source"></span>
          </div>
          <div className="card-content">
            <CardControlers
              style={{ position: "absolute", right: "0" }}
              onPinnedCard={onPinnedCard}
              cardContent={cardContent}
              onCardHistory={onCardHistory}
            />
            <div className="card-detail">
              <div className="card-head">
                <div className="card-wrapper">
                  <h6 className="card-type">Your Data Sources</h6>
                  <p>
                    {moment(cardContent.dateShowAfter)
                      .local()
                      .format("MMMM DD, YYYY")}
                    <span className="ml-1">
                      {moment(cardContent.dateShowAfter)
                        .local()
                        .format("  h:mm a")}
                    </span>
                  </p>
                </div>
              </div>

              <h5 className="card-records">
                {this.state.selectedMessages}! No Card Details
              </h5>
            </div>
          </div>
        </div>
      );
    } else {
      if (
        cardDetails.connections === undefined ||
        cardDetails.connections.length === 0
      ) {
        return (
          <div className="card fadeIn data-source">
            <div className="card-thumbnail">
              <span className="icon-data-source"></span>
            </div>
            <div className="card-content">
              <CardControlers
                style={{ position: "absolute", right: "0" }}
                onPinnedCard={onPinnedCard}
                cardContent={cardContent}
                onCardHistory={onCardHistory}
              />
              <div className="card-detail">
                <div className="card-head">
                  <div className="card-wrapper">
                    <h6 className="card-type">Your Data Sources</h6>
                    <p>
                      {moment(cardContent.dateShowAfter)
                        .local()
                        .format("MMMM DD, YYYY")}
                      <span className="ml-1">
                        {moment(cardContent.dateShowAfter)
                          .local()
                          .format("  h:mm a")}
                      </span>
                    </p>
                  </div>
                </div>

                <h5 className="card-records">
                  {this.state.selectedMessages}! No Connections Details
                </h5>
              </div>
            </div>
          </div>
        );
      } else {
        var dataSourcesLength = 0;
        cardDetails.connections.map(item => {
          dataSourcesLength += item.dataSources.length;
        });

        return (
          <div className="card fadeIn data-source">
            <div className="card-thumbnail">
              <span className="icon-data-source"></span>
            </div>
            <div className="card-content">
              <CardControlers
                style={{ position: "absolute", right: "0" }}
                onPinnedCard={onPinnedCard}
                cardContent={cardContent}
                onCardHistory={onCardHistory}
              />

              <div className="card-detail">
                <div className="card-head">
                  <div className="card-wrapper">
                    <h6 className="card-type">Your Data Sources</h6>
                    <p>
                      {moment(cardContent.dateShowAfter)
                        .local()
                        .format("MMMM DD, YYYY")}
                      <span className="ml-1">
                        {moment(cardContent.dateShowAfter)
                          .local()
                          .format("  h:mm a")}
                      </span>
                    </p>
                  </div>
                </div>

                <h5 className="card-records">
                  {this.state.selectedMessages}! You processed{" "}
                  <span>
                    {cardDetails.totalRecordsProcessed.toLocaleString(
                      navigator.language
                    )}
                  </span>{" "}
                  records yesterday.
                </h5>

                <p className="card-desc">
                  There were{" "}
                  <span>
                    {cardDetails.totalUpdated.toLocaleString(
                      navigator.language
                    )}
                  </span>{" "}
                  records updated,{" "}
                  <span>
                    {cardDetails.totalAdded.toLocaleString(navigator.language)}
                  </span>{" "}
                  added and{" "}
                  <span>
                    {cardDetails.totalDeleted.toLocaleString(
                      navigator.language
                    )}
                  </span>{" "}
                  records deleted across{" "}
                  <span>
                    {cardDetails.connections.flatMap(c => c.dataSources).length.toLocaleString(
                      navigator.language
                    )}
                  </span>{" "}
                  Data Sources.{" "}
                  {cardDetails.totalErrors > 0 && (
                    <React.Fragment>
                      However, you also had{" "}
                      {cardDetails.totalErrors.toLocaleString(
                        navigator.language
                      )}{" "}
                      sync errors - just contact support if you would like some
                      help with that.
                    </React.Fragment>
                  )}
                </p>
                <div className="card-section">
                  <table className="dataSource">
                    {cardDetails.connections.map(connection => (
                      <tbody key={connection.connectionId}>
                        <tr>
                          <td
                            colSpan="4"
                            data-tooltip={connection.name}
                            className="dataRowMain"
                          >
                            <div className="info-heading">
                              {connection.name}
                            </div>
                          </td>
                          <th className="text-right">
                            {(membershipType === "ADMIN" ||
                              membershipType === "TECHNICAL") && (
                              ((connection.isDatabase) && <Link
                                to={{
                                  pathname: "/settings/dataconnections",
                                  state: {
                                    selectedID: connection.connectionId
                                  }
                                }}
                                onClick={() => this.props.onPageChange("Settings")}
                              ><i className="icon-setting-distil"/></Link>)
                              ||
                              <Link
                                to={{
                                  pathname: "/settings/integrations",
                                  state: {
                                    selectedID: connection.connectionId
                                  }
                                }}
                                onClick={() => this.props.onPageChange("Settings")}
                              ><i className="icon-setting-distil"/></Link>
                            )}

                          </th>
                        </tr>
                        {connection.dataSources.map(dataSource => (
                          <tr key={dataSource.dataSourceId}>
                            <td
                              className="dataRow"
                              data-tooltip={dataSource.name}
                            >
                              <div>
                                {dataSource.isNew && (
                                  <i className="icon-new font" />
                                )}
                                {dataSource.name}
                              </div>
                            </td>
                            <td>
                              <div className="graphUI">
                                <div
                                  data-tooltip={
                                    dataSource.recordsDeleted +
                                    " Record deleted"
                                  }
                                  className="graphBlock UIdeleted"
                                  style={{
                                    flex: dataSource.recordsDeletedPercentGraph
                                  }}
                                ></div>

                                <div
                                  data-tooltip={
                                    dataSource.recordsUnchanged +
                                    " Record unchanged"
                                  }
                                  className="graphBlock UIunchanged"
                                  style={{
                                    flex:
                                      dataSource.recordsUnchangedPercentGraph
                                  }}
                                ></div>

                                <div
                                  data-tooltip={
                                    dataSource.recordsUpdated +
                                    " Record updated"
                                  }
                                  className="graphBlock UIupdated"
                                  style={{
                                    flex: dataSource.recordsUpdatedPercentGraph
                                  }}
                                ></div>

                                <div
                                  data-tooltip={
                                    dataSource.recordsAdded + " Record added"
                                  }
                                  className="graphBlock UIadded"
                                  style={{
                                    flex: dataSource.recordsAddedPercentGraph
                                  }}
                                ></div>
                              </div>
                            </td>
                            <td className="black-text-80">{dataSource.percentChange}</td>
                            <td className="black-text-80">{millify(dataSource.totalRecords)}</td>
                            <td className="text-right">
                              <i
                                className={`iconRoundBg ${
                                  dataSource.lastRunStatus === "SUCCESS"
                                    ? dataSource.errorsInLast7Days > 0
                                      ? "warningText icon-exclamation-outline"
                                      : "icon-check-circle successText"
                                    : "errorText icon-exclamation-outline"
                                }`}
                              ></i>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ))}
                  </table>

                  {dataSourcesLength > 6 && (
                    <div className="card-section-footer">
                      <p>
                        For details of all data sources please view the{" "}
                        <strong onClick={() => onCardHistory(cardContent)}>
                          Data Source History
                        </strong>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }
}

export default DataSourceCardSummary;
