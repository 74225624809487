import React, { Component } from "react";
import NewsfeedCard from "./components/card";
import { Button, ButtonGroup } from "react-bootstrap";

import "./assets/style.css";
import Masonry from "react-masonry-component";
import _ from "lodash";
import moment from "moment";
import Loader from "../../../components/Loader/loader";
import NewsfeedAPI from "../../../services/newsfeed";
import NoData from "../../../components/NoData/nodata";
const masonryOptions = {
  transitionDuration: 0,
  gutter: 30,
  fitWidth: true,
  horizontalOrder: true
};

const imagesLoadedOptions = { background: ".my-bg-image-el" };
class Newsfeed extends Component {
  state = {
    viewType: "Timeline",
    isEndOfData: false,
    scrolling: false,
    pageNo: 0,
    chunkedResult: null,
    isloading: true,
    newsFeedcards: null,
    finalResult: [],
    noData: false
  };

  componentDidMount() {
    this.handleNewsfeed();
  }
  componentWillReceiveProps(prevProps) {
    if (prevProps.pinnedUpdated !== null && this.state.newsFeedcards !== null) {
      const cards = this.state.newsFeedcards;
      cards.map(item => {
        if (item.newsfeedStackId === prevProps.pinnedUpdated.newsfeedStackId) {
          item.pinned = false;
        }
      });

      this.setState({ newsFeedcards: cards });
    }
  }

  handleNewsfeed = async () => {
    try {
      await NewsfeedAPI.getNewsfeed().then(response => {
        if (response.length === 0) {
          this.setState({ noData: true });
          this.props.hasNoData();
        }
        this.setState({ newsFeedcards: response, isloading: false }, () =>
          this.chunkArray(response)
        );
      });
    } catch (err) {
      this.setState({ isloading: false, noData: true });
    }
  };

  renderHeader() {
    const { viewType } = this.state;
    return (
      <div className=" newsfeedHeader">
        <h2>
          {viewType === "Curated"
            ? "Arranged scientifically just for you"
            : "Arranged chronologically"}{" "}
        </h2>
        <ButtonGroup className="top-btn-group">
          <Button
            onClick={() => this.setState({ viewType: "Curated" })}
            className={`${viewType === "Curated" ? "active" : ""}`}
          >
            <span className="icon-grid" /> Curated
          </Button>
          <Button
            onClick={() => this.setState({ viewType: "Timeline" })}
            className={`${viewType === "Timeline" ? "active" : ""}`}
          >
            <span className="icon-list" /> Timeline
          </Button>
        </ButtonGroup>
        <span className="timestamp">
          {moment().format("dddd, MMMM DD, YYYY")}{" "}
        </span>
      </div>
    );
  }

  renderTime(exactTime) {
    var CurrentDate = moment().local();
    var matchTime = moment(exactTime).local();
    var duration = moment.duration(CurrentDate.diff(matchTime));
    var diff = duration._data.days;

    return (
      moment(CurrentDate).format("YYYYMMDD") -
      moment(matchTime).format("YYYYMMDD")
    );

    //return diff;
  }
  renderDayType(index, dateStamp) {
    switch (index) {
      case "0":
        return "Today";
      case "1":
        return "Yesterday";
      case "2":
        return moment.utc(dateStamp[0].dateShowAfter).format("dddd");
      case "3":
        return moment.utc(dateStamp[0].dateShowAfter).format("dddd");
      default:
        return moment.utc(dateStamp[0].dateShowAfter).format("DD MMMM");
    }
  }
  renderTimelineUI() {
    var groupSize = 2;
    const { finalResult ,sampleDataSource} = this.state;
    const { onCardHistory } = this.props;
    // var updatedCards = finalResult.map(item => {
    //   let dDiff = this.renderTime(item.dateShowAfter);
    //   if (dDiff >= 0) {
    //     item.dayDiffereces = this.renderTime(item.dateShowAfter);
    //     return item;
    //   } else {
    //     return null;
    //   }
    // });

    var updatedCards = [];

    for (let i = 0; i < finalResult.length; i++) {
      if (this.renderTime(finalResult[i].dateShowAfter) >= 0) {
        finalResult[i].dayDiffereces = this.renderTime(
          finalResult[i].dateShowAfter
        );
        updatedCards.push(finalResult[i]);
      }
    }

    // console.log(updatedCards);

    var timelineResult = [];
    timelineResult = _.groupBy(updatedCards, "dayDiffereces");

    let result = [];
    let items = "";
    for (var key in timelineResult) {
      if (timelineResult.hasOwnProperty(key) && key >= 0) {
        items = (
          <div className="history" key={key}>
            <span className="mx-auto timeline-history">
              {this.renderDayType(key, timelineResult[key])}
            </span>

            <ul className="timeline ">
              {timelineResult[key].map((card, index) => (
                <li
                  key={index}
                  className={index % groupSize !== 0 ? "timeline-inverted" : ""}
                >
                  <div className="timeline-panel">
                    <NewsfeedCard
                      cardContent={card}
                      contextUser={this.props.contextUser}
                      sampleDataSource={sampleDataSource}
                      onPinnedCard={this.handlePinned}
                      onCardHistory={onCardHistory}
                    />
                  </div>
                </li>
              ))}
            </ul>
            {/* {console.log(Object.keys(timelineResult), Object.keys(timelineResult)[Object.keys(timelineResult).length-1] , key)} */}
            {Object.keys(timelineResult)[
              Object.keys(timelineResult).length - 1
            ] === key && (
              <span className="mx-auto timeline-history last">
                Beginning of time
              </span>
            )}
          </div>
        );

        result.push(items);
      }
    }

    return result;
  }

  handleScroll = () => {
    const {
      scrolling,
      isEndOfData,
      pageNo,
      chunkedResult,
      finalResult
    } = this.state;
    if (scrolling) return;
    if (isEndOfData) return;

    const newsfeedContainer = document.querySelector(".newsfeed-container");

    const historyEndOfData = document.querySelector(".history-endOfData");
    const bottomOffset = 300;

    let tableScrollTop = newsfeedContainer.scrollTop;
    let tableHeight = newsfeedContainer.offsetHeight;
    let endOfTable = historyEndOfData.offsetTop;

    if (
      tableScrollTop > endOfTable - tableHeight - bottomOffset &&
      pageNo < chunkedResult.length - 1
    ) {
      let currentPage = pageNo + 1;

      this.setState({ pageNo: currentPage });
      let result = [...finalResult, ...chunkedResult[currentPage]];
      this.setState({ finalResult: result });
    }
  };

  chunkArray = newsFeedcards => {
    var results = [];
    let feedCards = newsFeedcards;

    if (feedCards !== null) {
      while (feedCards.length) {
        results.push(feedCards.splice(0, 10));
      }
      this.setState({ chunkedResult: results, finalResult: results[0] });
    } else {
      results = null;
    }
  };

  handlePinned = async card => {
    const newsFeedcards = this.state.newsFeedcards;

    let cardStatus = card.pinned ? (card.pinned = false) : (card.pinned = true);
    let pinnedItem = { id: card.newsfeedStackId, pinned: cardStatus };

    try {
      await NewsfeedAPI.updatePinStatus(pinnedItem).then(response => {
        const currentIndex = newsFeedcards.map(currentcard => {
          if (currentcard.newsfeedStackId === card.newsfeedStackId) {
            currentcard.pinned = cardStatus;
          }
        });
      });
    } catch (err) {
      return;
    }

    this.setState({ newsFeedcards });
  };

  render() {
    const { onCardHistory } = this.props;

    const {
      pageNo,
      chunkedResult,
      finalResult,
      isloading,
      newsFeedcards,
      sampleDataSource
    } = this.state;

    var maxValue = _.maxBy(finalResult, "displayBlock");

    var i;
    var groupedResult = [];
    var maxItems = 200;
    var itemAdded = 0;

    if (maxValue !== undefined) {
      for (i = 0; i <= maxValue.displayBlock; i++) {
        let newItem = finalResult.filter(item => item.displayBlock === i);

        if (newItem.length > 0) {
          groupedResult.push(newItem);
          itemAdded = newItem.length + 1;
        }
      }
    }
    return isloading ? (
      <Loader />
    ) : this.state.noData ? (
      <NoData userName={this.props.contextUser.firstName} />
    ) : (
      <React.Fragment>
        {this.renderHeader()}
        <div className="newsfeed-container" onScroll={this.handleScroll}>
          {this.state.viewType === "Curated" &&
            groupedResult.map((sectionItem, key) => (
              <div className="history" key={key}>
                <Masonry
                  className={"my-gallery-class"}
                  options={masonryOptions} // default {}
                  disableImagesLoaded={false} // default false
                  updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
                  imagesLoadedOptions={imagesLoadedOptions} // default {}
                >
                  {sectionItem.map((card, index) => (
                    <NewsfeedCard
                      cardContent={card}
                      membershipType={this.props.membershipType}
                      key={index}
                      sampleDataSource={sampleDataSource}
                      onPinnedCard={this.handlePinned}
                      onCardHistory={onCardHistory}
                      onPageChange={this.props.onPageChange}
                    />
                  ))}
                </Masonry>
              </div>
            ))}

          {this.state.viewType === "Timeline" && (
            <React.Fragment>{this.renderTimelineUI()}</React.Fragment>
          )}
          <div className="history-endOfData" />
        </div>
      </React.Fragment>
    );
    // } else {
    //   return <Loader />;
    // }
  }
}

export default Newsfeed;
