import React, { Component } from "react";
import moment from "moment";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import CardControlers from "./components/cardControlers";
import { get } from "lodash";

class DefaultCard extends Component {
  state = {};

  render() {
    const { cardContent, onPinnedCard, onCardHistory, keyIndex } = this.props;
    const cardTitle = {
      CREATED:
        "A new Customer Segment called " +
        cardContent.stackTitle +
        " has been created!",
      CHANGED:
        "A new Data Source called " +
        cardContent.stackTitle +
        " has been changed"
    };
    return (
      <div className="card segment">
        <div className="card-thumbnail">
          <span className="icon-segment"></span>
        </div>
        <div className="card-content">
        <CardControlers
            onPinnedCard={onPinnedCard}
            cardContent={cardContent}
            onCardHistory={onCardHistory}
          />
          <div className="card-detail">
            <h6 className="card-type">
            {cardContent.cardTitle} / {cardContent.stackTitle}
            </h6>
            <br />
            <h5>{cardTitle[cardContent.newsfeedStackCardType.cardType]}</h5>
            <p>
              <React.Fragment>
                  by {cardContent.userFirstName} {cardContent.userLastName}
              </React.Fragment>
            </p>
            <p className="date">
              {" "}
              {moment(cardContent.dateShowAfter)
                .local()
                .format("MMMM DD, YYYY")}
              <span className="ml-1">
                {moment(cardContent.dateShowAfter)
                  .local()
                  .format("  h:mm a")}
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default DefaultCard;
