import React, {Component} from "react";
//import { loginAPI } from "../../../services/apiService";
import AuthenticationService from "../../../services/authentication";

import {Link} from "react-router-dom";

import {Textbox} from "react-inputs-validation";
import "react-inputs-validation/lib/react-inputs-validation.min.css";

import {toast} from "react-toastify";
import BottomWrap from "../../../components/BottomWrap/BottomWrap";
import {getUrlVars} from "../../Main/Customers/customerdata/segmentUtils";
import {distilShopifyAccountConfig} from "../../../variables/authCreds";
import Loader from "../../../components/Loader/loader";
import { emailRex } from "../../../variables/utils";


class Login extends Component {
  constructor(props) {
    super(props);
    this.myEmail = React.createRef();
    this.myPassword = React.createRef();
  }

  state = {
    validateEmail: false,
    validatePassword: false,

    isSubmitting: true,
    email: "",
    password: "",
    isPasswordField: "password",
    errors: {},
    shopifyUserIsChecked: false,
    isShopify: getUrlVars(window.location.href)?.shopifyUser !== undefined && getUrlVars(window.location.href)?.shopifyUser.length > 0
  };

  onFormSubmit = async event => {
    if (this.props.authMockLogin === true) {
      return this.handleMockAuth(getUrlVars(window.location.href), event);
    } else {
      return this.doLogin(event, (email, password) => AuthenticationService.login(email, password).then(_ => window.location = "/"));
    }
  }

  handleMockAuth(urlVars, event) {
    let isShopifyAuth = /^[a-zA-Z0-9][a-zA-Z0-9\-]*.myshopify.com$/.test(urlVars.shop);

    if (isShopifyAuth) {
      return this.doLogin(event, (email, password) =>
        AuthenticationService.getLoginData(email, password).then(_ => {
          window.location.href = `https://${urlVars.shop}/admin/oauth/authorize` +
            "?client_id=" + distilShopifyAccountConfig.apiKey +
            "&scope=" + distilShopifyAccountConfig.scope +
            "&redirect_uri=" + distilShopifyAccountConfig.redirectToSuccess +
            "&grant_options[]=" + distilShopifyAccountConfig.grant;
        })
      );
    }
  }

  doLogin = async (event, loginFlow) => {
    this.setState({isSubmitting: true});
    event.preventDefault();
    const email = this.state.email;

    const password = this.state.password;
    const errInMyEmail = this.myEmail.current.state.err;
    const errInMyPassword = this.myPassword.current.state.err;

    window.distil.identifyCustomer({EmailAddress: email, GDPRStatus: {MarketingSubscribed: false}});
    window.distil.trackEvent('try_login', ' ', {EmailAddress: email});

    if (!errInMyEmail && !errInMyPassword) {
      try {
        await loginFlow(email, password);
      } catch (ex) {
        window.distil.trackEvent('unsuccessful_login', ' ', {EmailAddress: email});
        toast.error("Invalid Username and/or Password provided", ex);
      }
    }
    this.setState({isSubmitting: false});
  };

  componentDidMount() {
    let vars = getUrlVars(window.location.href);
    sessionStorage.setItem("shopVars", vars)
    if (this.state.isShopify){
      let data = JSON.parse(atob(decodeURIComponent(vars?.shopifyUser)));
      AuthenticationService.loginFromShopify(data.token).then(_ => {
        let registeredShopUser = JSON.parse(sessionStorage.getItem("registeredShopUser"));
        if (!registeredShopUser || !registeredShopUser.firstName
          || !registeredShopUser.lastName || !registeredShopUser.marketingEmailAddress) {
          window.location = "/update"
        } else {
          window.location = "/chooseaccount?isShopify=true"
        }
      })
    }
    document.title = "Distil - Log in";
  }

  togglePasswordField = () => {
    this.setState({
      isPasswordField:
        this.state.isPasswordField === "password" ? "text" : "password"
    });
  };

  validateEmail = () => {
    const errInMyEmail = this.myEmail.current.props.value;
    const errInMyPassword = this.myPassword.current.props.value;

    if (!emailRex.test(errInMyEmail) || errInMyPassword.length === 0) {
      this.setState({isSubmitting: true, validateEmail: true});
    } else {
      this.setState({isSubmitting: false, validateEmail: false});
    }
  };
  validatePassword = () => {
    const errInMyEmail = this.myEmail.current.props.value;
    const errInMyPassword = this.myPassword.current.props.value;

    if (!emailRex.test(errInMyEmail) || errInMyPassword.length === 0) {
      this.setState({isSubmitting: true, validatePassword: true});
    } else {
      this.setState({isSubmitting: false, validatePassword: false});
    }
  };

  render() {
    const {validateEmail, validatePassword, isSubmitting, isShopify} = this.state;
    return !isShopify ? (
      <div className="content-wrap bg-white">

        <div className="content-inner-wrap col-xl-9 col-md-11">
          <div className="top-wrap ">
            <h1 className="font-weight-heavy">Log in</h1>
            <p>
              Unlock the facts that will help you speak to your whole audience
              in a uniquely personal way.
            </p>
            {isShopify ? ""
              : <p>
                Need a Distil account?
                <Link
                  className="px-1"
                  to={{
                    pathname: "/signup"
                  }}
                >
                  {"Create an account"}
                </Link>

              </p>}

          </div>


          <div className="form-wrap">
            <form onSubmit={this.onFormSubmit}>
              <div className="form-group new-form-group">
                <label htmlFor="email">Email</label>
                <Textbox
                  ref={this.myEmail}
                  classNameInput="form-control"
                  classNameContainer="custome-input"
                  tabIndex="1" // Optional.[String or Number].Default: none.
                  id={"email"} // Optional.[String].Default: "".  Input ID.
                  name="email" // Optional.[String].Default: "". Input name.
                  type="text" // Optional.[String].Default: "text". Input type [text, password, number].
                  value={this.state.email} // Optional.[String].Default: "".
                  placeholder="Your Email here" // Optional.[String].Default: "".
                  autoComplete="off"
                  onChange={(email, e) => {
                    this.setState({email}, () => this.validateEmail());
                  }} // Required.[Func].Default: () => {}. Will return the value.
                  onBlur={e => {
                  }} // Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                  validate={validateEmail}
                  validationOption={{
                    name: "Email Address", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
                    check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                    required: true, // Optional.[Bool].Default: true. To determin if it is a required field.

                    reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // Optional.[Bool].Default: "" Custom regex.
                    regMsg: "Enter valid Email Address" // Optional.[String].Default: "" Custom regex error message.
                  }}
                />
              </div>
              <div className="form-group new-form-group">
                <label htmlFor="password">
                  Password{" "}
                  <span onClick={this.togglePasswordField}>
                    {" "}
                    <i
                      className={`${
                        this.state.isPasswordField === "password"
                          ? "icon-visibility"
                          : "icon-visibility_off"
                      }`}
                    />{" "}
                    Show{" "}
                  </span>
                </label>

                <span
                  onClick={this.togglePasswordField}
                  className="d-block mobilePass-show d-md-none"
                >
                  {" "}
                  <i
                    className={`${
                      this.state.isPasswordField === "password"
                        ? "icon-visibility"
                        : "icon-visibility_off"
                    }`}
                  ></i>{" "}
                </span>

                <Textbox
                  ref={this.myPassword}
                  classNameInput="form-control"
                  classNameContainer="custome-input"
                  tabIndex="1" // Optional.[String or Number].Default: none.
                  id={"password"} // Optional.[String].Default: "".  Input ID.
                  name="password" // Optional.[String].Default: "". Input name.
                  type={this.state.isPasswordField} // Optional.[String].Default: "text". Input type [text, password, number].
                  value={this.state.password} // Optional.[String].Default: "".
                  placeholder="Your password here" // Optional.[String].Default: "".
                  onChange={(password, e) => {
                    this.setState({password}, () => this.validatePassword());
                  }} // Required.[Func].Default: () => {}. Will return the value.
                  onBlur={e => {
                  }} // Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                  validate={validatePassword}
                  validationOption={{
                    name: "password", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
                    check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                    required: true // Optional.[Bool].Default: true. To determin if it is a required field.
                  }}
                />

                <Link
                  className="secondary-btn light-black-text-40 "
                  to={{
                    pathname: "/forgot"
                  }}
                >
                  Forgot Password?
                  <br/>
                </Link>
              </div>

              <div className="btn-wrap">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-primary green-btn"
                >
                  Log In
                </button>
              </div>
            </form>
          </div>

          <BottomWrap/>
        </div>
      </div>
    ) : <Loader/> ;
  }
}

export default Login;
