// import React, { Component } from "react";
import React from "react";
import "./css/style.css";

const DevcieNotification = () => {
  return (
    <div className="notSupported">
      <p>Not Supported in Small Devices</p>
    </div>
  );
};

export default DevcieNotification;
