import React from "react";
import "./styles.scss";

const FirstVisitTable = ({funnelMetrics_formatted}) => {
  console.log(funnelMetrics_formatted)
    return (
        <>
            <div className="row display-flex-max576 display-none pb-3 border-top1px-dotted pt-2">
                <div className="col-6 font-size-20px color-brown">
                    <strong>First Visit</strong>
                </div>
            </div>
            <div className="row display-flex-max576 display-none pb-3">
                <div className="col-1">
                </div>
                <div className="col-11">
                    <div className="content-row"
                         style={{display: 'flex', justifyContent: 'space-evenly'}}>
                        <p className="unique color-706 width168px text-right"><strong>Unique </strong></p>
                        <p className="unique color-706 width60px">
                            {funnelMetrics_formatted?.totalCustomerNumber_formatted}
                        </p>
                        <p/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FirstVisitTable;