import React, {Component} from "react";
import {Col, Container, Jumbotron, ListGroup, Navbar, Row} from "react-bootstrap";
import logoTracking from './assets/img/logo-tracking.svg';
import shopify from './assets/img/shopify.png';
import "./assets/styles.css";
import TrackingAPI from "../../../../services/tracking";
import SyntaxHighlighter from 'react-syntax-highlighter';
import {docco} from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import {globalConstants} from "../../../../variables/globalVariables";
import TrackingShopify from "./components/TrackingShopify";
import TrackingShopifyCompleted from "./components/TrackingShopifyCompleted";
import AccountAPI from "../../../../services/account";
import {toast} from "react-toastify";

class Tracking extends Component {
  constructor(props) {
    super(props);
    const selectedMembers = JSON.parse(sessionStorage.getItem("selectedMembers"));

    this.state = {
      activeTab: 'shopify',
      loaded: false,
      firstScreenTracking: false,
      selectedMembers: selectedMembers,
      stagingEnv: globalConstants.BASE_MESSAGE === "Staging",
      trackingKey: "",
      isLoading: false,
      linkShopify: '',
      trackingId: '',
      hideShopifyTab: false,
      trackingDetection: [
          {
            eventSource: null,
            eventTypes: ['page_view'],
            eventName: 'Page View Events',
            lastEventTimestamp: null,
          },
            {
          eventSource: null,
          eventTypes: ['customer_identification', 'customer_identification_url'],
          eventName: 'Customer Identification events',
          lastEventTimestamp: null,
        },
        {
          eventSource: null,
          eventTypes: ['product_view'],
          eventName: 'Product Viewed events',
          lastEventTimestamp: null,
        },
        {
          eventSource: null,
          eventTypes: ['content_view'],
          eventName: 'Content Viewed events',
          lastEventTimestamp: null,
        },
        {
          eventSource: null,
          eventTypes: ['order_placed'],
          eventName: 'Purchase Made events',
          lastEventTimestamp: null,
        },
        {
          eventSource: null,
          eventTypes: ['distil_track_event', 'email', 'Email'],
          eventName: 'Event Tracking events',
          lastEventTimestamp: null,
        },
      ]
    }
  }

  componentDidMount() {
    this.getTrackingInfo();
    if (this.state.selectedMembers.origin !== 'SHOPIFY') {
      this.setState({
        activeTab: 'bespoke',
        hideShopifyTab: true,
        loaded: true,
      });
    } else {
      AccountAPI.getShopifyTracking().then((response) => {
        this.setState({
          firstScreenTracking: response.trackingIdCorrect && response.extTrackingBlockEnabled,
          loaded: true,
          linkShopify: `https://${response.shop}/admin/themes/current/editor?context=apps&template=${response.themeId}&activateAppId=${response.shopifyAppExtensionUuid}/tracking`,
          isThemeIdPresent: !!response.themeId,
          trackingId: response.trackingId,
        });
      }).catch(() => {
        this.setState({
          loaded: true,
          firstScreenTracking: false
        });
        toast.error("Something went wrong, try again");
      });
    }
  }

  getTrackingInfo = async event => {
    try {
      await TrackingAPI.getTrackingInfo().then(response => {
        let {trackingDetection} = this.state;

        trackingDetection.forEach(baseEvent => {
          baseEvent.lastEventTimestamp = response?.trackingDetection
            ?.find(event => baseEvent.eventSource === event.eventSource && baseEvent.eventTypes.includes(event.eventType))
            ?.lastEventTimestamp;
        });

        this.setState({
          trackingKey: response.trackingKey,
          trackingDetection: trackingDetection,
          isLoading: true
        });
      });
    } catch (err) {
    }
  };

  switchNavTab = (activeTab) => {
    this.setState({
      activeTab: activeTab,
    });
  }

  getTrackingStatus = (date) => {
    let message = "Never seen";
    let iconStyle = "";

    if (date) {

      let currentDate = new Date();
      let utcTimestamp = currentDate.getTime() + this.minutesToMillis(currentDate.getTimezoneOffset());
      const div = utcTimestamp - date;

      switch (div) {
        case (div < this.minutesToMillis(60) && div):
          message = "In the last hour";
          iconStyle = "active";
          break;

        case (div < this.minutesToMillis(4 * 60) && div):
          message = "In the last 4 hours";
          iconStyle = "active";
          break;

        case (div < this.minutesToMillis(12 * 60) && div):
          message = "In the last 12 hours";
          iconStyle = "active";
          break;

        case (div < this.minutesToMillis(24 * 60) && div):
          message = "In the last 24 hours";
          iconStyle = "twoDaysAgo";
          break;

        case (div < this.minutesToMillis(48 * 60) && div):
          message = "2 days ago";
          iconStyle = "twoDaysAgo";
          break;

        default:
          message = `${Math.floor(div / (this.minutesToMillis(24 * 60)))} days ago`;
          iconStyle = "longAgo";
      }
    }
    return {message: message, iconStyle: iconStyle};
  };

  minutesToMillis = (minutes) => minutes * 60 * 1000;

  render() {
    const {isLoading, trackingDetection, trackingKey, activeTab, loaded, linkShopify, trackingId, hideShopifyTab} = this.state;
    const trackingItemStyle = {justifyContent: 'flex-start', display: 'flex'};

    trackingDetection.forEach(td => {
      let trackingStatus = this.getTrackingStatus(td.lastEventTimestamp);
      td.message = trackingStatus.message;
      td.iconStyle = trackingStatus.iconStyle;
    })

    let stagingParam = this.state.stagingEnv ? ', {staging: true}' : '';

    const includeScriptCodeString = '<script type="text/javascript">\n' +
      '  (function(){var distil=window.distil=window.distil||[];if(distil.initialize)return;if(distil.invoked){if(window.console&&console.error){console.error("Distil snippet included twice.")}return}distil.invoked=true;distil.methods=["identifyCustomer","trackEvent","trackOrder","trackContent","trackProduct","trackPage","trackPageView"];distil.factory=function(method){return function(){var args=Array.prototype.slice.call(arguments);args.unshift(method);distil.push(args);return distil}};for(var i=0;i<distil.methods.length;i++){var key=distil.methods[i];distil[key]=distil.factory(key)}distil.load=function(key,options){var script=document.createElement("script");script.type="text/javascript";script.async=true;script.src="https://engagement.distil.ai/src/distil-latest.min.js";var first=document.getElementsByTagName("script")[0];first.parentNode.insertBefore(script,first);distil._loadOptions=options||{};distil._loadOptions.distilAccountId=key;distil.trackPage()};distil.SNIPPET_VERSION="1.6.0";\n' +
      `      distil.load("${trackingKey}"${stagingParam})\n` +
      '  })();\n' +
      '</script>'

    const customerProperties = `
    let customerProperties = {
      CustomerId : '1234',
      EmailAddress: 'user@yourcompany.com',
      FirstName: 'Barry',
      LastName: 'Scott',
      GDPRStatus: {
        MarketingSubscribed: false,
        RightOfAccessRequested: true,
        DataAnonymization: true
      },
      LastSeen: 1609156563000,
      FacebookSlug: "",
      TwitterHandle: "",
      InstagramHandle: "", 
      LinkedInSlug: "",
      PostalAddress: {
        Line1: "2851  Rogers Street",
        Line2: "",
        Line3: "",
        Town: "Cincinnati",
        Region: "Michigan",
        Country: "USA",
        Postcode: "48075"
      },
      Age: 33,
      Interests: "Tennis, Football"
    };\n`;

    const productProperties = `
    let productProperties = {
      ProductId: "prod123",
      Name: "Test",
      Precis: "product test",
      ThumbnailUrl: "http://thumbnail/url",
      FullImageUrl: "http://image/url",
      ListPriceExTax: 12.23,
      ListPriceIncTax: 16.23,
      Currency: "USD",
      PriceBreaksDesc: "Some description",
      Available: false,
      Quantity: 23
    }
    \n`;

    const contentProperties = `
    let contentProperties = {
      ContentId: "cont123",
      Precis: "Some description",
      Title: "Content",
      ContentType: "Article",
      ThumbnailUrl: "http://thumbnail/url",
      FullImageUrl: "http://image/url",
      PublicUrl: "http://public/url",
      PublishedOnUTC: "2020-12-12T12:12:12Z",
      ExpiresOnUTC: "2021-12-12T12:12:12Z",
      KeyWords: "Article, theme",
      SubTitle: "subTitle"
    }
    \n`;

    const orderProperties = `
    let orderProperties = {
        OrderId: "Ord123",
        OrderLineItems: [
          {
              OrderExternalId: "Ord123",
              OrderLineItemId: "Ord123-1",
              ProductId: "ABC-1234",
              Name: "Felt tiles",
              Qty: 3,
              PriceExTax: 50.00,
              PriceIncTax: 60.00,
              ThumbnailUrl: "https://www.dummystore.co.uk/thumbnails/1.jpg",
              FullImageUrl: "https://www.dummystore.co.uk/images/1.jpg",
              Url: "https://www.dummystore.co.uk/products/1",
              Categories: ["Texttiles", "Ceramics"]
          },
          {
              OrderExternalId: "Ord123",
              OrderLineItemId: "Ord123-2",
              ProductId: "ABC-789",
              Name: "Fruit Smoothie",
              Qty: 1,
              PriceExTax: 50.00,
              PriceIncTax: 60.00,
              ThumbnailUrl: "https://www.dummystore.co.uk/thumbnails/3.jpg",
              FullImageUrl: "https://www.dummystore.co.uk/images/3.jpg",
              Url: "https://www.dummystore.co.uk/products/3",
              Categories: ["Food", "Beverages"]
          }
      ],
      TotalOrderValueExTax: 200.00,
      TotalOrderValueIncTax: 240.00,
      Currency: "GBP",
      PaymentMethod: "STRIPE",
      BillingAddress: {
          Title: "Mr",
          FirstName: "Sam",
          LastName: "Mendes",
          Company: "BiG",
          Line1: "Third Floor",
          Line2: "",
          Line3: "",
          Town: "Exeter",
          Region: "Devon",
          Country: "United Kingdom",
          Postcode: "EX1 1QA",
          Phone: "12345 678910"
      },
      DeliveryAddress: {
          Title: "Mr",
          FirstName: "Sammy",
          LastName: "Big",
          Company: "BiG",
          Line1: "Second Floor",
          Line2: "",
          Line3: "",
          Town: "Exeter",
          Region: "Devon",
          Country: "United Kingdom",
          Postcode: "EX1 1QA",
          Phone: "12345 678910"
      }
    }
    \n`;

    const codeIdentifyCustomer = '<script>\n' + customerProperties +
      ' distil.identifyCustomer(customerProperties);\n' +
      '</script>';

    const codeTrackProduct = '<script>\n' + customerProperties + productProperties +
      '   distil.trackProduct(productProperties, customerProperties);\n' +
      '</script>';

    const codeTrackProductAnon = '<script>\n' + productProperties +
    '   distil.trackProduct(productProperties);\n' +
    '</script>';

    const codeTrackContent = '<script>\n' + customerProperties + contentProperties +
      '   distil.trackContent(contentProperties, customerProperties);\n' +
      '</script>';

    const codeTrackContentAnon = '<script>\n' + contentProperties +
    '   distil.trackContent(contentProperties);\n' +
    '</script>';

    const codeTrackOrder = '<script>\n' + customerProperties + orderProperties +
      '   distil.trackOrder(orderProperties, customerProperties);\n' +
      '</script>';

    const codeTrackPageView = '<script>\n' +
      '   distil.trackPageView();\n' +
      '</script>';

    const codeTrackPageViewAdditionalPageInfo = '<script>\n' +
        '   distil.trackPageView({pageUrl:"/new-path"});\n' +
        '</script>';

    const trackingIdIsUpdated = (trackingIdCorrect) => {
      this.setState({ firstScreenTracking: trackingIdCorrect });
    }

    const verifiedShopify = () => {
      if (!this.state.firstScreenTracking) {
        return (<TrackingShopify
            linkShopify={linkShopify}
            trackingId={trackingId}
            loaded={loaded}
            trackingIdIsUpdated={trackingIdIsUpdated}/>);
      }
      return (<TrackingShopifyCompleted/>)
    }

    return (
      <React.Fragment>
         <React.Fragment>
                <Jumbotron className="border-radius-0 bg-white p-0 m-0 pt-4 ml-4 tracking-i-main">
                  <Navbar bg="white" className="">
                    <Navbar.Brand href="#home"><img src={logoTracking} alt=""/></Navbar.Brand>
                    <strong>Tracking Instructions</strong>
                  </Navbar>
                  <Container fluid="true" className="tracking-container">
                    <Row className="instructions-content-wrapper">
                      <Col lg={8} className="instructions-content">
                        <div className="nav-tracking">
                          {
                            !hideShopifyTab &&
                              <div
                                  onClick={() => { this.switchNavTab('shopify') }}
                                  className={`shopify-nav-tab nav-tab-base ${activeTab === 'shopify' ? 'nav-tab--active' : ''}`}>
                                    <img src={shopify} alt=""/>
                                    Shopify
                              </div>
                          }
                          <div
                              onClick={() => { this.switchNavTab('bespoke') }}
                              className={`bespoke-nav-tab nav-tab-base ${activeTab === 'bespoke' ? 'nav-tab--active' : ''} `}>
                                <img src={logoTracking} alt=""/>
                                Bespoke
                          </div>
                        </div>
                        { activeTab === 'shopify' ?
                            verifiedShopify() :
                            <section className="area-instruction">
                              <p>For a complete introduction to using the Distil Tracking script please see the <a
                                  href="https://help.distil.ai/hc/en-gb/articles/10514388937757-Introduction-to-Tracking"
                                  className="" rel="noreferrer" target="_blank">Guide to Distil Tracking.</a></p>
                              <h5 style={{marginTop: 20, marginBottom: 20}}>Tracking Setup</h5>
                              <p>Copy and paste the following code snippet into the top of the Head section of your
                                website: </p>
                              nav-tab-base
                              <SyntaxHighlighter language="xml" style={docco}>
                                {includeScriptCodeString}
                              </SyntaxHighlighter>

                              <p>When a user logs in to your site - call the identifyUser function: </p>
                              <SyntaxHighlighter language="xml" style={docco}>
                                {codeIdentifyCustomer}
                              </SyntaxHighlighter>

                              <p>Every time a user views a Product - call: </p>
                              <SyntaxHighlighter language="xml" style={docco}>
                                {codeTrackProduct}
                              </SyntaxHighlighter>

                              <p>Or to track an anonymous Product view - call the function without any customer
                                information: </p>
                              <SyntaxHighlighter language="xml" style={docco}>
                                {codeTrackProductAnon}
                              </SyntaxHighlighter>

                              <p>Every time a user views a Piece of content - call: </p>
                              <SyntaxHighlighter language="xml" style={docco}>
                                {codeTrackContent}
                              </SyntaxHighlighter>

                              <p>Or to track an anonymous Content view - call the function without any customer
                                information: </p>
                              <SyntaxHighlighter language="xml" style={docco}>
                                {codeTrackContentAnon}
                              </SyntaxHighlighter>

                              <p>Every time a user Completes an Order - call: </p>
                              <SyntaxHighlighter language="xml" style={docco}>
                                {codeTrackOrder}
                              </SyntaxHighlighter>

                              <p>Each page is automatically tracked when the Distil Tracking script is loaded. However, to register another page view manually then call the
                                function without any other contextual information: </p>
                              <SyntaxHighlighter language="xml" style={docco}>
                                {codeTrackPageView}
                              </SyntaxHighlighter>
                              <p>When tracking different pages within a Single Page Application - it is possible to pass
                                in additional
                                contextual information so that the journey through the site can be registered:
                              </p>
                              <SyntaxHighlighter language="xml" style={docco}>
                                {codeTrackPageViewAdditionalPageInfo}
                              </SyntaxHighlighter>
                              <p>
                                For full details, please see the&nbsp;<a href="https://help.distil.ai/hc/en-gb/articles/10546320601117-Tracking-Setup-Distil-Tracking-Code#h_01HSKE6ED8AZ27XPP74XTREBFG"
                                   className="" rel="noreferrer" target="_blank">Guide to Distil Tracking.</a>
                            </p>
                          </section>
                        }
                      </Col>
                      <Col lg={4} className="bg-list">
                      <section className="area-list p-4">
                          <h3>How do I know it's working?</h3>
                          <p>
                            <strong>Distil will monitor your Tracking feed and tick the various types of data expected below.</strong>
                            It may take an hour or two for this to be updated.
                            Distil will keep monitoring this to make sure your feeds continue to work.
                          </p>
                          {isLoading &&
                            <ListGroup as="ul">
                              {trackingDetection.map((data, index) => (
                                <ListGroup.Item as="li" key={index} className="row" style={trackingItemStyle}>
                                  <i className={`icon-check_circle ${data.iconStyle}`}/>
                                  <div>
                                    <strong>{data.eventName}</strong><br/>
                                    {data.message}
                                  </div>
                                </ListGroup.Item>
                              ))}
                            </ListGroup>
                          }
                        </section>
                      </Col>
                    </Row>
                  </Container>
                </Jumbotron>
              </React.Fragment>
      </React.Fragment>
    );
  }
}

export default Tracking;
