import React from "react";
import millify from "millify";

export const NOT_PROCESSED = 'Not processed yet ';
export const SEGMENT_TYPE_SORTING_ORDER = ["ALL_CUSTOMERS", "AI", "USER_CREATED"];
export const ENRICHMENT_ENABLED_STATUSES = ['ENABLED', 'ENABLED_WAITING_FOR_FIRST_RUN'];

export function getSegmentChangeStyle(num, type, defaultValue = 'draw-black') {
  if (num > 0 && type === 'GROWING_IS_GOOD' || num < 0 && type === 'SHRINKING_IS_GOOD') {
    return 'draw-positive'
  }

  if (num < 0 && type === 'GROWING_IS_GOOD' || num > 0 && type === 'SHRINKING_IS_GOOD') {
    return 'draw-negative'
  }

  return defaultValue;
}

export function getCountChangePercent(percent, limit = 1000) {
  let checkedPercent = +percent || 0;

  let sign = Math.sign(checkedPercent) < 0 ? '-' : '';
  let comparison = Math.abs(checkedPercent) > limit ? '>' : '';

  return `${comparison} ${sign}${Math.min(Math.abs(checkedPercent), limit)}%`;
}

export function getSegmentAverageType(all, part, defaultStile = 'draw-neutral') {
  let allNum = all || 0;
  let partNum = part || 0;

  if (allNum < partNum) {
    return <p><strong className="draw-positive">greater</strong> than</p>
  }

  if (allNum > partNum) {
    return <p><strong className="draw-negative">less</strong> than</p>
  }

  return <p><strong className={defaultStile}>same</strong> as</p>
}

export function getSegmentChangeIcon(num) {
  if (num > 0) {
    return 'icon-arrow-up';
  }

  if (num < 0) {
    return 'icon-arrow-down';
  }

  return 'icon-arrow-right';
}

export function getSegmentGrowthType(customerSegmentGrowthType) {
  switch (customerSegmentGrowthType) {
    case 'GROWING_IS_GOOD':
      return <strong className="draw-positive">Grow</strong>;
    case 'SHRINKING_IS_GOOD':
      return <strong className="draw-negative">Shrink</strong>;
    default:
      return '';
  }
}

export function divide(value, divider, defaultValue = NOT_PROCESSED, digits = 0, percents = true) {
  if (!divider) {
    return defaultValue;
  }

  return roundTo((value || 0) / divider * (percents ? 100 : 1), digits);
}

export function roundTo(num, digits = 0) {
  return num ? +(Math.round(+`${num}e+${digits}`) + "e-" + digits) : 0;
}

export function formatNumber(num) {
  return num.toLocaleString(navigator.language, {minimumFractionDigits: 0})
}

export function getPluralEnding(num) {
  return Number(num || 0) !== 1 ? 's' : ''
}

export function formatToMilli(num) {
  return millify(num || 0, {precision: getDecimalsCount(num)});
}

export function getDecimalsCount(num) {
  let value = Math.abs(num || 0);

  if (value < 1000) {
    return 0;
  } else if (value < 1_000_000) {
    return 1;
  } else {
    return 2;
  }
}

export function areDisabledAttributesUsed(rulesGroups, dataSchema) {
  return rulesGroups?.some(ruleGroup => ruleGroup?.rules.some(rule => {
    const ds = dataSchema?.schemaSections.find(d => d.datasourceId === rule.dataSourceId);
    const attr = ds?.attributes.find(a => a.id === rule.dataSourceAttributeId);
    return ds?.disabled || attr?.active === false;
  }))
}

export function processAttributeListToShow(rulesGroups, dataSchema) {
  return rulesGroups?.forEach(ruleGroup => ruleGroup?.rules.forEach(rule => {
    const ds = dataSchema?.schemaSections.find(d => d.datasourceId === rule.dataSourceId) || {};
    ds.usedInRule = true;
    let find = ds.attributes?.find(a => a.id === rule.dataSourceAttributeId) || {};
    find.usedInRule = true;
  }))
}

export function isSegmentProcessing(segment) {
  return segment.customerSegmentBuildStatus === 'UPDATING_STATS' || segment.customerSegmentBuildStatus === 'BEING_BUILT';
}

export function getSegmentsSort(reverseOrder = false) {
  return (a, b) => {
    // sort by segment type then by name then by id (guarantees consistency if names are equals)
    return (compare(getSegmentTypePoints(a), getSegmentTypePoints(b)) ||
      compare(
        (a.name || a.segmentName)?.toLowerCase(),
        (b.name || b.segmentName)?.toLowerCase()
      ) ||
      compare(a.id, b.id)) * (reverseOrder ? -1 : 1);
  }
}

export function getSegmentTypePoints(segment) {
  return SEGMENT_TYPE_SORTING_ORDER.indexOf(segment.customerSegmentType);
}

export function compare(a, b, compareFunc = (f, s) => f < s) {
  if (compareFunc(a, b) === true) {
    return -1;
  } else if (compareFunc(b, a) === true) {
    return 1;
  } else {
    return 0;
  }
}

export function getSegmentIconClass(segment, markAsUsed = false) {
  let baseIconClass = "icon icon-relative";

  if (segment.customerSegmentType === "AI") {
    baseIconClass += " icon-helper";
  } else if (segment.privateSegment) {
    baseIconClass += " icon-person_outline";
  } else {
    baseIconClass += " icon-people_outline";
  }

  return baseIconClass + (markAsUsed || segment?.destinations?.filter(v => v.type !== 'DATA_WAREHOUSE').length > 0 ? " segment-used" : "");
}

export function noDataForAI(allCustomersSegment, spendBracketEnrichment, engagementEnrichment) {
  return (allCustomersSegment?.cachedCustomerCount === 0 || (
        !ENRICHMENT_ENABLED_STATUSES.includes(spendBracketEnrichment?.enrichmentProcessedStatus)
      )
    );
}

export function isAIDataNotProcessed(allCustomersSegment, segment, spendBracketEnrichment, engagementEnrichment) {
  // if segment has no stats - returns true
  let spendBracketUpdateDate = spendBracketEnrichment?.dateLastProcessed;

  return segment?.customerSegmentType === 'AI' &&
    !noDataForAI(allCustomersSegment, spendBracketEnrichment, engagementEnrichment) &&
    !spendBracketUpdateDate;
}

export function filterAiAndDefineAiStatus(allCustomersSegment, segments, engagement, spendBracket) {
  let aiNoData = noDataForAI(allCustomersSegment, spendBracket, engagement);

  let aiNotProcessed = segments.filter(s => s.customerSegmentType === 'AI')
    .every(s => isAIDataNotProcessed(allCustomersSegment, s, spendBracket, engagement));

  let filtered = segments.filter(s => {
    const usedInDestination = s.destinations.length > 0;
    const wasNotEmpty = s?.maxCustomersCount > 0;

    return s.customerSegmentType === 'AI' ?
        (!(aiNoData || aiNotProcessed) && wasNotEmpty || usedInDestination) :
        true;
  });

  return {segments: filtered, aiNoData, aiNotProcessed};
}

export function getAllCustomersSegment(segments) {
  return segments.find(s => s.customerSegmentType === 'ALL_CUSTOMERS');
}

  export function getUrlVars(url) {
    let vars = {};
    url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value;
    });
    return vars;
  }
