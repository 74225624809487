import React, {Component} from "react";
import {Button, Form, ListGroup, Modal} from "react-bootstrap";
import _ from "lodash";
import DestinationAPI from "../../services/destination";
import Loader from "../Loader/loader";
import ViewAttributes from "../../views/Main/Customers/common/DataView/attributeList";
import MapAttributes from "./attributeMapping";
import {toast} from "react-toastify";
import RadioButton from "../../views/Main/Customers/enrichment/enrichmentData/enrichmentContent/radioButton";
import {notNull} from "./attributeProcessor";
import MapBrazeAttributes from "./brazeAttrMapping";

class AttributeSelectDropdown extends Component {
  INTEGRATION_CREATION_STEPS = {
    DOT_DIGITAL: ['CHOOSE_SYNC_TYPE', 'CHOOSE_ATTRIBUTES', 'CHOOSE_MAPPING'],
    DOT_DIGITAL_V3: ['CHOOSE_SYNC_TYPE', 'CHOOSE_ATTRIBUTES', 'CHOOSE_MAPPING'],
    DEFAULT: ['CHOOSE_ATTRIBUTES'],
    BRAZE: ['CHOOSE_ATTRIBUTES', 'CHOOSE_CORE_ATTRIBUTES']
  }

  STEPS_INFO = {
    CHOOSE_SYNC_TYPE: {
      hideAttrInfo: true,
      title: "Choose DotDigital Data Location"
    },
    CHOOSE_ATTRIBUTES: {
      title: "Choose Attributes"
    },
    CHOOSE_MAPPING: {
      title: "Map Attribute Names"
    },
    CHOOSE_CORE_ATTRIBUTES: {
      hideAttrInfo: true,
      title: "Map Core Braze Fields"
    }
  }

  DOT_DIGITAL_SYNC_TYPES = [
    {
      id: 'CONTACT_DATA_ONLY',
      title: 'Customer Data Fields'
    },
    {
      id: 'INSIGHT_DATA_ONLY',
      title: 'Insight Data Collection'
    },
    {
      id: 'CONTACT_AND_INSIGHT_DATA',
      title: 'Both: Customer Data and Insight Data Collection'
    }
  ]

  constructor(props) {
    super(props);
    this.attrList = React.createRef();
    this.state = {
      selectedAttributesCount: 0,
      dataView: [],
      masterDataView: [],
      searchStarted: false,
      searchStartedBegin: false,
      searchWhat: "",
      isLoaded: false,
      syncType: props?.selectedIntegration?.integrationSettings?.syncType,
      creationSteps: [],
      destinationType: null,
      currentStep: null,
    };
  }

  componentWillMount() {
    this.getIntegrationAttr();
  }

  getIntegrationAttr = async event => {
    try {
      await DestinationAPI.getIntegrationAttr(
        this.props.selectedIntegration.destinationId,
        this.props.currentCardType
      ).then(response => {
        let dataView = response;
        dataView
          .flatMap(it => it.attributes)
          .forEach(it => it.isActive = it.selected);

        const steps = this.INTEGRATION_CREATION_STEPS[this.props.selectedIntegration.destinationType] || this.INTEGRATION_CREATION_STEPS["DEFAULT"];

        this.setState({
            dataView,
            masterDataView: response,
            isLoaded: true,
            creationSteps: steps,
            currentStep: steps[0],
            destinationType: this.props.selectedIntegration.destinationType
          },
          () => this.setIntegrationAttr()
        );
      });
    } catch (err) {
    }
  };

  setIntegrationAttr = async event => {
    let selectedAttributes = this.props.selectedIntegration.attributes || [];
    let selectedAttributesLength = selectedAttributes.length;
    let dataView = this.state.dataView;

    dataView
      .flatMap(section => section.attributes || [])
      .forEach(attr => {
        let attTag = attr.attributeTag ?? "null";
        let attId = attr.attributeId ?? "null";

        for (let i = 0; i < selectedAttributesLength; i++) {
          let activeAttr;

          if(selectedAttributes[i].attributeDataTag === null) {
            activeAttr = (selectedAttributes[i].attributeId ?? "null") === (attId ?? "null");
          } else {
            activeAttr = ((selectedAttributes[i].attributeDataTag ?? "NONE") === (attTag ?? "NONE")) ||
                ((selectedAttributes[i].attributeId ?? "null") === (attId ?? "null"));
          }


          attr.selected = activeAttr;
          attr.isActive = activeAttr;

          if (activeAttr) {
            break;
          }
        }
      });

    this.selectionCheck(dataView);
    this.setState({dataView});
  };

  onSelectSection = group => {

    const dataView = this.state.dataView;

    let dataViewSection = dataView.find(dv => dv.sectionId === group.sectionId);

    let isAllChecked =
      dataViewSection.attributes.filter(
        count => count.toShow !== false && count.selected === true
      ).length > 0;

    dataViewSection.attributes.filter(attr => attr.toShow !== false).map(
      attribute => {
        attribute.selected = !isAllChecked;
        attribute.isActive = !isAllChecked;
      }
    );

    this.selectionCheck(dataView);

    this.setState({dataView});
  };

  selectionCheck = dataView => {
    const productTags = [
      "PRODUCT_EXTERNAL_ID",
      "PRODUCT_NAME",
      "PRODUCT_SHOP_URL",
      "PRODUCT_IMAGE_URL",
      "PRODUCT_THUMBNAIL_URL",
      "PRODUCT_PRECIS",
      "PRODUCT_AVAILABLE",
      "PRODUCT_LIST_PRICE_EX_TAX",
      "PRODUCT_LIST_PRICE_INC_TAX",
      "PRODUCT_PRICE_BREAKS_DESCRIPTION"
    ];
    let selectedAttributes = [];
    let selectedAttributesCount = 0;

    dataView.map(section => {
      section.attributes.map(attributes => {
        if (attributes.selected === true) {
          selectedAttributes.push(attributes);
          if (_.includes(productTags, attributes.attributeTag)) {
            selectedAttributesCount += this.props.selectedIntegration
              .recommendationCount;
          } else {
            selectedAttributesCount++;
          }
        }
      });
    });
    this.setState({selectedAttributesCount});
  };

  toggleAll = () => {
    const dataView = this.state.dataView;
    dataView.flatMap(it => it.attributes).forEach(it => it.selected = it.isActive);
    this.selectionCheck(dataView);
  };

  onSelectAttribute = (group, attribute) => {
    const dataView = this.state.dataView;
    const dataViewSection = dataView.find(dv => dv.sectionId === group.sectionId);
    const attr = dataViewSection.attributes.find(it => attribute.attributeId === it.attributeId);

    attr.selected = !attr.selected;
    attr.isActive = attr.selected;

    this.selectionCheck(dataView);
    this.setState({dataView});
  };

  backToSelectAttr = () => {
    let step = 'CHOOSE_ATTRIBUTES';
    this.setState({currentStep: step});
  }

  updateDataSourceAttr = async event => {
    const {currentStep, creationSteps} = this.state;
    if (this.hasNextSteps(currentStep, creationSteps)) {
      let nextStep = creationSteps[creationSteps.findIndex(s => s === currentStep) + 1];
      const {dataView} = this.state;
      this.setState({currentStep: nextStep, dataView: dataView});
    } else {
      let isMappingStep = currentStep === 'CHOOSE_MAPPING' || currentStep === 'CHOOSE_CORE_ATTRIBUTES';

      try {
        let updatedData = isMappingStep ? this.attrList?.current?.getUpdatedData() : {};

        if (!isMappingStep || updatedData) {
          const {dataView} = this.state;
          let attributeSelected = [];

          dataView.forEach(section => {
              section.toShow = null;
              return (section?.attributes || [])
                .forEach(att => {
                  att.toShow = null;
                  if (isMappingStep && updatedData[att.attributeId]) {
                    att.externalId = updatedData[att.attributeId];
                  }
                  if (att.selected === true) {
                    attributeSelected.push(att);
                  }
                })
            }
          );

          this.setState({dataView});
          this.props.onSelectAttribute(attributeSelected, this.state.selectedAttributesCount);
          this.props.onCloseModal();
        }
      } catch (e) {
        if (e.message !== '') {
          toast.error(e.message);
        }
      }
    }
  };

  hasNextSteps(currentStep, creationSteps) {
    const {destinationType, syncType} = this.state;

    let insideDotDigitalDataMapping =
            (destinationType === 'DOT_DIGITAL' || destinationType === 'DOT_DIGITAL_V3') &&
      syncType === 'INSIGHT_DATA_ONLY' &&
      currentStep === 'CHOOSE_ATTRIBUTES';

    return !insideDotDigitalDataMapping && currentStep !== creationSteps[creationSteps.length - 1];
  }

  onSearch = (e, dataView) => {
    let searchWhat = e.target.value;
    this.setState({searchStarted: searchWhat !== "", searchWhat}, () =>
      this.handleSearchAll(searchWhat, dataView)
    );
  };

  handleSearchAll = (searchValue, dataViewSaved) => {
    let filteredResult = [];
    let dataView = dataViewSaved;
    if (searchValue !== "") {

      for (let i = 0; i < dataView.length; i++) {
        let dataViewItem = {...dataView[i]};

        if (dataViewItem.sectionName.toLowerCase().includes(searchValue.toLowerCase())) {
          dataViewItem.toShow = true;
          dataViewItem.isActive = true;
          dataViewItem.attributes.forEach(attr => attr.toShow = null)
          filteredResult.push(dataViewItem);
        } else {
          dataViewItem.attributes = [];
          dataViewItem.toShow = false;

          let attributes = [];
          for (let j = 0; j < dataView[i].attributes.length; j++) {
            const attr = {...dataView[i].attributes[j]};

            if (attr.name.toLowerCase().includes(searchValue.toLowerCase())) {
              dataViewItem.toShow = true;
              dataViewItem.isActive = true;
              attr.toShow = true;
              dataViewItem.attributes[j] = attr;
              dataViewItem.attributes[j].toShow = true;
            } else {
              attr.toShow = false;
            }
            attributes.push(attr)
          }
          dataViewItem.attributes = attributes;
          filteredResult.push(dataViewItem);
        }
      }
    } else {
      dataView.forEach(dv => {
        dv.toShow = null;
        dv.attributes.forEach(attr => attr.toShow = null);
      });
      filteredResult = dataView;
    }

    this.setState({dataView: filteredResult}, () => this.selectionCheck(dataView));
  };

  attributesUI() {
    const {dataView, selectedAttributesCount, isLoaded, currentStep} = this.state;
    const numExternalFields = this.props.selectedIntegration.numExternalFields;
    const filteredData = dataView.map(obj => ({
      ...obj,
      attributes: obj.attributes.filter(attr => attr.isActive || attr.isSelected || !attr.deleted)
    }));
    if (isLoaded) {
      return (
        <section className="m-auto list-view-main d-flex flex-row justify-content-center">
          <section className="view-list position-relative">
            {this.getSection(selectedAttributesCount, numExternalFields, currentStep)}
            <section className="view-select p-select">
              <ListGroup as="ul" className={currentStep === 'CHOOSE_ATTRIBUTES' ? "" : "h-100"}>
                {this.getModalContent(currentStep, filteredData)}
              </ListGroup>
            </section>
          </section>
        </section>
      );
    } else {
      return (
        <p className="pt-5">
          <Loader/>
        </p>
      );
    }
  }

  getSubTitle(syncType, currentStep) {
    return syncType === 'CONTACT_AND_INSIGHT_DATA' && currentStep === 'CHOOSE_MAPPING' ?
      <div className="sub-title">Mapping labels only apply to Customer Data Fields</div>
      : '';
  }

  getSection(selectedAttributesCount, numExternalFields, currentStep) {
    if (!this.STEPS_INFO[currentStep]?.hideAttrInfo) {
      if (numExternalFields !== -1) {
        return <p className={`selectedAttribute ${selectedAttributesCount > numExternalFields ? "hasMoreAttribute" : ""}`}>
          {selectedAttributesCount}/{numExternalFields} Attributes chosen
        </p>
      } else {
        return <p className="selectedAttribute">
          {selectedAttributesCount} Attribute chosen
        </p>
      }
    }
  }

  getModalContent(stepName, dataView) {
    const {enrichmentType, content} = this.props;
    let {syncType} = this.state;

    if (!syncType) {
      syncType = notNull(content.id) ? 'INSIGHT_DATA_ONLY' : 'CONTACT_DATA_ONLY';
    }

    switch (stepName) {
      case 'CHOOSE_ATTRIBUTES':
        return (
          <>
            <div className="view-list-top">
              <div className="filter-input">
                <Form.Group>
                  <span className="icon-search"/>
                  <Form.Control
                    type="text"
                    placeholder="Search Attributes to View..."
                    autoComplete="off"
                    onChange={e => this.onSearch(e, dataView)}
                  />
                </Form.Group>
              </div>
            </div>
            <ViewAttributes
              showDisplayName={false}
              enableOnlyCoreAttributes={false}
              attributesData={dataView}
              onToggleAttributeGroup={this.onSelectSection}
              onToggleAttribute={this.onSelectAttribute}
              allAttributesStateChanged={this.toggleAll}
            />
          </>
        );
      case 'CHOOSE_MAPPING':
        return <MapAttributes
          ref={this.attrList}
          allNamesAllowed={this.state.integrationSettings?.syncType === 'INSIGHT_DATA_ONLY'}
          enrichmentType={enrichmentType}
          recommendationCount={content.recommendationCount}
          connectionId={content.destinationId}
          integrationId={content.id}
          attributesData={this.getEnabledAttributes(dataView)}
        />;
      case 'CHOOSE_CORE_ATTRIBUTES' :
        return <MapBrazeAttributes
            ref={this.attrList}
            attributesData={this.getEnabledAttributes(dataView)}
            connectionId={content.destinationId}
            integrationId={content.id}
            integrationId={content.id}
            validationErrors={this.state.validationErrors}
        />
      case 'CHOOSE_SYNC_TYPE':
        return <RadioButton
          options={this.DOT_DIGITAL_SYNC_TYPES}
          onSelect={this.updateIntegrationSettings}
          value={syncType}
        />
    }
  }

  updateIntegrationSettings = (id) => {
    this.props.selectedIntegration.integrationSettings = {
      destinationIntegrationType: this.props.selectedIntegration.destinationType,
      syncType: id
    }

    this.setState({
      integrationSettings: {
        destinationIntegrationType: this.props.selectedIntegration.destinationType,
        syncType: id
      }, syncType: id
    });

  }

  getEnabledAttributes(dataView) {
    return dataView.map(sect => {
      return {
        ...sect,
        attributes: sect.attributes.filter(a => a.selected && a.isActive)
          .map(a => {
            a.externalId = this.props.selectedIntegration.attributes?.find(ia => ia.attributeId === a.attributeId)?.externalId

            if(!a.externalId && a.attributeTag != null){
              a.externalId = this.props.selectedIntegration.attributes?.find(ia =>
                ((ia.attributeDataTag ?? "NONE") === (a.attributeTag ?? "NONE")) ||
                ((ia.attributeId ?? "null") === (a.attributeId ?? "null")))?.externalId;
            }
            return a;
          })
      }
    }).filter(section => section.attributes.length > 0);
  }

  render() {
    const {currentStep, creationSteps, dataView, syncType} = this.state;

    return (
      <Modal
        show={this.props.modalstate}
        onHide={this.props.onCloseModal}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="wide-modal"
        className="attribute-select-dropdown"
      >
        <Modal.Header closeButton className="border-bottom-0 pb-0">
          <Modal.Title className="" id="contained-modal-title-vcenter">
            {this.STEPS_INFO[currentStep]?.title || "Choose Attributes"}
            {this.getSubTitle(syncType, currentStep)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 no-scroll">
          <p className="mb-3 pl-3">{this.props.content.destinationTitle}</p>

          <section className="simple-attribute">{this.attributesUI()}</section>
        </Modal.Body>
        <Modal.Footer className="pl-0">
          <Button
              className="border-0 btn-cancel"
              hidden={creationSteps[creationSteps.findIndex(s => s === currentStep) + 1] === "CHOOSE_CORE_ATTRIBUTES"}
              onClick={this.backToSelectAttr}>
          Back
          </Button>
          <Button
            onClick={this.updateDataSourceAttr}
            disabled={dataView.length === 0}
            className="border-0 btn-save"
          >
            {this.hasNextSteps(currentStep, creationSteps) ? 'Next' : 'Save Changes'}
          </Button>
          <Button
            onClick={this.props.onCloseModal}
            className="border-0 btn-cancel"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AttributeSelectDropdown;
