import React, { Component } from "react";

const checkNavigtorTimer = "";
class DataHead extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabsCount: "",
      gridSetting: "",
      gridSettingOnScroll: "",
      tabsSetting: [],
      tabSettingOnScroll: [],
      tabSpace: 5,
      navigatorSize: 60,
      mainToggle: "",
      checkNavigtor: true
    };
  }

  componentDidMount() {
    this.setState(
      {
        tabsCount: this.props.customerList[0].dataRecordSections.length,
        mainToggle: this.props.mainToggle
      },
      this.getGridSetting
    );
    // this.isNavigatorRequired();
  }
  componentWillReceiveProps() {
    // this.isNavigatorRequired();
    setTimeout(() => {
      let gridTabs = document.querySelector(".grid-tabs");
      let tableWrapper = document.querySelector(".table-wrapper.grid-data");
      let tableWrapperWidth =
        tableWrapper !== null ? tableWrapper.clientWidth : 0;
      if (gridTabs !== undefined && gridTabs !==null) {
        // debugger;
        gridTabs.setAttribute(
          "style",
          `width:${tableWrapperWidth - this.state.navigatorSize}px`
        );
      }
    }, 500);
  }

  isNavigatorRequired = () => {
    checkNavigtorTimer = setInterval(() => {
      let lastTabWidth = document.querySelector(".grid-tabs .tab_last")
        .clientWidth;
      let gridTabs = document.querySelector(".grid-tabs");
      let tabnavigator = document.querySelector(".tab-navigator");
      if (tabnavigator !== null) {
        if (gridTabs.clientWidth >= gridTabs.scrollWidth - lastTabWidth - 5) {
          let tabnavigatorClass = tabnavigator.getAttribute("class");
          tabnavigator.remove("disable-nav");
          tabnavigator.className.replace(/(?:^|\s)disable-nav(?!\S)/, "");
          tabnavigator.setAttribute(
            "class",
            `disable-nav ${tabnavigatorClass}`
          );
        }
        clearInterval(checkNavigtorTimer);
      }
    }, 100);
  };

  getGridSetting = () => {
    this.setState({ gridSetting: "" });
    let gridSetting = this.state.gridSetting;
    let tabSpace = this.state.tabSpace;
    let tabsCount = this.state.tabsCount;
    let tabsSetting = this.state.tabsSetting;
    let lastTabNo = tabsCount - 1;
    let tableWrapper = document.querySelector(".table-wrapper.grid-data");
    let sidebar = document.querySelector("#sidebar");
    let gridTabs = document.querySelector(".grid-tabs");
    let firstTab = document.querySelector(".grid-tabs .tab_0");
    let firstTabMoving = document.querySelector(".grid-tabs .tab_1");
    let lastTabMoving = document.querySelector(".grid-tabs .tab_" + lastTabNo);
    let lastTab = document.querySelector(".grid-tabs .tab_last");

    let firstHeading = document.querySelector(".thead-wrapper .tab_0");
    let firstHeadingMoving = document.querySelector(".thead-wrapper .tab_1");

    // Getting Grid Settings
    let tableWrapperWidth = tableWrapper.clientWidth;
    let tableWrapperTop = tableWrapper.offsetTop;
    let tableWrapperRight =
      window.innerWidth - tableWrapperWidth - tableWrapper.offsetLeft;
    let tableWrapperLeft =
      tableWrapper.offsetLeft +
      (sidebar.offsetLeft < 0 ? 0 : sidebar.clientWidth);

    let firstTabSpace =
      firstTab != null ? firstTab.clientWidth + tabSpace : 0 + tabSpace;
    let firstHeadingSpace = firstHeading != null ? firstHeading.clientWidth : 0;

    let availableTabSpace =
      tableWrapperWidth - this.state.navigatorSize - firstTabSpace;
    let scrollLeftPostition = tableWrapper.scrollLeft;
    let scrollRightPostition =
      tableWrapper.scrollWidth - scrollLeftPostition - tableWrapperWidth;

    gridTabs.setAttribute(
      "style",
      `width:${tableWrapperWidth - this.state.navigatorSize}px`
    );

    if (firstHeadingMoving != null) {
      let firstSectionWidth =
        firstHeadingMoving.offsetLeft -
        firstHeading.offsetLeft -
        firstTab.clientWidth;

      // firstSectionWidth =
      //   firstSectionWidth > tableWrapperWidth / 4
      //     ? firstSectionWidth- tableWrapperWidth / 4
      //     : firstSectionWidth;

      // firstTabMoving.setAttribute(
      //   "style",
      //   `margin-left:${firstSectionWidth}px;`
      // );

      // let marginLeft =
      //   firstTab.clientWidth >= firstHeading.clientWidth
      //     ? firstTab.clientWidth - firstHeading.clientWidth
      //     : firstHeading.clientWidth - firstTab.clientWidth;

      // firstTabMoving.setAttribute("style", `margin-left:${marginLeft}px;`);

      // lastTab.setAttribute(
      //   "style",
      //   `flex: 0 0 ${tableWrapperWidth -
      //     (firstHeading.clientWidth +
      //       lastTabMoving.clientWidth +
      //       this.state.navigatorSize)}px;`
      // );
    }

    // Getting Total Tab width

    // var totalTabWidth = 0;

    for (let i = 0; i < tabsCount; i++) {
      var mytab_heading = document.querySelector(".grid-tabs .tab_" + i);

      let nextTab = i + 1;
      var myGridNext_heading = document.querySelector(
        ".thead-wrapper .tab_" + nextTab
      );

      // if (i > 0) {
      //   totalTabWidth +=
      //     document.querySelector(".grid-tabs .tab_" + i).clientWidth + tabSpace;
      // }

      let sectionWidth = 0;
      if (i !== tabsCount - 1) {
        sectionWidth =
          myGridNext_heading.offsetLeft -
          document.querySelector(".thead-wrapper .tab_" + i).offsetLeft;
      } else {
        sectionWidth =
          tableWrapper.scrollWidth -
          document.querySelector(".thead-wrapper .tab_" + i).offsetLeft;
      }

      let tabLeftOffset = document.querySelector(".thead-wrapper .tab_" + i)
        .offsetLeft;

      let tabSetting = {
        name: "tab_" + i,
        width: document.querySelector(".grid-tabs .tab_" + i).clientWidth,
        spaceLeft: tabSpace,
        leftOffset: tabLeftOffset,
        sectionWidth: sectionWidth
      };

      let tabDistance =
        firstTabSpace > firstHeadingSpace ? firstTabSpace : firstHeadingSpace;
      let scrollHeading = i == 0 ? 0 : tabLeftOffset - firstHeadingSpace;
      let scrolltab = i === 0 ? 0 : mytab_heading.offsetLeft - tabDistance;

      mytab_heading.setAttribute("data-scrollheading", `${scrollHeading}`);
      mytab_heading.setAttribute("data-scrolltab", `${scrolltab}`);
      mytab_heading.setAttribute("data-tabno", `${i}`);
      tabsSetting.push(tabSetting);
    }

    //Updating Setting in States

    gridSetting = {
      top: tableWrapperTop,
      right: tableWrapperRight,
      left: tableWrapperLeft,
      width: tableWrapperWidth,
      firstTabSpace: firstTabSpace,
      availableTabSpace: availableTabSpace,
      // totalTabWidth: totalTabWidth,

      scrollLeftPostition: scrollLeftPostition,
      scrollRightPostition: scrollRightPostition
    };

    this.setState({ gridSetting });
    this.setState({ tabsSetting });
  };

  renderSectionHeadings = sections => {
    return sections.map((section, keys) =>
      section.attributes.map((attribute, key) => (
        <th
          key={`${section.sectionName}_${key}`}
          className={`${keys == 0 ? "firstSection" : ""} section_${
            keys % 2 ? "odd" : "even"
          } ${key === 0 ? "tab_" + keys : ""}`}
        >
          {attribute.displayName}
        </th>
      ))
    );
  };

  render() {
    const { customerList } = this.props;
    return (
      <thead className="thead-wrapper">
        <tr>
          {this.renderSectionHeadings(customerList[0].dataRecordSections)}
        </tr>
      </thead>
    );
  }
}

export default DataHead;
