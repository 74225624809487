import React, {Component} from "react";
import {Button, Col, Form} from "react-bootstrap";
import DataConnectionAPI from "../../../../../services/dataConnections";
import NewConnectionMeta from "./NewConnectionMeta";
import {Textbox} from "react-inputs-validation";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import {toast} from "react-toastify";
import {distilGoogleAdsAccountConfig} from "../../../../../variables/authCreds";

const initialState = {
    updatingContent: false,

    connectionDesc: "",
    connectionTitle: "",
    connectionSettingsUserName: "",

    connectionToken: "",
    iconType: "GOOGLE_ADS",
    isConnectionTested: "TEST",
    connectionSettingEnabled: true,
    hasConnectionTitleError: true,
    hasConnectionDescError: true,
    enabled: true,
    validate: false,
    disableOnEdit: false
};

const editConnection = {
    disableOnEdit: false
};

class GoogleAds extends Component {
    constructor(props) {
        super(props);
        this.state = {initialState, editConnection};
    }

    resetData = () => {
        this.setState(initialState);
        localStorage.setItem("checkChange", false);
    };

    componentDidMount() {
        this.setState(initialState);
        this.setState({connectionType: this.props.formType});
        if (this.props.editConnection) {
            this.editConnectionDefaults();
        } else {
            this.parseDataToken();
        }
    }

    componentWillReceiveProps(nextProps) {
        let checkChange = JSON.parse(localStorage.getItem("checkChange"));

        if (!checkChange) {
            if (nextProps.editConnection) {
                this.editConnectionDefaults();
            } else {
                if (this.props.editConnection) {
                    this.resetData();
                }
            }
        }
    }

    parseDataToken() {
        const {params} = this.props;
        let dataToken = params?.state;

        if (dataToken) {
            try {
                let data = JSON.parse(atob(decodeURIComponent(dataToken)));
                let validData = this.isNotEmpty(data?.title)
                    && this.isNotEmpty(data?.desc) && this.isNotEmpty(data?.cid);

                if (validData) {
                    this.setState({
                        connectionTitle: data.title,
                        connectionDesc: data.desc,
                        connectionToken: params?.code,
                        connectionSettingsUserName: data.cid,
                        isConnectionTested: 'PASS'
                        }
                    );
                }
                window.history.pushState({}, document.title, "/settings/integrations")
                this.onCheckChange();
            } catch (e) {
            }
        }
    }

    isNotEmpty(data) {
        return data?.length > 0;
    }

    editConnectionDefaults = () => {
        this.setState({
            connectionTitle: this.props.selectedConnection.name,
            connectionDesc: this.props.selectedConnection.description,
            connectionSettingsUserName: this.props.selectedConnection.connectionSettings.userName,
            connectionType: this.props.selectedConnection.connectionType,
            iconType: this.props.selectedConnection.iconType,
            connectionSettingEnabled: this.props.selectedConnection.enabled,
            updatingContent: false,
            isConnectionTested: 'PASS'
        });
    };

    validateForm = e => {
        e.preventDefault();

        this.setState({updatingContent: false});

        if (this.isValidData()) {
            this.testConnection();
        } else {
            toast.warn("Please enter valid data in fields");
        }
    };

    isValidData() {
        const {
            connectionDesc,
            connectionTitle,
            connectionSettingsUserName,


        } = this.state;
        return this.isNotEmpty(connectionSettingsUserName) && this.isNotEmpty(connectionDesc)
            && this.isNotEmpty(connectionTitle);
    }

    testConnection = () => {
        window.location.href = this.buildAuthUrl();
    }

    parseCustomerId = (s) => {
        return s.replace(/-/g, "");
    }

    addConnection = async (e) => {
        e.preventDefault();
        this.setState({updatingContent: true});
        let formData = {
            name: this.state.connectionTitle,
            description: this.state.connectionDesc,
            connectionType: 'GOOGLE_ADS',
            iconType: this.state.iconType,
            enabled: this.state.connectionSettingEnabled,
            connectionSettings: {
                securityCode: decodeURIComponent(this.state.connectionToken),
                userName: this.parseCustomerId(this.state.connectionSettingsUserName)
            }
        };

        if (this.props.editConnection) {
            let formUpdate = {id: this.props.selectedConnection.id, ...formData};

            try {
                await DataConnectionAPI.updateConnection(formUpdate, this.props.selectedConnection.id).then(response => {
                    toast.success("Connection updated successfully");
                    this.resetData();
                    this.props.onAddingConnection(response.id);
                });
            } catch (err) {
                toast.error("Connection updating error");
            }
        } else {
            try {
                await DataConnectionAPI.newConnection(formData).then(response => {
                    toast.success("Connection created successfully");
                    this.resetData();
                    this.props.onAddingConnection(response.id);
                });
            } catch (err) {
                toast.error("Connection creation error");
                this.setState({isConnectionTested: "TEST"});
            }
        }
        this.setState({updatingContent: false});
    };

    onCheckChange = () => {
        this.props.onCheckChange()
    };


    buildAuthUrl() {
        let connectionData = {
            title: this.state.connectionTitle,
            desc: this.state.connectionDesc,
            cid: this.state.connectionSettingsUserName,
        };

        return `https://accounts.google.com/o/oauth2/v2/auth` +
            "?scope=" + distilGoogleAdsAccountConfig.scope +
            "&access_type=" + distilGoogleAdsAccountConfig.access_type +
            "&include_granted_scopes=" + distilGoogleAdsAccountConfig.include_granted_scopes +
            "&response_type=" + distilGoogleAdsAccountConfig.response_type +
            "&state=" + btoa(JSON.stringify(connectionData)) +
            "&redirect_uri=" + distilGoogleAdsAccountConfig.redirectUrl +
            "&client_id=" + distilGoogleAdsAccountConfig.client_id;
    }

    renderDoneButton(isConnectionTested) {
        return <Button variant="primary"
                       disabled={!this.isValidData()}
                       type="submit">{isConnectionTested === 'PASS' ? 'Done' : 'Login'}</Button>;
    }

    getSubmitAction(isConnectionTested) {
        if (isConnectionTested === 'PASS') {
            return this.addConnection;
        } else {
            return this.validateForm;
        }
    }

    render() {
        const {
            connectionTitle,
            connectionDesc,
            // shop,
            connectionSettingsUserName,
            validate,
            isConnectionTested,
            disableOnEdit
        } = this.state;

        return (
            <React.Fragment>

                <NewConnectionMeta
                    formType={this.props.formType}
                    onBackPress={this.props.onBackPress}
                    editConnection={this.props.editConnection}
                />

                <Form onSubmit={this.getSubmitAction(isConnectionTested)}>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridName">
                            <Form.Label>Title</Form.Label>
                            <Textbox
                                classNameInput="form-control"
                                classNameContainer="custome-input"
                                tabIndex="1"
                                id={"connectionTitle"}
                                name="connectionTitle"
                                type="text"
                                validate={validate}
                                validationCallback={res => {
                                    this.setState({hasConnectionTitleError: res, validate: false})
                                }}
                                onBlur={e => {}}
                                value={connectionTitle}
                                onChange={(connectionTitle, e) => {
                                    this.setState({connectionTitle, updatingContent: false}, () => this.onCheckChange());
                                }}
                                validationOption={{name: "Title", check: true, required: true}}
                            />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridDesc">
                            <Form.Label>Description</Form.Label>
                            <Textbox
                                classNameInput="form-control"
                                classNameContainer="custome-input"
                                tabIndex="2"
                                id={"connectionDesc"}
                                name="connectionDesc"
                                type="text"
                                validate={validate}
                                validationCallback={res => this.setState({hasConnectionDescError: res, validate: false})}
                                value={connectionDesc}
                                onBlur={e => {
                                }}
                                onChange={(connectionDesc, e) => {
                                    this.setState({connectionDesc, updatingContent: false}, () => this.onCheckChange());
                                }}
                                validationOption={{name: "Description", check: true, required: true}}
                            />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridUserId">
                            <Form.Label>Account ID</Form.Label>
                            <Textbox
                                classNameInput="form-control"
                                classNameContainer="custome-input"
                                tabIndex="4"
                                id={"connectionSettingsUserName"}
                                name="connectionSettingsUserName"
                                type="text"
                                validate={validate}
                                validationCallback={res => this.setState({hasShopError: res, validate: false})}
                                value={connectionSettingsUserName}
                                onBlur={e => {
                                }}
                                disabled={isConnectionTested === 'PASS'}
                                onChange={(connectionSettingsUserName, e) => {
                                    this.setState({connectionSettingsUserName: connectionSettingsUserName}, () => this.onCheckChange());
                                }}
                                validationOption={{name: "Account ID", check: true, required: true}}
                            />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row className="btn-wrap">
                        {this.renderDoneButton(isConnectionTested)}

                        {!disableOnEdit && (
                            <Button variant="secondary" onClick={this.props.onCancelConnection}>
                                <span className="icon-cancel icon"/>
                                Cancel
                            </Button>
                        )}
                    </Form.Row>
                </Form>
            </React.Fragment>
        );
    }
}

export default GoogleAds;
