import React, { Component } from "react";

import { Form, Col, ButtonGroup, Button, Image, Badge } from "react-bootstrap";
import "../assets/styles.css";
import {userRoleList} from "../../../../../../variables/globalValues.json";

import moment from "moment";
import { dataFormat } from "../../../../../../variables/globalVariables";

import { Textbox, Select } from "react-inputs-validation";
import "react-inputs-validation/lib/react-inputs-validation.min.css";

import { toast } from "react-toastify";
import User from "../assets/img/user-dummy.jpg";
const initState = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  membershipType: "",
  isInviteSend: true,
  hasfirstNameError: true,
  haslastNameError: true,
  hasemailAddressError: true,
  hasmembershipTypeError: true,
  emailError: null,
  validateAll: false,
  validateEmail: false
};
const membershipTypeList = userRoleList.filter(type => type.id !== "DISABLED")

class InviteUser extends Component {
  constructor() {
    super();
    this.state = initState;
    this.validateForm = this.validateForm.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isInviteSend !== this.props.isInviteSend) {
      this.setState({
        isInviteSend: nextProps.isInviteSend
      });
    }

    if (nextProps.emailError !== null) {
      console.log(nextProps.emailError);
      this.setState({ emailError: nextProps.emailError });
    }
  }

  toggleValidatingAll(validateAll) {
    this.setState({ validateAll });
  }

  validateForm(e) {
    e.preventDefault();

    this.toggleValidatingAll(true);
    if (
      (!this.state.hasfirstNameError,
      !this.state.haslastNameError,
      !this.state.hasemailAddressError,
      !this.state.hasmembershipTypeError)
    ) {
      let formData = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        emailAddress: this.state.emailAddress,
        membershipType: this.state.membershipType
      };

      this.props.onSubmitInvite(formData);
    }
  }

  render() {
    const {
      firstName,
      lastName,
      emailAddress,
      membershipType,
      validateAll,
      isInviteSend,
      emailError,
      hasemailAddressError
    } = this.state;

    console.log(membershipType);
    return (
      <section className="right-body-content right-list-area bg-white">
        <Button
          aria-label="Basic example"
          className="d-block d-md-none back-btn"
          variant="secondary"
        >
          Back
        </Button>

        <div className="form-body-section">
          <div className="user-header clearfix d-flex align-items-end">
            <div className="left-section d-flex align-items-center">
              <Image src={User} roundedCircle />
              <div className="user-content">
                <h6 className="font-weight-heavy mb-0">Create Invite</h6>
              </div>
            </div>
            <div className="right-section ml-auto">
              <span>
                Invite date{" "}
                <strong> {moment().format(dataFormat.MEDIUM)}</strong>
              </span>
            </div>
          </div>

          <Form onSubmit={this.validateForm}>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridAddress1">
                <Form.Label>First Name</Form.Label>
                <Textbox
                  classNameInput="form-control"
                  classNameContainer="custome-input"
                  tabIndex="1"
                  id={"firstName"}
                  name="firstName"
                  type="text"
                  validate={validateAll}
                  validationCallback={res =>
                    this.setState({
                      hasfirstNameError: res,
                      validateAll: false
                    })
                  }
                  value={firstName}
                  placeholder="First Name"
                  onChange={(firstName, e) => {
                    this.setState({ firstName });
                    console.log(e);
                  }}
                  onBlur={e => {
                    console.log(e);
                  }}
                  validationOption={{
                    name: "First Name",
                    check: true,
                    required: true
                  }}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridAddress1">
                <Form.Label>Last Name</Form.Label>
                <Textbox
                  classNameInput="form-control"
                  classNameContainer="custome-input"
                  tabIndex="2"
                  id={"lastName"}
                  name="lastName"
                  type="text"
                  validate={validateAll}
                  validationCallback={res =>
                    this.setState({
                      haslastNameError: res,
                      validateAll: false
                    })
                  }
                  value={lastName}
                  placeholder="Last Name"
                  onChange={(lastName, e) => {
                    this.setState({ lastName });
                    console.log(e);
                  }}
                  onBlur={e => {
                    console.log(e);
                  }}
                  validationOption={{
                    name: "Last Name",
                    check: true,
                    required: true
                  }}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Email</Form.Label>
                <Textbox
                  ref={this.myemailAddress}
                  classNameInput="form-control"
                  classNameContainer="custome-input"
                  tabIndex="3"
                  id={"emailAddress"}
                  name="emailAddress"
                  type="text"
                  validate={validateAll}
                  validationCallback={res =>
                    this.setState({
                      hasemailAddressError: res,

                      validateAll: false
                    })
                  }
                  value={emailAddress}
                  placeholder="Invitees email address"
                  onChange={(emailAddress, e) => {
                    this.setState({ emailAddress, emailError: null });
                    this.setState({ isInviteSend: true });
                    console.log(e);
                  }}
                  onBlur={e => {
                    console.log(e);
                  }}
                  validationOption={{
                    name: "Email Address",
                    check: true,
                    required: true,

                    reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    regMsg: "Enter Valid Email Address"
                  }}
                />
                {!hasemailAddressError && emailError === "ENTITY_EXISTS"  && (
                  <div class="react-inputs-validation__msg___pxv8o react-inputs-validation__error___2aXSp">
                    An account with this email address already belongs to this
                    Organisation.
                  </div>
                )}
                {!hasemailAddressError && emailError === "INVITE_EXISTS"  && (
                  <div class="react-inputs-validation__msg___pxv8o react-inputs-validation__error___2aXSp">
                    An account with this email address already invited pending and cannot be added again.
                  </div>
                )}
                
                {!hasemailAddressError && emailError === "USER_DISABLED" && (
                  <div class="react-inputs-validation__msg___pxv8o react-inputs-validation__error___2aXSp">
                    Access for a user with this email address has been disabled.{" "}
                    <br />
                    To re-enable go to <strong>Manage Users</strong>, select the
                    user and click <strong>Enable User</strong>.
                  </div>
                )}
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>Role</Form.Label>
                <Select
                  tabIndex="4"
                  id={"membershipType"}
                  name={"membershipType"}
                  value={membershipType || null}
                  disabled={false}
                  validate={validateAll}
                  validationCallback={res =>
                    this.setState({
                      hasmembershipTypeError: res,
                      validateAll: false
                    })
                  }
                  optionList={membershipTypeList}
                  classNameSelect="select-control"
                  classNameWrapper="form-control select-control-wrapper"
                  classNameContainer=""
                  classNameOptionListContainer="select-control-option"
                  classNameOptionListItem=""
                  customStyleSelect={{}}
                  customStyleWrapper={{}}
                  customStyleContainer={{}}
                  customStyleOptionListContainer={{}}
                  customStyleOptionListItem={{}}
                  onChange={(membershipType, e) => {
                    this.setState({ membershipType });
                    console.log(e);
                  }}
                  onBlur={() => {}}
                  validationOption={{
                    name: "Membership Type",
                    check: true,
                    required: true
                  }}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row className="btn-wrap">
              <Button 
             disabled={this.props.isInviteRequestedProccession}
              
              variant="primary submit green-btn mr-30" type="submit">
                Send Invite
              </Button>

              <Button variant="secondary" onClick={this.props.cancleInvite}>
                Cancel
              </Button>
            </Form.Row>
          </Form>
        </div>
      </section>
    );
  }
}

export default InviteUser;
