import React, { Component } from "react";
import { ResponsiveChord } from "@nivo/chord";

class ChordChat extends Component {
  state = {
    matrix: [this.props.graphChord]
  };
  render() {
    return (
      <div className="chordChart">
        <ResponsiveChord
          matrix={this.props.graphChord}
          keys={this.props.graphKeys}
          margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
          valueFormat=".2f"
          pixelRatio={1}
          padAngle={0.006}
          innerRadiusRatio={0.86}
          innerRadiusOffset={0}
          arcOpacity={1}
          arcBorderWidth={0}
          arcBorderColor={{ from: "color", modifiers: [["darker", 0.4]] }}
          ribbonOpacity={0.5}
          ribbonBorderWidth={0}
          ribbonBorderColor={{ from: "color", modifiers: [["darker", 0.4]] }}
          enableLabel={true}
          label={function(e) {
            let labelValue = e.id.substring(0, 4);
            let labelLength = e.id.length > 4 ? "..." : "";
            return labelValue + labelLength;
          }}
          arcTooltip={e => {
            return (
              <div className="chordToolTips">
                <div className="colorBox" style={{ background: e.arc.color }} />
                {e.arc.id} : <strong>{e.arc.value}</strong>
              </div>
            );
          }}
          ribbonTooltip={e => {
            return (
              <div className="chordToolTips">
                <div
                  className="colorBox"
                  style={{ background: e.ribbon.source.color }}
                />
                {e.ribbon.source.id} : <strong>{e.ribbon.source.value}</strong>
                <br />
                <div
                  className="colorBox"
                  style={{ background: e.ribbon.target.color }}
                />
                {e.ribbon.target.id} : <strong>{e.ribbon.target.value}</strong>
              </div>
            );
          }}
          labelOffset={2}
          labelRotation={-90}
          labelTextColor={{ from: "color", modifiers: [["darker", 1]] }}
          colors={{ scheme: "paired" }}
          isInteractive={true}
          arcHoverOpacity={1}
          arcHoverOthersOpacity={0.4}
          ribbonHoverOpacity={0.75}
          ribbonHoverOthersOpacity={0}
        />
      </div>
    );
  }
}

export default ChordChat;
