import React, {Component} from "react";
import {ResponsiveLine} from "@nivo/line";
import millify from "millify";

function handler(e) {
  var startSection = document.getElementsByClassName("topRecomendation")[0];
  e = e || window.event;

  if (window.innerWidth < 991) {
    var pageX = e.pageX - document.getElementsByClassName("chart-views")[0].offsetLeft;

  } else {
    var pageX = e.pageX - document.getElementsByClassName("chart-views")[0].offsetLeft - 130;
    var pageY = e.pageY;
  }

  // IE 8
  if (pageX === undefined) {
    pageX = e.clientX + startSection.scrollLeft + startSection.scrollLeft;
    pageY = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
  }

  return pageX;
}

class LineChart extends Component {
  processProps(props) {
    return {
      tooltipLabel: props.tooltipLabel || '',
      chartTitle: props.chartTitle || '',
      yPostfix: props.yPostfix || '',
      xAxisLabelsCount: Math.max(props.xAxisLabelsCount || 3, 3),
      CustomSymbol: this.getCustomSymbol(),
      data: props.data,
      enableCrosshair: props.enableCrosshair === true,
      enablePoints: props.enablePoints !== false,
      enableGridY: props.enableGridY !== false,
      enableBottom: props.enableBottom || false,
      isLinesOnly: props.isLinesOnly || false,
      enableTop: props.enableTop || false,
      noLine: props.noLine || false,
      decimals: this.findYDecimals(props)
    }
  }

  findYDecimals(props) {
    let decimals = 2;
    let ys = (props.data[0]?.data || []).map(e => e.y);

    while (true) {
      if (new Set(ys.map(y => millify(y, {precision: decimals}))).size === new Set(ys).size || decimals > 5) {
        break;
      }
      decimals += 1;
    }
    return decimals;
  }

  kFormatter = num => {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
      : Math.sign(num) * Math.abs(num);
  };

  renderBottomMarkers() {
    return {
      orient: "bottom",
      tickSize: 0,
      tickPadding: -20,
      tickRotation: 0,

      renderTick: ({x, y}) => {
        return (
          <g transform={`translate(${x - 10},${y - 20})`}>
            <path
              d="M9 0C4.032 0 0 4.032 0 9s4.032 9 9 9 9-4.032 9-9-4.032-9-9-9zm0 16.2c-3.969 0-7.2-3.231-7.2-7.2 0-3.969 3.231-7.2 7.2-7.2 3.969 0 7.2 3.231 7.2 7.2 0 3.969-3.231 7.2-7.2 7.2zm-1.8-5.247l5.931-5.931L14.4 6.3l-7.2 7.2-3.6-3.6 1.269-1.269L7.2 10.953z"
              fill="#9FCE1D"
            />
          </g>
        );
      }
    };
  }

  findAxisLabelsPositions(length, labelsCount) {
    if (!length || labelsCount < 2) {
      return [];
    }

    let labelPositions = [];

    if (labelsCount <= 3) {
      // case used in newsfeed stat
      labelPositions = [0, Math.ceil((length - 1) / 2), length - 1]
    } else {
      // case used in segment stat
      let decrement = Math.ceil((length - 1) / (labelsCount - 2))
      // find all labels positions with fixed step from the end
      labelPositions.push(0);
      [...Array(labelsCount).keys()].splice(1, labelsCount - 2).map(pos => (pos) * decrement).filter(pos => pos >= 0).forEach(el => labelPositions.push(el));

      labelPositions.push(length - 1);
    }

    return [...new Set(labelPositions)];
  }

  getLabelsX(data, labelsCount) {
    return this.findAxisLabelsPositions(data.length, labelsCount).map(pos => data[pos]).filter(v => v).map(v => v.x);
  }

  splitAxisLabel(label, textAnchor, textBaseline, textX, textY, x, y) {
    return <g transform={`translate(${x},${y - 20})`}>
      {label.split('\n').map((t, index) => <text
          key={index}
          alignmentBaseline={textBaseline}
          textAnchor={textAnchor}
          transform={`translate(${textX},${textY + index * 16})`}
        >
          {t}
        </text>
      )}
    </g>
  }

  renderBottomAxis(data, xAxisLabelsCount, textAnchor = 'left') {
    return {
      orient: "bottom",
      tickSize: 0,
      tickPadding: 30,
      tickRotation: 0,
      legendOffset: -8,
      legendPosition: "start",
      renderTick: ({textBaseline, textX, textY, value, x, y}) => {
        let labelsX = this.getLabelsX(data[0]?.data || [], xAxisLabelsCount)
        return labelsX.includes(value) && this.splitAxisLabel(value, textAnchor, textBaseline, textX, textY, x, y)
      }
    };
  }

  renderTopAxis(data) {
    return {
      orient: "top",
      tickSize: 0,
      tickPadding: 30,
      tickRotation: 0,
      legend: "Timeline",
      legendOffset: -8,
      legendPosition: "start",
      renderTick: ({
                     textAnchor,
                     textBaseline,
                     textX,
                     textY,
                     value,
                     x,
                     y
                   }) => {
        let dataSize = data[0].data.length - 1;
        let startVal = 0;
        let endVal = dataSize;
        let midVal = Math.ceil(dataSize / 2);
        return (
          <g transform={`translate(${x},${y})`}>
            <text
              alignmentBaseline={textBaseline}
              textAnchor={textAnchor}
              transform={`translate(${textX},${textY})`}
            >
              {value === data[0].data[startVal].x ||
              value === data[0].data[endVal].x ||
              value === data[0].data[midVal].x
                ? value
                : ""}
            </text>
          </g>
        );
      }
    };
  }

  renderLeftAxis(yPostfix, decimals) {
    return {
      orient: "left",
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      format: (v) => millify(v, {precision: decimals}) + yPostfix,
      legend: "",
      legendOffset: -40,
      legendPosition: "middle"
    };
  }

  getCustomSymbol() {
    return ({size, borderWidth, borderColor}) => (
      <g>
        <circle
          fill="#fff"
          r={size}
          strokeWidth={borderWidth}
          stroke={borderColor}
        />
      </g>
    );
  }

  renderTooltip(yPostfix, tooltipLabel) {
    return e => {
      let mouseX = handler();
      let chartWrapper = document.getElementsByClassName("chart-views")[0].offsetWidth;
      return (
        <div
          className="chordToolTips"
          style={{
            left: mouseX < chartWrapper / 2 && 0,
            right: mouseX > chartWrapper / 2 && 0,
            position: "absolute",
            top: 15,
            width: "max-content"
          }}
        >
          {e.point.data.tooltip ? e.point.data.tooltip :
            <>
              <div key={1}
                   className="colorBox"
                   style={{background: e.point.serieColor}}
              />
              <strong key={2}>{e.point.data.y + yPostfix}</strong>{" "}
              {tooltipLabel.toLowerCase()} on {e.point.data.x}
            </>
          }
        </div>
      )
    }
  }

  render() {
    const {
      chartTitle, yPostfix, xAxisLabelsCount, tooltipLabel, CustomSymbol, data, decimals,
      enableCrosshair, enablePoints, enableGridY, enableBottom, enableTop, isLinesOnly, noLine
    } = this.processProps(this.props);

    return (
      <React.Fragment>
        {chartTitle && <h3 className="lineChartTitle">{chartTitle}</h3>}
        {data.length > 0 && (
          <div className="lineChart">
            <ResponsiveLine
              data={data}
              margin={{
                top: enableTop ? 50 : 30,
                right: 40,
                bottom: enableBottom ? 60 : 30,
                left: 50
              }}
              areaBaselineValue={data[0].minY || 0}
              xScale={{type: "point"}}
              yScale={{
                type: "linear",
                stacked: false,
                min: "auto",
                max: "auto"
              }}
              curve={isLinesOnly ? "linear" : "monotoneX"}
              axisTop={enableTop ? this.renderTopAxis(data) : null}
              axisRight={null}
              axisBottom={enableBottom
                ? this.renderBottomAxis(data, xAxisLabelsCount)
                : enableTop
                  ? this.renderBottomMarkers()
                  : null
              }
              axisLeft={this.renderLeftAxis(yPostfix, decimals)}
              tooltip={this.renderTooltip(yPostfix, tooltipLabel)}
              layers={[
                "grid",
                "axes",
                "areas",
                "lines",
                enablePoints ? "points" : '',
                enableCrosshair ? "crosshair" : '',
                "mesh",
                "slices",
                "markers"
              ]}
              crosshairType={'x'}
              enableGridY={enableGridY}
              gridXValues={this.getLabelsX(data[0]?.data || [], xAxisLabelsCount)}
              colors={[data[0].color]}
              lineWidth={noLine ? 0 : 1}
              pointSize={5}
              pointColor={{theme: "background"}}
              pointBorderWidth={2}
              pointBorderColor={{from: "serieColor"}}
              pointLabel="yFormatted"
              pointLabelYOffset={-24}
              enableArea={!isLinesOnly}
              areaOpacity={0.6}
              useMesh={true}
              pointSymbol={CustomSymbol}
              legends={[]}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default LineChart;
