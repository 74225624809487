import React from "react";
import NumberFormat from "react-number-format";

const CountValue = ({ value,
                      isDecimal = false,
                      isPercent = false,
                      decimalCount = 0,
                      className }) => {

  if(isNaN(value)) {
    return ('-')
  }

  if(isDecimal || isPercent) {
    let processedValue = parseFloat(value);
    let unit = ''
    if(!isPercent) {
      processedValue = processedValue.toFixed(0);
      if(value >= 1000000000) {
        processedValue = value / 1000000000;
        unit = 'B';
      } else if(value >= 1000000) {
        processedValue = value / 1000000
        unit = 'M';
      } else if(value >= 1000) {
        processedValue = value / 1000
        unit = 'K';
      }
    } else {
      processedValue = Math.round((processedValue + Number.EPSILON) * 100) / 100
      unit='%'
    }
    return (
            <NumberFormat thousandSeparator={true}
                          decimalScale={decimalCount}
                          displayType={'text'}
                          className={className}
                          suffix={unit}
                          value={processedValue}/>
    );
  }

  return (
          <NumberFormat thousandSeparator={true}
                        decimalScale={0}
                        displayType={'text'}
                        className={className}
                        value={value}/>
  );
};

export default CountValue;
