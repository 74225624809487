import React,{Component} from "react";
import {Button} from "react-bootstrap";
import DataConnectionAPI from "../../../../../services/dataConnections";
import {toast} from "react-toastify";


class BrazeInfo extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="compatible-result">
                <p>This integration is a write only Destination integration and as such there are no Data Sources to display</p>
            </div>
        )
    }
}

export default BrazeInfo