import React, {Component} from "react";
import ListGroup from 'react-bootstrap/ListGroup'
import NoData from "../../../../../components/NoData/nodata";
import _ from "lodash";
import {Accordion} from "react-bootstrap";

/**
 * props:
 *   enableOnlyCoreAttributes - marker for enabling only core attributes on new views
 *   attributesData - list of all groups with attributes
 *   showDisplayName - shows attribute display name if true else regular name
 *   onToggleAttributeGroup - function that calls when attribute group was checked/unchecked
 *   onToggleAttribute - function that calls when attribute was checked/unchecked
 *   allAttributesStateChanged - callback after click on check/uncheck all
 */

class ViewAttributes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attributesData: null,
      allEnabled: false
    };
  }

  defaultCoreAttributes = [
    'CUSTOMER_EMAIL_ADDRESS',
    'CUSTOMER_FIRST_NAME',
    'CUSTOMER_LAST_NAME',
    'GDPR_STATUS_SUBSCRIBED',
    'GDPR_STATUSES_COUNT',
    'CUSTOMER_MOBILE_NUMBER'
  ];

  componentDidMount() {
    if(this.props.defaultAttributesTagsForCoreDataSource) {

      this.props.attributesData.filter(this.attributeToShow())
        .filter(it => it.sectionName === "Customer Core Data")
        .flatMap((item) => item.attributes).forEach(attr => {
        
          if(this.props.defaultAttributesTagsForCoreDataSource.includes(attr.attributeTag)) {
            attr.isActive = true;
            attr.tickedAlways = true;
        }
      });
      
    }
    
    if (this.props.enableOnlyCoreAttributes) {
      // disabling all not core attributes
      this.props.attributesData.filter(this.attributeToShow())
        .filter(it => !it.sectionName.includes("Core Data"))
        .map(it => {
          it.isActive = false;
          return it
        })
        .flatMap((item) => item.attributes).forEach(attr => {
        attr.isActive = false
      });

      // disabling all not defaultCoreFields
      this.props.attributesData.filter(this.attributeToShow())
        .filter(it => it.sectionName === "Core Data")
        .flatMap((item) => item.attributes)
        .filter(it => !_.includes(this.defaultCoreAttributes, it.attributeDataTag))
        .forEach(attr => {
          attr.isActive = false
        });
    }

    let allEnabled = this.checkEnabled();

    this.setState({attributesData: this.props.attributesData, allEnabled: allEnabled});
  }

  attributeToShow() {
    return it => (it.toShow === true || it.toShow === undefined || it.toShow === null)
            && (it.active === true || it.active === undefined);
  }

  checkEnabled() {
    return this.props.attributesData.filter(this.attributeToShow())
      .flatMap((item) => item.attributes).some(attr => attr.isActive === true);
  }

  changeAllAttributesState() {
    let allState = !this.state.allEnabled;
    this.state.attributesData.filter(this.attributeToShow())
      .flatMap((item) => item.attributes).forEach(attr => {
      attr.isActive = allState
    });
    this.state.attributesData.filter(this.attributeToShow())
      .forEach(group => {
        group.isActive = allState
      });
    this.setState({allEnabled: allState});
    this.props.allAttributesStateChanged();
  }

  renderDataSchema() {
    if (this.props.attributesData !== null && this.props.attributesData !== undefined && this.props.attributesData.length > 0) {
      return (
        <Accordion className="d-block mySize attribute-accordion" defaultActiveKey="0">
          {this.props.attributesData.filter(this.attributeToShow()).map((item, idx) => (
            <div className="attribute-section d-flex flex-column-reverse" key={idx}>

              <Accordion.Collapse eventKey={`${idx}`} className="attribute-section-body">
                <ListGroup className="section-attribute-list">
                  {item.attributes.filter(this.attributeToShow()).map(
                    (attribute, attrIdx) => (
                      <ListGroup.Item
                        onClick={() => {
                          if(attribute.tickedAlways) {
                            return;
                          }
                          this.props.onToggleAttribute(item, attribute);
                          this.setState({allEnabled: this.checkEnabled()})
                        }}
                        key={attrIdx}
                        title={attribute.tickedAlways ? "This is a mandatory field" : ""}
                        className={"section-attribute " + (attribute.isActive
                          ? "list_active position-relative" : "position-relative not-active ")
                                + (item?.isSelected || attribute?.deleted ? ' draw-negative' : '')
                                + (attribute.tickedAlways ? " font-weight-extraBold" : "")}
                      >
                      <span className="checkbox-span">
                        {" "}
                        {attribute.isActive && (<i className="icon-check active"/>)}
                      </span>
                        <div className="d-inline-block text-truncate w-100"
                        >
                          {this.props.showDisplayName ? attribute.displayName : attribute.name}
                        </div>
                      </ListGroup.Item>
                    ))}
                </ListGroup>
              </Accordion.Collapse>
              <div className={"attribute-section-header " + (this.hasEnabledAttributes(item) ? "list_active" : "not-active")}>

                  <span className="checkbox-span" onClick={() => {
                    this.props.onToggleAttributeGroup(item);
                    this.setState({allEnabled: this.checkEnabled()})
                  }}>
                    {this.hasEnabledAttributes(item) ? <i className="icon-check active"/> : <i className="notactive"/>}
                  </span>

                <Accordion.Toggle eventKey={`${idx}`} as="li" className="attribute-section-toggle">
                  <strong className="text-truncate">{item.sectionName}</strong>
                  <span className="label"/>
                </Accordion.Toggle>
              </div>
            </div>
          ))}
        </Accordion>
      )
    } else {
      return (
        <NoData
          customTitle="No attributes available"
          customSubTitle=""
        />
      )
    }
  }

  hasEnabledAttributes(item) {
    return item.attributes.filter(count => count.isActive === true).length > 0;
  }

  render() {
    return (
      <>
        <div className="all-checkbox" onClick={() => {
          this.changeAllAttributesState()
        }}>
              <span className="checkbox-span">
                {this.state.allEnabled ? <i className="icon-check "/> : <i className="notactive"/>}
              </span>
          Select / Deselect all
        </div>
        {this.renderDataSchema()}
      </>
    )
  }

}

export default ViewAttributes;
