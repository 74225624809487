import React, {Component} from "react";
import img from "./assets/img/screen.png";
import checkIcon from "../../../assets/img/check-mark-icon.svg";
import AccountAPI from "../../../services/account";
import dataStudio from "./assets/img/data-studio.svg";
import dataStudioMobile from "./assets/img/data-studio-mobile.svg";
import "../../../assets/css/_empty-pages.scss";
import { globalConstants } from "../../../variables/globalVariables";
import Loader from "../../../components/Loader/loader";

class EmptyCustomers extends Component {
    constructor(props) {
        super(props);
        this.state = { width: 0, loading: false };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.activateTrial = this.activateTrial.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth });
    }

  activateTrial() {
    let selectedMembers = JSON.parse(sessionStorage.getItem("selectedMembers"));
    this.setState({ loading: true });
    // ACTIVATED_TRIAL status has id 1
    AccountAPI.activateTrialModeWithUpdateStorage(selectedMembers.id).then();
  }

    changeButton() {
        let selectedMembers = JSON.parse(sessionStorage.getItem("selectedMembers"));
        let accountType = selectedMembers.accountType;
        return accountType === 'NEW_SIGN_UP';
    }

    redirectWithinTheWebSite = (route) => {
      this.props.history.push(route);
    }

    render() {
        const { width, loading } = this.state;

        return (
            <div className="analytics-portal-wrap bg-white lock-screen-component">
                <div className="content-width new-top content">
                    <div className="flex-block">
                        <div className="top-text flex-block-item">
                            <h3 className="font-weight-Avenirmedium mb-4">What are your Customers really like?</h3>
                            <p className="font-on-top primary-font">
                                Customers are the heart of your business and the answers are right there in your data.
                                But when it’s scattered across multiple platforms it's hard for you to get to know them.<br/><br/>

                                Distil AI Customer Data Studio gives you that elusive Single Customer View that lets
                                your team work easily with your data. Every bit of data, from every part of your
                                business, is drawn together.<br/><br/>

                                Customers can be analysed as individuals, or in groups, to reveal previously hidden
                                traits and patterns.<br/><br/>
                            </p>
                            <div className="btn-wrap">
                                {this.changeButton() ?
                                    (<>
                                        <button
                                            type="submit"
                                            className="btn btn-primary black-btn"
                                            onClick={this.activateTrial}
                                            disabled={loading}
                                        >
                                            Start a free {globalConstants.TRIAL_PERIOD_DAYS} day trial
                                        </button>
                                        { loading && <Loader/> }
                                    </> ) :
                                    <button
                                        type="submit"
                                        className="btn btn-primary green-btn mt-1"
                                        onClick={() => this.redirectWithinTheWebSite('/settings/billing')}
                                    >
                                        Choose a Billing plan
                                    </button>
                                }
                            </div>

                            <div className="content-items">
                                <div className="content-item">
                                    <div className="content-item-icon">
                                        <img src={checkIcon} alt="checkIcon"/>
                                    </div>
                                    <div>
                                        <p className="content-item-text">
                                            <b>A True Single Customer View</b> - powerful Segmentation, direct access to
                                            work easily with all your data.
                                        </p>
                                    </div>
                                </div>
                                <div className="content-item">
                                    <div className="content-item-icon">
                                        <img src={checkIcon} alt="checkIcon"/>
                                    </div>
                                    <div>
                                        <p className="content-item-text">
                                            <b>A.I. Segmentation and Customer Trait tagging</b> that automatically
                                            highlights key Customer groups and properties.
                                        </p>
                                    </div>
                                </div>
                                <div className="content-item">
                                    <div className="content-item-icon">
                                        <img src={checkIcon} alt="checkIcon"/>
                                    </div>
                                    <div>
                                        <p className="content-item-text">
                                            <b>Track your Segments over time</b> - see if your Campaigns, Pricing and
                                            Product strategies are delivering the results you expect.
                                        </p>
                                    </div>
                                </div>
                                <div className="content-item">
                                    <div className="content-item-icon">
                                        <img src={checkIcon} alt="checkIcon"/>
                                    </div>
                                    <div>
                                        <p className="content-item-text">
                                            <b>Auto-Sync your Segments</b> - use your Segments with Distil Destinations
                                            to
                                            improve your ROAS, focus your Campaigns and optimise your team’s workflows.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-image">
                        <img src={ width < 1250 ? dataStudioMobile : dataStudio } alt="dataStudio"/>
                    </div>
                </div>
            </div>
        );
    }
}

export default EmptyCustomers
