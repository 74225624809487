import React, { Component } from "react";
import { Form } from "react-bootstrap";

class Options extends Component {
  state = {};

  render() {
     const { currentEnrichment,onAttributeChange } = this.props;
   
    return (
      <div className="options">
        <p>
          Enrich Customer data with the mix recommendation as well as individual
          recommedations from ...
        </p>
        <Form>
          {["checkbox"].map(type => (
            <div key={`custom-inline-${type}`} className="mb-3">
              <Form.Check
                custom
                label="Content Affinity (Keywords)"
                type={type}
                checked={currentEnrichment.contentRecWriteContentAffinityKeywords}
                id={`option-custom-inline-${type}-1`}
               onChange={()=>onAttributeChange("contentRecWriteContentAffinityKeywords",currentEnrichment.contentRecWriteContentAffinityKeywords?false:true)}
              />
              <Form.Check
                custom
                label="Content Affinity (Recommendations)"
                type={type}
                checked={currentEnrichment.contentRecWriteContentAffinityRecommendations}
                id={`option-custom-inline-${type}-2`}
                onChange={()=>onAttributeChange("contentRecWriteContentAffinityRecommendations",currentEnrichment.contentRecWriteContentAffinityRecommendations?false:true)}
              
              />
              <Form.Check
                custom
                label="Content Viewed together"
                type={type}
                checked={currentEnrichment.contentRecWriteViewedTogether}
                id={`option-custom-inline-${type}-3`}
                onChange={()=>onAttributeChange("contentRecWriteViewedTogether",currentEnrichment.contentRecWriteViewedTogether?false:true)}
              
              />
              <Form.Check
                custom
                label="Content Product Affinity"
                type={type}
                checked={currentEnrichment.contentRecWriteProductAffinity}
                id={`option-custom-inline-${type}-4`}
                onChange={()=>onAttributeChange("contentRecWriteProductAffinity",currentEnrichment.contentRecWriteProductAffinity?false:true)}
              
              />
              <Form.Check
                custom
                label="Viewed before Purchase"
                type={type}
                id={`option-custom-inline-${type}-5`}
                checked={currentEnrichment.contentRecWriteViewedBeforePurchase}
                onChange={()=>onAttributeChange("contentRecWriteViewedBeforePurchase",currentEnrichment.contentRecWriteViewedBeforePurchase?false:true)}
              
              />
              <Form.Check
                custom
                label="Trending"
                type={type}
                id={`option-custom-inline-${type}-6`}
                checked={currentEnrichment.contentRecWriteTrending}
                onChange={()=>onAttributeChange("contentRecWriteTrending",currentEnrichment.contentRecWriteTrending?false:true)}
              
              />
              <Form.Check
                custom
                label="Default Content"
                type={type}
                id={`option-custom-inline-${type}-7`}
                checked={currentEnrichment.contentRecWriteDefault}
                onChange={()=>onAttributeChange("contentRecWriteDefault",currentEnrichment.contentRecWriteDefault?false:true)}
              
              />
              
            </div>
          ))}
        </Form>
      </div>
    );
  }
}

export default Options;
