import React, { Component } from "react";
import ProductRecommendations from "./CardTypes/ProductRecommendationsCard";
import DataSourceCard from "./CardTypes/DataSourceCard";
import DataSourceCardSummary from "./CardTypes/DataSourceCardSummary";
import DefaultCard from "./CardTypes/DefaultCard";
import ConnectionCard from "./CardTypes/ConnectionCard";
import SegmentCard from "./CardTypes/SegmentCard";
import moment from "moment";

import _ from "lodash";

class NewsfeedCard extends Component {
  state = {};

  cardType() {
    const {
      cardContent,
      onPinnedCard,
      onCardHistory,
      keyIndex,
      sampleDataSource,
      membershipType
    } = this.props;

    //const currentCard = this.checkCard(cardContent.newsfeedStackCardType.stackType);
    var currentCard = null;
    if (cardContent.newsfeedStackCardType !== null) {
      currentCard = cardContent.newsfeedStackCardType.stackType;
    }

    if (cardContent.newsfeedStackCardType === null) {
      // console.log(cardContent);
    }

    switch (currentCard) {
      case "DATA_SOURCE":
        
        if (cardContent.newsfeedStackCardType.cardType === 'SUMMARY') {
          return (
            <DataSourceCardSummary
              sampleDataSource={sampleDataSource}
              membershipType={membershipType}
              cardContent={cardContent}
              onPinnedCard={onPinnedCard}
              onCardHistory={onCardHistory}
              keyIndex={keyIndex}
              onPageChange={this.props.onPageChange}
            />
          );
        }
        else {
          return (
            <DataSourceCard
              cardContent={cardContent}
              onPinnedCard={onPinnedCard}
              onCardHistory={onCardHistory}
              keyIndex={keyIndex} />
          );
        }

      case "ENRICHMENT":
        return (
          <ProductRecommendations
            cardContent={cardContent}
            onPinnedCard={onPinnedCard}
            onCardHistory={onCardHistory}
            keyIndex={keyIndex}
          />
        );

      case "CONNECTION":
        return (
          <ConnectionCard
            cardContent={cardContent}
            onPinnedCard={onPinnedCard}
            onCardHistory={onCardHistory}
            keyIndex={keyIndex}
          />
        );
      case "SEGMENT":
        return (
          <SegmentCard
            cardContent={cardContent}
            onPinnedCard={onPinnedCard}
            onCardHistory={onCardHistory}
            keyIndex={keyIndex}
          />
        );

      default:
        return (
          <DefaultCard
            cardContent={cardContent}
            onPinnedCard={onPinnedCard}
            onCardHistory={onCardHistory}
            keyIndex={keyIndex}
          />
        );
    }    
  }
  checkCard(currentCard) {
    const ProductRecommendations = [
      "PRODUCT_RECOMMENDATIONS_ENABLED",
      "PRODUCT_RECOMMENDATIONS_DISABLED",
      "PRODUCT_RECOMMENDATIONS_SETTINGS_CHANGE",
      "PRODUCT_RECOMMENDATIONS_DAILY_SUMMARY_TOP_PRODUCTS",
      "PRODUCT_RECOMMENDATIONS_DAILY_SUMMARY_TRENDING",
      "PRODUCT_RECOMMENDATIONS_DAILY_SUMMARY_VIEWED_TOGETHER",
      "PRODUCT_RECOMMENDATIONS_DAILY_SUMMARY_PURCHASED_TOGETHER"
    ];
    const Data_Source = ["DATA_SOURCE"];
    const Segment = ["SEGMENT"];

    if (_.includes(ProductRecommendations, currentCard)) {
      return "PRODUCT_RECOMMENDATIONS";
    } else if (_.includes(Data_Source, currentCard)) {
      return "DATA_SOURCE";
    } else if (_.includes(Segment, currentCard)) {
      return "SEGMENT";
    } else {
      return "ENRICHMENT";
    }
  }

  render() {
    const { cardContent } = this.props;

    if (cardContent !== undefined) {
      return this.cardType();
    } else {
      return "Content not found";
    }
  }
}

export default NewsfeedCard;
