import React, { Component } from "react";

class Page403 extends Component {
  render() {
    return <div className="app flex-row align-items-center">
      <div className="p-4">
        <h1>403</h1>
        <h2>Access Forbidden</h2>
        <br/>
        <h5> You don't have an access to this page. </h5>
        <h5> Check your billing plan, please. </h5>
      </div>
    </div>;
  }
}

export default Page403;
