import React, {Component} from "react";

import logo from "../../assets/img/brand/Logo.png";

import AccountService from "../../services/account";

import {toast} from "react-toastify";
import {BannerPanel} from "../../components/Branding";
import {Link} from "react-router-dom";
import {getUrlVars} from "../Main/Customers/customerdata/segmentUtils";

class Account extends Component {
  state = {
    contextUser: [],
    orgMemberships: [],
    selectedAccount: "",
    createOrganisation: false,
    msg: "Fetching member list..."
  };

  componentDidMount() {
    document.title = "Distil - Choose account";
    this.getaccount().then(_ => {
      if (!this.state.contextUser || (this.state.contextUser.isShopify && (!this.state.contextUser.firstName
        || !this.state.contextUser.lastName || !this.state.contextUser.marketingEmailAddress))) {
        window.location = "/update"
      }
    });
  }

  logout = () => {
    this.setState({logout: true});
    this.logoutProcess();
  };

  logoutProcess = () => {
    // todo remove access token
    sessionStorage.clear();
    localStorage.clear();
    window.location = "/";
  };

  getaccount = async () => {
    try {
      await AccountService.getAccount().then(response => {
        this.setState({
          contextUser: response.contextUser,
          orgMemberships: response.orgMemberships.sort(function (a, b) {
            return (a.orgName).localeCompare(b.orgName);
          })
        });

        if (response.orgMemberships.length === 0) {
          this.setState({
            msg: "You are not currently a member of any Organisation"
          });
        }
      });
    } catch (ex) {
      toast.success("Error");
    }
  };


  selectAccount = members => {
    this.props.onSelectAccount(this.state.contextUser, members);
  };

  render() {
    let vars = getUrlVars(window.location.href);
    const isShopify = vars.isShopify === "true" || sessionStorage.getItem('isShopify');
    console.debug(`Auto choosing org: isShopifyParam=${vars.isShopify},
     isShopify session param = ${sessionStorage.getItem('isShopify')},
     members count = ${this.state.orgMemberships.length}`
    )
    if (isShopify && this.state.orgMemberships.length === 1) {
      console.log("Choosing single org:", this.state.orgMemberships[0])
      sessionStorage.removeItem('isShopify');
      this.selectAccount(this.state.orgMemberships[0])
      return <></>
    }
    return (
      <div className="app">
        <div className="login">
          <div className="container-fluid">
            <div className="row no-gutters h-100">
              <div className="col-lg-5">
                <BannerPanel/>
              </div>
              <div className="col-lg-7">
                <div className="content-wrap choose-account bg-white">
                  {/* <div className="logo-on-mobile">
                    <img src={logolight} alt="" />
                  </div> */}

                  <div className="content-inner-wrap ">
                    <div className="top-wrap d-none d-lg-block">
                      <div className="logo">
                        <img src={logo} alt=""/>
                      </div>
                    </div>

                    <h1 className="font-weight-heavy">Choose Account</h1>

                    <div className="form-wrap">
                      {this.state.orgMemberships.map(members => (
                        <button
                          className="choose-one"
                          onClick={() => this.selectAccount(members)}
                          key={members.tenantCode}
                        >
                          {members.orgName}
                          {members.isOrgTrialAccount && members.daysLeftInContract < 10 &&
                            members.daysLeftInContract >= 0 && (
                              <i className="icon-warning-outline float-right info-text"
                                 title="Account expiring soon"></i>
                            )}
                        </button>
                      ))}

                      {this.state.orgMemberships.length === 0 && (
                        <h5>{this.state.msg}</h5>
                      )}
                    </div>
                    <div className="btn-wrap">
                      <Link className="btn btn-info" to="/createaccount">
                        Create New Organisation
                      </Link>

                      <Link to="/#" className="return" onClick={this.logout}>
                        Return to Log in
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Account;
