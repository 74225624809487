import React, { Component } from "react";
import { ListGroup } from "react-bootstrap";
class Demographics extends Component {
  state = {};
  render() {
    const { currentEnrichment,onAttributeChange} = this.props
    return (
      <div className="body-content-wrap bg-white">
              
              <div className="content-section">
                <p>
                  Choose the number of Demographics clusters this enrichment asssign Customer into.
                </p>

                <ListGroup as="ul">
                  <ListGroup.Item as="li"  onClick={()=>onAttributeChange( "demographicNumClusters","THREE")}>
                  <i className={currentEnrichment.demographicNumClusters === "THREE" ?  "icon-check_circle active" :  "icon-check_circle "} />
                    <p><span>Three Clusters</span> Your Customers will be assigned into one of three Clusters.</p>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" onClick={()=>onAttributeChange( "demographicNumClusters","SIX")}>
                  <i className={currentEnrichment.demographicNumClusters === "SIX" ?  "icon-check_circle active" :  "icon-check_circle "} />
                   <p><span>Six Clusters</span>Your Customers will be assigned into one of six Clusters.</p></ListGroup.Item>
                  <ListGroup.Item as="li"  onClick={()=>onAttributeChange( "demographicNumClusters","EIGHT")}>
                  <i className={currentEnrichment.demographicNumClusters === "EIGHT" ?  "icon-check_circle active" :  "icon-check_circle "} />
                  <p><span>Eight Clusters</span>Your Customers will be assigned into one of eight Clusters.</p></ListGroup.Item>
                  <ListGroup.Item as="li"  onClick={()=>onAttributeChange( "demographicNumClusters","TEN")}>
                  <i className={currentEnrichment.demographicNumClusters === "TEN" ?  "icon-check_circle active" :  "icon-check_circle "} />
                  <p><span>Ten Clusters</span>Your Customers will be assigned into one of ten Clusters.</p></ListGroup.Item>
                </ListGroup>

              </div>

            </div>
    );
  }
}

export default Demographics;
