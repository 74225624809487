import React, {Component} from "react";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class ValidationMessages extends Component {

  render() {
    const {message, show} = this.props;
    return (
      <div className="validation-messages">
        {show && message ? <FontAwesomeIcon icon={faExclamationTriangle} title={message} /> : null }
      </div>
    );
  }
}


export default ValidationMessages;
