import ApiService from "./tenantApiService";
import UserService from "./apiService";

function getAllUsers() {
  const requestUrl = "/user";

  return ApiService.get(requestUrl).then(response => {
    return response.data;
  });
}

function getAllUsersInvite() {
  const requestUrl = "/invite";

  return ApiService.get(requestUrl).then(response => {
    return response.data;
  });
}
function createUsersInvite(formData) {
  const requestUrl = "/invite";

  return ApiService.post(requestUrl, formData).then(response => {
    return response.data;
  });
}

function checkInvite(formData) {
  const requestUrl = "/invite/findbyemail";

  ApiService.defaults.headers.post["Content-Type"] = "text/plain";

  return ApiService.post(requestUrl, formData)
    .then(response => {
      return response.data;
    })
    .catch(response => {
      return response.response;
    });
}

function resendUsersInvite(inviteId) {
  const requestUrl = "/invite/" + inviteId + "/resend";

  return ApiService.put(requestUrl).then(response => {
    return response.data;
  });
}

function revokeUsersInvite(inviteId) {
  const requestUrl = "/invite/" + inviteId;

  return ApiService.delete(requestUrl).then(response => {
    return response.data;
  });
}

function getUser(userId) {
  const requestUrl = "/user/" + userId;

  return ApiService.get(requestUrl).then(response => {
    return response.data;
  });
}

function resendVerificationInvite() {
  const requestUrl = "/context/resendInvite";
  return UserService.post(requestUrl).then(response => {
    return response.data;
  });
}

function updateUser(userData) {
  const requestUrl = "/user/" + userData.id;

  return ApiService.put(requestUrl, userData).then(response => {
    return response.data;
  });
}

function updateShopifyUser(userData) {
  const requestUrl = "shopify/private/user";
  return ApiService.put(requestUrl, userData).then(response => {
    return response.data;
  });
}

function checkExistShopifyUser(shopUserId) {
  const requestUrl = "context/public/user/checkexist/" + shopUserId;

  return ApiService.get(requestUrl).then(response => {
    return response.data;
  }).catch(response => {
    return response.response;
  })
}

export default {
  getAllUsers,
  getUser,
  updateUser,
  getAllUsersInvite,
  checkInvite,
  createUsersInvite,
  resendUsersInvite,
  revokeUsersInvite,
  resendVerificationInvite,
  checkExistShopifyUser,
  updateShopifyUser
};
