import ApiService from "./tenantApiService";

function get(url) {
  return ApiService.get(url).then(response => {
    return response.data;
  });
}

function post(url, payload) {
  return ApiService.post(url, payload).then(response => {
    return response.data;
  });
}

function put(url, payload) {
  return ApiService.put(url, payload).then(response => {
    return response.data;
  });
}

function performDelete(url) {
  return ApiService.delete(url).then(response => {
    return response.data;
  });
}

function getOrderPaymentStatuses() {
  return get("/order/payment_status");
}


export default {
  getOrderPaymentStatuses,
}
