import React, {Component} from "react";
import "../assets/tracking-shopify.scss";

class TrackingShopify extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <section className="area-instruction tracking-shopify-verified">
                    <h3 className='tracking-shopify-verified-title-completed'>
                        Your setup has been verified and everything is correct.
                    </h3>
                    <p className='tracking-shopify-verified-info-completed'>
                        <b>Distil AI: Business Analytics</b> is gathering your engagement data and building your
                        marketing funnel insights.
                    </p>
                    <p className='tracking-shopify-verified-multiple-stores-completed'>
                        <span>Multi-store setups.</span><br/>
                        If you are running multiple Shopify stores and want to combine all your data into a Single
                        Customer View, please contact <b>getstarted@distil.ai</b> and we will guide you through our
                        multi-store setup options.
                    </p>
                </section>
            </React.Fragment>
        );
    }
}

export default TrackingShopify;
