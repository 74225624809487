import React, {Component} from "react";
import "./assets/css/styles.css";
import headphones from "../AnalyticsPortal/assets/img/headphones.svg";
import img from "../Customers/assets/img/screen.png";
import checkIcon from "../../../assets/img/check-mark-icon.svg";
import tailoredAnalytics from "./assets/img/tailored-analytics.svg";
import "../../../assets/css/_empty-pages.scss";

class AnalyticsPortalEmpty extends Component {
  openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  render() {
    return (
        <div className="analytics-portal-wrap bg-white lock-screen-component">
          <div className=" content-width new-top content">
            <div className="flex-block">
              <div className="top-text flex-block-item">
                <h3 className="font-weight-Avenirmedium mb-4">Your Customers, Your Data…</h3>
                <p className="font-on-top primary-font">
                  Your insights - made to measure.<br/><br/>

                  Getting the best out of your data is complex and sometimes you need things
                  tailored to your needs. You need better ways to stay one step
                  ahead of the competition, connect with your customers and exceed
                  their expectations. You need to see a clear ROI.<br/><br/>

                  With Distil you can bring order to your untapped data
                  and turn it into a vital resource without the hassle.<br/><br/>

                  As we say at Distil - if you want to get your data guiding your strategy,
                  empowering your marketing and boosting your bottom line…
                  then it’s time to stop crunching it, analysing
                  it and processing it. Then it’s time to start Distiling it.<br/><br/>
                </p>
                <div className="btn-wrap">
                  <button
                      type="submit"
                      className="btn btn-primary black-btn mt-1"
                      onClick={() => this.openInNewTab("https://distil.ai/get-distil/#demo")}
                  >
                    <img className="top-picture" src={headphones} alt=""/>
                    Tell us your needs
                  </button>
                </div>
                <div className="content-items">
                  <div className="content-item">
                    <div className="content-item-icon">
                      <img src={checkIcon} alt="checkIcon"/>
                    </div>
                    <div>
                      <p className="content-item-text">
                        <b>Automate your key metric reporting</b> - your data, your way. Staying on top of your key metrics
                        keeps the whole team focused, but manually putting it all together
                        is time-consuming. Let us build tailored key metric live dashboards that take this pain away.
                      </p>
                    </div>
                  </div>
                  <div className="content-item">
                    <div className="content-item-icon">
                      <img src={checkIcon} alt="checkIcon"/>
                    </div>
                    <div>
                      <p className="content-item-text">
                        <b>Data deep dive</b> - do you need to answer some fundamental questions about
                        your business? From Revenue Attribution to Customer Profiling
                        we have a team that can uncover the insights that help you grow your business.
                      </p>
                    </div>
                  </div>
                  <div className="content-item">
                    <div className="content-item-icon">
                      <img src={checkIcon} alt="checkIcon"/>
                    </div>
                    <div>
                      <p className="content-item-text">
                        <b>Data Strategy</b> - data can drive optimisations, predict and reduce business
                        risks and help you focus your resources to drive
                        revenue increases. But it’s complex.
                        We can help you with long-term data strategy planning
                        and tactical deployment of analytics and data flows.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="content-image">
              <img src={tailoredAnalytics} alt="dataStudio"/>
            </div>
          </div>
        </div>
    );
  }
}

export default AnalyticsPortalEmpty;
